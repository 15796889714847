import {
    SET_STUDENTUPLOAD_STEP,
    SET_STUDENTUPLOAD_KEY_DROPZONE,
    SET_STUDENTUPLOAD_REJECTED_FILES,
    SET_STUDENTUPLOAD_FILES,
    INIT_STUDENTUPLOAD,
    SET_STUDENTUPLOAD_NEXT_PATH,
    SET_STUDENTUPLOAD_LEAVE_NO_SAVING_OPEN,
    SET_STUDENTUPLOAD_DATA_FROM_FILE,
    SET_STUDENTUPLOAD_LOADING,
    SET_STUDENTUPLOAD_COMPLETED
} from "../actions/studentUploadActions";

import { generateUniqueKey } from '../../util/arrayUtil';

const initialState = {
    isLeavingNoSavingOpen: false,
    keyDropZone: generateUniqueKey(),
    nextPath: '',
    step: 0,
    studentFiles: [],
    studentRejectedFiles: [],
    dataFromFile: [],
    isFullyValidated: false,
    isLoading: false,
    isCompleted: false
};

/* eslint-disable-next-line default-param-last */
const studentUploadReducers = (state = initialState, action) => {
    switch(action.type) {
    case SET_STUDENTUPLOAD_STEP:
        return {
            ...state,
            step: action.step
        };
    case SET_STUDENTUPLOAD_KEY_DROPZONE:
        return {
            ...state,
            keyDropZone: action.keyDropZone
        };
    case SET_STUDENTUPLOAD_FILES:
        return {
            ...state,
            studentFiles: action.studentFiles,
            isFullyValidated: action.isFullyValidated
        };
    case SET_STUDENTUPLOAD_REJECTED_FILES:
        return {
            ...state,
            studentRejectedFiles: [ ...state.studentRejectedFiles, ...action.studentRejectedFiles ]
        };
    case INIT_STUDENTUPLOAD:
        return {
            ...state,
            ...initialState,
            isCompleted: state.isCompleted

        };
    case SET_STUDENTUPLOAD_NEXT_PATH:
        return {
            ...state,
            nextPath: action.nextPath
        };
    case SET_STUDENTUPLOAD_LEAVE_NO_SAVING_OPEN:
        return {
            ...state,
            isLeavingNoSavingOpen: action.status
        };
    case SET_STUDENTUPLOAD_DATA_FROM_FILE:
        return {
            ...state,
            dataFromFile: action.dataFromFile
        };
    case SET_STUDENTUPLOAD_LOADING:
        return {
            ...state,
            isLoading: action.isLoading
        };
    case SET_STUDENTUPLOAD_COMPLETED:
        return {
            ...state,
            isCompleted: action.isCompleted
        };
    default:
        return state;
    }
};

export default studentUploadReducers;