import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, Grid, Select, MenuItem } from '@mui/material';

import StyledTextField from '../../components/Common/StyledTextField/StyledTextField';
import { localize } from '../../util/Localizer';
import commonUseStyles, { commonStyles } from '../../styles/CommonStyles';

import AddressAutoComplete from './AddressAutoComplete';
import StyledTypography from '../../components/Common/StyledTypography/StyledTypography';

const GroupInsideLeftGridItem = styled(Grid)(() => ({
    ...commonUseStyles.defaultGroupInsideLeft
}));

const GroupInsideRightGridItem = styled(Grid)(() => ({
    ...commonUseStyles.defaultGroupInsideRight
}));

const FormLabel = styled('span')(() => ({
    ...commonUseStyles.customFormLabel
}));

const RequiredLabel = styled('span')(({ theme }) => ({
    color: theme.palette.everDrivenFuchsia
}));

const AddressFormControl = styled(FormControl)(({ theme }) => ({
    marginTop: theme.spacing(1)
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    ...commonStyles(theme).selectItem

}));

const StyledMenuItemPosition = styled(StyledTypography)(() => ({
    display: 'flex',
    flexDirection: 'column'
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300
        }
    }
};

function AddressForm({
    addressLine1Id,
    refAddress1,
    addressLine2Id,
    refAddress2,
    cityId,
    zipCodeId,
    stateId,
    previousAddressList,
    selectedPreviousAddress,
    onSelectAddressInfoLine1,
    onChangeInputAddressLine1,
    onSelectAddressInfoLine2,
    onChangeInputAddressLine2,
    onInputChange,
    onSelectPreviousAddress,
    addressLine1Value,
    defaultaddressLine1Value,
    addressLine2Value,
    defaultaddressLine2Value,
    cityValue,
    zipCodeValue,
    stateValue,
    isRequired
}) {
    const refLocationAddress1 = refAddress1 ?? useRef();
    const refLocationAddress2 = refAddress2 ?? useRef();

    const renderAddresses = () => previousAddressList.map((item) => (
        <StyledMenuItem key={item.Name} value={item.RecentAddressGuid}>
            <StyledMenuItemPosition variant="h5">
                <span className="qm_block">{item.Name}</span>
                <span className="qm_block">{item.AddressLine1}</span>
                <span className="qm_block">{item.AddressLine2}</span>
                <span className="qm_block">{item.City} - {item.State}</span>
            </StyledMenuItemPosition>
        </StyledMenuItem>
    ));

    const renderValue = (item) => {
        const selectedAddress = previousAddressList.find(x => x.RecentAddressGuid === item);

        return selectedAddress ? selectedAddress.Name : localize('tripRequestForm.selectPreviousAddress');
    };

    return (
        <>
            {previousAddressList.length > 1 &&
                <>
                    <FormLabel>
                        {localize('tripRequestForm.previousAddress')}
                    </FormLabel>
                    <AddressFormControl fullWidth size="small" variant="filled">
                        <Select
                            value={selectedPreviousAddress ?? ""}
                            onChange={onSelectPreviousAddress}
                            displayEmpty
                            renderValue={(item) => renderValue(item)}
                            MenuProps={MenuProps}
                            variant="standard"
                        >
                            <StyledMenuItem key={-1} value="">
                                {localize('tripRequestForm.selectPreviousAddress')}
                            </StyledMenuItem>
                            {renderAddresses()}
                        </Select>
                    </AddressFormControl>
                </>
            }
            <FormLabel>
                {localize('tripRequestForm.streetAddress')}
                {isRequired && <RequiredLabel>*</RequiredLabel>}
            </FormLabel>
            <AddressAutoComplete
                isRequired={isRequired}
                ref={refLocationAddress1}
                idName={addressLine1Id}
                name={addressLine1Id}
                onSelectAddressInfo={onSelectAddressInfoLine1}
                onChangeInput={onChangeInputAddressLine1}
                value={addressLine1Value ?? ""}
                defaultAddress={defaultaddressLine1Value}
            />
            <FormLabel>
                {localize('tripRequestForm.addressLine2')}
            </FormLabel>
            <AddressAutoComplete
                ref={refLocationAddress2}
                idName={addressLine2Id}
                name={addressLine2Id}
                onSelectAddressInfo={onSelectAddressInfoLine2}
                onChangeInput={onChangeInputAddressLine2}
                value={addressLine2Value ?? ""}
                defaultAddress={defaultaddressLine2Value}
            />
            <FormLabel>
                {localize('tripRequestForm.city')}
                {isRequired && <RequiredLabel>*</RequiredLabel>}
            </FormLabel>
            <StyledTextField
                isRequired={isRequired}
                inputProps={{ className: 'qm_block', "data-testid": cityId }}
                name={cityId}
                value={cityValue ?? ""}
                onChange={onInputChange}
            />
            <Grid container>
                <GroupInsideLeftGridItem item xs={6}>
                    <FormLabel>
                        {localize('tripRequestForm.zipCode')}
                        {isRequired && <RequiredLabel>*</RequiredLabel>}
                    </FormLabel>

                    <StyledTextField
                        isRequired={isRequired}
                        inputProps={{ className: 'qm_block', "data-testid": zipCodeId }}
                        name={zipCodeId}
                        value={zipCodeValue ?? ""}
                        onChange={onInputChange}
                    />
                </GroupInsideLeftGridItem>
                <GroupInsideRightGridItem item xs={6}>
                    <FormLabel>
                        {localize('tripRequestForm.state')}
                        {isRequired && <RequiredLabel>*</RequiredLabel>}
                    </FormLabel>
                    <StyledTextField
                        isRequired={isRequired}
                        inputProps={{ className: 'qm_block', "data-testid": stateId }}
                        name={stateId}
                        value={stateValue ?? ""}
                        onChange={onInputChange}
                    />
                </GroupInsideRightGridItem>
            </Grid>
        </>
    );
}

export default AddressForm;
