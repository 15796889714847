import mrmFetch from '../../util/fetchUtil';

export const TRIP_CANCEL = "PASSENGERS_SELECT";
export const cancel = async (guid) => {
    window.log(`Cancelling Trip: ${guid}`);
    const msg = {Action:"Cancel"};

    try{
        await mrmFetch(`/api/v2/Trip/${guid}`, 'put', null, JSON.stringify(msg));
        window.log(`we cancelled it ${guid}`);
    } catch(e) {
        window.warn(`Could not cancel the trip with id: ${guid}`, e);
    }
};
