import { connect } from 'react-redux';

import { setTabValue } from 'redux/actions/transportationRequest/transportationRequestActions';
import Review from 'components/TransportationRequest/Review/Review';

const mapStateToProps = state => {
    const { TransportationRequestReducers } = state;

    return {
        tripModel: TransportationRequestReducers.tripModel,
        trips: TransportationRequestReducers.trips,
        transportationNeedReview: TransportationRequestReducers.transportationNeedReview,
        populationTypeReview: TransportationRequestReducers.populationTypeReview
    };
};

const ReviewContainer = connect(
    mapStateToProps,
    { setTabValue }
)(Review);

export default ReviewContainer;
