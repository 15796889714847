import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { reports } from '../util/EnumHelper';
import DPApp from './DPApp';

import ReportRowContainer from '../redux/containers/report/ReportRowContainer';
import NoShowReportModalDatePickerContainer from '../redux/containers/report/NoShowReportModalDatePickerContainer';

import StyledTypography from './Common/StyledTypography/StyledTypography';

const HeaderSearch = styled('div')(() => ({
    width: '100%',
    minHeight: 120,
    paddingRight: 15,
    paddingLeft: 15
}));

function Report({
    resetReponses
}) {
    const renderReports = () => {
        if (reports) {
            return (
                reports.map(item => (
                    <ReportRowContainer
                        key={item.index}
                        report={item}
                    />
                ))
            );
        }

        return null;
    };

    useEffect(() => {
        resetReponses();
    }, []);

    return (
        <DPApp>
            <Grid container>
                <HeaderSearch id="headerSearch">
                    <Grid item xs={12}>
                        <StyledTypography>
                            &nbsp;
                        </StyledTypography>
                    </Grid>
                    <Grid item xs={12}>
                        {renderReports()}
                    </Grid>
                </HeaderSearch>
            </Grid>

            <NoShowReportModalDatePickerContainer />
        </DPApp>
    );
}

export default Report;
