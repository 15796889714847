/* eslint max-lines: 0 */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import { AttachFile, Description, PictureAsPdf, Delete } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from 'react-dropzone';
import readFile, { nameNonASCIIValidator } from 'util/fileUtil';
import { fileError } from 'util/EnumHelper';

const DropzoneContainerBox = styled(Box)((props) => {
    const isCalendarUpload = /^true$/i.test(props?.iscalendarupload);

    const dropZoneContainer = {
        borderWidth: '0.2px',
        borderStyle: 'solid',
        borderColor: props.theme.palette.everDrivenLightBlue20,
        background: props.theme.palette.everDrivenLightBlue20,
        marginTop: 10,
        borderRadius: 5,
        marginBottom: 20,
        cursor: 'pointer',
        minHeight: 250
    };

    const dropZoneContainerUC = {
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: props.theme.palette.everDrivenGrey,
        background: props.theme.palette.transparent,
        marginTop: 10,
        borderRadius: 5,
        marginBottom: 20,
        cursor: 'pointer',
        minHeight: 250
    };

    return isCalendarUpload ? dropZoneContainerUC : dropZoneContainer;
});

const DropzoneTextContainerBox = styled(Box)((props) => {
    const isCalendarUpload = /^true$/i.test(props?.iscalendarupload);

    const dropZoneTextContainer = {
        textAlign: 'center'
    };

    const dropZoneTextContainerUC = {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        height: 250,
        justifyContent: 'center'
    };

    return isCalendarUpload ? dropZoneTextContainerUC : dropZoneTextContainer;
});

const DropzoneText = styled(Typography)((props) => {
    const isCalendarUpload = /^true$/i.test(props?.iscalendarupload);

    const dropZoneText = {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 24
    };

    const dropZoneTextUC = {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 24,
        marginTop: -10,
        color: props.theme.palette.everDrivenDarkGrey
    };

    return isCalendarUpload ? dropZoneTextUC : dropZoneText;
});

const DropZoneIcon = styled(CloudUploadIcon)((props) => {
    const isCalendarUpload = /^true$/i.test(props?.iscalendarupload);

    const dropZoneCloudIcon = {
        width: 51,
        height: 51,
        color: props.theme.palette.black
    };

    const dropZoneCloudIconUC = {
        width: 150,
        height: 100,
        color: props.theme.palette.everDrivenGrey60
    };

    return isCalendarUpload ? dropZoneCloudIconUC : dropZoneCloudIcon;
});

const DropzoneImageContainer = styled(Grid)(() => ({
    padding: 4,
    position: 'relative',
    textAlign: 'center',
    zIndex: 2,
    '&:hover': {
        '& > svg': {
            opacity: 0.5
        },
        '& > button': {
            opacity: 1
        }
    }

}));

const DropzoneImg = styled('img')(({ theme }) => ({
    zIndex: 1,
    width: 50,
    height: 50,
    borderRadius: 4,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: `${theme.palette.everDrivenGrey40} 0 1px 6px, ${theme.palette.everDrivenGrey40} 0 1px 4px`,
    opacity: 1
}));

const DropzoneDescription = styled(Description)(({ theme }) => ({
    zIndex: 1,
    width: 50,
    height: 50,
    borderRadius: 4,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: `${theme.palette.everDrivenGrey40} 0 1px 6px, ${theme.palette.everDrivenGrey40} 0 1px 4px`,
    opacity: 1
}));

const DropzonePictureAsPdf = styled(PictureAsPdf)(({ theme }) => ({
    zIndex: 1,
    width: 50,
    height: 50,
    borderRadius: 4,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: `${theme.palette.everDrivenGrey40} 0 1px 6px, ${theme.palette.everDrivenGrey40} 0 1px 4px`,
    opacity: 1
}));

const DropzoneAttachFile = styled(AttachFile)(({ theme }) => ({
    zIndex: 1,
    width: 50,
    height: 50,
    borderRadius: 4,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: `${theme.palette.everDrivenGrey40} 0 1px 6px, ${theme.palette.everDrivenGrey40} 0 1px 4px`,
    opacity: 1
}));

const DropzoneRemoveButton = styled(IconButton)(({ theme }) => ({
    top: -8,
    width: 40,
    height: 40,
    position: 'absolute',
    backgroundColor: theme.palette.everDrivenGrey40,
    color: theme.palette.black,
    transition: '.5s ease',
    opacity: 0,
    boxShadow: `0px 3px 5px -1px ${theme.palette.everDrivenGrey40},
        0px 6px 10px 0px ${theme.palette.everDrivenGrey40},
        0px 1px 18px 0px ${theme.palette.everDrivenGrey40}`
}));

const AttachmentFileName = styled('div')(({ theme }) => ({
    color: theme.palette.black,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginLeft: 5
}));

function DropZone({
    allowedFiles,
    dropZoneText,
    filesLimit,
    fileObjects,
    maxFileSize,
    isCalendarUpload,
    showMiniatures,
    getDropRejectMessage,
    getFileAddedMessage,
    getFileLimitExceedMessage,
    getFileRemovedMessage,
    onChange,
    onChangeRejections
}) {
    const [ files, setFiles ] = useState([]);

    const validateFileName = (file) => {
        if (nameNonASCIIValidator(file)) {
            return {
                code: fileError.fileNameHasNonAsciiCharacter,
                message: ''
            };
        }

        return null;
    };

    const onSetRejections = (rfiles, message) => {
        const fileRejections = rfiles.map(x => ({
            errors: [{ message }],
            file: x
        }));

        if (typeof onChangeRejections === 'function') {
            onChangeRejections(fileRejections);
        }
    };

    const { fileRejections, getRootProps, getInputProps } = useDropzone({
        accept: allowedFiles,
        maxFiles: filesLimit,
        maxSize: maxFileSize,
        validator: validateFileName,
        onDrop: async (receivedFiles) => {
            const acceptedFiles = receivedFiles.filter(file => !nameNonASCIIValidator(file));
            const nonValidASCIIFiles = receivedFiles.filter(file => nameNonASCIIValidator(file));
            const newFiles = await Promise.all(
                acceptedFiles.map(async (file) => {
                    const nFile = file;
                    const data = await readFile(file);

                    nFile.dataPreview = data;

                    return nFile;
                })
            );

            if (files.length + newFiles.length > filesLimit) {
                onSetRejections(newFiles);

                return getFileLimitExceedMessage();
            }

            if (nonValidASCIIFiles.length > 0) {
                nonValidASCIIFiles.forEach(nonValidASCIIFile => {
                    validateFileName(nonValidASCIIFile);
                });
            }

            acceptedFiles.filter(file => !nameNonASCIIValidator(file))
                .map(file => typeof getFileAddedMessage === 'function' ? getFileAddedMessage(file.name): null);

            onChange([ ...files, ...newFiles ]);

            return setFiles([ ...files, ...newFiles ]);
        },
        onDropRejected: (fRejections) => {
            if (fRejections.length > filesLimit) {
                getFileLimitExceedMessage();
            } else {
                fRejections.map(fileRejection => getDropRejectMessage(fileRejection));
            }
        }
    });

    const createFileFromUrl = async (url) => {
        const response = await fetch(url);
        const data = await response.blob();
        const metadata = { type: data.type };
        const filename = url.replace(/\?.+/, '').split('/').pop();

        return new File(
            [data],
            filename,
            metadata
        );
    };

    const onHandleDeleteItem = (removeItem, removeItemIndex) => {
        const newItems = files.filter((file, index) => index !== removeItemIndex);

        setFiles(newItems);
        onChange(newItems);
        getFileRemovedMessage(removeItem.name);
    };

    const renderFileIcon = (index, file, children) => (
        <DropzoneImageContainer key={`${file.name}-${index}`} item xs={4}>
            {children}
            <DropzoneRemoveButton
                data-testid="removeAttachmentBtn"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getRootProps({
                    onClick: event => {
                        event.stopPropagation();

                        return onHandleDeleteItem(file, index);
                    }
                })}
            >
                <Delete />
            </DropzoneRemoveButton>
            <AttachmentFileName data-testid="AttachmentFileName">
                {file.name}
            </AttachmentFileName>
        </DropzoneImageContainer>
    );

    const renderItems = () => {
        const fileItems = files.map((file, index) => {
            const { type } = file;

            if (type.split('/')[0] === 'image') {
                return (
                    renderFileIcon(
                        index,
                        file,
                        <DropzoneImg
                            alt=""
                            role="presentation"
                            src={file.dataPreview}
                        />
                    )
                );
            }

            switch (type) {
            case "application/msword":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return (
                    renderFileIcon(
                        index,
                        file,
                        <DropzoneDescription />
                    )
                );
            case "application/pdf":
                return (
                    renderFileIcon(
                        index,
                        file,
                        <DropzonePictureAsPdf />
                    )
                );
            default:
                return (
                    renderFileIcon(
                        index,
                        file,
                        <DropzoneAttachFile />
                    )
                );
            }
        });

        return fileItems;
    };

    useEffect(() => {
        async function setFilesObject() {
            try {
                const fileObjs = await Promise.all(
                    fileObjects.map(async (initialFile) => {
                        let file;

                        if (typeof initialFile === 'string') {
                            file = await createFileFromUrl(initialFile);
                        } else {
                            file = initialFile;
                        }

                        return file;
                    })
                );

                if (isCalendarUpload) {
                    setFiles(fileObjs);
                } else {
                    setFiles([].concat(
                        files,
                        fileObjs
                    ));
                }
            } catch (err) {
                // eslint-disable-next-line no-console
                console.log(err);
            }
        }

        setFilesObject();
    }, isCalendarUpload ? [fileObjects] : []);

    useEffect(() => {
        if (typeof onChangeRejections === 'function') {
            onChangeRejections(fileRejections);
        }
    }, [fileRejections]);

    return (
        <DropzoneContainerBox
            iscalendarupload={isCalendarUpload?.toString()}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getRootProps()}
        >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input data-testid="dropzoneInputBox" {...getInputProps()} />
            <DropzoneTextContainerBox
                iscalendarupload={isCalendarUpload?.toString()}
            >
                <DropzoneText
                    variant="h5"
                    iscalendarupload={isCalendarUpload?.toString()}
                >
                    {dropZoneText}
                </DropzoneText>
                <DropZoneIcon
                    iscalendarupload={isCalendarUpload?.toString()}
                />
            </DropzoneTextContainerBox>
            {
                showMiniatures && <Grid container spacing={1}>
                    {renderItems()}
                </Grid>
            }
        </DropzoneContainerBox>
    );
}

export default DropZone;