import {
    styledHeaderSetOption,
    styledHeaderSetOptions
} from 'redux/actions/styledHeaderActions';
import {
    DttTabModel,
    DttTabOptions
} from 'data/models/tabModel/DttTabModel';

// --- INIT

// eslint-disable-next-line import/prefer-default-export
export const initialSetup = () => (dispatch) => {
    dispatch(styledHeaderSetOption(DttTabOptions.Schools));
    dispatch(styledHeaderSetOptions(DttTabModel));
};
