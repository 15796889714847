import { connect } from 'react-redux';
import StyledSortColumnButton from '../../../components/Common/StyledSortColumnButton/StyledSortColumnButton';

import { sortbyColumn } from '../../workers/draftListWorker';

const mapStateToProps = () => ({});

const StyledSortColumnButtonContainer = connect(
    mapStateToProps, {
        sortbyColumn
    }
)(StyledSortColumnButton);

export default StyledSortColumnButtonContainer;