import React, {useState} from 'react';

import {
    Paper,
    Collapse,
    IconButton,
    Grid
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import { styled } from '@mui/material/styles';

import { localize } from '../../util/Localizer';

import { monthDayYearFormat } from '../../util/dateUtils';

const EMPTY_VALUE = '-';

const ADJUSTMENT_TYPES = {
    EXCLUDE_ALL: 'calendar.excludeAll',
    EXCLUDE_RUNS_TO_PINNED_LOCATION: 'calendar.excludeRunsToPinnedLocation', // We don't use for now
    EXCLUDE_RUNS_FROM_PINNED_LOCATION: 'calendar.excludeRunsFromPinnedLocation', // We don't use for now
    EARLY_RELEASE: 'calendar.earlyRelease',
    DELAYED_START: 'calendar.delayedStart'
};

const ExceptionTableHeader = styled(Grid)(({ theme }) => ({
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: 16,
    background: theme.palette.everDrivenLightBlue20
}));

const ExceptionTableBody = styled(Grid)(() => ({
    fontSize: 14
}));

const ExceptionTableColumn = styled(Grid)(() => ({
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'left'
}));

const ExceptionTableOverflow = styled('div')(() => ({
    textOverflow:'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
}));

const ExceptionTableArrow = styled(Grid)(() => ({
    textAlign: 'right',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 15
}));

const ExceptionTableButton = styled(IconButton)(() => ({
    paddingTop: 0,
    paddingBottom: 0
}));

const ExceptionTableCollapse = styled(Collapse)(() => ({
    width: '100%'
}));

const ExceptionTableRow = styled(Grid)(({ theme }) => ({
    paddingLeft: 15,
    paddingRight: 15,
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    }
}));

function ExceptionTable({
    CalendarExceptions
}) {
    const [ open, setOpen ] = useState(true);

    const SCHOOLNAME = CalendarExceptions?.length > 0 ? CalendarExceptions[0].adjustmentEntityTypeSource : null;

    const renderExceptionType = (calendar) => {
        let type = '';

        switch(ADJUSTMENT_TYPES[calendar.adjustmentType]) {
        case ADJUSTMENT_TYPES.DELAYED_START:
            type = localize(ADJUSTMENT_TYPES.DELAYED_START, { minutes: calendar.adjustMinutes });
            break;
        case ADJUSTMENT_TYPES.EARLY_RELEASE:
            type = localize(ADJUSTMENT_TYPES.EARLY_RELEASE, { minutes: calendar.adjustMinutes });
            break;
        case ADJUSTMENT_TYPES.EXCLUDE_ALL:
            type = localize(ADJUSTMENT_TYPES.EXCLUDE_ALL);
            break;
        case ADJUSTMENT_TYPES.EXCLUDE_RUNS_TO_PINNED_LOCATION:
            type = localize(ADJUSTMENT_TYPES.EXCLUDE_RUNS_TO_PINNED_LOCATION);
            break;
        case ADJUSTMENT_TYPES.EXCLUDE_RUNS_FROM_PINNED_LOCATION:
            type = localize(ADJUSTMENT_TYPES.EXCLUDE_RUNS_FROM_PINNED_LOCATION);
            break;
        default:
            break;
        }

        return type;
    };

    const renderColumnRow = (item, columnWidth, testId) => (
        <ExceptionTableColumn
            item
            xs={columnWidth}
            sm={columnWidth}
            md={columnWidth}
            lg={columnWidth}
            xl={columnWidth}
        >
            <ExceptionTableOverflow data-testid={testId}>
                {item ?? EMPTY_VALUE}
            </ExceptionTableOverflow>
        </ExceptionTableColumn>
    );

    const renderExceptionRow = (exception) => {
        const type = renderExceptionType(exception);

        return(
            <ExceptionTableRow
                container
                key={exception.calendarAdjustmentId}
                direction="row"
                data-testid={`calendarAdjustmentId_${exception.calendarAdjustmentId}`}
            >
                {renderColumnRow(type, 3, "exception")}
                {renderColumnRow(exception.notes, 4, "description")}
                {renderColumnRow(monthDayYearFormat(exception.startDate), 2, "startDate")}
                {renderColumnRow(monthDayYearFormat(exception.endDate), 3, "endDate")}
            </ExceptionTableRow>
        );
    };

    const renderExceptions = () => (
        <ExceptionTableBody
            container
            justifyContent="space-between"
            direction="row"
        >
            <ExceptionTableCollapse in={open} timeout="auto">
                {(CalendarExceptions ?? []).map(exception => renderExceptionRow(exception))}
            </ExceptionTableCollapse>
        </ExceptionTableBody>
    );

    const renderHeaderExceptions = () => (
        <ExceptionTableHeader
            container
        >
            {renderColumnRow(<strong>{localize('calendar.adjustmentType')}</strong>, 3)}
            {renderColumnRow(<strong>{localize('calendar.description')}</strong>, 4)}
            {renderColumnRow(<strong>{localize('calendar.startDate')}</strong>, 2)}
            {renderColumnRow(<strong>{localize('calendar.endDate')}</strong>, 2)}
            <ExceptionTableArrow
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
            >
                <ExceptionTableButton
                    size="small"
                    onClick={()=> setOpen(!open)}
                >
                    { open
                        ? <ExpandMore />
                        : <ExpandLess />
                    }
                </ExceptionTableButton>
            </ExceptionTableArrow>
        </ExceptionTableHeader>
    );

    return (
        <Paper>
            <Grid container data-testid={SCHOOLNAME}>
                {renderHeaderExceptions()}
                {renderExceptions()}
            </Grid>
        </Paper>
    );
}

export default ExceptionTable;