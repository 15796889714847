import React from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import { localize } from '../../util/Localizer';
import StyledTableCell from "../Common/StyledTableCell/StyledTableCell";
import StyledTableRow from "../Common/StyledTableRow/StyledTableRow";
import StyledButton from "../Common/StyledButton/StyledButton";
import breakpointsNames from "../../styles/ResponsiveConstants";

const useStyles = makeStyles((theme) => ({
    buttonLink: {
        color: theme.palette.everDrivenLightBlue,
        fontWeight: 500,
        padding: 0,
        margin: 0,
        minWidth: 0,
        textTransform: 'none',
        fontSize: 12,
        '& > span > svg': {
            fontSize: '14px !important'
        },
        '&.Mui-disabled': {
            color: theme.palette.white
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            fontSize: 10
        }
    },
    deleteIcon:{
        color: theme.palette.everDrivenDarkGrey
    },
    acceptedIcon: {
        color: theme.palette.everDrivenDarkGreen
    },
    fileSizeColumn: {
        width: 150,
        color: theme.palette.everDrivenDarkGrey,
        [theme.breakpoints.down(breakpointsNames.md)]: {
            width: 120
        }
    },
    fileNameColumn: {
        width: 400,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            width: 270
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            width: 250
        },
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            width: 200
        }
    },
    buttonColumn:{
        width: 200,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            width: 100
        }
    },
    fileNameText: {
        paddingTop: 5,
        marginLeft: 5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: 400,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            width: 250
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            width: 230,
            paddingTop: 0
        },
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            width: 180
        }
    },
    acceptedRow: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    }
}));

function StudentFileRow({
    nRow,
    row,
    onDeleteFile
}) {
    const classes = useStyles();

    return (
        <StyledTableRow>
            <StyledTableCell extraCssClass={classes.fileNameColumn} component="th" scope="row">
                <div className={classes.acceptedRow}>
                    <CheckCircleOutlineIcon className={classes.acceptedIcon} />
                    <div className={classes.fileNameText}>
                        {row.name}
                    </div>
                </div>
            </StyledTableCell>
            <StyledTableCell extraCssClass={classes.fileSizeColumn} component="th" scope="row" align="left">
                {`${(row.size / 1024).toFixed(2)} KB`}
            </StyledTableCell>
            <StyledTableCell className={classes.buttonColumn} component="th" scope="row" align="right">
                <StyledButton
                    className={classes.buttonLink}
                    color="inherit"
                    startIcon={<DeleteIcon fontSize="inherit" className={classes.deleteIcon} />}
                    testId={`deleteFileButton_${nRow}_${row.name}`}
                    type="button"
                    variant="text"
                    onClick={() => onDeleteFile(nRow)}
                >
                    {localize('studentUpload.removeFile')}
                </StyledButton>
            </StyledTableCell>
        </StyledTableRow>
    );
}

export default StudentFileRow;