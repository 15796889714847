import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Isvg from "react-inlinesvg";
import { styled } from '@mui/material/styles';

import { cancel } from 'redux/actions/tripActions';
import * as dateUltis from 'util/dateUtils';
import { Enum } from 'util/EnumHelper';
import { tripPrimaryColor } from 'util/colorUtil';
import { localize } from 'util/Localizer';
import { hasPendingCancellation } from 'util/validationUtil';
import withRouter from 'util/customHooks/withRouter';
import breakpointsNames from 'styles/ResponsiveConstants';

import StyledCardContainer from '../../Common/StyledCardContainer.js/StyledCardContainer';
import TripDetail from '../TripDetail/TripDetail';
import CancelConfirmation from '../CancelConfirmation';

const CardHeader = styled('div')(() => ({
    borderBottom: '1px solid',
    padding: '16px 0',
    '&> :first-of-type': {
        textTransform: 'uppercase',
        fontSize: '16px',
        fontWeight: 'bold',
        maxWidth: 'calc( 100% - 50px)',
        wordBreak: 'break-word'
    }
}));

const Content = styled('div')(() => ({
    padding: '4px 0',
    '&> :first-of-type': {
        fontSize: '12px',
        display: 'flex',
        flexDirection: 'column'
    },
    '&> :last-of-type': {
        maxWidth: '110px',
        textAlign: 'right',
        fontWeight: 'bold',
        display: 'flex'
    }
}));

const OptionListSvg = styled(Isvg)(() => ({
    minWidth: '20px',
    width: '20px'
}));

const TripIsLate = styled('div')(() => ({
    textAlign: 'right',
    marginLeft: 'auto',
    marginRight: 0
}));

const StylelessBtn = styled('button')(() => ({
    background: 'transparent',
    border: 'none',
    '&:focus, &:active': {
        outline: 'none'
    }
}));

const OverflowFullName = styled('span')(({theme}) => ({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '90%',
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        width: '100%'
    }
}));

function TripCard({
    isMapCard,
    showHeader,
    trip,
    tripType,
    goToStudent,
    goToTrackTrip
}) {
    const { DueDateTLT } = trip;

    const [ isExtended, setIsExtended ] = useState(isMapCard);
    const [ showDeleteConfirm, setShowDeleteConfirm ] = useState(false);
    const [ isCancelDisabled, setIsCancelDisabled ] = useState(false);

    const showTripStatus = tripState => {
        switch (tripState) {
        case Enum.TripState.Active:
            return localize('tripList.tripStatusActive');
        case Enum.TripState.EnRoute:
            return localize('tripList.tripStatusEnRoute');
        case Enum.TripState.OnSite:
            return localize('tripList.tripStatusOnSite');
        case Enum.TripState.OnSiteAtDropoff:
            return localize('tripList.tripStatusOnSiteAtDropoff');
        case Enum.TripState.OnBoard:
            return localize('tripList.tripStatusOnBoard');
        case Enum.TripState.Accepted:
            return localize('tripList.tripStatusAccepted');
        case Enum.TripState.Scheduled:
            return localize('tripList.tripStatusScheduled');
        case Enum.TripState.Canceled:
            return localize('tripList.tripStatusCanceled');
        case Enum.TripState.WillCall:
            return localize('tripList.tripStatusWillCall');
        case Enum.TripState.NoShow:
            return localize('tripList.tripStatusNoShow');
        case Enum.TripState.Completed:
            return localize('tripList.tripStatusCompleted');
        case Enum.TripState.PendingCancel:
            return localize('tripList.tripStatusPendingCancellation');
        default:
            window.log(`unknown trip state ${tripState}`);
            
            return '';
        }
    };

    const showImageNameByTripStatus = () => {
        switch (trip.State) {
        case Enum.TripState.EnRoute:
            return localize('tripList.tripIsLateToPU');
        case Enum.TripState.OnBoard:
            return localize('tripList.tripIsLateToDO');
        case Enum.TripState.OnSite:
            return localize('tripList.tripIsLateAtPU');
        default:
            return '';
        }
    };

    const renderCardHeader = () => (
        <>
            {showHeader && (
                <CardHeader>
                    <OverflowFullName className="qm_block">
                        {`${trip.MemberPII.FirstName} ${trip.MemberPII.LastName}`.trim()}
                    </OverflowFullName>
                    <StylelessBtn
                        onClick={() => goToStudent(trip.MemberGUID)}
                        type="button"
                    >
                        <OptionListSvg src="/img/option_list.svg" />
                    </StylelessBtn>
                </CardHeader>
            )}
            <Content>
                <div>
                    <span>{dateUltis.dayFormat(DueDateTLT)}</span>
                    <span>{dateUltis.monthDayYearFormat(DueDateTLT)}</span>
                </div>
                {dateUltis.isTripLate(trip) &&
                    (<TripIsLate>
                        <img
                            src="/img/trip_is_late.png"
                            alt={showImageNameByTripStatus()}
                            title={showImageNameByTripStatus()}
                            height="25"
                        />
                    </TripIsLate>)
                }
                <div>
                    <span>
                        {hasPendingCancellation(trip)
                            ? showTripStatus(Enum.TripState.PendingCancel)
                            : showTripStatus(trip.State)
                        }
                    </span>
                </div>
            </Content>
        </>
    );

    const renderCardBody = () => (
        isExtended && showDeleteConfirm ? (
            <CancelConfirmation
                hoursTillTrip={dateUltis.calcUtcHoursAgo(trip.DueDateTimeUTC)}
                id={`${trip.TripGuid}-${trip.MemberGUID}`}
                onCancel={() => {
                    setShowDeleteConfirm(false);
                }}
                onConfirm={() => {
                    setIsCancelDisabled(true);
                    cancel(trip.TripGuid);
                    setShowDeleteConfirm(false);
                }}
            />
        ) : (
            <TripDetail
                isCancelDisabled={isCancelDisabled}
                isExtended={isExtended}
                isMapCard={isMapCard}
                isPendingCancel={hasPendingCancellation(trip)}
                trip={trip}
                tripType={tripType}
                setShowDeleteConfirm={setShowDeleteConfirm}
                showTripStatus={showTripStatus}
                goToTrackTrip={goToTrackTrip}
            />
        )
    );

    useEffect(() => {
        setIsCancelDisabled(false);
    }, [trip]);

    return (
        <StyledCardContainer
            isTracker
            headerColor={tripPrimaryColor(trip)}
            isMapCard={isMapCard}
            cardHeader={renderCardHeader()}
            cardBody={renderCardBody()}
            onClickHeader={() => setIsExtended(!isExtended)}
        />
    );
}

TripCard.propTypes = {
    trip: PropTypes.shape({
        MemberPII: PropTypes.shape({ FirstName: PropTypes.string }),
        status: PropTypes.string,
        DueDateTimeUTC: PropTypes.string,
        MemberGUID: PropTypes.string,
        TripGuid: PropTypes.string,
        State: PropTypes.number
    }).isRequired,
    tripType: PropTypes.string.isRequired,
    showHeader: PropTypes.bool.isRequired
};

export default withRouter(TripCard);
