import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import StyledFormLabel from 'components/Common/StyledFormLabel/StyledFormLabel';
import { commonStyles } from 'styles/CommonStyles';

const SelectButton = styled(
    StyledButton,
    { shouldForwardProp: (prop) => ![ "isActive", "widthButton" ].includes(prop) }
)(({ theme, isActive, widthButton }) => {
    if(isActive) {
        return {
            ...commonStyles(theme).selectButton,
            backgroundColor: theme.palette.everDrivenLightBlue,
            color: theme.palette.white,
            width: widthButton,
            "&:hover": {
                boxShadow: 'none',
                backgroundColor: theme.palette.everDrivenLightBlue
            }
        };
    }

    return {
        ...commonStyles(theme).selectButton,
        width: widthButton
    };
});

function StyledButtonSelector({
    options,
    title,
    widthButton,
    showElements,
    value,
    onChange
}) {
    const isActive = (option) => (
        value.length > 0 && value.includes(option.id)
    );

    return (
        showElements &&
            <Grid item xs={12}>
                <Grid item xs={12} sx={{ marginTop: '20px' }}>
                    <StyledFormLabel
                        label={title}
                        isRequired
                    />
                </Grid>
                <Grid container sx={{ justifyContent: 'space-between' }}>
                    {options.map(option => (
                        <Grid
                            item
                            key={option.id}
                        >
                            <SelectButton
                                key={option.id}
                                testId={option.id}
                                type="button"
                                isActive={isActive(option)}
                                widthButton={widthButton}
                                onClick={() => onChange(option.id)}
                            >
                                {option.label}
                            </SelectButton>

                        </Grid>
                    ))}
                </Grid>
            </Grid>
    );
}

StyledButtonSelector.defaultProps = {
    value: [],
    widthButton: null,
    title: '',
    options: [],
    showElements: true,
    onChange: null
};

/* eslint-disable react/forbid-prop-types */
StyledButtonSelector.propTypes = {
    value: PropTypes.array,
    showElements: PropTypes.bool,
    widthButton: PropTypes.number,
    title: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func
};

export default StyledButtonSelector;