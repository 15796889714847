import React, { useEffect } from 'react';
import { Menu, MenuItem, Link } from '@mui/material';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import { leftMenu, rightMenu } from "../../util/dynamicMenu";
import breakpointsNames from '../../styles/ResponsiveConstants';
import { localize } from "../../util/Localizer";
import useWidth from '../../util/customHooks/useWidth';
import { paths } from '../../util/EnumHelper';

const UserNameMenuItem = styled(MenuItem)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.black,
    paddingLeft: 50,
    background: theme.palette.everDrivenLightBlue20
}));

const ActionMenuItem = styled(MenuItem)(({ theme }) => ({
    paddingLeft: 35,
    minWidth: 100,
    height: 40,
    color: theme.palette.white,
    background: theme.leftMenuContainer.background,
    '&:hover': {
        backgroundColor: theme.palette.everDrivenDarkBlue
    }
}));

const ActionLinkMenuItem = styled(MenuItem)(({ theme }) => ({
    fontWeight: 'bold',
    paddingLeft: 50,
    minWidth: 100,
    color: theme.palette.black,
    background: theme.palette.everDrivenLightBlue40,
    '&:hover': {
        backgroundColor: theme.palette.everDrivenLightBlue20
    },
    '&.Mui-selected': {
        backgroundColor: theme.palette.everDrivenLightBlue20,
        '&:hover': {
            backgroundColor: theme.palette.everDrivenLightBlue20
        }
    }
}));

const HeaderMenu = styled(Menu)(({ theme }) => ({
    display: "none",
    '& > li' : {
        margin: 0
    },
    [theme.breakpoints.down(breakpointsNames.sm)] : {
        display: 'block'
    },
    [theme.breakpoints.only(breakpointsNames.sm)]: {
        display: 'none',
        "@media only screen and (orientation: landscape)": {
            display: 'block'
        }
    },
    '& .MuiPaper-root': {
        left: "-5px !important"
    }
}));

const UserName = styled('span')(() => ({
    textOverflow:'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
}));

function MainMenu({
    disableLogOut,
    userName,
    onClickMenu,
    onClickActionMenuItem,
    setTab,
    tabValue,
    pathname
}) {
    const [ isOpen, setIsOpen ] = React.useState(false);
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const dynLeftMenu = leftMenu();
    const dynRightMenu = rightMenu();
    const width = useWidth();

    useEffect(() => {
        setTab();
    }, [pathname]);

    const headerOff = () => {
        setIsOpen(false);
        onClickMenu(false);
    };

    const headerOn = () => {
        setIsOpen(true);
        onClickMenu(true);
    };

    const handleClick = (value) => {
        onClickActionMenuItem(value);
        setAnchorEl(null);
        headerOff();
    };

    const handleShowOff = event => {
        event.preventDefault();

        if (!isOpen) {
            setAnchorEl(event.currentTarget);
            headerOn();
        } else {
            setAnchorEl(null);
            headerOff();
        }
    };

    const handleClose = () => {
        if (!document.getElementById('contact-Modal')) {
            if (isOpen) {
                setAnchorEl(null);
                headerOff();
            }
        }
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setAnchorEl(null);
            headerOff();
        }
    };

    switch(width) {
    case breakpointsNames.md:
    case breakpointsNames.lg:
    case breakpointsNames.xl:
        if (isOpen) {
            setAnchorEl(null);
            headerOff();
        }
        break;
    default:
        break;
    }

    return (
        <div>
            <IconButton
                edge="start"
                onClick={handleShowOff}
                color="inherit"
                aria-label="menu"
                id="btnHandleEventId"
                size="large">
                {isOpen
                    ? <CancelIcon fontSize="large"/>
                    : <MenuIcon fontSize="large"/>
                }
            </IconButton>
            <HeaderMenu
                id="main-mobile-menu"
                aria-labelledby="main-mobile-button"
                anchorEl={(width === breakpointsNames.xs || width === breakpointsNames.sm) ? anchorEl : null}
                open={Boolean((width === breakpointsNames.xs || width === breakpointsNames.sm) ? anchorEl : null)}
                TransitionComponent={Fade}
                onClose={handleClose}
                onKeyDown={handleListKeyDown}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 80, left: 0 }}
            >
                {dynLeftMenu.map((x, index) => (
                    <ActionMenuItem
                        key={x.id}
                        onClick={() => handleClick(x)}
                        selected={x.id === tabValue}
                        divider={index === dynLeftMenu.length - 1}
                    >
                        <ListItemIcon>{x.icon}</ListItemIcon>
                        {x.label}
                    </ActionMenuItem>
                ))}
                <UserNameMenuItem
                    value={10}
                    disabled
                    divider
                >
                    <UserName>
                        {userName}
                    </UserName>
                </UserNameMenuItem>
                <ActionLinkMenuItem
                    onClick={() => onClickActionMenuItem(
                        dynRightMenu.find(x => x.id === paths.Contact.tabValue)
                    )}
                    divider
                >
                    {localize('header.contact')}
                </ActionLinkMenuItem>
                {dynRightMenu.map(x => {
                    if (x.id !== paths.Contact.tabValue) {
                        if (x.id === paths.Support.tabValue ||
                            x.id === paths.UserGuide.tabValue) {
                            return (
                                <Link
                                    key={x.id}
                                    href={x.path ?? null}
                                    underline="none"
                                    target={x.id === paths.UserGuide.tabValue ? '_blank' : null}
                                >
                                    <ActionLinkMenuItem>
                                        {x.label}
                                    </ActionLinkMenuItem>
                                </Link>
                            );
                        }

                        return (
                            <ActionLinkMenuItem
                                key={x.id}
                                disabled={x.id === paths.LogOut.tabValue && disableLogOut}
                                onClick={() => onClickActionMenuItem(x)}
                            >
                                {x.label}
                            </ActionLinkMenuItem>
                        );
                    }

                    return null;
                })}
            </HeaderMenu>
        </div>
    );
}

export default MainMenu;
