import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { string } from 'prop-types';

const EmptyTableCell = styled(TableCell)(() => ({
    fontSize: 12,
    padding: '8px 16px'
}));

const EmptyTableContainer = styled(TableContainer)(({ theme }) => ({
    backgroundColor: theme.palette.offWhite
}));

function StyledEmptyTable ({ message, testId }) {
    return (
        <EmptyTableContainer component={Paper} padding="none" data-testid={testId}>
            <Table>
                <TableBody>
                    <TableRow key={0}>
                        <EmptyTableCell component="th" scope="row">
                            {message}
                        </EmptyTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </EmptyTableContainer>
    );
}

StyledEmptyTable.defaultProps = {
    message: null,
    testId: "styledEmptyTable"
};

StyledEmptyTable.propTypes = {
    message: string,
    testId: string
};

export default StyledEmptyTable;