import mrmFetch from "util/fetchUtil";
import { Enum } from "util/EnumHelper";

const DEFAULT_SKIP = 1;
const DEFAULT_ORDER = 'name';
const DEFAULT_PAGE = 10;
const DEFAULT_ORDER_TYPE = Enum.Sorter.Asc;

// eslint-disable-next-line import/prefer-default-export
export const facilityTrips = async (guid, filterTo = 0, continuationToken = null) => {
    const response = await mrmFetch(
        `/api/v3/school/${guid}/Trips?FilterTo=${filterTo}&ContinuationToken=${continuationToken}`,
        'get'
    );

    window.log(`selected facility ${guid}`);
    
    return response;
};

export const getFacilities = async (
    pageSize = DEFAULT_PAGE,
    skip = DEFAULT_SKIP,
    orderType = DEFAULT_ORDER_TYPE,
    order = DEFAULT_ORDER
) => {
    const path = `/api/v2/DistrictSchools?pageSize=${pageSize}&skip=${skip}`+
        `&orderType=${orderType}&order=${order}`+
        `&includeInactive=false`;
    const response = await mrmFetch(path, 'post');
    
    window.log(`found facilities`, response);

    return response;
};