export const SET_TR_CLEAN = 'SET_TR_CLEAN';
export const cleanForm = () => ({
    type: SET_TR_CLEAN
});

export const UPDATE_TR_MODEL = 'UPDATE_TR_MODEL';
export const updateModel = payload => ({
    type: UPDATE_TR_MODEL,
    model: payload
});

export const SET_TR_POPULATION_TYPE =
	'SET_TR_POPULATION_TYPE';
export const setPopulationTypes = payload => ({
    type: SET_TR_POPULATION_TYPE,
    populationTypes: payload
});

export const SET_TR_TRANSPORTATION_NEED =
	'SET_TR_TRANSPORTATION_NEED';
export const setTransportationNeeds = payload => ({
    type: SET_TR_TRANSPORTATION_NEED,
    transportationNeeds: payload
});

export const SET_TR_TRANSPORTATION_NEED_FORMAT =
	'SET_TR_TRANSPORTATION_NEED_FORMAT';
export const setTransportationNeedFormat = payload => ({
    type: SET_TR_TRANSPORTATION_NEED_FORMAT,
    equipmentNeeds: payload
});

export const SET_TR_NUMBER_OF_CONTACTS = 'SET_TR_NUMBER_OF_CONTACTS';
export const setNumberOfContacts = payload => ({
    type: SET_TR_NUMBER_OF_CONTACTS,
    numberOfContacts: payload
});

export const SET_TR_SHOW_REMOVE_CONTACT_MODAL = 'SET_TR_SHOW_REMOVE_CONTACT_MODAL';
export const setShowRemoveContactModal = payload => ({
    type: SET_TR_SHOW_REMOVE_CONTACT_MODAL,
    showRemoveContactModal: payload
});

export const SET_TR_CURRENT_CONTACT = 'SET_TR_CURRENT_CONTACT';
export const setCurrentContact = payload => ({
    type: SET_TR_CURRENT_CONTACT,
    currentContact: payload
});

export const SET_TR_HAS_GRADE = 'SET_TR_HAS_GRADE';
export const setHasGrade = payload => ({
    type: SET_TR_HAS_GRADE,
    hasGrade: payload
});

export const SET_TR_HAS_MORE_PT = 'SET_TR_HAS_MORE_PT';
export const setHasMorePT = payload => ({
    type: SET_TR_HAS_MORE_PT,
    hasMorePT: payload
});

export const SET_TR_HAS_TN = 'SET_TR_HAS_TN';
export const setHasTN = payload => ({
    type: SET_TR_HAS_TN,
    hasTN: payload
});

export const SET_TR_SHOW_STUDENT_NOTES = 'SET_TR_SHOW_STUDENT_NOTES';
export const setShowStudentNotes = payload => ({
    type: SET_TR_SHOW_STUDENT_NOTES,
    showStudentNotes: payload
});

export const SET_TR_DISABLED_CAR_SEAT = 'SET_TR_DISABLED_CAR_SEAT';
export const setDisabledCarSeat = payload => ({
    type: SET_TR_DISABLED_CAR_SEAT,
    disabledCarSeat: payload
});

export const SET_TR_DISABLED_SAFETY_VEST = 'SET_TR_DISABLED_SAFETY_VEST';
export const setDisabledSafetyVest = payload => ({
    type: SET_TR_DISABLED_SAFETY_VEST,
    disabledSafetyVest: payload
});

export const SET_TR_SHOW_REMOVE_STUDENT_NOTES_MODAL = 'SET_TR_SHOW_REMOVE_STUDENT_NOTES_MODAL';
export const setShowRemoveStudentNotesModal = payload => ({
    type: SET_TR_SHOW_REMOVE_STUDENT_NOTES_MODAL,
    showRemoveStudentNotesModal: payload
});

export const SET_TR_SHOW_FAILED_SUBMIT_MODAL = 'SET_TR_SHOW_FAILED_SUBMIT_MODAL';
export const setShowFailedSubmitModal = payload => ({
    type: SET_TR_SHOW_FAILED_SUBMIT_MODAL,
    showFailedSubmitModal: payload
});

export const SET_TR_SHOW_SUCCESS_SUBMIT_MODAL = 'SET_TR_SHOW_SUCCESS_SUBMIT_MODAL';
export const setShowSuccessSubmitModal = payload => ({
    type: SET_TR_SHOW_SUCCESS_SUBMIT_MODAL,
    showSuccessSubmitModal: payload
});

export const SET_TR_SHOW_ERROR_SUBMIT_MODAL = 'SET_TR_SHOW_ERROR_SUBMIT_MODAL';
export const setShowErrorSubmitModal = payload => ({
    type: SET_TR_SHOW_ERROR_SUBMIT_MODAL,
    showErrorSubmitModal: payload
});

export const SET_TR_SHOW_SAVE_DRAFT_MODAL = 'SET_TR_SHOW_SAVE_DRAFT_MODAL';
export const setShowSaveDraftModal = payload => ({
    type: SET_TR_SHOW_SAVE_DRAFT_MODAL,
    showSaveDraftModal: payload
});

export const SET_TR_DRAFT_CAN_BE_SAVED = 'SET_TR_DRAFT_CAN_BE_SAVED';
export const setDraftCanBeSaved = payload => ({
    type: SET_TR_DRAFT_CAN_BE_SAVED,
    draftCanBeSaved: payload
});

export const SET_TR_OLD_DRAFT_MODEL = 'SET_TR_OLD_DRAFT_MODEL';
export const updateOldDraftModel = payload => ({
    type: SET_TR_OLD_DRAFT_MODEL,
    model: payload
});

export const SET_TR_OLD_DRAFT_POPULATION_TYPE =
	'SET_TR_OLD_DRAFT_POPULATION_TYPE';
export const setOldDraftPopulationTypes = payload => ({
    type: SET_TR_OLD_DRAFT_POPULATION_TYPE,
    populationTypes: payload
});

export const SET_TR_OLD_DRAFT_TRANSPORTATION_NEED =
    'SET_TR_OLD_DRAFT_TRANSPORTATION_NEED';
export const setOldDraftTransportationNeeds = payload => ({
    type: SET_TR_OLD_DRAFT_TRANSPORTATION_NEED,
    transportationNeeds: payload
});

export const SET_TR_NEXT_PATH = 'SET_TR_NEXT_PATH';
export const setNextPath = payload => ({
    type: SET_TR_NEXT_PATH,
    nextPath: payload
});

export const SET_TR_TAB_VALUE = 'SET_TR_TAB_VALUE';
export const setTabValue = payload => ({
    type: SET_TR_TAB_VALUE,
    tabValue: payload
});

export const SET_TR_POPULATION_TYPE_FORMAT =
	'SET_TR_POPULATION_TYPE_FORMAT';
export const setPopulationTypeFormat = payload => ({
    type: SET_TR_POPULATION_TYPE_FORMAT,
    populationType: payload
});

export const SET_TR_POPULATION_TYPE_REVIEW =
	'SET_TR_POPULATION_TYPE_REVIEW';
export const setPopulationTypeReview = payload => ({
    type: SET_TR_POPULATION_TYPE_REVIEW,
    populationTypeReview: payload
});

export const SET_TR_TRANSPORTATION_NEED_REVIEW =
	'SET_TR_TRANSPORTATION_NEED_REVIEW';
export const setTransportationNeedReview = payload => ({
    type: SET_TR_TRANSPORTATION_NEED_REVIEW,
    transportationNeedReview: payload
});

export const SET_TR_TITLE = 'SET_TR_TITLE';
export const setTitle = payload => ({
    type: SET_TR_TITLE,
    title: payload
});

export const SET_TR_TRIPS = 'SET_TR_TRIPS';
export const setTrips = (trips) => ({
    type: SET_TR_TRIPS,
    trips
});

export const SET_TR_OLD_DRAFT_TRIPS =
    'SET_TR_OLD_DRAFT_TRIPS';
export const setOldDraftTrips = payload => ({
    type: SET_TR_OLD_DRAFT_TRIPS,
    trips: payload
});

export const SET_TR_TRIP_NUMBER_ERROR = 'SET_TR_TRIP_NUMBER_ERROR';
export const setTripNumberError = (tripNumberError) => ({
    type: SET_TR_TRIP_NUMBER_ERROR,
    tripNumberError
});

export const SET_TR_DRAFT_SELECTED =
	'SET_TR_DRAFT_SELECTED';
export const setIsDraftSelected = payload => ({
    type: SET_TR_DRAFT_SELECTED,
    isDraftSelected: payload
});

export const SET_CORRELATIONID =
	'SET_CORRELATIONID';
export const setCorrelationId = payload => ({
    type: SET_CORRELATIONID,
    correlationId: payload
});

export const SET_TR_SUBMIT_BUTTON_STATE = 'SET_TR_SUBMIT_BUTTON_STATE';
export const setSubmitButtonDisabled = payload => ({
    type: SET_TR_SUBMIT_BUTTON_STATE,
    isSubmitDisabled: payload
});

export const SET_TR_CALLED_GETCORRELATIONID = 'SET_TR_CALLED_GETCORRELATIONID';
export const setCalledGetCorrelationId = payload => ({
    type: SET_TR_CALLED_GETCORRELATIONID,
    calledGetCorrelationId: payload
});