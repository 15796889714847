import React, { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import TripGroupContainer from 'redux/containers/districtTripTracker/TripGroupContainer';

import StyledLoading from './Common/StyledLoading/StyledLoading';
import { localize } from '../util/Localizer';
import FacilityMap from '../widgets/facility/FacilityMap';
import breakpointsNames from '../styles/ResponsiveConstants';

import StyledButton from './Common/StyledButton/StyledButton';
import DPApp from './DPApp';
import StyledFacilityHeader from './Common/StyledFacilityHeader/StyledFacilityHeader';
import StyledContentBox from './Common/StyledContentBox/StyledContentBox';
import { optionsTripGroupDTT } from '../util/EnumHelper';

const TOGGLE_MAP = {
    OPENED: '50vh',
    CLOSED: '0vh'
};

const MapContainerGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        width: '100vw',
        position: 'relative',
        left: 'calc(-50vw + 50%)'
    }
}));

const HideShowMapContainerGrid = styled(Grid)(({ theme }) => ({
    textAlign: 'right',
    display: 'inline-block',
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        textAlign: 'center'
    }
}));

const HideShowMapLabel = styled(Box)(({ theme }) => ({
    marginTop: 3,
    float: 'left',
    userSelect: 'none',
    fontWeight: 'bold',
    fontSize: 20,
    color: theme.palette.everDrivenDarkGrey
}));

const StyledUpIcon = styled(UpIcon)(({ theme }) => ({
    color: theme.palette.everDrivenDarkGrey,
    paddingBottom: 4
}));

const StyledDownIcon = styled(DownIcon)(({ theme }) => ({
    color: theme.palette.everDrivenDarkGrey,
    paddingBottom: 4
}));

const LinkButton = styled(StyledButton)(() => ({
    border: 0,
    background: 'transparent',
    boxShadow: 'none !important',
    '&:hover': {
        background: 'transparent'
    }
}));

function Facility({
    initialSetup,
    isLoading,
    toggleMapChange,
    toggleMap,
    trips,
    facilityId,
    facility
}) {
    const showMapText = toggleMap
        ? localize('facilityMap.hideMap')
        : localize('facilityMap.showMap');

    const showMapIcon = toggleMap
        ? (
            <StyledUpIcon fontSize="large" />
        ) : (
            <StyledDownIcon fontSize="large" />
        );

    const mapHeight = toggleMap
        ? TOGGLE_MAP.OPENED
        : TOGGLE_MAP.CLOSED;

    const onSelectMap = id => {
        Array.prototype.forEach.call(
            document.querySelectorAll('.pulse'),
            element => {
                element.classList.remove('pulse');
            }
        );
        const row = document.getElementById(`trip-${id}`);

        if (row !== null) {
            row.scrollIntoView();
            document.getElementById(`trip-${id}`).classList.add('pulse');
        }
    };

    const renderLoading = () => (
        isLoading && (
            <StyledContentBox>
                <StyledLoading message={localize('school.loadingTrips')} />
            </StyledContentBox>
        )
    );

    const renderResults = () => (
        !isLoading && (
            <>
                <StyledFacilityHeader
                    facility={facility}
                />
                <StyledContentBox>
                    <MapContainerGrid container>
                        <HideShowMapContainerGrid item xs={12}>
                            <LinkButton
                                testId="toogleMapButton"
                                variant="text"
                                onClick={() => toggleMapChange()}
                            >
                                <HideShowMapLabel id="toggleMap">{showMapText}</HideShowMapLabel>{' '}
                                {showMapIcon}
                            </LinkButton>
                        </HideShowMapContainerGrid>
                        <Grid item xs={12}>
                            <div id="map">
                                <FacilityMap
                                    height={mapHeight}
                                    facilityId={facilityId}
                                    trips={trips.Today}
                                    onSelectMap={onSelectMap}
                                />
                            </div>
                        </Grid>
                    </MapContainerGrid>
                    {
                        optionsTripGroupDTT
                            .map(tripType => (
                                <TripGroupContainer
                                    key={tripType}
                                    tripType={tripType}
                                />
                            ))
                    }
                </StyledContentBox>
            </>
        )
    );

    useEffect(() => {
        initialSetup(facilityId);
    }, [facilityId]);

    return (
        <DPApp>
            {renderLoading()}
            {renderResults()}
        </DPApp>
    );
}

export default Facility;
