import React from 'react';
import { styled } from '@mui/material/styles';

const DivConnector = styled('div')(({ theme }) => ({
    display: 'block',
    borderColor: theme.palette.everDrivenGrey,
    borderTopStyle: 'solid',
    borderTopWidth: 2,
    width: '100%'
}));

function CustomConector() {
    return <DivConnector />;
}

export default CustomConector;