/* eslint-disable max-lines */
import { transportationNeed } from "data/models/transportationRequest/TransportationRequestModel";
import {
    setDisabledCarSeat,
    setDisabledSafetyVest,
    setHasTN,
    setPopulationTypeFormat,
    setPopulationTypeReview,
    setPopulationTypes,
    setShowRemoveStudentNotesModal,
    setShowStudentNotes,
    setTransportationNeedFormat,
    setTransportationNeedReview,
    setTransportationNeeds,
    updateModel
} from "redux/actions/transportationRequest/transportationRequestActions";
import { safetyVest } from "redux/constants";
import { Enum } from "util/EnumHelper";
import { localize } from "util/Localizer";
import { onValidDraftCanBeSaved } from "./transportationRequestDraftWorker";

const addPopulationTypeMKV = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeMKV) {
        pTypes.push(Enum.populationTypes.MKV);
        reviews.push(localize('transportationRequest.MKV'));
    }
};

const addPopulationTypeESE = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeESE) {
        pTypes.push(Enum.populationTypes.ESE);
        reviews.push(localize('transportationRequest.ESE'));
    }
};

const addPopulationTypeESY = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeESY) {
        pTypes.push(Enum.populationTypes.ESY);
        reviews.push(localize('transportationRequest.ESY'));
    }
};

const addPopulationTypeATP = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeATP) {
        pTypes.push(Enum.populationTypes.ATP);
        reviews.push(localize('transportationRequest.ATP'));
    }
};

const addPopulationTypeNCLB = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeNCLB) {
        pTypes.push(Enum.populationTypes.NCLB);
        reviews.push(localize('transportationRequest.NCLB'));
    }
};

const addPopulationTypeRegularEd = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeRegularEd) {
        pTypes.push(Enum.populationTypes.RegularEd);
        reviews.push(localize('transportationRequest.regularEducationService'));
    }
};

const addPopulationTypeMedFragile = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeMedFragile) {
        pTypes.push(Enum.populationTypes.MedFragile);
        reviews.push(localize('transportationRequest.medicallyFragile'));
    }
};

const addPopulationTypeMagnet = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeMagnet) {
        reviews.push(localize('transportationRequest.magnetSchool'));
        pTypes.push(Enum.populationTypes.Magnet);
    }
};

const addPopulationTypeOutofDistrict = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeOutofDistrict) {
        pTypes.push(Enum.populationTypes.OutofDistrict);
        reviews.push(localize('transportationRequest.outOfDistrict'));
    }
};

const addPopulationTypeSPED = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeSPED) {
        pTypes.push(Enum.populationTypes.SPED);
        reviews.push(localize('transportationRequest.SPED'));
    }
};

const addPopulationTypeMultiDistrict = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeMultiDistrict) {
        pTypes.push(Enum.populationTypes.MultiDistrict);
        reviews.push(localize('transportationRequest.multiDistrict'));
    }
};

const addPopulationTypeDeafEd = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeDeafEd) {
        pTypes.push(Enum.populationTypes.DeafEd);
        reviews.push(localize('transportationRequest.deafEducationServices'));
    }
};

const addPopulationTypeSpecialEvents = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeSpecialEvents) {
        pTypes.push(Enum.populationTypes.SpecialEvents);
        reviews.push(localize('transportationRequest.specialEvents'));
    }
};

const addPopulationTypeSchoolofChoice = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeSchoolofChoice) {
        pTypes.push(Enum.populationTypes.SchoolofChoice);
        reviews.push(localize('transportationRequest.schoolOfChoice'));
    }
};

const addPopulationTypeESSA = (populationTypes, pTypes, reviews) => {
    if (populationTypes.populationTypeESSA) {
        pTypes.push(Enum.populationTypes.ESSA);
        reviews.push(localize('transportationRequest.ESSA'));
    }
};

export const formatPopulationType = () => (dispatch, getState) => {
    const { populationTypes } = getState().TransportationRequestReducers;

    const pTypes = [];
    const reviews = [];

    addPopulationTypeMKV(populationTypes, pTypes, reviews);
    addPopulationTypeESE(populationTypes, pTypes, reviews);
    addPopulationTypeESY(populationTypes, pTypes, reviews);
    addPopulationTypeATP(populationTypes, pTypes, reviews);
    addPopulationTypeNCLB(populationTypes, pTypes, reviews);
    addPopulationTypeRegularEd(populationTypes, pTypes, reviews);
    addPopulationTypeMedFragile(populationTypes, pTypes, reviews);
    addPopulationTypeMagnet(populationTypes, pTypes, reviews);
    addPopulationTypeOutofDistrict(populationTypes, pTypes, reviews);
    addPopulationTypeSPED(populationTypes, pTypes, reviews);
    addPopulationTypeMultiDistrict(populationTypes, pTypes, reviews);
    addPopulationTypeDeafEd(populationTypes, pTypes, reviews);
    addPopulationTypeSpecialEvents(populationTypes, pTypes, reviews);
    addPopulationTypeSchoolofChoice(populationTypes, pTypes, reviews);
    addPopulationTypeESSA(populationTypes, pTypes, reviews);

    if (pTypes.length === 0) {
        pTypes.push(Enum.populationTypes.NOT);
    }

    dispatch(setPopulationTypeReview(reviews));
    dispatch(setPopulationTypeFormat(pTypes));
};

const addMonitorNeeded = (transportationNeeds, equipmentNeeds, selectedMonitorNeeded, reviews) => {
    if (transportationNeeds.monitorNeeded) {
        equipmentNeeds.push(Enum.transportationNeeds.monitorNeeded);
        if (selectedMonitorNeeded === Enum.monitorNeeded.districtMonitor) {
            reviews.push(localize('transportationRequest.districtMonitor'));
        } else {
            reviews.push(localize('transportationRequest.everDrivenMonitor'));
        }
    }
};

const addMustRideAlone = (transportationNeeds, equipmentNeeds, reviews) => {
    if (transportationNeeds.mustRideAlone) {
        equipmentNeeds.push(Enum.transportationNeeds.mustRideAlone);
        reviews.push(localize('transportationRequest.mustRideAlone'));
    }
};

const addBooster = (transportationNeeds, equipmentNeeds, reviews) => {
    if (transportationNeeds.boosterSeat) {
        equipmentNeeds.push(Enum.transportationNeeds.booster);
        reviews.push(localize('transportationRequest.boosterSeat'));
    }
};

const addSafetyVest = (transportationNeeds, selectedSafetyVest, equipmentNeeds, reviews) => {
    if (transportationNeeds.safetyVest) {
        equipmentNeeds.push(Enum.transportationNeeds.safetyVest);
        if (selectedSafetyVest) {
            const { acronym } = safetyVest.find(x => x.key === selectedSafetyVest);

            reviews.push(`${localize('transportationRequest.safetyVest')}, ${localize(acronym)}`);
        } else {
            reviews.push(localize('transportationRequest.safetyVest'));
        }
    }
};

const addCarSeat = (transportationNeeds, equipmentNeeds, reviews) => {
    if (transportationNeeds.carSeat) {
        equipmentNeeds.push(Enum.transportationNeeds.carSeat);
        reviews.push(localize('transportationRequest.carSeat'));
    }
};

const addBuckleGuard = (transportationNeeds, equipmentNeeds, reviews) => {
    if (transportationNeeds.buckleGuard) {
        equipmentNeeds.push(Enum.transportationNeeds.buckleGuard);
        reviews.push(localize('transportationRequest.buckleGuard'));
    }
};

const addWheelchair = (transportationNeeds, selectedWheelchair, equipmentNeeds, reviews) => {
    if (transportationNeeds.wheelchair) {
        equipmentNeeds.push(Enum.transportationNeeds.wheelchair);
        switch (selectedWheelchair) {
        case Enum.wheelchairOptions.needsLift:
            reviews.push(
                `${localize('transportationRequest.wheelchair')}, ${localize('transportationRequest.needsLift')}`
            );
            break;
        case Enum.wheelchairOptions.needsRamp:
            reviews.push(
                `${localize('transportationRequest.wheelchair')}, ${localize('transportationRequest.needsRamp')}`
            );
            break;
        default:
            reviews.push(localize('transportationRequest.wheelchair'));
            break;
        }
    }
};

const addHandToHand = (transportationNeeds, equipmentNeeds, reviews) => {
    if (transportationNeeds.handToHand) {
        equipmentNeeds.push(Enum.transportationNeeds.handToHand);
        reviews.push(localize('transportationRequest.handToHand'));
    }
};

export const formatTransportationNeeds = () => (dispatch, getState) => {
    const {
        transportationNeeds,
        tripModel
    } = getState().TransportationRequestReducers;

    const {
        selectedMonitorNeeded,
        selectedSafetyVest,
        selectedWheelchair
    } = tripModel;

    const equipmentNeeds = [];
    const reviews = [];

    addMonitorNeeded(transportationNeeds, equipmentNeeds, selectedMonitorNeeded, reviews);
    addMustRideAlone(transportationNeeds, equipmentNeeds, reviews);
    addBooster(transportationNeeds, equipmentNeeds, reviews);
    addSafetyVest(transportationNeeds, selectedSafetyVest, equipmentNeeds, reviews);
    addCarSeat(transportationNeeds, equipmentNeeds, reviews);
    addBuckleGuard(transportationNeeds, equipmentNeeds, reviews);
    addWheelchair(transportationNeeds, selectedWheelchair, equipmentNeeds, reviews);
    addHandToHand(transportationNeeds, equipmentNeeds, reviews);

    dispatch(setTransportationNeedReview(reviews));
    dispatch(setTransportationNeedFormat(equipmentNeeds));
};

export const onUpdatePopulationType = (evt) => (dispatch) => {
    const { name, type } = evt.target;
    let { value } = evt.target;

    if (type === 'checkbox') {
        value = evt.target.checked;
    }

    dispatch(setPopulationTypes({
        [name]: value
    }));

    dispatch(formatPopulationType());
    dispatch(onValidDraftCanBeSaved());
};

export const onUpdateTransportationNeed = (evt) => (dispatch, getState) => {
    const {
        disabledCarSeat,
        disabledSafetyVest
    } = getState().TransportationRequestReducers;

    const { name, type } = evt.target;
    let { value } = evt.target;

    if (type === 'checkbox') {
        value = evt.target.checked;
    }

    switch (name) {
    case 'monitorNeeded':
        dispatch(updateModel({
            selectedMonitorNeeded: value ? Enum.monitorNeeded.districtMonitor : ''
        }));
        break;
    case 'safetyVest':
        dispatch(updateModel({
            selectedSafetyVest: ''
        }));
        dispatch(setTransportationNeeds({ carSeat: false }));
        dispatch(setDisabledCarSeat(!disabledCarSeat));
        break;
    case 'wheelchair':
        dispatch(updateModel({
            selectedWheelchair: ''
        }));
        break;
    case 'carSeat':
        dispatch(setDisabledSafetyVest(!disabledSafetyVest));

        dispatch(setTransportationNeeds({ safetyVest: false }));
        dispatch(updateModel({ selectedSafetyVest: '' }));
        break;
    default:
        break;
    }

    dispatch(setTransportationNeeds({
        [name]: value
    }));

    dispatch(formatTransportationNeeds());
    dispatch(onValidDraftCanBeSaved());
};

export const setHasTransportationNeeds = (hasTN) => (dispatch) => {
    dispatch(setHasTN(hasTN));

    if(!hasTN) {
        dispatch(setDisabledCarSeat(false));
        dispatch(setDisabledSafetyVest(false));

        const model = {
            selectedMonitorNeeded: '',
            selectedSafetyVest: '',
            selectedWheelchair: ''
        };

        dispatch(updateModel(model));
    
        dispatch(setTransportationNeeds({
            ...transportationNeed
        }));
        dispatch(formatTransportationNeeds());
        dispatch(onValidDraftCanBeSaved());
    }
};

export const onUpdateStudentBirthDate = (evt) => (dispatch) => {
    if (evt) {
        // eslint-disable-next-line function-call-argument-newline
        evt.setHours(0, 0, 0, 0);
        
        dispatch(updateModel({
            studentDateOfBirth: evt
        }));
    } else {
        dispatch(updateModel({
            studentDateOfBirth: null,
            studentAge: ""
        }));
    }

    dispatch(onValidDraftCanBeSaved());
};

export const onRemoveStudentNotes = () => (dispatch) => {
    dispatch(setShowStudentNotes(false));
    dispatch(setShowRemoveStudentNotesModal(false));
    dispatch(updateModel({
        studentNotes: ''
    }));
    dispatch(onValidDraftCanBeSaved());
};