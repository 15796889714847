import React from 'react';
import { styled } from '@mui/material/styles';

import { array, func } from 'prop-types';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import StyledFilterOptions from 'components/Common/StyledFilterOptions/StyledFilterOptions';
import breakpointsNames from 'styles/ResponsiveConstants';
import { localize } from 'util/Localizer';

const MainContentBox = styled('div')(({ theme }) => ({
    paddingLeft: 0,
    float: 'left',
    width: '80%',
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: '100% !important',
        paddingTop: '10px',
        paddingBottom: '10px',
        display: 'none'
    }
}));

const ActionContainerBox = styled('div')(({ theme }) => ({
    paddingRight: 0,
    width: '20%',
    float: 'left',
    [theme.breakpoints.down(breakpointsNames.md)]: {
        display: 'none !important'
    }
}));

const FilterActionBox = styled('div')(({ theme }) => ({
    marginTop: 15,
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        '& > .MuiButton-text': {
            padding: '3px 5px',
            fontSize: 12
        }
    },
    float: 'right',
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        display: 'none !important'
    }
}));

const AlignRightButton = styled(StyledButton)(({ theme }) => ({
    float: 'right',
    margin: theme.spacing(0.3)
}));

function FilterOptions({
    listOptions,
    handleChange,
    onShowDeleteModal
}) {
    const handleDeleteBatch = (event) => {
        event.preventDefault();
        onShowDeleteModal();
    };

    const renderFilters = () => (
        <StyledFilterOptions
            handleChange={handleChange}
            listOptions={listOptions}
        />
    );

    return (
        <div>
            <MainContentBox>
                {renderFilters()}
            </MainContentBox>
            <ActionContainerBox>
                <FilterActionBox>
                    <AlignRightButton
                        color="primary"
                        testId="deleteUsersButton"
                        variant="contained"
                        onClick={handleDeleteBatch}>
                        {localize('userManagement.delete')}
                    </AlignRightButton>
                </FilterActionBox>
            </ActionContainerBox>
        </div>
    );
}

FilterOptions.defaultProps = {
    listOptions: [],
    handleChange: () => null,
    onShowDeleteModal: () => null
};

/* eslint-disable react/forbid-prop-types */
FilterOptions.propTypes = {
    listOptions: array,
    handleChange: func,
    onShowDeleteModal: func
};
/* eslint-enable react/forbid-prop-types */

export default FilterOptions;
