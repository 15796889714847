/* eslint-disable max-lines */

import {
    PublicClientApplication,
    Logger,
    LogLevel,
    InteractionRequiredAuthError
} from "@azure/msal-browser";
import Env from '../Env';
import LoggingInit from '../util/logging';
// eslint-disable-next-line import/no-cycle
import mrmFetch from "../util/fetchUtil";

// we need to set logging up as soon as possible
LoggingInit();

const TOKEN_KEY = 'authToken';
const NAME_KEY = 'authName';
const FULLNAME_KEY = 'fullProperName';
const FORCE_RESET = 'authForce';
const SESSION_ID = 'sessionId';
const PROFESSIONAL_KEY = 'professionalId';
const TRIPTRACKINGACCESS_KEY = 'tripTrackingAccess';
const PROGRAMMANAGEMENTACCESS_KEY = 'programManagementAccess';
const BILLINGACCESS_KEY = 'billingAccess';
const ADMINISTRATORACCESS_KEY = 'administratorAccess';
const CONFIG_KEY = 'config';
const loginRedirectUri = `${window.location.origin}/redirect`;

const formatStringValidation = (value) => {
    let result;

    if (`${value}`.length > 0) {
        result = `${value}`.toLowerCase();
    }

    return result;
};

const loggerCallback = (logLevel, message) => {
    window.info(`Auth: [${logLevel}]: ${message}`);
};
const logger = new Logger(
    loggerCallback,
    {
        correlationId: '1234', /* This is set to get around a bug in the framework where
                                if you don't set it, no authentication can occur, don't
                                believe me? comment it out :) */
        level: LogLevel.Info,
        piiLoggingEnabled: true
    });

const authority = `https://${Env.auth.authoritySub}.b2clogin.com/${Env.auth.authoritySub}.onmicrosoft.com/${Env.auth.signInFlow}`;

const msalApp = new PublicClientApplication({
    auth: {
        clientId: Env.auth.clientId,
        authority,
        redirectUri: loginRedirectUri,
        knownAuthorities: [authority],
        postLogoutRedirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    },
    system: {
        logger
    }
});

const req = {
    scopes: [Env.auth.scope]
};

const errorHandler = async (error, fnlogout) => {
    if (error.message === "User login is required.") {
        window.info("User is not logged in");
        await fnlogout();
    } else if (error.message.indexOf("User does not have an existing session") !== -1) {
        window.warn('Session Expired, sending to login page');
        await fnlogout();
        // Should be "msalApp.logout();", if cookies are not blocked... but we can't tell.
    } else if (error.message.indexOf("Token calls are blocked in hidden iframes") !== -1) {
        // This is a "feature" of the MSAL library and we can safely ignore it
        // NOOP
    } else if (error.message.indexOf("monitor_window_timeout: Token " +
        "acquisition in iframe failed due to timeout.") !== -1) {
        window.info("Time Out - long time without interaction");
        await fnlogout();
    } else {
        // Sometimes with login issues it happens before the logging has been init so we'll just use console.log here.
        // eslint-disable-next-line no-console
        console.log("[MSAL GetToken]ERROR: ",
            error);
        await fnlogout();
    }
};

const Auth = {
    async login(
        success,
        forbidden,
        loading
    ) {
        loading();
        try {
            await msalApp.initialize();
            const tokenResponse = await msalApp.handleRedirectPromise();
            let account;

            if (tokenResponse !== null) {
                account = tokenResponse.account;

                await this.continue(tokenResponse, success, forbidden);
            } else {
                const currentAccounts = msalApp.getAllAccounts();

                account = currentAccounts.length > 0 ? currentAccounts[0] : null;

                if (account === null) {
                    window.warn("[login] No account or tokenResponse present. User must now login.");
                    await msalApp.loginRedirect(req);
                }

                await this.tokenRenewal(success, forbidden);
            }

            if (account?.idTokenClaims?.tfp === Env.auth.resetFlow) {
                window.info("[ResetPassword] Force logout after a succesful change password flow");
                await msalApp.logoutRedirect();
            }

            if (account?.idTokenClaims?.isForgotPassword) {
                window.info("[ForgotPassword] Force logout after a succesful forgot password flow");
                await msalApp.logoutRedirect();
            }
        } catch (error) {
            if (error.message.indexOf('AADB2C90118') !== -1) {
                window.info('i am redirecting cause password reset');
                await this.resetPassword();
            } else if (error.message.indexOf('AADB2C90091') !== -1) {
                window.warn("User cancelled a password reset, but msal has a " +
                    "bug so were sending them to the homescreen");
                await msalApp.loginRedirect(req);
            } else if (error.message.indexOf('AADB2C90182') !== -1) {
                window.warn("There are 2 tabs/windows with the page login openned, " +
                    "This is not allowed by msal, close one");
                await msalApp.loginRedirect(req);
            } else if (error.message.indexOf('interaction_in_progress') !== -1) {
                window.warn("Interaction in progress error");
                await this.removeToken();
                window.log('hard deleting the msal cookie');
                const cookieName = ' msal.interaction.status';

                if (document.cookie.indexOf(cookieName) !== -1) {
                    window.log('deleting cookie', document.cookie);
                    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
                }
                document.location = "/";
            } else {
                window.warn("[login] Failed to handleRedirectPromise()",
                    error);
                document.location = "/";
            }
        }
    },
    async continue(token, success, forbidden) {
        if (!success) {
            await this.postLogin(token);

            return;
        }

        if (token?.idTokenClaims?.extension_IsDistrict) {
            await this.postLogin(token);
            success();
        } else {
            forbidden();
        }
    },
    async removeToken() {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(NAME_KEY);
        localStorage.removeItem(FULLNAME_KEY);
        localStorage.removeItem(PROFESSIONAL_KEY);
        localStorage.removeItem(FORCE_RESET);
        localStorage.removeItem(SESSION_ID);
        localStorage.removeItem(TRIPTRACKINGACCESS_KEY);
        localStorage.removeItem(PROGRAMMANAGEMENTACCESS_KEY);
        localStorage.removeItem(BILLINGACCESS_KEY);
        localStorage.removeItem(ADMINISTRATORACCESS_KEY);
        localStorage.removeItem(CONFIG_KEY);
    },

    async logoutAfterError() {
        await msalApp.logoutRedirect();
        // TODO is this right?
        // tokenRenewal --> errorHandler --> logoutAfterError --> logoutRedirect --> tokenRenewal (?)
        await this.tokenRenewal();
    },

    async tokenRenewal(success, forbidden) {
        let tokenRenewal = null;

        try {
            window.info("[login] Trying to renew token.");
            tokenRenewal = await msalApp.acquireTokenSilent({
                account: msalApp.getAllAccounts()[0],
                scopes: req.scopes
            });
            await this.continue(tokenRenewal, success, forbidden);
            window.info("[login] Token renewed.");
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                tokenRenewal = await msalApp.acquireTokenRedirect(req);
                await this.continue(tokenRenewal, success, forbidden);
            } else {
                await errorHandler(error,
                	this.logoutAfterError);
            }
        }
    },

    async postLogin(token) {
        const res = await window.fetch(Env.configUrl,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token.accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
        const text = await res.text();

        if (!text) {
            window.Error('error occured attempting to log in, signing out');
            this.signout();
        }
        try {
            const json = JSON.parse(text);
            const {systemConfig} = json;

            window.Config = Object.assign(window.Config,
            	Env,
                systemConfig);
            window.debug = window.Config.logDebug || false;
            window.document.title = window.Config.title;
            window.Config.facilities = json.schools;
            window.Config.clientProfiles = json.clientProfiles;
            window.Config.parentAppEnabled = systemConfig.parentAppEnabled || false;
            window.Config.useNewTransportationRequestForm = systemConfig.useNewTransportationRequestForm || false;
            window.Config.useNewTripTracker = systemConfig.useNewTripTracker || false;
            window.Config.teacherAppEnabled = systemConfig.teacherAppEnabled !== false ?
                true : systemConfig.teacherAppEnabled;
            window.Config.configLoaded = true;
            const configToStore = JSON.stringify({ ...window.Config });

            localStorage.setItem(CONFIG_KEY,
            	configToStore);
        } catch (ex) {
            const message = `error attempting to process config response,
                            signing out - error:  ${ex.name}, message: ${ex.message}`;

            window.Error(message);
            this.signout();
        }
        const name = token.idTokenClaims.emails[0];

        this.setName(name);
        let fullName = null;

        if (token.idTokenClaims.given_name) {
            fullName = token.idTokenClaims.given_name;
        }

        if (token.idTokenClaims.family_name) {
            if (fullName) {
                fullName = `${fullName} ${token.idTokenClaims.family_name}`;
            } else {
                fullName = token.idTokenClaims.family_name;
            }
        }

        if (!fullName) {
            fullName = name;
        }

        this.setFullName(fullName);
        this.setToken(token.accessToken);
        this.setProfessionalAgent(token.idTokenClaims.extension_IsProfessionalAgent);
        this.setTripTrackingAccess(token.idTokenClaims.extension_CanAccessTripTracking);
        this.setProgramManagementAccess(token.idTokenClaims.extension_CanAccessProgramManagement);
        this.setBillingAccess(token.idTokenClaims.extension_CanAccessBilling);
        this.setUserAccess(token.idTokenClaims.extension_IsAdministrator);
    },

    getFullName: () => localStorage.getItem(FULLNAME_KEY),
    setFullName: (name) => localStorage.setItem(FULLNAME_KEY,
        name),
    getName: () => localStorage.getItem(NAME_KEY),
    setName: (name) => localStorage.setItem(NAME_KEY,
        name),

    hasUserAccess: () => {
        let haa = localStorage.getItem(ADMINISTRATORACCESS_KEY);

        haa = formatStringValidation(haa);

        return haa === 'true';
    },

    setUserAccess: (value) => {
        let haa = value;

        haa = formatStringValidation(haa);
        localStorage.setItem(ADMINISTRATORACCESS_KEY,
            haa);
    },

    hasBillingAccess: () => {
        let hba = localStorage.getItem(BILLINGACCESS_KEY);

        hba = formatStringValidation(hba);

        return hba === 'true';
    },

    setBillingAccess: (value) => {
        let hba = value;

        hba = formatStringValidation(hba);
        localStorage.setItem(BILLINGACCESS_KEY,
            hba);
    },

    hasProgramManagementAccess: () => {
        let hpma = localStorage.getItem(PROGRAMMANAGEMENTACCESS_KEY);

        hpma = formatStringValidation(hpma);

        return hpma === 'true';
    },

    setProgramManagementAccess: (value) => {
        let hpma = value;

        hpma = formatStringValidation(hpma);
        localStorage.setItem(PROGRAMMANAGEMENTACCESS_KEY,
            hpma);
    },

    hasTripTrackingAccess: () => {
        let htta = localStorage.getItem(TRIPTRACKINGACCESS_KEY);

        htta = formatStringValidation(htta);

        return htta === 'true';
    },

    setTripTrackingAccess: (value) => {
        let htta = value;

        htta = formatStringValidation(htta);
        localStorage.setItem(TRIPTRACKINGACCESS_KEY,
            htta);
    },

    isProfessionalAgent: () => {
        let ipa = localStorage.getItem(PROFESSIONAL_KEY);

        ipa = formatStringValidation(ipa);

        return ipa === 'true';
    },
    setProfessionalAgent: (value) => {
        let ipa = value;

        ipa = formatStringValidation(ipa);
        localStorage.setItem(PROFESSIONAL_KEY,
            ipa);
    },

    getToken: () => localStorage.getItem(TOKEN_KEY),
    setToken: (token) => localStorage.setItem(TOKEN_KEY,
        token),

    resetPassword: async () => {
        await msalApp.loginRedirect({
            authority: `https://${Env.auth.authoritySub}.b2clogin.com/${Env.auth.authoritySub}.onmicrosoft.com/${Env.auth.resetFlow}`
        });
    },

    signout: async () => {
        window.info('I am logging out, time to leave groups');

        try {
            const response = await mrmFetch('/api/v2/Users/WebSubscriptions',
        	    'delete',
                null,
                null,
                false,
                true);

            if (response === 200) {
                window.info('unsubscribed from all signalR subscriptions');
            } else {
                window.warn('There was an error executing WebSubscriptions Api');
            }
        } catch (error) {
            window.warn(`There was an error executing WebSubscriptions Api: ${error}`);
        }

        await Auth.removeToken();

        // eslint-disable-next-line no-console
        console.log("As we are signing out, origin for redirect: ", window.location.origin);
        await msalApp.logoutRedirect();
    },

    getConfig: () => JSON.parse(localStorage.getItem(CONFIG_KEY)),

    getAccounts() {
        if (!Auth.getConfig()?.clientProfiles) {
            return [];
        }

        const accounts = Auth.getConfig().clientProfiles.find(a => a.keyType).payload.Accounts;

        return accounts.filter(account => account.DeleteFlag !== true);
    },

    getMrmClient() {
        return Auth.getConfig().clientProfiles.find(a => a.keyType).mrmClient;
    }

};

export default Auth;
