import React from "react";
import { makeStyles } from '@mui/styles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { localize } from '../../util/Localizer';
import StyledTable from "../Common/StyledTable/StyledTable";
import StyledTableBody from "../Common/StyledTableBody/StyledTableBody";
import StyledTableCell from "../Common/StyledTableCell/StyledTableCell";
import StyledTableHead from "../Common/StyledTableHead/StyledTableHead";
import StyledTableRow from "../Common/StyledTableRow/StyledTableRow";
import StyledEmptyTable from "../Common/StyledEmptyTable/StyledEmptyTable";
import breakpointsNames from "../../styles/ResponsiveConstants";
import StudentFileRowContainer from "../../redux/containers/studentUpload/StudentFileRowContainer";

const useStyles = makeStyles((theme) => ({
    rejectedIcon: {
        color: theme.palette.everDrivenFuchsia
    },
    rejectedRow: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    },
    rejectedFileName: {
        paddingTop: 5,
        marginLeft: 5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            width: 700
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            width: 400,
            paddingTop: 0
        },
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            width: 200
        }
    },
    rejectedCellStyle: {
        width: 400,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            width: 700
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            width: 400
        },
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            width: 200
        }
    }
}));

function StudentFileList({ files, rejectedFiles }) {
    const classes = useStyles();

    if (files.length === 0 && rejectedFiles.length === 0) {
        return (
            <StyledEmptyTable message={localize('calendarUpload.filesEmpty')} />
        );
    }

    return (
        <StyledTable>
            <StyledTableHead>
                <StyledTableRow>
                    <StyledTableCell isHeader>
                        {localize('studentUpload.fileName')}
                    </StyledTableCell>
                    <StyledTableCell isHeader>
                        {localize('studentUpload.fileSize')}
                    </StyledTableCell>
                    <StyledTableCell />
                </StyledTableRow>
            </StyledTableHead>
            <StyledTableBody>
                {files.map((file, index) => {
                    const keyValue = `student.file.name_${index}`;

                    return (
                        <StudentFileRowContainer
                            row={file}
                            nRow={index}
                            key={keyValue}
                        />
                    );
                })}
                {rejectedFiles.map((rejected, index) => {
                    const keyValue = `rejectedFile.name_${index}`;

                    return (
                        <StyledTableRow key={keyValue}>
                            <StyledTableCell extraCssClass={classes.rejectedCellStyle} colSpan="4">
                                <div className={classes.rejectedRow}>
                                    <HighlightOffIcon className={classes.rejectedIcon} />
                                    <div className={classes.rejectedFileName}>
                                        {rejected.file.name}
                                    </div>
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>
                    );
                })}
            </StyledTableBody>
        </StyledTable>
    );
}

export default StudentFileList;