import {
    tripGroupSetGuid,
    tripGroupSetIsFacility
} from 'redux/actions/tripGroupActions';
import {
    internalLoad,
    facilityIsLoading,
    facilitySelected,
    facilitySelectedToggleMap,
    facilitySelectedId
} from 'redux/actions/districtTripTracker/facilityActions';
import { abbrState } from 'util/textFormatters';
import Auth from 'auth/Auth';

// -------- Load Facilities
const setAddress = (f) => (
    `${f.address1}${f.address2 ?? ''}, ${f.city}, ${abbrState(f.state)} ${f.zip ?? ''}`
);

const mapFacilities = facilities => facilities.map(f => ({
    id: f.id,
    name: f.name,
    address: setAddress(f),
    timeZone: f.timeZone,
    phone: f.phoneNumber,
    coordinates: { lat: f.lat, lng: f.lng },
    activeFlag: f.activeFlag
}));

export const checkFacilities = (facilityId) => dispatch => {
    dispatch(facilityIsLoading(true));

    const facilityList = mapFacilities(Auth.getConfig().facilities);
    const facility = facilityList.find(fac => fac.id === facilityId);

    if (!facility) {
        window.warn(
            `Attempt to view a school with the id of ${facilityId} which they cannot see, sending to 404`
        );
        document.location = '/error';
    } else {
        dispatch(facilitySelected(facility));
        dispatch(internalLoad(facilityList));
        dispatch(facilitySelectedId(facilityId));
    }
};

export const loadFacilities = () => dispatch => {
    const facilityList = mapFacilities(Auth.getConfig().facilities);

    dispatch(internalLoad(facilityList));
};

// -------- Initialization

export const initialSetup = facilityId => dispatch => {
    dispatch(tripGroupSetIsFacility(true));

    dispatch(checkFacilities(facilityId));
    dispatch(tripGroupSetGuid(facilityId));
    
    dispatch(facilityIsLoading(false));
};

// -------- Toggle

export const toggleMapChange = () => (dispatch, getState) => {
    const { toggleMap } = getState().FacilityReducers;

    dispatch(facilitySelectedToggleMap(!toggleMap));
};
