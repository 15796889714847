import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { TablePagination } from '@mui/material';
import {
    string,
    func,
    number
} from 'prop-types';
import useWidth from 'util/customHooks/useWidth';
import { breakpointsWithoutMobile } from "util/EnumHelper";

const INITIAL_PAGE = 0;
const PARSE_INT = 10;
const ROWS_PER_PAGE = [ 10, 20, 50, 100 ];

const StyledPagination = styled(TablePagination)(({theme}) => ({
    '& .MuiTablePagination-actions > button': {
        color: theme.palette.everDrivenCharcoal
    },
    '& .MuiTablePagination-actions > button:disabled': {
        color: theme.palette.everDrivenDarkCharcoal60
    }
}));

function StyledTablePagination({
    component,
    limitRows,
    activePage,
    setPage,
    rowsShowed,
    setPageSize,
    labelRowsPerPage
}) {
    const [ rowsPerPage, setRowsPerPage ] = useState([]);
    const width = useWidth();

    const handleChangePage = (event, newPage) => (
        setPage(newPage)
    );

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, PARSE_INT));
        setPage(INITIAL_PAGE);
    };

    useEffect(() => {
        if(breakpointsWithoutMobile.includes(width)) {
            setRowsPerPage(ROWS_PER_PAGE);
        } else {
            setRowsPerPage([]);
        }
    }, [width]);

    return (
        <StyledPagination
            rowsPerPageOptions={rowsPerPage}
            component={component}
            count={limitRows}
            page={activePage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsShowed}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={labelRowsPerPage}
        />
    );
}

StyledTablePagination.defaultProps = {
    component: 'div',
    limitRows: 100,
    activePage: 1,
    setPage: () => null,
    setPageSize: () => null,
    rowsShowed: 10,
    labelRowsPerPage: ''

};

StyledTablePagination.propTypes = {
    component: string,
    limitRows: number,
    activePage: number,
    setPage: func,
    setPageSize: func,
    rowsShowed: number,
    labelRowsPerPage: string
};

export default StyledTablePagination;