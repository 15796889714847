/* eslint max-lines: 0 */
import React, { Fragment, useEffect, useState } from 'react';
import { AppBar, Toolbar, Box, Grid } from '@mui/material';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { init } from 'redux/workers/signalRWorker';

import { updatePath, setTab } from '../redux/workers/pathWorker';
import { paths } from '../util/EnumHelper';
import Auth from '../auth/Auth';
import { getLanguage, localizeImg } from '../util/Localizer';
import MainMenu from '../controls/Layout/MainMenu';
import TopMenu from '../controls/Layout/TopMenu';
import ContactDetail from '../controls/Layout/ContactDetail';
import { rightMenu } from '../util/dynamicMenu';
import breakpointsNames from '../styles/ResponsiveConstants';

import IdleTimer from './IdleTimerComponent';
import StyledButton from './Common/StyledButton/StyledButton';
import StyledTypography from './Common/StyledTypography/StyledTypography';
import withRouter from '../util/customHooks/withRouter';

const logo = localizeImg('/img/logo.png');

const setBoxByLanguage = (language) => {
    if (language === 'en') {
        return 430;
    }

    return 550;
};

const setDisplayBoxContentByLanguage = (language, theme) => {
    if (language === 'en') {
        return {
            [theme.breakpoints.up(breakpointsNames.md)] : {
                display: 'block'
            },
            [theme.breakpoints.down(breakpointsNames.md)] : {
                display: 'none'
            }
        };
    }

    return {
        [theme.breakpoints.up(breakpointsNames.lg)] : {
            display: 'block'
        },
        [theme.breakpoints.down(breakpointsNames.lg)] : {
            display: 'none'
        }
    };
};

const HeaderRootTypography = styled(StyledTypography)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.black,
    fontWeight: 'bold'
}));

const HeaderMenuBox = styled(Box)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down(breakpointsNames.sm)] : {
        display: 'block'
    },
    [theme.breakpoints.only(breakpointsNames.sm)]: {
        display: 'none',
        "@media only screen and (orientation: landscape)": {
            display: 'block'
        }
    }
}));

const TabBox = styled(Box)((props) => {
    const isTrueSet = /^true$/i.test(props?.menustatus);
    const hideMobileSize = {
        display: 'block',
        padding: '0 !important',
        [props.theme.breakpoints.down(breakpointsNames.sm)]: {
            display: 'none'
        },
        [props.theme.breakpoints.only(breakpointsNames.sm)]: {
            display: 'block',
            "@media only screen and (orientation: landscape)": {
                display: 'none'
            }
        }
    };

    const tabBox = {
        marginLeft: 10,
        '& > h3': {
            marginTop: 10
        },
        [props.theme.breakpoints.down(breakpointsNames.sm)]: {
            marginRight: 40,
            '& > h3': {
                fontSize: 18
            }
        }
    };

    return {
        ...tabBox,
        ...(isTrueSet && hideMobileSize)
    };
});

const RightBox = styled(Box)(({ theme }) => ({
    display: 'block',
    padding: '0 !important',
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        display: 'none'
    },
    [theme.breakpoints.only(breakpointsNames.sm)]: {
        display: 'block',
        "@media only screen and (orientation: landscape)": {
            display: 'none'
        }
    },
    maxWidth: props => setBoxByLanguage(props.language),
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: 10,
    color: theme.palette.black,
    textAlign: 'left'
}));

const LogoButton = styled('button')((props) => {
    const isTrueSet = /^true$/i.test(props?.menustatus);
    const hideMobileSize = {
        display: 'block',
        padding: '0 !important',
        [props.theme.breakpoints.down(breakpointsNames.sm)]: {
            display: 'none'
        },
        [props.theme.breakpoints.only(breakpointsNames.sm)]: {
            display: 'block',
            "@media only screen and (orientation: landscape)": {
                display: 'none'
            }
        }
    };

    const centerLogo = {
        [props.theme.breakpoints.down(breakpointsNames.sm)]: {
            marginLeft: -40,
            width: '100%'
        },
        [props.theme.breakpoints.only(breakpointsNames.sm)]: {
            "@media only screen and (orientation: landscape)": {
                marginLeft: -40,
                width: '100%'
            }
        },
        padding: '0 !important'
    };

    return isTrueSet ? centerLogo : hideMobileSize;
});

const HeaderAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    top: 0,
    zIndex: 100
}));

const HeaderToolbar = styled(Toolbar)(() => ({
    height: 80,
    minWidth: 360
}));

const ButtonLink = styled(StyledButton)(({ theme }) => ({
    color: theme.palette.everDrivenDarkGrey,
    fontWeight: 500,
    padding: 0,
    margin: 0,
    minWidth: 0,
    textTransform: 'none',
    '&.Mui-disabled': {
        color: theme.palette.white
    }
}));

const Separator = styled(Box)(() => ({
    marginLeft: 10,
    marginRight: 10,
    textAlign: 'center'
}));

const NameSpan = styled(Box)(() => ({
    fontWeight: 'bold',
    display: 'block',
    textOverflow:'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
}));

const HideBoxContent = styled(Box)((props) => () => setDisplayBoxContentByLanguage(props.language, props.theme)
);

function Header({
    updatePath: path,
    setTab: tab,
    tabValue,
    pathname,
    init: start,
    signalR
}) {
    const language = getLanguage();

    const [ menuStatus, setMenuStatus ] = useState(false);
    const [ disableLogOut, setDisableLogOut ] = useState(false);
    const [ contactPhoneNumber, setContactPhoneNumber ] = useState('');
    const [ contactEmail, setContactEmail ] = useState('');
    const [ openContactModal, setOpenContactModal ] = React.useState(false);

    const handleOpenContactModal = () => {
        setOpenContactModal(true);
    };

    const handleCloseContactModal = () => {
        setOpenContactModal(false);
    };

    const handleClickEvent = (menuItem) => {
        switch (menuItem.id) {
        case paths.Contact.tabValue:
            handleOpenContactModal();
            break;
        case paths.LogOut.tabValue:
            setDisableLogOut(!disableLogOut);
            path(menuItem.id);
            break;
        default:
            path(menuItem.id);
            break;
        }
    };

    const dynMenu = rightMenu();

    useEffect(() => {
        if (!signalR) {
            start();
        }

        const accounts = Auth.getAccounts();
        const phoneNumbers = accounts.map(x => x.PhoneNumber).filter(x => x !== '');
        const emails = accounts.map(x => x.Email).filter(x => x !== '');

        if (phoneNumbers.length > 0) {
            setContactPhoneNumber(phoneNumbers[0] ?? '');
        }

        if (emails.length > 0) {
            setContactEmail(emails[0] ?? '');
        }
    }, []);

    return (
        <IdleTimer timeout={(Auth.getConfig().idleTimeoutInSeconds || 3600) * 1000}>
            <HeaderAppBar enableColorOnDark onLoad={() => Auth.getFullName()} position="fixed">
                <HeaderToolbar>
                    <HeaderMenuBox>
                        <MainMenu
                            disableLogOut={disableLogOut}
                            edge="end"
                            userName={Auth.getFullName()}
                            phoneNumber={contactPhoneNumber}
                            email={contactEmail}
                            onClickMenu={(isOpen) => { setMenuStatus(isOpen); }}
                            onClickActionMenuItem={handleClickEvent}
                            updatePath={path}
                            setTab={tab}
                            tabValue={tabValue}
                            pathname={pathname}
                        />
                    </HeaderMenuBox>
                    <LogoButton menustatus={menuStatus?.toString()}
                        type="button"
                        className="linkButton App-logo"
                        id="EverDrivenLogoButton"
                        onClick={() => path(paths.Home.tabValue)}
                    >
                        <img
                            src={logo}
                            alt="Logo"
                            height="50"
                        />
                    </LogoButton>
                    <TabBox menustatus={menuStatus?.toString()}
                        flexGrow={1}
                        id="DistrictNameContent">
                        <HeaderRootTypography>
                            {Auth.getConfig().districtName}
                        </HeaderRootTypography>
                    </TabBox>
                    <RightBox>
                        <TopMenu
                            language={language}
                            userName={Auth.getFullName()}
                            onMenuItemClick={handleClickEvent}
                            disableLogOut={disableLogOut}
                        />
                        <HideBoxContent language={language}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <NameSpan
                                        component="span"
                                        className="qm_block"
                                        id="UserDisplayName"
                                    >
                                        { Auth.getFullName() }
                                    </NameSpan>
                                </Grid>
                            </Grid>

                            {dynMenu.map(x => (
                                <Fragment key={x.id}>
                                    <ButtonLink
                                        color="inherit"
                                        isDisabled={x.id === paths.LogOut.tabValue && disableLogOut}
                                        variant="text"
                                        onClick={x.path ? null : () => handleClickEvent(x)}
                                        href={x.path ?? null}
                                        target={x.id === paths.UserGuide.tabValue ? '_blank' : null}
                                        testId={`contentRightMenu_${x.id}`}
                                    >
                                        {x.label}
                                    </ButtonLink>
                                    {x.id !== paths.LogOut.tabValue
                                        ? <Separator component="span">|</Separator>
                                        : null
                                    }
                                </Fragment>
                            ))}
                        </HideBoxContent>
                    </RightBox>
                </HeaderToolbar>
            </HeaderAppBar>
            <ContactDetail
                open={openContactModal}
                email={contactEmail}
                phoneNumber={contactPhoneNumber}
                onClose={handleCloseContactModal}
            />
        </IdleTimer>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        signalR: state.SignalRReducers.signalR,
        theme: ownProps.theme,
        tabValue: state.PathReducers.activeItem,
        pathname: state.router.location.pathname
    };
}

const HeaderContainer = withRouter(
    connect(mapStateToProps,
        {
            init,
            updatePath,
            setTab
        })(Header)
);

export default HeaderContainer;
