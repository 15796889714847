import { StatePostalAbv } from "./EnumHelper";

export const formatPhoneNumber = str => {
    if (str && str.length === 10) {
        return `(${str.substring(0,
            3)}) ${str.substring(3,
            6)}-${str.substring(
            6
        )}`;
    }

    if (str && str.length === 12) {
        return `(${str.substring(2,
            5)}) ${str.substring(5,
            8)}-${str.substring(
            8
        )}`;
    }

    return str || '';
};

export const toPascalCase = string => string.replace(/\w+/g,
    (w) => w[0].toUpperCase() + w.slice(1).toLowerCase());

// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = email => {
    if (email) {
        return !email.match(EMAIL_REGEX);
    }

    return true;
};

export const stringValidate = (value) => (!value || value?.trim().length === 0);

export const fixedEncodeURIComponent = (str) => encodeURIComponent(str).replace(/[!'()]/g, (c) => `%${c.charCodeAt(0).toString(16)}`).replaceAll("*", "%2A");

export const toUpperFirstLetter = string => {
    if (!string) {
        return "";
    }
    
    const arr = string.split(" ");

    for (let i = 0; i < arr.length; i += 1) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    }

    return arr.join(" ");
};

export const toCamelCase = string => string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g,
    (match, index) => {
        if (+match === 0) {
            return "";
        }
        
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    }
);

export const abbrState = (name) => {
    const state = toCamelCase(name);
    
    if (StatePostalAbv[state] !== null) {
        return StatePostalAbv[state];
    }

    return name;
};

export const formatSingleLineAddress = address => {
    const name = address.Name ? `${address.Name}, ` : '';
    const address1 = address.Address1 ? `${address.Address1}` : '';
    const address2 = address.Address2 ? ` ${address.Address2}` : '';
    const city = address.City ? `, ${address.City}` : '';
    const state = address.State ? `, ${abbrState(address.State)}` : '';
    const zip = address.Zip ? ` ${address.Zip}` : '';

    const ret = `${name}${address1}${address2}${city}${state}${zip}`;

    return ret;
};