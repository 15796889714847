import { connect } from 'react-redux';
import SchoolInformation from '../../../controls/TripRequestForm/SchoolInformation';
import {
    onUpdateModel
} from '../../workers/tripRequestFormWorker';
import {
    onSelectSchool,
    onUpdateSchoolDays,
    onSelectSchoolAddressInfo,
    onSelectSchoolAddressLine2Info,
    onUpdateSchoolAddressLine1,
    onUpdateSchoolAddressLine2,
    onUpdateSchoolPhone,
    onUpdateSchedule,
    onCopyTime,
    setIsSameSchool
} from '../../workers/tripRequestFormSchoolWorker';
import { setScheduleValid } from '../../actions/tripRequestFormActions';

const mapStateToProps = state => {
    const { TripRequestFormReducers } = state;

    return {
        tripModel: TripRequestFormReducers.tripModel,
        schoolDays: TripRequestFormReducers.schoolDays,
        schedule: TripRequestFormReducers.schedule,
        enabledSchoolInputs: TripRequestFormReducers.enabledSchoolInputs,
        currentSibling: TripRequestFormReducers.currentSibling,
        scheduleValid: TripRequestFormReducers.scheduleValid,
        isSameSchool: TripRequestFormReducers.isSameSchool,
        selectedSchool: TripRequestFormReducers.selectedSchool,
        selectedSchoolAddress: TripRequestFormReducers.selectedSchoolAddress,
        selectedSchoolAddress2: TripRequestFormReducers.selectedSchoolAddress2
    };
};

const SchoolInformationContainer = connect(mapStateToProps,
    {
        onCopyTime,
        onSelectSchool,
        onSelectSchoolAddressInfo,
        onSelectSchoolAddressLine2Info,
        onUpdateModel,
        onUpdateSchedule,
        onUpdateSchoolDays,
        onUpdateSchoolAddressLine1,
        onUpdateSchoolAddressLine2,
        onUpdateSchoolPhone,
        setIsSameSchool,
        setScheduleValid
    })(SchoolInformation);

export default SchoolInformationContainer;
