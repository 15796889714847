import { connect } from 'react-redux';
import { initialSetup } from 'redux/workers/districtTripTracker/studentWorker';
import Student from 'components/Student';
import Auth from 'auth/Auth';
import withRouter from 'util/customHooks/withRouter';

const mapStateToProps = (state, ownProps) => {
    const { StudentReducers } = state;

    return {
        router: ownProps.router,
        memberId: ownProps.router.params.memberId,
        navigate: ownProps.router.navigate,
        hasManagementAccess: Auth.hasProgramManagementAccess(),
        isLoading: StudentReducers.isLoading,
        memberName: StudentReducers.student,
        memberCode: StudentReducers.studentCode
    };
};

const mapDispatchToProps = {
    initialSetup
};

const StudentContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Student)
);

export default StudentContainer;
