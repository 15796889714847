import { IntlProvider } from 'react-intl';
import enMessages from '../intl/en.json';
import esMessages from '../intl/es.json';
import { objectEmptyValidator } from './validationUtil';

export function localize(incomingKey, paramValues = {}) {
    // Different browsers use different methods of getting the language
    const language = (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;

    // Split locales with a region code
    const locale = language.toLowerCase().split(/[_-]+/)[0];

    const messages = locale === 'en' ? enMessages : esMessages;
    const intlProvider = new IntlProvider({ locale, messages },
        {});
    const { intl } = intlProvider.state;

    if (objectEmptyValidator(paramValues)) {
        return intl.formatMessage({ id: incomingKey });
    }

    return intl.formatMessage({ id: incomingKey }, paramValues);
}

export function localizeImg(key) {
    const language = (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;

    // Split locales with a region code
    const locale = language.toLowerCase().split(/[_-]+/)[0];

    // If you update logo.png, also update the query string below
    // which means: increment the "v=#" value to ensure browser cache refreshes
    const ret = key.replace('.png',
        `_${locale}.png?v=1`);

    return ret;
    // return require(ret);
    // return img;
}

export const getLanguage = () => {
    const language =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;

    return language.toLowerCase().split(/[_-]+/)[0];
};
