import {
    TRACK_TRIP_SET_IS_LOADING,
    TRACK_TRIP_SET_TRIP,
    TRACK_TRIP_SET_TRIP_TYPE
} from 'redux/actions/trackTripActions';

import { optionsTripGroup } from 'util/EnumHelper';

const initialState = {
    trip: null,
    tripType: optionsTripGroup.Today,
    isLoading: true
};

/* eslint-disable-next-line default-param-last */
const trackTripReducers = (state = initialState, action) => {
    switch (action.type) {
    case TRACK_TRIP_SET_TRIP:
        return {
            ...state,
            trip: action.trip
        };
    case TRACK_TRIP_SET_TRIP_TYPE:
        return {
            ...state,
            tripType: action.tripType
        };
    case TRACK_TRIP_SET_IS_LOADING:
        return {
            ...state,
            isLoading: action.isLoading
        };
    default:
        return state;
    }
};

export default trackTripReducers;
