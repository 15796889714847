export const SET_STUDENTUPLOAD_STEP = 'SET_STUDENTUPLOAD_STEP';
export const setStep = payload => ({
    type: SET_STUDENTUPLOAD_STEP,
    step: payload
});

export const SET_STUDENTUPLOAD_KEY_DROPZONE = 'SET_STUDENTUPLOAD_KEY_DROPZONE';
export const setKeyDropZone = payload => ({
    type: SET_STUDENTUPLOAD_KEY_DROPZONE,
    keyDropZone: payload
});

export const SET_STUDENTUPLOAD_FILES = 'SET_STUDENTUPLOAD_FILES';
export const setStudentFiles = payload => ({
    type: SET_STUDENTUPLOAD_FILES,
    studentFiles: payload.studentFiles,
    isFullyValidated: payload.isFullyValidated
});

export const SET_STUDENTUPLOAD_REJECTED_FILES = 'SET_STUDENTUPLOAD_REJECTED_FILES';
export const setStudentRejectedFiles = payload => ({
    type: SET_STUDENTUPLOAD_REJECTED_FILES,
    studentRejectedFiles: payload
});

export const INIT_STUDENTUPLOAD = 'INIT_STUDENTUPLOAD';
export const initForm = () => ({
    type: INIT_STUDENTUPLOAD
});

export const SET_STUDENTUPLOAD_NEXT_PATH = 'SET_STUDENTUPLOAD_NEXT_PATH';
export const setNextPath = payload => ({
    type: SET_STUDENTUPLOAD_NEXT_PATH,
    nextPath: payload
});

export const SET_STUDENTUPLOAD_LEAVE_NO_SAVING_OPEN = 'SET_STUDENTUPLOAD_LEAVE_NO_SAVING_OPEN';
export const setLeaveNoSavingModalOpen = payload => ({
    type: SET_STUDENTUPLOAD_LEAVE_NO_SAVING_OPEN,
    status: payload
});

export const SET_STUDENTUPLOAD_DATA_FROM_FILE = 'SET_STUDENTUPLOAD_DATA_FROM_FILE';
export const setDataFromFile = payload => ({
    type: SET_STUDENTUPLOAD_DATA_FROM_FILE,
    dataFromFile: payload
});

export const SET_STUDENTUPLOAD_LOADING = 'SET_STUDENTUPLOAD_LOADING';
export const setStudentUploadLoading = payload => ({
    type: SET_STUDENTUPLOAD_LOADING,
    isLoading: payload
});

export const SET_STUDENTUPLOAD_COMPLETED = 'SET_STUDENTUPLOAD_COMPLETED';
export const setStudentUploadCompleted = payload => ({
    type: SET_STUDENTUPLOAD_COMPLETED,
    isCompleted: payload
});