import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import StyledFormLabel from 'components/Common/StyledFormLabel/StyledFormLabel';
import StyledTextField from 'components/Common/StyledTextField/StyledTextField';
import StyledAddressAutoComplete from 'components/Common/StyledAddressAutoComplete/StyledAddressAutoComplete';
import { isPhoneNumber, isTRFAddressValid } from 'util/validationUtil';
import { localize } from 'util/Localizer';
import StyledPhoneNumber from 'components/Common/StyledPhoneNumber/StyledPhoneNumber';

function SegmentInfo({
    segment,
    disabled,
    segments,
    currentTrip,
    errors,
    onChange
}) {
    const handleChangeInformation = (name, value) => {
        onChange(segment, name, value, currentTrip);
    };

    const renderSegmentTwoTitles = (input) => {
        if(input === localize("transportationRequest.leavingFrom")) {
            return localize("transportationRequest.returningFrom");
        }

        return localize("transportationRequest.returningTo");
    };

    const renderSegmentInputsAddress = () => (
        segments.slice(0, 2).map(input => (
            <Grid item xs={6} key={input.name}>
                <StyledFormLabel
                    label={
                        disabled
                            ? renderSegmentTwoTitles(input.label)
                            : input.label
                    }
                    isRequired
                />
                <StyledAddressAutoComplete
                    idName={input.name}
                    disabled={disabled}
                    onChangeInput={(evt) => handleChangeInformation(input.name, evt)}
                    value={input.value.selectedAddress ?? ''}
                    isNewStyle
                    isError={
                        !disabled
                        && errors?.[`${segment}-${input.name}`]?.isError
                        && !isTRFAddressValid(input.value)
                    }
                />
            </Grid>
        ))
    );

    const renderSegmentInputsApartments = () => (
        segments.slice(2, 4).map(input => (
            <Grid item xs={6} key={input.name} sx={{ marginTop: '20px' }}>
                <StyledFormLabel label={input.label} />
                <StyledTextField
                    isDisabled={disabled}
                    autoComplete="disabled"
                    inputProps={{ className: 'qm_block', "data-testid": input.name }}
                    isNewStyle
                    isRequired
                    name={input.name}
                    value={input.value.addressLine2 ?? ''}
                    onChange={(evt) => handleChangeInformation(input.name, evt.target.value)}
                />

            </Grid>
        ))
    );

    const renderSegmentInputsPhones = () => (
        segments.slice(4, 6).map(input => (
            <Grid item xs={6} key={input.name} sx={{ marginTop: '20px' }}>
                <StyledFormLabel label={input.label} isRequired />
                <StyledPhoneNumber
                    idMobilePhone={input.name}
                    modelInput={input.value.phone ?? ""}
                    handleChangeModel={(evt) => handleChangeInformation(input.name, evt.target.value)}
                    inputProps={{ className: 'qm_block', "data-testid": `${segment}${input.name}` }}
                    isRequired
                    disabled={disabled}
                    error={
                        !disabled
                        && errors?.[`${segment}-${input.name}`]?.isError
                        && (input.value.phone ? !isPhoneNumber(input.value.phone) : true)
                    }
                />
            </Grid>
        ))
    );

    return (
        <Grid container columnSpacing={6}>
            {renderSegmentInputsAddress()}
            {renderSegmentInputsApartments()}
            {renderSegmentInputsPhones()}
        </Grid>
    );
}

SegmentInfo.defaultProps = {
    disabled: false,
    currentTrip: null,
    segment: '',
    segments: [],
    errors: null,
    onChange: () => {}
};

/* eslint-disable react/forbid-prop-types */
SegmentInfo.propTypes = {
    segment: PropTypes.string,
    segments: PropTypes.array,
    disabled: PropTypes.bool,
    currentTrip: PropTypes.number,
    errors: PropTypes.object,
    onChange: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default SegmentInfo;