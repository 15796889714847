export function initChangeDetection(form) {
    Array.from(form).forEach(el => {
        const newEl = el;

        newEl.dataset.origValue = el.value;

        return newEl;
    });
}
export function formHasChanges(form) {
    return Array.from(form).some(el => 'origValue' in el.dataset && 'value' in el && el.dataset.origValue !== el.value);
}

export const onPreventSubmit = event => {
    if (event.key === 'Enter') {
        event.preventDefault();

        return false;
    }

    return true;
};
