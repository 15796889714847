import React from "react";
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { node } from "prop-types";

const MainContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.offWhite,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minWidth: 930,
    marginBottom: 50
}));

function StyledMainContainer({ children }) {
    return (
        <Grid container>
            <MainContainer item xs={12}>
                {children}
            </MainContainer>
        </Grid>
    );
}

/* eslint-disable react/forbid-prop-types */
StyledMainContainer.propTypes = {
    children: node.isRequired
};
/* eslint-enable react/forbid-prop-types */

export default StyledMainContainer;