import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    bool,
    node,
    object,
    string
} from 'prop-types';
import { museoFont700 } from 'util/EnumHelper';
import colorConstants from 'styles/ColorConstants';
import StyledTypography from 'components/Common/StyledTypography/StyledTypography';

const Header = styled(
    Grid,
    { shouldForwardProp: (prop) => prop !== 'isFixed' && prop !== 'isBGTransparent' }
)(({ theme, isFixed, isBGTransparent }) => {
    let extraProps = {};

    if (isFixed) {
        extraProps = { ...extraProps, ...{
            position: 'fixed',
            zIndex: 20,
            width: 'calc(100% - 100px)'
        }};
    }

    if (!isBGTransparent) {
        extraProps = { ...extraProps, ...{
            backgroundColor: theme.palette.white
        }};
    }

    return {
        padding: '0px 15px 0px 15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.everDrivenGrey30}`,
        ...extraProps
    };
});

const Title = styled(StyledTypography)(() => ({
    marginLeft: 0,
    marginTop: 10,
    paddingBottom: 15,
    fontSize: 24,
    fontFamily: museoFont700,
    color: colorConstants.EVERDRIVENDARKCHARCOAL,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

function StyledHeaderContainer ({
    children,
    extraStyle,
    idContainer,
    isBGTransparent,
    isFixed,
    title,
    systemAlert,
    titleStyle,
    testId
}) {
    return (
        <Header
            isFixed={isFixed}
            isBGTransparent={isBGTransparent}
            id={idContainer}
            style={extraStyle}
        >
            {systemAlert}
            <Grid item xs={12} style={{ width: 'calc(100% - 100px)' }}>
                <Title data-testid={testId} variant="h3" sx={titleStyle}>
                    {title}
                </Title>
            </Grid>
            {children}
        </Header>
    );
}

StyledHeaderContainer.defaultProps = {
    children: null,
    extraStyle: null,
    idContainer: null,
    isBGTransparent: false,
    isFixed: false,
    systemAlert: null,
    title: null,
    titleStyle: null,
    testId: null
};

/* eslint-disable react/forbid-prop-types */
StyledHeaderContainer.propTypes = {
    children: node,
    extraStyle: object,
    idContainer: string,
    isBGTransparent: bool,
    isFixed: bool,
    systemAlert: node,
    title: string,
    titleStyle: object,
    testId: string
};
/* eslint-enable react/forbid-prop-types */

export default StyledHeaderContainer;