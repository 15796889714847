import React from "react";
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import CommonStyles from 'styles/CommonStyles';
import { localize } from 'util/Localizer';
import StyledFormLabel from 'components/Common/StyledFormLabel/StyledFormLabel';
import colorConstants from 'styles/ColorConstants';
import StyledTextField from 'components/Common/StyledTextField/StyledTextField';
import StyledConfirmModal from "components/Common/StyledConfirmModal/StyledConfirmModal";

function SegmentNotes({
    segment,
    showNotes,
    notes,
    currentTrip,
    errors,
    setShowSegmentNotes,
    setNotes
}) {
    const [ showModal, setShowModal ] = React.useState(false);

    const handleChangeNotes = (evt) => {
        setNotes(segment, evt.target.value, currentTrip);
    };

    const removeNotes = () => {
        setShowModal(false);
        setShowSegmentNotes(segment, !showNotes, currentTrip);
    };

    return (
        <Grid container>
            { showNotes &&
                <Grid container sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                        <StyledFormLabel
                            label={localize('transportationRequest.segmentNotes')}
                        />
                        <StyledButton
                            color="inherit"
                            style={{
                                ...CommonStyles.linkButton,
                                color: colorConstants.EVERDRIVENFUCHSIA,
                                marginLeft: 'auto',
                                alignItems: 'center'
                            }}
                            type="button"
                            startIcon={<CloseIcon style={{ fontSize: 16, paddingBottom: 1 }} />}
                            variant="text"
                            testId={`${segment}-removeSegmentNotesButton`}
                            onClick={() => setShowModal(true)}
                        >
                            {localize('transportationRequest.remove')}
                        </StyledButton>
                    </Grid>
                    <Grid item md={12} lg={12} xl={12} sm={12} xs={12}>
                        <StyledTextField
                            inputProps= {{ "data-testid": `${segment}SegmentNotes`, maxLength: "250" }}
                            isMultiline
                            isNewStyle
                            name={`${segment}SegmentNotes`}
                            rows={4}
                            role="textbox"
                            value={notes ?? ''}
                            isError={errors?.[`${segment}Notes`]?.isError && notes === ''}
                            onChange={(e) => handleChangeNotes(e)}
                        />
                    </Grid>
                </Grid>
            }
            <StyledConfirmModal
                isOpen={showModal}
                contentMessage={localize('transportationRequest.removeStudentNotesConfirm')}
                confirmInputLabel={localize('transportationRequest.remove')}
                cancelInputLabel={localize('transportationRequest.cancel')}
                onConfirm={() => removeNotes()}
                onCancel={() => setShowModal(false)}
            />
        </Grid>
    );
}

SegmentNotes.defaultProps = {
    segment: '',
    showNotes: false,
    currentTrip: 0,
    notes: '',
    errors: null,
    setShowSegmentNotes:  () => {},
    setNotes: () => {}
};

/* eslint-disable react/forbid-prop-types */
SegmentNotes.propTypes = {
    segment: PropTypes.string,
    showNotes: PropTypes.bool,
    notes: PropTypes.string,
    currentTrip: PropTypes.number,
    errors: PropTypes.object,
    setShowSegmentNotes: PropTypes.func,
    setNotes: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default SegmentNotes;