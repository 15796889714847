import React from "react";

import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
    string,
    func
} from 'prop-types';
import { Enum } from "../../../util/EnumHelper";

function StyledSortColumnButton({
    size,
    sortbyColumn: onClick,
    columnName,
    sort,
    testId
}) {
    return (
        <IconButton
            sx={{padding: 0, marginLeft: '10px'}}
            size={size}
            onClick={() => onClick(columnName)}
            data-testid={testId}
        >
            { sort === Enum.Sorter.Desc
                ? <ExpandMoreIcon />
                : <ExpandLessIcon />
            }
        </IconButton>
    );
}

StyledSortColumnButton.defaultProps = {
    size: 'small',
    sortbyColumn: () => null,
    sort: '',
    columnName: '',
    testId: ''
};

StyledSortColumnButton.propTypes = {
    size: string,
    sortbyColumn: func,
    sort: string,
    columnName: string,
    testId: string
};

export default StyledSortColumnButton;