import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import withTheme from '@mui/styles/withTheme';
import TripRequestForm from 'components/TripRequestForm/TripRequestForm';
import {
    onAddForm,
    onLoadDefaultStudent,
    onLoadForm,
    onSubmitTripForm
} from '../../workers/tripRequestFormWorker';
import {
    onDeleteDraft,
    onSaveDraftBeforeLeave,
    onValidDraftIsSaved
} from '../../workers/tripRequestFormDraftWorker';
import {
    onLoadStudent,
    onClearStudent
} from '../../workers/tripRequestFormStudentWorker';
import { onDeleteTab } from '../../workers/tripRequestFormSiblingHeaderWorker';
import {
    clearDraftIndicator,
    setClearStudentStatusModal,
    setDeleteDraftModalStatus,
    setNextPath,
    setRemoveSiblingModalStatus,
    setSaveBeforeLeaveModalStatus,
    setSubmitConfirmModalStatus,
    setSearchTerm
} from '../../actions/tripRequestFormActions';
import {
    updatePath
} from '../../workers/pathWorker';
import withRouter from '../../../util/customHooks/withRouter';

const mapStateToProps = state => {
    const {
        TripRequestFormReducers
    } = state;

    return {
        defaultStudentId: TripRequestFormReducers.defaultStudentId,
        isDeleteDraftModalOpen: TripRequestFormReducers.isDeleteDraftModalOpen,
        isClearStudentFormOpen: TripRequestFormReducers.isClearStudentFormOpen,
        isDraftForSave: TripRequestFormReducers.isDraftForSave,
        isMissingEmailAccount: TripRequestFormReducers.isMissingEmailAccount,
        isRemoveSiblingOpen: TripRequestFormReducers.isRemoveSiblingOpen,
        isSaveDraftBeforeLeaveOpen: TripRequestFormReducers.isSaveDraftBeforeLeaveOpen,
        isSubmitConfirmOpen: TripRequestFormReducers.isSubmitConfirmOpen,
        isTRFLoading: TripRequestFormReducers.isTRFLoading,
        limitReached: TripRequestFormReducers.limitReached,
        nextPath: TripRequestFormReducers.nextPath,
        removeTabMessage: TripRequestFormReducers.removeTabMessage,
        searchTerm: TripRequestFormReducers.searchTerm,
        selectedStudent: TripRequestFormReducers.selectedStudent,

        submitFormMessage: TripRequestFormReducers.submitFormMessage,
        tabForDelete: TripRequestFormReducers.tabForDelete
    };
};

const TripRequestFormContainer = withRouter(
    withTheme(
        connect(mapStateToProps,
            {
                clearDraftIndicator,
                onAddForm,
                onClearStudent,
                onDeleteDraft,
                onDeleteTab,
                onLoadDefaultStudent,
                onLoadForm,
                onLoadStudent,
                onSaveDraftBeforeLeave,
                onSubmitTripForm,
                onValidDraftIsSaved,
                setClearStudentStatusModal,
                setDeleteDraftModalStatus,
                setNextPath,
                setRemoveSiblingModalStatus,
                setSaveBeforeLeaveModalStatus,
                setSubmitConfirmModalStatus,
                setSearchTerm,
                updatePath
            })(injectIntl(TripRequestForm))
    )
);

export default TripRequestFormContainer;
