import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes, {
    bool,
    string,
    func
} from 'prop-types';
import Alert from '@mui/material/Alert';
import { Enum, museoSansFont } from 'util/EnumHelper';

import StyledButton from '../StyledButton/StyledButton';

const HIDE_DURATION = 6000;

const StyleSnackbar = styled(Snackbar)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        width: '75%',
        top: '50%'
    },
    [theme.breakpoints.up('lg')]: {
        top: '50%'
    }

}));

const StyleMuiAlert = styled(
    Alert,
    { shouldForwardProp: (prop) => prop !== 'severity' }
)(({ theme, severity }) => {
    const styles = {
        '& .MuiAlert-message': {
            fontSize: 16,
            fontColor: theme.palette.white,
            fontFamily: museoSansFont,
            fontWeight: 500
        },
        '& .MuiAlert-action': {
            width: 50,
            padding: 0
        },
        alignItems: 'center',
        width: '100%',
        paddingRight: 18,
        paddingLeft: 18,
        paddingTop: 3,
        paddingBottom: 3,
        wordWrap: 'break-word'
    };

    switch(severity) {
    case Enum.severityType.success:
        return {
            ...styles,
            backgroundColor: theme.palette.everDrivenDarkGreen
        };
    case Enum.severityType.error:
        return {
            ...styles,
            backgroundColor: theme.palette.everDrivenFuchsia
        };
    case Enum.severityType.info:
        return {
            ...styles,
            backgroundColor: theme.palette.everDrivenLightBlue
        };
    case Enum.severityType.warning:
    default:
        return {
            ...styles,
            backgroundColor: theme.palette.yellowOption1
        };
    }
});

const StyleCloseButton = styled(StyledButton)(({ theme }) => ({
    background: theme.palette.transparent,
    '&:hover': {
        background: 'transparent'
    }
}));

function StyledSnackbarWrapper({
    message,
    onClose,
    severity,
    openSnackBar
}) {
    return (
        <StyleSnackbar
            open={openSnackBar}
            autoHideDuration={HIDE_DURATION}
            ClickAwayListenerProps={{mouseEvent: false, touchEvent:false}}
            anchorOrigin={{vertical: 'top', horizontal: 'center' }}
            onClose={onClose}
            sx={{ width: 1217 }}
            data-testid={`SnackBar-${severity}`}
        >
            <StyleMuiAlert
                elevation={6}
                variant="filled"
                icon={false}
                severity={severity}
                action={
                    <StyleCloseButton
                        disableElevation
                        testId="closeSnackBarButton"
                        onClick={onClose}
                    >
                        <CloseIcon sx={{ width: 24, height: 24 }}/>
                    </StyleCloseButton>
                }
            >
                {message}
            </StyleMuiAlert>
        </StyleSnackbar>
    );
}

StyledSnackbarWrapper.defaultProps = {
    message: '',
    onClose: () => {},
    severity: Enum.severityType.warning,
    openSnackBar: false
};

StyledSnackbarWrapper.propTypes = {
    severity: PropTypes.oneOf(
        [
            Enum.severityType.success,
            Enum.severityType.warning,
            Enum.severityType.error,
            Enum.severityType.info
        ]
    ),
    message: string,
    onClose: func,
    openSnackBar: bool
};

export default StyledSnackbarWrapper;