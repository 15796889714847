import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ReduxRouter } from '@lagunovsky/redux-react-router';

import StyledLoading from 'components/Common/StyledLoading/StyledLoading';
import Invoicing from 'components/Invoicing';
import HeaderContainer from 'components/Header';
import LoadingPrerender from 'widgets/common/LoadingPrerender';

import TransportationRequestContainer from 'redux/containers/transportationRequest/TransportationRequestContainer';
import StudentContainer from 'redux/containers/districtTripTracker/StudentContainer';
import FacilityContainer from 'redux/containers/districtTripTracker/FacilityContainer';
import TrackTripContainer from 'redux/containers/districtTripTracker/TrackTripContainer';
import ErrorPageContainer from 'redux/containers/errorPage/ErrorPageContainer';
import TrackTripDetailContainer from 'redux/containers/districtTripTracker/TrackTripDetailContainer';
import CalendarUploadContainer from 'redux/containers/calendar/CalendarUploadContainer';
import StudentUploadContainer from 'redux/containers/studentUpload/StudentUploadContainer';
import DraftListContainer from 'redux/containers/draftList/DraftListContainer';
import Error403PageContainer from 'redux/containers/errorPage/Error403PageContainer';
import DistrictTripTrackerContainer from 'redux/containers/districtTripTracker/DistrictTripTrackerContainer';
import ReportContainer from 'redux/containers/report/ReportContainer';
import StudentOnboardingReportContainer from 'redux/containers/report/StudentOnboardingReportContainer';
import TripRequestFormContainer from 'redux/containers/tripRequestForm/TripRequestFormContainer';
import UserManagementContainer from 'redux/containers/user/UserManagementContainer';
import LeftMenuContainer from 'redux/containers/path/LeftMenuContainer';
import CalendarContainer from 'redux/containers/districtTripTracker/CalendarContainer';
import { localize } from 'util/Localizer';
import { paths } from 'util/EnumHelper';
import ResourcesContainer from 'redux/containers/resources/ResourcesContainer';
import HowToGrantAppAccessContainer from 'redux/containers/resources/HowToGrantAppAccessContainer';
import { bool } from 'prop-types';
import Auth from './auth/Auth';

import history from './history';

const useStyles = {
    mainContent: {
        width: '100%'
    },
    rootLoaded: {
        display: 'flex',
        flexGrow: 1
    },
    root: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
};

function App({ configLoaded, isLoggingOut }) {
    const hasTripTrackAccess = Auth.hasTripTrackingAccess();
    const hasManagementAccess = Auth.hasProgramManagementAccess();
    const hasBillingAccess = false;
    const hasUserAccess = Auth.hasUserAccess();
    const { useNewTransportationRequestForm } = Auth.getConfig();

    if(!configLoaded) {
        return (
            <div style={useStyles.root}>
                <LoadingPrerender />
            </div>
        );
    }

    return(
        <ReduxRouter history={history}>
            <div>
                { isLoggingOut && <StyledLoading isFullWidth isLoggingOut message={localize('header.loggingOut')}/> }
                <HeaderContainer />
                <div style={useStyles.rootLoaded}>
                    <LeftMenuContainer />
                    <div style={useStyles.mainContent}>
                        <Routes>
                            {hasTripTrackAccess && (
                                <>
                                    <Route
                                        path={paths.Home.path}
                                        element={<DistrictTripTrackerContainer/>}
                                    />
                                    <Route
                                        path={paths.DistrictTripTracker.path}
                                        element={<DistrictTripTrackerContainer />}
                                    />
                                    <Route
                                        path="/school/:facilityId/tracktrip/:memberId/:tripId"
                                        element={<TrackTripContainer />}
                                    />
                                    <Route
                                        path="/school/:facilityId/triplist/:memberId"
                                        element={<StudentContainer />}
                                    />
                                    <Route
                                        path="/school/:facilityId"
                                        element={<FacilityContainer />}
                                    />
                                    <Route
                                        path="/school/:facilityId/current"
                                        element={<FacilityContainer />}
                                    />
                                    <Route
                                        path="/tracktrip/:memberId/:tripId"
                                        element={<TrackTripDetailContainer />}
                                    />
                                    <Route
                                        path="/triplist/:memberId"
                                        element={<StudentContainer />}
                                    />
                                    <Route
                                        path="/calendar/:facilityId"
                                        element={<CalendarContainer />}
                                    />
                                </>
                            )}

                            {!hasTripTrackAccess &&
                            hasManagementAccess && (
                                useNewTransportationRequestForm ?
                                    (<>
                                        <Route
                                            path={paths.Home.path}
                                            element={<TransportationRequestContainer />}
                                        />
                                        <Route
                                            path={paths.TransportationRequestForm.path}
                                            element={<Navigate to={paths.TransportationRequest.path} />}
                                        />
                                    </>)
                                    :
                                    (<>
                                        <Route
                                            path={paths.Home.path}
                                            element={<TripRequestFormContainer />}
                                        />
                                        <Route
                                            path={paths.TransportationRequest.path}
                                            element={<Navigate to={paths.TransportationRequestForm.path} />}
                                        />
                                    </>)
                            )}

                            {hasManagementAccess &&
                                useNewTransportationRequestForm ? (
                                    <>
                                        <Route
                                            path={paths.TransportationRequest.path}
                                            element={<TransportationRequestContainer />}
                                        />
                                        <Route
                                            path={paths.TransportationRequestForm.path}
                                            element={<Navigate to={paths.TransportationRequest.path} />}
                                        />
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Route
                                            path={paths.TransportationRequestForm.path}
                                            element={<TripRequestFormContainer />}
                                        />
                                        <Route
                                            path={paths.TransportationRequest.path}
                                            element={<Navigate to={paths.TransportationRequestForm.path} />}
                                        />
                                    </>
                                )
                            }

                            {hasManagementAccess && (
                                <>
                                    <Route
                                        path={paths.StudentUpload.path}
                                        element={<StudentUploadContainer />}
                                    />
                                    <Route
                                        path="/TransportationRequestForm/DraftList"
                                        element={<Navigate to={paths.DraftList.path} />}
                                    />
                                    <Route
                                        path={paths.DraftList.path}
                                        element={<DraftListContainer />}
                                    />
                                    <Route
                                        path={paths.Reports.path}
                                        element={<ReportContainer />}
                                    />
                                    <Route
                                        path={paths.StudentOnboardingReport.path}
                                        element={
                                            <StudentOnboardingReportContainer />
                                        }
                                    />
                                </>
                            )}

                            {!hasTripTrackAccess &&
                            !hasManagementAccess &&
                            hasBillingAccess ? (
                                    <Route
                                        path={paths.Home.path}
                                        element={<Invoicing />}
                                    />
                                ) : null}

                            {hasBillingAccess ? (
                                <Route
                                    path={paths.Invoicing.path}
                                    element={<Invoicing />}
                                />
                            ) : null}

                            {!hasTripTrackAccess &&
                            !hasManagementAccess &&
                            !hasBillingAccess &&
                            hasUserAccess && (
                                <Route
                                    path={paths.Home.path}
                                    element={<UserManagementContainer />}
                                />
                            )}
                            {hasUserAccess && (
                                <>
                                    <Route
                                        path={paths.User.path}
                                        element={<UserManagementContainer />}
                                    />
                                    <Route
                                        path={paths.CalendarUpload.path}
                                        element={<CalendarUploadContainer />}
                                    />
                                </>
                            )}

                            <Route
                                path={paths.Resources.path}
                                element={<ResourcesContainer />}
                            />
                            <Route
                                path={paths.HowToGrantAppAccess.path}
                                element={<HowToGrantAppAccessContainer />}
                            />

                            <Route
                                path="/error"
                                element={<ErrorPageContainer />}
                            />

                            <Route
                                path="/error403"
                                element={<Error403PageContainer />}
                            />

                            <Route
                                path="*"
                                element={<ErrorPageContainer/>}
                            />

                            <Route
                                path="/redirect"
                                element={
                                    <Navigate
                                        to={paths.Home.path}
                                        replace
                                    />
                                }
                            />
                        </Routes>
                    </div>
                </div>
            </div>
        </ReduxRouter>
    );
}

const mapStateToProps = (state) => ({
    isLoggingOut: state.PathReducers.isLoggingOut
});

const ConnectedApp = connect(
    mapStateToProps,
    null
)(App);

App.propTypes = {
    configLoaded: bool.isRequired,
    isLoggingOut: bool.isRequired
};

export default ConnectedApp;