import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import DttTableFacilityContainer from 'redux/containers/districtTripTracker/DttTableFacilityContainer';
import { localize } from 'util/Localizer';
import StyledButton from 'components/Common/StyledButton/StyledButton';

const StyledTextButton = styled(StyledButton)(({theme}) => ({
    color: theme.palette.everDrivenLightBlue,
    fontFamily: 'MuseoSans500',
    fontWeight: 500,
    fontSize: 14,
    textDecorationLine: 'underline',
    padding: 0,
    whiteSpace: 'nowrap'
}));

function DttFacilities({
    initialSetup,
    goToFacilityDetail,
    goToCalendar
}) {
    const viewSchoolButton = (facility) => (
        <StyledTextButton
            color="inherit"
            type="button"
            endIcon={<KeyboardArrowRightOutlinedIcon fontSize="inherit" />}
            variant="text"
            testId="viewSchool"
            onClick={() => goToFacilityDetail(facility)}
        >
            {localize('dttFacility.viewSchool')}
        </StyledTextButton>
    );

    const viewCalendarButton = (facility) => (
        <StyledTextButton
            color="inherit"
            type="button"
            endIcon={<KeyboardArrowRightOutlinedIcon fontSize="inherit" />}
            variant="text"
            testId="viewCalendar"
            onClick={() => goToCalendar(facility)}
        >
            {localize('dttFacility.viewCalendar')}
        </StyledTextButton>
    );

    useEffect(() => {
        initialSetup();
    }, []);

    return (
        <DttTableFacilityContainer
            rightControls={[ viewCalendarButton, viewSchoolButton ]}
        />
    );
}

export default DttFacilities;
