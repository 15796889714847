import { connect } from 'react-redux';
import { localize } from 'util/Localizer';
import TripBuilderContent from 'components/TransportationRequest/TripBuilder/TripBuilderContent/TripBuilderContent';
import {
    setCollapseTrip,
    removeTrip
} from 'redux/workers/transportationRequest/transportationRequestTripsWorker';

const mapStateToProps = (state, ownProps) => {
    const { TransportationRequestReducers } = state;
    const { trips } = TransportationRequestReducers;

    return {
        trip: trips[ownProps.tripNumber],
        title: localize('transportationRequest.tripInformation', { number: ownProps.tripNumber + 1}),
        textButton: trips[ownProps.tripNumber]?.collapsed
            ? localize('transportationRequest.expand')
            : localize('transportationRequest.collapse')
    };
};

const mapDispatchToProps = {
    setCollapseTrip,
    removeTrip
};

const TripBuilderContentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TripBuilderContent);

export default TripBuilderContentContainer;
