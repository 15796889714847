import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CancelIcon from '@mui/icons-material/Cancel';
import PlaceIcon from '@mui/icons-material/Place';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { makeStyles } from '@mui/styles';
import { Enum } from "../../util/EnumHelper";
import { localize } from "../../util/Localizer";
import commonUseStyles from '../../styles/CommonStyles';
import * as dateUtils from '../../util/dateUtils';

const useStyles = makeStyles(() => ({
    tripContent: {
        fontFamily: 'Arial, Helvetica, sans-serif'
    },
    lastRowContent: {
        ...commonUseStyles.popUpRowContent,
        paddingTop: 2
    },
    closeButton: {
        marginBottom: -5,
        marginLeft: 'auto',
        display: 'none !important',
        cursor: 'pointer'
    },
    rowLabelName: {
        marginTop: 4,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    rowLabel: {
        marginTop: 4
    },
    contentRow: {
        display: 'none'
    },
    contentDescription: {
        ...commonUseStyles.popUpDetailContent,
        fontSize: '12px'
    },
    contentAddress: {
        ...commonUseStyles.popUpDetailContent,
        paddingLeft: 5
    },
    statusLabel: {
        fontWeight: 'bold',
        fontSize: 14
    },
    icon: {
        height: 20
    }
}));

function ActiveTripRow({ trip, isFirstRow, isLastRow, isOneTrip, popUpClasses }) {
    const classes = useStyles();
    let colorStatus = '';
    let statusLabel = '';
    let rowStyle = popUpClasses.rowContent;
    let dropOffLabel = `${localize("school.dropOff")} ETA ${Math.round(trip.EtaToDropOffSeconds / 60)} mins`;

    if (!trip.EtaToDropOffSeconds) {
        dropOffLabel = localize("school.dropOff");
    } else if (trip.EtaToDropOffSeconds < 60) {
        dropOffLabel = `${localize("school.dropOff")} ETA ${dateUtils.timeFormat(
            trip.EtaTLT, trip.EtaToDropOffSeconds - trip.EtaToPickUpSeconds)}`;
    }

    if (trip.State === Enum.TripState.EnRoute) {
        colorStatus = popUpClasses.imgStatusEnRoute;
        statusLabel = localize("tripDetails.statusEnRoute");
    } else if (trip.State === Enum.TripState.OnBoard) {
        colorStatus = popUpClasses.imgStatusOnBoard;
        statusLabel = localize("tripDetails.statusOnBoard");
    } else {
        colorStatus = popUpClasses.imgStatusOnSite;
        statusLabel = localize("tripDetails.statusOnSite");
    }

    if (isFirstRow) {
        rowStyle = popUpClasses.firstRowContent;
    } else if (isLastRow) {
        rowStyle = classes.lastRowContent;
    }

    return (
        <div key={`trip_${trip.VehicleRunID}_${trip.MemberPII.ClientMemberCode}`}
            id={`trip_${trip.VehicleRunID}_${trip.MemberPII.ClientMemberCode}`}
            className={classes.tripContent}>
            <div key={`row_${trip.VehicleRunID}_${trip.MemberPII.ClientMemberCode}`}
                className={rowStyle}
                id={`tripRow_${trip.VehicleRunID}_${trip.MemberPII.ClientMemberCode}`}>
                <FiberManualRecordIcon
                    color="primary"
                    className={`${colorStatus} ${classes.icon}`}
                />
                <div id={`tripRowName_${trip.VehicleRunID}_${trip.MemberPII.ClientMemberCode}`}
                    className={classes.rowLabelName}>
                    <span title={`${trip.MemberPII.FirstName} ${trip.MemberPII.LastName}`}>
                        {`${trip.MemberPII.FirstName} ${trip.MemberPII.LastName}`}
                    </span>
                </div>
                <CancelIcon id={`cancelButton_${trip.VehicleRunID}_${trip.MemberPII.ClientMemberCode}`}
                    className={`${classes.closeButton} ${classes.icon}`}/>
            </div>
            <div key={`content_${trip.VehicleRunID}_${trip.MemberPII.ClientMemberCode}`}
                id={`tripContent_${trip.VehicleRunID}_${trip.MemberPII.ClientMemberCode}`}
                className={!isOneTrip ? classes.contentRow : null}>
                <div className={classes.contentDescription}>
                    <LocationCityIcon
                        className={`${colorStatus} ${classes.icon}`} />
                    <span className={`${colorStatus} ${classes.rowLabel}`}>
                        {localize("school.pickup")}
                    </span>
                </div>
                <div className={classes.contentAddress}>
                    <span>{trip.PickUpLocation.Address1}</span>
                </div>
                <div className={classes.contentDescription}>
                    <PlaceIcon
                        className={`${colorStatus} ${classes.icon}`} />
                    <span className={`${colorStatus} ${classes.rowLabel}`} >
                        {dropOffLabel}
                    </span>
                </div>
                <div className={classes.contentAddress}>
                    <span>{trip.DropOffLocation.Address1}</span>
                </div>
                <div id={`statusLabel_${trip.VehicleRunID}_${trip.MemberPII.ClientMemberCode}`}
                    className={popUpClasses.contentStatusLabel}>
                    <span className={`${colorStatus} ${classes.statusLabel}`}>{statusLabel}</span>
                </div>
            </div>
        </div>
    );
}

export default ActiveTripRow;
