import { setTrips } from "redux/actions/transportationRequest/transportationRequestActions";
import { setTripBuilderErrors } from "redux/actions/transportationRequest/transportationRequestErrorsActions";
import {
    daysTime,
    newTrip,
    TransportationRequestTripTypesOptions as TripTypesOptions,
    TransportationRequestSegmentTypes as SegmentTypes,
    segment as segmentModel,
    TransportationRequestRecurringTripOptions as RecurringTripOptions,
    TransportationRequestDatesOptions as DatesOptions,
    days as TRdays,
    TransportationRequestSegmentsOptions as SegmentsOptions
} from "data/models/transportationRequest/TripBuilderModel";
import { getOpositeField } from "util/data/TripBuilderDecorator";
import { dataAccounts } from 'util/data/dataDecorator';
import { onValidDraftCanBeSaved } from "./transportationRequestDraftWorker";

export const trTripUpdateAccount = (account, currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;
    
    trips[currentTrip].account= account;
    dispatch(setTrips([...trips]));
    dispatch(onValidDraftCanBeSaved());
};

export const trTripUpdateRecurring = (recurring, currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;
    
    trips[currentTrip].recurring = recurring;
    if(trips[currentTrip].recurring === RecurringTripOptions.oneTime) {
        trips[currentTrip].arrivalHasTimeMultiples = false;
        trips[currentTrip].departureHasTimeMultiples = false;
        trips[currentTrip][DatesOptions.endDate]= null;
        trips[currentTrip].days = [];
    } else {
        trips[currentTrip].days.push(TRdays.monday);
        trips[currentTrip].days.push(TRdays.tuesday);
        trips[currentTrip].days.push(TRdays.wednesday);
        trips[currentTrip].days.push(TRdays.thursday);
        trips[currentTrip].days.push(TRdays.friday);
    }

    dispatch(setTrips(trips));
    dispatch(onValidDraftCanBeSaved());
};

export const trTripUpdateDate = (value, date, currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;
    
    trips[currentTrip][date] = value;
    dispatch(setTrips([...trips]));
    dispatch(onValidDraftCanBeSaved());
};

export const trTripUpdateDays = (day, currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;

    if(!trips[currentTrip].days.includes(day)) {
        trips[currentTrip].days.push(day);
    } else if(trips[currentTrip].days.length === 2) {
        const dayTime = trips[currentTrip].days[0];

        trips[currentTrip][`${SegmentTypes.arrival}HasTimeMultiples`] = false;
        trips[currentTrip][`${SegmentTypes.departure}HasTimeMultiples`] = false;

        const arrivalTime = trips[currentTrip][`${SegmentTypes.arrival}Time`][dayTime];
        const departureTime = trips[currentTrip][`${SegmentTypes.departure}Time`][dayTime];
    
        trips[currentTrip][`${SegmentTypes.arrival}SingleTime`] = arrivalTime;
        trips[currentTrip][`${SegmentTypes.departure}SingleTime`] = departureTime;

        trips[currentTrip].days = trips[currentTrip].days.filter(d => d !== day);
    } else if(trips[currentTrip].days.includes(day) && trips[currentTrip].days.length > 2) {
        trips[currentTrip].days = trips[currentTrip].days.filter(d => d !== day);
        trips[currentTrip][`${SegmentTypes.arrival}Time`][day] = '';
        trips[currentTrip][`${SegmentTypes.departure}Time`][day] = '';
    }

    dispatch(setTrips(trips));
    dispatch(onValidDraftCanBeSaved());
};

const getOpositeSegment = (sgmt) => {
    const opositeSegment = {...sgmt};

    opositeSegment.tripLeavingFrom = sgmt[getOpositeField('tripLeavingFrom')];
    opositeSegment.tripGoingTo = sgmt[getOpositeField('tripGoingTo')];
    opositeSegment.tripApartment = sgmt[getOpositeField('tripApartment')];
    opositeSegment.tripApartment2 = sgmt[getOpositeField('tripApartment2')];

    return opositeSegment;
};

export const trTripUpdateTripType = (tripType, currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;

    trips[currentTrip].tripTypes = [tripType];
    if(trips[currentTrip].tripTypes.includes(TripTypesOptions.oneWay)) {
        trips[currentTrip][SegmentTypes.departure] = { ...segmentModel};
        trips[currentTrip].departureSingleTime = '';
        trips[currentTrip].departureTime = { ...daysTime };
    }
    if(trips[currentTrip].tripTypes.includes(TripTypesOptions.roundTrip)) {
        const opositeSegment = getOpositeSegment(trips[currentTrip][SegmentTypes.arrival]);

        trips[currentTrip][SegmentTypes.departure] = { ...opositeSegment};
    }
    dispatch(setTrips([...trips]));
    dispatch(onValidDraftCanBeSaved());
};

export const trTripUpdateSegment = (segment, field, value, currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;

    if(field === SegmentsOptions.tripApartment) {
        trips[currentTrip][segment][SegmentsOptions.tripLeavingFrom].addressLine2 = value;
    } else if(field === SegmentsOptions.tripApartment2) {
        trips[currentTrip][segment][SegmentsOptions.tripGoingTo].addressLine2 = value;
    } else if(field === SegmentsOptions.tripPhone) {
        trips[currentTrip][segment][SegmentsOptions.tripLeavingFrom].phone = value;
    } else if(field === SegmentsOptions.tripPhone2) {
        trips[currentTrip][segment][SegmentsOptions.tripGoingTo].phone = value;
    } else {
        trips[currentTrip][segment][field] = {
            ...trips[currentTrip][segment][field],
            ...value
        };
    }

    if(trips[currentTrip].tripTypes.includes(TripTypesOptions.roundTrip)
        && segment !== SegmentTypes.departure) {
        trips[currentTrip][SegmentTypes.departure][SegmentsOptions.tripLeavingFrom] =
            trips[currentTrip][segment][SegmentsOptions.tripGoingTo];
        trips[currentTrip][SegmentTypes.departure][SegmentsOptions.tripGoingTo] =
            trips[currentTrip][segment][SegmentsOptions.tripLeavingFrom];
    }

    dispatch(setTrips([...trips]));
    dispatch(onValidDraftCanBeSaved());
};

export const trTripUpdateTime = (segment, field, value, currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;
    
    trips[currentTrip][`${segment}Time`][field] = value;
    dispatch(setTrips([...trips]));
    dispatch(onValidDraftCanBeSaved());
};

export const trTripUpdateMultipleTimes = (segment, value, currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;
    
    trips[currentTrip][`${segment}SingleTime`] = value;

    dispatch(setTrips([...trips]));
    dispatch(onValidDraftCanBeSaved());
};

export const setMultipleTimes = (segment, value, currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;

    trips[currentTrip][`${segment}HasTimeMultiples`] = value;

    dispatch(setTrips([...trips]));
    dispatch(onValidDraftCanBeSaved());
};

export const setNotes = (segment, value, currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;

    trips[currentTrip][`${segment}Notes`] = value;
    dispatch(setTrips([...trips]));
    dispatch(onValidDraftCanBeSaved());
};

export const setShowSegmentNotes = (segment, value, currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;

    trips[currentTrip][`${segment}Notes`] = '';
    trips[currentTrip][`${segment}ShowNotes`] = value;
    dispatch(setTrips([...trips]));
    dispatch(onValidDraftCanBeSaved());
};

export const setCollapseTrip = (currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;

    trips[currentTrip].collapsed = !trips[currentTrip].collapsed;
    dispatch(setTrips([...trips]));
};

export const removeTrip = (currentTrip) => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;
    
    trips.splice(currentTrip, 1);
    dispatch(setTrips([...trips]));
    dispatch(setTripBuilderErrors([]));
    dispatch(onValidDraftCanBeSaved());
};

export const initialSetup = () => (dispatch) => {
    dispatch(setTrips([{
        ...newTrip(),
        account: dataAccounts()[0]?.id
    }]));
    dispatch(onValidDraftCanBeSaved());
};

export const addTrip = () => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;
    
    trips.forEach((trip, index) => {
        trips[index].collapsed = true;
    });

    dispatch(setTrips([ ...trips, {
        ...newTrip(),
        account: dataAccounts()[0]?.id
    }]));
    dispatch(onValidDraftCanBeSaved());
};
