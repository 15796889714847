import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ReportRow from '../../../controls/Report/ReportRow';
import {
    downloadReport,
    downloadNoShowReport,
    loadReport
} from '../../workers/reportsWorker';
import { updatePath } from '../../workers/pathWorker';
import withRouter from '../../../util/customHooks/withRouter';

const mapStateToProps = (state, ownProps) => {
    const { report } = ownProps;
    const { ReportReducers } = state;

    if (ReportReducers) {
        return {
            rowMessage: ReportReducers.responseRowMessage[report.index],
            viewButtonActive: ReportReducers.viewButtonsActive[report.index],
            downloadButtonActive: ReportReducers.downloadButtonActive[report.index],
            showMessage:(
                (ReportReducers.downloadButtonActive[report.index])
                || (ReportReducers.viewButtonsActive[report.index])
            )
        };
    }

    return {};
};

const mapDispatchToProps = {
    updatePath,
    downloadReport,
    downloadNoShowReport,
    loadReport
};

const ReportRowContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ReportRow)));

export default ReportRowContainer;
