import COLORCONSTANTS from "styles/ColorConstants";
import { isTripLate, calcIsToday } from './dateUtils';
import { Enum } from './EnumHelper';
import { hasPendingCancellation } from './validationUtil';

export const tripPrimaryColor = (tripProps) => {
    switch (tripProps.State) {
    case Enum.TripState.Active:
        return COLORCONSTANTS.TEALOPTION1;
    case Enum.TripState.EnRoute:
        if(isTripLate(tripProps)) {
            return COLORCONSTANTS.YELLOWOPTION1;
        }

        return COLORCONSTANTS.TEALOPTION2;
    case Enum.TripState.OnSite:
    case Enum.TripState.OnSiteAtDropoff:
        if(isTripLate(tripProps)) {
            return COLORCONSTANTS.YELLOWOPTION1;
        }

        return COLORCONSTANTS.GREENOPTION2;
    case Enum.TripState.OnBoard:
        if(isTripLate(tripProps)) {
            return COLORCONSTANTS.YELLOWOPTION1;
        }

        return COLORCONSTANTS.GREENOPTION3;
    case Enum.TripState.Accepted:
        if(isTripLate(tripProps)) {
            return COLORCONSTANTS.YELLOWOPTION1;
        }

        return COLORCONSTANTS.EVERDRIVENGREY;
    case Enum.TripState.Scheduled:
        if(hasPendingCancellation(tripProps)) {
            return COLORCONSTANTS.GREYOPTION1;
        }

        return COLORCONSTANTS.LIGHTBLUEOPTION1;
    case Enum.TripState.WillCall:
        return COLORCONSTANTS.LIGHTBLUEOPTION1;
    case Enum.TripState.Canceled:
        return COLORCONSTANTS.GREYOPTION2;
    case Enum.TripState.NoShow:
        return COLORCONSTANTS.ORANGEOPTION1;
    case Enum.TripState.Completed:
        return COLORCONSTANTS.GREENOPTION1;
    case Enum.TripState.PendingCancel:
        return COLORCONSTANTS.GREENOPTION1;
    default:
        return COLORCONSTANTS.GREENOPTION1;
    }
};

export const tripSecondaryColor = (tripState) => {
    switch (tripState) {
    case Enum.TripState.EnRoute:
    case Enum.TripState.OnSite:
    case Enum.TripState.OnSiteAtDropoff:
    case Enum.TripState.OnBoard:
    case Enum.TripState.Completed:
        return COLORCONSTANTS.EVERDRIVENSEAGREEN40;
    case Enum.TripState.Accepted:
        return COLORCONSTANTS.EVERDRIVENGREY40;
    case Enum.TripState.NoShow:
        return COLORCONSTANTS.EVERDRIVENFUCHSIA40;
    default:
        return COLORCONSTANTS.EVERDRIVENSEAGREEN40;
    }
};

export const tripStatusColor = (tripProps) => {
    switch (tripProps.State) {
    case Enum.TripState.Active:
    case Enum.TripState.Scheduled:
    case Enum.TripState.Accepted:
        if(hasPendingCancellation(tripProps)) {
            return ({
                color: COLORCONSTANTS.WHITE,
                backgroundColor: COLORCONSTANTS.EVERDRIVENFUCHSIA
            });
        }

        return ({
            color: COLORCONSTANTS.white,
            backgroundColor: COLORCONSTANTS.EVERDRIVENGREY
        });

    case Enum.TripState.EnRoute:
    case Enum.TripState.OnSite:
    case Enum.TripState.OnSiteAtDropoff:
    case Enum.TripState.OnBoard:
        if(isTripLate(tripProps) || tripProps?.IsEarly === true) {
            return ({
                color: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL,
                backgroundColor: COLORCONSTANTS.YELLOWOPTION1
            });
        }

        return ({
            color: COLORCONSTANTS.WHITE,
            backgroundColor: COLORCONSTANTS.EVERDRIVENLIGHTBLUE
        });
    
    case Enum.TripState.WillCall:
        return ({
            color: COLORCONSTANTS.WHITE,
            backgroundColor: COLORCONSTANTS.EVERDRIVENLIGHTBLUE
        });
    case Enum.TripState.Canceled:
    case Enum.TripState.NoShow:
    case Enum.TripState.PendingCancel:
        return ({
            color: COLORCONSTANTS.WHITE,
            backgroundColor: COLORCONSTANTS.EVERDRIVENFUCHSIA
        });
    case Enum.TripState.Completed:
        return ({
            color: COLORCONSTANTS.WHITE,
            backgroundColor: COLORCONSTANTS.EVERDRIVENDARKGREEN
        });
    default:
        return ({
            color: COLORCONSTANTS.WHITE,
            backgroundColor: COLORCONSTANTS.EVERDRIVENGREY
        });
    }
};

// Map Marker colors taken from TripOptionsBuilder that wasn't being used for anything else.
// These should all be consolidated with new trip tracker
export const parseColor = (trip) => {
    const isToday = calcIsToday(trip);

    switch(trip.State) {
    case Enum.TripState.OnBoard:
    case Enum.TripState.OnSite:
    case Enum.TripState.OnSiteAtDropoff:
    case Enum.TripState.EnRoute:
    case Enum.TripState.Active:
    case Enum.TripState.Accepted:
        return COLORCONSTANTS.BLUEOPTION1;
    case Enum.TripState.NoShow:
        return COLORCONSTANTS.REDOPTION1;
    case Enum.TripState.Completed:
        return COLORCONSTANTS.GREENOPTION2;
    case Enum.TripState.Canceled:
        return COLORCONSTANTS.ORANGEOPTION1;
    case Enum.TripState.WillCall:
        return isToday ? COLORCONSTANTS.ORANGEOPTION1 : COLORCONSTANTS.BLACK;
    case Enum.TripState.Scheduled:
        return isToday ? COLORCONSTANTS.BLUEOPTION1 : COLORCONSTANTS.BLACK;
    default:
        return COLORCONSTANTS.BLACK;
    }
};