/* eslint max-lines: 0 */
import { alpha } from '@mui/material/styles';
import { museoFont300, museoFont700, museoSansFont, museoSansFont500 } from 'util/EnumHelper';
import breakpointsNames from './ResponsiveConstants';
import COLORCONSTANTS from './ColorConstants';

export default {
    content: {
        fontFamily: museoSansFont,
        fontWeight: 400,
        margin: 'auto',
        marginTop: 15,
        textAlign: 'center',
        padding: '20px 95px',
        maxWidth: 500,
        width: '80%',
        background: `${COLORCONSTANTS.WHITE} 0% 0% no-repeat padding-box`,
        boxShadow: '0px -1px 16px 0px rgba(51, 51, 51, 0.15)',
        borderRadius: 12,
        opacity: 0.8
    },
    paragraph: {
        font: 'normal 28px/24px Arial, Helvetica, sans-serif',
        textAlign: 'center',
        marginTop: 30
    },
    title: { font: 'Bold 32px/38px Arial, Helvetica, sans-serif' },
    header: {
        height: 32,
        paddingLeft: 25,
        width: '100%'
    },
    headerForm: {
        height: 50,
        paddingLeft: 0,
        width: '100%',
        borderBottom: 'groove',
        '& > h5': {
            fontWeight: 'bold !important'
        }
    },
    headerContent: { flexWrap: 'nowrap', height: 32 },
    gridButton: {
        marginLeft: '20px',
        float: 'right',
        height: 46.500,
        borderRadius: '0px 12px 12px 0px',
        boxShadow: `0px 3px 6px ${alpha(COLORCONSTANTS.EVERDRIVENGREY40, 0.29)}`,
        width: 'auto'
    },
    gridContent: {
        marginTop: '5px',
        paddingLeft: 25,
        boxShadow: `0px 3px 6px ${alpha(COLORCONSTANTS.EVERDRIVENGREY40, 0.29)}`,
        borderRadius: 12
    },
    roundedButton: {
        borderRadius: '24px !important',
        border: 'none',
        padding: '8px 24px',
        color: COLORCONSTANTS.WHITE,
        margin: '12px'
    },
    gridSeparator: {
        marginRight: 10,
        border: `1px dashed ${COLORCONSTANTS.EVERDRIVENDARKGREY}`,
        opacity: 0.3
    },
    marginResult: { marginTop: 60 },
    paddingResult: { paddingTop: 40 },
    positionFixed: { position: 'fixed', zIndex: 1 },
    paragraphDefaultMargin: {
        margin: '0 0 10px',
        marginBlockStart: 0,
        marginBlockEnd: 0
    },
    filterTitle: {
        marginLeft: 0,
        marginTop: 10
    },
    errorPageContent: {
        height: 'calc(100vh - 100px)',
        marginTop: 80
    },
    errorPageContainerFluid: {
        paddingRight: 15,
        paddingLeft: 15,
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    defaultGroupLeft: {
        paddingLeft: 0,
        paddingRight: 15
    },
    defaultGroupCenter: {
        paddingLeft: 15,
        paddingRight: 15
    },
    defaultGroupRight: {
        paddingLeft: 15,
        paddingRight: 0
    },
    defaultGroupInsideLeft: {
        paddingRight: 15
    },
    defaultGroupInsideRight: {
        paddingLeft: 15
    },
    popUpRowContent: {
        fontSize: '12px',
        width: '100%',
        display: 'flex'
    },
    popUpDetailContent: {
        paddingTop: 5,
        paddingBottom: 5,
        display: 'flex'
    },
    timePickerContainer: {
        width: '40%',
        float: 'left'
    },
    spanTitle: {
        display: 'flex',
        fontWeight: 'bold',
        fontSize: '12px',
        marginTop: 10,
        width: '100%'
    },
    customFormLabel: {
        display: 'flex',
        fontWeight: 'bold',
        fontSize: '12px',
        marginTop: 10,
        width: '100%'
    },
    defaultResponsive: {
        minWidth: 330
    },
    headLine: {
        fontFamily: museoFont300,
        color: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL,
        marginLeft: '25px',
        fontWeight: 600,
        marginBottom: 0
    },
    subHeadLine: {
        color: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL,
        fontSize: 16,
        width: 150,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        fontFamily: museoFont700,
        justifyContent: 'center',
        height: 26,
        lineHeight: 1.5,
        margin: 0
    },
    bodyText: {
        fontFamily: museoSansFont,
        color: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL,
        wordWrap: 'break-word'
    },
    linkButton: {
        color: COLORCONSTANTS.EVERDRIVENLIGHTBLUE,
        fontFamily: museoSansFont,
        fontWeight: 300,
        fontSize: 16,
        textDecorationLine: 'underline',
        padding: 0,
        display: 'flex',
        lineHeight: 1.5,
        alignItems: 'flex-start'
    },
    contentConfirmModal: {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: 24,
        fontFamily: museoFont700,
        lineHeight: 1.5,
        margin: 0,
        color: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL,
        wordBreak: 'break-word',
        display: 'flex',
        flexDirection: 'column'
    },
    list: {
        fontSize: 16,
        fontFamily: museoSansFont,
        color: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL,
        fontWeight: 300,
        lineWeight: 24,
        display: 'flex',
        flexWrap: 'wrap',
        margin: 0,
        paddingLeft: 20
    },
    listItem: {
        paddingRight: 50
    },
    subTitle: {
        color: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        fontFamily: museoFont700,
        height: 56,
        justifyContent: 'center',
        margin: 0,
        lineHeight: 1.5
    }
};

export function setBackgroundImage() {
    const headerbgPage = document.getElementById('headerSearch');

    if (headerbgPage) {
        headerbgPage.style.backgroundColor = 'transparent';
    }
}

export function clearBackgroundImage() {
    const headerbgPage = document.getElementById('headerSearch');

    if (headerbgPage) {
        headerbgPage.style.backgroundColor = COLORCONSTANTS.OFFWHITE;
    }
}

const styles = (theme) => ({
    contentResponsive: {
        margin:'auto',
        marginTop: 200,
        textAlign: 'center',
        padding: 15,
        height: 152,
        background: `${theme.palette.white} 0% 0% no-repeat padding-box`,
        boxShadow: `0px 0px 6px ${alpha(COLORCONSTANTS.EVERDRIVENGREY40, 0.29)}`,
        borderRadius: 12,
        opacity: 0.8,
        width: 688,
        [theme.breakpoints.down(breakpointsNames.md)]: {
            width: 450,
            marginTop: 280
        },
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            width: 280,
            marginTop: 280
        }
    },
    titleResponsive: {
        font: 'Bold 32px/38px Arial, Helvetica, sans-serif',
        [theme.breakpoints.down(breakpointsNames.md)]: {
            font: 'Bold 24px Arial, Helvetica, sans-serif'
        },
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            font: 'Bold 20px Arial, Helvetica, sans-serif'
        }
    },
    paragraphResponsive: {
        font: 'normal 28px/24px Arial, Helvetica, sans-serif',
        textAlign: 'center',
        marginTop: 30,
        [theme.breakpoints.down(breakpointsNames.md)]: {
            font: 'normal 22px Arial, Helvetica, sans-serif'
        },
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            font: 'normal 18px Arial, Helvetica, sans-serif'
        }
    },
    searchSection: {
        background: theme.palette.everDrivenLightBlue20,
        width: 'calc(100% - 130px)',
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            width: 'calc(100% - 30px)',
            paddingLeft: 10
        },
        [theme.breakpoints.only(breakpointsNames.sm)]: {
            width: 'calc(100% - 130px)',
            "@media only screen and (orientation: landscape)": {
                width: 'calc(100% - 30px)'
            }
        }
    },
    userMgt: {
        nameColumn: {
            flex: '1 0 100px',
            boxSizing: 'border-box'
        },
        lastNameColumn: {
            flex: '1 0 100px',
            boxSizing: 'border-box'
        },
        emailColumn: {
            flex: '1 0 180px',
            boxSizing: 'border-box'
        }
    },
    borderTopDashed: {
        backgroundImage: `linear-gradient(to right,
            ${theme.palette.everDrivenLightBlue20} 40%, ${theme.palette.white} 20%)`,
        backgroundPosition: 'top',
        backgroundSize: '8px 1px',
        backgroundRepeat: 'repeat-x'
    },
    borderBottonDashed: {
        backgroundImage: `linear-gradient(to right,
				${theme.palette.everDrivenLightBlue20} 40%, ${theme.palette.white} 20%)`,
        backgroundPosition: 'bottom',
        backgroundSize: '8px 1px',
        backgroundRepeat: 'repeat-x'
    },
    tripPageHeaderBar: {
        position: 'relative',
        borderRadius: '8px',
        overflow: 'hidden',
        '&> *': {
            padding: '0 12px'
        },
        '&> :first-child': {
            background: theme.palette.everDrivenDarkBlue,
            color: theme.palette.white
        }
    },
    centerTextOnSmallScreen: {
        [theme.breakpoints.down(breakpointsNames.md)]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up(breakpointsNames.md)]: {
            textAlign: 'left'
        }
    },
    groupCardFilterButton: {
        background: 'transparent',
        border: 'none',
        fontSize: '12px',
        borderRadius: '6px',
        color: theme.palette.black,
        padding: '4px 8px',
        margin: '0 4px',
        minWidth: '40px',
        fontWeight: 500,
        '&:hover, &:active, &.active': {
            backgroundColor: `${theme.palette.everDrivenLightBlue} !important`,
            color: theme.palette.white
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: theme.palette.transparent
        }
    },
    submitButton: {
        fontSize: 12,
        fontWeight: 'bold',
        borderRadius: '6px',
        opacity: 1,
        marginRight: 15,
        backgroundColor: theme.palette.everDrivenDarkBlue,
        color: theme.palette.white,
        height: 40,
        '& > input': {
            padding: 0,
            backgroundColor: `${theme.palette.transparent} !important`
        }
    },
    selectItem: {
        '&:hover': {
            backgroundColor: theme.palette.everDrivenLightBlue20
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.everDrivenLightBlue20,
            '&:hover': {
                backgroundColor: theme.palette.everDrivenLightBlue20
            }
        }
    },
    gridButton: {
        marginLeft: '20px',
        float: 'right',
        height: 46.500,
        borderRadius: '0px 12px 12px 0px',
        boxShadow: `0px 3px 6px ${alpha(COLORCONSTANTS.EVERDRIVENGREY40, 0.29)}`,
        width: 'auto',
        '&.Mui-disabled': {
            background: theme.palette.everDrivenLightBlue40,
            '&:hover': {
                background: theme.palette.everDrivenLightBlue40
            }
        }
    },
    inputTextField: {
        backgroundColor: theme.palette.transparent,
        whiteSpace: 'nowrap',
        paddingRight: 24,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    customSubtitle: {
        fontSize: '75%',
        fontWeight: 400,
        lineHeight: 1,
        color: theme.palette.everDrivenGrey
    },
    loadMoreButton: {
        margin: '12px 0px',
        fontWeight: 'bold',
        width: 300,
        color: theme.palette.black,
        background: theme.palette.white,
        border: `${theme.palette.everDrivenDarkGrey} 2px solid`,
        height: 35,
        '&:hover': {
            color: theme.palette.white,
            backgroundColor: theme.palette.everDrivenLightBlue
        },
        '&:focus': {
            outline: 'none'
        },
        '&:disabled': {
            cursor: 'default',
            pointerEvents: 'all !important',
            backgroundColor: theme.palette.everDrivenLightBlue20,
            '&:hover': {
                color: theme.palette.disabledFont,
                backgroundColor: theme.palette.everDrivenLightBlue20
            }
        }
    },
    oldInputStyle: {
        '& .MuiFilledInput-root': {
            backgroundColor: theme.palette.everDrivenLightBlue20,
            '&:hover': {
                backgroundColor: theme.palette.everDrivenLightBlue20
            },
            '&:focus': {
                backgroundColor: theme.palette.everDrivenLightBlue20
            },
            '&.Mui-focused': {
                backgroundColor: theme.palette.everDrivenLightBlue20
            },
            '&.Mui-disabled': {
                backgroundColor: theme.palette.everDrivenLightBlue60,
                '&:hover': {
                    backgroundColor: theme.palette.everDrivenLightBlue60
                }
            }
        },
        '& .MuiFormHelperText-root.Mui-error': {
            color: theme.palette.everDrivenFuchsia
        },
        '& .MuiFormHelperText-root': {
            color: theme.palette.everDrivenFuchsia
        }
    },
    newInputStyle: {
        '& .MuiFilledInput-root': {
            backgroundColor: theme.palette.everDrivenDarkCharcoal10,
            color: theme.palette.everDrivenCharcoal,
            borderRadius: '4px 4px 0px',
            fontFamily: museoSansFont500,
            padding: 12,
            width: '100%',
            '&:hover': {
                backgroundColor: theme.palette.everDrivenDarkCharcoal10
            },
            '&:focus': {
                backgroundColor: theme.palette.everDrivenDarkCharcoal10
            },
            '&.Mui-focused': {
                backgroundColor: theme.palette.everDrivenDarkCharcoal10
            },
            '&.Mui-error': {
                backgroundColor: theme.palette.everDrivenFuchsia40,
                borderColor: theme.palette.everDrivenFuchsia,
                borderWidth: 2,
                borderStyle: 'solid'
            },
            '&.Mui-error::after': {
                backgroundColor: theme.palette.everDrivenFuchsia40,
                borderColor: theme.palette.everDrivenFuchsia,
                borderWidth: 2,
                borderStyle: 'solid'
            },
            '&.Mui-disabled': {
                backgroundColor: theme.palette.offWhite,
                color: theme.palette.everDrivenDarkCharcoal60,
                '&:hover': {
                    backgroundColor: theme.palette.offWhite,
                    color: theme.palette.everDrivenDarkCharcoal60
                }
            }
        },
        '& .MuiFilledInput-input': {
            padding: 0,
            '&.Mui-disabled': {
                backgroundColor: theme.palette.offWhite,
                color: theme.palette.everDrivenDarkCharcoal60,
                '&:hover': {
                    backgroundColor: theme.palette.offWhite,
                    color: theme.palette.everDrivenDarkCharcoal60
                }
            }
        },
        '& .MuiFormHelperText-root.Mui-error': {
            color: theme.palette.everDrivenFuchsia
        },
        '& .MuiFormHelperText-root': {
            color: theme.palette.everDrivenFuchsia,
            fontFamily: museoSansFont
        },
        marginTop: 5
    },
    newBaseInput: {
        color: theme.palette.everDrivenCharcoal,
        '&.Mui-error': {
            fontFamily: museoSansFont,
            backgroundColor: theme.palette.everDrivenFuchsia40,
            borderColor: theme.palette.everDrivenFuchsia,
            borderWidth: 2,
            borderStyle: 'solid',
            '&:after': {
                borderBottomColor: theme.palette.transparent
            }
        },
        '& .MuiInputBase-input': {
            backgroundColor: theme.palette.everDrivenDarkCharcoal10,
            borderRadius: '4px 4px 0px',
            fontFamily: museoSansFont500,
            padding: 12,
            '&.Mui-disabled': {
                backgroundColor: theme.palette.offWhite,
                color: theme.palette.everDrivenDarkCharcoal60,
                '&:hover': {
                    backgroundColor: theme.palette.offWhite,
                    color: theme.palette.everDrivenDarkCharcoal60
                }
            }
        }
    },
    cancelButton: {
        border: `1px solid ${theme.palette.everDrivenFuchsia}`,
        backgroundColor: theme.palette.transparent,
        borderRadius: 20,
        width: 75,
        height: 48,
        fontSize: 16,
        color: theme.palette.everDrivenFuchsia,
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: theme.palette.transparent,
            boxShadow: 'none'
        },
        fontFamily: museoSansFont
    },
    confirmButton: {
        // eslint-disable-next-line max-len
        background: `linear-gradient(0deg, rgba(38, 38, 94, 0.8), rgba(38, 38, 94, 0.8)), ${theme.palette.everDrivenDarkBlue}`,
        padding: '8px 20px',
        borderRadius: 20,
        width: 150,
        height: 48,
        fontSize: 16,
        fontWeight: 600,
        fontFamily: museoSansFont,
        color: theme.palette.white,
        boxShadow: 'none',
        "&:hover": {
            boxShadow: 'none'
        }
    },
    selectButton: {
        backgroundColor: theme.palette.transparent,
        padding: '8px 20px',
        borderRadius: 20,
        border: `1px solid ${theme.palette.everDrivenLightBlue}`,
        height: 48,
        fontSize: 16,
        marginTop: 5,
        fontWeight: 600,
        fontFamily: museoSansFont,
        color: theme.palette.everDrivenLightBlue,
        boxShadow: 'none',
        "&:hover": {
            boxShadow: 'none',
            backgroundColor: theme.palette.transparent
        }
    }
});

export function commonStyles(theme) {
    return styles(theme);
}
