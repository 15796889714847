/* eslint max-lines: 0 */
import mrmFetch from '../../util/fetchUtil';
import { compareValues } from '../../util/sorterUtil';
import Auth from '../../auth/Auth';
import { Enum, optionsFilterUsers as options } from '../../util/EnumHelper';
import { localize } from '../../util/Localizer';
import { initialUser } from '../constants';
import {
    setOpenUserPopUp,
    setOpenUserMobile,
    setColumnsCheckAll,
    setUserObject,
    internalUpdateLoadingCall,
    internalLoadingCall,
    internalLoad,
    internalFilteredUsers,
    setUserFirstNameColumn,
    setUserLastNameColumn,
    setUserEmailColumn,
    setUserColumnSort,
    userTermFilter,
    userOptionFilter,
    setSaveButtonDisabled,
    setUserResponse,
    setOpenUserDeleteModal,
    setFilterOptions
} from '../actions/userActions';

import breakpointsNames from '../../styles/ResponsiveConstants';

import {
    closeStyledSnackBar,
    openStyledSnackBar,
    setSnackbar
} from './styledSnackbarWrapperWorker';

const filterSorter = (allUsers, columnSorter, sort) => {
    let userList = allUsers;

    if (columnSorter) {
        userList = userList.sort(
            compareValues(columnSorter,
                sort
            )
        );
    }

    return userList;
};

const filter = (u, term) => {
    const param = term.toLowerCase().trim();
    let firstName = '';
    let lastName = '';
    let emailAddress = '';

    if (u.firstName !== null) {
        firstName = u.firstName.toLowerCase();
    }
    if (u.lastName !== null) {
        lastName = u.lastName.toLowerCase();
    }
    if (u.emailAddress !== null) {
        emailAddress = u.emailAddress.toLowerCase();
    }
    const fullName = `${firstName} ${lastName} ${emailAddress}`;
    const nameEmail = `${firstName} ${emailAddress}`;

    return (
        nameEmail.indexOf(param) > -1 ||
        fullName.indexOf(param) > -1 ||
        firstName.indexOf(param) > -1 ||
        lastName.indexOf(param) > -1 ||
        emailAddress.indexOf(term) > -1
    );
};

const filterByOptions = (list, filterOption) => {
    let userList = list;

    switch (filterOption) {
    case options[Enum.filterUsers.allUsers].id:
        userList = list;
        break;
    case options[Enum.filterUsers.admins].id:
        userList = list.filter(r => r.isAdministrator);
        break;
    case options[Enum.filterUsers.progMgmt].id:
        userList = list.filter(r => r.canAccessProgramManagement);
        break;
    case options[Enum.filterUsers.DTT].id:
        userList = list.filter(r => r.canAccessTripTracking);
        break;
    case options[Enum.filterUsers.VIP].id:
        userList = list.filter(r => r.isTeacher);
        break;
    case options[Enum.filterUsers.activeUsers].id:
        userList = list.filter(r => r.accountEnabled);
        break;
    case options[Enum.filterUsers.inactiveUsers].id:
        userList = list.filter(r => !r.accountEnabled);
        break;
    default:
        break;
    }

    return userList;
};

export const filterUsers = () => (dispatch, getState) => {
    const {
        users: allUsers,
        term,
        filterOption,
        filterOptions,
        columnSorter,
        columns
    } = getState().UserReducers;

    dispatch(internalLoadingCall(true));

    const usersList = JSON.parse(JSON.stringify(allUsers));

    const userOrderedList = filterSorter(
        usersList,
        columnSorter,
        columns[columnSorter].sort
    );
    const userFilteredList = userOrderedList.filter(f => filter(f, term));
    const userList = filterByOptions(userFilteredList, filterOption);

    filterOptions[Enum.filterUsers.allUsers].quantity = userFilteredList.length;
    filterOptions[Enum.filterUsers.admins].quantity = userFilteredList.filter(r => r.isAdministrator).length;
    filterOptions[Enum.filterUsers.progMgmt].quantity =
        userFilteredList.filter(r => r.canAccessProgramManagement).length;
    filterOptions[Enum.filterUsers.DTT].quantity = userFilteredList.filter(r => r.canAccessTripTracking).length;
    filterOptions[Enum.filterUsers.VIP].quantity = userFilteredList.filter(r => r.isTeacher).length;
    filterOptions[Enum.filterUsers.activeUsers].quantity = userFilteredList.filter(r => r.accountEnabled).length;
    filterOptions[Enum.filterUsers.inactiveUsers].quantity = userFilteredList.filter(r => !r.accountEnabled).length;

    dispatch(setFilterOptions(filterOptions));
    dispatch(internalFilteredUsers(userList, userFilteredList));
    dispatch(internalLoadingCall(false));
    dispatch(setColumnsCheckAll(false));
};

const initializeUser = (users) => users.map((u) => Object.assign(
    u,
    {
        isChecked: false,
        allowedSchools: u.allowedSchools ?? [],
        allowedSchoolsVipApp: u.allowedSchoolsVipApp ?? []
    }
));

export const loadUsers = users => async (dispatch) => {
    dispatch(internalLoadingCall(true));

    if (!users) {
        const userResponse = await mrmFetch(`/api/v2/User`, 'get');

        window.log(`found users`, userResponse);
        dispatch(internalLoad(initializeUser(userResponse)));
        dispatch(internalLoadingCall(false));
    } else {
        dispatch(internalLoad(initializeUser(users)));
        dispatch(internalLoadingCall(false));
    }
};

export const addUser = user => async (dispatch) => {
    dispatch(internalUpdateLoadingCall(true));

    if (user) {
        const userResponse = await mrmFetch(`/api/v2/User`, 'post', null, JSON.stringify(user));

        window.log(`found users`, userResponse);

        if (userResponse.length > 0) {
            dispatch(setUserResponse(userResponse));
        } else {
            dispatch(setSnackbar(
                localize('userManagement.userAddedMessage'),
                Enum.severityType.success
            ));
            dispatch(openStyledSnackBar());

            dispatch(loadUsers(null));
            dispatch(setOpenUserPopUp(false));
            dispatch(setOpenUserMobile(false));
            dispatch(setUserObject({...initialUser}));
        }

        dispatch(internalUpdateLoadingCall(false));
    } else {
        window.log(`Empty user object`, user);
        dispatch(internalUpdateLoadingCall(false));
        dispatch(setOpenUserPopUp(false));
    }
};

export const editUser = user => async (dispatch) => {
    dispatch(internalUpdateLoadingCall(true));

    if (user) {
        const userResponse = await mrmFetch(`/api/v2/User`, 'PATCH', null, JSON.stringify(user));

        window.log(`found users`, userResponse);
        if (userResponse.length > 0) {
            dispatch(setUserResponse(userResponse));
        } else {
            dispatch(setSnackbar(
                localize('userManagement.userUpdatedMessage'),
                Enum.severityType.success
            ));
            dispatch(openStyledSnackBar());

            dispatch(loadUsers(null));
            dispatch(setOpenUserPopUp(false));
        }

        dispatch(internalUpdateLoadingCall(false));
    } else {
        window.log(`Empty user object`, user);
        dispatch(internalUpdateLoadingCall(false));
        dispatch(setOpenUserPopUp(false));
    }
};

export const sendInvitationUser = user => async dispatch => {
    dispatch(internalUpdateLoadingCall(true));

    if (user) {
        const userResponse = await mrmFetch(
            `/api/v2/User/Invitation`,
            'POST',
            null,
            JSON.stringify(user)
        );

        window.log(`found users`, userResponse);

        if (userResponse.length > 0) {
            dispatch(setUserResponse(userResponse));
        } else {
            dispatch(
                setSnackbar(
                    localize('userManagement.invitationResent'),
                    Enum.severityType.success
                )
            );
            dispatch(openStyledSnackBar());
            dispatch(setOpenUserPopUp(false));
        }
        dispatch(internalUpdateLoadingCall(false));
    } else {
        window.log(`Empty user object`, user);
        dispatch(internalUpdateLoadingCall(false));
        dispatch(setOpenUserPopUp(false));
    }
};

export const deleteBatchUser = userList => async (dispatch) => {
    dispatch(internalUpdateLoadingCall(true));
    dispatch(setOpenUserDeleteModal(false));

    let nProcess = 0;
    let nResponse = '';

    await Promise.all(userList.map(async (user) => {
        const userResponse = await mrmFetch(`/api/v2/User/${user.id}`, 'DELETE', null, null);

        window.log(`found users`, userResponse);
        if (userResponse.length > 0) {
            nResponse = `${nResponse}${user.id}-${userResponse}. `;
        }

        nProcess += 1;

        if (nProcess === userList.length) {
            if (nResponse.length > 0) {
                dispatch(setUserResponse(nResponse));
            } else {
                dispatch(setSnackbar(
                    localize('userManagement.userDeletedBatchMessage'),
                    Enum.severityType.success
                ));
                dispatch(openStyledSnackBar());
                dispatch(loadUsers(null));
            }

            dispatch(internalUpdateLoadingCall(false));
        }
    }));

    dispatch(internalUpdateLoadingCall(false));
    dispatch(setColumnsCheckAll(false));
};

// -------- Columns
const changeSort = sort => {
    if (sort === Enum.Sorter.Asc) {
        return Enum.Sorter.Desc;
    }

    return Enum.Sorter.Asc;
};

export const sortUsersByColumn = columnName => (dispatch, getState) => {
    const { columns } = getState().UserReducers;

    const newSort = changeSort(columns[columnName].sort);

    if (columnName === 'firstName') {
        dispatch(setUserFirstNameColumn(newSort));
    } else if (columnName === 'lastName') {
        dispatch(setUserLastNameColumn(newSort));
    } else {
        dispatch(setUserEmailColumn(newSort));
    }

    dispatch(setOpenUserMobile(false));
    dispatch(closeStyledSnackBar());
    dispatch(setUserColumnSort(columnName));
    dispatch(setUserObject({ ...initialUser }));
};
// -------- Search
export const setUserTermFilter = term => (dispatch) => {
    dispatch(setOpenUserMobile(false));
    dispatch(closeStyledSnackBar());

    dispatch(userTermFilter(term));
    dispatch(setUserObject({ ...initialUser }));
};

// -------- Filters
export const setUserOptionFilter = option => (dispatch, getState) => {
    dispatch(setOpenUserMobile(false));
    dispatch(closeStyledSnackBar());

    const { filterOption } = getState().UserReducers;
    const IdOption = option !== undefined ? option.id : filterOption;

    dispatch(userOptionFilter(IdOption));
};

export const onShowDeleteModal = () => (dispatch, getState) => {
    const { filteredUsers } = getState().UserReducers;
    const selectedList = filteredUsers.filter(x => x.isChecked);

    if (selectedList.length > 0) {
        dispatch(setOpenUserDeleteModal(true));
    }
};

export const onDeleteUser = () => (dispatch, getState) => {
    const { filteredUsers } = getState().UserReducers;
    const selectedList = filteredUsers.filter(x => x.isChecked);

    if (selectedList.length > 0) {
        dispatch(setOpenUserMobile(false));
        dispatch(closeStyledSnackBar());
        dispatch(deleteBatchUser(selectedList));
    }
};

// -------- PopUp
export const handleClosePopUp = () => (dispatch) => {
    dispatch(setUserObject({ ...initialUser }));
    dispatch(setOpenUserPopUp(false));
};

// -------- Check User
export const handleChangeCheck = (id, checked) => (dispatch, getState) => {
    const {
        filteredUsers,
        orderedUsers
    } = getState().UserReducers;
    const users = JSON.parse(JSON.stringify(filteredUsers));

    users.forEach(item => {
        const u = item;

        if (u.id === id) {
            u.isChecked = checked;
        }
    });
    dispatch(internalFilteredUsers(users, orderedUsers));
};

export const handleChangeCheckAll = (checked) => (dispatch, getState) => {
    const {
        filteredUsers,
        orderedUsers
    } = getState().UserReducers;
    const users = JSON.parse(JSON.stringify(filteredUsers));

    users.forEach(user => {
        const u = user;
        const checkDisabled = Auth.getName() === u.emailAddress;

        if (!checkDisabled) {
            u.isChecked = checked;
        }
    });

    dispatch(internalFilteredUsers(users, orderedUsers));
    dispatch(setColumnsCheckAll(checked));
};

export const openUserForm = (width) => dispatch => {
    if (width === breakpointsNames.xs) {
        dispatch(setUserTermFilter(''));
        dispatch(setOpenUserMobile(true));
    } else {
        dispatch(setOpenUserPopUp(true));
    }

    dispatch(closeStyledSnackBar());
};

// -------- Submit
export const onSubmit = () => (dispatch, getState) => {
    const { user } = getState().UserReducers;

    dispatch(setSaveButtonDisabled(true));
    dispatch(addUser(user));
};
