import { Enum } from 'util/EnumHelper';
import mrmFetch, { mrmFetchFormData } from 'util/fetchUtil';
import { downloadFile } from 'util/fileUtil';
import { localize } from 'util/Localizer';
import { fixedEncodeURIComponent } from 'util/textFormatters';
import { getPathTabValue } from 'util/updatePathTab';
import {
    clearRejectedFiles,
    setCalendarAttachments,
    setCalendars,
    setCalendarsUploaded,
    setCalendarUploadLoading,
    setCalendarUploadNotes,
    setClearModalOpen,
    setDeleteAttachmentModalOpen,
    setDeleteFileName,
    setLeaveNoSavingModalOpen,
    setNotesModalOpen,
    setSelectedNotes
} from 'redux/actions/calendarUploadActions';
import { updatePath } from './pathWorker';
import { onSnackBarPropertyChange } from './styledSnackbarWrapperWorker';

export const onLoadForm = () => dispatch => {
    dispatch(setCalendars([]));
    dispatch(setCalendarAttachments([]));
    dispatch(clearRejectedFiles([]));
    dispatch(setCalendarUploadNotes(''));
};

export const onGetUploadedCalendars = (pageNumber) => async dispatch => {
    try {
        const calendars = await mrmFetch(`/api/v2/CalendarFiles?skip=${pageNumber}`, 'GET');

        dispatch(setCalendarsUploaded(calendars));
    } catch (error) {
        window.warn('error in onGetUploadedCalendars',
            error
        );
    }
};

export const onOpenDeleteAttachmentModal = (fileName) => dispatch => {
    dispatch(setDeleteAttachmentModalOpen(true));
    dispatch(setDeleteFileName(fileName));
};

export const onDeleteUploadedCalendar = () => async (dispatch, getState) => {
    const { deleteFileName, calendarUploadedList } = getState().CalendarUploadReducers;

    dispatch(setCalendarUploadLoading(true));
    dispatch(setDeleteAttachmentModalOpen(false));
    
    try {
        const isFileDeleted = await mrmFetch(
            `/api/v2/DeleteCalendar/${fixedEncodeURIComponent(deleteFileName)}`,
            'DELETE'
        );

        if (isFileDeleted) {
            dispatch(onSnackBarPropertyChange(
                Enum.severityType.success,
                localize('calendarUpload.successDelete')
            ));
            dispatch(onGetUploadedCalendars(calendarUploadedList.pageNumber));
        } else {
            dispatch(onSnackBarPropertyChange(
                Enum.severityType.error,
                localize('calendarUpload.errorDelete')
            ));
        }

        dispatch(setCalendarUploadLoading(false));
    } catch (error) {
        dispatch(setCalendarUploadLoading(false));
        window.warn('error in onDeleteUploadedCalendar',
            error
        );
    }
};

export const onDownloadCalendarAttachment = (fileName) => async dispatch => {
    dispatch(setCalendarUploadLoading(true));

    try {
        const responseMessage = await mrmFetch(
            `/api/v2/DownloadCalendar/${fixedEncodeURIComponent(fileName)}`,
            'get',
            null,
            null,
            true
        );

        if (responseMessage === '') {
            dispatch(onSnackBarPropertyChange(
                Enum.severityType.error,
                localize('calendarUpload.downloadError')
            ));
        } else {
            downloadFile(responseMessage);
    
            dispatch(onSnackBarPropertyChange(
                Enum.severityType.success,
                localize('calendarUpload.downloadSuccess')
            ));
        }
    } catch (error) {
        dispatch(setCalendarUploadLoading(false));
        window.warn('error in onDeleteUploadedCalendar',
            error
        );
    }

    dispatch(setCalendarUploadLoading(false));
};

export const submitCalendars = () => async (dispatch, getState) => {
    const { calendars, notes } = getState().CalendarUploadReducers;

    dispatch(setCalendarUploadLoading(true));

    const formData = new FormData();

    calendars.map((calendar, index) => {
        formData.append('file', calendar.file);
        formData.append(`calendarDescription${index+1}`, calendar.typeDescription.trim());
        formData.append(`calendarFileName${index+1}`, calendar.file.name);
        
        return true;
    });

    formData.append('notes', notes.trim());

    try {
        await mrmFetchFormData(
            `/api/v2/UploadCalendarFiles`,
            formData,
            'post'
        );

        dispatch(setCalendarUploadLoading(false));
        dispatch(onLoadForm());
        dispatch(onGetUploadedCalendars(1));
        dispatch(onSnackBarPropertyChange(
            Enum.severityType.success,
            localize('calendarUpload.successSubmit')
        ));
    } catch (e) {
        window.warn('error in submitCalendars',
            e
        );
        
        dispatch(setCalendarUploadLoading(false));
        dispatch(onSnackBarPropertyChange(
            Enum.severityType.error,
            localize('calendarUpload.errorSubmit')
        ));
    }
};

export const onUploadCalendarFiles = (files) => (dispatch, getState) => {
    const { calendars } = getState().CalendarUploadReducers;
    
    dispatch(setCalendarAttachments(files));

    const newCalendars = [];
        
    files.map((file, index) => {
        if (index > calendars.length - 1) {
            newCalendars.push({
                file,
                selectedType: '',
                typeDescription: ''
            });
        }

        return null;
    });
        
    dispatch(setCalendars([ ...calendars, ...newCalendars ]));
};

export const onDeleteFile = nRow => (dispatch, getState) => {
    const { calendars, calendarAttachments } = getState().CalendarUploadReducers;
    
    const updatedFiles = calendarAttachments.filter((file, index) => index !== nRow);
    const updatedCalendars = calendars.filter((c, index) => index !== nRow);
        
    dispatch(setCalendarAttachments(updatedFiles));
    dispatch(setCalendars(updatedCalendars));
};

export const onClearCalendarUpload = () => (dispatch) => {
    dispatch(setCalendarUploadLoading(true));
    dispatch(onLoadForm());
    dispatch(setClearModalOpen(false));
    dispatch(setCalendarUploadLoading(false));
};

export const onLeaveCalendarUpload = (nextPath) => dispatch => {
    dispatch(onLoadForm());
    dispatch(updatePath(getPathTabValue(nextPath)));
    dispatch(setLeaveNoSavingModalOpen(false));
};

export const onOpenNotesModal = (notes) => dispatch => {
    dispatch(setNotesModalOpen(true));
    dispatch(setSelectedNotes(notes));
};
