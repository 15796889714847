import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from '@mui/styles/withStyles';

import { localize } from '../../util/Localizer';
import StyledButton from '../../components/Common/StyledButton/StyledButton';

const useStyles = () => ({
    control: {
        display: 'flex'
    },
    tab: {
        marginLeft: 0,
        marginBottom: 5,
        borderRadius: '4px 0 0 0',
        height: 45,
        minWidth: 170
    },
    delete: {
        marginLeft: 0,
        marginBottom: 5,
        borderRadius: '0 4px 0 0',
        height: 45,
        minWidth: 40,
        width: 40,
        marginRight: 15
    }
});

function CommonTabControl({
    classes,
    isCurrentTab,
    siblingNumber,
    totalSibling,
    variant,
    onClearForm,
    onDeleteTab,
    onSelectTab
}) {
    const handleDelete = () => {
        if (totalSibling === 1) {
            onClearForm();
        } else {
            onDeleteTab();
        }
    };

    const handleSelect = () => {
        onSelectTab();
    };

    return (
        <div className={classes.control}>
            {
                isCurrentTab ?
                    (
                        <>
                            <StyledButton
                                className={classes.tab}
                                color="primary"
                                testId={"student".concat(siblingNumber.toString()).concat("Btn")}
                                variant={variant}
                                onClick={handleSelect}
                            >
                                {localize('tripRequestForm.siblingNumber', { number: siblingNumber.toString() })}
                            </StyledButton>
                            <StyledButton
                                className={classes.delete}
                                color="primary"
                                testId={"student".concat(siblingNumber.toString()).concat("RemoveBtn")}
                                variant={variant}
                                onClick={handleDelete}
                            >
                                <CloseIcon />
                            </StyledButton>
                        </>
                    ) :
                    (
                        <>
                            <StyledButton
                                className={classes.tab}
                                color="tabSiblingNonSelected"
                                testId={"student".concat(siblingNumber.toString()).concat("Tab")}
                                variant={variant}
                                onClick={handleSelect}
                            >
                                {localize('tripRequestForm.siblingNumber', { number: siblingNumber.toString() })}
                            </StyledButton>
                            <StyledButton
                                className={classes.delete}
                                color="tabSiblingNonSelected"
                                testId={"student".concat(siblingNumber.toString()).concat("RemoveBtn")}
                                variant={variant}
                                onClick={handleDelete}
                            >
                                <CloseIcon />
                            </StyledButton>
                        </>
                    )
            }
        </div>
    );
}

export default withStyles(useStyles)(CommonTabControl);
