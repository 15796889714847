import { connect } from 'react-redux';
import AddressInformation from '../../../controls/TripRequestForm/AddressInformation';
import {
    onUpdateModel
} from '../../workers/tripRequestFormWorker';
import {
    onSelectPreviousHomeAddress,
    onSelectPreviousPickUpAddress,
    onSelectPreviousDropOffAddress,
    onUpdateLocation,
    onUpdateHomeAddress,
    onUpdateHomeAddress2,
    onUpdatePickUpAddress,
    onUpdatePickUpAddress2,
    onUpdateDropOffAddress,
    onUpdateDropOffAddress2,
    setIsSameAddress
} from '../../workers/tripRequestFormAddressWorker';
import { updateModel } from '../../actions/tripRequestFormActions';

const mapStateToProps = state => {
    const { TripRequestFormReducers } = state;

    return {
        nonSchoolAddressList: TripRequestFormReducers.nonSchoolAddressList,
        tripModel: TripRequestFormReducers.tripModel,
        selectedHomeAddress: TripRequestFormReducers.selectedHomeAddress,
        selectedHomeAddress2: TripRequestFormReducers.selectedHomeAddress2,
        selectedPreviousHomeAddress: TripRequestFormReducers.selectedPreviousHomeAddress,
        selectedPickUpAddress: TripRequestFormReducers.selectedPickUpAddress,
        selectedPickUpAddress2: TripRequestFormReducers.selectedPickUpAddress2,
        selectedPreviousPickUpAddress: TripRequestFormReducers.selectedPreviousPickUpAddress,
        selectedDropOffAddress: TripRequestFormReducers.selectedDropOffAddress,
        selectedDropOffAddress2: TripRequestFormReducers.selectedDropOffAddress2,
        selectedPreviousDropOffAddress: TripRequestFormReducers.selectedPreviousDropOffAddress,
        showDifferentAddress: TripRequestFormReducers.showDifferentAddress,
        currentSibling: TripRequestFormReducers.currentSibling,
        isSameAddress: TripRequestFormReducers.isSameAddress
    };
};

const AddressInformationContainer = connect(mapStateToProps,
    {
        onSelectPreviousHomeAddress,
        onSelectPreviousPickUpAddress,
        onSelectPreviousDropOffAddress,
        onUpdateModel,
        onUpdateLocation,
        onUpdateHomeAddress,
        onUpdateHomeAddress2,
        onUpdatePickUpAddress,
        onUpdatePickUpAddress2,
        onUpdateDropOffAddress,
        onUpdateDropOffAddress2,
        setIsSameAddress,
        updateModel
    })(AddressInformation);

export default AddressInformationContainer;
