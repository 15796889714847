import React from "react";
import {
    array,
    func,
    object,
    string,
    bool
} from "prop-types";
import { Grid, FormControl, RadioGroup, Radio } from '@mui/material';
import StyledFormControlLabel from "../StyledFormControlLabel/StyledFormControlLabel";
import StyledSectionHeader from "../StyledSectionHeader/StyledSectionHeader";

function StyledRadioButtonsSelect({
    title,
    name,
    value,
    options,
    extraStyle,
    textButton,
    icon,
    buttonColor,
    isRequired,
    onChange,
    onClick
}) {
    const setValue = (evt) => {
        onChange(evt.target.defaultValue);
    };

    return (
        <>
            <StyledSectionHeader
                title={title}
                onClick={onClick}
                icon={icon}
                textButton={textButton}
                extraStyle={{
                    ...extraStyle
                }}
                buttonColor={buttonColor}
                isRequired={isRequired}
            />
            <Grid item sx={{ paddingTop: '11px' }}>
                <FormControl>
                    <RadioGroup
                        aria-label={name}
                        name={name}
                        row
                        value={value}
                        onChange={setValue}
                        data-testid={name}
                    >
                        {options.map((option) => (
                            <StyledFormControlLabel
                                key={option.id}
                                testId={option.id.concat("option")}
                                value={option.id}
                                control={<Radio color="secondary"/>}
                                label={option.label}
                                extraStyle={{ paddingRight: 15 }}
                            />
                        ))}

                    </RadioGroup>
                </FormControl>
            </Grid>
        </>

    );
}

StyledRadioButtonsSelect.defaultProps = {
    textButton: '',
    icon: null,
    buttonColor: '',
    isRequired: false,
    title: '',
    name: '',
    options: [],
    value: '',
    extraStyle: null,
    onChange: null,
    onClick: null
};

/* eslint-disable react/forbid-prop-types */
StyledRadioButtonsSelect.propTypes = {
    textButton: string,
    icon: object,
    buttonColor: string,
    isRequired: bool,
    extraStyle: object,
    title: string,
    name: string,
    options: array,
    value: string,
    onClick: func,
    onChange: func
};

export default StyledRadioButtonsSelect;
