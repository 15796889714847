import { arrayOf, bool, string } from 'prop-types';

const userShape =
{
    firstName: string,
    lastName: string,
    emailAddress: string,
    isAdministrator: bool,
    canAccessBilling: bool,
    canAccessProgramManagement: bool,
    canAccessTripTracking: bool,
    isProfessionalAgent: string,
    allowedSchools: arrayOf(string),
    allowedSchoolsVipApp: arrayOf(string),
    accountEnabled: bool
};

export default userShape;