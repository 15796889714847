import React from 'react';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { localize } from 'util/Localizer';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import { museoSansFont, paths } from 'util/EnumHelper';
import useBlocker from 'util/customHooks/useBlocker';
import StyledConfirmModal from 'components/Common/StyledConfirmModal/StyledConfirmModal';

const LinkButton = styled(StyledButton)(({ theme }) => ({
    color: theme.palette.everDrivenLightBlue,
    backgroundColor: theme.palette.transparent,
    boxShadow: 'none',
    fontFamily: museoSansFont,
    fontWeight: 300,
    fontSize: 18,
    textDecorationLine: 'underline',
    padding: 0,
    "&:hover": {
        backgroundColor: theme.palette.transparent,
        boxShadow: 'none',
        textDecorationLine: 'underline'
    }
}));

const CancelButton = styled(StyledButton)(({ theme }) => ({
    border: `1px solid ${theme.palette.everDrivenFuchsia}`,
    backgroundColor: theme.palette.transparent,
    borderRadius: 20,
    width: 75,
    height: 48,
    fontSize: 16,
    color: theme.palette.everDrivenFuchsia,
    boxShadow: 'none',
    "&:hover": {
        backgroundColor: theme.palette.transparent,
        boxShadow: 'none'
    }
}));

const NextButton = styled(StyledButton)(({ theme }) => ({
    // eslint-disable-next-line max-len
    background: `linear-gradient(0deg, rgba(38, 38, 94, 0.8), rgba(38, 38, 94, 0.8)), ${theme.palette.everDrivenDarkBlue}`,
    padding: '8px 20px',
    borderRadius: 20,
    width: 150,
    height: 48,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: museoSansFont,
    color: theme.palette.white,
    boxShadow: 'none',
    "&:hover": {
        boxShadow: 'none'
    }
}));

const BackButton = styled(StyledButton)(({ theme }) => ({
    marginRight: 20,
    backgroundColor: theme.palette.transparent,
    padding: '8px 20px',
    borderRadius: 20,
    border: `1px solid ${theme.palette.everDrivenDarkBlue}`,
    width: 150,
    height: 48,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: museoSansFont,
    color: theme.palette.everDrivenDarkBlue,
    boxShadow: 'none',
    "&:hover": {
        boxShadow: 'none',
        backgroundColor: theme.palette.transparent
    }
}));

function TRNav({
    draftCanBeSaved,
    isOuterContainer,
    nextPath,
    showSaveDraftModal,
    newTR,
    tripNumberError,
    tabValue,
    closeSaveDraftModal,
    showErrorSubmitModal,
    showFailedSubmitModal,
    showSuccessSubmitModal,
    onChangeTab,
    onCloseWithoutSave,
    onExitTRF,
    onSaveDraft,
    setNextPath,
    submitForm,
    closeFailedSubmitModal,
    closeErrorSubmitModal,
    closeSuccessSubmitModal,
    editTrip,
    updatePath,
    isSubmitDisabled
}) {
    const onHandleClickNext = () => {
        onChangeTab(tabValue+1);
    };

    const closeAllModals = () => {
        closeSaveDraftModal();
        closeErrorSubmitModal();
        closeFailedSubmitModal();
        closeSuccessSubmitModal();
    };

    const onBtnSaveDraft = () => {
        closeAllModals();
        onSaveDraft(nextPath);
    };

    const onBtnCloseWithoutSave = () => {
        closeAllModals();
        onCloseWithoutSave(nextPath);
    };

    const blocker = (tx) => {
        if (draftCanBeSaved) {
            setNextPath(tx.location.pathname);
            onExitTRF();
        } else {
            tx.retry();
        }
    };

    const contactLink = () => (
        <LinkButton
            testId="LinkButton"
            type="button"
            onClick={() => updatePath(paths.Support.tabValue)}
        >
            {localize('transportationRequest.failedSubmitModalContent2Link')}
        </LinkButton>
    );

    useBlocker(blocker, !draftCanBeSaved);

    return (
        <Grid container sx={
            {
                padding: isOuterContainer
                    ? '20px 130px 20px 130px'
                    : '0px 130px 40px 130px'
            }}
        >
            <Grid item xs={6}>
                <CancelButton
                    testId="CancelTransportationRequestButton"
                    type="button"
                    onClick={() => updatePath(paths.DraftList.tabValue)}
                >
                    <CloseIcon />
                </CancelButton>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {tabValue > 0 &&
                    <BackButton
                        testId="BackTransportationRequestButton"
                        type="button"
                        onClick={() => onChangeTab(tabValue-1)}
                    >
                        {localize('transportationRequest.backButton')}
                    </BackButton>
                }
                {tabValue < 3 &&
                    <NextButton
                        testId="NextTransportationRequestButton"
                        type="button"
                        onClick={onHandleClickNext}
                    >
                        {localize('transportationRequest.nextButton')}
                    </NextButton>
                }
                {tabValue === 3 &&
                    <NextButton
                        testId="SubmitTransportationRequestButton"
                        type="button"
                        isDisabled={isSubmitDisabled}
                        onClick={() => submitForm()}
                    >
                        {localize('transportationRequest.submit')}
                    </NextButton>
                }
            </Grid>
            <StyledConfirmModal
                isOpen={showSaveDraftModal}
                disableBackDrop
                disableEscKey
                dataId="closeSaveDraftModalBtn"
                contentTitle={localize('transportationRequest.confirmSaveDraftTitle')}
                contentMessage={localize('transportationRequest.confirmSaveDraftContent')}
                onClose={closeSaveDraftModal}
                confirmInputLabel={localize('transportationRequest.saveAndClose')}
                cancelInputLabel={localize('transportationRequest.closeWithoutSaving')}
                onConfirm={onBtnSaveDraft}
                onCancel={onBtnCloseWithoutSave}
            />
            <StyledConfirmModal
                isOpen={showErrorSubmitModal}
                disableBackDrop
                disableEscKey
                dataId="closeErrorSubmitModalBtn"
                contentTitle={localize('transportationRequest.failedSubmitModalTitle')}
                contentMessage={localize('transportationRequest.errorSubmitModalContent')}
                contentMessage2={localize('transportationRequest.errorSubmitModalContent2', {link: contactLink()})}
                onClose={closeErrorSubmitModal}
                confirmInputLabel={localize('transportationRequest.saveAndClose')}
                cancelInputLabel={localize('transportationRequest.closeWithoutSaving')}
                onConfirm={onBtnSaveDraft}
                onCancel={onBtnCloseWithoutSave}
            />
            <StyledConfirmModal
                isOpen={showFailedSubmitModal}
                disableBackDrop
                disableEscKey
                dataId="closeFailedSubmitModalBtn"
                contentTitle={localize('transportationRequest.failedSubmitModalTitle')}
                contentMessage={localize('transportationRequest.failedSubmitModalContent',
                    { tripNumber: tripNumberError })}
                contentMessage2={localize('transportationRequest.failedSubmitModalContent2', {link: contactLink()})}
                onClose={closeFailedSubmitModal}
                confirmInputLabel={localize('transportationRequest.editTrip')}
                cancelInputLabel={localize('transportationRequest.close')}
                onConfirm={editTrip}
                onCancel={closeFailedSubmitModal}
            />
            <StyledConfirmModal
                isOpen={showSuccessSubmitModal}
                disableBackDrop
                disableEscKey
                isSuccess
                contentMessage={localize('transportationRequest.successSubmitModalTitle')}
                confirmInputLabel={localize('transportationRequest.createNewRequest')}
                cancelInputLabel={localize('transportationRequest.openDraftList')}
                onConfirm={newTR}
                onCancel={onBtnCloseWithoutSave}
            />
        </Grid>
    );
}

TRNav.defaultProps = {
    draftCanBeSaved: false,
    showSaveDraftModal: false,
    nextPath: '',
    tripNumberError: null,
    tabValue: 0,
    closeSaveDraftModal: null,
    onChangeTab: null,
    onCloseWithoutSave: null,
    onExitTRF: null,
    onSaveDraft: null,
    setNextPath: null,
    submitForm: null,
    closeFailedSubmitModal: null,
    closeErrorSubmitModal: null,
    editTrip: null,
    updatePath: null,
    isSubmitDisabled: false
};

TRNav.propTypes = {
    draftCanBeSaved: PropTypes.bool,
    showSaveDraftModal: PropTypes.bool,
    nextPath: PropTypes.string,
    tabValue: PropTypes.number,
    closeSaveDraftModal: PropTypes.func,
    onChangeTab: PropTypes.func,
    tripNumberError: PropTypes.number,
    onCloseWithoutSave: PropTypes.func,
    onExitTRF: PropTypes.func,
    onSaveDraft: PropTypes.func,
    setNextPath: PropTypes.func,
    submitForm: PropTypes.func,
    closeFailedSubmitModal: PropTypes.func,
    closeErrorSubmitModal: PropTypes.func,
    editTrip: PropTypes.func,
    updatePath: PropTypes.func,
    isSubmitDisabled: PropTypes.bool
};

export default TRNav;