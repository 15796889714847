/* eslint max-lines: 0 */
import _ from 'lodash';
import { objectEmptyValidator } from "../../util/validationUtil";
import { isNewCorrect } from "./tripRequestFormWorker";
import {
    setIsSameAddressCheck,
    setSelectedDropOffAddress,
    setSelectedDropOffAddress2,
    setSelectedHomeAddress,
    setSelectedHomeAddress2,
    setSelectedPickUpAddress,
    setSelectedPickUpAddress2,
    setSelectedPreviousDropOffAddress,
    setSelectedPreviousHomeAddress,
    setSelectedPreviousPickUpAddress,
    setShowDifferentAddress,
    updateModel
} from "../actions/tripRequestFormActions";

export const onUpdateLocation = (event) => (dispatch) => {
    const { name, value } = event.target;
    const differentAddress = "Different Address";

    switch (name) {
    case 'amPickupLocation': {
        let isPk = false;

        if (value === differentAddress) {
            isPk = true;
            dispatch(updateModel({
                amPickupLocation: value,
                pickupLocationAddressLine1: '',
                pickupLocationAddressLine2: '',
                pickupLocationAddressCity: '',
                pickupLocationAddressZipCode: '',
                pickupLocationAddressState: '',
                pickupLocationAddressLatitude: '',
                pickupLocationAddressLongitude: ''
            }));
        } else {
            dispatch(updateModel({
                amPickupLocation: value
            }));
        }
        dispatch(setShowDifferentAddress({
            pk: isPk
        }));
    }
        break;
    case 'pmDropOffLocation': {
        let isDo = false;

        if (value === differentAddress) {
            isDo = true;
            dispatch(updateModel({
                pmDropOffLocation: value,
                dropOffLocationAddressLine1: '',
                dropOffLocationAddressLine2: '',
                dropOffLocationAddressCity: '',
                dropOffLocationAddressZipCode: '',
                dropOffLocationAddressState: '',
                dropOffLocationAddressLatitude: '',
                dropOffLocationAddressLongitude: ''
            }));
        } else {
            dispatch(updateModel({
                pmDropOffLocation: value
            }));
        }
        dispatch(setShowDifferentAddress({
            do: isDo
        }));
    }
        break;
    default:
        break;
    }

    dispatch(isNewCorrect());
};

export const setIsSameAddress = (checked) => (dispatch, getState) => {
    const { tripModels } = getState().TripRequestFormReducers;

    dispatch(setIsSameAddressCheck(checked));
    if (checked) {
        const studentOne = tripModels.find(x => x.student === 1);
        const {
            tripModel,
            showDifferentAddress,
            selectedHomeAddress,
            selectedHomeAddress2,
            selectedPickUpAddress,
            selectedPickUpAddress2,
            selectedDropOffAddress,
            selectedDropOffAddress2
        } = studentOne;

        dispatch(updateModel({
            studentAddressLine1: tripModel.studentAddressLine1 ?? '',
            studentAddressLine2: tripModel.studentAddressLine2 ?? '',
            studentAddressCity: tripModel.studentAddressCity ?? '',
            studentAddressZipCode: tripModel.studentAddressZipCode ?? '',
            studentAddressState: tripModel.studentAddressState ?? '',
            studentAddressLatitude: tripModel.studentAddressLatitude ?? '',
            studentAddressLongitude: tripModel.studentAddressLongitude ?? '',
            amPickupLocation: tripModel.amPickupLocation ?? '',
            pmDropOffLocation: tripModel.pmDropOffLocation ?? ''
        }));

        dispatch(setSelectedHomeAddress(selectedHomeAddress));
        dispatch(setSelectedHomeAddress2(selectedHomeAddress2));
        dispatch(setShowDifferentAddress(showDifferentAddress));

        if (showDifferentAddress.pk) {
            dispatch(updateModel({
                amPickupLocation: tripModel.amPickupLocation,
                pickupLocationAddressLine1: tripModel.pickupLocationAddressLine1 ?? '',
                pickupLocationAddressLine2: tripModel.pickupLocationAddressLine2 ?? '',
                pickupLocationAddressCity: tripModel.pickupLocationAddressCity ?? '',
                pickupLocationAddressZipCode: tripModel.pickupLocationAddressZipCode ?? '',
                pickupLocationAddressState: tripModel.pickupLocationAddressState ?? '',
                pickupLocationAddressLatitude: tripModel.pickupLocationAddressLatitude ?? '',
                pickupLocationAddressLongitude: tripModel.pickupLocationAddressLongitude ?? ''
            }));

            dispatch(setSelectedPickUpAddress(selectedPickUpAddress));
            dispatch(setSelectedPickUpAddress2(selectedPickUpAddress2));
        }

        if (showDifferentAddress.do) {
            dispatch(updateModel({
                pmDropOffLocation: tripModel.pmDropOffLocation,
                dropOffLocationAddressLine1: tripModel.dropOffLocationAddressLine1 ?? '',
                dropOffLocationAddressLine2: tripModel.dropOffLocationAddressLine2 ?? '',
                dropOffLocationAddressCity: tripModel.dropOffLocationAddressCity ?? '',
                dropOffLocationAddressZipCode: tripModel.dropOffLocationAddressZipCode ?? '',
                dropOffLocationAddressState: tripModel.dropOffLocationAddressState ?? '',
                dropOffLocationAddressLatitude: tripModel.dropOffLocationAddressLatitude ?? '',
                dropOffLocationAddressLongitude: tripModel.dropOffLocationAddressLongitude ?? ''
            }));

            dispatch(setSelectedDropOffAddress(selectedDropOffAddress));
            dispatch(setSelectedDropOffAddress2(selectedDropOffAddress2));
        }
    }
    dispatch(isNewCorrect());
};

export const onUpdateHomeAddress = (item) => (dispatch, getState) => {
    const { selectedHomeAddress } = getState().TripRequestFormReducers;

    if (objectEmptyValidator(item.selectedAddress)) {
        dispatch(setIsSameAddress(false));
        dispatch(setSelectedHomeAddress({}));
        dispatch(updateModel({
            studentAddressLine1: '',
            studentAddressLine2: '',
            studentAddressCity: '',
            studentAddressZipCode: '',
            studentAddressState: '',
            studentAddressLatitude: '',
            studentAddressLongitude: ''
        }));
        dispatch(setSelectedHomeAddress2({}));
        dispatch(setSelectedPreviousHomeAddress(""));

        return false;
    }
    
    if (!_.isEqual(selectedHomeAddress, item.selectedAddress)) {
        dispatch(setIsSameAddress(false));
        dispatch(setSelectedHomeAddress(item.selectedAddress));
        dispatch(updateModel({
            studentAddressLine1: item.streetAddress,
            studentAddressCity: item.city,
            studentAddressZipCode: item.zipCode,
            studentAddressState: item.state,
            studentAddressLatitude: item.latitude,
            studentAddressLongitude: item.longitude
        }));
    }
    dispatch(isNewCorrect());

    return true;
};

export const onUpdateHomeAddress2 = (item) => (dispatch, getState) => {
    const { selectedHomeAddress2 } = getState().TripRequestFormReducers;

    if (objectEmptyValidator(item.selectedAddress)) {
        dispatch(setIsSameAddress(false));
        dispatch(setSelectedHomeAddress2({}));
        dispatch(updateModel({
            studentAddressLine2: '',
            studentAddressLine2Latitude: '',
            studentAddressLine2Longitude: ''
        }));

        return false;
    }

    if (!_.isEqual(selectedHomeAddress2, item.selectedAddress)) {
        dispatch(setIsSameAddress(false));
        dispatch(setSelectedHomeAddress2(item.selectedAddress));
        dispatch(updateModel({
            studentAddressLine2: item.streetAddress,
            studentAddressLine2Latitude: item.latitude,
            studentAddressLine2Longitude: item.longitude
        }));
    }
    dispatch(isNewCorrect());

    return true;
};

export const onUpdatePickUpAddress = (item) => (dispatch, getState) => {
    const { selectedPickUpAddress } = getState().TripRequestFormReducers;

    if (objectEmptyValidator(item.selectedAddress)) {
        dispatch(setIsSameAddress(false));
        dispatch(setSelectedPickUpAddress({}));
        dispatch(updateModel({
            pickupLocationAddressLine1: '',
            pickupLocationAddressLine2: '',
            pickupLocationAddressCity: '',
            pickupLocationAddressZipCode: '',
            pickupLocationAddressState: '',
            pickupLocationAddressLatitude: '',
            pickupLocationAddressLongitude: ''
        }));
        dispatch(setSelectedPickUpAddress2({}));
        dispatch(setSelectedPreviousPickUpAddress(""));

        return false;
    }

    if (!_.isEqual(selectedPickUpAddress, item.selectedAddress)) {
        dispatch(setIsSameAddress(false));
        dispatch(setSelectedPickUpAddress(item.selectedAddress));
        dispatch(updateModel({
            pickupLocationAddressLine1: item.streetAddress,
            pickupLocationAddressCity: item.city,
            pickupLocationAddressZipCode: item.zipCode,
            pickupLocationAddressState: item.state,
            pickupLocationAddressLatitude: item.latitude,
            pickupLocationAddressLongitude: item.longitude
        }));
    }
    dispatch(isNewCorrect());

    return true;
};

export const onUpdatePickUpAddress2 = (item) => (dispatch, getState) => {
    const { selectedPickUpAddress2 } = getState().TripRequestFormReducers;

    if (objectEmptyValidator(item.selectedAddress)) {
        dispatch(setIsSameAddress(false));
        dispatch(setSelectedPickUpAddress2({}));
        dispatch(updateModel({
            pickupLocationAddressLine2: ''
        }));

        return false;
    }

    if (!_.isEqual(selectedPickUpAddress2, item.selectedAddress)) {
        dispatch(setIsSameAddress(false));
        dispatch(setSelectedPickUpAddress2(item.selectedAddress));
        dispatch(updateModel({
            pickupLocationAddressLine2: item.streetAddress
        }));
    }
    dispatch(isNewCorrect());

    return true;
};

export const onUpdateDropOffAddress = (item) => (dispatch, getState) => {
    const { selectedDropOffAddress } = getState().TripRequestFormReducers;

    if (objectEmptyValidator(item.selectedAddress)) {
        dispatch(setIsSameAddress(false));
        dispatch(setSelectedDropOffAddress({}));
        dispatch(updateModel({
            dropOffLocationAddressLine1: '',
            dropOffLocationAddressLine2: '',
            dropOffLocationAddressCity: '',
            dropOffLocationAddressZipCode: '',
            dropOffLocationAddressState: '',
            dropOffLocationAddressLatitude: '',
            dropOffLocationAddressLongitude: ''
        }));
        dispatch(setSelectedDropOffAddress2({}));
        dispatch(setSelectedPreviousDropOffAddress(""));

        return false;
    }

    if (!_.isEqual(selectedDropOffAddress, item.selectedAddress)) {
        dispatch(setIsSameAddress(false));
        dispatch(setSelectedDropOffAddress(item.selectedAddress));
        dispatch(updateModel({
            dropOffLocationAddressLine1: item.streetAddress,
            dropOffLocationAddressCity: item.city,
            dropOffLocationAddressZipCode: item.zipCode,
            dropOffLocationAddressState: item.state,
            dropOffLocationAddressLatitude: item.latitude,
            dropOffLocationAddressLongitude: item.longitude
        }));
    }
    dispatch(isNewCorrect());

    return true;
};

export const onUpdateDropOffAddress2 = (item) => (dispatch, getState) => {
    const { selectedDropOffAddress2 } = getState().TripRequestFormReducers;

    if (objectEmptyValidator(item.selectedAddress)) {
        dispatch(setIsSameAddress(false));
        dispatch(setSelectedDropOffAddress2({}));
        dispatch(updateModel({
            dropOffLocationAddressLine2: ''
        }));

        return false;
    }

    if (!_.isEqual(selectedDropOffAddress2, item.selectedAddress)) {
        dispatch(setIsSameAddress(false));
        dispatch(setSelectedDropOffAddress2(item.selectedAddress));
        dispatch(updateModel({
            dropOffLocationAddressLine2: item.streetAddress
        }));
    }
    dispatch(isNewCorrect());

    return true;
};

export const onSelectPreviousHomeAddress = (evt) => (dispatch, getState) => {
    const { nonSchoolAddressList } = getState().TripRequestFormReducers;

    const selectedValue = evt.target.value;

    const selectedAddress = nonSchoolAddressList.find(x => x.RecentAddressGuid === selectedValue);

    if (selectedAddress) {
        dispatch(updateModel({
            studentAddressLine1: selectedAddress.AddressLine1,
            studentAddressLine2: selectedAddress.AddressLine2,
            studentAddressCity: selectedAddress.City,
            studentAddressZipCode: selectedAddress.PostalCode,
            studentAddressState: selectedAddress.State
        }));
    } else {
        dispatch(updateModel({
            studentAddressLine1: "",
            studentAddressLine2: "",
            studentAddressCity: "",
            studentAddressZipCode: "",
            studentAddressState: ""
        }));
    }
    dispatch(setSelectedPreviousHomeAddress(selectedValue));
};

export const onSelectPreviousPickUpAddress = (evt) => (dispatch, getState) => {
    const { nonSchoolAddressList } = getState().TripRequestFormReducers;

    const selectedValue = evt.target.value;

    const selectedAddress = nonSchoolAddressList.find(x => x.RecentAddressGuid === selectedValue);

    if (selectedAddress) {
        dispatch(updateModel({
            pickupLocationAddressLine1: selectedAddress.AddressLine1,
            pickupLocationAddressLine2: selectedAddress.AddressLine2,
            pickupLocationAddressCity: selectedAddress.City,
            pickupLocationAddressZipCode: selectedAddress.PostalCode,
            pickupLocationAddressState: selectedAddress.State
        }));
    } else {
        dispatch(updateModel({
            pickupLocationAddressLine1: "",
            pickupLocationAddressLine2: "",
            pickupLocationAddressCity: "",
            pickupLocationAddressZipCode: "",
            pickupLocationAddressState: ""
        }));
    }

    dispatch(setSelectedPreviousPickUpAddress(selectedValue));
};

export const onSelectPreviousDropOffAddress = (evt) => (dispatch, getState) => {
    const { nonSchoolAddressList } = getState().TripRequestFormReducers;

    const selectedValue = evt.target.value;

    const selectedAddress = nonSchoolAddressList.find(x => x.RecentAddressGuid === selectedValue);

    if (selectedAddress) {
        dispatch(updateModel({
            dropOffLocationAddressLine1: selectedAddress.AddressLine1,
            dropOffLocationAddressLine2: selectedAddress.AddressLine2,
            dropOffLocationAddressCity: selectedAddress.City,
            dropOffLocationAddressZipCode: selectedAddress.PostalCode,
            dropOffLocationAddressState: selectedAddress.State
        }));
    } else {
        dispatch(updateModel({
            dropOffLocationAddressLine1: "",
            dropOffLocationAddressLine2: "",
            dropOffLocationAddressCity: "",
            dropOffLocationAddressZipCode: "",
            dropOffLocationAddressState: ""
        }));
    }

    dispatch(setSelectedPreviousDropOffAddress(selectedValue));
};
