import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import UserManagement from 'screens/UserManagement/UserManagement';
import withRouter from 'util/customHooks/withRouter';
import { loadFacilities } from 'redux/workers/districtTripTracker/facilityWorker';
import { loadUsers } from 'redux/workers/userWorker';

const mapStateToProps = state => {
    const { UserReducers, FacilityReducers } = state;

    if (UserReducers) {
        return {
            facilityList: FacilityReducers.facilities
        };
    }

    return {};
};

const UserManagementContainer = withRouter(
    connect(
        mapStateToProps, {
            loadUsers,
            loadFacilities
        }
    )(injectIntl(UserManagement))
);

export default UserManagementContainer;
