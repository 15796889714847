import React from 'react';
import { Grid, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

import { localize } from '../../../util/Localizer';
import CommonTabControl from "../../Common/CommonTabControl";
import StyledButton from '../../../components/Common/StyledButton/StyledButton';
import StyledTypography from '../../../components/Common/StyledTypography/StyledTypography';
import { paths } from '../../../util/EnumHelper';

const HeaderGrid = styled(Paper)(({ theme }) => ({
    height: 40,
    paddingLeft: 0,
    width: '100%',
    background: theme.palette.everDrivenLightBlue20,
    textAlign: 'center',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const HeaderText = styled(StyledTypography)(() => ({
    marginBottom: 0,
    marginTop: 0
}));

const TabList = styled(Grid)(() => ({
    marginBottom: 0,
    display: 'flex',
    overflow: 'auto',
    '&> :last-child': {
        marginRight: 195
    }
}));

const AddStudentButton = styled(StyledButton)(() => ({
    marginLeft: 0,
    marginRight: 15,
    marginBottom: 5,
    height: 45,
    minWidth: 170
}));

const UploadContainer = styled('div')(() => ({
    float: 'right',
    marginLeft: -180
}));

const UploadButton = styled(StyledButton)(({ theme }) => ({
    marginLeft: 'auto',
    marginRight: 15,
    height: 45,
    position: 'fixed',
    minWidth: 180,
    '&:hover': {
        color: theme.palette.white
    }
}));

const GroupLabel = styled(Grid)(() => ({
    display: 'flex'
}));

const LimitLabel = styled('span')(() => ({
    marginLeft: 'auto',
    opacity: 0.5,
    fontWeight: 'bold'
}));

function TripRequestSiblingHeader({
    siblingNumber,
    currentSibling,
    limitReached,
    onAddNewTab,
    onLoadStudent,
    onSelectNewTab,
    onOpenDeleteTabModal,
    updatePath
}) {
    const handleAddTab = () => {
        onAddNewTab();
    };

    const handleDeleteTab = (tabNumber) => {
        onOpenDeleteTabModal(tabNumber);
    };

    const handleSelectTab = (tabNumber) => {
        onSelectNewTab(
            tabNumber,
            currentSibling
        );
    };

    const renderTabs = () => {
        const tabs = [];

        for (let index = 1; index <= siblingNumber; index += 1) {
            tabs.push(
                <CommonTabControl
                    key={index}
                    siblingNumber={index}
                    totalSibling={siblingNumber}
                    isCurrentTab={index === currentSibling}
                    variant="contained"
                    onSelectTab={() => handleSelectTab(index)}
                    onDeleteTab={() => handleDeleteTab(index)}
                    onClearForm={() => onLoadStudent({})}
                />
            );
        }

        return tabs;
    };

    return (
        <>
            <Grid item xs={12}>
                <HeaderGrid>
                    <HeaderText
                        testid={"student".concat(currentSibling.toString()).concat("Form")}
                    >
                        {localize('tripRequestForm.siblingNumber', { number: currentSibling.toString() })}
                    </HeaderText>
                </HeaderGrid>
            </Grid>
            <TabList item xs={12}>
                {renderTabs().map(x => x)}
                <AddStudentButton
                    color="primary"
                    startIcon={<AddIcon />}
                    testId="addSibling"
                    onClick={handleAddTab}
                >
                    {localize('tripRequestForm.addSibling')}
                </AddStudentButton>
            </TabList>
            <UploadContainer>
                <UploadButton
                    color="primary"
                    testId="uploadStudentData"
                    onClick={() => updatePath(paths.StudentUpload.tabValue)}
                >
                    {localize('tripRequestForm.uploadStudentData')}
                </UploadButton>
            </UploadContainer>
            <GroupLabel item xs={12}>
                {
                    limitReached &&
                    <LimitLabel
                        data-testid="limitReach"
                    >{localize('tripRequestForm.limitReach')}</LimitLabel>
                }
            </GroupLabel>
        </>
    );
}

export default TripRequestSiblingHeader;
