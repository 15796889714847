import {
    tripGroupSetGuid,
    tripGroupSetIsFacility
} from 'redux/actions/tripGroupActions';
import { studentTrips } from 'services/studentService';
import { paths } from 'util/EnumHelper';
import {
    studentIsLoading,
    studentSelected,
    studentSelectedCode,
    studentSelectedId,
    studentSelectedSchool
} from 'redux/actions/districtTripTracker/studentActions';
import { updatePath } from '../pathWorker';

const setStudentData = (studentId) => async (dispatch) => {
    const studentData = await studentTrips(studentId);

    dispatch(tripGroupSetIsFacility(false));
    dispatch(tripGroupSetGuid(studentId));

    dispatch(studentSelectedId(studentId));
    dispatch(studentSelected(`${studentData.firstName} ${studentData.lastName}`));
    dispatch(studentSelectedCode(studentData.clientMemberCode));
    dispatch(studentSelectedSchool(studentData.schoolName));

    dispatch(studentIsLoading(false));
};

// -------- Initialization

export const initialSetup = (studentId) => async (dispatch) => {
    dispatch(studentIsLoading(true));
    dispatch(setStudentData(studentId));
};

// ---

export const goToStudent = (studentId) => async (dispatch) => {
    dispatch(studentIsLoading(true));

    dispatch(updatePath(paths.StudentDetail.tabValue, studentId));
    dispatch(setStudentData(studentId));
};