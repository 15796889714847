import {
    DTT_FACILITY_IS_LOADING,
    DTT_FACILITY_SEARCHTERM,
    DTT_FACILITY_SORT_BY_COLUMN,
    DTT_FACILITY_STATE_COLUMNS,
    DTT_FACILITY_RESET_COLUMNS,
    DTT_FACILITY_SET_FACILITIES,
    DTT_FACILITY_SEARCH_FACILITIES
} from 'redux/actions/districtTripTracker/dttFacilityActions';
import {
    initialFacilitiesColumnsSorted
} from 'redux/constants';

const initialState = {
    isLoading: true,
    dttFacilities: [],
    dttFacilitySearchResults: [],
    dttFacilitySearch: '',
    dttFacilityColumns: JSON.parse(JSON.stringify(initialFacilitiesColumnsSorted)),
    dttFacilitySortColumn: initialFacilitiesColumnsSorted.name.name
};
/* eslint-disable-next-line default-param-last */
const DttFacilityReducers = (state = initialState, action) => {
    switch (action.type) {
    case DTT_FACILITY_IS_LOADING:
        return {
            ...state,
            isLoading: action.isLoading
        };
    case DTT_FACILITY_SEARCHTERM:
        return {
            ...state,
            dttFacilitySearch: action.dttFacilitySearch
        };

    case DTT_FACILITY_SORT_BY_COLUMN:
        return {
            ...state,
            dttFacilitySortColumn: action.columnName
        };

    case DTT_FACILITY_STATE_COLUMNS:
        return {
            ...state,
            dttFacilityColumns: {
                ...state.dttFacilityColumns,
                [action.columnName]: {
                    ...state.dttFacilityColumns[action.columnName],
                    sort: action.sort
                }
            }
        };
    case DTT_FACILITY_RESET_COLUMNS:
        return {
            ...state,
            dttFacilityColumns: JSON.parse(JSON.stringify(initialFacilitiesColumnsSorted)),
            dttFacilitySortColumn: initialFacilitiesColumnsSorted.name.name
        };
    case DTT_FACILITY_SET_FACILITIES:
        return {
            ...state,
            dttFacilities: action.dttFacilities
        };
    case DTT_FACILITY_SEARCH_FACILITIES:
        return {
            ...state,
            dttFacilitySearchResults: action.dttFacilitySearchResults
        };
    default:
        return state;
    }
};

export default DttFacilityReducers;
