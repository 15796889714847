import React from 'react';
import { Menu, MenuItem, IconButton, Link } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { rightMenu } from '../../util/dynamicMenu';
import { paths } from '../../util/EnumHelper';
import { localize } from '../../util/Localizer';
import breakpointsNames from '../../styles/ResponsiveConstants';

const setDisplayMenuByLanguage = (language, theme) => {
    if (language === 'en') {
        return {
            [theme.breakpoints.up(breakpointsNames.md)] : {
                display: 'none'
            },
            [theme.breakpoints.down(breakpointsNames.md)] : {
                display: 'block'
            }
        };
    }

    return {
        [theme.breakpoints.up(breakpointsNames.lg)] : {
            display: 'none'
        },
        [theme.breakpoints.down(breakpointsNames.lg)] : {
            display: 'block'
        }
    };
};

const HideMenuIconButton = styled(IconButton)((props) => ({
    marginRight: -20,
    marginLeft: 40,
    marginTop: -10,
    ...(setDisplayMenuByLanguage(props.language, props.theme))
}));

const LinkButton = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.black
}));

const UserName = styled('span')(() => ({
    textOverflow:'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
}));

function TopMenu({
    disableLogOut,
    language,
    userName,
    onMenuItemClick
}) {
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const dynRightMenu = rightMenu();

    const handleOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <HideMenuIconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                language={language}
                onClick={handleOpen}
                color="primary"
                size="large">
                <MoreVertIcon />
            </HideMenuIconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    value={0}
                    disabled
                    divider
                >
                    <UserName>{userName}</UserName>
                </MenuItem>
                <MenuItem
                    onClick={() => onMenuItemClick(dynRightMenu.find(x => x.id === paths.Contact.tabValue))}
                    divider
                >
                    {localize('header.contact')}
                </MenuItem>

                {dynRightMenu.map(x => {
                    if (x.id !== paths.Contact.tabValue) {
                        if (x.id === paths.Support.tabValue ||
                            x.id === paths.UserGuide.tabValue) {
                            return (
                                <Link
                                    key={x.id}
                                    href={x.path ?? null}
                                    underline="none"
                                    target={x.id === paths.UserGuide.tabValue ? '_blank' : null}
                                >
                                    <LinkButton>
                                        {x.label}
                                    </LinkButton>
                                </Link>
                            );
                        }

                        return (
                            <MenuItem
                                key={x.id}
                                disabled={x.id === paths.LogOut.tabValue && disableLogOut}
                                onClick={() => onMenuItemClick(x)}
                            >
                                {x.label}
                            </MenuItem>
                        );
                    }

                    return null;
                })}
            </Menu>
        </>
    );
}

export default TopMenu;
