/* eslint-disable max-lines */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import {
    any,
    arrayOf,
    bool,
    func,
    object,
    string
} from 'prop-types';
import StyledTablePaginationContainer from 'redux/containers/common/StyledTablePaginationContainer';
import StyledSearchBar from 'components/Common/StyledSearchBar/StyledSearchBar';
import StyledSortColumnButton from 'components/Common/StyledSortColumnButton/StyledSortColumnButton';
import StyledLoading from 'components/Common/StyledLoading/StyledLoading';
import useWidth from 'util/customHooks/useWidth';

import { localize } from 'util/Localizer';
import StyledTypography from 'components/Common/StyledTypography/StyledTypography';
import CommonStyles from 'styles/CommonStyles';

const MainContainer = styled(Paper)(() => ({
    maxWidth: 900,
    width: '100%',
    margin: 15,
    paddingTop: 25,
    boxShadow: '0px -1px 16px 0px #33333326'
}));

const TableTitleHeader = styled('div')(() => ({
    justifyContent: 'space-between',
    display: 'flex',
    padding: '10px 40px',
    flexWrap: 'wrap',
    gap: '10px'
}));

const StyledHeaderCell = styled(TableCell)(({theme}) => ({
    fontFamily: 'Museo700',
    fontStyle: 'normal',
    fontSize: 16,
    padding: '10px 16px',
    color: theme.palette.everDrivenCharcoal,
    '&:first-of-type' : {
        paddingLeft: '40px'
    },
    '&:last-of-type' : {
        paddingRight: '40px'
    },
    whiteSpace: 'pre'
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.white
    },
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.offWhite
    }
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
    fontFamily: 'MuseoSans500',
    fontStyle: 'normal',
    fontSize: 14,
    color: theme.palette.everDrivenCharcoal,
    '&:first-of-type' : {
        paddingLeft: '40px'
    },
    '&:last-of-type' : {
        paddingRight: '40px'
    }
}));

function DistrictTripTrackerTable({
    resultList,
    headerCells,
    columns,
    isLoading,
    showPagination,
    rightControls,
    emptyText,
    tableTitle,
    searchValue,
    performSearch,
    sortByColumn,
    setPage,
    titleButton
}) {
    const width = useWidth();

    const renderRow = (row) => (
        <StyledTableRow
            key={`row ${row.id}`}
            data-testid="row"
        >
            {headerCells.map((column) => (
                row[column.name] && ((column.visible).includes(width)) &&
                    <StyledTableCell
                        align={column?.isNumeric ? "right" : "left"}
                        component="td"
                        scope="row"
                        key={`${column.name} ${row.id}`}
                        data-testid={column.name}
                        className="qm_block"
                    >
                        {row[column.name]}
                    </StyledTableCell>

            ))}
            {rightControls?.map((button) => (
                <StyledTableCell
                    component="td"
                    scope="row"
                    key={`button ${button}`}
                >
                    {button(row)}
                </StyledTableCell>

            ))

            }
        </StyledTableRow>
    );

    const renderResults = () => (
        !isLoading && resultList.length > 0 &&
            <>
                {resultList.map((item) => (
                    renderRow(item)
                ))}
            </>
    );

    const renderIsLoading = () => (
        isLoading &&
            <StyledTableRow>
                <StyledTableCell colSpan="5">
                    <StyledLoading message={localize('dttStudent.isLoading')} />
                </StyledTableCell>
            </StyledTableRow>
    );

    const renderEmptyResults = () => (
        !isLoading && resultList.length === 0 &&
            <StyledTableRow data-testid="row">
                <StyledTableCell colSpan="5">
                    <div>
                        {emptyText}
                    </div>
                </StyledTableCell>
            </StyledTableRow>

    );

    const renderHeader = () => (
        headerCells.map((header) => (
            (header.visible).includes(width) &&
                <StyledHeaderCell
                    align={header?.isNumeric ? "right" : "left"}
                    key={header.text}
                    data-testid={localize(header.text)}
                >
                    {localize(header.text)}
                    {header.name &&
                        <StyledSortColumnButton
                            sortbyColumn={sortByColumn}
                            sort={columns[header.name].sort}
                            columnName={header.name}
                            testId={`testSortBy-${header.name}`}
                        />
                    }
                </StyledHeaderCell>

        ))
    );

    const renderTableTitleHeader = () => (
        <TableTitleHeader>
            <StyledTypography
                extraStyle={{...CommonStyles.headLine, fontFamily: 'Museo700', margin:0, padding: 0}}
            >
                {tableTitle}
            </StyledTypography>
            <div style={{whiteSpace: 'pre'}}>
                <StyledSearchBar
                    performSearch={performSearch}
                    placeholder={localize('dttStudent.search')}
                    value={searchValue}
                    testId="filterSearch"
                />
                {!searchValue && titleButton &&
                    titleButton()
                }
            </div>
        </TableTitleHeader>
    );

    return (
        <MainContainer>
            {renderTableTitleHeader()}
            <Table sx={{boxShadow: 'none'}}>
                <TableHead>
                    <TableRow>
                        {renderHeader()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { renderEmptyResults() }
                    { renderResults() }
                    { renderIsLoading() }
                </TableBody>
            </Table>
            { showPagination &&
                <div style={{paddingRight: '25px'}}>
                    <StyledTablePaginationContainer setPage={setPage} />
                </div>
            }
        </MainContainer>
    );
}

DistrictTripTrackerTable.defaultProps = {
    headerCells: [],
    resultList: [],
    columns: {},
    rightControls: null,
    isLoading: true,
    showPagination: false,
    emptyText: '',
    tableTitle: '',
    searchValue: '',
    performSearch: () => null,
    sortByColumn: () => null,
    titleButton: () => null,
    setPage: () => null
};

/* eslint-disable react/forbid-prop-types */
DistrictTripTrackerTable.propTypes = {
    headerCells: arrayOf(object),
    resultList: arrayOf(object),
    columns: object,
    rightControls: any,
    isLoading: bool,
    showPagination: bool,
    emptyText: string,
    tableTitle: string,
    searchValue: string,
    performSearch: func,
    sortByColumn: func,
    titleButton: func,
    setPage: func
};

export default DistrictTripTrackerTable;