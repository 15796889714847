import { connect } from 'react-redux';
import GeneralTripInformation from 'controls/TripRequestForm/GeneralTripInformation/GeneralTripInformation';
import {
    onUpdateModel
} from 'redux/workers/tripRequestFormWorker';
import {
    onUpdateTransportationEquipmentNeed,
    onUpdatePopulationType,
    setIsSameTripInfo
} from 'redux/workers/tripRequestFormTripInformationWorker';
import {
    setPopulationTypes
} from 'redux/actions/tripRequestFormActions';

const mapStateToProps = state => {
    const { TripRequestFormReducers } = state;

    return {
        tripModel: TripRequestFormReducers.tripModel,
        populationTypes: TripRequestFormReducers.populationTypes,
        transportationEquipmentNeeds: TripRequestFormReducers.transportationEquipmentNeeds,
        currentSibling: TripRequestFormReducers.currentSibling,
        isSameTripInformation: TripRequestFormReducers.isSameTripInformation
    };
};

const GeneralTripInformationContainer = connect(mapStateToProps,
    {
        onUpdateModel,
        onUpdateTransportationEquipmentNeed,
        onUpdatePopulationType,
        setPopulationTypes,
        setIsSameTripInfo
    })(GeneralTripInformation);

export default GeneralTripInformationContainer;
