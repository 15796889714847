import { Enum, tripsFilterStates } from "../util/EnumHelper";

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const ZERO = 0;
export const maxTabNumber = 8;

export const grades = [
    {
        index: -1,
        key: '',
        value: 'None'
    },
    {
        index: 0,
        key: 'Early Childhood Edu. (ECE)',
        value: 'Early Childhood Edu. (ECE)'
    },
    {
        index: 1,
        key: 'Pre-Kindergarten',
        value: 'Pre-Kindergarten'
    },
    {
        index: 2,
        key: 'Kindergarten',
        value: 'Kindergarten'
    },
    {
        index: 3,
        key: '1st grade',
        value: '1st grade'
    },
    {
        index: 4,
        key: '2nd grade',
        value: '2nd grade'
    },
    {
        index: 5,
        key: '3rd grade',
        value: '3rd grade'
    },
    {
        index: 6,
        key: '4th grade',
        value: '4th grade'
    },
    {
        index: 7,
        key: '5th grade',
        value: '5th grade'
    },
    {
        index: 8,
        key: '6th grade',
        value: '6th grade'
    },
    {
        index: 9,
        key: '7th grade',
        value: '7th grade'
    },
    {
        index: 10,
        key: '8th grade',
        value: '8th grade'
    },
    {
        index: 11,
        key: 'Freshman/9th grade',
        value: 'Freshman/9th grade'
    },
    {
        index: 12,
        key: 'Sophomore/10th grade',
        value: 'Sophomore/10th grade'
    },
    {
        index: 13,
        key: 'Junior/11th grade',
        value: 'Junior/11th grade'
    },
    {
        index: 14,
        key: 'Senior/12th grade',
        value: 'Senior/12th grade'
    },
    {
        index: 15,
        key: '13th grade/Other',
        value: '13th grade/Other'
    }
];

export const realtionships = [
    {
        index: -1,
        key: '',
        value: 'transportationRequest.selectOption'
    },
    {
        index: 0,
        key: 'Parent',
        value: 'tripRequestForm.relationshipParent'
    },
    {
        index: 1,
        key: 'Guardian',
        value: 'tripRequestForm.relationshipGuardian'
    },
    {
        index: 2,
        key: 'Emergency',
        value: 'tripRequestForm.relationshipEmergency'
    }
];

export const studentOnBoardingConstants = {
    DataIntakeAcceptedValue: "YES",
    RoutedAcceptedValue: "YES",
    ParentGuardianStatusAcceptedValue: "CONFIRMED",
    DriverAcceptedValue: "CONFIRMED"
};

export const initialUserColumnsSorted = {
    firstName: {
        name: 'firstName',
        sort: Enum.Sorter.Asc
    },
    lastName: {
        name: 'lastName',
        sort: Enum.Sorter.Asc
    },
    emailAddress: {
        name: 'emailAddress',
        sort: Enum.Sorter.Asc
    }
};

export const initialUser = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    isAdministrator: false,
    canAccessBilling: false,
    canAccessProgramManagement: false,
    canAccessTripTracking: false,
    isProfessionalAgent: "False",
    allowedSchools: [],
    allowedSchoolsVipApp: [],
    accountEnabled: true
};

export const initialFacilitiesColumnsSorted = {
    name: {
        name: 'name',
        sort: Enum.Sorter.Asc
    },
    address: {
        name: 'address',
        sort: Enum.Sorter.Desc
    }
};

export const initialDraftListColumnsSorted = {
    studentLastName: {
        name: 'studentLastName',
        sort: Enum.Sorter.Desc
    },
    studentFirstName: {
        name: 'studentFirstName',
        sort: Enum.Sorter.Desc
    },
    studentId: {
        name: 'studentId',
        sort: Enum.Sorter.Desc
    },
    source: {
        name: 'source',
        sort: Enum.Sorter.Desc
    },
    dateDraftSaved: {
        name: 'dateDraftSaved',
        sort: Enum.Sorter.Desc
    },
    percent: {
        name: 'percent',
        sort: Enum.Sorter.Desc
    }
};

export const initialDttStudentSearchColumnsSorted = {
    studentLastName: {
        name: 'studentLastName',
        sort: Enum.Sorter.Desc
    },
    studentFirstName: {
        name: 'studentFirstName',
        sort: Enum.Sorter.Desc
    },
    studentId: {
        name: 'studentId',
        sort: Enum.Sorter.Desc
    },
    request: {
        name: 'request',
        sort: Enum.Sorter.Desc
    },
    tripList: {
        name: 'tripList',
        sort: Enum.Sorter.Desc
    }
};

export const initialStudentsColumnsSorted = {
    lastName: {
        name: 'lastName',
        sort: Enum.Sorter.Asc
    },
    firstName: {
        name: 'firstName',
        sort: Enum.Sorter.Desc
    },
    clientMemberCode: {
        name: 'clientMemberCode',
        sort: Enum.Sorter.Desc
    }
};

export const initialTripFilter = {
    Today: tripsFilterStates.All.filterType,
    Future: tripsFilterStates.All.filterType,
    NoShow: tripsFilterStates.All.filterType,
    Finished: tripsFilterStates.All.filterType
};

export const wheelchair = [
    {
        index: -1,
        key: '',
        value: 'transportationRequest.selectOption'
    },
    {
        index: 0,
        key: Enum.wheelchairOptions.needsRamp,
        value: 'transportationRequest.needsRamp'
    },
    {
        index: 1,
        key: Enum.wheelchairOptions.needsLift,
        value: 'transportationRequest.needsLift'
    }
];

export const safetyVest = [
    {
        index: -1,
        key: '',
        value: 'transportationRequest.selectOption'
    },
    {
        index: 0,
        key: Enum.safetyVestOptions.extraSmall,
        value: 'transportationRequest.extraSmall',
        acronym: 'transportationRequest.sizeXS'
    },
    {
        index: 1,
        key: Enum.safetyVestOptions.small,
        value: 'transportationRequest.small',
        acronym: 'transportationRequest.sizeS'
    },
    {
        index: 2,
        key: Enum.safetyVestOptions.medium,
        value: 'transportationRequest.medium',
        acronym: 'transportationRequest.sizeM'
    },
    {
        index: 3,
        key: Enum.safetyVestOptions.large,
        value: 'transportationRequest.large',
        acronym: 'transportationRequest.sizeL'
    }
];