import { tripStatusColor } from "util/colorUtil";
import COLORCONSTANTS from "styles/ColorConstants";

export const getCarIcon = (trip) => {
    const markerColors = tripStatusColor(trip);

    const carSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="41" height="56" viewBox="0 0 41 56" fill="none">
    <path d="M7.37584 29.6199C7.12051 29.2619 6.87751 28.8926 6.64684 28.5119C5.22326 26.0831 4.48151 23.3151
    4.49984 20.4999C4.49962 17.9862 5.09167 15.5078 6.228 13.2657C7.36434 11.0235 9.01298 9.08054 11.0403
    7.59439C13.0676 6.10823 15.4166 5.12066 17.8968 4.7117C20.377 4.30275 22.9187 4.48392 25.3158
    5.24054C27.7129 5.99716 29.8981 7.30793 31.6941 9.06662C33.4901 10.8253 34.8465 12.9824 35.6533
    15.3631C36.4601 17.7438 36.6946 20.2811 36.3379 22.7694C35.9811 25.2577 35.0431 27.6268 33.5998
    29.6849L20.4328 51.4999L7.37584 29.6199Z" fill="${markerColors?.backgroundColor}"/>
    <path d="M26.651 14.4C26.5614 14.1367 26.3914 13.9083 26.1649 13.7468C25.9385 13.5853 25.6671 13.499
    25.389 13.5H15.611C15.3332 13.5003 15.0624 13.5872 14.8363 13.7485C14.6101 13.9098 14.4398 14.1375
    14.349 14.4L12.5 19.722V26.833C12.5008 27.0685 12.5947 27.2942 12.7613 27.4608C12.9278 27.6273 13.1535
    27.7212 13.389 27.722H14.278C14.5135 27.7212 14.7392 27.6273 14.9057 27.4608C15.0723 27.2942 15.1662
    27.0685 15.167 26.833V25.944H25.833V26.833C25.8338 27.0685 25.9277 27.2942 26.0943 27.4608C26.2608
    27.6273 26.4865 27.7212 26.722 27.722H27.611C27.8465 27.7212 28.0722 27.6273 28.2387 27.4608C28.4053
    27.2942 28.4992 27.0685 28.5 26.833V19.722L26.651 14.4ZM15.611 23.28C15.3474 23.28 15.0896 23.2018
    14.8704 23.0554C14.6512 22.9089 14.4804 22.7007 14.3795 22.4571C14.2786 22.2136 14.2522 21.9455
    14.3036 21.687C14.355 21.4284 14.482 21.1909 14.6684 21.0044C14.8548 20.818 15.0924 20.6911 15.3509
    20.6396C15.6095 20.5882 15.8775 20.6146 16.1211 20.7155C16.3647 20.8164 16.5729 20.9872 16.7193
    21.2064C16.8658 21.4256 16.944 21.6834 16.944 21.947C16.9439 22.1219 16.9093 22.2951 16.8422
    22.4567C16.7752 22.6182 16.6769 22.765 16.5532 22.8886C16.4294 23.0122 16.2825 23.1102 16.1208
    23.177C15.9592 23.2438 15.7859 23.2781 15.611 23.278V23.28ZM25.389 23.28C25.1254 23.28 24.8676 23.2018
    24.6484 23.0554C24.4292 22.9089 24.2584 22.7007 24.1575 22.4571C24.0566 22.2136 24.0302 21.9455 24.0816
    21.687C24.133 21.4284 24.26 21.1909 24.4464 21.0044C24.6328 20.818 24.8704 20.6911 25.1289
    20.6396C25.3875 20.5882 25.6555 20.6146 25.8991 20.7155C26.1427 20.8164 26.3509 20.9872 26.4973
    21.2064C26.6438 21.4256 26.722 21.6834 26.722 21.947C26.7219 22.1219 26.6873 22.2951 26.6202
    22.4567C26.5532 22.6182 26.4549 22.765 26.3312 22.8886C26.2074 23.0122 26.0605 23.1102 25.8988
    23.177C25.7372 23.2438 25.5639 23.2781 25.389 23.278V23.28ZM14.278 18.833L15.611
    14.833H25.389L26.722 18.833H14.278Z" fill="${markerColors?.color}"/></svg>`;

    return ({
        url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(carSvg)}`
    });
};

export const getHomeIcon = () => {
    const homeSvg = `<svg width="41" height="56" viewBox="0 0 41 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.37584 29.62C7.12051 29.262 6.87751 28.8927 6.64684 28.512C5.22326 26.0832 4.48151 23.3152 4.49984 
        20.5C4.49962 17.9863 5.09167 15.5079 6.228 13.2657C7.36434 11.0235 9.01298 9.0806 11.0403 7.59445C13.0676 
        6.1083 15.4166 5.12072 17.8968 4.71176C20.377 4.30281 22.9187 4.48398 25.3158 5.2406C27.7129 5.99722 29.8981 
        7.308 31.6941 9.06668C33.4901 10.8254 34.8465 12.9825 35.6533 15.3632C36.4601 17.7439 36.6946 20.2812 36.3379 
        22.7695C35.9811 25.2577 35.0431 27.6269 33.5998 29.685L20.4328 51.5L7.37584 29.62Z"
        fill="${COLORCONSTANTS.EVERDRIVENGREY}"/>
        <path d="M18 29V23H22V29H27V21H30L20 12L10 21H13V29H18Z" fill="${COLORCONSTANTS.WHITE}"/></svg>`;
    
    return ({
        url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(homeSvg)}`
    });
};

export const getSchoolIcon = () => {
    const schoolSvg = `<svg width="41" height="56" viewBox="0 0 41 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.37584 29.62C7.12051 29.262 6.87751 28.8927 6.64684 28.512C5.22326 26.0832 4.48151 23.3152 4.49984 
        20.5C4.49962 17.9863 5.09167 15.5079 6.228 13.2657C7.36434 11.0235 9.01298 9.0806 11.0403 7.59445C13.0676 
        6.1083 15.4166 5.12072 17.8968 4.71176C20.377 4.30281 22.9187 4.48398 25.3158 5.2406C27.7129 5.99722 29.8981 
        7.308 31.6941 9.06668C33.4901 10.8254 34.8465 12.9825 35.6533 15.3632C36.4601 17.7439 36.6946 20.2812 36.3379 
        22.7695C35.9811 25.2577 35.0431 27.6269 33.5998 29.685L20.4328 51.5L7.37584 29.62Z"
        fill="${COLORCONSTANTS.EVERDRIVENGREY}"/>
        <path d="M13 22.18V26.18L20 30L27 26.18V22.18L20 26L13 22.18ZM20 12L9 18L20 24L29 19.09V26H31V18L20 12Z" 
        fill="${COLORCONSTANTS.WHITE}"/></svg>`;

    return ({
        url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(schoolSvg)}`
    });
};