import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import withRouter from 'util/customHooks/withRouter';
import { styledHeaderSetOption } from 'redux/actions/styledHeaderActions';
import { localize } from 'util/Localizer';
import StyledHeader from 'components/Common/StyledHeader/StyledHeader';

const mapStateToProps = (state) => {
    const { StyledHeaderReducers } = state;

    return {
        tabValue: StyledHeaderReducers.option,
        tabItems: StyledHeaderReducers.options,
        title: localize('districtTripTracker.title'),
        idContainer: 'dttheaderSearch'
    };
};

const mapDispatchToProps = {
    onChangeTab: styledHeaderSetOption
};

const StyledHeaderDttContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(StyledHeader)));

export default StyledHeaderDttContainer;
