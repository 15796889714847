import { UPDATE_PATH, SET_LOGOUT_ON } from '../actions/pathActions';

import { paths } from '../../util/EnumHelper';

const initialState = {
    activeItem: paths.DistrictTripTracker.tabValue,
    isLoggingOut: false
};

/* eslint-disable-next-line default-param-last */
const pathReducers = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_PATH:
        return {
            ...state,
            activeItem: action.activeItem
        };
    case SET_LOGOUT_ON:
        return {
            ...state,
            isLoggingOut: action.payload
        };
    default:
        return state;
    }
};

export default pathReducers;