export const DTT_FACILITY_SEARCHTERM =
    'DTT_FACILITY_SEARCHTERM';
export const dttFacilitySearchTerm = dttFacilitySearch => ({
    type: DTT_FACILITY_SEARCHTERM,
    dttFacilitySearch
});

export const DTT_FACILITY_SET_FACILITIES =
    'DTT_FACILITY_SET_FACILITIES';
export const dttFacilitySetFacilities = dttFacilities => ({
    type: DTT_FACILITY_SET_FACILITIES,
    dttFacilities
});

export const DTT_FACILITY_SEARCH_FACILITIES =
    'DTT_FACILITY_SEARCH_FACILITIES';
export const dttFacilitySetSearchFacilities = dttFacilitySearchResults => ({
    type: DTT_FACILITY_SEARCH_FACILITIES,
    dttFacilitySearchResults
});

export const DTT_FACILITY_RESET_COLUMNS =
    'DTT_FACILITY_RESET_COLUMNS';
export const dttFacilityColumnsReset = () => ({
    type: DTT_FACILITY_RESET_COLUMNS
});

export const DTT_FACILITY_STATE_COLUMNS =
  'DTT_FACILITY_STATE_COLUMNS';
export const dttFacilityStateColumns = (columnName, sort) => ({
    type: DTT_FACILITY_STATE_COLUMNS,
    columnName,
    sort
});

export const DTT_FACILITY_SORT_BY_COLUMN =
    'DTT_FACILITY_SORT_BY_COLUMN';
export const dttFacilitySortByColumn = (columnName) => ({
    type: DTT_FACILITY_SORT_BY_COLUMN,
    columnName
});

export const DTT_FACILITY_IS_LOADING =
  'DTT_FACILITY_IS_LOADING';
export const dttFacilityIsLoading = isLoading => ({
    type: DTT_FACILITY_IS_LOADING,
    isLoading
});

export const DTT_FACILITY_SET_ROWS = 'DTT_FACILITY_SET_ROWS';
export const dttFacilitySetRows = rows => ({
    type: DTT_FACILITY_SET_ROWS,
    rows
});

export const DTT_FACILITY_SET_PAGE_SIZE = 'DTT_FACILITY_SET_PAGE_SIZE';
export const dttFacilitySetPageSize = pageSize => ({
    type: DTT_FACILITY_SET_PAGE_SIZE,
    pageSize
});

export const DTT_FACILITY_SET_PAGE = 'DTT_FACILITY_SET_PAGE';
export const dttFacilitySetPage = activePage => ({
    type: DTT_FACILITY_SET_PAGE,
    activePage
});