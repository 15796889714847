import {
    SET_TR_SHOW_ERROR_SUBMIT_MODAL,
    SET_TR_SHOW_FAILED_SUBMIT_MODAL,
    SET_TR_SHOW_REMOVE_CONTACT_MODAL,
    SET_TR_SHOW_REMOVE_STUDENT_NOTES_MODAL,
    SET_TR_SHOW_SAVE_DRAFT_MODAL,
    SET_TR_SHOW_SUCCESS_SUBMIT_MODAL
} from "redux/actions/transportationRequest/transportationRequestActions";

const initialState = {
    showRemoveContactModal: false,
    showRemoveStudentNotesModal: false,
    showSaveDraftModal: false,
    showErrorSubmitModal: false,
    showFailedSubmitModal: false,
    showSuccessSubmitModal: false
};

/* eslint-disable-next-line default-param-last */
const transportationRequestModalReducers = (state = initialState, action) => {
    switch (action.type) {
    case SET_TR_SHOW_REMOVE_CONTACT_MODAL:
        return {
            ...state,
            showRemoveContactModal: action.showRemoveContactModal
        };
    case SET_TR_SHOW_REMOVE_STUDENT_NOTES_MODAL:
        return {
            ...state,
            showRemoveStudentNotesModal: action.showRemoveStudentNotesModal
        };
    case SET_TR_SHOW_SAVE_DRAFT_MODAL:
        return {
            ...state,
            showSaveDraftModal: action.showSaveDraftModal
        };
    case SET_TR_SHOW_FAILED_SUBMIT_MODAL:
        return {
            ...state,
            showFailedSubmitModal: action.showFailedSubmitModal
        };
    case SET_TR_SHOW_SUCCESS_SUBMIT_MODAL:
        return {
            ...state,
            showSuccessSubmitModal: action.showSuccessSubmitModal
        };
    case SET_TR_SHOW_ERROR_SUBMIT_MODAL:
        return {
            ...state,
            showErrorSubmitModal: action.showErrorSubmitModal
        };
    default:
        return state;
    }
};

export default transportationRequestModalReducers;