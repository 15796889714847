import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
    onDownloadCalendarAttachment,
    onOpenDeleteAttachmentModal,
    onOpenNotesModal
} from 'redux/workers/calendarUploadWorker';
import CalendarUploadedRow from 'components/calendar/CalendarUploadedRow';

const CalendarUploadedRowContainer =
    connect(null,
        {
            onDownloadCalendarAttachment,
            onOpenDeleteAttachmentModal,
            onOpenNotesModal
        })(injectIntl(CalendarUploadedRow));

export default CalendarUploadedRowContainer;