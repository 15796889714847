/* eslint-disable max-lines */
/* eslint-disable camelcase */
import React, {
    useState,
    useMemo,
    useEffect
} from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import CircularProgress from '@mui/material/CircularProgress';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { objectEmptyValidator } from 'util/validationUtil';
import { address as objectAddress } from 'data/models/transportationRequest/TripBuilderModel';
import StyledTextField from 'components/Common/StyledTextField/StyledTextField';
import StyledTypography from 'components/Common/StyledTypography/StyledTypography';
import { localize } from "util/Localizer";

const MAX_LENGTH = 128;
const autocompleteService = { current: null };
const ALLOWED_TYPES = [ "establishment", "street_address", "school", "premise", "point_of_interest", "intersection" ];

const StyleLocationOnIcon = styled(LocationOnIcon)(({ theme }) => ({
    color: theme.palette.secondary,
    marginRight: theme.spacing(2)
}));

const StyledCircularProgress = styled(CircularProgress)(() => ({
    color: "inherit"
}));

const StyledAutocomplete = styled(
    Autocomplete,
    { shouldForwardProp: (prop) => prop !== 'isDisabled' }
)(({ theme, isDisabled }) => {
    const styles = {
        '& .MuiFilledInput-root.MuiInputBase-sizeSmall': {
            padding: '10px 40px 10px 4px'
        }
    };

    if (isDisabled) {
        return {
            ...styles,
            '& .MuiFilledInput-root.MuiInputBase-sizeSmall .MuiFilledInput-input': {
                color: theme.palette.everDrivenDarkCharcoal60,
                backgroundColor: theme.palette.transparent

            },

            '& > div': {
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                backgroundColor: theme.palette.everDrivenDarkCharcoal10
            },
            '& > div > div > input': {
                color: theme.palette.everDrivenDarkCharcoal60,
                backgroundColor: theme.palette.transparent
            }

        };
    }

    return styles;
});

function StyledAddressAutoComplete({
    value,
    idName,
    isRequired,
    disabled,
    isError,
    onChangeInput
}) {
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const handleChange = (event) => {
        onChangeInput(
            {
                ...objectAddress,
                selectedAddress: event.target.value
            }
        );
    };

    const handleAddressSelected = async (address) => {
        const { structured_formatting, place_id } = address;
        const addressData = { ...objectAddress };
        const mainText = structured_formatting.main_text;

        const geocoder = new window.google.maps.Geocoder();
        const parameter = {
            placeId: place_id
        };

        await geocoder.geocode(parameter,
            (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    const addressComponents = results[0].address_components;

                    let streetNumber = '';

                    // eslint-disable-next-line no-restricted-syntax
                    for(const component of addressComponents) {
                        const addressType =
                            // For places like Queens, NY For the city type[0] is "political" and type[1] is sublocality
                            component.types[0] === "political" && component.types[1] ?
                                component.types[1] : component.types[0];

                        switch (addressType) {
                        case 'street_number':
                            streetNumber = component.long_name;
                            break;
                        case 'street_address':
                        case 'route':
                        case 'intersection':
                            addressData.addressLine1 = component.long_name;
                            addressData.addressLine1Short = component.short_name;
                            break;
                        case 'locality':
                        case 'sublocality':
                        case 'sublocality_level_1':
                            addressData.city = component.long_name;
                            break;
                        case 'administrative_area_level_1':
                        case 'administrative_area_level_2':
                            addressData.state = component.long_name;
                            addressData.stateShort = component.short_name;
                            break;
                        case 'postal_code':
                            addressData.postal = component.short_name;
                            break;
                        case 'country':
                            addressData.country = component.long_name;
                            break;
                        default:
                            break;
                        }
                    }
                    addressData.addressLine1 =
                        streetNumber ? `${streetNumber} ${addressData.addressLine1}` : addressData.addressLine1;

                    addressData.addressLine1Short =
                        streetNumber ? `${streetNumber} ${addressData.addressLine1Short}` :
                            addressData.addressLine1Short;
                    if (
                        !addressData.addressLine1.includes(mainText) &&
                        !addressData.addressLine1Short.includes(mainText)
                    ) {
                        addressData.placeName = mainText;
                    }

                    addressData.selectedAddress = addressData.placeName ? `${addressData.placeName }, ` : "";
                    addressData.selectedAddress +=
                        `${addressData.addressLine1Short}, ${addressData.city}, `+
                        `${addressData.stateShort} ${addressData.postal}`;
                }
                onChangeInput({ ...addressData});
            }
        );
    };

    const onPreSelectedAddress = (item, fieldValue) => {
        if (fieldValue && !objectEmptyValidator(fieldValue)) {
            handleAddressSelected(fieldValue);
        } else {
            onChangeInput({ ...objectAddress });
        }
    };

    const renderInput = (params) => (
        <StyledTextField
            isDisabled={disabled}
            id={idName}
            InputLabelProps={params.InputLabelProps}
            inputProps={{
                ...params.inputProps,
                "data-testid": params.id,
                maxLength: MAX_LENGTH
            }}
            isNewStyle
            InputProps={{
                className: params.InputProps.className,
                ref: params.InputProps.ref,
                endAdornment: (
                    <>
                        {loading ? <StyledCircularProgress size={20} /> : null}
                        {(value?.length > 0) ? params.InputProps.endAdornment : null}
                    </>
                )
            }}
            required={isRequired}
            value={value}
            onChange={handleChange}
            isError={isError}
        />
    );

    const onRenderOptionResult = (properties, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        let parts = [];

        if (matches) {
            parts = parse(
                option.structured_formatting.main_text,
                matches.map((match) => [ match.offset, match.offset + match.length ])
            );
        }

        if (parts.length > 0) {
            return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Grid container alignItems="center" { ...properties } key={option.place_id}>
                    <Grid item>
                        <StyleLocationOnIcon />
                    </Grid>
                    <Grid className="qm_block" item xs>
                        {parts.map((part) => (
                            <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                            </span>
                        ))}

                        <StyledTypography
                            variant="body2"
                            color="textSecondary"
                        >
                            {option.structured_formatting.secondary_text}
                        </StyledTypography>
                    </Grid>
                </Grid>
            );
        }

        return null;
    };

    const filterResults = (results) => {
        if (!results) return [];

        const resultsWithAllowedTypes = results.filter(
            result => result.types.some(type => ALLOWED_TYPES.includes(type))
        );

        return resultsWithAllowedTypes;
    };

    const fetch = useMemo(
        () => throttle((request, callback) => {
            if (!request.input) {
                return false;
            }
            setLoading(true);
            const requestDto = {
                input: request.input,
                componentRestrictions: { country: 'US' }
            };

            autocompleteService.current.getPlacePredictions(requestDto, callback);

            return true;
        }, 200),
        []
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }
        if (value === '') {
            setData([]);
        } else if(active) {
            fetch({ input: value },
                (results) => {
                    setLoading(false);
                    const filteredResults = filterResults(results);

                    setData(filteredResults);
                }
            );
        }

        return () => {
            active = false;
        };
    }, [ value, fetch ]);

    return (
        <StyledAutocomplete
            classes={{
                popper: { zIndex: -1},
                input: 'qm_block',
                option: 'qm_block'
            }}
            disabled={disabled}
            isDisabled={disabled}
            filterOptions={option => option}
            filterSelectedOptions
            freeSolo
            getOptionLabel={
                item => !objectEmptyValidator(item) && item.structured_formatting
                    ? item.structured_formatting.main_text
                    : value || ''
            }
            id={idName}
            includeInputInList
            inputValue={value}
            loadingText={localize('search.searchingForStudents')}
            options={data}
            value={value}
            renderInput={params => renderInput(params)}
            renderOption={(properties, option) => onRenderOptionResult(properties,
                option)}
            onChange={onPreSelectedAddress}
        />
    );
}

StyledAddressAutoComplete.defaultProps = {
    value: '',
    idName: '',
    isRequired: true,
    disabled: false,
    isError: false,
    onChangeInput: null
};

/* eslint-disable react/forbid-prop-types */
StyledAddressAutoComplete.propTypes = {
    value: PropTypes.string,
    idName: PropTypes.string,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    isError: PropTypes.bool,
    onChangeInput: PropTypes.func
};

export default StyledAddressAutoComplete;