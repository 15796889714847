import React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { localize } from '../../util/Localizer';
import CalendarUploadRowContainer from '../../redux/containers/calendar/CalendarUploadRowContainer';
import breakpointsNames from '../../styles/ResponsiveConstants';
import StyledEmptyTable from '../Common/StyledEmptyTable/StyledEmptyTable';
import StyledTable from '../Common/StyledTable/StyledTable';
import StyledTableHead from '../Common/StyledTableHead/StyledTableHead';
import StyledTableBody from '../Common/StyledTableBody/StyledTableBody';
import StyledTableRow from '../Common/StyledTableRow/StyledTableRow';
import StyledTableCell from '../Common/StyledTableCell/StyledTableCell';

const RejectedRow = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
}));

const RejectedIcon = styled(HighlightOffIcon)(({ theme }) => ({
    color: theme.palette.everDrivenFuchsia
}));

const RejectedFileName = styled('div')(({ theme }) => ({
    paddingTop: 5,
    marginLeft: 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        width: 700
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: 400,
        paddingTop: 0
    },
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        width: 200
    }
}));

const useStyles = makeStyles((theme) => ({
    rejectedCellStyle: {
        width: 400,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            width: 700
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            width: 400
        },
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            width: 200
        }
    },
    hideNormalTable: {
        display: 'table',
        [theme.breakpoints.down(breakpointsNames.md)]: {
            display: 'none'
        }
    },
    displayMobileTable: {
        width: '100%',
        display: 'none',
        [theme.breakpoints.down(breakpointsNames.md)]: {
            display: 'table'
        }
    }

}));

function CalendarUploadList ({ calendars, rejectedCalendars }) {
    const classes = useStyles();

    if (calendars.length === 0 && rejectedCalendars.length === 0) {
        return (
            <StyledEmptyTable message={localize('calendarUpload.filesEmpty')} testId="calendarFileList"/>
        );
    }

    return (
        <>
            <StyledTable extraClassCss={classes.hideNormalTable} testId="calendarFileList">
                <StyledTableHead>
                    <StyledTableRow>
                        <StyledTableCell isHeader>
                            {localize('calendarUpload.fileName')}
                        </StyledTableCell>
                        <StyledTableCell isHeader>
                            {localize('calendarUpload.fileSize')}
                        </StyledTableCell>
                        <StyledTableCell isHeader>
                            {localize('calendarUpload.calendarAudience')}
                        </StyledTableCell>
                        <StyledTableCell />
                    </StyledTableRow>
                </StyledTableHead>
                <StyledTableBody>
                    {calendars.map((calendar, index) => {
                        const keyValue = `calendar.file.name_${index}`;

                        return (
                            <CalendarUploadRowContainer
                                row={calendar}
                                editRow={calendar}
                                nRow={index}
                                key={keyValue}
                            />
                        );
                    })}
                    {rejectedCalendars.map((rejected, index) => {
                        const keyValue = `rejectedFile.name_${index}`;

                        return (
                            <StyledTableRow key={keyValue} testId={`calendarRejectedFileRow_${index}`}>
                                <StyledTableCell extraCssClass={classes.rejectedCellStyle} colSpan="4">
                                    <RejectedRow>
                                        <RejectedIcon />
                                        <RejectedFileName data-testid={`calendarRejectedFile_${index}`}>
                                            {rejected.file.name}
                                        </RejectedFileName>
                                    </RejectedRow>
                                </StyledTableCell>
                            </StyledTableRow>
                        );
                    })}
                </StyledTableBody>
            </StyledTable>
            <div className={classes.displayMobileTable}>
                {calendars.map((calendar, index) => {
                    const keyValue = `calendar.file.name_${index}`;

                    return (
                        <CalendarUploadRowContainer
                            row={calendar}
                            editRow={calendar}
                            nRow={index}
                            isMobileView
                            key={keyValue}
                        />
                    );
                })}
                {rejectedCalendars.map((rejected, index) => {
                    const keyValue = `rejectedFile.name_${index}`;

                    return (
                        <StyledTable isMobileView key={keyValue} testId="calendar-fileList">
                            <StyledTableBody>
                                <StyledTableRow>
                                    <StyledTableCell>
                                        <RejectedRow>
                                            <RejectedIcon />
                                            <RejectedFileName>
                                                {rejected.file.name}
                                            </RejectedFileName>
                                        </RejectedRow>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </StyledTableBody>
                        </StyledTable>
                    );
                })}
            </div>
        </>
    );
}

export default CalendarUploadList;