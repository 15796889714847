import React from "react";
import Radio from '@mui/material/Radio';
import {
    string,
    bool,
    object
} from 'prop-types';

function StyledRadio({
    color,
    disabled,
    inputProps,
    value,
    props
}) {
    return (
        <Radio
            color={color}
            disabled={disabled}
            inputProps={inputProps}
            value={value}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}

StyledRadio.defaultProps = {
    color: 'primary',
    disabled: false,
    inputProps: null,
    value: ''
};

/* eslint-disable react/forbid-prop-types */
StyledRadio.propTypes = {
    color: string,
    disabled: bool,
    inputProps: object,
    value: string
};
/* eslint-enable react/forbid-prop-types */

export default StyledRadio;