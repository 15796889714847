import React from 'react';
import {
    bool,
    func,
    number,
    object,
    oneOf,
    string
} from 'prop-types';
import { Checkbox } from '@mui/material';

function StyledCheckbox({
    color,
    disableRipple,
    id,
    indeterminate,
    inputProps,
    isChecked,
    isDisabled,
    name,
    size,
    tabIndex,
    onChange,
    onClick
}) {
    return <Checkbox
        checked={isChecked}
        color={color}
        disabled={isDisabled}
        disableRipple={disableRipple}
        id={id}
        indeterminate={indeterminate}
        inputProps={inputProps}
        name={name}
        size={size}
        tabIndex={tabIndex}
        onChange={onChange}
        onClick={onClick}
    />;
}

StyledCheckbox.defaultProps = {
    color: 'default',
    disableRipple: false,
    id: null,
    indeterminate: false,
    inputProps: null,
    isChecked: false,
    isDisabled: false,
    name: '',
    size: 'medium',
    tabIndex: null,
    onChange: () => null,
    onClick: () => null
};

/* eslint-disable react/forbid-prop-types */
StyledCheckbox.propTypes = {
    color: oneOf([ 'default', 'primary', 'secondary' ]),
    disableRipple: bool,
    id: string,
    indeterminate: bool,
    inputProps: object,
    isChecked: bool,
    isDisabled: bool,
    name: string,
    size: oneOf([ 'small', 'medium' ]),
    tabIndex: number,
    onChange: func,
    onClick: func
};
/* eslint-enable react/forbid-prop-types */

export default StyledCheckbox;
