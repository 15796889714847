import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import StyledTextField from 'components/Common/StyledTextField/StyledTextField';
import { museoSansFont } from 'util/EnumHelper';

const TimePicker = styled(StyledTextField)(({ theme }) => ({
    fontFamily: museoSansFont,
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.everDrivenCharcoal,
    '& > div > input': {
        padding: '10px 6px',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    "& .Mui-error": {
        color: theme.palette.everDrivenFuchsia
    },
    "& .MuiFormHelperText-root": {
        color: theme.palette.everDrivenFuchsia
    }
}));

function StyledTimePicker({
    controlId,
    controlValue,
    disabled,
    isValid,
    fontSize,
    onChangeTime
}) {
    return (
        <TimePicker
            isNewStyle
            FormHelperTextProps={{
                style: { fontSize: 10 }
            }}
            id={controlId}
            inputProps={{
                "data-testid": controlId,
                step: 300
            }}
            InputProps={{
                style: { fontSize, height: 56 }
            }}
            isDisabled={disabled}
            isError={isValid}
            name={controlId}
            type="time"
            value={controlValue}
            onBlur={(evt) => onChangeTime(evt)}
            onChange={(evt) => onChangeTime(evt)}
        />
    );
}

StyledTimePicker.defaultProps = {
    controlId: null,
    controlValue: null,
    disabled: false,
    isValid: false,
    fontSize: 12,
    onChangeTime: null
};

/* eslint-disable react/forbid-prop-types */
StyledTimePicker.propTypes = {
    controlId: PropTypes.string,
    controlValue: PropTypes.string,
    disabled: PropTypes.bool,
    isValid: PropTypes.bool,
    fontSize: PropTypes.number,
    onChangeTime: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default StyledTimePicker;
