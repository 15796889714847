import React from 'react';
import { FormControl, FormControlLabel, FormGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { bool, func, object } from 'prop-types';

import StyledTextField from 'components/Common/StyledTextField/StyledTextField';
import StyledCheckbox from 'components/Common/StyledCheckbox/StyledCheckbox';
import { localize } from 'util/Localizer';
import Auth from 'auth/Auth';
import breakpointsNames from 'styles/ResponsiveConstants';
import commonUseStyles from 'styles/CommonStyles';
import { validateEmail } from 'util/textFormatters';

const SpanTitle = styled('span')(() => ({
    ...commonUseStyles.spanTitle
}));

const FormControlInactive = styled(FormControl)(() => ({
    marginLeft: 'auto',
    marginTop: -9
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    marginBottom: 0,
    fontSize: 14
}));

const FirstNameTextField = styled(StyledTextField)(({ theme }) => ({
    margin : '10px 0px',
    minWidth: 150,
    width: '100%',
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: '100%'
    },
    '& > div > input': {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    }
}));

const LastNameTextField = styled(StyledTextField)(({ theme }) => ({
    margin : '10px 0px',
    minWidth: 150,
    width: '100%',
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: '100%'
    },
    '& > div > input': {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    }
}));

const EmailTextField = styled(StyledTextField)(({ theme }) => ({
    margin : '10px 0px',
    minWidth: 150,
    width: '100%',
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: '100%'
    },
    '& > div > input': {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    }
}));

function UserPersonalData({
    isEdit,
    user,
    onChangeInput
}) {
    const [ emailError, setEmailError ] = React.useState(false);

    const contextUser = user;
    const handleLostFocus = (event) => {
        if (event.key === 'Enter') {
            document.activeElement.blur();
        }
    };

    const setFirstName = (event) => {
        if (event.target.value.trim().length === 0) {
            contextUser.firstName = '';
        } else {
            contextUser.firstName = event.target.value;
        }

        onChangeInput(contextUser);
    };

    const setLastName = (event) => {
        if (event.target.value.trim().length === 0) {
            contextUser.lastName = '';
        } else {
            contextUser.lastName = event.target.value;
        }

        onChangeInput(contextUser);
    };

    const setEmail = (event) => {
        contextUser.emailAddress = event.target.value;
        setEmailError(false);
        onChangeInput(contextUser);
    };

    const setEmailOnBlur = () => {
        if (contextUser.emailAddress.length > 0 && validateEmail(contextUser.emailAddress)) {
            setEmailError(true);
        }
    };

    const handleChangeStatus = event => {
        contextUser.accountEnabled = event.target.checked;
        onChangeInput(contextUser);
    };

    return (
        <>
            <SpanTitle>
                {localize('userForm.userDetail')}
                {isEdit && contextUser.emailAddress !== Auth.getName() ?
                    <FormControlInactive>
                        <FormGroup>
                            <StyledFormControlLabel
                                labelPlacement="start"
                                control={
                                    <StyledCheckbox
                                        color="secondary"
                                        inputProps={{'data-testid': 'activeUser'}}
                                        size="small"
                                        name="recordStatus"
                                        onChange={handleChangeStatus}
                                        isChecked={contextUser.accountEnabled}
                                    />
                                }
                                label={localize('userForm.UserActive')}
                            />
                        </FormGroup>
                    </FormControlInactive>
                    : null
                }
            </SpanTitle>
            <FirstNameTextField
                defaultValue={contextUser.firstName}
                id="firstName"
                inputProps={{ className: 'qm_block', "data-testid": "firstName" }}
                isRequired
                label={localize('userForm.firstName')}
                name="firstName"
                onChange={setFirstName}
            />
            <LastNameTextField
                defaultValue={contextUser.lastName}
                id="lastName"
                inputProps={{ className: 'qm_block', "data-testid": "lastName" }}
                isRequired
                label={localize('userForm.lastName')}
                name="lastName"
                onChange={setLastName}
                onKeyPress={handleLostFocus}
            />
            <EmailTextField
                defaultValue={contextUser.emailAddress}
                isError={emailError}
                helperText={localize('userForm.InvalidFormatEmail')}
                id="email"
                inputProps={{ className: 'qm_block', "data-testid": "email" }}
                isDisabled={isEdit}
                isRequired
                label={localize('userForm.email')}
                name="email"
                type="email"
                onBlur={setEmailOnBlur}
                onChange={setEmail}
                onKeyPress={handleLostFocus}
            />
        </>
    );
}

UserPersonalData.defaultProps = {
    isEdit: false,
    user: {},
    onChangeInput: () => null
};

/* eslint-disable react/forbid-prop-types */
UserPersonalData.propTypes = {
    isEdit: bool,
    user: object,
    onChangeInput: func
};

export default UserPersonalData;
