import React from 'react';
import { alpha, styled } from '@mui/material/styles';

import {
    array,
    bool,
    func,
    node,
    object,
    oneOf,
    oneOfType,
    string
} from 'prop-types';
import { Button } from '@mui/material';

const StyleButton = styled(Button)(({ theme, variant }) => {
    if (variant === 'text') {
        return {
            "&:hover": {
                backgroundColor: theme.palette.transparent
            },
            "&:disabled": {
                backgroundColor: theme.palette.transparent,
                color: alpha(theme.palette.everDrivenLightBlue, 0.60)
            },
            backgroundColor: theme.palette.transparent,
            color: theme.palette.everDrivenLightBlue
        };
    }

    return null;
});

function StyledButton({
    children,
    classes,
    className,
    color,
    disableElevation,
    isDisabled,
    id,
    testId,
    style,
    type,
    variant,
    onClick,
    ...props
}) {
    return <StyleButton
        classes={classes}
        className={className}
        color={color}
        disableElevation={disableElevation}
        disabled={isDisabled}
        id={id}
        style={style}
        type={type}
        data-testid={testId}
        variant={variant}
        onClick={onClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >{children}</StyleButton>;
}

StyledButton.defaultProps = {
    color: 'primary',
    classes: null,
    className: null,
    disableElevation: false,
    isDisabled: false,
    id: null,
    style: null,
    type: null,
    variant: 'contained',
    onClick: () => null,
    children: null
};

/* eslint-disable react/forbid-prop-types */
StyledButton.propTypes = {
    children: node,
    classes: oneOfType([
        array,
        object
    ]),
    className: string,
    color: string,
    disableElevation: bool,
    isDisabled: bool,
    testId: string.isRequired,
    id: string,
    style: oneOfType([
        array,
        object
    ]),
    type: oneOf([ 'button', 'submit' ]),
    variant: oneOf([
        'contained',
        'outlined',
        'text'
    ]),
    onClick: func
};
/* eslint-enable react/forbid-prop-types */

export default StyledButton;
