export const ERROR_PAGE_TITLE = 'ERROR_PAGE_TITLE';
export const errorPageSetTitle = (title) => ({
    type: ERROR_PAGE_TITLE,
    title
});

export const ERROR_PAGE_CONTENT = 'ERROR_PAGE_CONTENT';
export const errorPageSetContent = (content) => ({
    type: ERROR_PAGE_CONTENT,
    content
});
