import React from 'react';
import { injectIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import CommonStyles from '../../styles/CommonStyles';
import { localize } from "../../util/Localizer";
import StyledButton from '../Common/StyledButton/StyledButton';
import { Enum } from '../../util/EnumHelper';

const CancelConfirmationContainer = styled(Grid)(({ theme }) => ({
    background: theme.palette.white
}));

const Prompt = styled('p')(() => ({
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '80px 20%',
    fontSize: '16px'
}));

const CancelNote = styled('p')(() => ({
    textAlign: 'justify',
    margin: '-20px 20px 20px'
}));

const CtaBtnCancelBtn = styled(StyledButton)(({ theme }) => ({
    ...CommonStyles.roundedButton,
    fontWeight: 'bold',
    width: '80%',
    margin: '12px 10%',
    color: theme.palette.everDrivenDarkGrey,
    background: theme.palette.white,
    border: `${theme.palette.everDrivenDarkGrey} 2px solid`,
    '&:hover': {
        color: theme.palette.white,
        background: theme.palette.lightBlue
    }

}));

const CtaBtnConfirmBtn = styled(StyledButton)(({ theme }) => ({
    ...CommonStyles.roundedButton,
    fontWeight: 'bold',
    width: '80%',
    margin: '12px 10%',
    background: theme.palette.everDrivenDarkGrey,
    color: theme.palette.white,
    '&:hover': {
        color: theme.palette.white,
        background: theme.palette.everDrivenDarkBlue
    }

}));

function CancelConfirmation({ hoursTillTrip, id, onCancel, onConfirm }) {
    return (
        <CancelConfirmationContainer>
            {(hoursTillTrip < Enum.tripTimeToCancel) ?
                <>
                    <Grid item xs={12}>
                        <Prompt>{localize("tripList.OutOfTimeCancel")}</Prompt>
                    </Grid>
                    <Grid item xs={12}>
                        <CtaBtnCancelBtn
                            testId={`dismissCancelTripButton_${id}`}
                            onClick={() => onCancel()}
                        >
                            {localize("tripList.DismissCancel")}
                        </CtaBtnCancelBtn>
                    </Grid>
                </> :
                <>
                    <Grid item xs={12}>
                        <Prompt>{localize("tripList.areYouSureCancel")}</Prompt>
                        {(hoursTillTrip <= Enum.tripTimeToWarnning) && (
                            <CancelNote>{localize("tripList.noticeAboutCancel")}</CancelNote>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <CtaBtnCancelBtn
                            color="secondary"
                            testId={`dismissCancelNoButton_${id}`}
                            onClick={() => onCancel()}
                        >
                            {localize("tripList.no")}
                        </CtaBtnCancelBtn>
                    </Grid>
                    <Grid item xs={12}>
                        <CtaBtnConfirmBtn
                            testId={`dismissCancelYesButton_${id}`}
                            onClick={() => onConfirm()}
                        >
                            {localize("tripList.yes")}
                        </CtaBtnConfirmBtn>
                    </Grid>
                </>
            }
        </CancelConfirmationContainer>
    );
}

export default injectIntl(CancelConfirmation);
