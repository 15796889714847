import { connect } from 'react-redux';

import { paginationSetRowsShowed } from 'redux/actions/StyledTablePaginationActions';
import StyledTablePagination from '../../../components/Common/StyledTablePagination/StyledTablePagination';
import { localize } from '../../../util/Localizer';

const mapStateToProps = state => {
    const { StyledTablePaginationReducer } = state;

    return {
        component: 'div',
        limitRows: StyledTablePaginationReducer.limitRows,
        activePage: StyledTablePaginationReducer.activePage,
        rowsShowed: StyledTablePaginationReducer.rowsShowed,
        labelRowsPerPage: localize('draftList.pagination')
    };
};

const StyledTablePaginationContainer = connect(
    mapStateToProps, {
        setPageSize: paginationSetRowsShowed
    }
)(StyledTablePagination);

export default StyledTablePaginationContainer;