import React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { any, arrayOf, bool, object } from 'prop-types';

import SeparatorHeader from 'widgets/common/SeparatorHeader';

import { localize } from '../../util/Localizer';
import StyledEmptyTable from '../Common/StyledEmptyTable/StyledEmptyTable';
import StyledTable from '../Common/StyledTable/StyledTable';
import StyledTableHead from '../Common/StyledTableHead/StyledTableHead';
import StyledTableRow from '../Common/StyledTableRow/StyledTableRow';
import StyledTableBody from '../Common/StyledTableBody/StyledTableBody';
import StyledTableCell from '../Common/StyledTableCell/StyledTableCell';
import { draftListColumns } from '../../util/EnumHelper';
import breakpointsNames from '../../styles/ResponsiveConstants';
import StyledLoading from '../Common/StyledLoading/StyledLoading';
import StyledDraftTablePaginationContainer from '../../redux/containers/draftList/StyledDraftTablePaginationContainer';
import StyledSortColumnButtonContainer from '../../redux/containers/draftList/StyledSortColumnButtonContainer';
import StyledCircularProgress from '../Common/StyledCircularProgress/StyledCircularProgress';

const PERCENT_COLUMN = 5;

const TextOverflowBox = styled('div')(({ theme }) => ({
    width: 150,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        width: 120
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: 90
    },
    "@media only screen and (max-width: 1155px)": {
        width: 100
    }
}));

const HeaderTextOverflowBox = styled('div')(() => ({
    width: 'auto',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    overflow: 'hidden',
    "@media only screen and (max-width: 1155px)": {
        maxWidth: 70
    }
}));

const CellBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
}));

const LoadingBox = styled('div')(() => ({
    padding: 20
}));

const SeparatorHeaderBox = styled('div')(() => ({
    width: 1
}));

const NameBox = styled('div')(() => ({
    display: 'flex'
}));

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.everDrivenLightBlue20,
        whiteSpace: 'nowrap'
    },
    table: {
        borderRadius: '6px 6px 6px 6px'
    },
    pagination: {
        textAlign: 'right',
        fontSize: 30,
        backgroundColor: theme.palette.white
    }
}));

function DraftListTable({
    headerCells,
    resultList,
    rightControls,
    showPagination,
    columns,
    isLoading
}) {
    const classes = useStyles();

    if (resultList.length === 0) {
        return (
            <StyledEmptyTable message={localize('draftList.notFound')} />
        );
    }

    const renderCellValue = (value, testid) => (
        <TextOverflowBox className="qm_block" data-testid={testid}>
            <strong>
                {value}
            </strong>
        </TextOverflowBox>
    );

    const renderRow = (row) => (
        <StyledTableRow
            key={`row ${row.studentId}`}
            testId={`row-${row.studentId}`}
        >
            {headerCells.map((title) => (
                row[title.name] &&
                    <StyledTableCell
                        component="th"
                        scope="row"
                        key={`${title.name} ${row[title.name]}`}
                    >
                        {title.name === draftListColumns[PERCENT_COLUMN].name
                            ? <StyledCircularProgress
                                progress={row[title.name]}
                                dataId={`${title.name}`}
                            />
                            : renderCellValue(row[title.name], title.name)
                        }
                    </StyledTableCell>

            ))}
            {rightControls &&
                <StyledTableCell
                    component="th"
                    scope="row"
                >
                    {rightControls(row)}
                </StyledTableCell>
            }
        </StyledTableRow>
    );

    const renderHeaderCellValue = (value) => (
        <HeaderTextOverflowBox>
            {value}
        </HeaderTextOverflowBox>
    );

    const renderHeader = () => (
        headerCells.map((header, index) => (
            <StyledTableCell
                isHeader
                key={headerCells[index].name}
            >
                <CellBox>
                    <NameBox className={classes.name}>
                        {renderHeaderCellValue(localize(headerCells[index].text))}
                        {headerCells[index].name &&
                            <StyledSortColumnButtonContainer
                                sort={columns[headerCells[index].name].sort}
                                columnName={headerCells[index].name}
                            />
                        }
                    </NameBox>
                    {headerCells[index].name &&
                        <SeparatorHeaderBox>
                            <SeparatorHeader/>
                        </SeparatorHeaderBox>
                    }
                </CellBox>
            </StyledTableCell>

        ))
    );

    const renderPagination = () => (
        showPagination &&
            <StyledTableRow>
                <StyledTableCell colSpan="10" extraCssClass={classes.pagination}>
                    <StyledDraftTablePaginationContainer />
                </StyledTableCell>
            </StyledTableRow>
    );

    const renderIsLoading = () => (
        isLoading &&
            <LoadingBox>
                <StyledLoading message={localize('draftList.processing')} />
            </LoadingBox>
    );

    const renderTable = () => (
        !isLoading &&
            <StyledTable extraClassCss={classes.table}>
                <StyledTableHead extraCssClass={classes.header}>
                    <StyledTableRow>
                        {renderHeader()}
                    </StyledTableRow>
                </StyledTableHead>
                <StyledTableBody>
                    {resultList.map((item) => (
                        renderRow(item)
                    ))}
                    {renderPagination()}
                </StyledTableBody>
            </StyledTable>
    );

    return (
        <>
            {renderIsLoading()}
            {renderTable()}
        </>
    );
}

DraftListTable.defaultProps = {
    headerCells: [],
    resultList: [],
    columns: {},
    rightControls: null,
    showPagination: false,
    isLoading: false

};

/* eslint-disable react/forbid-prop-types */
DraftListTable.propTypes = {
    headerCells: arrayOf(object),
    resultList: arrayOf(object),
    columns: object,
    rightControls: any,
    showPagination: bool,
    isLoading: bool
};

export default DraftListTable;