import { connect } from 'react-redux';
import { trTripUpdateDate } from 'redux/workers/transportationRequest/transportationRequestTripsWorker';
import TripDates from 'components/TransportationRequest/TripBuilder/TripDates/TripDates';
import { dataDatesTrip } from 'util/data/TripBuilderDecorator';
import {
    TransportationRequestDatesOptions as DatesOptions
} from 'data/models/transportationRequest/TripBuilderModel';

const mapStateToProps = (state, ownProps) => {
    const {
        TransportationRequestReducers,
        TransportationRequestErrorsReducers
    } = state;
    const { trips } = TransportationRequestReducers;
    const { tripBuilderErrors } = TransportationRequestErrorsReducers;

    return {
        options: dataDatesTrip(trips[ownProps.currentTrip]),
        errors: tripBuilderErrors[ownProps.currentTrip] ?? null,
        datesOptions: DatesOptions
    };
};

const mapDispatchToProps = {
    onChange: trTripUpdateDate
};

const TripDatesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TripDates);

export default TripDatesContainer;
