import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Report from '../../../components/Report';
import { resetReponses } from '../../workers/reportsWorker';
import withRouter from '../../../util/customHooks/withRouter';

const mapStateToProps = () => ({});

const ReportContainer = withRouter(
    connect(
        mapStateToProps,
        { resetReponses }
    )(injectIntl(Report)));

export default ReportContainer;
