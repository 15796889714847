import {
    openSnackBar,
    setSnackBarMessage,
    setSnackBarSeverity
} from '../actions/styledSnackbarWrapperAction';

export const closeStyledSnackBar = () => dispatch => {
    dispatch(openSnackBar(false));
};

export const openStyledSnackBar = () => dispatch => {
    dispatch(openSnackBar(true));
};

export const setSnackbar = (message, severity) => dispatch => {
    dispatch(setSnackBarMessage(message));
    dispatch(setSnackBarSeverity(severity));
};

export const onSnackBarPropertyChange = (severity, message) => (dispatch) => {
    dispatch(setSnackbar(
        message,
        severity
    ));
    dispatch(openStyledSnackBar());
};