import React from 'react';
import { Grid } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PropTypes from 'prop-types';
import StyledTypography from 'components/Common/StyledTypography/StyledTypography';
import { localize } from 'util/Localizer';
import StyledCheckbox from 'components/Common/StyledCheckbox/StyledCheckbox';
import StyledFormControlLabel from 'components/Common/StyledFormControlLabel/StyledFormControlLabel';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import CommonStyles from 'styles/CommonStyles';
import StyledModal from 'components/Common/StyledModal/StyledModal';
import {
    InitialPopulationType,
    MorePopulationType,
    PopulationTypeDescription
} from 'data/models/descriptions/TransportationRequestModels';
import { museoSansFont } from 'util/EnumHelper';
import { isOdd } from 'util/validationUtil';
import colorConstants from 'styles/ColorConstants';

function PopulationType({
    hasMorePT,
    populationTypes,
    setHasMorePT,
    onUpdatePopulationType
}) {
    const [ openLearnMore, setOpenLearnMore ] = React.useState(false);

    const onChangePopulationType = (evt) => {
        if (evt === null) {
            return false;
        }
        
        onUpdatePopulationType(evt);

        return true;
    };
    
    const renderPopulationTypesHelper = () => PopulationTypeDescription.map(item => (
        <Grid item xs={12} key={item.label} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <p style={{
                fontFamily: museoSansFont,
                fontSize: 18,
                lineHeight: 1.5,
                color: colorConstants.EVERDRIVENDARKCHARCOAL
            }}>
                <b>{item.label}: </b>
                {item.content}
            </p>
        </Grid>
    ));

    const renderPopulationTypes = (model, isFirstColumn) => model.map((item, index) => {
        const validFirstColumn = isFirstColumn ? index : index + 1;

        return (
            <Grid item key={item.name} xs={6} sx={
                {
                    paddingLeft: !isOdd(validFirstColumn) ? '0px': '20px',
                    paddingRight: !isOdd(validFirstColumn) ? '20px': '0px',
                    paddingBottom: '15px'
                }}
            >
                <StyledFormControlLabel
                    testId={item.name}
                    size="medium"
                    control={<StyledCheckbox
                        name={item.name}
                        color="secondary"
                        isChecked={populationTypes[item.name]}
                        size="medium"
                        onChange={onChangePopulationType}
                    />}
                    label={item.label}
                />
            </Grid>
        );
    });

    return (
        <>
            <Grid item xs={12} sx={{ paddingBottom: '10px', display: 'flex' }}>
                <StyledTypography
                    extraStyle={{ ...CommonStyles.subHeadLine, width: 133 }}
                    variant="h5"
                >
                    {localize('transportationRequest.populationType')}
                </StyledTypography>
                <StyledButton
                    color="inherit"
                    style={{ ...CommonStyles.linkButton, paddingLeft: 20, alignItems: 'center' }}
                    type="button"
                    variant="text"
                    testId="openLearnMorePTButton"
                    onClick={() => setOpenLearnMore(true)}
                >
                    {localize('transportationRequest.learnMore')}
                </StyledButton>
                { hasMorePT && <StyledButton
                    color="inherit"
                    style={{ ...CommonStyles.linkButton, marginLeft: 'auto', alignItems: 'center' }}
                    startIcon={<RemoveCircleOutlineIcon style={{ fontSize: 16 }} />}
                    type="button"
                    variant="text"
                    testId="openLearnMorePTButton"
                    onClick={() => setHasMorePT(false)}
                >
                    {localize('transportationRequest.collapsePT')}
                </StyledButton>
                }
            </Grid>
            {renderPopulationTypes(InitialPopulationType, true)}
            { !hasMorePT && <Grid item xs={6} sx={{ paddingLeft: '20px' }}>
                <StyledButton
                    color="inherit"
                    style={CommonStyles.linkButton}
                    type="button"
                    variant="text"
                    testId="seeMorePopulationTypes"
                    onClick={() => setHasMorePT(true)}
                >
                    {localize('transportationRequest.see8More')}
                </StyledButton>
            </Grid>}
            { hasMorePT && <>
                {renderPopulationTypes(MorePopulationType, false)}
            </>
            }
            <StyledModal
                open={openLearnMore}
                title={localize('transportationRequest.populationType')}
                onClose={() => setOpenLearnMore(false)}
            >
                {renderPopulationTypesHelper()}
            </StyledModal>
        </>
    );
}

PopulationType.defaultProps = {
    hasMorePT: false,
    setHasMorePT: null,
    onUpdatePopulationType: null
};

/* eslint-disable react/forbid-prop-types */
PopulationType.propTypes = {
    hasMorePT: PropTypes.bool,
    populationTypes: PropTypes.object.isRequired,
    setHasMorePT: PropTypes.func,
    onUpdatePopulationType: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default PopulationType;