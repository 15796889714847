import {
    FACILITIES_LOAD,
    FACILITIES_SORTED,
    FACILITY_IS_LOADING,
    FACILITY_SELECTED,
    FACILITY_SELECTED_ID,
    FACILITY_SELECTED_TOGGLEMAP
} from "redux/actions/districtTripTracker/facilityActions";

const initialState = {
    facilities: [],
    facilitiesSorted: [],
    toggleMap: true,
    facility: null,
    facilityId: '',
    isLoading: true
};

/* eslint-disable-next-line default-param-last */
const facilityReducers = (state = initialState, action) => {
    switch (action.type) {
    case FACILITIES_LOAD:
        return { ...state, facilities: action.facilities };
    case FACILITIES_SORTED:
        return { ...state, facilitiesSorted: action.facilitiesSort };

    case FACILITY_IS_LOADING:
        return {
            ...state,
            isLoading: action.loading
        };
    case FACILITY_SELECTED:
        return {
            ...state,
            facility: action.facility
        };
    case FACILITY_SELECTED_ID:
        return {
            ...state,
            facilityId: action.facilityId
        };
    case FACILITY_SELECTED_TOGGLEMAP:
        return {
            ...state,
            toggleMap: action.toggleMap
        };
    default:
        return state;
    }
};

export default facilityReducers;
