/* eslint max-lines: 0 */
import React from 'react';
import { RadioGroup, Radio, FormControl, FormControlLabel, Grid } from '@mui/material';

import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { localize } from 'util/Localizer';
import Auth from 'auth/Auth';
import { requestTypeDto } from 'data/models/tripRequestForm/TripRequestFormModel';
import commonUseStyles from 'styles/CommonStyles';
import breakpointsNames from 'styles/ResponsiveConstants';
import TransportationNeeds from 'controls/TripRequestForm/TransportationNeeds/TransportationNeeds';
import StyledTypography from 'components/Common/StyledTypography/StyledTypography';
import StyledCheckbox from 'components/Common/StyledCheckbox/StyledCheckbox';
import PopulationType from 'controls/TripRequestForm/PopulationType/PopulationType';

const ContentMarginContainer = styled(Grid)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
}));

const GroupLeftContainer = styled(Grid)(({ theme }) => ({
    ...commonUseStyles.defaultGroupLeft,
    [theme.breakpoints.down(breakpointsNames.md)]: {
        paddingRight: 0
    }
}));

const GroupLeftFormControl = styled(FormControl)(({ theme }) => ({
    ...commonUseStyles.defaultGroupLeft,
    [theme.breakpoints.down(breakpointsNames.md)]: {
        paddingRight: 0
    }
}));

const GroupCenterContainer = styled(Grid)(({ theme }) => ({
    ...commonUseStyles.defaultGroupCenter,
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        paddingRight: 0
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        paddingRight: 0,
        paddingLeft: 0
    }
}));

const GroupRightContainer = styled(Grid)(({ theme }) => ({
    ...commonUseStyles.defaultGroupRight,
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        paddingLeft: 0,
        paddingRight: 15
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        paddingLeft: 0,
        paddingRight: 0
    }
}));

const GroupTitle = styled(StyledTypography)(() => ({
    marginBottom: -10
}));

const GroupTitleBox = styled('div')(() => ({
    ...commonUseStyles.headerForm,
    marginTop: -20,
    marginBottom: 20
}));

const EquipmentAlert = styled(Alert)(({ theme }) => ({
    marginTop: 10,
    fontSize: 12,
    backgroundColor: theme.palette.everDrivenFuchsia
}));

const EquipmentAlertIcon = styled(ErrorIcon)(({ theme }) => ({
    fontSize: 40,
    color: theme.palette.white
}));

const RequiredLabel = styled('span')(({ theme }) => ({
    color: theme.palette.everDrivenFuchsia
}));

function GeneralTripInformation({
    tripModel,
    populationTypes,
    transportationEquipmentNeeds,
    currentSibling,
    isSameTripInformation,
    onUpdateModel: onUpdateGeneralInformation,
    onUpdateTransportationEquipmentNeed,
    onUpdatePopulationType,
    setIsSameTripInfo
}) {
    const accounts = Auth.getAccounts();

    const handleChangeGeneralTripInfo = (evt) => {
        if (evt === null) {
            return false;
        }

        setIsSameTripInfo(false);
        onUpdateGeneralInformation(evt);

        return true;
    };

    const renderAccounts = () => accounts.map(item => (
        <FormControlLabel
            data-testid={item.Name.concat("account")}
            key={item.AccountID}
            value={item.AccountID}
            control={<Radio color="secondary" />}
            label={item.Name}
        />
    ));

    const handleCopyTripInformation = (evt) => {
        if (evt === null) {
            return false;
        }

        setIsSameTripInfo(evt.target.checked);

        return true;
    };

    return (
        <ContentMarginContainer container spacing={2}>
            {currentSibling > 1 &&
                <Grid item xs={12} sx={{marginTop: '-15px'}}>
                    <FormControlLabel
                        size="medium"
                        control={<StyledCheckbox
                            color="secondary"
                            isChecked={isSameTripInformation}
                            name="isSameTripInformation"
                            size="medium"
                            onChange={handleCopyTripInformation}
                        />}
                        label={localize('tripRequestForm.repeatStudentInformation')}
                    />
                </Grid>
            }
            {accounts.length > 1 &&
            <GroupLeftContainer item xs={4} sm={12} md={6} lg={4}>
                <>
                    <GroupTitleBox className="memberCard">
                        <GroupTitle
                            variant="h5"
                        >
                            {localize('tripRequestForm.accountNumber')}
                            <RequiredLabel>
                                    *
                            </RequiredLabel>
                        </GroupTitle>
                    </GroupTitleBox>

                    <GroupLeftFormControl>
                        <RadioGroup row
                            aria-label="accountNumber"
                            name="accountNumber"
                            value={tripModel.accountNumber ?? ""}
                            onChange={handleChangeGeneralTripInfo}>
                            {renderAccounts()}
                        </RadioGroup>
                    </GroupLeftFormControl>
                </>
            </GroupLeftContainer>
            }
            <GroupCenterContainer item xs={4} sm={12} md={6} lg={4}>
                <GroupTitleBox className="memberCard">
                    <GroupTitle
                        variant="h5"
                    >
                        {localize('tripRequestForm.populationType')}
                        {requestTypeDto.new === tripModel.requestType ?
                            <RequiredLabel>
                                *
                            </RequiredLabel>
                            : null
                        }
                    </GroupTitle>
                </GroupTitleBox>
                <PopulationType
                    populationTypes={populationTypes}
                    onUpdatePopulationType={onUpdatePopulationType}
                    setIsSameTripInfo={setIsSameTripInfo}
                />
            </GroupCenterContainer>
            <GroupRightContainer item xs={4} sm={12} md={6} lg={4}>
                <GroupTitleBox className="memberCard">
                    <GroupTitle
                        variant="h5"
                    >
                        {localize('tripRequestForm.transportationNeeds')}
                        {requestTypeDto.new === tripModel.requestType ?
                            <RequiredLabel>
                                *
                            </RequiredLabel>
                            : null
                        }
                    </GroupTitle>
                </GroupTitleBox>
                <TransportationNeeds
                    transportationEquipmentNeeds={transportationEquipmentNeeds}
                    onUpdateTransportationEquipmentNeed={onUpdateTransportationEquipmentNeed}
                    setIsSameTripInfo={setIsSameTripInfo}
                />
                <EquipmentAlert
                    variant="filled"
                    severity="error"
                    icon={<EquipmentAlertIcon />}
                >
                    {localize('tripRequestForm.transportationNeedsDetails')}
                    <u>{localize('tripRequestForm.transportationNeedsDetailsNotApplicable')}</u>
                </EquipmentAlert>
            </GroupRightContainer>
        </ContentMarginContainer>
    );
}

GeneralTripInformation.defaultProps = {
    tripModel: null,
    populationTypes: null,
    transportationEquipmentNeeds: null,
    currentSibling: null,
    isSameTripInformation: false,
    onUpdateModel: null,
    onUpdateTransportationEquipmentNeed: null,
    onUpdatePopulationType: null,
    setIsSameTripInfo: null
};

/* eslint-disable react/forbid-prop-types */
GeneralTripInformation.propTypes = {
    tripModel: PropTypes.object,
    populationTypes: PropTypes.object,
    transportationEquipmentNeeds: PropTypes.object,
    currentSibling: PropTypes.number,
    isSameTripInformation: PropTypes.bool,
    onUpdateModel: PropTypes.func,
    onUpdateTransportationEquipmentNeed: PropTypes.func,
    onUpdatePopulationType: PropTypes.func,
    setIsSameTripInfo: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default GeneralTripInformation;
