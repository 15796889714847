import React from 'react';
import ListIcon from '@mui/icons-material/List';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import Auth from '../auth/Auth';
import { localize } from "./Localizer";
import { paths, urlSupport } from './EnumHelper';

const useStyles = makeStyles(theme => ({
    icon : { color: theme.palette.white },
    schoolIcon: { color: theme.palette.white, fontSize: '1.5625rem' }
}));

const MenuOptions = () => {
    const classes = useStyles();

    const menuTripTrack = {
        id: paths.DistrictTripTracker.tabValue,
        menu: "TripAccess",
        label: localize('leftMenu.DistrictTracker'),
        icon: <FontAwesomeIcon icon={faSchool} size="lg" className={classes.schoolIcon} data-testid="DttIcon"/>,
        testid: "leftMenuDTT"
    };
    const menuManagement = {
        id: paths.DraftList.tabValue,
        menu: "ManagementAccess",
        label: localize('leftMenu.TripRequestForm'),
        icon: <ListIcon fontSize="large" className={classes.icon} />,
        testid: "leftMenuTRF"
    };
    const menuReport = {
        id: paths.Reports.tabValue,
        menu: "ReportAccess",
        label: localize('leftMenu.Report'),
        icon: <AssignmentIcon fontSize="large" className={classes.icon} />,
        testid: "leftMenuReports"
    };
    const menuBilling = {
        id: paths.Invoicing.tabValue,
        menu: "InvoiceAccess",
        label: localize('leftMenu.Invoicing'),
        icon: <FontAwesomeIcon icon={faMoneyBillWave} size="lg" className={classes.icon} />,
        testid: "leftMenuInvoicing"
    };
    const menuUser = {
        id: paths.User.tabValue,
        menu: "UserAccess",
        label: localize('leftMenu.User'),
        icon: <GroupIcon fontSize="large" className={classes.icon} />,
        testid: "leftMenuUsers"
    };
    const menuResources = {
        id: paths.Resources.tabValue,
        menu: "Resources",
        label: localize('leftMenu.Resources'),
        icon: <VideoLibraryIcon fontSize="large" className={classes.icon} />,
        testid: "leftMenuResources"
    };
    const support = {
        id: paths.Support.tabValue,
        path: urlSupport.url,
        label: localize('header.support')
    };
    const contact = {
        id: paths.Contact.tabValue,
        label: localize('header.contact')
    };
    const resetPassword = {
        id: paths.ResetPassword.tabValue,
        label: localize('header.resetPassword')
    };
    const logOut = {
        id: paths.LogOut.tabValue,
        label: localize('header.logout')
    };
    const userGuide = {
        id: paths.UserGuide.tabValue,
        path: paths.UserGuide.path,
        label: localize('header.userGuide')
    };
    const menuCalendar = {
        id: paths.CalendarUpload.tabValue,
        menu: "UploadCalendar",
        label: localize('calendarUpload.title'),
        icon: <DateRangeIcon fontSize="large" className={classes.icon} />,
        testid: "leftMenuCalendarUpload"
    };

    return {
        menuTripTrack,
        menuManagement,
        menuCalendar,
        menuReport,
        menuBilling,
        menuUser,
        menuResources,
        userGuide,
        support,
        contact,
        resetPassword,
        logOut
    };
};

export const leftMenu = () => {
    const {
        menuTripTrack,
        menuManagement,
        menuCalendar,
        menuBilling,
        menuReport,
        menuUser,
        menuResources
    } = MenuOptions();

    const hasTripTrackAccess = Auth.hasTripTrackingAccess();
    const hasManagementAccess = Auth.hasProgramManagementAccess();
    const hasBillingAccess = false; /*
                                    Change for show menu Invoicing  Auth.hasBillingAccess();
                                    */
    const hasUserAccess = Auth.hasUserAccess();
    const mainMenu = [];

    if (hasTripTrackAccess) {
        mainMenu.push(menuTripTrack);
    }

    if (hasManagementAccess) {
        mainMenu.push(menuManagement);
        if (Auth.getConfig().areReportsEnabled) {
            mainMenu.push(menuReport);
        }
    }

    if (hasBillingAccess) {
        mainMenu.push(menuBilling);
    }

    if (hasUserAccess) {
        mainMenu.push(menuCalendar);
        mainMenu.push(menuUser);
    }

    mainMenu.push(menuResources);

    return mainMenu;
};

export const rightMenu = () => {
    const {
        userGuide,
        support,
        contact,
        resetPassword,
        logOut
    } = MenuOptions();

    return [
        userGuide,
        support,
        contact,
        resetPassword,
        logOut
    ];
};