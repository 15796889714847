import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
    initialSetup,
    toggleMapChange
} from 'redux/workers/districtTripTracker/facilityWorker';
import Facility from 'components/Facility';
import withRouter from 'util/customHooks/withRouter';

const mapStateToProps = (state, ownProps) => {
    const {
        TripGroupReducers,
        FacilityReducers
    } = state;

    return {
        facilityId: ownProps.router.params.facilityId,
        facility: FacilityReducers.facility,
        trips: TripGroupReducers.trips,
        toggleMap: FacilityReducers.toggleMap,
        isLoading: FacilityReducers.isLoading
    };
};

const mapDispatchToProps = {
    initialSetup,
    toggleMapChange
};

const FacilityContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(Facility))
);

export default FacilityContainer;
