import { paths } from "./EnumHelper";

export const updatePathTab = (tab, id, update) => {
    const path = window.location.pathname.toLowerCase();

    update(path, tab, id);
};

export const addIdToPath = (item, id) => (
    item.path.replace('%id', id)
);

export const addIdsToPath = (item, ids) => {
    const keys = Object.keys(ids);
    let pathUpdated = item.path;

    keys.forEach(key => {
        pathUpdated = pathUpdated.replace(`%${key}`, ids[key]);
    });

    return pathUpdated;
};

export const getPathTabValue = path => {
    switch (path) {
    case paths.DistrictTripTracker.path:
        return paths.DistrictTripTracker.tabValue;
    case paths.Home.path:
        return paths.Home.tabValue;
    case paths.TransportationRequestForm.path:
        return paths.TransportationRequestForm.tabValue;
    case paths.TransportationRequest.path:
        return paths.TransportationRequest.tabValue;
    case paths.Reports.path:
        return paths.Reports.tabValue;
    case paths.StudentOnboardingReport.path:
        return paths.StudentOnboardingReport.tabValue;
    case paths.Invoicing.path:
        return paths.Invoicing.tabValue;
    case paths.User.path:
        return paths.User.tabValue;
    case paths.CalendarUpload.path:
        return paths.CalendarUpload.tabValue;
    case paths.CalendarDetail.path:
        return paths.CalendarDetail.tabValue;
    case paths.StudentUpload.path:
        return paths.StudentUpload.tabValue;
    case paths.DraftList.path:
        return paths.DraftList.tabValue;
    case paths.TrackTrip.path:
        return paths.TrackTrip.tabValue;
    case paths.Resources.path:
        return paths.Resources.tabValue;
    default:
        return 10;
    }
};