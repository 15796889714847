import React from 'react';
import PropTypes from 'prop-types';
import StyledTabPanel from 'components/Common/StyledTabPanel/StyledTabPanel';
import { TRTabOptions } from 'data/models/tabModel/TRTabModel';
import ContactInformationContainer from 'redux/containers/transportationRequest/ContactInformationContainer';
import ReviewContainer from 'redux/containers/transportationRequest/ReviewContainer';
import TripBuilderContainer from 'redux/containers/transportationRequest/TripBuilderContainer';
import StyledMainContainer from 'components/Common/StyledMainContainer/StyledMainContainer';
import StudentInformation from '../StudentInformation/StudentInformation';

function TRContent({ value }) {
    return (
        <StyledMainContainer>
            <StyledTabPanel value={value} index={TRTabOptions.Student}>
                <StudentInformation />
            </StyledTabPanel>
            <StyledTabPanel value={value} index={TRTabOptions.Contacts}>
                <ContactInformationContainer />
            </StyledTabPanel>
            <StyledTabPanel value={value} index={TRTabOptions.TripBuilder}>
                <TripBuilderContainer />
            </StyledTabPanel>
            <StyledTabPanel value={value} index={TRTabOptions.Review}>
                <ReviewContainer />
            </StyledTabPanel>
        </StyledMainContainer>
    );
}

TRContent.propTypes = {
    value: PropTypes.number.isRequired
};

export default TRContent;