import React from "react";
import { node, string } from "prop-types";
import colorConstants from "styles/ColorConstants";
import { museoFont700 } from "util/EnumHelper";

function VideoHeaderLabel({
    label,
    testid
}) {
    return (
        <span style={{
            color: colorConstants.EVERDRIVENDARKCHARCOAL,
            display: 'flex',
            fontFamily: museoFont700,
            fontSize: 24,
            margin: 0,
            lineHeight: 1.5,
            minHeight: 56,
            flexDirection: 'column',
            justifyContent: 'center'
        }}
        data-testid={testid}
        >
            {label}
        </span>
    );
}

VideoHeaderLabel.defaultProps = {
    label: null,
    testid: null
};

/* eslint-disable react/forbid-prop-types */
VideoHeaderLabel.propTypes = {
    label: node,
    testid: string
};
/* eslint-enable react/forbid-prop-types */

export default VideoHeaderLabel;