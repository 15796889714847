import React from 'react';
import { Grid, Paper, FormControl, InputLabel } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { styled } from '@mui/material/styles';
import breakpointsNames from '../../styles/ResponsiveConstants';
import { localize } from '../../util/Localizer';
import { convertToLocalTimeZone } from '../../util/dateUtils';
import { optionsFilterStudentOnBoardingGroup as filterOptions } from '../../util/EnumHelper';
import StyledButton from '../Common/StyledButton/StyledButton';
import StyledInput from '../Common/StyledInput/StyledInput';
import StyledFilterOptions from '../Common/StyledFilterOptions/StyledFilterOptions';
import commonUseStyles from '../../styles/CommonStyles';

const ReportPaper = styled(Paper)(({ theme }) => ({
    background: theme.palette.everDrivenLightBlue20,
    boxShadow: theme.typography.boxShadow,
    borderRadius: 6,
    ...commonUseStyles.defaultResponsive
}));

const LastModifiedBox = styled('div')(({ theme }) => ({
    background: theme.palette.everDrivenDarkBlue,
    color: theme.palette.white,
    height: 50.500,
    minWidth: 240,
    maxWidth: 260,
    borderRadius:'6px 0px 0px 6px',
    boxShadow: theme.typography.boxShadow,
    padding: '8px 8px',
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: '100%',
        maxWidth: '100%',
        borderRadius:'6px 6px 0px 0px'
    }
}));

const SearchFieldBox = styled('div')(({ theme }) => ({
    height: 50.500,
    flex: '1 0 140px',
    background: theme.palette.everDrivenLightBlue20,
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        width: '60%'
    }
}));

const SearchStudentForm = styled('form')(() => ({
    marginLeft:15,
    marginRight: 15
}));

const DoownloadButtonBox = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        width: '40%'
    }
}));

const DoownloadButton = styled(StyledButton)(({ theme }) => ({
    float:'right',
    height:50.500,
    borderRadius: '0px 6px 6px 0px',
    boxShadow:`0px 3px 6px ${theme.palette.everDrivenGrey40}`,
    color: theme.palette.white,
    width:'auto',
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: 180,
        borderRadius: '0px 0px 6px 0px'
    },
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        borderRadius: '0px 0px 6px 0px',
        width: '100%'
    }
}));

const InputFilter = styled(StyledInput)(({ theme }) => ({
    backgroundColor: theme.palette.transparent
}));

function StudentOnBoardingReportHeader({
    lastModfiedDate,
    filterTerm,
    onSearchStudent,
    onClickDownload,
    onHoldCount,
    inProgressCount,
    activeCount
}) {
    filterOptions[0].quantity = onHoldCount;
    filterOptions[1].quantity = inProgressCount;
    filterOptions[2].quantity = activeCount;

    const handleChange = (option) => {
        const divContainer = document.getElementById(`divGroup_${option.key}`);

        divContainer.scrollIntoView({
            behavior: 'smooth'
        });
    };

    return (
        <>
            <Grid item xs={12}>
                <ReportPaper className="memberCard">
                    <LastModifiedBox>
                        <div><b>{`${localize('report.updated')}:
                            ${convertToLocalTimeZone(lastModfiedDate)}`}</b>
                        </div>
                        <div>{localize('report.dailyUpdate')}</div>
                    </LastModifiedBox>
                    <SearchFieldBox>
                        <SearchStudentForm
                            autoComplete="off"
                            onSubmit={(e) => e.preventDefault()}>
                            <FormControl fullWidth variant="standard">
                                <InputLabel htmlFor="filterSearch">
                                    {localize('report.findStudents')}
                                </InputLabel>
                                <InputFilter
                                    id="filterSearch"
                                    value={filterTerm}
                                    fullWidth
                                    onChange={(e)=>onSearchStudent(e.target.value)}
                                />
                            </FormControl>
                        </SearchStudentForm>
                    </SearchFieldBox>
                    <DoownloadButtonBox>
                        <DoownloadButton
                            color="alternativeButton"
                            testId="downloadStudentOnBoardingReportButton"
                            onClick={() => onClickDownload()}
                        >
                            <GetAppIcon />
                            {localize('report.download')}
                        </DoownloadButton>
                    </DoownloadButtonBox>
                </ReportPaper>
            </Grid>
            <StyledFilterOptions
                handleChange={handleChange}
                listOptions={filterOptions}
                title={localize('report.studentStatus')}
                description={localize('report.clickToJump')}
            />
        </>
    );
}

export default StudentOnBoardingReportHeader;
