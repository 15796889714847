import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import breakpointsNames from '../../styles/ResponsiveConstants';
import CustomList from '../../controls/Common/CustomList';
import { localize } from '../../util/Localizer';
import commonUseStyles from '../../styles/CommonStyles';
import { Enum } from '../../util/EnumHelper';

const useStyles = (theme) => ({
    spanTitle: {
        ...commonUseStyles.spanTitle
    },
    schoolListActionContainer: {
        [theme.breakpoints.down(breakpointsNames.md)]: {
            width: '100%'
        }
    },
    addRemoveIconButton: {
        fontSize: 10,
        [theme.breakpoints.down(breakpointsNames.md)]: {
            fontSize: 18
        }
    },
    schoolListActions: {
        [theme.breakpoints.down(breakpointsNames.md)]: {
            width: '100%',
            justifyContent: 'center'
        }
    }
});

function UserSchool({
    classes,
    user,
    facilityList,
    setErrorAlert,
    onChangeInput
}) {
    const [ searchValue, setSearchValue ] = useState('');
    const contextUser = {...user };

    const updateSchoolLists = () => (
        facilityList.filter(school => school.name.toUpperCase().includes(searchValue))
    );

    const [ schoolList, setSchoolList ] = useState(facilityList);

    const selectSchool = (newSelectedSchool) => {
        const allowedSchoolsUser = [...contextUser.allowedSchools];
        const currentIndex = allowedSchoolsUser.indexOf(newSelectedSchool.id);

        if (currentIndex !== -1) {
            setErrorAlert('');

            allowedSchoolsUser.splice(currentIndex, 1);
        } else if (contextUser.allowedSchools.length >= Enum.maxAllowedSchoolsLength) {
            setErrorAlert(localize('userForm.PreventAddAllowedSchools'));
        } else {
            setErrorAlert('');

            if (currentIndex === -1) {
                allowedSchoolsUser.push(newSelectedSchool.id);
            }
        }

        contextUser.allowedSchools = (allowedSchoolsUser);

        onChangeInput(contextUser);
    };

    const removeAllSchools = () => {
        contextUser.allowedSchools = [];
        onChangeInput(contextUser);
    };

    useEffect(() => {
        setSchoolList(updateSchoolLists());
    }, [searchValue]);

    return (
        <Grid container alignItems="center" spacing={0}>
            <span className={classes.spanTitle}>{localize('userForm.school')}</span>
            <Grid item xs={12} sm={12} md={10} lg={10}>
                <CustomList
                    title={localize('userForm.labelChoices')}
                    items={schoolList}
                    unfilteredItems={facilityList}
                    checkedList={contextUser.allowedSchools ?? []}
                    onUpdateCheckedList={selectSchool}
                    labelSearchText={localize('userForm.labelSearchSchool')}
                    updateSearch={setSearchValue}
                    searchValue={searchValue}
                    removeAllSchools={removeAllSchools}
                />
            </Grid>
        </Grid>
    );
}

export default withStyles(useStyles)(UserSchool);
