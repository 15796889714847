/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Map from 'google-maps-react';
import { withTheme } from '@mui/styles';
import { nullCarIcon } from 'styles/MapStyles';
import breakpointsNames from 'styles/ResponsiveConstants';
import useWidth from 'util/customHooks/useWidth';
import { Enum } from 'util/EnumHelper';

import Marker from './Marker';

function Container({ trip, theme }) {
    const width = useWidth();
    const [ mapRef, setMapRef ] = React.useState(null);

    let driver = trip.Coordinates;
    let pickup = trip.PickUpLocation.Coordinates;
    let dropoff = trip.DropOffLocation.Coordinates;
    let markerColor = theme.palette.black;
    let heightMap = 'calc(100vh - 190px)';
    let minHeightMap = 695;

    switch (width) {
    case breakpointsNames.xs:
        heightMap = 'calc(100vh - 400px)';
        minHeightMap = 300;
        break;
    case breakpointsNames.sm:
        heightMap = 'calc(100vh - 500px)';
        minHeightMap = 300;
        break;
    default:
        break;
    }

    switch (trip.State) {
    case Enum.TripState.Active:
        markerColor = theme.palette.tealOption1;
        break;
    case Enum.TripState.EnRoute:
        markerColor = theme.palette.tealOption2;
        break;
    case Enum.TripState.OnSite:
    case Enum.TripState.OnSiteAtDropoff:
        markerColor = theme.palette.greenOption2;
        break;
    case Enum.TripState.OnBoard:
        markerColor = theme.palette.greenOption3;
        break;
    case Enum.TripState.Accepted:
        markerColor = theme.palette.everDrivenGrey;
        break;
    case Enum.TripState.Scheduled:
        if (trip.commands &&
            trip.commands.findIndex(command => command.action === "Cancel"
                || command.Action === "Cancel") > -1) {
            markerColor = theme.palette.grayOption1;
        } else {
            markerColor = theme.palette.lightBlueOption1;
        }
        break;
    case Enum.TripState.NoShow:
        markerColor = theme.palette.orangeOption1;
        break;
    case Enum.TripState.Canceled:
        markerColor = theme.palette.grayOption2;
        break;
    case Enum.TripState.Completed:
        markerColor = theme.palette.greenOption1;
        break;
    default:
        break;
    }

    const factor = 1000000;

    // This is only here becuase the data is multiplied by factor.
    // driver = {latitude: 38.9389511, longitude: -104.8132027};
    if (driver) {
        driver = { latitude: driver.Latitude / factor, longitude: driver.Longitude / factor };
    } else {
        driver = null;
    }

    pickup = { latitude: pickup.Latitude / factor, longitude: pickup.Longitude / factor };
    dropoff = { latitude: dropoff.Latitude / factor, longitude: dropoff.Longitude / factor };

    const carPosition = driver ? { lat: driver.latitude, lng: driver.longitude } : null;
    const dropoffLocation = { lat: dropoff.latitude, lng: dropoff.longitude };
    const pickupLocation = { lat: pickup.latitude, lng: pickup.longitude };

    const rotation = trip.DriverInfo ? trip.Heading : 0;
    const scale = 0.4;
    const anchorX = 28;
    const anchorY = 76;

    const isPassengerOnBoard = trip.status === 'OnBoard';
    const carDotColor = isPassengerOnBoard ? theme.palette.everDrivenDarkGreen : theme.palette.black;
    let pathCarBack = 'M29.9,99.8H16.4c-7.2,0-13-5.8-13-13V13c0-6.6,5.2-12';

    pathCarBack = `${pathCarBack}.1,11.8-12.4c2.1-0.1,4.6-0.1,7.6-0.1 c3.2,0,`;
    pathCarBack = `${pathCarBack}5.9,0.1,8.2,0.2C37.6,1,42.8,6.4,42.8,13.1v73`;
    pathCarBack = `${pathCarBack}.6C42.8,93.9,37,99.8,29.9,99.8z`;
    const carBackground = {
        path: pathCarBack,
        fillOpacity: 1,
        fillColor: theme.palette.white,
        scale,
        rotation,
        strokeWeight: 1,
        anchorX,
        anchorY
    };
    let pathCarIcon = 'M46.5,39.1c0.1-1.6-2.5-3.8-3.7-4.7V13.1C42.8,6.4,37.6,';

    pathCarIcon = `${pathCarIcon}1,31,0.7c-2.3-0.1-5-0.2-8.3-0.2c-3,0-5.5,0.1`;
    pathCarIcon = `${pathCarIcon}-7.6,0.1	C8.4,0.9,3.2,6.4,3.2,13v21.8c-1.3,1.`;
    pathCarIcon = `${pathCarIcon}1-3.2,2.8-3.1,4.2l3.1-1.6v49.2c0,7.2,5.8,13,`;
    pathCarIcon = `${pathCarIcon}13,13h13.6c7.2,0,13-5.8,13-13V37.2L46.5,39.`;
    pathCarIcon = `${pathCarIcon}1z M38.2,6.9c2.6,3.5-2.1,5.3-3.8,.1c-1.8-1.`;
    pathCarIcon = `${pathCarIcon}1-1.9-7.3-1.9-7.3S35.6,3.4,38.2,6.9z M22.7,24.`;
    pathCarIcon = `${pathCarIcon}7h0.1c6.4,0,12.7,1.2,14.9,4.1	C40,31.6,37,43,36.8,43.5H8.7C8.6,`;
    pathCarIcon = `${pathCarIcon}43,5.6,31.6,7.8,28.8C10.1,25.9,16.3,24.7,22.7,24.7z M7.3,6.9C9.9,`;
    pathCarIcon = `${pathCarIcon}3.4,13,3.8,13,3.8	s-0.1,6.1-1.9,7.3C9.3,12.2,4.7,10.5,7.3,6.9z M7.`;
    pathCarIcon = `${pathCarIcon}4,87.3c-2.2-1.3-2.6-5.1-2.6-5.1l0.3-47l2.5,.4L7.4,87.3z M23.1,96.4L23`;
    pathCarIcon = `${pathCarIcon}.1,96.4	c-11.9,0-13-3.1-13-3.1h26.2C36.4,93.2,35.1,96.4,23.1,96.4z `;
    pathCarIcon = `${pathCarIcon}M38.2,87.4L38,43.7l2.5-8.4l0.3,47C40.8,82.3,40.4,86.1,38.2,87.4z`;

    const carIcon = {
        path: pathCarIcon,
        fillOpacity: 1,
        fillColor: theme.palette.black,
        scale,
        rotation,
        strokeWeight: 1,
        anchorX,
        anchorY
    };

    const carDot = {
        path: 'M34.3,60.5c0,6.1-4.9,11-11,11s-11-4.9-11-11s4.9-11,11-11S34.3,54.4,34.3,60.5z',
        fillOpacity: 1,
        fillColor: carDotColor,
        scale,
        rotation,
        strokeWeight: 1,
        anchorX,
        anchorY
    };

    let pathNoSchoolIcon = 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1';

    pathNoSchoolIcon = `${pathNoSchoolIcon} 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5`;
    pathNoSchoolIcon = `${pathNoSchoolIcon},14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z`;

    const noSchoolIcon = {
        path: pathNoSchoolIcon,
        fillOpacity: 1,
        strokeOpacity: 0,
        fillColor: markerColor,
        label: '1',
        scale: 2,
        labelOriginX: 25,
        labelOriginY: 20,
        anchorX: 11,
        anchorY: 20
    };

    let schoolBubblePath = 'M52.8,26.6c0,24.5-23.6,48.5-24.6,49.5c-0.5,0.5-1.1,0.8-1.8';

    schoolBubblePath = `${schoolBubblePath},0.8s-1.3-0.3-1.8-0.8C23.6,75.1,0,51.1,0,26.6 C0,12,`;
    schoolBubblePath = `${schoolBubblePath}11.9,0.2,26.4,0.2C41,0.2,52.8,12,52.8,26.6z`;

    const bubblePathIcon = {
        path: schoolBubblePath,
        fillOpacity: 1,
        strokeOpacity: 0,
        fillColor: markerColor,
        label: '2',
        scale: 0.6,
        labelOriginX: 25,
        labelOriginY: 20,
        anchorX: 28,
        anchorY: 76
    };

    let pathSchoolIcon = 'M19,15H17V13H19M19,19H17V17H19M13,7H11V5H13M13,11H11V9H13M13,';

    pathSchoolIcon = `${pathSchoolIcon}15H11V13H13M13,19H11V17H13M7,11H5V9H7M7,15H5V13`;
    pathSchoolIcon = `${pathSchoolIcon}H7M7,19H5V17H7M15,11V5L12,2L9,5V7H3V21H21V11H15Z`;

    const schoolIcon = {
        path: pathSchoolIcon,
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: theme.palette.white,
        fillColor: theme.palette.white,
        labelOriginX: 25,
        labelOriginY: 40,
        scale: 1,
        anchorX: anchorX - 15,
        anchorY: anchorY - 36
    };

    const mapStyle = [{
        featureType: 'poi.school',
        stylers: [{
            visibility: 'off'
        }]
    }];

    const mapStyles = {
        position: 'relative',
        width: '100%',
        height: heightMap,
        minHeight: minHeightMap,
        borderRadius: (width === breakpointsNames.xs || width === breakpointsNames.sm) ? 0 : '12px',
        boxShadow: `0px 3px 6px ${theme.palette.everDrivenGrey40}`
    };

    const markers = carPosition ? [ carPosition, dropoffLocation, pickupLocation ] :
        [ dropoffLocation, pickupLocation ];
    
    const centerAndZoom = () => {
        const bounds = markers.reduce((ebounds, marker) => ebounds.extend(marker),
            new window.google.maps.LatLngBounds());

        mapRef.map.setCenter(bounds.getCenter());
        mapRef.map.fitBounds(bounds);
    };

    const renderCar = (carPos, carBg, carIc, carDt, markerClr) => {
        if (!carPos || trip.status === "Completed") {
            return [];
        }

        if (!trip.Heading) {
            let pathIc = 'M52.8,26.6c0,24.5-23.6,48.5-24.6,49.5c-0.5,0.5-1.1,0.8-1.8';

            pathIc = `${pathIc},0.8s-1.3-0.3-1.8-0.8C23.6,75.1,0,51.1,0,26.6 C0,12,`;
            pathIc = `${pathIc}11.9,0.2,26.4,0.2C41,0.2,52.8,12,52.8,26.6z`;

            const ic = {
                path: pathIc,
                fillOpacity: 1,
                strokeOpacity: 0,
                fillColor: markerClr,
                label: '3',
                scale: 0.6,
                labelOriginX: 25,
                labelOriginY: 20,
                anchorX: 28,
                anchorY: 76
            };

            return [
                <Marker
                    key="nullCar"
                    position={carPos}
                    icon={ic}
                />,
                <Marker
                    key="nullCar-back"
                    position={carPos}
                    icon={nullCarIcon}
                />
            ];
        }

        return [
            <Marker
                key="1"
                position={carPos}
                icon={carBg}
            />,
            <Marker
                key="2"
                position={carPos}
                icon={carIc}
            />,
            <Marker
                key="3"
                position={carPos}
                icon={carDt}
            />
        ];
    };

    return (
        <Map ref={(m) => { setMapRef(m); }}
            google={window.google}
            onReady={() => centerAndZoom()}
            containerStyle={mapStyles}
            style={mapStyles}
            styles={mapStyle}
            streetViewControl={false}
        >
            {trip.PickUpLocation.Name ?
                <Marker position={pickupLocation} icon={bubblePathIcon} /> :
                <Marker position={pickupLocation} icon={noSchoolIcon} />
            }
            {trip.PickUpLocation.Name ?
                <Marker position={pickupLocation} icon={schoolIcon} /> :
                null
            }

            {trip.DropOffLocation.Name ?
                <Marker position={dropoffLocation} icon={bubblePathIcon} /> :
                <Marker position={dropoffLocation} icon={noSchoolIcon} />
            }
            {trip.DropOffLocation.Name ?
                <Marker position={dropoffLocation} icon={schoolIcon} /> :
                null
            }

            {renderCar(carPosition,
                carBackground,
                carIcon,
                carDot,
                markerColor).map(m => m)}
        </Map>
    );
}

Container.propTypes = {
    trip: PropTypes.shape({
        Coordinates: PropTypes.shape({
            Latitude: PropTypes.number,
            Longitude: PropTypes.number
        }),
        PickUpLocation: PropTypes.shape({
            Coordinates: PropTypes.shape({
                Latitude: PropTypes.number,
                Longitude: PropTypes.number
            })
        }),
        DropOffLocation: PropTypes.shape({
            Coordinates: PropTypes.shape({
                Latitude: PropTypes.number,
                Longitude: PropTypes.number
            })
        }),
        State: PropTypes.number,
        Heading: PropTypes.number
    }).isRequired
};

export default withTheme(Container);
