import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import withRouter from 'util/customHooks/withRouter';

import {
    initialSetup,
    goToTRF,
    goToStudentDetail,
    showStudents
} from 'redux/workers/districtTripTracker/dttStudentWorker';

import DttStudents from '../../../components/districtTripTracker/DttStudents/DttStudents';

const mapStateToProps = state => {
    const { DttStudentReducers } = state;

    return {
        showStudentData: DttStudentReducers.showStudentData
    };
};

const mapDispatchToProps = {
    initialSetup,
    goToTRF,
    goToStudentDetail,
    showStudents
};

const DttStudentContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(DttStudents)));

export default DttStudentContainer;
