import React from 'react';
import { object } from 'prop-types';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import Auth from 'auth/Auth';
import { optionsFilterUsers as options, Enum } from '../../util/EnumHelper';
import { localize } from '../../util/Localizer';

const useStyles = theme => ({
    item: {
        paddingTop: 6,
        backgroundColor: theme.palette.everDrivenLightBlue20,
        fontWeight: 'bold',
        fontSize: '12px'
    },
    groupButton: {
        display: 'flex',
        textAlign: 'center',
        opacity: 1,
        borderRadius: 6,
        '& > *': {
            margin: theme.spacing(0.4),
            width: 80,
            height: theme.spacing(3.5)
        }
    }
});

function UserGroups({ user, classes }) {
    const teacherAppEnabled = Auth.getConfig().teacherAppEnabled !== false ?
        true : Auth.getConfig().teacherAppEnabled;

    const buttonAdmin = user.isAdministrator
        ? <Paper className={classes.item}>{localize('userManagement.admin')}</Paper>
        : null;
    const buttonProgMgmt = user.canAccessProgramManagement
        ? <Paper className={classes.item}>{localize(options[Enum.filterUsers.progMgmt].name)}</Paper>
        : null;
    const buttonDTT = user.canAccessTripTracking
        ? <Paper className={classes.item}>{localize(options[Enum.filterUsers.DTT].name)}</Paper>
        : null;
    const buttonVIP = user.isTeacher
        ? <Paper className={classes.item}>{localize(options[Enum.filterUsers.VIP].name)}</Paper>
        : null;

    return (
        <div className={classes.groupButton}>
            {buttonAdmin}
            {buttonProgMgmt}
            {buttonDTT}
            {teacherAppEnabled && buttonVIP}
        </div>
    );
}

UserGroups.defaultProps = {
    user: null,
    classes: null
};

/* eslint-disable react/forbid-prop-types */
UserGroups.propTypes = {
    user: object,
    classes: object
};
/* eslint-enable react/forbid-prop-types */

export default withStyles(useStyles)(UserGroups);
