import React from 'react';
import {
    Grid,
    FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import StyledTypography from 'components/Common/StyledTypography/StyledTypography';
import StyledTextField from 'components/Common/StyledTextField/StyledTextField';
import StyledFormLabel from 'components/Common/StyledFormLabel/StyledFormLabel';
import { localize } from 'util/Localizer';
import { realtionships } from 'redux/constants';
import CommonStyles from 'styles/CommonStyles';
import StyledFormControlLabel from 'components/Common/StyledFormControlLabel/StyledFormControlLabel';
import StyledCheckbox from 'components/Common/StyledCheckbox/StyledCheckbox';
import Auth from 'auth/Auth';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import colorConstants from 'styles/ColorConstants';
import StyledConfirmModal from 'components/Common/StyledConfirmModal/StyledConfirmModal';
import StyledPhoneNumber from 'components/Common/StyledPhoneNumber/StyledPhoneNumber';
import { stringValidate, validateEmail } from 'util/textFormatters';
import { isPhoneNumber } from 'util/validationUtil';
import { museoSansFont } from 'util/EnumHelper';
import StyledDropdown from 'components/Common/StyledDropdown/StyledDropdown';

function ContactForm({
    contactNumber,
    contactModel,
    formName,
    numberOfContacts,
    showRemoveModal,
    onChangeContactValue,
    onLoadRemoveContactModal,
    onRemoveContactSection,
    onShowRemoveModal,
    errors
}) {
    const checkEmailError = () => (
        errors[`contact${contactNumber}EmailAddress`]?.isError
            && ((contactModel.appAccess
                    && (contactModel.emailAddress === "" || validateEmail(contactModel.emailAddress ?? '')))
                || (!contactModel.appAccess
                    && (contactModel.emailAddress !== "" && validateEmail(contactModel.emailAddress ?? '')))
            )
    );

    return (
        <>
            <StyledTypography
                extraStyle={CommonStyles.subTitle}
            >
                {localize('transportationRequest.contactInformation', { number: contactNumber })}
            </StyledTypography>
            {numberOfContacts > 1 &&
                <StyledButton
                    color="inherit"
                    style={{
                        ...CommonStyles.linkButton,
                        color: colorConstants.EVERDRIVENFUCHSIA,
                        marginLeft: 'auto',
                        alignItems: 'center'
                    }}
                    type="button"
                    startIcon={<CloseIcon style={{ fontSize: 16 }} />}
                    variant="text"
                    testId={`remove${formName}`}
                    onClick={() => onLoadRemoveContactModal(contactNumber)}
                >
                    {localize('transportationRequest.remove')}
                </StyledButton>
            }
            <Grid container sx={{ paddingBottom: contactNumber === numberOfContacts ? '0px' : '40px' }}>
                <Grid item xs={6} sx={{ paddingRight: '20px' }}>
                    <StyledFormLabel
                        label={contactNumber > 1
                            ? localize('transportationRequest.contactName2', { number: contactNumber })
                            : localize('transportationRequest.contactName')}
                        isRequired
                    />
                    <StyledTextField
                        autoComplete="disabled"
                        inputProps={{ className: 'qm_block', "data-testid": `${formName}Name` }}
                        isNewStyle
                        isRequired
                        name={`${formName}Name`}
                        onChange={onChangeContactValue}
                        value={contactModel.name ?? ""}
                        isError={errors[`contact${contactNumber}Name`]?.isError && stringValidate(contactModel.name)}
                    />
                </Grid>
                <Grid item xs={6} sx={{ paddingLeft: '20px' }}>
                    <StyledFormLabel label={localize('transportationRequest.relationship')} isRequired />
                    <StyledDropdown
                        items={realtionships}
                        name={`${formName}Relationship`}
                        value={contactModel.relationship ?? ''}
                        onChange={onChangeContactValue}
                        emptyOption={localize('transportationRequest.selectOption')}
                        isError={errors[`contact${contactNumber}Relationship`]?.isError &&
                            stringValidate(contactModel.relationship)}
                    />
                </Grid>
                <Grid item xs={6} sx={{ paddingRight: '20px', paddingTop: '20px' }}>
                    <StyledFormLabel label={localize('transportationRequest.phoneNumber')} isRequired />
                    <StyledPhoneNumber
                        idMobilePhone={`${formName}PhoneNumber`}
                        modelInput={contactModel.phoneNumber ?? ""}
                        handleChangeModel={onChangeContactValue}
                        inputProps={{ className: 'qm_block', "data-testid": `${formName}PhoneNumber` }}
                        isRequired
                        error={errors[`contact${contactNumber}PhoneNumber`]?.isError &&
                            (contactModel.phoneNumber ? !isPhoneNumber(contactModel.phoneNumber) : true)}
                    />
                </Grid>
                <Grid item xs={6} sx={{ paddingLeft: '20px', paddingTop: '20px' }}>
                    <StyledFormLabel label={localize('transportationRequest.altPhoneNumber')} />
                    <StyledPhoneNumber
                        idMobilePhone={`${formName}AltPhoneNumber`}
                        modelInput={contactModel.altPhoneNumber ?? ""}
                        handleChangeModel={onChangeContactValue}
                        inputProps={{ className: 'qm_block', "data-testid": `${formName}AltPhoneNumber` }}
                        error={errors[`contact${contactNumber}AltPhoneNumber`]?.isError &&
                        (contactModel.altPhoneNumber ? !isPhoneNumber(contactModel.altPhoneNumber) : false)}
                    />
                </Grid>
                <Grid container columnSpacing={4} sx={{ paddingRight: '20px', paddingTop: '20px' }}>
                    <Grid item sx={{ whiteSpace: 'nowrap' }}>
                        <StyledFormLabel
                            label={localize('transportationRequest.emailAddress')}
                            isRequired={contactModel.appAccess}
                        />
                    </Grid>
                    <Grid item sx={{ paddingTop:'5px' }}>
                        <FormHelperText sx={{
                            marginLeft: 0,
                            marginTop: 0,
                            paddingTop: 0,
                            lineHeight: 1.5,
                            fontFamily: museoSansFont
                        }}>
                            {(contactModel.appAccess && errors[`contact${contactNumber}EmailAddress`]?.isError
                                && validateEmail(contactModel.emailAddress ?? ''))
                                && errors[`contact${contactNumber}EmailAddress`]?.message
                            }
                        </FormHelperText>
                    </Grid>
                </Grid>
                <Grid item xs={6} sx={{ paddingRight: '20px' }}>
                    <StyledTextField
                        autoComplete="disabled"
                        isNewStyle
                        name={`${formName}EmailAddress`}
                        value={contactModel.emailAddress ?? ""}
                        isError={checkEmailError()}
                        type="email"
                        onChange={onChangeContactValue}
                        inputProps={{ className: 'qm_block', "data-testid": `${formName}EmailAddress`, maxLength: 128 }}
                    />
                </Grid>
                {Auth.getConfig().parentAppEnabled &&
                    <Grid item xs={6} sx={{ paddingLeft: '20px', display: 'flex', alignItems: 'center' }}>
                        <StyledFormControlLabel
                            size="medium"
                            data-testid={`${formName}AppAccess`}
                            control={
                                <StyledCheckbox
                                    color="secondary"
                                    isChecked={contactModel.appAccess }
                                    name={`${formName}AppAccess`}
                                    size="small"
                                    onChange={onChangeContactValue}
                                />
                            }
                            label={localize('transportationRequest.addToVipApp')}
                        />
                    </Grid>
                }
            </Grid>
            <StyledConfirmModal
                isOpen={showRemoveModal}
                contentMessage={localize('transportationRequest.removeContactConfirm')}
                confirmInputLabel={localize('transportationRequest.remove')}
                cancelInputLabel={localize('transportationRequest.cancel')}
                onConfirm={onRemoveContactSection}
                onCancel={() => onShowRemoveModal(false)}
            />
        </>
    );
}

ContactForm.defaultProps = {
    contactNumber: 1,
    contactModel: {},
    numberOfContacts: 1,
    showRemoveModal: false,
    onChangeContactValue: null,
    onLoadRemoveContactModal: null,
    onRemoveContactSection: null,
    onShowRemoveModal: null,
    errors: {}
};

/* eslint-disable react/forbid-prop-types */
ContactForm.propTypes = {
    contactNumber: PropTypes.number,
    contactModel: PropTypes.object,
    formName: PropTypes.string.isRequired,
    numberOfContacts: PropTypes.number,
    showRemoveModal: PropTypes.bool,
    onChangeContactValue: PropTypes.func,
    onLoadRemoveContactModal: PropTypes.func,
    onRemoveContactSection: PropTypes.func,
    onShowRemoveModal: PropTypes.func,
    errors: PropTypes.object
};
/* eslint-enable react/forbid-prop-types */

export default ContactForm;
