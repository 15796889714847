import { localize } from 'util/Localizer';

const createlabel = (labelKey) => localize(`transportationRequest.${labelKey}`);

const createDescription = (contentKey) => ({
    label: createlabel(contentKey),
    content: localize(`transportationRequest.${contentKey}Description`)
});

export const TransportationNeedOptions = {
    handToHand: "handToHand",
    monitorNeeded: "monitorNeeded",
    boosterSeat: "boosterSeat",
    carSeat: "carSeat",
    wheelchair: "wheelchair",
    safetyVest: "safetyVest",
    buckleGuard: "buckleGuard",
    mustRideAlone: "mustRideAlone"
};

export const PopulationTypeOptions = {
    MKV: "MKV",
    SPED: "SPED",
    regularEducationService: "regularEducationService",
    outOfDistrict: "outOfDistrict",
    ESE: "ESE",
    ESSA: "ESSA",
    deafEducationServices: "deafEducationServices",
    schoolOfChoice: "schoolOfChoice",
    medicallyFragile: "medicallyFragile",
    ATP: "ATP",
    multiDistrict: "multiDistrict",
    ESY: "ESY",
    NCLB: "NCLB",
    specialEvents: "specialEvents",
    magnetSchool: "magnetSchool"
};

export const WheelchairOptions = {
    needsRamp: 'Needs Ramp',
    needsLift: 'Needs Lift'
};

export const MonitorNeededOptions = {
    districtMonitor: 'District Monitor',
    everDrivenMonitor: 'Ever Driven Monitor'
};

export const PopulationTypeDescription = [
    createDescription(PopulationTypeOptions.MKV),
    createDescription(PopulationTypeOptions.SPED),
    createDescription(PopulationTypeOptions.regularEducationService),
    createDescription(PopulationTypeOptions.outOfDistrict),
    createDescription(PopulationTypeOptions.ESE),
    createDescription(PopulationTypeOptions.ESSA),
    createDescription(PopulationTypeOptions.deafEducationServices),
    createDescription(PopulationTypeOptions.schoolOfChoice),
    createDescription(PopulationTypeOptions.medicallyFragile),
    createDescription(PopulationTypeOptions.ATP),
    createDescription(PopulationTypeOptions.multiDistrict),
    createDescription(PopulationTypeOptions.ESY),
    createDescription(PopulationTypeOptions.NCLB),
    createDescription(PopulationTypeOptions.specialEvents),
    createDescription(PopulationTypeOptions.magnetSchool)
];

export const TransportationNeedDescription = [
    createDescription(TransportationNeedOptions.handToHand),
    createDescription(TransportationNeedOptions.monitorNeeded),
    createDescription(TransportationNeedOptions.boosterSeat),
    createDescription(TransportationNeedOptions.carSeat),
    createDescription(TransportationNeedOptions.wheelchair),
    createDescription(TransportationNeedOptions.safetyVest),
    createDescription(TransportationNeedOptions.buckleGuard),
    createDescription(TransportationNeedOptions.mustRideAlone)
];

export const InitialTransportationNeed = [
    {
        name: TransportationNeedOptions.handToHand,
        label: createlabel(TransportationNeedOptions.handToHand)
    },
    {
        name: TransportationNeedOptions.wheelchair,
        label: createlabel(TransportationNeedOptions.wheelchair)
    },
    {
        name: TransportationNeedOptions.monitorNeeded,
        label: createlabel(TransportationNeedOptions.monitorNeeded)
    },
    {
        name: TransportationNeedOptions.safetyVest,
        label: createlabel(TransportationNeedOptions.safetyVest)
    },
    {
        name: TransportationNeedOptions.boosterSeat,
        label: createlabel(TransportationNeedOptions.boosterSeat)
    },
    {
        name: TransportationNeedOptions.buckleGuard,
        label: createlabel(TransportationNeedOptions.buckleGuard)
    },
    {
        name: TransportationNeedOptions.carSeat,
        label: createlabel(TransportationNeedOptions.carSeat)
    },
    {
        name: TransportationNeedOptions.mustRideAlone,
        label: createlabel(TransportationNeedOptions.mustRideAlone)
    }
];

export const InitialPopulationType = [
    {
        name: 'populationTypeMKV',
        label: createlabel(PopulationTypeOptions.MKV)
    },
    {
        name: 'populationTypeESE',
        label: createlabel(PopulationTypeOptions.ESE)
    },
    {
        name: 'populationTypeSPED',
        label: createlabel(PopulationTypeOptions.SPED)
    },
    {
        name: 'populationTypeESSA',
        label: createlabel(PopulationTypeOptions.ESSA)
    },
    {
        name: 'populationTypeRegularEd',
        label: createlabel(PopulationTypeOptions.regularEducationService)
    },
    {
        name: 'populationTypeDeafEd',
        label: createlabel(PopulationTypeOptions.deafEducationServices)
    },
    {
        name: 'populationTypeOutofDistrict',
        label: createlabel(PopulationTypeOptions.outOfDistrict)
    }
];

export const MorePopulationType = [
    {
        name: 'populationTypeSchoolofChoice',
        label: createlabel(PopulationTypeOptions.schoolOfChoice)
    },
    {
        name: 'populationTypeMedFragile',
        label: createlabel(PopulationTypeOptions.medicallyFragile)
    },
    {
        name: 'populationTypeATP',
        label: createlabel(PopulationTypeOptions.ATP)
    },
    {
        name: 'populationTypeMultiDistrict',
        label: createlabel(PopulationTypeOptions.multiDistrict)
    },
    {
        name: 'populationTypeESY',
        label: createlabel(PopulationTypeOptions.ESY)
    },
    {
        name: 'populationTypeNCLB',
        label: createlabel(PopulationTypeOptions.NCLB)
    },
    {
        name: 'populationTypeSpecialEvents',
        label: createlabel(PopulationTypeOptions.specialEvents)
    },
    {
        name: 'populationTypeMagnet',
        label: createlabel(PopulationTypeOptions.magnetSchool)
    }
];