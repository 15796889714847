import { connect } from 'react-redux';
import withTheme from '@mui/styles/withTheme';

import UserRow from 'components/user/UserRow/UserRow';
import { setUserResponse } from 'redux/actions/userActions';
import {
    editUser as onSubmitEdit,
    sendInvitationUser,
    handleChangeCheck
} from 'redux/workers/userWorker';

const mapStateToProps = state => {
  	const { UserReducers, FacilityReducers } = state;

    if (UserReducers) {
        return {
            facilityList: FacilityReducers.facilities,
            errorMessage: UserReducers.userResponse
        };
    }

    return {};
};

const UserRowContainer = withTheme(
    connect(
        mapStateToProps, {
            onSubmitEdit,
            setUserResponse,
            handleChangeCheck,
            sendInvitationUser
        }
    )(UserRow)
);

export default UserRowContainer;
