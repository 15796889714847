import React from 'react';
import { Grid, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    bool,
    func,
    node,
    string
} from 'prop-types';

import commonUseStyles, { commonStyles } from '../../../styles/CommonStyles';
import StyledButton from '../StyledButton/StyledButton';

import breakpointsNames from '../../../styles/ResponsiveConstants';

const CardSection = styled('div')((props) => {
    const isTrueSet = /^true$/i.test(props?.isopenpanel);

    const cardSection = {
        transition: 'height 1s ease',
        height: 'auto',
        padding: '0'
    };

    const closed = {
        height: '0px',
        overflow: 'hidden'
    };

    return {
        ...cardSection,
        ...(!isTrueSet && closed)
    };
});

const GroupCardBtnGrid = styled(Grid)(({ theme }) => ({
    background: theme.palette.everDrivenLightBlue20,
    ...commonUseStyles.defaultResponsive
}));

const GroupCardsButton = styled(StyledButton)((props) => {
    const isTrueSet = /^true$/i.test(props?.grouped);
    const button = {
        ...commonStyles(props.theme).groupCardFilterButton,
        boxShadow: 'none'
    };

    const groupStudentBtnSml = {
        margin: '8px',
        marginTop: '0'
    };

    const groupStudentBtnNoActive = {
        '&:active, &.active': {
            backgroundColor: `${props.theme.palette.everDrivenLightBlue} !important`,
            color: `${props.theme.palette.white} !important`
        },
        [props.theme.breakpoints.down(breakpointsNames.sm)]: {
            '&:hover': {
                backgroundColor: 'transparent !important',
                color: `${props.theme.palette.black} !important`
            }
        }
    };

    return {
        ...button,
        ...(groupStudentBtnSml),
        ...(!isTrueSet && groupStudentBtnNoActive)
    };
});

const LoadMoreContainerBox = styled('div')(() => ({
    position: 'relative',
    margin: '0 auto',
    width: 300
}));

const LoadMoreButton = styled(StyledButton)(({ theme }) => ({
    ...commonUseStyles.roundedButton,
    ...commonStyles(theme).loadMoreButton
}));

function StyledCardGroupContainer({
    children,
    disablePagBtn,
    grouped,
    groupLabel,
    groupType,
    isOpenPanel,
    paginationLabel,
    showGroupButton,
    onClickGroupButton,
    showLoadMoreBtn,
    onPageChange
}) {
    return (
        <CardSection isopenpanel={isOpenPanel}>
            {showGroupButton &&
                /* show group button should show under the header when screen size is small */
                <Hidden mdUp>
                    <GroupCardBtnGrid item xs={12}>
                        <GroupCardsButton
                            className={
                                grouped ? "active" : null
                            }
                            grouped={grouped?.toString()}
                            testId={`groupCardsButton_${groupType}`}
                            type="button"
                            variant="text"
                            onClick={onClickGroupButton}
                        >
                            {groupLabel}
                        </GroupCardsButton>
                    </GroupCardBtnGrid>
                </Hidden>
            }
            { children }
            {showLoadMoreBtn &&
                <LoadMoreContainerBox>
                    <LoadMoreButton
                        id="loadMoreBtn"
                        isDisabled={disablePagBtn}
                        testId={`loadMoreButton_${groupType}`}
                        onClick={onPageChange}
                    >
                        {paginationLabel}
                    </LoadMoreButton>
                </LoadMoreContainerBox>
            }
        </CardSection>
    );
}

StyledCardGroupContainer.defaultProps = {
    showLoadMoreBtn: false,
    grouped: false,
    groupLabel: null,
    isOpenPanel: false,
    paginationLabel: null,
    disablePagBtn: false,
    onClickGroupButton: () => null,
    onPageChange: () => null
};

/* eslint-disable react/forbid-prop-types */
StyledCardGroupContainer.propTypes = {
    children: node.isRequired,
    grouped: bool,
    showLoadMoreBtn: bool,
    disablePagBtn: bool,
    groupLabel: string,
    isOpenPanel: bool,
    paginationLabel: string,
    groupType: string.isRequired,
    onClickGroupButton: func,
    onPageChange: func
};
/* eslint-enable react/forbid-prop-types */

export default StyledCardGroupContainer;
