import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { bool, func } from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useWidth from 'util/customHooks/useWidth';
import {
    breakpointsWithoutMobile,
    studentSearchColumns,
    studentSearchColumnsWithOutRequest
} from 'util/EnumHelper';
import Auth from 'auth/Auth';
import DttTableStudentContainer from 'redux/containers/districtTripTracker/DttTableStudentContainer';
import { localize } from 'util/Localizer';
import StyledButton from 'components/Common/StyledButton/StyledButton';

const UnmaskButton = styled(StyledButton)(({theme}) => ({
    fontFamily: 'Museo700',
    fontSize: '16px',
    color: theme.palette.everDrivenCharcoal,
    paddingLeft: '10px',
    width: '100px'
}));

const StyledUnMaskTypography = styled(Typography)(({theme}) => ({
    fontFamily: 'Museo700',
    fontSize: '16px',
    color: theme.palette.everDrivenCharcoal
}));

const StyledTextButton = styled(StyledButton)(({theme}) => ({
    color: theme.palette.everDrivenLightBlue,
    fontFamily: 'MuseoSans500',
    fontWeight: 500,
    fontSize: 14,
    textDecorationLine: 'underline',
    padding: 0,
    whiteSpace: 'nowrap'
}));

function DttStudents({
    initialSetup,
    goToTRF,
    router,
    goToStudentDetail,
    showStudents,
    showStudentData
}) {
    const width = useWidth();
    const hasManagementAccess = Auth.hasProgramManagementAccess();
    const [ buttons, setButtons ] = useState([]);
    const { navigate } = router;

    const selectStudentToTRF = (student) => {
        goToTRF(navigate, student);
    };

    const goToTripListButton = (student) => (
        <StyledTextButton
            color="inherit"
            type="button"
            endIcon={<KeyboardArrowRightOutlinedIcon fontSize="inherit" />}
            variant="text"
            testId="goToTripList"
            onClick={() => goToStudentDetail(student)}
        >
            {localize('dttStudent.goTripList')}
        </StyledTextButton>
    );

    const goToTrfButton = (student) => (
        <StyledTextButton
            color="inherit"
            type="button"
            startIcon={<AddIcon fontSize="inherit" />}
            variant="text"
            testId="goToTrf"
            onClick={() => selectStudentToTRF(student)}
        >
            {localize('dttStudent.addRequest')}
        </StyledTextButton>
    );

    const showHideButton = () => (
        <UnmaskButton
            type="button"
            variant="text"
            testId="hideButton"
            endIcon={showStudentData
                ? <VisibilityOffIcon fontSize="inherit" color="inherit"/>
                : <VisibilityIcon fontSize="inherit" color="inherit"/>
            }
            onClick={() => showStudents(!showStudentData)}
        >
            <StyledUnMaskTypography>
                {showStudentData
                    ? localize('dttStudent.hide')
                    : localize('dttStudent.show')
                }
            </StyledUnMaskTypography>
        </UnmaskButton>
    );

    const showHeaderCells = () => {
        if(hasManagementAccess) {
            return studentSearchColumns;
        }

        return studentSearchColumnsWithOutRequest;
    };

    const setShowButtons = () => {
        if(breakpointsWithoutMobile.includes(width) && hasManagementAccess) {
            setButtons([ goToTrfButton, goToTripListButton ]);
        } else {
            setButtons([goToTripListButton]);
        }
    };

    useEffect(() => {
        initialSetup();
        setShowButtons();
    }, []);

    useEffect(() => {
        setShowButtons();
    }, [ width, hasManagementAccess ]);

    return (
        <DttTableStudentContainer
            rightControls={buttons}
            titleButton={showHideButton}
            headerCells={showHeaderCells()}
        />
    );
}

DttStudents.defaultProps = {
    initialSetup: () => null,
    goToTRF: () => null,
    goToStudentDetail: () => null,
    showStudents: () => null,
    showStudentData: false
};

DttStudents.propTypes = {
    initialSetup: func,
    goToTRF: func,
    goToStudentDetail: func,
    showStudents: func,
    showStudentData: bool
};

export default DttStudents;
