import React from "react";
import { array, func, object } from "prop-types";

import { Grid, Box } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import StyledTypography from "components/Common/StyledTypography/StyledTypography";
import StyledButton from "components/Common/StyledButton/StyledButton";
import CommonStyles from "styles/CommonStyles";
import { localize } from "util/Localizer";
import { calcAge, monthDayYearFormat } from "util/dateUtils";
import StyledWrapper from "components/Common/StyledWrapper/StyledWrapper";
import TRNavContainer from "redux/containers/transportationRequest/TRNavContainer";
import StyledSectionItem from "components/Common/StyledSectionItem/StyledSectionItem";
import TripBuilderReviewContainer
    from "redux/containers/transportationRequest/tripBuilder/TripBuilderReviewContainer";

function Review({
    populationTypeReview,
    transportationNeedReview,
    tripModel,
    trips,
    setTabValue
}) {
    const renderPopulationTypes = () => (
        <ul data-testid="populationTypesList" style={{ ...CommonStyles.list, listStyleType: 'none', paddingLeft: 0 }}>
            { populationTypeReview.map(x => (
                <li key={x} style={CommonStyles.listItem}>
                    {x}
                </li>)
            )}
        </ul>
    );

    const renderTransportationNeeds = () => (
        <ul data-testid="transportationNeedsList" style={CommonStyles.list}>
            {transportationNeedReview.map(x => (
                <li key={x} style={CommonStyles.listItem}>
                    {x}
                </li>)
            )}
        </ul>
    );

    const renderItemReview = (testid, title, tabValue, children) => (
        <StyledWrapper key={title}>
            <Box sx={{ margin: '0 131px', padding: '28px 0 40px' }}>
                <Grid container>
                    <StyledTypography
                        extraStyle={CommonStyles.subTitle}
                        testid={testid}
                    >
                        {title}
                    </StyledTypography>
                    <StyledButton
                        color="inherit"
                        style={{
                            ...CommonStyles.linkButton,
                            marginLeft: 'auto',
                            paddingRight: 25
                        }}
                        type="button"
                        startIcon={<ModeEditIcon style={{ fontSize: 16 }} />}
                        variant="text"
                        testId={`edit${title}Button`}
                        onClick={() => setTabValue(tabValue)}
                    >
                        {localize('transportationRequest.edit')}
                    </StyledButton>
                    {children}
                </Grid>
            </Box>
        </StyledWrapper>
    );

    const renderContactReviewSection = (contactFormName) => {
        const nameKey = `${contactFormName}Name`;
        const phoneKey = `${contactFormName}PhoneNumber`;
        const emailKey = `${contactFormName}EmailAddress`;
        const relationshipKey = `${contactFormName}Relationship`;
        const appAccessKey = `${contactFormName}AppAccess`;

        return(
            <Grid item xs={4} data-testid={contactFormName}>
                <StyledTypography variant="body1" extraStyle={CommonStyles.bodyText}>
                    <span data-testid={nameKey}>{tripModel[nameKey]}</span>
                    { tripModel[relationshipKey] &&
                        <>
                            , <span data-testid={relationshipKey}>{tripModel[relationshipKey]}</span>
                        </>
                    }
                </StyledTypography>
                <StyledTypography testid={phoneKey} variant="body1" extraStyle={CommonStyles.bodyText}>
                    {tripModel[phoneKey]}
                </StyledTypography>
                <StyledTypography testid={emailKey} variant="body1" extraStyle={CommonStyles.bodyText}>
                    {tripModel[emailKey]}
                </StyledTypography>
                {tripModel[emailKey] && tripModel[appAccessKey] &&
                    <StyledTypography testid={appAccessKey} variant="body1" extraStyle={CommonStyles.bodyText}>
                        {localize('transportationRequest.addToVIP')}
                    </StyledTypography>
                }
            </Grid>
        );
    };

    const renderTripInformation = () => (
        trips?.map((trip, index) => (
            renderItemReview(
                `Trip${index+1}Information`,
                localize('transportationRequest.tripInformation', { number: index + 1}),
                2,
                <Grid>
                    <TripBuilderReviewContainer tripNumber={index} />
                </Grid>

            )
        ))
    );

    const renderStudentInformationReview = () => (
        <Grid container>
            <StyledSectionItem
                label={localize('transportationRequest.studentId')}
                value={tripModel.studentId}
                testid="StudentId"
            />
            <StyledSectionItem
                testid="StudentDOB"
                label={localize('transportationRequest.dateofBirth')}
                value={tripModel.studentDateOfBirth
                    ? localize(
                        'transportationRequest.reviewDateOfBirth',
                        {
                            date: monthDayYearFormat(tripModel.studentDateOfBirth),
                            age: calcAge(tripModel.studentDateOfBirth)
                        })
                    : ''
                }
            />
            {tripModel.studentGrade &&
                <StyledSectionItem
                    testid="StudentGrade"
                    label={localize('transportationRequest.grade')}
                    value={tripModel.studentGrade}
                />
            }
            <Grid item xs={12} sx={{ marginTop: '20px' }}>
                <StyledSectionItem
                    testid="populationTypes"
                    size={12}
                    label={localize('transportationRequest.populationType')}
                    value={populationTypeReview.length > 0
                        ? renderPopulationTypes()
                        : localize('transportationRequest.noPopulationTypeRequired')
                    }
                />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '20px' }}>
                <StyledSectionItem
                    testid="transportationNeeds"
                    size={12}
                    label={localize('transportationRequest.transportationNeeds')}
                    value={transportationNeedReview.length > 0
                        ? renderTransportationNeeds()
                        : localize('transportationRequest.noTransportationNeedsRequired')
                    }
                />
            </Grid>
            {tripModel.studentNotes &&
                <StyledSectionItem
                    contentStyle={{ marginTop: '20px' }}
                    testid="studentNotes"
                    size={12}
                    label={localize('transportationRequest.studentNotes')}
                    value={tripModel.studentNotes}
                />
            }
        </Grid>
    );

    const renderContactReview = () => (
        <Grid container columnSpacing={1}>
            {renderContactReviewSection('contact1')}
            {renderContactReviewSection('contact2')}
            {renderContactReviewSection('contact3')}
        </Grid>
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
            {renderItemReview(
                "StudentInformation",
                localize('transportationRequest.studentInformation'),
                0,
                renderStudentInformationReview()
            )}

            {renderItemReview(
                "Contacts",
                localize('transportationRequest.tabContacts'),
                1,
                renderContactReview()
            )}
            {renderTripInformation()}
            <StyledWrapper>
                <TRNavContainer isOuterContainer />
            </StyledWrapper>
        </div>
    );
}

Review.defaultProps = {
    populationTypeReview: [],
    transportationNeedReview: [],
    tripModel: null,
    trips: [],
    setTabValue: () => {}
};

/* eslint-disable react/forbid-prop-types */
Review.propTypes = {
    populationTypeReview: array,
    transportationNeedReview: array,
    tripModel: object,
    trips: array,
    setTabValue: func
};

export default Review;