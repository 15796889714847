import React from 'react';
import { TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { bool, node, oneOf, string } from 'prop-types';

const useStyles = makeStyles(() => ({
    cellStyle: {
        fontSize: 12,
        padding: '8px 16px'
    },
    headerStyle: {
        fontWeight: 700
    }
}));

function StyledTableCell ({
    align,
    children,
    colSpan,
    component,
    extraCssClass,
    isHeader,
    scope,
    testId
}) {
    const classes = useStyles();

    const setCellStyle = () => {
        let cellClass = classes.cellStyle;

        if (isHeader) {
            cellClass = `${cellClass} ${classes.headerStyle}`;
        }

        if (extraCssClass) {
            cellClass = `${cellClass} ${extraCssClass}`;
        }

        return cellClass;
    };

    return (
        <TableCell
            align={align}
            className={setCellStyle()}
            colSpan={colSpan}
            component={component}
            scope={scope}
            data-testid={testId}
        >
            {children}
        </TableCell>
    );
}

StyledTableCell.defaultProps = {
    align: 'inherit',
    children: null,
    colSpan: '1',
    component: null,
    extraCssClass: null,
    isHeader: false,
    scope: null,
    testId: ''
};

StyledTableCell.propTypes = {
    align: oneOf([ 'center', 'inherit', 'justify', 'left', 'right' ]),
    children: node,
    colSpan: string,
    component: node,
    extraCssClass: string,
    isHeader: bool,
    scope: string,
    testId: string
};

export default StyledTableCell;