import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function StyledTabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: '100%' }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
        >
            {value === index && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

StyledTabPanel.defaultProps = {
    children: null
};

StyledTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

export default StyledTabPanel;