import React, { useRef } from 'react';
import { Grid, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { requestTypeDto } from 'data/models/tripRequestForm/TripRequestFormModel';
import { localize } from '../../util/Localizer';
import commonUseStyles from '../../styles/CommonStyles';
import breakpointsNames from '../../styles/ResponsiveConstants';
import AddressForm from './AddressForm';
import StyledTypography from '../../components/Common/StyledTypography/StyledTypography';
import { addressType } from '../../util/EnumHelper';

const useStyles = makeStyles(theme => ({
    groupCenter: {
        ...commonUseStyles.defaultGroupCenter,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            paddingRight: 0
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingRight: 0,
            paddingLeft: 0
        }
    },
    groupRight: {
        ...commonUseStyles.defaultGroupRight,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            paddingLeft: 0,
            paddingRight: 15
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    paragraphMargin: {
        ...commonUseStyles.paragraphDefaultMargin
    },
    headerSection: {
        ...commonUseStyles.headerForm,
        marginTop: -20,
        marginBottom: 20
    },
    groupTitle: {
        marginBottom: -10
    }
}));

function AddressSection({
    tripModel,
    onUpdateLocation,
    setAddressInformation,
    onChangeAddress,
    showDifferentAddress,
    setIsSameAddress,
    onUpdatePickUpAddress,
    onUpdatePickUpAddress2,
    onUpdateDropOffAddress,
    onUpdateDropOffAddress2,
    selectedPickUpAddress,
    selectedPickUpAddress2,
    selectedDropOffAddress,
    selectedDropOffAddress2,
    previousAddressList,
    selectedPreviousPickUpAddress,
    selectedPreviousDropOffAddress,
    onSelectPreviousPickUpAddress,
    onSelectPreviousDropOffAddress
}) {
    const refAddress1 = useRef();
    const refAddress2 = useRef();
    const classes = useStyles();
    const differentAddress = localize('tripRequestForm.differentAddress');
    const homeAddress = localize('tripRequestForm.homeAddress');

    const onChangeLocations = (event) => {
        if (event === null) {
            return false;
        }

        setIsSameAddress(false);
        onUpdateLocation(event);

        return true;
    };

    const onSelectPickupLocationAddressLine1 = (item) => {
        onUpdatePickUpAddress(item);
    };

    const onChangePickupLocationAddressLine1 = (fieldValue) => {
        setIsSameAddress(false);
        setAddressInformation({
            pickupLocationAddressLine1: fieldValue
        });
    };

    const onSelectPickupLocationAddressLine2 = (item) => {
        onUpdatePickUpAddress2(item);
    };

    const onChangePickupLocationAddressLine2 = (fieldValue) => {
        setIsSameAddress(false);
        setAddressInformation({
            pickupLocationAddressLine2: fieldValue
        });
    };

    const onSelectDropOffLocationAddressLine1 = (item) => {
        onUpdateDropOffAddress(item);
    };

    const onChangeDropOffLocationAddressLine1 = (fieldValue) => {
        setIsSameAddress(false);
        setAddressInformation({
            dropOffLocationAddressLine1: fieldValue
        });
    };

    const onSelectDropOffLocationAddressLine2 = (item) => {
        onUpdateDropOffAddress2(item);
    };

    const onChangeDropOffLocationAddressLine2 = (fieldValue) => {
        setIsSameAddress(false);
        setAddressInformation({
            dropOffLocationAddressLine2: fieldValue
        });
    };

    const isPickupDifferentAddressRequired =
        tripModel.requestType !== requestTypeDto.update && tripModel.amPickupLocation === "Different Address";
    const isDropOffDifferentAddressRequired =
        tripModel.requestType !== requestTypeDto.update && tripModel.pmDropOffLocation === "Different Address";

    const renderPickupLocation = () => <AddressForm
        isRequired={isPickupDifferentAddressRequired}
        addressLine1Id="pickupLocationAddressLine1"
        addressLine1Value={tripModel.pickupLocationAddressLine1}
        addressLine2Id="pickupLocationAddressLine2"
        addressLine2Value={tripModel.pickupLocationAddressLine2}
        cityId="pickupLocationAddressCity"
        cityValue={tripModel.pickupLocationAddressCity}
        defaultaddressLine1Value={selectedPickUpAddress}
        defaultaddressLine2Value={selectedPickUpAddress2}
        refAddress1={refAddress1}
        refAddress2={refAddress2}
        stateId="pickupLocationAddressState"
        stateValue={tripModel.pickupLocationAddressState}
        zipCodeId="pickupLocationAddressZipCode"
        zipCodeValue={tripModel.pickupLocationAddressZipCode}
        onChangeInputAddressLine1={onChangePickupLocationAddressLine1}
        onChangeInputAddressLine2={onChangePickupLocationAddressLine2}
        onInputChange={onChangeAddress}
        onSelectAddressInfoLine1={onSelectPickupLocationAddressLine1}
        onSelectAddressInfoLine2={onSelectPickupLocationAddressLine2}
        previousAddressList={previousAddressList}
        selectedPreviousAddress={selectedPreviousPickUpAddress}
        onSelectPreviousAddress={(evt) => onSelectPreviousPickUpAddress(evt)}
    />;

    const renderDropOffLocation = () => <AddressForm
        isRequired={isDropOffDifferentAddressRequired}
        addressLine1Id="dropOffLocationAddressLine1"
        addressLine1Value={tripModel.dropOffLocationAddressLine1}
        addressLine2Id="dropOffLocationAddressLine2"
        addressLine2Value={tripModel.dropOffLocationAddressLine2}
        cityId="dropOffLocationAddressCity"
        cityValue={tripModel.dropOffLocationAddressCity}
        defaultaddressLine1Value={selectedDropOffAddress}
        defaultaddressLine2Value={selectedDropOffAddress2}
        stateId="dropOffLocationAddressState"
        stateValue={tripModel.dropOffLocationAddressState}
        zipCodeId="dropOffLocationAddressZipCode"
        zipCodeValue={tripModel.dropOffLocationAddressZipCode}
        onChangeInputAddressLine1={onChangeDropOffLocationAddressLine1}
        onChangeInputAddressLine2={onChangeDropOffLocationAddressLine2}
        onInputChange={onChangeAddress}
        onSelectAddressInfoLine1={onSelectDropOffLocationAddressLine1}
        onSelectAddressInfoLine2={onSelectDropOffLocationAddressLine2}
        previousAddressList={previousAddressList}
        selectedPreviousAddress={selectedPreviousDropOffAddress}
        onSelectPreviousAddress={(evt) => onSelectPreviousDropOffAddress(evt)}
    />;

    const classesHeader = `memberCard ${classes.headerSection}`;

    return (
        <>
            <Grid item sm={12} md={6} lg={4} className={classes.groupCenter}>
                <div className={classesHeader}>
                    <StyledTypography
                        variant="h5"
                        className={classes.groupTitle}
                    >
                        {localize('tripRequestForm.amPickupLocation')}
                    </StyledTypography>
                </div>
                <p className={classes.paragraphMargin}>{localize('tripRequestForm.homeAddressTheAddress')}</p>
                <FormControl variant="standard">
                    <RadioGroup
                        required
                        aria-label="amPickupLocation"
                        name="amPickupLocation"
                        value={tripModel.amPickupLocation ?? addressType.homeAddress}
                        onChange={onChangeLocations}
                    >
                        <FormControlLabel
                            data-testid="amPickupLocationHomeAddress"
                            value={addressType.homeAddress}
                            control={<Radio color="secondary" />}
                            label={homeAddress}
                        />
                        <FormControlLabel
                            data-testid="amPickupLocationDifferentAddress"
                            value={addressType.differentAddress}
                            control={<Radio color="secondary" />}
                            label={differentAddress}
                        />
                    </RadioGroup>
                </FormControl>
                {showDifferentAddress.pk ? renderPickupLocation() : null}
            </Grid>
            <Grid item sm={12} md={6} lg={4} className={classes.groupRight}>
                <div className={classesHeader}>
                    <StyledTypography
                        variant="h5"
                        className={classes.groupTitle}
                    >
                        {localize('tripRequestForm.pmDropOffLocation')}
                    </StyledTypography>
                </div>
                <p className={classes.paragraphMargin}>{localize('tripRequestForm.homeAddressTheAddress')}</p>
                <FormControl variant="standard">
                    <RadioGroup
                        required
                        aria-label="pmDropOffLocation"
                        name="pmDropOffLocation"
                        value={tripModel.pmDropOffLocation ?? addressType.sameAddressAsPickUp}
                        onChange={onChangeLocations}
                    >
                        <FormControlLabel
                            data-testid="pmDropoffLocationHomeAddress"
                            value={addressType.homeAddress}
                            control={<Radio color="secondary" />}
                            label={homeAddress}
                        />
                        <FormControlLabel
                            data-testid="pmDropoffLocationSameAsPickUp"
                            value={addressType.sameAddressAsPickUp}
                            control={<Radio color="secondary" />}
                            label={localize('tripRequestForm.sameAddressAsAMPickupLocation')}
                        />
                        <FormControlLabel
                            data-testid="pmDropoffLocationDifferentAddress"
                            value={addressType.differentAddress}
                            control={<Radio color="secondary" />}
                            label={differentAddress}
                        />
                    </RadioGroup>
                </FormControl>
                {showDifferentAddress.do ? renderDropOffLocation() : null}
            </Grid>
        </>
    );
}

export default AddressSection;
