import React, { useRef, forwardRef, useImperativeHandle } from "react";
import AutoComplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import StyledTextField from "../../components/Common/StyledTextField/StyledTextField";
import StyledTypography from "../../components/Common/StyledTypography/StyledTypography";
import { localize } from "../../util/Localizer";
import { schoolDto } from "../../data/models/tripRequestForm/TripRequestFormModel";
import { objectEmptyValidator } from "../../util/validationUtil";
import { commonStyles } from "../../styles/CommonStyles";

const useStyles = makeStyles(theme => ({
    subtitleH4: {
        ...commonStyles(theme).customSubtitle
    },
    popperUp: {
        zIndex: 3
    },
    menuItemPosition: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const SchoolAutoComplete = forwardRef((props, ref) => {
    const { onSelectSchool, idName, value, defaultSchool, onChangeInput, isRequired, optionList } = props;

    const refAutocomplete = useRef();
    const refInputSearch = useRef();
    const loading = false;
    const classes = useStyles();

    let schooolInfo = schoolDto;

    const onClearInputFilter = () => {
        refAutocomplete.current.querySelector("button[title='Clear']").click();
    };

    useImperativeHandle(ref,
        () => ({
            onClearFilter() {
                onClearInputFilter();
            }
        }));

    const handleInput = (evt) => {
        onChangeInput(evt);
    };

    const onPreSelectedSchool = (item, fieldValue) => {
        if (fieldValue && typeof fieldValue === 'object') {
            schooolInfo = {
                selectedSchool: fieldValue,
                id: fieldValue.id,
                name: fieldValue.name,
                address1: fieldValue.address1,
                address2: fieldValue.address2,
                city: fieldValue.city,
                zip: fieldValue.zip,
                state: fieldValue.state,
                phoneNumber: fieldValue.phoneNumber,
                latitude: fieldValue.lat,
                longitude: fieldValue.lng
            };
            onSelectSchool(schooolInfo);
        } else {
            schooolInfo = {
                name: '',
                address1: '',
                address2: '',
                city: '',
                zip: '',
                state: '',
                phoneNumber: '(   )    -    ',
                latitude: '',
                longitude: ''
            };
            onSelectSchool(schooolInfo);
        }
    };

    const onLoadableTextField = params => (
        <StyledTextField
            id={idName}
            onChange={(e) => handleInput(e)}
            inputProps={{
                ...params.inputProps,
                "data-testid": idName,
                autoComplete: "disabled",
                onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                        e.stopPropagation();
                    }
                }
            }}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </>
                )
            }}
            inputRef={refInputSearch}
            isRequired={isRequired}
            name={idName}
            value={value}
        />
    );

    const onRenderOptionResult = (properties, suggestion) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div key={suggestion.id} {...properties}>
            <StyledTypography variant="h4" className={classes.menuItemPosition}>
                <span>{suggestion.name}</span>
                <span className={classes.subtitleH4}>{suggestion.address1} - {suggestion.phoneNumber}</span>
            </StyledTypography>
        </div>
    );

    return (
        <AutoComplete
            data-testid="schoolSearch"
            classes={{
                popper: classes.popperUp
            }}
            freeSolo
            includeInputInList
            inputValue={value}
            loading={loading}
            loadingText={localize('search.searchingForStudents')}
            options={optionList}
            popupIcon={null}
            ref={refAutocomplete}
            value={defaultSchool}
            getOptionLabel={item => !objectEmptyValidator(item) ? item.name : ''}
            onChange={onPreSelectedSchool}
            renderInput={params => (onLoadableTextField(params))}
            renderOption={(properties, suggestion) => onRenderOptionResult(properties,
                suggestion)}
        />
    );
});

export default SchoolAutoComplete;
