import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';

const DivInactive = styled('div')(({ theme }) => ({
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderColor: theme.palette.everDrivenGrey,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center'
}));

const DivComplete = styled('div')(({ theme }) => ({
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.everDrivenLightBlue
}));

const ActiveCircleIcon = styled(CircleIcon)(({ theme }) => ({
    fontSize: 20,
    color: theme.palette.everDrivenLightBlue
}));

const CompleteCheckIcon = styled(CheckIcon)(({ theme }) => ({
    fontSize: 20,
    color: theme.palette.white
}));

function CustomStepIcon({ active, completed }) {
    if (active) {
        return (
            <DivInactive>
                <ActiveCircleIcon />
            </DivInactive>
        );
    }

    if (completed) {
        return (
            <DivComplete>
                <CompleteCheckIcon />
            </DivComplete>
        );
    }

    return (
        <DivInactive />
    );
}

export default CustomStepIcon;