import React from "react";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import { localize } from 'util/Localizer';
import commonUseStyles from 'styles/CommonStyles';
import breakpointsNames from "styles/ResponsiveConstants";
import StyledHeaderContainer from "../StyledHeaderContainer/StyledHeaderContainer";

const Container = styled(Grid)(({theme}) => ({
    textAlign: 'center',
    display: 'none',
    [theme.breakpoints.down(breakpointsNames.sm)] : {
        display: 'block'
    },
    [theme.breakpoints.only(breakpointsNames.sm)]: {
        display: 'none',
        "@media only screen and (orientation: landscape)": {
            display: 'block'
        }
    }
}));

const ContentMobile = styled('div')(({theme}) => ({
    ...commonUseStyles.content,
    [theme.breakpoints.down(breakpointsNames.sm)] : {
        padding: '20px 30px'
    }
}));

const Message = styled('div')(({theme}) => ({
    margin: 0,
    padding: 0,
    fontSize: '20px',
    fontWeight: 400,
    [theme.breakpoints.down(breakpointsNames.sm)] : {
        fontSize: '14px'
    }
}));

function StyledMobileUnavailable({isNewTRF}) {
    const renderHeader = () => (
        isNewTRF ? (
            <StyledHeaderContainer
                title={localize('transportationRequest.title')}
                extraStyle={{minWidth: 320}}
                titleStyle={{fontSize: '18px'}} />
        ) : (
            <Grid item xs={12} >
                <h3 style={{...commonUseStyles.filterTitle, paddingTop: '15px'}}>
                    {localize('tripRequestForm.title')}
                </h3>
            </Grid>
        )
    );

    return (
        <Container>
            {renderHeader()}
            <Grid item xs={12}>
                <ContentMobile>
                    <Message>{localize('transportationRequest.mobileMessage')}</Message>
                </ContentMobile>
            </Grid>
        </Container>
    );
}

export default StyledMobileUnavailable;