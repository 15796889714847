import { connect } from 'react-redux';
import withTheme from '@mui/styles/withTheme';
import UserHeader from 'components/user/UserHeader/UserHeader';

import { setFilterOptions } from 'redux/actions/userActions';
import {
    filterUsers,
    handleChangeCheckAll,
    onShowDeleteModal,
    openUserForm,
    sortUsersByColumn,
    setUserOptionFilter,
    setUserTermFilter
} from 'redux/workers/userWorker';

const mapStateToProps = state => {
    const { UserReducers } = state;

    if (UserReducers) {
        return {
            userList: UserReducers.filteredUsers,
            userAll: UserReducers.orderedUsers,
            term: UserReducers.term,
            isModalMobile: UserReducers.isModalMobile,
            columns: UserReducers.columns,
            checkedAll: UserReducers.checkedAll,
            filterOption: UserReducers.filterOption,
            filterOptions: UserReducers.filterOptions,
            isLoadingUser: UserReducers.isLoadingUser
        };
    }

    return {};
};

const UserHeaderContainer = withTheme(
    connect(mapStateToProps,
        {
            filterUsers,
            handleChangeCheckAll,
            onShowDeleteModal,
            openUserForm,
            setFilterOptions,
            setUserOptionFilter,
            setUserTermFilter,
            sortUsersByColumn
        })(UserHeader)
);

export default UserHeaderContainer;
