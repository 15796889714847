import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import TripGroupContainer from 'redux/containers/districtTripTracker/TripGroupContainer';
import StudentHeaderContainer from 'redux/containers/student/StudentHeaderContainer';
import { localize } from 'util/Localizer';
import { optionsTripGroupDTT } from 'util/EnumHelper';
import StyledLoading from './Common/StyledLoading/StyledLoading';
import DPApp from './DPApp';

function Student({
    isLoading,
    memberId,
    initialSetup
}) {
    const renderLoading = () => isLoading && <StyledLoading message={localize('tripList.loadingTrips')} />;

    const renderResults = () => (
        !isLoading && (
            <>
                <StudentHeaderContainer studentId={memberId} />
                <div className="wrapper">
                    <Box className="tripListBox" sx={{padding: '.5em 2em'}}>
                        {
                            optionsTripGroupDTT
                                .map(tripType => (
                                    <TripGroupContainer
                                        key={tripType}
                                        tripType={tripType}
                                    />
                                ))
                        }
                    </Box>
                </div>
            </>
        )
    );

    useEffect(() => {
        initialSetup(memberId);
    }, [memberId]);

    return (
        <DPApp>
            {renderLoading()}
            {renderResults()}
        </DPApp>
    );
}

Student.defaultProps = {
    isLoading: false,
    initialSetup: null
};

/* eslint-disable react/forbid-prop-types */
Student.propTypes = {
    isLoading: PropTypes.bool,
    memberId: PropTypes.string.isRequired,
    initialSetup: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default Student;
