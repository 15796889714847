export const FACILITIES_LOAD = 'FACILITIES_LOAD';
export const internalLoad = facilities => ({
    type: FACILITIES_LOAD,
    facilities
});

export const FACILITIES_SORTED = 'FACILITIES_SORTED';
export const facilitiesSorted = facilitiesSort => ({
    type: FACILITIES_SORTED,
    facilitiesSort
});

export const FACILITY_SELECTED = 'FACILITY_SELECTED';
export const facilitySelected = facility => ({
    type: FACILITY_SELECTED,
    facility
});

export const FACILITY_SELECTED_ID = 'FACILITY_SELECTED_ID';
export const facilitySelectedId = facilityId => ({
    type: FACILITY_SELECTED_ID,
    facilityId
});

export const FACILITY_IS_LOADING = 'FACILITY_IS_LOADING';
export const facilityIsLoading = loading => ({
    type: FACILITY_IS_LOADING,
    loading
});

export const FACILITY_SELECTED_TOGGLEMAP = 'FACILITY_SELECTED_TOGGLEMAP';
export const facilitySelectedToggleMap = toggleMap => ({
    type: FACILITY_SELECTED_TOGGLEMAP,
    toggleMap
});
