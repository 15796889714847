import { paths } from "util/EnumHelper";
import {
    populationType,
    transportationNeed
} from 'data/models/transportationRequest/TransportationRequestModel';
import {
    SET_TR_CLEAN,
    SET_TR_POPULATION_TYPE,
    SET_TR_TRANSPORTATION_NEED,
    SET_TR_TRANSPORTATION_NEED_FORMAT,
    SET_TR_NUMBER_OF_CONTACTS,
    SET_TR_CURRENT_CONTACT,
    UPDATE_TR_MODEL,
    SET_TR_HAS_GRADE,
    SET_TR_HAS_MORE_PT,
    SET_TR_HAS_TN,
    SET_TR_SHOW_STUDENT_NOTES,
    SET_TR_DISABLED_CAR_SEAT,
    SET_TR_TAB_VALUE,
    SET_TR_POPULATION_TYPE_FORMAT,
    SET_TR_POPULATION_TYPE_REVIEW,
    SET_TR_TRANSPORTATION_NEED_REVIEW,
    SET_TR_DRAFT_CAN_BE_SAVED,
    SET_TR_OLD_DRAFT_MODEL,
    SET_TR_OLD_DRAFT_POPULATION_TYPE,
    SET_TR_OLD_DRAFT_TRANSPORTATION_NEED,
    SET_TR_NEXT_PATH,
    SET_TR_TRIPS,
    SET_TR_TITLE,
    SET_TR_OLD_DRAFT_TRIPS,
    SET_TR_TRIP_NUMBER_ERROR,
    SET_TR_DRAFT_SELECTED,
    SET_CORRELATIONID,
    SET_TR_DISABLED_SAFETY_VEST,
    SET_TR_SUBMIT_BUTTON_STATE,
    SET_TR_CALLED_GETCORRELATIONID
} from 'redux/actions/transportationRequest/transportationRequestActions';

const transportationRequestInitialState = {
    currentContact: 1,
    disabledCarSeat: false,
    disabledSafetyVest: false,
    hasGrade: false,
    hasMorePT: false,
    hasTN: false,
    numberOfContacts: 1,
    populationTypes: populationType,
    populationTypeReview: [],
    showStudentNotes: false,
    tabValue: 0,
    studentBirthDateErrorMessage: '',
    transportationNeedReview: [],
    transportationNeeds: transportationNeed,
    tripModel: {},
    draftCanBeSaved: false,
    isDraftSelected: false,
    correlationId: '',
    oldDraftModel: {},
    oldDraftPopulationTypes: populationType,
    oldDraftTransportationNeeds: transportationNeed,
    oldDraftTrips: [],
    nextPath: paths.DraftList.path,
    trips: [],
    title: '',
    tripNumberError: 1,
    isSubmitDisabled: false,
    calledGetCorrelationId: false
};

/* eslint-disable-next-line default-param-last */
const transportationRequestReducers = (state = transportationRequestInitialState, action) => {
    switch (action.type) {
    case SET_TR_CLEAN:
        return {
            ...state,
            ...transportationRequestInitialState,
            tripModel: {
                ...transportationRequestInitialState.tripModel,
                studentDateOfBirth: null
            }
        };
    case UPDATE_TR_MODEL:
        return {
            ...state,
            tripModel: {
                ...state.tripModel,
                ...action.model
            }
        };
    case SET_TR_POPULATION_TYPE:
        return {
            ...state,
            populationTypes: {
                ...state.populationTypes,
                ...action.populationTypes
            }
        };
    case SET_TR_TRANSPORTATION_NEED:
        return {
            ...state,
            transportationNeeds: {
                ...state.transportationNeeds,
                ...action.transportationNeeds
            }
        };
    case SET_TR_TRANSPORTATION_NEED_FORMAT:
        return {
            ...state,
            tripModel: {
                ...state.tripModel,
                transportationNeeds: action.equipmentNeeds
            }
        };
    case SET_TR_NUMBER_OF_CONTACTS:
        return {
            ...state,
            numberOfContacts: action.numberOfContacts
        };
    case SET_TR_CURRENT_CONTACT:
        return {
            ...state,
            currentContact: action.currentContact
        };
    case SET_TR_HAS_GRADE:
        return {
            ...state,
            hasGrade: action.hasGrade
        };
    case SET_TR_HAS_MORE_PT:
        return {
            ...state,
            hasMorePT: action.hasMorePT
        };
    case SET_TR_HAS_TN:
        return {
            ...state,
            hasTN: action.hasTN
        };
    case SET_TR_SHOW_STUDENT_NOTES:
        return {
            ...state,
            showStudentNotes: action.showStudentNotes
        };
    case SET_TR_DISABLED_CAR_SEAT:
        return {
            ...state,
            disabledCarSeat: action.disabledCarSeat
        };
    case SET_TR_DISABLED_SAFETY_VEST:
        return {
            ...state,
            disabledSafetyVest: action.disabledSafetyVest
        };
    case SET_TR_DRAFT_CAN_BE_SAVED:
        return {
            ...state,
            draftCanBeSaved: action.draftCanBeSaved
        };
    case SET_TR_OLD_DRAFT_MODEL:
        return {
            ...state,
            oldDraftModel: {
                ...state.oldDraftModel,
                ...action.model
            }
        };
    case SET_TR_OLD_DRAFT_POPULATION_TYPE:
        return {
            ...state,
            oldDraftPopulationTypes: {
                ...state.oldDraftPopulationTypes,
                ...action.populationTypes
            }
        };
    case SET_TR_OLD_DRAFT_TRANSPORTATION_NEED:
        return {
            ...state,
            oldDraftTransportationNeeds: {
                ...state.oldDraftTransportationNeeds,
                ...action.transportationNeeds
            }
        };
    case SET_TR_NEXT_PATH:
        return {
            ...state,
            nextPath: action.nextPath
        };
    case SET_TR_TAB_VALUE:
        return {
            ...state,
            tabValue: action.tabValue
        };
    case SET_TR_POPULATION_TYPE_FORMAT:
        return {
            ...state,
            tripModel: {
                ...state.tripModel,
                populationType: action.populationType
            }
        };
    case SET_TR_POPULATION_TYPE_REVIEW:
        return {
            ...state,
            populationTypeReview: action.populationTypeReview
        };
    case SET_TR_TRANSPORTATION_NEED_REVIEW:
        return {
            ...state,
            transportationNeedReview: action.transportationNeedReview
        };
    case SET_TR_TRIPS:
        return {
            ...state,
            trips: JSON.parse(JSON.stringify(action.trips))
        };
    case SET_TR_TITLE:
        return {
            ...state,
            title: action.title
        };
    case SET_TR_OLD_DRAFT_TRIPS:
        return {
            ...state,
            oldDraftTrips: JSON.parse(JSON.stringify(action.trips))
        };
    case SET_TR_TRIP_NUMBER_ERROR:
        return {
            ...state,
            tripNumberError: action.tripNumberError
        };
    case SET_TR_DRAFT_SELECTED:
        return {
            ...state,
            isDraftSelected: action.isDraftSelected
        };
    case SET_CORRELATIONID:
        return {
            ...state,
            correlationId: action.correlationId
        };
    case SET_TR_SUBMIT_BUTTON_STATE:
        return {
            ...state,
            isSubmitDisabled: action.isSubmitDisabled
        };
    case SET_TR_CALLED_GETCORRELATIONID:
        return {
            ...state,
            calledGetCorrelationId: action.calledGetCorrelationId
        };
    default:
        return state;
    }
};

export default transportationRequestReducers;