import { connect } from 'react-redux';
import {
    onSaveDraft,
    onExitTRF,
    closeSaveDraftModal,
    onCloseWithoutSave
} from 'redux/workers/transportationRequest/transportationRequestDraftWorker';
import { setNextPath } from 'redux/actions/transportationRequest/transportationRequestActions';
import { onChangeTab } from 'redux/workers/transportationRequest/transportationRequestValidationWorker';
import { updatePath } from 'redux/workers/pathWorker';
import TRNav from 'components/TransportationRequest/TRNav/TRNav';
import {
    submitForm,
    closeFailedSubmitModal,
    closeErrorSubmitModal,
    closeSuccessSubmitModal,
    editTrip,
    newTR
} from 'redux/workers/transportationRequest/transportationRequestWorker';

const mapStateToProps = state => {
    const {
        TransportationRequestReducers,
        TransportationRequestModalReducers
    } = state;

    return {
        tripNumberError: TransportationRequestReducers.tripNumberError,
        draftCanBeSaved: TransportationRequestReducers.draftCanBeSaved,
        isSubmitDisabled: TransportationRequestReducers.isSubmitDisabled,
        showSaveDraftModal: TransportationRequestModalReducers.showSaveDraftModal,
        nextPath: TransportationRequestReducers.nextPath,
        tabValue: TransportationRequestReducers.tabValue,
        showErrorSubmitModal: TransportationRequestModalReducers.showErrorSubmitModal,
        showFailedSubmitModal: TransportationRequestModalReducers.showFailedSubmitModal,
        showSuccessSubmitModal: TransportationRequestModalReducers.showSuccessSubmitModal
    };
};

const mapDispatchToProps = {
    onSaveDraft,
    onExitTRF,
    onCloseWithoutSave,
    closeSaveDraftModal,
    closeFailedSubmitModal,
    closeErrorSubmitModal,
    closeSuccessSubmitModal,
    setNextPath,
    onChangeTab,
    submitForm,
    editTrip,
    newTR,
    updatePath
};

const TRNavContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TRNav);

export default TRNavContainer;