import { connect } from 'react-redux';

import {
    closeStyledSnackBar
} from '../../workers/styledSnackbarWrapperWorker';
import StudentOverviewUpload from '../../../components/studentUpload/StudentOverviewUpload';

import {
    setStep
} from '../../actions/studentUploadActions';

import {
    saveStudentUploadDraft
} from '../../workers/studentUploadWorker';

const mapStateToProps = state => {
    const { StudentUploadReducers } = state;

    if (StudentUploadReducers) {
        return {
            dataFromFile: StudentUploadReducers.dataFromFile,
            isLoading: StudentUploadReducers.isLoading
        };
    }

    return {};
};

const StudentOverviewUploadContainer = connect(
    mapStateToProps, {
        closeStyledSnackBar,
        setStep,
        saveStudentUploadDraft
    }
)(StudentOverviewUpload);

export default StudentOverviewUploadContainer;