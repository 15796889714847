import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StyledFrameContainer = styled('div')(() => ({
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    paddingTop: '56.25%'
}));

const StyledIFrame = styled('iframe')(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: 'none',
    width: '100%',
    height: '100%'
}));

function VideoContainer({
    sortId,
    videoUrl,
    onLoad
}) {
    const iframeRef = useRef(null);
    const srcDoc = videoUrl? null: '<p>Video not available</p>';

    return(
        <StyledFrameContainer ref={iframeRef}>
            <StyledIFrame
                ref={iframeRef}
                data-testid={`videoFrame${sortId}`}
                title={`Video ${sortId}`}
                src={videoUrl}
                srcDoc={srcDoc}
                id={`videoFrame${sortId}`}
                loading="lazy"
                allowFullScreen
                onLoad={() => onLoad(false)}
            />
        </StyledFrameContainer>
    );
}

VideoContainer.defaultProps = {
    sortId: 0,
    videoUrl: null,
    onLoad: () => {}
};

/* eslint-disable react/forbid-prop-types */
VideoContainer.propTypes = {
    sortId: PropTypes.number,
    videoUrl: PropTypes.string,
    onLoad: PropTypes.func
};

/* eslint-enable react/forbid-prop-types */

export default VideoContainer;