import React, { useRef } from 'react';
import { Grid, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { localize } from '../../util/Localizer';

import SchoolDetail from './SchoolDetail';
import ScheduleGroup from './ScheduleGroup';
import StyledCheckbox from '../../components/Common/StyledCheckbox/StyledCheckbox';

const ContentContainer = styled(Grid)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
}));

function SchoolInformation({
    classesInputEnabled,
    currentSibling,
    enabledSchoolInputs,
    isSameSchool,
    schedule,
    scheduleValid,
    schoolDays,
    selectedSchool,
    selectedSchoolAddress,
    selectedSchoolAddress2,
    tripModel,
    onCopyTime,
    onSelectSchool,
    onSelectSchoolAddressInfo,
    onSelectSchoolAddressLine2Info,
    onUpdateModel: onUpdateSchoolInformation,
    onUpdateSchoolDays,
    onUpdateSchoolAddressLine1,
    onUpdateSchoolAddressLine2,
    onUpdateSchoolPhone,
    onUpdateSchedule,
    setIsSameSchool,
    setScheduleValid
}) {
    const refAddress = useRef();
    const refAddressLine2 = useRef();
    const refSchoolAutoComplete = useRef();

    const handleChangeSchoolInformation = (evt) => {
        if (evt === null) {
            return false;
        }

        setIsSameSchool(false);
        onUpdateSchoolInformation(evt);

        return true;
    };

    const handleChangePhoneSchoolInformation = (evt) => {
        if (evt === null) {
            return false;
        }

        onUpdateSchoolPhone(evt);

        return true;
    };

    const handleChangeSchoolDays = (evt) => {
        if (evt === null) {
            return false;
        }

        setIsSameSchool(false);
        onUpdateSchoolDays(evt);

        return true;
    };

    const handleChangeSchedule = (evt, name) => {
        if (evt.target.validationMessage && !evt.target.validity.stepMismatch) {
            setScheduleValid({ [name]: false });
        } else {
            setScheduleValid({ [name]: true });
        }

        setIsSameSchool(false);
        onUpdateSchedule(evt,
            name);
    };

    const copyTimeToDays = (arrivalName, pickUpName) => {
        setIsSameSchool(false);
        onCopyTime(arrivalName,
            pickUpName);
    };

    const handleCopySchool = (evt) => {
        if (evt === null) {
            return false;
        }

        setIsSameSchool(evt.target.checked);

        return true;
    };

    return (
        <ContentContainer container>
            {currentSibling > 1 &&
                <Grid item xs={12} sx={{marginTop: '-15px'}}>
                    <FormControlLabel
                        size="medium"
                        control={<StyledCheckbox
                            name="isSameSchool"
                            color="secondary"
                            isChecked={isSameSchool}
                            size="medium"
                            onChange={handleCopySchool}
                        />}
                        label={localize('tripRequestForm.repeatStudentInformation')}
                    />
                </Grid>
            }
            <SchoolDetail
                classesInputEnabled={classesInputEnabled}
                enabledSchoolInputs={enabledSchoolInputs}
                refSchool={refSchoolAutoComplete}
                refAddress={refAddress}
                refAddressLine2={refAddressLine2}
                requestType={tripModel.requestType}
                schoolInformation={tripModel}
                selectedSchool={selectedSchool}
                selectedSchoolAddress={selectedSchoolAddress}
                selectedSchoolAddress2={selectedSchoolAddress2}
                handleChangePhoneSchoolInformation={handleChangePhoneSchoolInformation}
                handleChangeSchoolInformation={handleChangeSchoolInformation}
                onSelectSchool={onSelectSchool}
                onSelectSchoolAddressInfo={onSelectSchoolAddressInfo}
                onSelectSchoolAddressLine2Info={onSelectSchoolAddressLine2Info}
                onUpdateSchoolAddressLine1={onUpdateSchoolAddressLine1}
                onUpdateSchoolAddressLine2={onUpdateSchoolAddressLine2}
                setIsSameSchool={setIsSameSchool}
            />
            <ScheduleGroup
                schedule={schedule}
                scheduleValid={scheduleValid}
                schoolDays={schoolDays}
                schoolInformation={tripModel}
                handleChangeSchedule={handleChangeSchedule}
                handleChangeSchoolDays={handleChangeSchoolDays}
                copyTimeToDays={copyTimeToDays}
            />
        </ContentContainer>
    );
}

export default SchoolInformation;
