// -------- Student
import {
    dttStudentSetSortStudents,
    dttStudentSortByColumn,
    dttStudentSearchTerm,
    dttStudentSetStudents,
    dttStudentIsLoading,
    dttStudentStateColumns,
    dttStudentSetShowStudentData
} from "redux/actions/districtTripTracker/dttStudentActions";
import Auth from "auth/Auth";
import { getStudents, searchStudents, searchStudentsByIds } from "services/studentService";
import { paths } from "util/EnumHelper";
import { changeSort, compareValues } from "util/sorterUtil";
import { studentIsLoading } from "redux/actions/districtTripTracker/studentActions";

import {
    paginationSetLimitRows,
    paginationSetActivePage,
    paginationReset
} from 'redux/actions/StyledTablePaginationActions';

import { updatePath } from "../pathWorker";

const PLUS_PAGE = 1;

const endpointColumns = {
    lastName: 'last',
    firstName: 'first',
    clientMemberCode: 'memberCode'
};

const sortStudents = (columnName, dttStudentColumns, dttStudents) => (
    dttStudents.sort(
        compareValues(columnName,
            dttStudentColumns[columnName].sort)
    )
);

export const goToStudentDetail = (student) => (dispatch) => {
    dispatch(studentIsLoading(true));
    if (student) {
        dispatch(updatePath(paths.StudentDetail.tabValue, student.id));
    }
};

export const goToTRF = (navigate, student) => async (dispatch) => {
    const body = JSON.stringify({ids: [student.id]});

    const { results } = await searchStudentsByIds(body);

    dispatch(dttStudentSetStudents(results));
    
    navigate(
        Auth.getConfig().useNewTransportationRequestForm
            ? paths.TransportationRequest.path
            : paths.TransportationRequestForm.path,
        { state: {
            studentCode: results[0].clientMemberCode,
            studentId: results[0].id
        }}
    );
};

const getStudentsEndpoint = (page) => async (dispatch, getState) => {
    const {
        dttStudentColumns,
        dttStudentSortColumn
    } = getState().DttStudentReducers;
    const {
        rowsShowed
    } = getState().StyledTablePaginationReducer;

    const responseGet = await getStudents(
        rowsShowed,
        page + PLUS_PAGE,
        dttStudentColumns[dttStudentSortColumn].sort,
        endpointColumns[dttStudentSortColumn]
    );

    dispatch(paginationSetLimitRows(responseGet.totalRows));
    dispatch(dttStudentSetStudents(responseGet.results));
    dispatch(dttStudentSetSortStudents(responseGet.results));
    dispatch(dttStudentIsLoading(false));
};

/* eslint-disable-next-line default-param-last */
export const searchStudent = (query, isTRF = false) => async (dispatch) => {
    dispatch(dttStudentIsLoading(true));

    const response = await searchStudents(query, isTRF);

    dispatch(dttStudentSetStudents(response));

    dispatch(dttStudentIsLoading(false));
};

export const studentSetSearch = (searchTerm = '') => async (dispatch, getState) => {
    const {
        dttStudentColumns,
        dttStudentSortColumn
    } = getState().DttStudentReducers;

    const {
        rowsShowed,
        activePage
    } = getState().StyledTablePaginationReducer;

    dispatch(dttStudentIsLoading(true));
    dispatch(paginationReset());
    dispatch(dttStudentSetShowStudentData(false));
    dispatch(dttStudentSearchTerm(searchTerm));

    if(searchTerm === '') {
        dispatch(getStudentsEndpoint(activePage));
    } else {
        const response = await searchStudents(searchTerm);

        dispatch(paginationSetLimitRows(response.length));

        const studentsSorted = sortStudents(
            dttStudentSortColumn,
            dttStudentColumns,
            response
        );
    
        dispatch(dttStudentSetStudents(studentsSorted));
        dispatch(
            dttStudentSetSortStudents(
                studentsSorted.slice(rowsShowed * 0, rowsShowed * PLUS_PAGE)
            ));
        dispatch(dttStudentIsLoading(false));
    }
};

export const initialSetup = () => async (dispatch, getState) => {
    const { dttStudentSearch } = getState().DttStudentReducers;

    dispatch(dttStudentIsLoading(true));
    dispatch(paginationReset());
    dispatch(dttStudentSetShowStudentData(false));
    dispatch(studentSetSearch(dttStudentSearch));
};

export const setPage = (page) => async (dispatch, getState) => {
    const {
        dttStudentSearch,
        dttStudents
    } = getState().DttStudentReducers;
    const {
        rowsShowed
    } = getState().StyledTablePaginationReducer;

    dispatch(dttStudentIsLoading(true));
    dispatch(dttStudentSetShowStudentData(false));
    dispatch(paginationSetActivePage(page));

    if(dttStudentSearch === '') {
        dispatch(getStudentsEndpoint(page));
    } else {
        dispatch(
            dttStudentSetSortStudents(
                dttStudents.slice(rowsShowed * page, rowsShowed * (page + PLUS_PAGE))
            ));
        dispatch(dttStudentIsLoading(false));
    }
};

export const showStudents = (show) => async (dispatch, getState) => {
    const {
        dttStudents,
        dttStudentsSorted
    } = getState().DttStudentReducers;

    dispatch(dttStudentSetShowStudentData(show));

    if(show) {
        const studentList = [];

        dttStudentsSorted.forEach(student => {
            studentList.push(student.id);
        });

        const body = JSON.stringify({ids: studentList});
        const { results } = await searchStudentsByIds(body);

        dispatch(dttStudentSetSortStudents(results));
    } else {
        dispatch(dttStudentSetSortStudents(dttStudents));
    }
};

export const sortStudentsByColumn = (columnName) => async (dispatch, getState) => {
    const {
        dttStudentColumns,
        dttStudents,
        dttStudentSearch
    } = getState().DttStudentReducers;

    const { activePage, rowsShowed } = getState().StyledTablePaginationReducer;

    dispatch(dttStudentIsLoading(true));

    const newSort = changeSort(dttStudentColumns[columnName].sort);

    dttStudentColumns[columnName].sort = newSort;

    dispatch(dttStudentStateColumns(columnName, newSort));
    dispatch(dttStudentSortByColumn(columnName));

    dispatch(dttStudentSetShowStudentData(false));
    dispatch(paginationSetActivePage(0));
    
    if(dttStudentSearch === '') {
        dispatch(getStudentsEndpoint(0));
    } else {
        const studentsSorted = sortStudents(columnName, dttStudentColumns, dttStudents);
        
        dispatch(dttStudentSetSortStudents(
            studentsSorted.slice(rowsShowed * activePage, rowsShowed * (activePage + PLUS_PAGE))));
        dispatch(dttStudentIsLoading(false));
    }
};