import React from 'react';
import PropTypes from 'prop-types';
import { CardStatusHeader, CardTitle, CardStatus } from '../TrackTrip.styles';

function CardHeader({statusColor, title, status}) {
    return (
        <CardStatusHeader statusColor={statusColor} data-testid="CardHeader">
            <CardTitle data-testid="detailCardTitle">
                {title}
            </CardTitle>
            <CardStatus data-testid="tripStatus">{status}</CardStatus>
        </CardStatusHeader>
    );
}

CardHeader.defaultProps = {
    title: "",
    status: "",
    statusColor: {
        color: "",
        backgroundColor: ""
    }
};

CardHeader.propTypes = {
    title: PropTypes.string,
    status: PropTypes.string,
    statusColor: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
    })
};

export default CardHeader;