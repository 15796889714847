import React, { useState, useEffect } from "react";
import Map from "google-maps-react";
import { mapStyles, homeIcon } from "../../styles/MapStyles";
import Marker from "../tracktrip/Marker";
import usePrevious from "../../util/customHooks/usePrevious";

function RRMap({
    height,
    facility,
    children
}) {
    const [ mapRef, setMapRef ] = useState(null);

    const centerAndZoom = () => {
        const map = mapRef && mapRef.map;
		
        if(map) {
            map.setCenter(facility);
            map.setZoom(9);
        }
    };

    if (!facility) {
        return null;
    }

    const heightStyle = { ...mapStyles, height: height || "calc( 100vh - 75px )" };

    const heightPrevious = usePrevious(height);
    
    useEffect(() => {
        if(heightPrevious !== height && mapRef) {
            setTimeout(() => {
                mapRef.props.google.maps.event.trigger(mapRef.map,
                    'resize');
            },
            800);
        }
    });

    return (
        <Map
            ref={(m) => { setMapRef(m); }}
            google={window.google}
            onReady={() => centerAndZoom()}
            containerStyle={heightStyle}
            style={heightStyle}
            streetViewControl={false}
        >
            <Marker key={9999} position={facility} icon={homeIcon} />
            {children}
        </Map>
    );
}

export default RRMap;
