/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
    bool,
    string
} from 'prop-types';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

const LoadingButtonBox = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: theme.palette.everDrivenDarkBlue,
    '&:hover': {
        backgroundColor: theme.palette.everDrivenDarkBlue80
    }
}));

function StyledLoadingButton({
    buttonLabel,
    isLoading,
    saveButtonDisabled,
    testId
}) {
    return <LoadingButtonBox
        data-testid={testId}
        disabled={saveButtonDisabled}
        loading={isLoading}
        type="submit"
    >
        {buttonLabel}
    </LoadingButtonBox>;
}

StyledLoadingButton.defaultProps = {
    buttonLabel: "",
    isLoading: false,
    saveButtonDisabled: false,
    testId: ""
};

/* eslint-disable react/forbid-prop-types */
StyledLoadingButton.propTypes = {
    buttonLabel: string,
    isLoading: bool,
    saveButtonDisabled: bool,
    testId: string
};

export default StyledLoadingButton;
