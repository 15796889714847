import * as signalR from "@microsoft/signalr";

import { setTripFormat } from "util/data/TripFormat";
import {
    Enum,
    optionsTripGroupDTT
} from "util/EnumHelper";
import { getTripType } from "util/tripSorter";
import Auth from 'auth/Auth';
import { internalInit } from "redux/actions/signalRActions";
import { setTripData } from "./trackTripWorker";
import { sortTrips } from "./tripGroupWorker";

const TRIP_STATUS = 'TripStatus';

const formatMsg = (msg) => {
    const newFormatMsg = {
        ...msg,
        payload: msg.Payload,
        commands: msg.Commands,
        dataVersion: msg.DataVersion
    };

    return newFormatMsg;
};

const tripGroupWithoutOldTrip = (trips, trip) => (
    trips.filter(findTrip => findTrip.TripGuid !== trip.TripGuid)
);

const addNewTrip = (newTrip) => (dispatch, getState) => {
    const { trips } = getState().TripGroupReducers;

    const newTripType = getTripType(newTrip);

    dispatch(sortTrips(newTripType, [ ...trips[newTripType], newTrip ]));
};

const updateTripGroup = (oldTrip, newTrip) => (dispatch, getState) => {
    const { trips } = getState().TripGroupReducers;

    const oldTripType = getTripType(oldTrip);
    const newListTrip = tripGroupWithoutOldTrip(trips[oldTripType], oldTrip);

    const newTripType = getTripType(newTrip);

    if(newTripType === oldTripType) {
        dispatch(sortTrips(newTripType, [ ...newListTrip, newTrip ]));
    } else {
        dispatch(sortTrips(oldTripType, newListTrip));
        dispatch(addNewTrip(newTrip));
    }
};

const getTrip = (trips, newTrip) => {
    const tripFound = trips.filter(findTrip => findTrip.TripGuid === newTrip.TripGuid);

    if(tripFound[0]) {
        return tripFound[0];
    }

    return null;
};

const foundOldTrip = (newTrip) => (dispatch, getState) => {
    const { trips } = getState().TripGroupReducers;
    let tripFound = null;

    optionsTripGroupDTT.forEach((option) => {
        if(!tripFound) {
            tripFound = getTrip(trips[option], newTrip);
        }
    });

    return tripFound;
};

const updateTrip = (newTrip) => (dispatch, getState) => {
    const { trip } = getState().TrackTripReducers;

    // Update trip in track trip
    if(trip
        && trip.TripGuid === newTrip.TripGuid
        && newTrip.dataVersion > trip.dataVersion) {
        dispatch(setTripData(newTrip));
    }

    const oldTrip = dispatch(foundOldTrip(newTrip));

    if(oldTrip) {
        dispatch(updateTripGroup(oldTrip, newTrip));
    } else {
        dispatch(addNewTrip(newTrip));
    }
};

const isATripSelected = (newTrip) => (dispatch, getState) => {
    const { guid } = getState().TripGroupReducers;
    const { trip } = getState().TrackTripReducers;

    const guidDropOff = newTrip.DropOffLocation.FacilityGuid;
    const guidPickUp = newTrip.PickUpLocation.FacilityGuid;
    const guidMember = newTrip.MemberGUID;

    // Is the trip on Track Trip or is a trip in Trip Group
    const validTrip = (
        (trip && trip.TripGuid === newTrip.TripGuid)
        || guid === guidDropOff || guid === guidPickUp || guid === guidMember
    );
    
    return validTrip;
};

const handleTripStatusMsg = (msg) => (dispatch, getState) => {
    const { facilityId } = getState().FacilityReducers;

    const msgFormat = formatMsg(msg);
    const newStateTrip = setTripFormat(msgFormat, facilityId);

    // Is the trip on Track Trip or is a trip in Trip Group
    if(dispatch(isATripSelected(newStateTrip))) {
        dispatch(updateTrip(newStateTrip));
    }
};

const handleMsg = (msg) => async (dispatch) => {
    const newMsg = {...msg};

    // Check msg type
    switch (newMsg.MrmMsgType) {
    case Enum.MrmMsgType.TripStatus:
    case TRIP_STATUS:
        dispatch(handleTripStatusMsg(newMsg));
        break;
    case Enum.MrmMsgType.SessionExpired:
        // This is a temporary solution. In the future, we should use a
        // single messaging component for displaying such information
        localStorage.setItem("LoginErrorMessage",
            "login.sessionExpired");
        await Auth.signout();

        break;
    default:
        window.error("We just got an unexpected message??",
            newMsg);
            
        break;
    }
};

const start = (connection) => async dispatch => {
    try {
        await connection.start();
        // eslint-disable-next-line
        console.assert(connection.state === signalR.HubConnectionState.Connected);
        dispatch(internalInit({ connection }));
    } catch (err) {
        // eslint-disable-next-line
        console.assert(connection.state === signalR.HubConnectionState.Disconnected);
        window.log('disconnected from signalR');
        // eslint-disable-next-line
        console.log(err);
        setTimeout(() => dispatch(start(connection)), 5000);
    }
};

// Action Creator for initializing SignalR
// eslint-disable-next-line import/prefer-default-export
export const init = () => (dispatch) => {
    setTimeout(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${Auth.getConfig().apiUrl}/api/MrmMsgPipe`, { accessTokenFactory: () => Auth.getToken() })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Warning)
            .build();

        connection.on('tripStatus', (msg) => {
            window.log(`got signalr message do: ${msg.Payload.DropOffLocation.FacilityGuid} pu: 
                    ${msg.Payload.PickUpLocation.FacilityGuid} member: ${msg.Payload.MemberGUID}`, msg);
            dispatch(handleMsg(msg));
        });

        connection.onclose();
        dispatch(start(connection));
    }, Auth.getConfig().configLoaded ? 0 : 2000);
};