const tripModel = {
    PickUpLocation: {
        Name: "",
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        Zip: "",
        Country: "",
        FacilityGuid: "",
        Coordinates: {
            Latitude: 0,
            Longitude: 0
        },
        PersonalLocation: false
    },
    DropOffLocation: {
        Name: "",
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        Zip: "",
        Country: "",
        FacilityGuid: "",
        Coordinates: {
            Latitude: 0,
            Longitude: 0
        },
        PersonalLocation: false
    },
    DriverInfo: {
        DriverName: "",
        VehicleMake: "",
        VehicleModel: "",
        VehicleColor: "",
        VehicleLicense: "",
        DriverGender: "",
        DriverSubstituteFlag: false,
        DriverGUID: ""
    },
    DueDateTLT: "",
    DueTimeTLT: "",
    DueDateTimeUTC: "",
    State: 211,
    PassengerCapacity: "",
    ServiceLevel: "",
    OnsiteTLT: null,
    NoshowTLT: null,
    MemberGUID: "",
    MemberPII: {
        FirstName: "",
        LastName: "",
        ClientMemberCode: ""
    },
    TripNumber: 0,
    VehicleRunID: 0,
    TimeZone: "",
    IsEmulated: false,
    ScheduledDropOffUTC: "",
    ScheduledDropOffTLT: "",
    dataVersion: 1,
    commands: [],
    TripGuid: "",
    status: ""
};

export default tripModel;