import { connect } from 'react-redux';
import TransportationNeed from 'components/TransportationRequest/TransportationNeed/TransportationNeed';
import { onUpdateModel } from 'redux/workers/transportationRequest/transportationRequestWorker';
import {
    onUpdateTransportationNeed,
    setHasTransportationNeeds
} from 'redux/workers/transportationRequest/transportationRequestStudentWorker';

const mapStateToProps = state => {
    const {
        TransportationRequestReducers,
        TransportationRequestErrorsReducers
    } = state;

    return {
        disabledCarSeat: TransportationRequestReducers.disabledCarSeat,
        disabledSafetyVest: TransportationRequestReducers.disabledSafetyVest,
        hasTN: TransportationRequestReducers.hasTN,
        transportationNeeds: TransportationRequestReducers.transportationNeeds,
        tripModel: TransportationRequestReducers.tripModel,
        errors: TransportationRequestErrorsReducers.studentErrors
    };
};

const TransportationNeedContainer = connect(mapStateToProps,
    {
        onUpdateModel,
        onUpdateTransportationNeed,
        setHasTN: setHasTransportationNeeds
    })(TransportationNeed);

export default TransportationNeedContainer;
