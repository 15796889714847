/* eslint-disable no-console */

const LOG_LEVEL_ERROR = 0;
const LOG_LEVEL_WARN = 1;
const LOG_LEVEL_INFO = 2;
const LOG_LEVEL_TRACE = 3;
const LOG_LEVEL_DEBUG = 4;

// So I'm not a huge fan of IE
const isIE = /* @cc_on!@ */false || !!document.documentMode;

const getNameForLogLevel = (level) => {
    switch (level) {
    case LOG_LEVEL_ERROR: return "ERROR";
    case LOG_LEVEL_WARN: return "WARN";
    case LOG_LEVEL_INFO: return "INFO";
    case LOG_LEVEL_TRACE: return "TRACE";
    default: return "DEBUG";
    }
};

const getStyleForLogLevel = (level) => {
    switch (level) {
    case LOG_LEVEL_ERROR: return 'background: red; color: white';
    case LOG_LEVEL_WARN: return 'background: hotpink; color: white';
    case LOG_LEVEL_INFO: return 'background: black; color: white';
    case LOG_LEVEL_TRACE: return 'background: gray; color: white';
    default: return 'background: lightgray; color: black';
    }
};

const log = (level, msg, logMethod, obj = null) => {
    let logLevel = window.logLevel || 0;
    const conf = window.Config || {};

    if (conf.logDebug) {
        logLevel = 4;
    }
    if (logLevel >= level) {
        if (isIE) {
            // eslint-disable-next-line no-console
            console.log(msg,
                obj);
        } else if (obj) {
            logMethod(`%c ${getNameForLogLevel(level)} `,
                getStyleForLogLevel(level),
                msg,
                obj);
        } else {
            logMethod(`%c ${getNameForLogLevel(level)} `,
                getStyleForLogLevel(level),
                msg);
        }
    }
};

// eslint-disable-next-line no-console
const debug = (msg, obj = null) => log(LOG_LEVEL_DEBUG,
    msg,
    isIE ? console.log : console.debug,
    obj);

// eslint-disable-next-line no-console
const trace = (msg, obj = null) => log(LOG_LEVEL_TRACE,
    msg,
    isIE ? console.log : console.log,
    obj);

// eslint-disable-next-line no-console
const info = (msg, obj = null) => log(LOG_LEVEL_INFO,
    msg,
    isIE ? console.log : console.info,
    obj);

// eslint-disable-next-line no-console
const warn = (msg, obj = null) => log(LOG_LEVEL_WARN,
    msg,
    isIE ? console.log : console.warn,
    obj);

// eslint-disable-next-line no-console
const error = (msg, obj = null) => log(LOG_LEVEL_ERROR,
    msg,
    isIE ? console.log : console.error,
    obj);

const init = () => {
    window.log = debug;
    window.trace = trace;
    window.info = info;
    window.warn = warn;
    window.error = error;
};

export default init;