import React, { useEffect } from 'react';
import { Paper, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import TripRequestFormAutoComplete from '../../widgets/tripRequestForm/TripRequestFormAutoComplete';
import { localize } from '../../util/Localizer';
import DPApp from '../DPApp';
import StyledLoading from '../Common/StyledLoading/StyledLoading';
// eslint-disable-next-line max-len
import TripRequestSiblingHeaderContainer from '../../redux/containers/tripRequestForm/TripRequestSiblingHeaderContainer';
import ModalConfirmation from '../../controls/Common/ModalConfirmation';
import TripRequestFormContentContainer from '../../redux/containers/tripRequestForm/TripRequestFormContentContainer';
import breakpointsNames from '../../styles/ResponsiveConstants';

import Auth from '../../auth/Auth';
import { getPathTabValue } from '../../util/updatePathTab';
import { paths, urlSupport } from '../../util/EnumHelper';
import useBlocker from '../../util/customHooks/useBlocker';

import StyledHeaderContent from '../Common/StyledHeaderContent/StyledHeaderContent';
import StyledMobileUnavailable from '../Common/StyledMobileUnavailable/StyledMobileUnavailable';
import StyledButton from '../Common/StyledButton/StyledButton';

const useStyles = makeStyles((theme) => ({
    paper: {
        background: theme.palette.white,
        boxShadow: theme.typography.boxShadow,
        borderRadius: '6px 6px 6px 6px',
        height: 60,
        width: 350
    },
    content: {
        paddingRight: 15,
        paddingLeft: 15
    },
    searchIcon: {
        marginLeft: 15,
        marginBottom: -15,
        marginRight: -15,
        color: theme.palette.everDrivenGrey
    },
    formContent: {
        paddingRight: 15,
        paddingLeft: 15,
        paddingTop: 220
    },
    formContentBig: {
        paddingRight: 15,
        paddingLeft: 15,
        paddingTop: 220
    },
    mobileSize: {
        display: 'none',
        [theme.breakpoints.down(breakpointsNames.sm)] : {
            display: 'block'
        },
        [theme.breakpoints.only(breakpointsNames.sm)]: {
            display: 'none',
            "@media only screen and (orientation: landscape)": {
                display: 'block'
            }
        }
    },
    normalSize: {
        display: 'block',
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            display: 'none'
        },
        [theme.breakpoints.only(breakpointsNames.sm)]: {
            display: 'block',
            "@media only screen and (orientation: landscape)": {
                display: 'none'
            }
        }
    },
    searchBarContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    draftListButton: {
        marginLeft: 15,
        width: 200,
        height: 40
    }
}));

function TripRequestForm({
    defaultStudentId,
    isDeleteDraftModalOpen,
    isClearStudentFormOpen,
    isRemoveSiblingOpen,
    isSaveDraftBeforeLeaveOpen,
    isSubmitConfirmOpen,
    isTRFLoading,
    limitReached,
    nextPath,
    removeTabMessage,
    router,
    searchTerm,
    selectedStudent,
    submitFormMessage,
    tabForDelete,
    clearDraftIndicator,
    onClearStudent,
    onDeleteDraft,
    onDeleteTab,
    onLoadForm,
    onLoadStudent,
    onLoadDefaultStudent: onLoadDefault,
    onSaveDraftBeforeLeave,
    onSubmitTripForm,
    onValidDraftIsSaved,
    setClearStudentStatusModal,
    setDeleteDraftModalStatus,
    setNextPath,
    setRemoveSiblingModalStatus,
    setSubmitConfirmModalStatus,
    setSaveBeforeLeaveModalStatus,
    setSearchTerm,
    updatePath
}) {
    const classes = useStyles();
    const { location } = router;

    const validIfIsMissingEmailAccount = () => {
        const accounts = Auth.getAccounts();

        return accounts.length === 0 || !accounts.filter(x => x.Email).length > 0;
    };

    const setNoEmailAccountBodyMessage = () => (
        <span>{localize('tripRequestForm.noAccountEmailPart1')}
            <a href={urlSupport.url}>
                {localize('tripRequestForm.noAccountEmailPart2')}</a>
        </span>
    );

    const onMissingAccountEmailConfirm = () => {
        const hasTripTrackAccess = Auth.hasTripTrackingAccess();

        // We want to do a hard reload here to verify if someone updated CosmoDB
        if (!hasTripTrackAccess) {
            document.location = '/Reports';
        } else {
            document.location = '/';
        }
    };

    const onSelectStudent = (item) => {
        onLoadStudent(item);
    };

    const onChangeStudentInput = (fieldValue) => {
        setSearchTerm(fieldValue);
    };

    const onBtnSubmit = () => {
        onSubmitTripForm();
    };

    const onBtnSaveDraft = () => {
        onSaveDraftBeforeLeave(nextPath);
    };

    const onBtnCancelDraft = () => {
        clearDraftIndicator();
        updatePath(getPathTabValue(nextPath));
    };

    const classNamePaper = `memberCard ${classes.paper}`;

    const blocker = (tx) => {
        if (onValidDraftIsSaved()) {
            setNextPath(tx.location.pathname);
            setSaveBeforeLeaveModalStatus(true);
        } else {
            tx.retry();
        }
    };

    useBlocker(blocker, !onValidDraftIsSaved());

    useEffect(() => {
        window.scrollTo(0, 0);

        // This is for prevent the browser to ask for a confirmation to leave the page when you have work unfinished
        window.addEventListener('beforeunload', (event) => {
            event.stopImmediatePropagation();
        });

        onLoadForm();

        return () => onLoadForm();
    },
    []);

    useEffect(() => {
        onLoadForm();
        if (location.state) {
            onLoadDefault(location.state);
        }
    },
    [ location, onLoadDefault ]);

    return (
        <DPApp>
            <StyledMobileUnavailable />
            <div className={classes.normalSize}>
                {isTRFLoading
                    ? (
                        <StyledLoading isFullWidth message={localize('tripRequestForm.processing')} />
                    ) : null
                }

                <StyledHeaderContent
                    idContainer="trfContainerHeader"
                    isFixed
                    title={localize('tripRequestForm.title')}
                >
                    <TripRequestSiblingHeaderContainer />
                </StyledHeaderContent>
                <Grid container className={limitReached ? classes.formContentBig : classes.formContent}>
                    <Grid item xs={12} className={classes.searchBarContainer}>
                        <Paper className={classNamePaper} >
                            <SearchIcon className={classes.searchIcon} />
                            <div className="item">
                                <div>
                                    <TripRequestFormAutoComplete
                                        searchTerm={searchTerm}
                                        defaultStudent={selectedStudent}
                                        onSelectStudent={onSelectStudent}
                                        onChangeInput={onChangeStudentInput}
                                        defaultStudentId={defaultStudentId}
                                    />
                                </div>
                            </div>
                        </Paper>
                        <StyledButton
                            testId="draftListButton"
                            color="primary"
                            className={classes.draftListButton}
                            onClick={() => updatePath(paths.DraftList.tabValue)}
                        >
                            {localize('tripRequestForm.draftList')}
                        </StyledButton>
                    </Grid>
                    <Grid item xs={12}>
                        <TripRequestFormContentContainer />
                    </Grid>
                </Grid>
            </div>
            <ModalConfirmation
                isOpen={isRemoveSiblingOpen}
                contentMessage={removeTabMessage}
                confirmInputLabel={localize('tripRequestForm.RemoveSiblingInputLabel')}
                cancelInputLabel={localize('tripRequestForm.CancelModalInputLabel')}
                onConfirm={() => onDeleteTab(tabForDelete)}
                onCancel={() => setRemoveSiblingModalStatus(false)}
            />
            <ModalConfirmation
                isOpen={isSubmitConfirmOpen}
                contentMessage={submitFormMessage}
                confirmInputLabel={localize('tripRequestForm.SubmitConfirmInputLabel')}
                cancelInputLabel={localize('tripRequestForm.CancelModalInputLabel')}
                onConfirm={onBtnSubmit}
                onCancel={() => setSubmitConfirmModalStatus(false)}
            />
            <ModalConfirmation
                isOpen={isClearStudentFormOpen}
                contentMessage={localize('tripRequestForm.modalConfirmBody')}
                confirmInputLabel={localize('tripList.yes')}
                cancelInputLabel={localize('tripList.no')}
                onConfirm={onClearStudent}
                onCancel={() => setClearStudentStatusModal(false)}
            />
            <ModalConfirmation
                isOpen={isSaveDraftBeforeLeaveOpen}
                contentMessage={localize('tripRequestForm.saveDraftBeforeLeave')}
                confirmInputLabel={localize('tripList.yes')}
                cancelInputLabel={localize('tripList.no')}
                disableBackDrop
                disableEscKey
                onConfirm={onBtnSaveDraft}
                onCancel={onBtnCancelDraft}
            />
            <ModalConfirmation
                isOpen={isDeleteDraftModalOpen}
                contentMessage={localize('tripRequestForm.deleteDraftConfirmation')}
                confirmInputLabel={localize('tripList.yes')}
                cancelInputLabel={localize('tripList.no')}
                onConfirm={onDeleteDraft}
                onCancel={() => setDeleteDraftModalStatus(false)}
            />
            <ModalConfirmation
                isOpen={validIfIsMissingEmailAccount()}
                contentMessage={setNoEmailAccountBodyMessage()}
                confirmInputLabel={localize('tripRequestForm.refreshPage')}
                hideCancelButton
                disableBackDrop
                disableEscKey
                onConfirm={onMissingAccountEmailConfirm}
            />
        </DPApp>
    );
}

export default TripRequestForm;
