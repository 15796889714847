import React from 'react';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    bool,
    func,
    node,
    object,
    string
} from 'prop-types';
import breakpointsNames from '../../../styles/ResponsiveConstants';

const RootPaper = styled(Paper)(({ theme }) => ({
    color: theme.palette.white,
    borderRadius: '12px',
    boxShadow: theme.typography.boxShadow
}));

const CardHeader = styled('div', {
    shouldForwardProp: prop => prop !== 'headerColor' && prop !== 'isTracker'
})(({headerColor, isTracker}) => {
    let styles = {
        padding: '0 8px',
        borderRadius: '12px 12px 0 0',
        background: headerColor
    };

    if (isTracker) {
        styles = {
            ...styles,
            '&> *': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            },
            '&:hover': {
                cursor: 'pointer'
            }
        };
    }
        
    return styles;
});

const CardBodyBox = styled('div', {
    shouldForwardProp: prop => prop !== 'isMapCard'
})(({theme, isMapCard}) => {
    let footer = {
        color: theme.palette.black,
        fontSize: '14px',
        '&> :last-child': {
            borderRadius: '0 0 8px 8px'
        }
    };

    if (isMapCard) {
        footer = {
            ...footer,
            height: 'calc(100vh - 295px)',
            minHeight: 590,
            [theme.breakpoints.down(breakpointsNames.sm)]: {
                minHeight: 570
            }
        };
    }

    return footer;
});

function StyledCardContainer({ isMapCard, isTracker, headerColor, cardHeader, cardBody, onClickHeader, footerStyles }) {
    return (
        <Grid item sx={{ padding: 0 }}>
            <RootPaper>
                <CardHeader
                    headerColor={headerColor}
                    isTracker={isTracker}
                    onClick={() => {
                        if(!isMapCard) {
                            onClickHeader();
                        }
                    }}
                >
                    {cardHeader}
                </CardHeader>
                <CardBodyBox isMapCard={isMapCard} style={footerStyles ?? {}}>
                    {cardBody}
                </CardBodyBox>
            </RootPaper>
        </Grid>
    );
}

StyledCardContainer.defaultProps = {
    isTracker: false,
    isMapCard: false,
    cardHeader: null,
    headerColor: null,
    cardBody: null,
    footerStyles: null,
    onClickHeader: () => null
};

/* eslint-disable react/forbid-prop-types */
StyledCardContainer.propTypes = {
    isTracker: bool,
    isMapCard: bool,
    cardHeader: node,
    headerColor: string,
    cardBody: node,
    footerStyles: object,
    onClickHeader: func
};
/* eslint-enable react/forbid-prop-types */

export default StyledCardContainer;
