import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CommonStyles from 'styles/CommonStyles';
import TripBuilderEditorContainer from 'redux/containers/transportationRequest/tripBuilder/TripBuilderEditorContainer';
import TripBuilderReviewContainer from 'redux/containers/transportationRequest/tripBuilder/TripBuilderReviewContainer';
import StyledSectionHeader from 'components/Common/StyledSectionHeader/StyledSectionHeader';

function TripBuilderContent({
    trip,
    tripNumber,
    title,
    textButton,
    setCollapseTrip

}) {
    const renderTripContent = () => (
        (!trip?.collapsed)
            ? <TripBuilderEditorContainer tripNumber={tripNumber} tripType={trip?.tripTypes?.toString()} />
            : <TripBuilderReviewContainer tripNumber={tripNumber} />
    );

    return (
        <Grid>
            <StyledSectionHeader
                title={title}
                onClick={() => setCollapseTrip(tripNumber)}
                icon={trip?.collapsed
                    ? <AddCircleOutlineIcon fontSize="inherit" />
                    : <RemoveCircleOutlineIcon fontSize="inherit" />
                }
                textButton={textButton}
                extraStyle={{ ...CommonStyles.subTitle, fontSize: 20 }}
            />
            {renderTripContent()}
        </Grid>
    );
}

TripBuilderContent.defaultProps = {
    trip: null,
    tripNumber: 0,
    title: '',
    textButton: '',
    setCollapseTrip: () => {}
};

/* eslint-disable react/forbid-prop-types */
TripBuilderContent.propTypes = {
    trip: PropTypes.object,
    tripNumber: PropTypes.number,
    title: PropTypes.string,
    textButton: PropTypes.string,
    setCollapseTrip: PropTypes.func
};

export default TripBuilderContent;