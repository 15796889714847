import React from "react";
import { bool, node, object, string } from "prop-types";
import colorConstants from "styles/ColorConstants";
import { museoFont700 } from "util/EnumHelper";

function StyledFormLabel({
    internalStyle,
    extraStyle,
    isRequired,
    label,
    markStyle,
    testid
}) {
    return (
        <span style={{
            color: colorConstants.EVERDRIVENDARKCHARCOAL,
            display: 'flex',
            fontFamily: museoFont700,
            fontSize: 16,
            margin: 0,
            lineHeight: 1.5,
            height: 26,
            ...extraStyle
        }}>
            <span
                style={{
                    height: 26,
                    ...internalStyle
                }}
                data-testid={testid}
            >
                {label}
                {
                    isRequired &&
                    <span style={{
                        fontSize: 20,
                        color: colorConstants.EVERDRIVENFUCHSIA,
                        ...markStyle
                    }}>
                        *
                    </span>
                }
            </span>
        </span>
    );
}

StyledFormLabel.defaultProps = {
    markStyle: null,
    extraStyle: null,
    internalStyle: null,
    isRequired: false,
    label: null,
    testid: null
};

/* eslint-disable react/forbid-prop-types */
StyledFormLabel.propTypes = {
    markStyle: object,
    extraStyle: object,
    internalStyle: object,
    isRequired: bool,
    label: node,
    testid: string
};
/* eslint-enable react/forbid-prop-types */

export default StyledFormLabel;