import React from 'react';
import './loadingprerender.css';

function LoadingPrerender() {
    return <div>
        <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>;
}

export default LoadingPrerender;
