import React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import { localize } from 'util/Localizer';
import { museoSansFont } from 'util/EnumHelper';
import StyledWrapper from 'components/Common/StyledWrapper/StyledWrapper';
import TRNavContainer from 'redux/containers/transportationRequest/TRNavContainer';
import ContactForm from '../ContactForm/ContactForm';

const AddContactButton = styled(StyledButton)(({ theme }) => ({
    // eslint-disable-next-line max-len
    backgroundColor: theme.palette.transparent,
    padding: '8px 20px',
    borderRadius: 20,
    border: `1px solid ${theme.palette.everDrivenLightBlue}`,
    width: 250,
    height: 48,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: museoSansFont,
    color: theme.palette.everDrivenLightBlue,
    boxShadow: 'none',
    "&:hover": {
        boxShadow: 'none',
        backgroundColor: theme.palette.transparent
    }
}));

function ContactInformation({
    numberOfContacts,
    showRemoveContactModal,
    tripModel,
    onLoadRemoveContactModal,
    onRemoveContactSection,
    onUpdateModel,
    setNumberOfContacts,
    setShowRemoveContactModal,
    errors
}) {
    const contactModel = (formName) => ({
        name: tripModel[`${formName}Name`],
        relationship: tripModel[`${formName}Relationship`],
        phoneNumber: tripModel[`${formName}PhoneNumber`],
        altPhoneNumber: tripModel[`${formName}AltPhoneNumber`],
        emailAddress: tripModel[`${formName}EmailAddress`],
        appAccess: tripModel[`${formName}AppAccess`]
    });

    const renderContacts = () => {
        const contacts = [];

        for (let index = 1; index <= numberOfContacts; index += 1) {
            contacts.push(
                <ContactForm
                    key={`contact${index}`}
                    contactModel={contactModel(`contact${index}`)}
                    contactNumber={index}
                    formName={`contact${index}`}
                    numberOfContacts={numberOfContacts}
                    showRemoveModal={showRemoveContactModal}
                    onChangeContactValue={onUpdateModel}
                    onLoadRemoveContactModal={onLoadRemoveContactModal}
                    onRemoveContactSection={onRemoveContactSection}
                    onShowRemoveModal={setShowRemoveContactModal}
                    errors={errors}
                />
            );
        }

        return contacts;
    };

    return (
        <StyledWrapper>
            <Box sx={{ margin: '0 131px', padding: '30px 0 20px' }}>
                <Grid container>
                    {renderContacts().map(x => x)}
                    {numberOfContacts < 3 &&
                        <Grid item xs={12} sx={{ paddingTop: '25px' }}>
                            <AddContactButton
                                isDisabled={numberOfContacts === 3}
                                testId="addContactButton"
                                type="button"
                                onClick={() => setNumberOfContacts(numberOfContacts+1)}
                            >
                                {localize('transportationRequest.addAnotherContact')}
                            </AddContactButton>
                        </Grid>
                    }
                </Grid>
            </Box>
            <TRNavContainer />
        </StyledWrapper>
    );
}

ContactInformation.defaultProps = {
    numberOfContacts: 1,
    showRemoveContactModal: false,
    tripModel: {},
    onLoadRemoveContactModal: null,
    onRemoveContactSection: null,
    onUpdateModel: null,
    setNumberOfContacts: null,
    setShowRemoveContactModal: null
};

/* eslint-disable react/forbid-prop-types */
ContactInformation.propTypes = {
    numberOfContacts: PropTypes.number,
    showRemoveContactModal: PropTypes.bool,
    tripModel: PropTypes.object,
    onLoadRemoveContactModal: PropTypes.func,
    onRemoveContactSection: PropTypes.func,
    onUpdateModel: PropTypes.func,
    setNumberOfContacts: PropTypes.func,
    setShowRemoveContactModal: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default ContactInformation;