import { connect } from "react-redux";
import StudentFileRow from "../../../components/studentUpload/StudentFileRow";
import { onDeleteFile } from "../../workers/studentUploadWorker";

const StudentFileRowContainer =
    connect(null,
        {
            onDeleteFile
        })(StudentFileRow);

export default StudentFileRowContainer;