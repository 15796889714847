import { combineReducers } from 'redux';
import { createRouterReducer } from '@lagunovsky/redux-react-router';
import SignalRReducers from './signalRReducers';
import FacilityReducers from './districtTripTracker/facilityReducers';
import PathReducers from './pathReducers';
import StudentReducers from './districtTripTracker/studentReducers';
import UserReducers from './userReducers';
import TripRequestFormReducers from './tripRequestFormReducers';
import ReportReducers from './reportReducers';
import CalendarReducers from './calendarReducers';
import StyledSnackbarWrapperReducer from './styledSnackbarWrapperReducer';
import CalendarUploadReducers from './calendarUploadReducers';
import ResourcesReducers from './resourcesReducers';
import StudentUploadReducers from './studentUploadReducers';
import DraftListReducers from './draftListReducer';
import StyledHeaderReducers from './styledHeaderReducer';
import DttStudentReducers from './districtTripTracker/dttStudentReducers';
import DttFacilityReducers from './districtTripTracker/dttFacilityReducers';
import StyledTablePaginationReducer from './StyledTablePaginationReducer';
import TripGroupReducers from './tripGroupReducer';
import TrackTripReducers from './trackTripReducer';
import TransportationRequestReducers from './transportationRequest/transportationRequestReducers';
import TransportationRequestErrorsReducers from './transportationRequest/transportationRequestErrorsReducers';
import TransportationRequestModalReducers from './transportationRequest/transportationRequestModalReducers';
import ErrorPageReducer from './errorPageReducer';

const rootReducer = (history) => combineReducers({
    SignalRReducers,
    FacilityReducers,
    PathReducers,
    StudentReducers,
    UserReducers,
    DttStudentReducers,
    DttFacilityReducers,
    TripRequestFormReducers,
    ReportReducers,
    StyledSnackbarWrapperReducer,
    CalendarReducers,
    CalendarUploadReducers,
    ResourcesReducers,
    StudentUploadReducers,
    DraftListReducers,
    StyledHeaderReducers,
    StyledTablePaginationReducer,
    TripGroupReducers,
    TrackTripReducers,
    TransportationRequestReducers,
    TransportationRequestErrorsReducers,
    TransportationRequestModalReducers,
    ErrorPageReducer,
    router: createRouterReducer(history)
});

export default rootReducer;
