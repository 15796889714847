/* eslint max-lines: 0 */
import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material/styles';
import Hidden from "@mui/material/Hidden";
import commonUseStyles, { commonStyles } from 'styles/CommonStyles';
import { localize } from 'util/Localizer';
import SeparatorContent from 'widgets/common/SeparatorContent';
import breakpointsNames from 'styles/ResponsiveConstants';
import UserForm from 'components/user/UserForm/UserForm';
import Auth from 'auth/Auth';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import StyledCheckbox from 'components/Common/StyledCheckbox/StyledCheckbox';
import UserGroups from 'controls/User/UserGroups';
import { array, func, object, string } from 'prop-types';
import { Enum } from 'util/EnumHelper';

const ContentResultDeactivate = styled(Paper)((props) => {
    const isAccountEnabled = /^true$/i.test(props?.accountenabled);

    const contentResult = {
        ...commonUseStyles.gridContent,
        paddingLeft: 0
    };

    const contentResultDeactivate = {
        backgroundColor: props.theme.palette.everDrivenGrey40
    };

    return {
        ...contentResult,
        ...(!isAccountEnabled && contentResultDeactivate)
    };
});

const ContentResult = styled(Paper)(() => ({
    ...commonUseStyles.gridContent,
    paddingLeft: 0
}));

const CheckBoxColumn = styled('div')(() => ({
    maxWidth: 40
}));

const GroupColumn = styled('div')(() => ({
    minWidth: 360
}));

const NameColumn = styled('div')(({ theme }) => ({
    ...commonStyles(theme).userMgt.nameColumn,
    display: 'flex',
    width: '300px',
    alignItems: 'center',
    [theme.breakpoints.down(breakpointsNames.xl)]: {
        width: '150px'
    },
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        width: '100px'
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        paddingLeft: 10
    },
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        flex: '1 0 90px'
    }
}));

const LastNameColumn = styled('div')(({ theme }) => ({
    ...commonStyles(theme).userMgt.lastNameColumn
}));

const EmailColumn = styled('div')(({ theme }) => ({
    ...commonStyles(theme).userMgt.emailColumn
}));

const DetailsButtonBox = styled('div')(() => ({
    minWidth: 52,
    maxWidth: 52
}));

const EditUserButton = styled(StyledButton)(() => ({
    ...commonUseStyles.gridButton
}));

const OverflowName = styled('div')(({ theme }) => ({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 300,
    [theme.breakpoints.down(breakpointsNames.xl)]: {
        width: '150px'
    },
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        width: '100px'
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: '150px'
    },
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        width: '80px'
    }
}));

const OverflowLastName = styled('div')(({ theme }) => ({
    textOverflow:'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 300,
    [theme.breakpoints.down(breakpointsNames.xl)]: {
        width: '150px'
    },
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        width: '150px'
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: '150px'
    },
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        width: '110px'
    }
}));

const OverflowEmail = styled('div')(({ theme }) => ({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '300px',
    [theme.breakpoints.down(breakpointsNames.xl)]: {
        width: '200px'
    }
}));

const ResendEmailButton = styled(Button)(({ theme }) => ({
    width: '10%',
    alignItems: 'center',
    display: 'flex',
    marginLeft: 'auto',
    paddingLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        width: '70px'
    }
}));

const EverDrivenEmailIcon = styled(EmailIcon)(({ theme }) => ({
    color: theme.palette.everDrivenLightBlue
}));

function UserRow({
    editUser,
    errorMessage,
    facilityList,
    user,
    handleChangeCheck,
    onSubmitEdit,
    sendInvitationUser,
    setUserResponse: setErrorMessage
}) {
    const [ saveButtonDisabled, setSaveButtonDisabled ] = React.useState(true);
    const [ showEdit, setShowEdit ] = React.useState(false);
    const [ formUser, setFormUser ] = useState({ ...editUser });
    const [ editButtonLabel, setEditButtonLabel ] = useState(localize('editUser.SaveChanges'));
    const [ inviteButtonLabel, setInviteButtonLabel ] = useState('');
    const [ inviteButtonVisible, setInviteButtonVisible ] = useState(false);
    const [ typeOfInvite, setTypeOfInvite ] = useState(Enum.typeOfInvite.allInvites);
    const bottonIdName = `SubmitEdit_${user.id}`;
    const newActionButtons = [bottonIdName];
    const checkDisabled = (Auth?.getName() ?? '') === (user?.emailAddress ?? '');

    const handleChange = (event) => {
        handleChangeCheck(
            user.id,
            event.target.checked
        );
    };

    const handleShowEdit = () => {
        setShowEdit(!showEdit);
        if (!showEdit) {
            setFormUser({ ...editUser });
        }
    };

    const onEditSubmit = (event) => {
        event.preventDefault();
        setErrorMessage('');
        setShowEdit(false);
        onSubmitEdit(formUser);
    };

    const sendInvitation = (event) => {
        event.preventDefault();
        const inviteUser = { ...user };
        
        if (typeOfInvite === Enum.typeOfInvite.allInvites) {
            inviteUser.isTeacher = true;
            inviteUser.isDistrict = true;
        } else if (typeOfInvite === Enum.typeOfInvite.portalInvite) {
            inviteUser.isTeacher = false;
            inviteUser.isDistrict = true;
        } else {
            inviteUser.isTeacher = true;
            inviteUser.isDistrict = false;
        }
        
        sendInvitationUser(inviteUser);
    };

    useEffect(() => {
        const isNewUserAccount = editUser.isNewUserAccount !== false && (editUser.canAccessProgramManagement
            || editUser.canAccessTripTracking || editUser.isAdministrator);
        const isNewVipUserAccount = editUser.isNewVipUserAccount !== false
            && editUser.isTeacher;
        
        if (isNewUserAccount && !isNewVipUserAccount) {
            setInviteButtonVisible(true);
            setInviteButtonLabel(localize('editUser.ResendPortalInvite'));
            setTypeOfInvite(Enum.typeOfInvite.portalInvite);
        } else if (!isNewUserAccount && isNewVipUserAccount) {
            setInviteButtonLabel(localize('editUser.ResendVIPInvite'));
            setInviteButtonVisible(true);
            setTypeOfInvite(Enum.typeOfInvite.vipInvite);
        } else if (isNewUserAccount && isNewVipUserAccount) {
            setInviteButtonLabel(localize('editUser.ResendInvitations'));
            setInviteButtonVisible(true);
            setTypeOfInvite(Enum.typeOfInvite.allInvites);
        } else {
            setInviteButtonVisible(false);
        }
    }, [editUser]);

    return (
        <div>
            <ContentResultDeactivate
                id={user.id}
                accountenabled={user.accountEnabled?.toString()}
            >
                <div className="memberCard" data-testid={`row_${user.emailAddress}`}>
                    <Hidden mdDown>
                        <CheckBoxColumn>
                            <StyledCheckbox
                                color="secondary"
                                isChecked={user.isChecked}
                                isDisabled={checkDisabled}
                                size="medium"
                                onChange={handleChange}
                            />
                        </CheckBoxColumn>
                        <SeparatorContent />
                    </Hidden>
                    <NameColumn>
                        <OverflowName>
                            <strong className="qm_block">{user.firstName}</strong>
                        </OverflowName>

                        {editUser.accountEnabled && inviteButtonVisible &&
                            (<Tooltip
                                title={
                                    <Typography color="inherit">
                                        {inviteButtonLabel}
                                    </Typography>
                                }
                            >
                                <ResendEmailButton
                                    onClick={sendInvitation}
                                >
                                    <EverDrivenEmailIcon />
                                </ResendEmailButton>
                            </Tooltip>)
                        }
                    </NameColumn>
                    <SeparatorContent />
                    <LastNameColumn>
                        <OverflowLastName>
                            <strong className="qm_block">{user.lastName}</strong>
                        </OverflowLastName>
                    </LastNameColumn>
                    <Hidden smDown>
                        <SeparatorContent />
                    </Hidden>
                    <Hidden mdDown>
                        <EmailColumn>
                            <OverflowEmail>
                                <strong className="qm_block">{user.emailAddress}</strong>
                            </OverflowEmail>
                        </EmailColumn>
                        <SeparatorContent />
                    </Hidden>
                    <Hidden lgDown>
                        <GroupColumn>
                            <UserGroups user={user} />
                        </GroupColumn>
                        <SeparatorContent />
                    </Hidden>
                    <DetailsButtonBox>
                        <EditUserButton
                            color="secondary"
                            testId={`showEditUserButton_${user.emailAddress}`}
                            onClick={handleShowEdit}
                        >
                            {showEdit ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                        </EditUserButton>
                    </DetailsButtonBox>
                </div>
            </ContentResultDeactivate>
            {showEdit &&
                <ContentResult>
                    <UserForm
                        actionButtons={newActionButtons}
                        baseUser={editUser}
                        bottonIdName={bottonIdName}
                        buttonLabel={editButtonLabel}
                        errorMessage={errorMessage}
                        facilityList = {facilityList}
                        inviteButtonLabel={inviteButtonLabel}
                        inviteButtonVisible={inviteButtonVisible}
                        isEdit
                        nameForm={`userForm_${user.id}`}
                        saveButtonDisabled={saveButtonDisabled}
                        user={formUser}
                        onSubmitForm={onEditSubmit}
                        sendInvitation={sendInvitation}
                        setEditButtonLabel={setEditButtonLabel}
                        setErrorAlert={setErrorMessage}
                        setSaveButtonDisabled={setSaveButtonDisabled}
                        setUser={setFormUser}
                    />
                </ContentResult>
            }
        </div>
    );
}

UserRow.defaultProps = {
    editUser: {},
    errorMessage: '',
    facilityList: [],
    user: {},
    handleChangeCheck: () => null,
    onSubmitEdit: () => null,
    sendInvitationUser: () => null,
    setUserResponse: () => null
};

/* eslint-disable react/forbid-prop-types */
UserRow.propTypes = {
    editUser: object,
    errorMessage: string,
    facilityList: array,
    user: object,
    handleChangeCheck: func,
    onSubmitEdit: func,
    sendInvitationUser: func,
    setUserResponse: func
};

export default UserRow;
