import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import UserHeaderContainer from 'redux/containers/user/UserHeaderContainer';
import UserBodyContainer from 'redux/containers/user/UserBodyContainer';
import UserModalContainer from 'redux/containers/user/UserModalContainer';
import UserModalDeleteContainer from 'redux/containers/user/UserModalDeleteContainer';
import DPApp from '../../components/DPApp';

function UserManagement({
    facilityList,
    loadUsers: loads,
    loadFacilities: loadFacs
}) {
    useEffect(() => {
        loads();
        if (facilityList.length === 0) {
            loadFacs();
        }
    }, []);

    return (
        <DPApp>
            <Grid container>
                <UserHeaderContainer />
                <UserBodyContainer />
            </Grid>
            <UserModalContainer />
            <UserModalDeleteContainer />
        </DPApp>
    );
}

UserManagement.defaultProps = {
    facilityList: [],
    loadUsers: () => null,
    loadFacilities: () => null
};

/* eslint-disable react/forbid-prop-types */
UserManagement.propTypes = {
    facilityList: PropTypes.array,
    loadUsers: PropTypes.func,
    loadFacilities: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default UserManagement;
