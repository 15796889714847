export function arrayMin(arr) {
    let len = arr.length;
    let min = Infinity;

    while (len >= 0) {
        if (arr[len] < min) {
            min = arr[len];
        }

        len -= 1;
    }

    return min;
}

export function arrayMax(arr) {
    let len = arr.length;
    let max = -Infinity;

    while (len >= 0) {
        if (arr[len] > max) {
            max = arr[len];
        }

        len -= 1;
    }

    return max;
}

export function groupByMap(list, groupId) {
    const results = {};

    list.map(item => {
        const key = groupId(item);

        if (!results[key]) {
            results[key] = [];
        }
        results[key].push(item);

        return null;
    });

    return results;
}

export const not = (a, b) => a.filter(value => !b.map(d => d.id).includes(value.id));

export const intersection = (a, b) => a.filter(value => b.map(d=> d.id).includes(value.id));

export const union = (a, b) => [ ...a, ...not(b,
    a) ];

export const isUnique = (arr, propName) => {
    const tmpArr = [];

    arr.forEach(item => {
        if(tmpArr.indexOf(item[propName]) < 0) {
            tmpArr.push(item[propName]);
        } else {
            return false;
        }

        return item;
    });

    return true;
};

export const generateUniqueKey = () => {
    const crypto = window.crypto || window.msCrypto;
    const arrayId = new Uint32Array(1);

    crypto.getRandomValues(arrayId);

    return arrayId.toString();
};