import React from'react';
import PropTypes from 'prop-types';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import colorConstants from "styles/ColorConstants";
import CommonStyles from 'styles/CommonStyles';
import { Box, Grid } from '@mui/material';
import { museoSansFont700 } from "util/EnumHelper";
import { styled } from '@mui/material/styles';
import StyledTypography from 'components/Common/StyledTypography/StyledTypography';

const Header = styled(Grid)(({theme}) => ({
    fontFamily: museoSansFont700,
    fontSize: 24,
    padding: '30px 15px 0px 15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.everDrivenGrey30}`,
    backgroundColor: theme.palette.white
}));

const Title = styled(StyledTypography)(() => ({
    marginLeft: 0,
    marginTop: 10,
    paddingBottom: 0,
    fontSize: 24,
    fontFamily: museoSansFont700,
    color: colorConstants.EVERDRIVENDARKCHARCOAL,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

function HowToHeader({
    title,
    buttonColor,
    textButton,
    testId,
    onClick
}) {
    return (
        <Header
            id="howToContainerHeader"
        >
            <Grid item xs={12} style={{ width: 'calc(100% - 100px)' }}>
                <Title data-testid="howToHeader" variant="h3">
                    {title}
                </Title>
            </Grid>
            <Box sx={{ paddingBottom: '32px'}}>
                <StyledButton
                    style={{
                        ...CommonStyles.linkButton,
                        color: buttonColor,
                        marginRight: 'auto',
                        paddingLeft: 128,
                        height: 26
                    }}
                    type="button"
                    variant="text"
                    testId={`${testId}-Button`}
                    onClick={() => onClick()}
                >
                    {textButton}
                </StyledButton>
            </Box>
        </Header>
    );
}
export default HowToHeader;

HowToHeader.defaultProps = {
    title: '',
    buttonColor: colorConstants.EVERDRIVENLIGHTBLUE,
    textButton: '',
    testId: '',
    onClick: () => {}
};

HowToHeader.propTypes = {
    title: PropTypes.string,
    buttonColor: PropTypes.string,
    textButton: PropTypes.string,
    testId: PropTypes.string,
    onClick: PropTypes.func
};