import React, {useState} from 'react';
import {
    arrayOf,
    func,
    string,
    shape
} from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SearchIcon from '@mui/icons-material/Search';
import { ClickAwayListener } from '@mui/material/';

import { localize } from 'util/Localizer';
import StyledCheckbox from 'components/Common/StyledCheckbox/StyledCheckbox';
import StyledTextField from 'components/Common/StyledTextField/StyledTextField';
import StyledListItem from 'components/Common/StyledListItem/StyledListItem';
import StyledTooltip from 'components/Common/StyledTooltip/StyledTooltip';
import facilityShape from 'data/models/FacilityModel';

const ItemIcon = styled(ListItemIcon)(() => ({
    minWidth: 30
}));

const ItemText = styled(ListItemText)(() => ({
    fontSize: '12px',
    textOverflow:'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
}));

const StyledCardHeader = styled(CardHeader)(({theme}) => ({
    padding: theme.spacing(0,
        0),
    fontWeight: 'bold',
    fontSize: '12px',
    backgroundColor: theme.palette.offWhite,
    '& .MuiCardHeader-avatar': {
        marginRight: 0
    }
}));

const StyledList = styled(List)(({theme}) => ({
    width: '100%',
    height: 150,
    overflow: 'auto',
    backgroundColor: theme.palette.offWhite
}));

function SchoolSelectList({
    id,
    items = [],
    unfilteredItems = [],
    checkedList = [],
    onUpdateCheckedList,
    updateSearch,
    searchValue,
    selectAllSchools
}) {
    const [ showSchoolList, setShowSchoolList ] = useState(false);

    const handleFocus = () => {
        setShowSchoolList(true);
    };

    const handleClickAway = () => {
        if (showSchoolList) {
            setShowSchoolList(false);
        }
    };

    const onSearch = event => {
        if(event) {
            const searchText = event.target.value.toUpperCase();

            updateSearch(searchText);
        }
    };

    const allSchoolsSelected = () => items.every(school => checkedList.includes(school.id));
    
    const getLabelText = () => {
        if (checkedList.length === 0) {
            return localize('userForm.labelSelectSchools');
        }
        if (allSchoolsSelected()) {
            return localize('userForm.labelAllSchoolsSelected');
        }

        return `${checkedList.length} ${ checkedList.length === 1 ?
            localize('userForm.labelSchoolsSelectedSingle') : localize('userForm.labelSchoolsSelected')}`;
    };

    const renderList = (list) => (
        list.map(value => (
            <StyledListItem
                key={`listItem_${value.id}`}
                button
                onClick={() => onUpdateCheckedList(value)}
                disableGutters
                testId={`${id}_${value.name}_item`}
            >
                <ItemIcon>
                    <StyledCheckbox
                        color="secondary"
                        disableRipple
                        isChecked={checkedList.indexOf(value.id) >= 0}
                        inputProps={{
                            'aria-labelledby': `transfer-list-all-item-${value.id}-label`,
                            'data-testid': `${id}_${value.name}_checkbox`
                        }}
                        size="small"
                        tabIndex={-1}
                    />
                </ItemIcon>
                <StyledTooltip title={value.name} placement="top-start">
                    <ItemText
                        id={`transfer-list-all-item-${value.id}-label`}
                        primary={value.name}
                        disableTypography
                    />
                </StyledTooltip>
            </StyledListItem>
        ))
    );

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Card
                onFocus={handleFocus}
            >
                <StyledTextField
                    id={id}
                    inputProps={{'data-testid': `${id}_searchInput` }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    label={getLabelText()}
                    name={id}
                    onChange={onSearch}
                    value={searchValue}
                />
                {showSchoolList &&
                    <><StyledCardHeader
                        disableTypography
                        avatar={<StyledCheckbox
                            color="secondary"
                            inputProps={{ 'aria-label': 'all items selected', 'data-testid': `${id}_selectAll` }}
                            isChecked={allSchoolsSelected()}
                            size="small"
                            onClick={(event) => selectAllSchools(event, items)} />}
                        title={localize("userForm.labelSelectAll")}
                    />
                    <Divider />
                    <StyledList disablePadding data-testid={`${id}_list`}>
                        {renderList(checkedList
                            .filter(sid => unfilteredItems.findIndex(school => school.id === sid) === -1)
                            .map(sid => ({ id: sid, name: 'Deleted school (Remove before save)' })))}
                        {renderList(items.filter(school => checkedList.includes(school.id)))}
                        {renderList(items.filter(school => !checkedList.includes(school.id)))}

                        <ListItem />
                    </StyledList>
                    </>
                }
            </Card>
        </ClickAwayListener>
    );
}

SchoolSelectList.defaultProps = {
    id: null,
    items: [],
    unfilteredItems: [],
    checkedList: [],
    onUpdateCheckedList: () => null,
    updateSearch: () => null,
    searchValue: '',
    selectAllSchools: () => null
};

SchoolSelectList.propTypes = {
    id: string,
    items: arrayOf(shape(facilityShape)),
    unfilteredItems: arrayOf(shape(facilityShape)),
    checkedList: arrayOf(string),
    onUpdateCheckedList: func,
    updateSearch: func,
    searchValue: string,
    selectAllSchools: func
};

export default SchoolSelectList;
