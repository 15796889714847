/* eslint max-lines: 0 */
import { createTheme, alpha } from '@mui/material/styles';

import COLORCONSTANTS from './styles/ColorConstants';

export default createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: COLORCONSTANTS.WHITE,
                        backgroundColor: COLORCONSTANTS.EVERDRIVENDARKBLUE
                    },
                    fontSize: '12px',
                    lineHeight: 1.75
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: COLORCONSTANTS.WHITE
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                startIcon: {
                    marginLeft: -2,
                    marginRight: 4
                },
                text: {
                    color: COLORCONSTANTS.WHITE
                },
                root: {
                    textTransform: 'none',
                    fontWeight: 700
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '12px'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: COLORCONSTANTS.EVERDRIVENFUCHSIA,
                    fontSize: '1rem',
                    '&.Mui-error': {
                        color: COLORCONSTANTS.EVERDRIVENFUCHSIA
                    }
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    height: '1.3em',
                    width: '1.3em'
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                /* Add root style property when all the app has been already redesigned */
                input: {
                    '&.Mui-disabled': {
                        backgroundColor: COLORCONSTANTS.EVERDRIVENLIGHTBLUE60,
                        WebkitTextFillColor: COLORCONSTANTS.WEBKITTEXTFILLCOLOR,
                        '&:hover': {
                            backgroundColor: COLORCONSTANTS.EVERDRIVENLIGHTBLUE60
                        }
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                /* Add root style property when all the app has been already redesigned */
                input: {
                    '&.Mui-disabled': {
                        backgroundColor: COLORCONSTANTS.EVERDRIVENLIGHTBLUE60,
                        WebkitTextFillColor: COLORCONSTANTS.WEBKITTEXTFILLCOLOR
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: COLORCONSTANTS.EVERDRIVENDARKGREY
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: COLORCONSTANTS.EVERDRIVENDARKBLUE,
                        '&:hover': {
                            backgroundColor: COLORCONSTANTS.EVERDRIVENDARKBLUE
                        }
                    },
                    '&.Mui-disabled': {
                        backgroundColor: COLORCONSTANTS.EVERDRIVENLIGHTBLUE20,
                        opacity: 1
                    },
                    '&.MuiDivider-root': {
                        margin: 0
                    }
                },
                '&.root+': {
                    '&.MuiDivider-root': {
                        marginTop: 0,
                        marginBottom: 0
                    }
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: COLORCONSTANTS.EVERDRIVENDARKGREY,
                        '&:hover': {
                            backgroundColor: COLORCONSTANTS.EVERDRIVENLIGHTBLUE
                        }
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    '&.Mui-selected': {
                        backgroundColor: `${COLORCONSTANTS.EVERDRIVENLIGHTBLUE20} !important`,
                        '&:hover': {
                            backgroundColor: `${COLORCONSTANTS.EVERDRIVENLIGHTBLUE20} !important`
                        }
                    },
                    '&:hover': {
                        backgroundColor: `${COLORCONSTANTS.EVERDRIVENLIGHTBLUE20} !important`
                    }
                }
            }
        },
        MuiLoadingButton: {
            styleOverrides: {
                root: {
                    backgroundColor: COLORCONSTANTS.EVERDRIVENDARKBLUE,
                    color: COLORCONSTANTS.WHITE,
                    '&:hover': {
                        backgroundColor: COLORCONSTANTS.EVERDRIVENDARKBLUE
                    },
                    '&.Mui-disabled': {
                        backgroundColor: COLORCONSTANTS.EVERDRIVENDARKBLUE60,
                        color: COLORCONSTANTS.WHITE
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    fontFamily: 'MuseoSans500',
                    fontSize: 12,
                    color: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL
                },
                selectLabel: {
                    fontFamily: 'MuseoSans500',
                    fontSize: 12,
                    color: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL
                },
                select: {
                    backgroundColor: COLORCONSTANTS.WHITE,
                    fontFamily: 'MuseoSans500',
                    fontSize: 12,
                    color: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL,
                    '&:focus': {
                        backgroundColor: COLORCONSTANTS.WHITE
                    }
                },
                selectIcon: {
                    top: 'calc(50% - 0.7em)'
                },
                menuItem: {
                    fontFamily: 'MuseoSans500',
                    fontSize: 12,
                    backgroundColor: COLORCONSTANTS.WHITE,
                    '&:hover': {
                        backgroundColor: COLORCONSTANTS.EVERDRIVENGREY40
                    },
                    '&.Mui-selected':{
                        backgroundColor: COLORCONSTANTS.EVERDRIVENGREY40,
                        '&:hover': {
                            backgroundColor: COLORCONSTANTS.EVERDRIVENGREY60
                        }
                    },
                    '&:focus': {
                        backgroundColor: COLORCONSTANTS.WHITE
                    }
                },
                displayedRows: {
                    fontFamily: 'MuseoSans500',
                    fontSize: 12,
                    color: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL
                }
            }
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    },
    palette: {
        primary: {
            main: COLORCONSTANTS.EVERDRIVENDARKBLUE,
            dark: COLORCONSTANTS.EVERDRIVENDARKBLUE80,
            contrastText: COLORCONSTANTS.WHITE
        },
        secondary: {
            main: COLORCONSTANTS.EVERDRIVENLIGHTBLUE,
            dark: COLORCONSTANTS.EVERDRIVENDARKBLUE
        },
        alternativeButton: {
            main: COLORCONSTANTS.EVERDRIVENLIGHTBLUE80,
            dark: COLORCONSTANTS.EVERDRIVENLIGHTBLUE,
            contrastText: COLORCONSTANTS.BLACK
        },
        tabSiblingNonSelected: {
            main: COLORCONSTANTS.EVERDRIVENDARKBLUE60,
            dark: COLORCONSTANTS.EVERDRIVENDARKBLUE,
            contrastText: COLORCONSTANTS.WHITE
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        black: COLORCONSTANTS.BLACK,
        white: COLORCONSTANTS.WHITE,
        transparent: 'transparent',
        offWhite: COLORCONSTANTS.OFFWHITE,
        everDrivenGrey: COLORCONSTANTS.EVERDRIVENGREY,
        everDrivenGrey30: COLORCONSTANTS.EVERDRIVENGREY30,
        everDrivenGrey40: COLORCONSTANTS.EVERDRIVENGREY40,
        everDrivenGrey60: COLORCONSTANTS.EVERDRIVENGREY60,
        everDrivenGrey80: COLORCONSTANTS.EVERDRIVENGREY80,
        everDrivenDarkGrey: COLORCONSTANTS.EVERDRIVENDARKGREY,
        everDrivenLightBlue: COLORCONSTANTS.EVERDRIVENLIGHTBLUE,
        everDrivenLightBlue20: COLORCONSTANTS.EVERDRIVENLIGHTBLUE20,
        everDrivenLightBlue40: COLORCONSTANTS.EVERDRIVENLIGHTBLUE40,
        everDrivenLightBlue60: COLORCONSTANTS.EVERDRIVENLIGHTBLUE60,
        everDrivenLightBlue80: COLORCONSTANTS.EVERDRIVENLIGHTBLUE80,
        everDrivenDarkBlue: COLORCONSTANTS.EVERDRIVENDARKBLUE,
        everDrivenDarkBlue60: COLORCONSTANTS.EVERDRIVENDARKBLUE60,
        everDrivenDarkBlue80: COLORCONSTANTS.EVERDRIVENDARKBLUE80,
        everDrivenSeaGreen40: COLORCONSTANTS.EVERDRIVENSEAGREEN40,
        everDrivenDarkGreen: COLORCONSTANTS.EVERDRIVENDARKGREEN,
        everDrivenFuchsia: COLORCONSTANTS.EVERDRIVENFUCHSIA,
        everDrivenFuchsia40: COLORCONSTANTS.EVERDRIVENFUCHSIA40,
        everDrivenCharcoal: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL,
        everDrivenDarkCharcoal10: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL10,
        everDrivenDarkCharcoal60: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL60,
        everDrivenDarkCharcoal80: COLORCONSTANTS.EVERDRIVENDARKCHARCOAL80,
        
        // Trip card colors
        grayOption1: COLORCONSTANTS.GREYOPTION1,
        grayOption2: COLORCONSTANTS.GREYOPTION2,
        lightBlueOption1: COLORCONSTANTS.LIGHTBLUEOPTION1,
        tealOption1: COLORCONSTANTS.TEALOPTION1,
        tealOption2: COLORCONSTANTS.TEALOPTION2,
        greenOption1: COLORCONSTANTS.GREENOPTION1,
        greenOption2: COLORCONSTANTS.GREENOPTION2,
        greenOption3: COLORCONSTANTS.GREENOPTION3,
        yellowOption1: COLORCONSTANTS.YELLOWOPTION1,
        orangeOption1: COLORCONSTANTS.ORANGEOPTION1

        // New Trip Status Colors
        /*  yellowOption1 - Early, Late
            everDrivenLightBlue - En Route, At Pickup, On Board
            everDrivenGrey - Scheduled, Active, Accepted
            everDrivenFuchsia - No Show, Canceled
            everDrivenDarkGreen - Completed */

    },
    leftMenuContainer: {
        background: `${COLORCONSTANTS.EVERDRIVENLIGHTBLUE} 0% 0% no-repeat padding-box`,
        boxShadow: `2px 0px 7px ${alpha(COLORCONSTANTS.EVERDRIVENGREY60, 0.52)}`,
        zIndex: 2,
        position: 'fixed'
    },
    typography: {
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: 10,
        body1: {
            fontSize: 16
        },
        body2: {
            fontSize: 10
        },
        button: {
            fontSize: 14
        },
        boxShadow: `0px 3px 6px ${alpha(COLORCONSTANTS.EVERDRIVENGREY40, 0.29)}`,
        h1: {
            fontWeight: 500,
            marginBottom: 10,
            lineHeight: 1.1,
            margin: '.67em 0'
        },
        h2: {
            fontWeight: 300,
            fontSize: 30,
            lineHeight: 1.1
        },
        h3: {
            color: COLORCONSTANTS.BLACK,
            fontWeight: 'Bold',
            fontSize: 20,
            letterSpacing: 0,
            opacity: 1,
            marginTop: 20,
            marginBottom: 10
        },
        h4: {
            fontWeight: 300,
            fontSize: 20,
            marginTop: 10,
            marginBottom: 10,
            fontFamily: 'inherit',
            lineHeight: 1.1,
            color: 'inherit'
        },
        h5: {
            marginTop: 10,
            marginBottom: 10,
            fontSize: 14,
            fontFamily: 'inherit',
            fontWeight: 500,
            lineHeight: 1.1,
            color: 'inherit'
        },
        caption: {
            fontSize: 18
        }
    }
});