import React from 'react';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import breakpointsNames from '../../../styles/ResponsiveConstants';

const useStyles = makeStyles((theme) =>({
    paperWidthLg: {
        maxWidth: 1800,
        width: 1410,
        borderRadius: 10,
        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
            minWidth: 1410
        },
        [theme.breakpoints.down(breakpointsNames.xl)]: {
            minWidth: 1150
        },
        "@media only screen and (max-width: 1470px)": {
            width: 1150
        },
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            width: 900,
            minWidth: 900
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            minWidth: 500,
            width: 500
        }
    },
    paperWidthMd: {
        width: 640
    },
    paperWidthXs: {
        width: 400
    }
}));

function StyledDialogModal({ open, onClose, maxWidth, children, 'aria-labelledby': ariaLabel }) {
    const classes = useStyles();

    return (
        <Dialog
            disableScrollLock
            classes={classes}
            open={open}
            onClose={onClose}
            maxWidth={maxWidth ?? 'sm'}
            data-testid={ariaLabel}
            aria-labelledby={ariaLabel}
        >
            {children}
        </Dialog>
    );
}

export default StyledDialogModal;
