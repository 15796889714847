import React, {
    useEffect,
    useState
} from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { parseColor } from 'util/colorUtil';
import RRMap from "../common/RRMap";
import Marker from "../tracktrip/Marker";
import { carIcon, carBackground, nullCarIcon } from "../../styles/MapStyles";
import { Enum, getKeyByValue } from "../../util/EnumHelper";
import { groupByMap } from "../../util/arrayUtil";

const FACTOR = 1000000;

function FacilityMap({
    facility,
    trips,
    height,
    facilityId
}) {
    const [ markerArray, setMarkerArray ] = useState([]);

    const getFacilityLocation = () => {
        const c = facility.coordinates;

        return { lat: c.lat / FACTOR, lng: c.lng / FACTOR };
    };

    const getTripLocation = (trip) => {
        const c = trip.Coordinates;

        return { lat: c.Latitude / FACTOR, lng: c.Longitude / FACTOR };
    };

    const groupedTrips = groupByMap(
        trips,
        trip => trip.VehicleRunID
    );

    useEffect(() => {
        const vehicleIds = [...new Set(trips.map(x => x.VehicleRunID))];
        const markers = [];
        
        setMarkerArray(markers);

        for (let i = 0; i < vehicleIds.length; i += 1) {
            const tripList = groupedTrips[vehicleIds[i]];
            const trip = tripList[0];
            const loc = getTripLocation(trip);
            const rotation = trip.DriverInfo ? trip.Heading : 0;
            const markerColor = parseColor(trip);

            const forgroundIcon = {
                ...carIcon,
                rotation,
                fillColor: markerColor
            };
            const backgroundIcon = { ...carBackground, rotation };

            if (!trip.Heading) {
                let pathIc = 'M52.8,26.6c0,24.5-23.6,48.5-24.6,49.5c-0.5,0.5-1.1,0.8-1.8,';

                pathIc = `${pathIc}0.8s-1.3-0.3-1.8-0.8C23.6,75.1,0,51.1,0,26.6 C0,12,11.`;
                pathIc = `${pathIc}9,0.2,26.4,0.2C41,0.2,52.8,12,52.8,26.6z`;
                const ic = {
                    path: pathIc,
                    fillOpacity: 1,
                    strokeOpacity: 0,
                    fillColor: "black",
                    label: '3',
                    scale: 0.6,
                    labelOriginX: 25,
                    labelOriginY: 20,
                    anchorX: 28,
                    anchorY: 76
                };

                markers.push(
                    <Marker
                        facilityId= {facilityId}
                        key={i}
                        id={i}
                        position={loc}
                        icon={{ ...ic, fillColor: markerColor }}
                        onClick
                        tripList={tripList}
                        onBlur
                    />
                );

                markers.push(
                    <Marker
                        facilityId= {facilityId}
                        key={`${i}-back`}
                        id={`${i}-back`}
                        position={loc}
                        icon={nullCarIcon}
                        onClick
                        tripList={tripList}
                        onBlur
                    />
                );
            } else {
                markers.push(
                    <Marker
                        facilityId= {facilityId}
                        key={`${i}-back`}
                        id={`${i}-back`}
                        position={loc}
                        icon={backgroundIcon}
                        tripList={tripList}
                        onBlur />
                );
                markers.push(
                    <Marker
                        facilityId= {facilityId}
                        key={i}
                        id={i}
                        position={loc}
                        icon={forgroundIcon}
                        onClick
                        tripList={tripList}
                        onBlur
                    />
                );
            }
            setMarkerArray(markers);
        }
    }, [trips]);

    return (
        <div>
            <RRMap height={height} facility={getFacilityLocation()}>
                {markerArray}
            </RRMap>
        </div>
    );
}

const STATES_TO_NOT_MAP_RECENTS = [
    Enum.TripState.Canceled,
    Enum.TripState.NoShow,
    Enum.TripState.Completed
];

function mapStateToProps(state, ownProps) {
    return {
        facility: state.FacilityReducers.facilities.find(f => f.id === ownProps.facilityId),
        height: ownProps.height,
        trips: ownProps.trips.filter(t => {
            // First get rid of everything with no location
            if(!t.Coordinates) {
                return false;
            }

            // Now hide any trip that's one of the recents
            if(STATES_TO_NOT_MAP_RECENTS.indexOf(t.State) > -1) {
                window.info(`Hidding Trip ${t.TripNumber} has a state of ${getKeyByValue(Enum.TripState,
                    t.State)}`);

                return false;
            }

            window.info(`Showing Trip ${t.TripNumber} has a state of ${getKeyByValue(Enum.TripState,
                t.State)}`);

            return true;
        })
    };
}
const ConnectedFacilityMap = connect(mapStateToProps)(injectIntl(FacilityMap));

export default ConnectedFacilityMap;
