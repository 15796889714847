import {
    SET_TR_STUDENT_ERRORS,
    SET_TR_CONTACTS_ERRORS,
    SET_TR_TRIP_BUILDER_ERRORS
} from 'redux/actions/transportationRequest/transportationRequestErrorsActions';

const transportationRequestErrorsInitialState = {
    studentErrors: {},
    contactsErrors: {},
    tripBuilderErrors: []
};

/* eslint-disable-next-line default-param-last */
const transportationRequestErrorsReducers = (state = transportationRequestErrorsInitialState, action) => {
    switch (action.type) {
    case SET_TR_STUDENT_ERRORS:
        return {
            ...state,
            studentErrors: action.studentErrors
        };
    case SET_TR_CONTACTS_ERRORS:
        return {
            ...state,
            contactsErrors: action.contactsErrors
        };
    case SET_TR_TRIP_BUILDER_ERRORS:
        return {
            ...state,
            tripBuilderErrors: action.tripBuilderErrors
        };
    default:
        return state;
    }
};

export default transportationRequestErrorsReducers;