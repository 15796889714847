const colorConstants = {
    
    EVERDRIVENDARKBLUE: '#26265E',
    EVERDRIVENDARKBLUE60: '#7D7D9E',
    EVERDRIVENDARKBLUE80: '#51517E',
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    EVERDRIVENDARKCHARCOAL: '#333333',
    EVERDRIVENDARKCHARCOAL10: '#3333331A',
    EVERDRIVENDARKCHARCOAL60: '#33333399',
    EVERDRIVENDARKCHARCOAL80: '#333333CC',
    OFFWHITE: '#F5F5F5',
    EVERDRIVENLIGHTBLUE: '#3673B0',
    EVERDRIVENLIGHTBLUE20: '#D7E3EF',
    EVERDRIVENLIGHTBLUE40: '#AEC7DF',
    EVERDRIVENLIGHTBLUE60: '#86ABD0',
    EVERDRIVENLIGHTBLUE80: '#5E8FC0',
    WEBKITTEXTFILLCOLOR: '#000000a6',
    EVERDRIVENDARKGREY: '#5C5C5C',
    EVERDRIVENGREY: '#788492',
    EVERDRIVENGREY30: '#7884924D',
    EVERDRIVENGREY40: '#C9CED3',
    EVERDRIVENGREY60: '#AEB5BE',
    EVERDRIVENGREY80: '#939DA8',
    EVERDRIVENSEAGREEN40: '#B2E9D9',
    EVERDRIVENDARKGREEN: '#367E37',
    EVERDRIVENFUCHSIA: '#C24269',
    EVERDRIVENFUCHSIA40: '#E7B3C3',

    // Trip card colors
    GREYOPTION1: '#748592',
    GREYOPTION2: '#929292',
    LIGHTBLUEOPTION1: '#1385B7',
    GREENOPTION1: '#168039',
    TEALOPTION1: '#13959F',
    TEALOPTION2: '#139B97',
    GREENOPTION2: '#13B078',
    GREENOPTION3: '#13C658',
    YELLOWOPTION1: '#FFA806',
    ORANGEOPTION1: '#D84B00',
    BLUEOPTION1: '#2585fc',
    REDOPTION1: '#ff0000'
};

export default colorConstants;
