import React from "react";
import {
    Search as SearchIcon,
    Close as CloseIcon
} from '@mui/icons-material';
import { TextField, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { func, string } from 'prop-types';

export const StyledTextField = styled(TextField)(({ theme }) => ({
    fontFamily: 'MuseoSans500',
    fontSize: '14px',
    width: '250px',
    '& ::placeholder': {
        color: theme.palette.everDrivenDarkCharcoal60,
        opacity: 1,
        fontSize: '14px',
        fontWeight: 500
    },
    '& ::before' : {
        borderBottom: `1px solid ${theme.palette.everDrivenDarkCharcoal60} !important`
    },
    '& .MuiInput-input': {
        paddingLeft: theme.spacing(1),
        fontFamily: 'MuseoSans500',
        fontSize: '14px',
        fontWeight: 500,
        color: theme.palette.everDrivenDarkCharcoal80
    }
}));

export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
    cursor: 'pointer',
    margin: theme.spacing(-0.5),
    color: theme.palette.everDrivenDarkCharcoal80,
    fontSize: '16px'
}));

export const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
    cursor: 'pointer',
    margin: theme.spacing(-0.5),
    color: theme.palette.everDrivenDarkCharcoal80,
    fontSize: '16px'
}));

function StyledSearchBar ({performSearch, placeholder, value, testId}) {
    return (
        <StyledTextField
            inputProps={{
                "data-testid": testId
            }}
            InputProps={{
                endAdornment: value && value.length > 0 && (
                    <IconButton
                        onClick={() => performSearch('')}
                    >
                        <StyledCloseIcon />
                    </IconButton>
                ),
                startAdornment: (
                    <IconButton>
                        <StyledSearchIcon />
                    </IconButton>
                )
            }}
            placeholder={placeholder || ""}
            type="search"
            value={value}
            variant="standard"
            onChange={(e) => performSearch(e.target.value)}
        />
    );
}

StyledSearchBar.defaultProps = {
    performSearch: () => null,
    placeholder: "",
    value: ""

};

/* eslint-disable react/forbid-prop-types */
StyledSearchBar.propTypes = {
    performSearch: func,
    placeholder: string,
    value: string
};
/* eslint-enable react/forbid-prop-types */

export default StyledSearchBar;