import { connect } from 'react-redux';
import {
    setNumberOfContacts,
    setShowRemoveContactModal
} from 'redux/actions/transportationRequest/transportationRequestActions';
import {
    onLoadRemoveContactModal,
    onRemoveContactSection,
    onUpdateModel
} from 'redux/workers/transportationRequest/transportationRequestWorker';
import ContactInformation from 'components/TransportationRequest/ContactInformation/ContactInformation';

const mapStateToProps = state => {
    const {
        TransportationRequestReducers,
        TransportationRequestErrorsReducers,
        TransportationRequestModalReducers
    } = state;

    return {
        emailErrors: TransportationRequestReducers.emailErrors,
        numberOfContacts: TransportationRequestReducers.numberOfContacts,
        showRemoveContactModal: TransportationRequestModalReducers.showRemoveContactModal,
        tripModel: TransportationRequestReducers.tripModel,
        errors: TransportationRequestErrorsReducers.contactsErrors
    };
};

const ContactInformationContainer = connect(mapStateToProps,
    {
        onLoadRemoveContactModal,
        onRemoveContactSection,
        onUpdateModel,
        setNumberOfContacts,
        setShowRemoveContactModal
    })(ContactInformation);

export default ContactInformationContainer;
