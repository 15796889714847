import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import TextMaskPhone from 'widgets/common/TextMaskPhone';
import { commonStyles } from 'styles/CommonStyles';
import StyledFormControl from '../StyledFormControl/StyledFormControl';
import StyledInput from '../StyledInput/StyledInput';

const PhoneInput = styled(StyledInput)(({ theme }) => ({
    ...commonStyles(theme).newBaseInput,
    "& .Mui-error::after": {
        borderBottomColor: theme.palette.transparent
    }
}));

function StyledPhoneNumber({
    disabled,
    idMobilePhone,
    inputProps,
    isRequired,
    modelInput,
    handleChangeModel,
    error
}) {
    return (
        <StyledFormControl
            fullWidth
            isNewStyle
            required={isRequired}
        >
            <PhoneInput
                id={idMobilePhone}
                autoComplete="disabled"
                fullWidth
                placeholder="(000) 000-0000"
                value={modelInput}
                onChange={handleChangeModel}
                name={idMobilePhone}
                inputComponent={TextMaskPhone}
                inputProps={inputProps}
                isDisabled={disabled}
                isError={error}
            />
        </StyledFormControl>
    );
}

StyledPhoneNumber.defaultProps = {
    disabled: false,
    inputProps: null,
    isRequired: false,
    modelInput: "",
    handleChangeModel: null
};

/* eslint-disable react/forbid-prop-types */
StyledPhoneNumber.propTypes = {
    disabled: PropTypes.bool,
    idMobilePhone: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
    isRequired: PropTypes.bool,
    modelInput: PropTypes.string,
    handleChangeModel: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default StyledPhoneNumber;
