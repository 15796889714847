import React from "react";
import {
    bool,
    func,
    node,
    string
} from 'prop-types';
import { makeStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 0,
        paddingBottom: 0
    }
}));

function StyledListItem({
    button,
    onClick,
    disableGutters,
    className,
    children,
    testId
}) {
    const classes = useStyles();

    return (
        <ListItem
            classes={classes}
            button={button}
            onClick={onClick}
            disableGutters={disableGutters}
            className={className}
            data-testid={testId}>
            {children}
        </ListItem>
    );
}

StyledListItem.defaultProps = {
    button: false,
    disableGutters: false,
    className: null,
    onClick: () => null,
    testId: ""
};

/* eslint-disable react/forbid-prop-types */
StyledListItem.propTypes = {
    button: bool,
    disableGutters: bool,
    className: string,
    children: node.isRequired,
    onClick: func,
    testId: string
};
/* eslint-enable react/forbid-prop-types */

export default StyledListItem;