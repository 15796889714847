import React from 'react';
import { Grid, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { localize } from '../../../util/Localizer';
import StyledCheckbox from '../../../components/Common/StyledCheckbox/StyledCheckbox';

function TransportationNeeds({
    transportationEquipmentNeeds,
    onUpdateTransportationEquipmentNeed,
    setIsSameTripInfo
}) {
    const handleChangeTransportationEquipmentNeeds = (evt) => {
        if (evt === null) {
            return false;
        }
        
        setIsSameTripInfo(false);
        onUpdateTransportationEquipmentNeed(evt);

        return true;
    };

    return (
        <Grid container>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="needsMonitorNeeded"
                    size="medium"
                    control={<StyledCheckbox
                        name="monitorNeeded"
                        color="secondary"
                        isDisabled={transportationEquipmentNeeds.notApplicable}
                        isChecked={transportationEquipmentNeeds.monitorNeeded}
                        size="medium"
                        onChange={handleChangeTransportationEquipmentNeeds}
                    />}
                    label={localize('tripRequestForm.monitorNeeded')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="needsBooster"
                    size="medium"
                    control={<StyledCheckbox
                        name="booster"
                        color="secondary"
                        isDisabled={transportationEquipmentNeeds.notApplicable || transportationEquipmentNeeds.carSeat}
                        isChecked={transportationEquipmentNeeds.booster}
                        size="medium"
                        onChange={handleChangeTransportationEquipmentNeeds}
                    />}
                    label={localize('tripRequestForm.booster')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="needsCarSeat"
                    size="medium"
                    control={<StyledCheckbox
                        name="carSeat"
                        color="secondary"
                        isDisabled={
                            transportationEquipmentNeeds.notApplicable ||
                            transportationEquipmentNeeds.booster ||
                            transportationEquipmentNeeds.safetyVest
                        }
                        isChecked={transportationEquipmentNeeds.carSeat}
                        size="medium"
                        onChange={handleChangeTransportationEquipmentNeeds}
                    />}
                    label={localize('tripRequestForm.carSeat')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="needsMustRideAlone"
                    size="medium"
                    control={<StyledCheckbox
                        name="mustRideAlone"
                        color="secondary"
                        isDisabled={transportationEquipmentNeeds.notApplicable}
                        isChecked={transportationEquipmentNeeds.mustRideAlone}
                        size="medium"
                        onChange={handleChangeTransportationEquipmentNeeds}
                    />}
                    label={localize('tripRequestForm.mustRideAlone')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="needsWheelchair"
                    size="medium"
                    control={<StyledCheckbox
                        name="wheelchair"
                        color="secondary"
                        isDisabled={transportationEquipmentNeeds.notApplicable}
                        isChecked={transportationEquipmentNeeds.wheelchair}
                        size="medium"
                        onChange={handleChangeTransportationEquipmentNeeds}
                    />}
                    label={localize('tripRequestForm.wheelchair')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="needsSafetyVest"
                    size="medium"
                    control={<StyledCheckbox
                        name="safetyVest"
                        color="secondary"
                        isDisabled={transportationEquipmentNeeds.notApplicable || transportationEquipmentNeeds.carSeat}
                        isChecked={transportationEquipmentNeeds.safetyVest}
                        size="medium"
                        onChange={handleChangeTransportationEquipmentNeeds}
                    />}
                    label={localize('tripRequestForm.safetyVest')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="needsBuckleGuard"
                    size="medium"
                    control={<StyledCheckbox
                        name="buckleGuard"
                        color="secondary"
                        isDisabled={transportationEquipmentNeeds.notApplicable}
                        isChecked={transportationEquipmentNeeds.buckleGuard}
                        size="medium"
                        onChange={handleChangeTransportationEquipmentNeeds}
                    />}
                    label={localize('tripRequestForm.buckleGuard')}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    data-testid="needsNotApplicable"
                    size="medium"
                    control={<StyledCheckbox
                        name="notApplicable"
                        color="secondary"
                        isChecked={transportationEquipmentNeeds.notApplicable}
                        size="medium"
                        onChange={handleChangeTransportationEquipmentNeeds}
                    />}
                    label={localize('tripRequestForm.notApplicable')}
                />
            </Grid>
            <Grid item xs={4} />
        </Grid>
    );
}

TransportationNeeds.defaultProps = {
    transportationEquipmentNeeds: null,
    onUpdateTransportationEquipmentNeed: null,
    setIsSameTripInfo: null
};

/* eslint-disable react/forbid-prop-types */
TransportationNeeds.propTypes = {
    transportationEquipmentNeeds: PropTypes.object,
    onUpdateTransportationEquipmentNeed: PropTypes.func,
    setIsSameTripInfo: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default TransportationNeeds;
