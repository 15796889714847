import { connect } from 'react-redux';
import SegmentInfo from 'components/TransportationRequest/TripBuilder/Segment/SegmentInfo/SegmentInfo';
import { trTripUpdateSegment } from 'redux/workers/transportationRequest/transportationRequestTripsWorker';
import { dataSegment } from 'util/data/TripBuilderDecorator';
import {
    TransportationRequestTripTypesOptions as TripTypesOptions,
    TransportationRequestSegmentTypes as SegmentTypes
} from 'data/models/transportationRequest/TripBuilderModel';

const mapStateToProps = (state, ownProps) => {
    const {
        TransportationRequestReducers,
        TransportationRequestErrorsReducers
    } = state;
    const { trips } = TransportationRequestReducers;
    const { tripBuilderErrors } = TransportationRequestErrorsReducers;

    return {
        disabled: (ownProps.segment === SegmentTypes.departure
            && trips[ownProps.currentTrip].tripTypes.includes(TripTypesOptions.roundTrip)),
        segments: dataSegment(trips[ownProps.currentTrip], ownProps.segment),
        errors: tripBuilderErrors[ownProps.currentTrip] ?? null
    };
};

const mapDispatchToProps = {
    onChange: trTripUpdateSegment
};

const SegmentInfoContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SegmentInfo);

export default SegmentInfoContainer;
