import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { localize } from 'util/Localizer';
import StyledTextField from 'components/Common/StyledTextField/StyledTextField';

const RRTimePickerTextField = styled(StyledTextField)(({ theme }) => ({
    '& > div > input': {
        padding: '10px 6px',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    "& .Mui-error": {
        color: theme.palette.everDrivenFuchsia
    },
    "& .MuiFormHelperText-root": {
        color: theme.palette.everDrivenFuchsia
    }
}));

function RRTimePicker({ isNewStyle, controlId, controlValue, disabled, isValid, onChangeTime, fontSize }) {
    return (
        <RRTimePickerTextField
            isNewStyle={isNewStyle}
            FormHelperTextProps={{
                style: { fontSize: 10 }
            }}
            helperText={localize('tripRequestForm.invalidTimeMessage')}
            id={controlId}
            inputProps={{
                "data-testid": controlId,
                step: 300 // 5 min,
            }}
            InputProps={{
                style: { fontSize }
            }}
            isDisabled={disabled}
            isError={!isValid}
            name={controlId}
            type="time"
            value={controlValue}
            onBlur={(evt) => onChangeTime(evt,
                controlId
            )}
            onChange={(evt) => onChangeTime(evt,
                controlId
            )}
        />
    );
}

RRTimePicker.defaultProps = {
    isNewStyle: false,
    controlId: null,
    controlValue: null,
    disabled: false,
    isValid: true,
    onChangeTime: null,
    fontSize: 12

};

/* eslint-disable react/forbid-prop-types */
RRTimePicker.propTypes = {
    isNewStyle: PropTypes.bool,
    controlId: PropTypes.string,
    controlValue: PropTypes.string,
    disabled: PropTypes.bool,
    isValid: PropTypes.bool,
    onChangeTime: PropTypes.func,
    fontSize: PropTypes.number

};
/* eslint-enable react/forbid-prop-types */

export default RRTimePicker;
