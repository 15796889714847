import { connect } from 'react-redux';

import EmergencyContactInfo from '../../../controls/TripRequestForm/EmergencyContactInfo';

import { onUpdateModel } from '../../workers/tripRequestFormWorker';
import { setEmailErrors } from '../../actions/tripRequestFormActions';
import { setIsSameContact, onUpdateEmergencyContactPhone } from '../../workers/tripRequestFormEmergencyContactWorker';

const mapStateToProps = state => {
    const { TripRequestFormReducers } = state;

    return {
        currentSibling: TripRequestFormReducers.currentSibling,
        emailErrors: TripRequestFormReducers.emailErrors,
        isSameContacts: TripRequestFormReducers.isSameContacts,
        tripModel: TripRequestFormReducers.tripModel
    };
};

const EmergencyContactInfoContainer = connect(mapStateToProps,
    {
        onUpdateEmergencyContactPhone,
        onUpdateModel,
        setEmailErrors,
        setIsSameContact
    })(EmergencyContactInfo);

export default EmergencyContactInfoContainer;
