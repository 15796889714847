export const UPDATE_PATH = 'UPDATE_PATH';

export const updateActiveItem = (activeItem) => ({
    type: UPDATE_PATH,
    activeItem
});

export const SET_LOGOUT_ON = 'SET_LOGOUT_ON';

export const setLogOutOn = (payload) => ({
    type: SET_LOGOUT_ON,
    payload
});