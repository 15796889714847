import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { closeStyledSnackBar } from '../../workers/styledSnackbarWrapperWorker';
import StyledSnackbarWrapper from '../../../components/Common/StyledSnackbarWrapper/StyledSnackbarWrapper';
import withRouter from '../../../util/customHooks/withRouter';

const mapStateToProps = state => {
    const { StyledSnackbarWrapperReducer } = state;

    return {
        message: StyledSnackbarWrapperReducer.message,
        severity: StyledSnackbarWrapperReducer.severity,
        openSnackBar: StyledSnackbarWrapperReducer.openSnackBar
    };
};

const StyledSnackbarWrapperContainer = withRouter(
    connect(
        mapStateToProps, {
            onClose: closeStyledSnackBar
        }
    )(injectIntl(StyledSnackbarWrapper))
);

export default StyledSnackbarWrapperContainer;
