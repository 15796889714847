import React from 'react';
import { Grid, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import StyledFormLabel from 'components/Common/StyledFormLabel/StyledFormLabel';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import CommonStyles from 'styles/CommonStyles';
import { localize } from 'util/Localizer';
import colorConstants from 'styles/ColorConstants';
import StyledTimePicker from 'components/Common/StyledTimePicker/StyledTimePicker';
import { museoSansFont } from 'util/EnumHelper';

function SegmentTimes({
    segment,
    times,
    title,
    multipleTimes,
    showNotes,
    showMultipleTimesButton,
    errors,
    currentTrip,
    isDisabledMultipleButton,
    singleTime,
    onChange,
    onChangeTime,
    setMultipleTimes,
    setShowSegmentNotes
}) {
    const handleMultipleTimesChange = (name, value) => {
        onChange(segment, name, value, currentTrip);
    };

    const handleChange = (value) => {
        onChangeTime(segment, value, currentTrip);
    };

    const renderNotesButton = () => (
        !showNotes &&
            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                <StyledButton
                    color="inherit"
                    style={{
                        ...CommonStyles.linkButton,
                        textWrap: 'nowrap',
                        alignItems: 'flex-end',
                        height: 26
                    }}
                    type="button"
                    startIcon={<AddIcon />}
                    variant="text"
                    testId={`${segment}-addSegmentNotesButton`}
                    onClick={() => setShowSegmentNotes(segment, !showNotes, currentTrip)}
                >
                    {localize('transportationRequest.addNotesSegment')}
                </StyledButton>
            </Grid>
    );

    const renderInputTimes = () => (
        multipleTimes &&
            <React.Fragment key="inputTypes">
                {times?.map(time => (
                    <Grid item key={time.id} xs={4}
                        sx={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}
                    >
                        <StyledFormLabel
                            label={time.label}
                            extraStyle={{ width: 55, height: 50, alignItems: 'center' }}
                            internalStyle={{ paddingTop: 7 }}
                        />
                        <StyledTimePicker
                            controlId={time.id}
                            controlValue={time.value ?? ''}
                            controlDateValue={time.value}
                            isValid={errors?.[`${segment}-${time.id}Time`]?.isError && time.value === ''}
                            fontSize={16}
                            onChangeTime={(evt) => handleMultipleTimesChange(time.id, evt.target.value)}
                        />

                    </Grid>
                ))}
                { showMultipleTimesButton &&
                    <Grid item xs={12} sx={{ display: 'flex', marginTop: '5px' }}>
                        <StyledButton
                            color="inherit"
                            style={{
                                ...CommonStyles.linkButton,
                                color: colorConstants.EVERDRIVENFUCHSIA,
                                alignItems: 'center'
                            }}
                            type="button"
                            startIcon={<CloseIcon style={{ fontSize: 16, paddingBottom: 1 }} />}
                            variant="text"
                            testId={`${segment}-removeMultipleTimes`}
                            onClick={() => setMultipleTimes(segment, !multipleTimes, currentTrip)}
                        >
                            {localize('transportationRequest.removeMultipleTimes')}
                        </StyledButton>
                    </Grid>
                }
                {renderNotesButton()}
            </React.Fragment>
    );

    const renderMultipleTimesButton = () => (
        showMultipleTimesButton &&
            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }} >
                <StyledButton
                    color="inherit"
                    style={{
                        ...CommonStyles.linkButton,
                        textWrap: 'nowrap',
                        whiteSpace: 'nowrap',
                        alignItems: 'flex-end',
                        height: 26
                    }}
                    isDisabled={isDisabledMultipleButton}
                    type="button"
                    startIcon={<AddIcon />}
                    variant="text"
                    testId={`${segment}-setMultipleTimes`}
                    onClick={() => setMultipleTimes(segment, !multipleTimes, currentTrip)}
                >
                    {localize('transportationRequest.setMultipleTimes')}
                </StyledButton>
            </Grid>
    );

    const renderOneInput = () => (
        !multipleTimes &&
            <>
                <Grid item xs={3}>
                    <StyledTimePicker
                        controlId={`${segment}Time`}
                        controlValue={singleTime ?? ''}
                        controlDateValue={singleTime ?? ''}
                        isValid={errors?.[`${segment}SingleTime`]?.isError && singleTime === ''}
                        fontSize={16}
                        onChangeTime={(evt) => handleChange(evt.target.value)}
                    />
                </Grid>
                {renderMultipleTimesButton()}
                {renderNotesButton()}
            </>
    );

    return (
        <Grid container sx={{ marginTop: '17px' }}>
            <Grid container columnSpacing={4} sx={{ justifyContent: 'left' }}>
                <Grid item sx={{ whiteSpace: 'nowrap' }}>
                    <StyledFormLabel label={title} isRequired />
                </Grid>
                <Grid item sx={{ marginTop: '5px' }}>
                    <FormHelperText sx={{marginLeft: 0, fontFamily: museoSansFont}}>
                        {multipleTimes && errors && errors[`${segment}-Times`]?.message}
                    </FormHelperText>
                </Grid>

            </Grid>
            <Grid container columnSpacing={4}>
                {renderInputTimes()}
                {renderOneInput()}
            </Grid>
        </Grid>
    );
}

SegmentTimes.defaultProps = {
    showNotes: false,
    currentTrip: 0,
    singleTime: '',
    onChangeTime: null,
    multipleTimes: false,
    segment: '',
    times: [],
    onChange: null,
    title: '',
    showMultipleTimesButton: false,
    errors: null,
    setMultipleTimes: () => {},
    setShowSegmentNotes: () => {}
};

/* eslint-disable react/forbid-prop-types */
SegmentTimes.propTypes = {
    showNotes: PropTypes.bool,
    currentTrip: PropTypes.number,
    singleTime: PropTypes.string,
    onChangeTime: PropTypes.func,
    multipleTimes: PropTypes.bool,
    segment: PropTypes.string,
    title: PropTypes.string,
    times: PropTypes.array,
    onChange: PropTypes.func,
    showMultipleTimesButton: PropTypes.bool,
    errors: PropTypes.object,
    setMultipleTimes: PropTypes.func,
    setShowSegmentNotes: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default SegmentTimes;