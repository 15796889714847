import { connect } from 'react-redux';
import Segment from 'components/TransportationRequest/TripBuilder/Segment/Segment';
import {
    TransportationRequestSegmentTypes as SegmentTypes,
    TransportationRequestTripTypesOptions as TripTypesOptions
} from 'data/models/transportationRequest/TripBuilderModel';
import { trTripUpdateSegment } from 'redux/workers/transportationRequest/transportationRequestTripsWorker';
import { localize } from 'util/Localizer';
    
const mapStateToProps = (state, ownProps) => {
    const { TransportationRequestReducers } = state;
    const { trips } = TransportationRequestReducers;

    return {
        title: trips[ownProps.currentTrip].tripTypes.includes(TripTypesOptions.oneWay)
            ? ''
            : localize(`transportationRequest.${ownProps.segmentType}Title`),
        show: !(ownProps.segmentType === SegmentTypes.departure
                && trips[ownProps.currentTrip].tripTypes.includes(TripTypesOptions.oneWay))
    };
};

const mapDispatchToProps = {
    onChange: trTripUpdateSegment
};

const SegmentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Segment);

export default SegmentContainer;
