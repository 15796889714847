/**
 * The purpose of this file is to allow for overriding any of the values
 * within the theme config file with ones appropriate for the environment.
 *
 * While there is a good chance that this will only apply to the apiUrl, as
 * the application grows, we may want to override other variables, this allows
 * for that functionality.
 */
const Env = {
    configUrl: 'https://ridemanager.everdriven.com/api/v2/Config',
    validateUrl: 'https://usc-ridemanager-externalutility.azurewebsites.net/api/v2/validate',
    applicationInsightsKey: '75543dca-221a-4eb6-974d-3ca029c455df',
    auth: {
        clientId: "ab360e8b-4f40-4438-b662-bee558e07667", // Web App "Application ID" in B2C Tenant
        authoritySub: "alcridemanager",
        signInFlow: "B2C_1_New_SignIn",
        resetFlow: "B2C_1_New_PasswordReset",
        scope: "https://alcridemanager.onmicrosoft.com/ridemanager/user_impersonation" // user_impersonation" URL from Web App > API permissions (B2C Tenant)
    },
    areReportsEnabled: true,
    nodeEnv: 'prod-alc'
}

export default Env;