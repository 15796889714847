import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { initialSetup } from 'redux/workers/districtTripTracker/districtTripTrackerWorker';
import DistrictTripTracker from '../../../screens/DistrictTripTracker/DistrictTripTracker';

const mapStateToProps = state => {
    const {
        StyledHeaderReducers
    } = state;

    return {
        filterOption: StyledHeaderReducers.option
    };
};

const mapDispatchToProps = {
    initialSetup
};

const DistrictTripTrackerContainer =
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(DistrictTripTracker));

export default DistrictTripTrackerContainer;
