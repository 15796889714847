export default {
    idleTimeoutInSeconds: 1800,
    commandTimeouts: {
        cancel: 5
    },
    recentSeconds: 900,
    tripTimeTimer: 900000,
    trackTripWaitTimeoutInMillis: 8000, // Remove when we do Track Trip
    logDebug: true,
    configLoaded: false
};
