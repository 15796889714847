import { Enum } from './EnumHelper';

export const validationPhoneNumber = (fieldValue) =>{
    const regexpPhone = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

    return regexpPhone.test(fieldValue);
};

export const isPhoneNumber = (fieldValue)=> {
    if (fieldValue) {
        if (fieldValue === '(') {
            return false;
        }

        const values = fieldValue.split('');
        let anyNumber = false;
        const breakException = {};

        try {
            values.forEach(item=>{
                if(/[0-9]/.test(item)) {
                    anyNumber = true;
                    throw breakException;
                }
            });
        } catch(e) {
            if (e !== breakException) {
                throw e;
            }
        }
    
        return anyNumber? validationPhoneNumber(fieldValue): true;
    }
    
    return true;
};

export const hasPendingCancellation = (trip) => {
    if (!trip.commands || trip.commands === null) {
        return false;
    }
    const tripStatus = Enum.TripState[trip.status];

    if (tripStatus === Enum.TripState.Scheduled ||
        tripStatus === Enum.TripState.Active ||
        tripStatus === Enum.TripState.Accepted) {
        return (trip.commands.findIndex(command => command.action === "Cancel" || command.Action === "Cancel") > -1);
    }

    return false;
};

export const formatPhoneNumber = (str) => {
    try {
        let cleaned = str.match(/\d/g);

        cleaned = cleaned.join("");
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return [ '(', match[2], ') ', match[3], '-', match[4] ].join('');
        }

        return null;
    } catch (error) {
        window.warn(`Invalid Phone Number: ${str}`, error);

        return str;
    }
};

export const objectEmptyValidator = (obj) => Object.keys(obj).length === 0;

export const acceptedFiles = {
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/msword': ['.doc'],
    'application/vnd.oasis.opendocument.text': ['.odt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.adobe.photoshop': ['.psd'],
    'application/pdf': ['.pdf'],
    'image/*': [
        '.gif',
        '.ico',
        '.jpeg',
        '.jpg',
        '.png'
    ]
};

export const studentUploadAcceptedFiles = {
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'text/csv': ['.csv']
};

export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    
    return true;
};

export const isDateValid = (date) => date !== null && !Number.isNaN(date.getTime());

export const isOdd = (num) => !!(num % 2);

export const isTRFAddressValid = (address) => (
    address.selectedAddress?.trim() &&
    address.addressLine1?.trim() &&
    address.city?.trim() &&
    address.state?.trim() &&
    address.country?.trim()
);

export default validationPhoneNumber;
