export const TransportationRequestSegmentTypes = {
    arrival: 'arrival',
    departure: 'departure'
};

export const TransportationRequestRecurringTripOptions = {
    oneTime: 'oneTime',
    recurring: 'recurring'
};

export const TransportationRequestDatesOptions = {
    startDate: 'startDate',
    endDate: 'endDate'
};

export const TransportationRequestTripTypesOptions = {
    oneWay: 'oneWay',
    roundTrip: 'roundTrip',
    multiStop: 'multiStop'
};

export const TransportationRequestSegmentsOptions = {
    tripLeavingFrom: 'tripLeavingFrom',
    tripGoingTo: 'tripGoingTo',
    tripApartment: 'tripApartment',
    tripApartment2: 'tripApartment2',
    tripPhone: 'tripPhone',
    tripPhone2: 'tripPhone2'
};

export const days = {
    sunday: 'sunday',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday'
};

export const TransportationRequestDays = [
    {
        id: days.sunday,
        label: 'transportationRequest.sunday'
    },
    {
        id: days.monday,
        label: 'transportationRequest.monday'
    },
    {
        id: days.tuesday,
        label: 'transportationRequest.tuesday'
    },
    {
        id: days.wednesday,
        label: 'transportationRequest.wednesday'
    },
    {
        id: days.thursday,
        label: 'transportationRequest.thursday'
    },
    {
        id: days.friday,
        label: 'transportationRequest.friday'
    },
    {
        id: days.saturday,
        label: 'transportationRequest.saturday'
    }
];

export const TransportationRequestTimeDays = [
    {
        id: days.sunday,
        label: 'transportationRequest.sundayTime'
    },
    {
        id: days.monday,
        label: 'transportationRequest.mondayTime'
    },
    {
        id: days.tuesday,
        label: 'transportationRequest.tuesdayTime'
    },
    {
        id: days.wednesday,
        label: 'transportationRequest.wednesdayTime'
    },
    {
        id: days.thursday,
        label: 'transportationRequest.thursdayTime'
    },
    {
        id: days.friday,
        label: 'transportationRequest.fridayTime'
    },
    {
        id: days.saturday,
        label: 'transportationRequest.saturdayTime'
    }
];

export const TransportationRequestRecurringTrip = [
    {
        id: TransportationRequestRecurringTripOptions.oneTime,
        label: 'transportationRequest.oneTimeTrip'
    },
    {
        id: TransportationRequestRecurringTripOptions.recurring,
        label: 'transportationRequest.recurringTrip'
    }
];

export const TransportationRequestDates = [
    {
        id: TransportationRequestDatesOptions.startDate,
        label: 'transportationRequest.startDate'
    },
    {
        id: TransportationRequestDatesOptions.endDate,
        label: 'transportationRequest.endDate'
    }
];

export const TransportationRequestTripTypes = [
    {
        id: TransportationRequestTripTypesOptions.oneWay,
        label: 'transportationRequest.oneWay'
    },
    {
        id: TransportationRequestTripTypesOptions.roundTrip,
        label: 'transportationRequest.roundTrip'
    },
    {
        id: TransportationRequestTripTypesOptions.multiStop,
        label: 'transportationRequest.multiStop'
    }
];

export const TransportationRequestSegments = [
    {
        section: TransportationRequestSegmentsOptions.tripLeavingFrom,
        id: TransportationRequestSegmentsOptions.tripLeavingFrom,
        label: 'transportationRequest.leavingFrom'
    },
    {
        section: TransportationRequestSegmentsOptions.tripGoingTo,
        id: TransportationRequestSegmentsOptions.tripGoingTo,
        label: 'transportationRequest.goingTo'
    },
    {
        section: TransportationRequestSegmentsOptions.tripLeavingFrom,
        id: TransportationRequestSegmentsOptions.tripApartment,
        label: 'transportationRequest.apartment'
    },
    {
        section: TransportationRequestSegmentsOptions.tripGoingTo,
        id: TransportationRequestSegmentsOptions.tripApartment2,
        label: 'transportationRequest.apartment'
    },
    {
        section: TransportationRequestSegmentsOptions.tripLeavingFrom,
        id: TransportationRequestSegmentsOptions.tripPhone,
        label: 'transportationRequest.phoneNumber'
    },
    {
        section: TransportationRequestSegmentsOptions.tripGoingTo,
        id: TransportationRequestSegmentsOptions.tripPhone2,
        label: 'transportationRequest.phoneNumber'
    }
];

export const tripDays = {
    sunday: false,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false
};

export const tripTypes = {
    oneWay: false,
    roundTrip: false,
    multiStop: false
};

export const address = {
    selectedAddress: '',
    placeName: '',
    addressLine1: '',
    addressLine1Short: '',
    addressLine2: '',
    city: '',
    state: '',
    stateShort: '',
    postal: '',
    country: '',
    phone: ''
};

export const segment = {
    tripLeavingFrom: { ...address },
    tripGoingTo: { ...address }
};

export const daysTime = {
    sunday: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: ''
};

export const newTrip = () => ({
    account: '',
    recurring: TransportationRequestRecurringTripOptions.recurring,
    startDate: null,
    endDate: null,
    days: [ days.monday, days.friday, days.thursday, days.tuesday, days.wednesday ],
    tripTypes: [TransportationRequestTripTypesOptions.roundTrip],
    arrival: { ...segment },
    arrivalTime: { ...daysTime },
    arrivalSingleTime: '',
    arrivalShowNotes: false,
    arrivalNotes: '',
    departure:  { ...segment },
    departureTime: { ...daysTime },
    departureSingleTime: '',
    departureShowNotes: false,
    departureNotes: '',
    arrivalHasTimeMultiples: false,
    departureHasTimeMultiples: false,
    collapsed: false
});