import React, { useEffect } from 'react';

import {
    Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

import StyledLoading from '../Common/StyledLoading/StyledLoading';
import DPApp from '../DPApp';
import { localize } from '../../util/Localizer';

import Auth from '../../auth/Auth';

import StyledFacilityHeader from '../Common/StyledFacilityHeader/StyledFacilityHeader';
import StyledContentBox from '../Common/StyledContentBox/StyledContentBox';

import StyledTypography from '../Common/StyledTypography/StyledTypography';
import StyledBannerContent from '../Common/StyledBannerContent/StyledBannerContent';

import ExceptionTable from './ExceptionTable';

import commonUseStyles from '../../styles/CommonStyles';
import { formatPhoneNumber } from '../../util/validationUtil';
import { defaultContact } from '../../util/EnumHelper';

const MASTER_CALENDAR = "MASTER_CALENDAR";
const MASTER_CALENDAR_SUB = "MASTER_CALENDAR_SUB";
const NAMED_LOCATION = "NAMED_LOCATION";
const CLIENT = "CLIENT";
const CTT = "CTT";

const LEGEND = [
    "calendar.legendEs",
    "calendar.legendMs",
    "calendar.legendJhs",
    "calendar.legendHs",
    "calendar.legendPk",
    "calendar.legendEcc",
    "calendar.legendElc",
    "calendar.legendAtp"
];

const CalendarsContainerGrid = styled(Grid)(() => ({
    marginLeft: 0,
    marginTop: 10,
    marginBottom: 10,
    paddingBottom: 10,
    textAlign: 'center',
    width: '100%'
}));

const HeadersTitle = styled(StyledTypography)(() => ({
    marginBottom: 10,
    marginTop: 10,
    textAlign: 'left'
}));

const LegendParagraph = styled('p')(() => ({
    marginTop: 10,
    ...commonUseStyles.paragraphDefaultMargin
}));

const EmptyCalendarBox = styled('div')(() => ({
    marginTop: -152
}));

const LegendBox = styled('div')(() => ({
    marginBottom: 10,
    marginLeft: 20
}));

function Calendar({
    calendars,
    facility,
    facilityId,
    isLoading,
    initialLoad
}) {
    const renderCalendarsTitle = (calendar) => {
        switch (calendar.adjustmentEntityType) {
        case MASTER_CALENDAR:
            return calendar.adjustmentEntityTypeSource + localize("calendar.masterCalendarType");
        case CLIENT:
            return calendar.adjustmentEntityTypeSource + localize("calendar.clientType");
        case MASTER_CALENDAR_SUB:
        case NAMED_LOCATION:
        case CTT:
        default:
            return calendar.adjustmentEntityTypeSource;
        }
    };

    const renderResults = () => (
        calendars.map(calendar => (
            <CalendarsContainerGrid
                container
                key={calendar.key}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    <HeadersTitle>
                        {renderCalendarsTitle(calendar.value[0] ?? [])}
                    </HeadersTitle>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{width: '100%'}}
                >
                    <ExceptionTable CalendarExceptions={calendar.value} />
                </Grid>
            </CalendarsContainerGrid>
        ))
    );

    const renderEmpty = () => (
        <EmptyCalendarBox>
            <StyledBannerContent
                title={localize('calendar.noCalendarFound')}
                content={localize('calendar.instructionsCalendar')}
                show
            />
        </EmptyCalendarBox>
    );

    const renderLegend = () => {
        const accounts = Auth.getAccounts();
        const phoneNumbers = accounts.map(x => x.PhoneNumber).filter(x => x !== '');
        const emails = accounts.map(x => x.Email).filter(x => x !== '');

        return (
            <div>
                <p> {localize('calendar.note')} </p>
                <LegendBox>
                    {LEGEND.map(legend => (
                        <LegendParagraph key={legend}>
                            {localize(legend)}
                        </LegendParagraph>
                    ))}
                </LegendBox>
                <p>
                    {
                        localize('calendar.contact', {
                            email: emails[0] ?? '',
                            phone: phoneNumbers[0]?.trim().length > 0
                                ? formatPhoneNumber(phoneNumbers[0])
                                : defaultContact.phone
                        })
                    }
                </p>
            </div>
        );
    };

    const renderCalendars = () => (
        !isLoading && (
            <>
                <StyledFacilityHeader
                    facility={facility}
                    isCalendar
                />

                <StyledContentBox>
                    { calendars.length > 0
                        ? renderResults()
                        : renderEmpty()
                    }

                    {renderLegend()}
                    
                </StyledContentBox>
            </>
        )
    );

    const renderLoading = () => (
        isLoading && (
            <StyledContentBox>
                <StyledLoading
                    message={localize('calendar.loadingCalendar')}
                />
            </StyledContentBox>
        )
    );

    useEffect(() => {
        initialLoad(facilityId);
    }, [facilityId]);

    return (
        <DPApp>
            {renderLoading()}
            {renderCalendars()}
        </DPApp>
    );
}

export default Calendar;
