import { Enum } from "util/EnumHelper";
import mrmFetch from "util/fetchUtil";
import { fixedEncodeURIComponent } from "util/textFormatters";

const DEFAULT_SKIP = 1;
const DEFAULT_ORDER = 'last';
const DEFAULT_PAGE = 20;
const DEFAULT_ORDER_TYPE = Enum.Sorter.Asc;

// were going to handle cancelling the old queries here.
let controller = null;

export const studentTrips = async (guid, filterTo = 0, continuationToken = null) => {
    const response = await mrmFetch(
        `/api/v3/student/${guid}/Trips?FilterTo=${filterTo}&ContinuationToken=${continuationToken}`,
        'get'
    );

    window.log(`selected student ${guid}`);
    
    return response;
};

export const searchStudents = async (query, isTRF = false) => {
    let path = `/api/v2/Student?query=${fixedEncodeURIComponent(query)}`;

    if (isTRF) {
        path = `${path}&isTRF=true`;
    }

    if (controller) {
        controller.abort();
    }

    const { AbortController } = window;

    controller = new AbortController();
    
    const response = await mrmFetch(path, 'get', controller.signal);

    window.log(`found students`, response);
    
    return response;
};

// eslint-disable-next-line import/prefer-default-export
export const getStudents = async (
    pageSize = DEFAULT_PAGE,
    skip = DEFAULT_SKIP,
    orderType = DEFAULT_ORDER_TYPE,
    order = DEFAULT_ORDER
) => {
    const path = `/api/v2/MemberList?pageSize=${pageSize}&skip=${skip}&orderType=${orderType}&order=${order}`;
    const response = await mrmFetch(path, 'get');
    
    window.log(`found students`, response);

    return response;
};

export const searchStudentsByIds = async (body) => {
    const path = '/api/v2/MemberList';
    const response = await mrmFetch(
        path,
        'post',
        null,
        body
    );
    
    window.log(`found students`, response);

    return response;
};

export const getStudentProfile = async (id) => {
    const student = await mrmFetch(
        `/api/v2/Student/${id}/StudentProfile`,
        'GET'
    );

    return student;
};
