import { read, utils } from 'xlsx';
import moment from 'moment';

export default function readFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.onload = (event) => {
            resolve(event?.target?.result);
        };
        reader.onerror = (event) => {
            reader.abort();
            reject(event);
        };
        reader.readAsDataURL(file);
    });
}

export const nameNonASCIIValidator = (file) => [...file.name].some(char => char.charCodeAt(0) > 127);

export const downloadFile = (responseMessage, download=true) => {
    const file = window.URL.createObjectURL(responseMessage.blob);
    const link = document.createElement('a');

    link.href = file;
    // Choose to force download or browser default
    if (download) {
        link.download = responseMessage.fileName;
    } else {
        link.target = '_blank'; // Open in new tab
    }
    // we need to append the element to the dom -> otherwise it will not work in firefox
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const maxSizeFileToMegaBytes = (fileSize) => parseFloat(
    (fileSize / (1024 ** Math.floor(Math.log(fileSize) / Math.log(1024)))).toFixed(2)
);

const fixFormatData = (data) => {
    const newData = {
        ...data,
        'Student Date of birth':
        data['Student Date of birth (mm/dd/yyyy)']
            ? moment(data['Student Date of birth (mm/dd/yyyy)']).format('YYYY-MM-DD')
            : ""
    };

    delete newData['Student Date of birth (mm/dd/yyyy)'];

    return newData;
};

export const convertExcelToData = async (file, filterColumn) => {
    const f = await file.arrayBuffer();
    const wb = read(f);

    let ws = null;
    let data = [];
    let dataList = [];

    wb.SheetNames.forEach(sheetName => {
        ws = wb.Sheets[sheetName];
        data = utils.sheet_to_json(ws, {raw: false});
        data = data.map((element) => (
            fixFormatData(element))
        );
        if (data.filter(x => Object.hasOwn(x, filterColumn)).length > 0) {
            dataList = [ ...dataList, ...data ];
        }

        return sheetName;
    });

    return dataList;
};