import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import LeftMenu from '../../../controls/Layout/LeftMenu/LeftMenu';

import {
    updatePath,
    setTab
} from '../../workers/pathWorker';

const mapStateToProps = state => {
    const { PathReducers, router } = state;

    if (PathReducers) {
        return {
            tabValue: PathReducers.activeItem,
            path: router.location.pathname
        };
    }

    return {};
};

const LeftMenuContainer =
    connect(mapStateToProps,
        {
            updatePath,
            setTab
        })(injectIntl(LeftMenu));

export default LeftMenuContainer;