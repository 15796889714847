import React from 'react';
import { Paper, TableRow, TableCell, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { localize } from 'util/Localizer';
import COLORCONSTANTS from 'styles/ColorConstants';
import breakpointsNames from 'styles/ResponsiveConstants';
import StyledTable from 'components/Common/StyledTable/StyledTable';
import StyledTableHead from 'components/Common/StyledTableHead/StyledTableHead';
import StyledTableBody from 'components/Common/StyledTableBody/StyledTableBody';
import StyledConfirmModal from 'components/Common/StyledConfirmModal/StyledConfirmModal';

const InterlacedTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: COLORCONSTANTS.WHITE
    }
}));

const HeaderCell = styled(TableCell)(() => ({
    fontFamily: 'Museo700',
    fontWeight: 600,
    fontSize: '16px',
    height: '57px',
    lineHeight: '19.2px',
    padding: '0px 10px 0px 10px'
}));

const BodyCell = styled(TableCell)(() => ({
    fontFamily: 'MuseoSans500',
    fontWeight: 400,
    fontSize: '14px',
    height: '57px',
    lineHeight: '16.8px',
    padding: '0px 10px 0px 10px'
}));

const OverflowEmail = styled('div')(({ theme }) => ({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down(breakpointsNames.xl)]: {
        width: '200px'
    }
}));

function UserModalDelete({
    usersToDelete,
    showDeleteUserModal,
    onDeleteUser,
    setOpenUserDeleteModal

}) {
    const userCount = usersToDelete?.length || 0;
    const confirmMessage = userCount > 1 ?
        localize('userManagement.deleteMultipleConfirm', { number: userCount.toString() }) :
        localize('userManagement.deleteUserConfirm');

    const renderUsersToDelete = (users) => {
        if (userCount <= 1) {
            return null;
        }

        return (
            <Grid container>
                <Grid item xs={12} sx={{pt: '16px'}}>
                    <Paper
                        style={{maxHeight: '340px', overflow: 'auto'}}
                        elevation={3}
                        sx={{mt: '0px', mb: '8px', borderRadius: '4px'}}
                    >
                        <StyledTable testId="userModal-table" sx={{width: '100%', maxHeight: 10}}>
                            <StyledTableHead data-testid="userModal-table-head">
                                <TableRow sx={{backgroundColor: COLORCONSTANTS.WHITE}}>
                                    <HeaderCell>
                                        {localize('userManagement.deleteUserName')}
                                    </HeaderCell>
                                    <HeaderCell>
                                        {localize('userManagement.email')}
                                    </HeaderCell>
                                </TableRow>
                            </StyledTableHead>
                            <StyledTableBody data-testid="userModal-table-body">
                                {
                                    users.map((user) => (
                                        <InterlacedTableRow
                                            key={user.id}
                                            data-testid={`userModal-table-row-${user.id}`}
                                        >
                                            <BodyCell data-testid={`userModal-name-cell-${user.id}`}>
                                                {`${user.firstName} ${user.lastName}`}
                                            </BodyCell>
                                            <BodyCell data-testid={`userModal-email-cell-${user.id}`}>
                                                <OverflowEmail>
                                                    {user.emailAddress}
                                                </OverflowEmail>
                                            </BodyCell>
                                        </InterlacedTableRow>
                                    ))
                                }
                            </StyledTableBody>
                        </StyledTable>
                    </Paper>
                </Grid>
            </Grid>
        );
    };

    return (
        <StyledConfirmModal
            isOpen={showDeleteUserModal}
            contentMessage={confirmMessage}
            confirmInputLabel={localize('userManagement.delete')}
            cancelInputLabel={localize('userManagement.cancel')}
            onConfirm={onDeleteUser}
            onCancel={() => setOpenUserDeleteModal(false)}>
            {renderUsersToDelete(usersToDelete)}
        </StyledConfirmModal>
    );
}

UserModalDelete.defaultProps = {
    usersToDelete: [],
    showDeleteUserModal: false,
    onDeleteUser: () => null,
    setOpenUserDeleteModal: () => null
};

/* eslint-disable react/forbid-prop-types */
UserModalDelete.propTypes = {
    usersToDelete: PropTypes.array,
    showDeleteUserModal: PropTypes.bool,
    onDeleteUser: PropTypes.func,
    setOpenUserDeleteModal: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default UserModalDelete;
