import React from 'react';
import {
    any,
    func,
    oneOf,
    string,
    bool
} from 'prop-types';
import { Input } from '@mui/material';

function StyledInput({
    type,
    color,
    className,
    id,
    value,
    isDisabled,
    autoComplete,
    name,
    isError,
    onClick,
    onChange,
    onKeyPress,
    ...props
}) {
    return <Input
        type={type}
        color={color}
        className={className}
        id={id}
        value={value}
        disabled={isDisabled}
        autoComplete={autoComplete}
        name={name}
        error={isError}
        onClick={onClick}
        onChange={onChange}
        onKeyPress={onKeyPress}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    />;
}

StyledInput.defaultProps = {
    type: 'text',
    color: 'primary',
    className: null,
    id: null,
    value: undefined,
    isDisabled: false,
    autoComplete: null,
    name: null,
    isError: false,
    onClick: () => null,
    onChange: () => null,
    onKeyPress: () => null

};

/* eslint-disable react/forbid-prop-types */
StyledInput.propTypes = {
    type: oneOf([
        'button',
        'submit',
        'text'
    ]),
    color: oneOf([
        'secondary',
        'primary'
    ]),
    className: string,
    id: string,
    value: any,
    isDisabled: bool,
    autoComplete: string,
    name: string,
    isError: bool,
    onChange: func,
    onKeyPress: func,
    onClick: func

};
/* eslint-enable react/forbid-prop-types */

export default StyledInput;
