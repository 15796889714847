import React from 'react';
import {
    bool,
    node,
    object,
    oneOf,
    string
} from 'prop-types';
import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import { commonStyles } from 'styles/CommonStyles';

const NewFormControl = styled(
    FormControl,
    { shouldForwardProp: (prop) => prop !== 'isNewStyle' }
)(({ theme, isNewStyle }) => {
    if (isNewStyle) {
        return {
            ...commonStyles(theme).newInputStyle
        };
    }

    return {
        ...commonStyles(theme).oldInputStyle
    };
});

function StyledFormControl({
    children,
    extraStyle,
    fullWidth,
    isNewStyle,
    size,
    testId,
    variant,
    isError
}) {
    return <NewFormControl
        fullWidth={fullWidth}
        isNewStyle={isNewStyle}
        size={size}
        style={extraStyle}
        data-testid={testId}
        variant={variant}
        error={isError}
    >
        {children}
    </NewFormControl>;
}

StyledFormControl.defaultProps = {
    extraStyle: null,
    fullWidth: false,
    isNewStyle: false,
    size: 'medium',
    testId: null,
    variant: 'outlined',
    isError: false
};

/* eslint-disable react/forbid-prop-types */
StyledFormControl.propTypes = {
    children: node.isRequired,
    extraStyle: object,
    fullWidth: bool,
    isNewStyle: bool,
    size: oneOf([
        'medium',
        'small'
    ]),
    testId: string,
    variant: oneOf([
        'filled',
        'outlined',
        'standard'
    ]),
    isError: bool
};
/* eslint-enable react/forbid-prop-types */

export default StyledFormControl;
