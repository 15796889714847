import React from "react";
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import CommonStyles from 'styles/CommonStyles';
import { localize } from 'util/Localizer';
import StyledFormLabel from 'components/Common/StyledFormLabel/StyledFormLabel';
import colorConstants from 'styles/ColorConstants';
import StyledTextField from 'components/Common/StyledTextField/StyledTextField';
import StyledTooltip from 'components/Common/StyledTooltip/StyledTooltip';
import StyledConfirmModal from "components/Common/StyledConfirmModal/StyledConfirmModal";

function StudentNotes({
    showRemoveStudentNotesModal,
    showStudentNotes,
    tripModel,
    onRemoveStudentNotes,
    onUpdateModel,
    setShowRemoveStudentNotesModal,
    setShowStudentNotes
}) {
    const handleChangeNotes = (evt) => {
        if (evt === null) {
            return false;
        }

        onUpdateModel(evt);

        return true;
    };

    return (
        <>
            { !showStudentNotes &&
                <Grid item xs={12} sx={{ paddingTop: '10px' }}>
                    <StyledButton
                        color="inherit"
                        style={CommonStyles.linkButton}
                        type="button"
                        startIcon={<AddIcon fontSize="inherit" />}
                        variant="text"
                        testId="addStudentNotes"
                        onClick={() => setShowStudentNotes(!showStudentNotes)}
                    >
                        {localize('transportationRequest.addNotes')}
                    </StyledButton>
                </Grid>
            }
            { showStudentNotes &&
                <>
                    <Grid item xs={12} sx={{
                        paddingTop: '10px',
                        display: 'flex'
                    }}>
                        <StyledFormLabel
                            internalStyle={{ display: 'flex' }}
                            label={
                                <>
                                    {localize('transportationRequest.studentNotes')}
                                    <StyledTooltip
                                        isNewStyle
                                        placement="bottom-start"
                                        title={localize('transportationRequest.studentNotesTooltip')}
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                color: colorConstants.EVERDRIVENGREY80,
                                                marginLeft: '10px'
                                            }}
                                        />
                                    </StyledTooltip>
                                </>
                            }
                        />
                        <StyledButton
                            color="inherit"
                            style={{
                                ...CommonStyles.linkButton,
                                color: colorConstants.EVERDRIVENFUCHSIA,
                                marginLeft: 'auto',
                                alignItems: 'center'
                            }}
                            type="button"
                            startIcon={<CloseIcon style={{ fontSize: 16 }} />}
                            variant="text"
                            testId="removeStudentNotes"
                            onClick={() => setShowRemoveStudentNotesModal(true)}
                        >
                            {localize('transportationRequest.remove')}
                        </StyledButton>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTextField
                            inputProps= {{ "data-testid": "studentNotes", maxLength: "250" }}
                            isMultiline
                            isNewStyle
                            name="studentNotes"
                            rows={4}
                            role="textbox"
                            value={tripModel.studentNotes ?? ''}
                            onChange={(e) => handleChangeNotes(e)}
                        />
                    </Grid>
                </>
            }
            <StyledConfirmModal
                isOpen={showRemoveStudentNotesModal}
                contentMessage={localize('transportationRequest.removeStudentNotesConfirm')}
                confirmInputLabel={localize('transportationRequest.remove')}
                cancelInputLabel={localize('transportationRequest.cancel')}
                onConfirm={onRemoveStudentNotes}
                onCancel={() => setShowRemoveStudentNotesModal(false)}
            />
        </>
    );
}

StudentNotes.defaultProps = {
    showRemoveStudentNotesModal: false,
    showStudentNotes: false,
    tripModel: null,
    onRemoveStudentNotes: null,
    onUpdateModel: null,
    setShowRemoveStudentNotesModal: null,
    setShowStudentNotes: null
};

/* eslint-disable react/forbid-prop-types */
StudentNotes.propTypes = {
    showRemoveStudentNotesModal: PropTypes.bool,
    showStudentNotes: PropTypes.bool,
    tripModel: PropTypes.object,
    onRemoveStudentNotes: PropTypes.func,
    onUpdateModel: PropTypes.func,
    setShowRemoveStudentNotesModal: PropTypes.func,
    setShowStudentNotes: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default StudentNotes;