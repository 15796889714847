import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import DPApp from 'components/DPApp';
import { localize } from 'util/Localizer';
import breakpointsNames from 'styles/ResponsiveConstants';

import StyledHeaderContainer from 'components/Common/StyledHeaderContainer/StyledHeaderContainer';
import StyledLoading from 'components/Common/StyledLoading/StyledLoading';
import DetailCard from '../DetailCard/DetailCard';
import MapContainer from '../MapContainer/MapContainer';

const CardBox = styled(Box)(({theme}) => ({
    width: '288px',
    position: 'absolute',
    top: 41,
    left: 48,
    zIndex: 100,
    // Move above map on small screens
    [theme.breakpoints.down(breakpointsNames.md)]: {
        position: 'unset',
        margin: '0 auto 15px auto'
    }
}));

function TrackTripDetail({
    trip,
    isLoading,
    tripId,
    memberId,
    getTrip
}) {
    useEffect(() => {
        getTrip(memberId, tripId);
    }, []);
    
    return (
        <DPApp>
            <StyledHeaderContainer
                title={localize('tripDetails.title')}
                extraStyle={{padding: '60px 0 45px 0px'}}
                testId="tripDetailTitle"
            />
            {isLoading &&
                <StyledLoading message={localize("trackTrip.loadingTripDetails")} />
            }
            {!isLoading && trip && trip.TripGuid === tripId &&
                <Box
                    sx={{
                        maxWidth: '930px',
                        margin: '0 auto',
                        position: 'relative',
                        padding: '15px'
                    }}>
                    <CardBox>
                        <DetailCard trip={trip} />
                    </CardBox>
                    <Paper
                        sx={{
                            padding: '18px 25px',
                            borderRadius: '8px',
                            boxShadow: '0px -1px 16px 0px rgba(51, 51, 51, 0.15);'
                        }}
                    >
                        <MapContainer trip={trip} />
                    </Paper>
                </Box>
            }
        </DPApp>
    );
}

TrackTripDetail.defaultProps = {
    trip: {},
    isLoading: false,
    tripId: "",
    memberId: "",
    getTrip: null
};

/* eslint-disable react/forbid-prop-types */
TrackTripDetail.propTypes = {
    trip: PropTypes.object,
    isLoading: PropTypes.bool,
    tripId: PropTypes.string,
    memberId: PropTypes.string,
    getTrip: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default TrackTripDetail;