/* eslint max-lines: 0 */
import React from 'react';
import { Grid, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import breakpointsNames from '../../styles/ResponsiveConstants';
import { localize } from '../../util/Localizer';
import commonUseStyles from '../../styles/CommonStyles';

import RRTimePicker from './RRTimePicker';
import CopyAllTimeButton from './CopyAllTimeButton';
import StyledCheckbox from '../../components/Common/StyledCheckbox/StyledCheckbox';

const useStyles = makeStyles((theme) => ({
    groupRight: {
        ...commonUseStyles.defaultGroupRight,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            paddingLeft: 0,
            paddingRight: 15
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    groupInsideRight: {
        ...commonUseStyles.defaultGroupInsideRight
    },
    days: {
        marginTop: 5
    },
    inputDaysSchedule: {
        paddingLeft: 4,
        paddingRight: 4
    },
    leftTimeContainer: {
        ...commonUseStyles.timePickerContainer,
        paddingRight: 3
    },
    rightTimeContainer: {
        ...commonUseStyles.timePickerContainer,
        paddingLeft: 3
    },
    formLabel: {
        ...commonUseStyles.customFormLabel
    },
    copyButtonColumn: {
        display: 'flex'
    },
    checkBoxLabel: {
        marginLeft: -8
    }
}));

function ScheduleGroup({
    schoolDays,
    schedule,
    scheduleValid,
    schoolInformation,
    handleChangeSchedule,
    handleChangeSchoolDays,
    copyTimeToDays
}) {
    const classes = useStyles();

    return (
        <Grid item xs={4} sm={12} md={6} lg={4} className={classes.groupRight}>
            <span className={classes.formLabel}>
                {localize('tripRequestForm.duteTimeBellSchedule')}
            </span>
            <Grid container>
                <Grid item xs={3}>
                    <span className={classes.formLabel}>
                        {localize('tripRequestForm.schoolDays')}
                    </span>
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <span className={classes.formLabel}>
                        {localize('tripRequestForm.arrivalTimeAtSchool')}
                    </span>
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <span className={classes.formLabel}>
                        {localize('tripRequestForm.pickupTimeFromSchool')}
                    </span>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <FormControlLabel
                        data-testid="schoolMonday"
                        className={classes.days}
                        size="medium"
                        classes={{
                            label: classes.checkBoxLabel
                        }}
                        control={
                            <StyledCheckbox
                                name="schoolMonday"
                                color="secondary"
                                isChecked={schoolDays.schoolMonday}
                                size="medium"
                                onChange={handleChangeSchoolDays}
                            />
                        }
                        label={localize('tripRequestForm.monday')} />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolMonday}
                        controlId="schoolArrivalMonday"
                        controlValue={schedule.schoolArrivalMonday}
                        controlDateValue={schoolInformation.schoolArrivalMonday}
                        isValid={scheduleValid.schoolArrivalMonday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolMonday}
                        controlId="schoolPickupMonday"
                        controlValue={schedule.schoolPickupMonday}
                        controlDateValue={schoolInformation.schoolPickupMonday}
                        isValid={scheduleValid.schoolPickupMonday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={1} className={classes.copyButtonColumn}>
                    <CopyAllTimeButton
                        disabled={!schoolInformation.schoolArrivalMonday &&
                            !schoolInformation.schoolPickupMonday}
                        id="btnCopyMonday"
                        onClick={() => copyTimeToDays('schoolArrivalMonday',
                            'schoolPickupMonday')}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <FormControlLabel
                        data-testid="schoolTuesday"
                        className={classes.days}
                        size="medium"
                        classes={{
                            label: classes.checkBoxLabel
                        }}
                        control={
                            <StyledCheckbox
                                name="schoolTuesday"
                                color="secondary"
                                isChecked={schoolDays.schoolTuesday}
                                size="medium"
                                onChange={handleChangeSchoolDays}
                            />
                        }
                        label={localize('tripRequestForm.tuesday')}
                    />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolTuesday}
                        controlId="schoolArrivalTuesday"
                        controlValue={schedule.schoolArrivalTuesday}
                        controlDateValue={schoolInformation.schoolArrivalTuesday}
                        isValid={scheduleValid.schoolArrivalTuesday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolTuesday}
                        controlId="schoolPickupTuesday"
                        controlValue={schedule.schoolPickupTuesday}
                        controlDateValue={schoolInformation.schoolPickupTuesday}
                        isValid={scheduleValid.schoolPickupTuesday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={1} className={classes.copyButtonColumn}>
                    <CopyAllTimeButton
                        disabled={!schoolInformation.schoolArrivalTuesday &&
                            !schoolInformation.schoolPickupTuesday}
                        id="btnCopyTuesday"
                        onClick={() => copyTimeToDays('schoolArrivalTuesday',
                            'schoolPickupTuesday')}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <FormControlLabel
                        data-testid="schoolWednesday"
                        className={classes.days}
                        size="medium"
                        control={
                            <StyledCheckbox
                                name="schoolWednesday"
                                color="secondary"
                                isChecked={schoolDays.schoolWednesday}
                                size="medium"
                                onChange={handleChangeSchoolDays}
                            />
                        }
                        label={localize('tripRequestForm.wednesday')}
                        classes={{
                            label: classes.checkBoxLabel
                        }}
                    />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolWednesday}
                        controlId="schoolArrivalWednesday"
                        controlValue={schedule.schoolArrivalWednesday}
                        controlDateValue={schoolInformation.schoolArrivalWednesday}
                        isValid={scheduleValid.schoolArrivalWednesday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolWednesday}
                        controlId="schoolPickupWednesday"
                        controlValue={schedule.schoolPickupWednesday}
                        controlDateValue={schoolInformation.schoolPickupWednesday}
                        isValid={scheduleValid.schoolPickupWednesday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={1} className={classes.copyButtonColumn}>
                    <CopyAllTimeButton
                        disabled={!schoolInformation.schoolArrivalWednesday &&
                            !schoolInformation.schoolPickupWednesday}
                        id="btnCopyWednesday"
                        onClick={() => copyTimeToDays('schoolArrivalWednesday',
                            'schoolPickupWednesday')}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <FormControlLabel
                        data-testid="schoolThursday"
                        className={classes.days}
                        size="medium"
                        control={
                            <StyledCheckbox
                                name="schoolThursday"
                                color="secondary"
                                isChecked={schoolDays.schoolThursday}
                                size="medium"
                                onChange={handleChangeSchoolDays}
                            />
                        }
                        label={localize('tripRequestForm.thursday')}
                        classes={{
                            label: classes.checkBoxLabel
                        }}
                    />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolThursday}
                        controlId="schoolArrivalThursday"
                        controlValue={schedule.schoolArrivalThursday}
                        controlDateValue={schoolInformation.schoolArrivalThursday}
                        isValid={scheduleValid.schoolArrivalThursday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolThursday}
                        controlId="schoolPickupThursday"
                        controlValue={schedule.schoolPickupThursday}
                        controlDateValue={schoolInformation.schoolPickupThursday}
                        isValid={scheduleValid.schoolPickupThursday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={1} className={classes.copyButtonColumn}>
                    <CopyAllTimeButton
                        disabled={!schoolInformation.schoolArrivalThursday &&
                            !schoolInformation.schoolPickupThursday}
                        id="btnCopyThursday"
                        onClick={() => copyTimeToDays('schoolArrivalThursday',
                            'schoolPickupThursday')}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <FormControlLabel
                        data-testid="schoolFriday"
                        className={classes.days}
                        size="medium"
                        control={
                            <StyledCheckbox
                                name="schoolFriday"
                                color="secondary"
                                isChecked={schoolDays.schoolFriday}
                                size="medium"
                                onChange={handleChangeSchoolDays}
                            />
                        }
                        label={localize('tripRequestForm.friday')}
                        classes={{
                            label: classes.checkBoxLabel
                        }}
                    />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolFriday}
                        controlId="schoolArrivalFriday"
                        controlValue={schedule.schoolArrivalFriday}
                        controlDateValue={schoolInformation.schoolArrivalFriday}
                        isValid={scheduleValid.schoolArrivalFriday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolFriday}
                        controlId="schoolPickupFriday"
                        controlValue={schedule.schoolPickupFriday}
                        controlDateValue={schoolInformation.schoolPickupFriday}
                        isValid={scheduleValid.schoolPickupFriday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={1} className={classes.copyButtonColumn}>
                    <CopyAllTimeButton
                        disabled={!schoolInformation.schoolArrivalFriday &&
                            !schoolInformation.schoolPickupFriday}
                        id="btnCopyFriday"
                        onClick={() => copyTimeToDays('schoolArrivalFriday',
                            'schoolPickupFriday')}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <FormControlLabel
                        data-testid="schoolSaturday"
                        className={classes.days}
                        size="medium"
                        control={
                            <StyledCheckbox
                                name="schoolSaturday"
                                color="secondary"
                                isChecked={schoolDays.schoolSaturday}
                                size="medium"
                                onChange={handleChangeSchoolDays}
                            />
                        }
                        label={localize('tripRequestForm.saturday')}
                        classes={{
                            label: classes.checkBoxLabel
                        }}
                    />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolSaturday}
                        controlId="schoolArrivalSaturday"
                        controlValue={schedule.schoolArrivalSaturday}
                        controlDateValue={schoolInformation.schoolArrivalSaturday}
                        isValid={scheduleValid.schoolArrivalSaturday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolSaturday}
                        controlId="schoolPickupSaturday"
                        controlValue={schedule.schoolPickupSaturday}
                        controlDateValue={schoolInformation.schoolPickupSaturday}
                        isValid={scheduleValid.schoolPickupSaturday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={1} className={classes.copyButtonColumn}>
                    <CopyAllTimeButton
                        disabled={!schoolInformation.schoolArrivalSaturday &&
                            !schoolInformation.schoolPickupSaturday}
                        id="btnCopySaturday"
                        onClick={() => copyTimeToDays('schoolArrivalSaturday',
                            'schoolPickupSaturday')} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <FormControlLabel
                        data-testid="schoolSunday"
                        className={classes.days}
                        size="medium"
                        control={
                            <StyledCheckbox
                                name="schoolSunday"
                                color="secondary"
                                isChecked={schoolDays.schoolSunday}
                                size="medium"
                                onChange={handleChangeSchoolDays}
                            />
                        }
                        label={localize('tripRequestForm.sunday')}
                        classes={{
                            label: classes.checkBoxLabel
                        }}
                    />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolSunday}
                        controlId="schoolArrivalSunday"
                        controlValue={schedule.schoolArrivalSunday}
                        controlDateValue={schoolInformation.schoolArrivalSunday}
                        isValid={scheduleValid.schoolArrivalSunday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={4} className={classes.inputDaysSchedule}>
                    <RRTimePicker
                        disabled={!schoolDays.schoolSunday}
                        controlId="schoolPickupSunday"
                        controlValue={schedule.schoolPickupSunday}
                        controlDateValue={schoolInformation.schoolPickupSunday}
                        isValid={scheduleValid.schoolPickupSunday}
                        onChangeTime={handleChangeSchedule} />
                </Grid>
                <Grid item xs={1} className={classes.copyButtonColumn}>
                    <CopyAllTimeButton
                        disabled={!schoolInformation.schoolArrivalSunday &&
                            !schoolInformation.schoolPickupSunday}
                        id="btnCopySunday"
                        onClick={() => copyTimeToDays('schoolArrivalSunday',
                            'schoolPickupSunday')}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ScheduleGroup;
