import React from 'react';
import { injectIntl } from 'react-intl';
import DPApp from './DPApp';

function Invoicing() {
    return <DPApp>
        <p>Invoicing</p>
    </DPApp>;
}

const ConnectedInvoicing = (injectIntl(Invoicing));

export default ConnectedInvoicing;