import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import HistoryIcon from '@mui/icons-material/History';
import { styled } from '@mui/material/styles';

// utils
import { Enum, optionsTripGroup } from 'util/EnumHelper';
import * as dateUtils from '../../util/dateUtils';
import { localize } from "../../util/Localizer";

const TripStatusContainer = styled('div')(({isMapCard}) => {
    if (isMapCard) {
        return ({
            height: 72
        });
    }
    
    return null;
}
);

const TripStatus = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.black,
    padding: '8px 12px',
    alignItems: 'center',
    '&> span:first-of-type': {
        fontSize: '12px',
        flexShrink: '0'
    },
    '&> span:last-of-type': {
        fontWeight: 'bold',
        flexShrink: '0'
    }
}));

const DottedLine = styled('div')(({theme}) => ({
    borderBottom: `1px dotted ${theme.palette.black}`,
    width: '100%',
    margin: '0 4px'
}));

const AssignmentTimeIcon = styled(AssignmentOutlinedIcon)(() => ({
    width: 20,
    height: 20,
    marginBottom: -5
}));

const HistoryTimeIcon = styled(HistoryIcon)(() => ({
    width: 20,
    height: 20,
    marginBottom: -5
}));

const LightTooltip = styled(Tooltip)(({ theme }) => ({
    tooltip: {
        backgroundColor: theme.palette.white,
        color: theme.palette.black,
        boxShadow: theme.shadows[1],
        fontSize: 14
    }
}));

function TripTimeDetail({ isExtended, tripType, trip, isMapCard }) {
    const { DueTimeTLT, OnsiteTLT, NoshowTLT, DropoffTLT, CancelTLT, PickupTLT, OnSiteAtDropoffTLT } = trip;
    let tripStatusDetails = {statusText:'', time: ''};
    let secondaryTripStatusDetails = {statusText:'', time: ''};

    // get trip status details
    switch (tripType) {
    case optionsTripGroup.Today:
        switch (trip.State) {
        case Enum.TripState.OnSite:
            tripStatusDetails = {statusText: localize("tripList.onSite"), time: OnsiteTLT};
            break;
        case Enum.TripState.OnSiteAtDropoff:
            tripStatusDetails = OnSiteAtDropoffTLT ?
                {statusText: localize("tripList.onSiteAtDropoff"), time: OnSiteAtDropoffTLT} :
                {statusText: localize("tripList.scheduled"), time: DueTimeTLT};
            break;
        case Enum.TripState.OnBoard:
            tripStatusDetails = {statusText: localize("tripList.pickedUp"), time: PickupTLT};
            break;
        default:
            tripStatusDetails = {statusText: localize("tripList.scheduled"), time: DueTimeTLT};
        }
        break;
    case optionsTripGroup.Future:
        tripStatusDetails = {statusText: localize("tripList.scheduled"), time: DueTimeTLT};
        break;
    case optionsTripGroup.NoShow:
        tripStatusDetails = {statusText: localize("tripList.noShow"), time: NoshowTLT};
        secondaryTripStatusDetails = {statusText: localize("tripList.onSite"), time: OnsiteTLT};
        break;
    case optionsTripGroup.Finished:
        switch (Enum.TripState[trip.status]) {
        case Enum.TripState.Canceled:
            tripStatusDetails = {
                statusText: localize("tripList.canceled"),
                time: CancelTLT,
                date: CancelTLT
            };
            secondaryTripStatusDetails = {
                statusText: localize("tripList.scheduled"),
                time: DueTimeTLT
            };
            break;
        default:
            tripStatusDetails = {statusText: localize("tripList.droppedOff"), time: DropoffTLT};
            secondaryTripStatusDetails = {statusText: localize("tripList.pickedUp"), time: PickupTLT};
        }
        break;
    default:
        break;
    }

    return (
        <TripStatusContainer isMapCard={isMapCard}>
            {isExtended && (tripType === optionsTripGroup.NoShow || tripType === optionsTripGroup.Finished) && (
                <TripStatus>
                    <span>{secondaryTripStatusDetails.statusText}</span>
                    <DottedLine />
                    <span>{dateUtils.timeFormat(secondaryTripStatusDetails.time)}</span>
                </TripStatus>
            )}
            <TripStatus>
                <span>{tripStatusDetails.statusText}</span>
                <DottedLine />
                <span>
                    {trip.IsEmulated &&
					<LightTooltip placement="top" title={localize("tripList.isEmulatetTooltip")}>
					    <AssignmentTimeIcon />
					</LightTooltip>
                    }
                    {tripStatusDetails.date
                        ? (
                            <>
                                <LightTooltip placement="top" title={dateUtils.timeFormat(tripStatusDetails.time)}>
                                    <HistoryTimeIcon />
                                </LightTooltip>
                                <span>{dateUtils.monthDayYearFormat(tripStatusDetails.date)}</span>
                            </>
                        )
                        : dateUtils.timeFormat(tripStatusDetails.time)
                    }
                </span>
            </TripStatus>
        </TripStatusContainer>
    );
}

TripTimeDetail.defaultProps = {
    isMapCard: false
};

TripTimeDetail.propTypes = {
    trip: PropTypes.shape({
        MemberPII: PropTypes.shape({
            FirstName: PropTypes.string
        }),
        State: PropTypes.number,
        status: PropTypes.string,
        DueDateTimeUTC: PropTypes.string,
        MemberGUID: PropTypes.string,
        TripGuid: PropTypes.string,
        OnsiteTLT: PropTypes.string,
        OnSiteAtDropoffTLT: PropTypes.string,
        PickupTLT: PropTypes.string,
        CancelTLT: PropTypes.string,
        DropoffTLT: PropTypes.string,
        NoshowTLT: PropTypes.string,
        DueTimeTLT: PropTypes.string
    }).isRequired,
    isExtended: PropTypes.bool.isRequired,
    tripType: PropTypes.string.isRequired,
    isMapCard: PropTypes.bool
};

export default TripTimeDetail;
