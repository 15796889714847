/* eslint max-lines: 0 */

import React, { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CalendarTypeAutoComplete from './CalendarTypeAutoComplete';
import StyledButton from '../Common/StyledButton/StyledButton';
import { localize } from '../../util/Localizer';
import Auth from '../../auth/Auth';
import breakpointsNames from '../../styles/ResponsiveConstants';
import { audienceType } from '../../util/EnumHelper';
import StyledTable from '../Common/StyledTable/StyledTable';
import StyledTableRow from '../Common/StyledTableRow/StyledTableRow';
import StyledTableCell from '../Common/StyledTableCell/StyledTableCell';
import StyledTableBody from '../Common/StyledTableBody/StyledTableBody';

const LinkButton = styled(StyledButton)(({ theme }) => ({
    color: theme.palette.everDrivenLightBlue,
    fontWeight: 500,
    padding: 0,
    margin: 0,
    minWidth: 0,
    textTransform: 'none',
    fontSize: 12,
    '& > span > svg': {
        fontSize: '14px !important'
    },
    '&.Mui-disabled': {
        color: theme.palette.white
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        fontSize: 10
    }
}));

const DeleteStartIcon = styled(DeleteIcon)(({ theme }) => ({
    color: theme.palette.everDrivenDarkGrey
}));

const AcceptedIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
    color: theme.palette.everDrivenDarkGreen
}));

const FileNameBox = styled('div')(({ theme }) => ({
    paddingTop: 5,
    marginLeft: 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 400,
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        width: 250
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: 300,
        paddingTop: 0
    },
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        width: 180
    }
}));

const AcceptedRow = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
}));

const useStyles = makeStyles((theme) => ({
    calendarTypeColumn: {
        verticalAlign: 'bottom',
        width: 320,
        padding: '0px 16px'
    },
    fileSizeColumn: {
        width: 150,
        textAlign: 'left',
        color: theme.palette.everDrivenDarkGrey,
        [theme.breakpoints.down(breakpointsNames.md)]: {
            width: 120,
            textAlign: 'right'
        }
    },
    fileNameColumn: {
        width: 400,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            width: 270
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            width: 320
        },
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            width: 200
        }
    },
    buttonColumn:{
        width: 200,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            width: 100
        }
    }
}));

function CalendarUploadRow ({
    calendars,
    editRow,
    isMobileView,
    nRow,
    row,
    onDeleteFile,
    setCalendars
}) {
    const result = row;

    const [ calendar, setCalendar ] = useState(editRow);
    const refCalendarTypeAutoComplete = useRef();
    const classes = useStyles();

    const listItems = () => {
        const Facilities = Auth.getConfig().facilities;
        const calendarTypes = Facilities.map(facility => ({
            id: facility.name,
            name: facility.name,
            type: audienceType.school
        }));

        calendarTypes.unshift({
            id: Auth.getConfig().districtName,
            name: Auth.getConfig().districtName,
            type: audienceType.district
        });

        return calendarTypes;
    };

    const onSelectCalendarType = (item) => {
        const updatedCalendars = calendars;

        setCalendar({ ...calendar, typeDescription: item.id });
        updatedCalendars[nRow].typeDescription = item.id;

        setCalendars(updatedCalendars);
    };

    const onChangeCalendarType = (evt) => {
        if (evt === null) {
            return false;
        }

        const updatedCalendars = calendars;

        setCalendar({ ...calendar, typeDescription: evt.target.value });
        updatedCalendars[nRow].typeDescription = evt.target.value;

        setCalendars(updatedCalendars);

        return true;
    };

    const renderNormalView = () => (
        <StyledTableRow testId={`calendarFileRow_${nRow}`}>
            <StyledTableCell extraCssClass={classes.fileNameColumn} component="th" scope="row">
                <AcceptedRow>
                    <AcceptedIcon />
                    <FileNameBox data-testid={`calendarFileName_${nRow}`}>
                        {result.file.name}
                    </FileNameBox>
                </AcceptedRow>
            </StyledTableCell>
            <StyledTableCell
                extraCssClass={classes.fileSizeColumn}
                component="th" scope="row"
                testId={`calendarFileSize_${nRow}`}
            >
                {`${(result.file.size / 1024).toFixed(2)} KB`}
            </StyledTableCell>
            <StyledTableCell
                extraCssClass={classes.calendarTypeColumn}
                align="left"
                component="th"
                scope="row"
            >
                <CalendarTypeAutoComplete
                    ref={refCalendarTypeAutoComplete}
                    onSelectCalendarType={onSelectCalendarType}
                    idName={`calendarType_${nRow}`}
                    value={result.typeDescription}
                    defaultCalendarType={{
                        id: '',
                        name: '',
                        type: ''
                    }}
                    onChangeInput={onChangeCalendarType}
                    optionList={listItems()}
                />
            </StyledTableCell>
            <StyledTableCell
                className={classes.buttonColumn}
                component="th"
                scope="row"
                align="right"
            >
                <LinkButton
                    color="inherit"
                    startIcon={<DeleteStartIcon fontSize="inherit" />}
                    testId={`calendarUploadDelete_${nRow}`}
                    type="button"
                    variant="text"
                    onClick={() => onDeleteFile(nRow)}
                >
                    {localize('calendarUpload.removeFile')}
                </LinkButton>
            </StyledTableCell>
        </StyledTableRow>
    );

    const renderMobileView = () => (
        <StyledTable isMobileView>
            <StyledTableBody>
                <StyledTableRow>
                    <StyledTableCell>
                        <AcceptedRow>
                            <AcceptedIcon />
                            <FileNameBox>
                                {result.file.name}
                            </FileNameBox>
                        </AcceptedRow>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                        {`${(result.file.size / 1024).toFixed(2)} KB`}
                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell>
                        <CalendarTypeAutoComplete
                            ref={refCalendarTypeAutoComplete}
                            onSelectCalendarType={onSelectCalendarType}
                            idName={`calendarType_${nRow}`}
                            value={result.typeDescription}
                            defaultCalendarType={{
                                id: '',
                                name: '',
                                type: ''
                            }}
                            onChangeInput={onChangeCalendarType}
                            optionList={listItems()}
                        />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                        <LinkButton
                            color="inherit"
                            startIcon={<DeleteStartIcon fontSize="inherit" />}
                            testId={`calendarUploadMobileDelete_${nRow}`}
                            type="button"
                            variant="text"
                            onClick={() => onDeleteFile(nRow)}
                        >
                            {localize('calendarUpload.removeFile')}
                        </LinkButton>
                    </StyledTableCell>
                </StyledTableRow>
            </StyledTableBody>
        </StyledTable>
    );

    return (
        <>
            { !isMobileView && renderNormalView()}
            { isMobileView && renderMobileView()}
        </>
    );
}

export default CalendarUploadRow;