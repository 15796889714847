import React from "react";
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import CommonStyles from 'styles/CommonStyles';
import StyledFormLabel from 'components/Common/StyledFormLabel/StyledFormLabel';
import colorConstants from "styles/ColorConstants";
import { museoSansFont700 } from "util/EnumHelper";

function StyledSectionHeader({
    title,
    icon,
    buttonColor,
    textButton,
    extraStyle,
    isRequired,
    testid,
    onClick
}) {
    return (
        <Grid item xs={12} sx={{
            display: 'flex',
            alignItems: 'center'
        }}>
            <StyledFormLabel
                extraStyle={extraStyle}
                markStyle={{ fontFamily: museoSansFont700 }}
                label={title}
                isRequired={isRequired}
                testid={testid}
            />
            {textButton &&
                <StyledButton
                    style={{
                        ...CommonStyles.linkButton,
                        color: buttonColor,
                        marginLeft: 'auto',
                        alignItems: 'center',
                        height: 26
                    }}
                    type="button"
                    startIcon={icon}
                    variant="text"
                    testId={`${textButton}-Button`}
                    onClick={() => onClick()}
                >
                    {textButton}
                </StyledButton>
            }
        </Grid>
    );
}

StyledSectionHeader.defaultProps = {
    title: '',
    icon: null,
    buttonColor: colorConstants.EVERDRIVENLIGHTBLUE,
    textButton: '',
    extraStyle: null,
    isRequired: false,
    onClick: null,
    testid: null
};

/* eslint-disable react/forbid-prop-types */
StyledSectionHeader.propTypes = {
    isRequired: PropTypes.bool,
    icon: PropTypes.object,
    buttonColor: PropTypes.string,
    textButton: PropTypes.string,
    title: PropTypes.string,
    extraStyle: PropTypes.object,
    onClick: PropTypes.func,
    testid: PropTypes.string
};

export default StyledSectionHeader;