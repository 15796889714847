import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    bool,
    node,
    object,
    string
} from 'prop-types';
import StyledTypography from '../StyledTypography/StyledTypography';
import breakpointsNames from '../../../styles/ResponsiveConstants';

const Title = styled(StyledTypography)(() => ({
    marginLeft: 0,
    marginTop: 10,
    paddingBottom: 15
}));

const Header = styled(
    Grid,
    { shouldForwardProp: (prop) => prop !== 'isFixed' && prop !== 'isBGTransparent' && prop !== 'isUserHeader' }
)(({ theme, isFixed, isBGTransparent, isUserHeader }) => {
    let extraProps = {};

    if (isFixed) {
        extraProps = { ...extraProps, ...{
            position: 'fixed',
            zIndex: 20,
            width: 'calc(100% - 100px)'
        }};
    }

    if (!isBGTransparent) {
        extraProps = { ...extraProps, ...{
            backgroundColor: theme.palette.white
        }};
    }

    if (isUserHeader) {
        extraProps = { ...extraProps, ... {
            minWidth: 480,
            position: 'fixed',
            width: 'calc(100% - 100px)',
            zIndex: 1,
            [theme.breakpoints.down(breakpointsNames.sm)]: {
                width: '100%',
                position: 'inherit'
            },
            [theme.breakpoints.only(breakpointsNames.sm)]: {
                position: 'fixed',
                "@media only screen and (orientation: landscape)": {
                    width: '100%',
                    position: 'inherit'
                }
            }
        }};
    }

    return {
        minWidth: 360,
        paddingRight: 15,
        paddingLeft: 15,
        width: '100%',
        paddingBottom: 15,
        [theme.breakpoints.only(breakpointsNames.sm)]: {
            "@media only screen and (orientation: landscape)": {
                width: '100%'
            }
        },
        ...extraProps
    };
});

function StyledHeaderContent ({
    children,
    extraStyle,
    idContainer,
    isBGTransparent,
    isFixed,
    isUserHeader,
    title,
    systemAlert
}) {
    return (
        <Header
            container
            id={idContainer}
            data-testid={idContainer}
            isFixed={isFixed}
            isBGTransparent={isBGTransparent}
            isUserHeader={isUserHeader}
            style={extraStyle}
        >
            {systemAlert}
            <Grid item xs={12}>
                <Title variant="h3">
                    {title}
                </Title>
            </Grid>
            {children}
        </Header>
    );
}

StyledHeaderContent.defaultProps = {
    children: null,
    extraStyle: null,
    idContainer: null,
    isBGTransparent: false,
    isUserHeader: false,
    isFixed: false,
    title: null
};

/* eslint-disable react/forbid-prop-types */
StyledHeaderContent.propTypes = {
    children: node,
    extraStyle: object,
    idContainer: string,
    isBGTransparent: bool,
    isFixed: bool,
    isUserHeader: bool,
    title: string
};
/* eslint-enable react/forbid-prop-types */

export default StyledHeaderContent;