import { connect } from 'react-redux';
import PopulationType from 'components/TransportationRequest/PopulationType/PopulationType';
import {
    setHasMorePT
} from 'redux/actions/transportationRequest/transportationRequestActions';
import { onUpdatePopulationType } from 'redux/workers/transportationRequest/transportationRequestStudentWorker';
    
const mapStateToProps = state => {
    const { TransportationRequestReducers } = state;

    return {
        hasMorePT: TransportationRequestReducers.hasMorePT,
        populationTypes: TransportationRequestReducers.populationTypes
    };
};

const PopulationTypeContainer = connect(mapStateToProps,
    {
        onUpdatePopulationType,
        setHasMorePT
    })(PopulationType);

export default PopulationTypeContainer;
