import React from 'react';
import ActiveTripRow from "./ActiveTripRow";

function ActiveTripListContent({ tripList, popUpClasses }) {
    if (tripList) {
        const id = `contentTriplList_${tripList[0].VehicleRunID}`;
        const rowCount = tripList.length;

        return(
            <div id={id}>
                {
                    tripList.map((trip, t) => (
                        <ActiveTripRow
                            key={`${trip.VehicleRunID}_${trip.MemberPII.ClientMemberCode}`}
                            trip={trip}
                            isLastRow={(t+1) === rowCount}
                            isFirstRow={t === 0}
                            isOneTrip={rowCount === 1}
                            popUpClasses={popUpClasses} />
                    ))
                }
            </div>
        );
    }

    return null;
}

export default ActiveTripListContent;