import React from 'react';
import { Grid, IconButton, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { bool, func, node, string } from 'prop-types';
import breakpointsNames from 'styles/ResponsiveConstants';
import { museoFont700 } from 'util/EnumHelper';
import StyledTypography from '../StyledTypography/StyledTypography';

const ModalGridContainer = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px -1px 16px rgba(0, 0, 0, 0.25)',
    padding: '40px 40px 20px',
    backgroundColor: theme.palette.white,
    maxHeight: 'calc(100% - 100px)',
    overflowY: 'auto',
    width: 800,
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: 'calc(100% - 100px)'
    }
}));

function StyledModal({
    children,
    modalLabel,
    open,
    title,
    onClose
}) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby={modalLabel}
            aria-describedby={modalLabel}
        >
            <ModalGridContainer>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        data-testid={`closeModal${modalLabel}btn`}
                        onClick={onClose}
                        sx={{ padding: 0 }}
                    >
                        <CloseIcon sx={{ fontSize: 26 }} />
                    </IconButton>
                    <StyledTypography extraStyle={{
                        fontFamily: museoFont700,
                        fontSize: 24,
                        lineHeight: 1.5,
                        marginLeft: 'auto',
                        marginTop: 0,
                        marginBottom: 0
                    }}>
                        {title}
                    </StyledTypography>
                    <span style={{ marginLeft: 'auto' }} />
                </Grid>
                {children}
            </ModalGridContainer>
        </Modal>
    );
}

StyledModal.defaultProps = {
    children: null,
    modalLabel: null,
    open: false,
    title: null,
    onClose: null
};

StyledModal.propTypes = {
    children: node,
    modalLabel: string,
    open: bool,
    title: string,
    onClose: func
};

export default StyledModal;