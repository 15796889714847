import React from "react";

import {
    string
} from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import StyledTypography from "../StyledTypography/StyledTypography";

const COLOR_RED = 50;
const COLOR_YELLOW = 80;

const PercentProgressBox = styled(Box)(() => ({
    position: 'relative',
    display: 'inline-flex'
}));

const PercentProgress = styled(Box)(() => ({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const useStyles = makeStyles(theme => ({
    warning: {
        color: theme.palette.yellowOption1
    },
    success: {
        color: theme.palette.everDrivenDarkGreen
    },
    error: {
        color: theme.palette.everDrivenFuchsia
    }
}));

function StyledCircularProgress({
    progress,
    dataId,
    variant
}) {
    const classes = useStyles();

    const setStyleColor = () => {
        if(progress <= COLOR_RED) {
            return classes.error;
        }
        if(progress < COLOR_YELLOW) {
            return classes.warning;
        }

        return classes.success;
    };

    return (
        <PercentProgressBox className={classes.percentProgressBox}>
            <CircularProgress
                variant={variant}
                value={Math.floor(progress)}
                className={setStyleColor()}
            />
            <PercentProgress className={classes.percentProgress}>
                <StyledTypography
                    variant="h5"
                    data-testid={dataId}
                >
                    <strong>
                        {(Math.round(progress))}
                    </strong>
                </StyledTypography>
            </PercentProgress>
        </PercentProgressBox>
    );
}

StyledCircularProgress.defaultProps = {
    variant: 'determinate',
    progress: '0',
    dataId: ''
};

StyledCircularProgress.propTypes = {
    variant: string,
    progress: string,
    dataId: string
};

export default StyledCircularProgress;