// eslint-disable-next-line import/no-cycle
import Auth from '../auth/Auth';
import { localize } from './Localizer';

let tokenRenewalPromise = null;

const responseMethod = async (
    response,
    resolve,
    url,
    method,
    signal,
    data,
    isFile,
    isFormData,
    ignoreResponse,
    ignore404
) => {
    if (ignoreResponse) {
        resolve(response?.status);
    } else {
        switch (response?.status) {
        case 200:
            if (isFile) {
                const blob = await response.blob();
                const headerDisposition = response.headers.get('Content-Disposition');
                const fileName = headerDisposition.split('filename=')[1].replace(/["']/g, "");
    
                resolve({ blob, fileName });
            } else {
                const text = await response.text();
                const result = text.length === 0 ? [] : JSON.parse(text);
    
                window.log(`got back status of ${response.status} with res`, result);
                resolve(text.length === 0 ? [] : JSON.parse(text));
            }
            break;
        case 204:
            resolve("");
            break;
        case 400:
            resolve(localize('userForm.Error400'));
            break;
        case 401:
            if (!tokenRenewalPromise) {
                window.warn(`Received a 401 from url: ${url}, trying to renew the token`);
                tokenRenewalPromise = new Promise((resolveTokenRenewal, rejectTokenRenewal) => {
                    Auth.tokenRenewal(() => {
                        resolveTokenRenewal();
                    }, () => {
                        window.warn(localize('errorPage.apiError403'));
                        Auth.signout();
                        rejectTokenRenewal(new Error('Token Renewal Failed'));
                    });
                });
        
                tokenRenewalPromise.finally(() => {
                    tokenRenewalPromise = null;
                });
            }

            try {
                await tokenRenewalPromise;
                if (isFormData) {
                    // eslint-disable-next-line no-use-before-define
                    resolve(mrmFetchFormData(url, data, method));
                } else {
                    // eslint-disable-next-line no-use-before-define
                    resolve(mrmFetch(url, method, signal, data, isFile));
                }
            } catch (error) {
                document.location = '/';
            }
            break;
        case 403:
            window.warn(localize('errorPage.apiError403'));
            document.location = "/error403";
            break;
        case 404:
            if (ignore404) {
                resolve("");
                break;
            }
            window.warn(`404 message back from server for url: ${url}, sending to 404 page`);
            document.location = "/error";
            break;
        case 409:
            resolve(localize('userForm.Error409'));
            break;
        case 422:
            resolve(response.status);
            break;
        case undefined:
            window.warn(`${url} received response: ${response}`);
            break;
        default:
            window.warn(`Non-200 message back from server for url: 
                        ${url} received response: ${response.status}, 
                        ${response.statusText}, sending to error page`);
            document.location = "/error";
            break;
        }
    }
};

const responseError = (error, reject, url) => {
    window.warn('caught an error fetch',
        error);
    if(error.name !== "AbortError") {
        reject(new Error(`Error fetching from ${url}`,
            error));
    }
};

const mrmFetch = async (
    url,
    method,
    signal,
    data,
    isFile = false,
    ignoreResponse = false,
    ignore404 = false
) => new Promise((resolve, reject) => {
    window.fetch(Auth.getConfig().apiUrl + url, {
        method,
        signal,
        headers: {
            Authorization: `Bearer ${ Auth.getToken()}`,
            "Content-Type": "application/json"
        },
        body: data
    }).then(async (response) => {
        await responseMethod(response, resolve, url, method, signal, data, isFile, false, ignoreResponse, ignore404);
    }).catch(error => {
        responseError(error, reject, url);
    });
});

export const mrmFetchFormData = async (url, data, method = "post") => new Promise((resolve, reject) => {
    window.fetch(Auth.getConfig().apiUrl + url, {
        method,
        headers: { Authorization: `Bearer ${ Auth.getToken()}` },
        body: data
    }).then(async (response) => {
        await responseMethod(response, resolve, url, method, null, data, false, true);
    }).catch(error => {
        responseError(error, reject, url);
    });
});

export default mrmFetch;
