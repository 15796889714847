import React, { useRef, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { makeStyles } from '@mui/styles';

import ActiveTripListContent from '../../controls/MapTripList/ActiveTripListContent';
import commonUseStyles, { commonStyles } from '../../styles/CommonStyles';
import withRouter from '../../util/customHooks/withRouter';

import CreatePopupClass from './MapPopUp';

const wrappedPromise = () => {
    const wrapPromise = {};
    const promise = new Promise((resolve, reject) => {
        wrapPromise.resolve = resolve;
        wrapPromise.reject = reject;
    });

    wrapPromise.then = promise.then.bind(promise);
    wrapPromise.catch = promise.catch.bind(promise);
    wrapPromise.promise = promise;

    return wrapPromise;
};

const useStyles = makeStyles((theme) => ({
    imgStatusEnRoute: {
        fill: theme.palette.tealOption2,
        color: theme.palette.tealOption2
    },
    imgStatusOnSite: {
        fill: theme.palette.greenOption2,
        color: theme.palette.greenOption2
    },
    imgStatusOnBoard: {
        fill: theme.palette.greenOption3,
        color: theme.palette.greenOption3
    },
    contentStatusLabel: {
        ...commonStyles(theme).borderTopDashed,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        backgroundColor: theme.palette.everDrivenSeaGreen40,
        marginBottom: -5,
        marginLeft: -5,
        marginRight: -5,
        cursor: 'pointer'
    },
    rowContent: {
        ...commonStyles(theme).borderBottonDashed,
        ...commonUseStyles.popUpRowContent,
        paddingBottom: 2,
        paddingTop: 2
    },
    firstRowContent: {
        ...commonStyles(theme).borderBottonDashed,
        ...commonUseStyles.popUpRowContent,
        paddingBottom: 2
    },
    popUpBuble: {
        /* Position the bubble centred-above its parent. */
        position: 'relative',
        top: 0,
        left: 0,
        /* Style the bubble. */
        backgroundColor: theme.palette.white,
        width: '200px',
        padding: '5px',
        borderRadius: '15px',
        fontFamily: 'Arial, Helvetica, sans-serif',
        overflowY: 'auto',
        boxShadow: `0px 2px 10px 1px ${theme.palette.everDrivenGrey}`
    },
    /* The parent of the bubble. A zero-height div at the top of the tip. */
    popupBubbleAnchor: {
        /* Position the div a fixed distance above the tip. */
        position: 'relative',
        width: '100%',
        bottom: 8,
        left: 0
    },
    /* This element draws the tip. */
    popupBubbleAnchorAfter: {
        position: 'absolute',
        bottom: '50%',
        left: '100%',
        /* Center the tip horizontally. */
        transform: 'translate(0, 25%)',
        /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
        width: 0,
        height: 0,
        /* The tip is 8px high, and 12px wide. */
        borderTop: `18px solid ${theme.palette.transparent}`,
        borderBottom: `18px solid ${theme.palette.transparent}`,
        borderLeft: `12px solid ${theme.palette.white}`
    },
  	/* JavaScript will position this div at the bottom of the popup tip. */
  	popupContainer: {
        cursor: 'auto',
        height: 'auto',
        position: 'absolute',
        /* The max width of the info window. */
        width: 200
    }
}));

function Marker({
    map,
    google,
    tripList,
    mapCenter,
    icon,
    label,
    onClick,
    facilityId,
    position,
    router
}) {
    const classes = useStyles();
    const marker = useRef(null);

    const renderMarker = () => {
        if (!google) {
            return null;
        }

        const pos = position || mapCenter;

        if (!(pos instanceof google.maps.LatLng)) {
            // eslint-disable-next-line no-param-reassign
            position = new google.maps.LatLng(
                pos.lat,
                pos.lng
            );
        }

        if (icon && icon.anchorX) {
            // eslint-disable-next-line no-param-reassign
            icon.anchor = new google.maps.Point(icon.anchorX,
                icon.anchorY);
        }

        if (icon && icon.labelOriginX) {
            // eslint-disable-next-line no-param-reassign
            icon.labelOrigin = new google.maps.Point(icon.labelOriginX,
                icon.labelOriginY);
        }

        const pref = { map, position, label, icon };

        marker.current = new google.maps.Marker(pref);

        // removing from DOM actives popUps - for SignalR Refresh
        const activePopUps = document.querySelectorAll('[id^="container_"]');

        if (activePopUps) {
            activePopUps.forEach(activePopUp => {
                activePopUp.parentElement.removeChild(activePopUp);
            });
        }

        const contentPopUp =
			ReactDOMServer.renderToString(
			    <ActiveTripListContent
			        tripList={tripList}
			        popUpClasses={classes} />
			);

        let contentDivID = '';

        if (tripList) {
            contentDivID = `container_${tripList[0].VehicleRunID}`;

            const Popup = CreatePopupClass(
                tripList,
                facilityId,
                contentDivID,
                google,
                router,
                classes
            );
            const popup = new Popup(
                position,
                contentPopUp
            );

            if (onClick) {
                marker.current.addListener('click',
                    () => {
                        const contentDiv = document.getElementById(contentDivID);

                        if (popup.map && contentDiv) {
                            popup.resetPopUp();
                            popup.setMap(null);
                            if (contentDiv.parentElement) {
                                contentDiv.parentElement.removeChild(contentDiv);
                            }
                        } else if (contentDiv) {
                            if (contentDiv.parentElement) {
                                popup.resetPopUp();
                                contentDiv.parentElement.removeChild(contentDiv);
                                popup.setMap(null);
                            }
                        } else {
                            popup.resetPopUp();
                            popup.setMap(null);
                            popup.setMap(map);
                        }

                        // removing from DOM other actives popUp
                        const popUpContainers = document.querySelectorAll('[id^="container_"]');

                        popUpContainers.forEach(popUpContainer => {
                            popup.resetPopUp();
                            if (popUpContainer.id !== contentDivID) {
                                popUpContainer.parentElement.removeChild(popUpContainer);
                            }
                        });
                    });
            }
        }

        // This will remain until TrackTrip component gets updated
        wrappedPromise().resolve(marker.current);

        return null;
  	};

    useEffect(() => {
        if (marker.current) {
            marker.current.setMap(null);
            if (marker.__e3_) {
                google.maps.event.clearListeners(marker.current,
                    'click');
                google.maps.event.clearListeners(marker.current,
                    'mouseover');
                google.maps.event.clearListeners(marker.current,
                    'mouseout');
                marker.current.__e3_ = {};
            }
        }
        renderMarker();
    }, [ map, position ]);

    return null;
}

Marker.defaultProps = {
    label: null,
    map: null
};

export default withRouter(Marker);
