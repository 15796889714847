import { connect } from 'react-redux';
import TripRequestSiblingHeader from 'controls/TripRequestForm/TripRequestSiblingHeader/TripRequestSiblingHeader';
import {
    onAddNewTab,
    onSelectNewTab,
    onOpenDeleteTabModal
} from '../../workers/tripRequestFormSiblingHeaderWorker';
import { onLoadStudent } from '../../workers/tripRequestFormStudentWorker';
import { updatePath } from '../../workers/pathWorker';
import withRouter from '../../../util/customHooks/withRouter';

const mapStateToProps = state => {
    const { TripRequestFormReducers } = state;

    return {
        siblingNumber: TripRequestFormReducers.siblingNumber,
        currentSibling: TripRequestFormReducers.currentSibling,
        limitReached: TripRequestFormReducers.limitReached
    };
};

const TripRequestSiblingHeaderContainer = withRouter(
    connect(mapStateToProps,
        {
            onAddNewTab,
            onSelectNewTab,
            onLoadStudent,
            onOpenDeleteTabModal,
            updatePath
        })(TripRequestSiblingHeader)
);

export default TripRequestSiblingHeaderContainer;
