import { useEffect } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import Env from '../../Env';

const reactPlugin = new ReactPlugin();

function Analytics({ history }) {
    useEffect(() => {
        if (Env.applicationInsightsKey && !window.ai) {
            window.log("We are starting up app insights tracking, with key",
                Env.applicationInsightsKey);
            window.ai = new ApplicationInsights({
                config: {
                    instrumentationKey: Env.applicationInsightsKey,
                    loggingLevelConsole: 2,
                    enableDebug: true,
                    verboseLogging: true,
                    enableAjaxPerfTracking : true,
                    maxAjaxCallsPerView: -1,
                    maxBatchInterval: 0,
                    disableFetchTracking: false,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: { history }
                    }
                }
            });
            window.ai.loadAppInsights();
            window.ai.trackPageView();
        }
    }, []);
  
    return null;
}

export default withAITracking(
    reactPlugin,
    Analytics
);