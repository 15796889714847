export const TRACK_TRIP_SET_TRIP = 'TRACK_TRIP_SET_TRIP';
export const trackTripSetTrip = (trip) => ({
    type: TRACK_TRIP_SET_TRIP,
    trip
});

export const TRACK_TRIP_SET_TRIP_TYPE =
  'TRACK_TRIP_SET_TRIP_TYPE';
export const trackTripSetTripType = (tripType) => ({
    type: TRACK_TRIP_SET_TRIP_TYPE,
    tripType
});

export const TRACK_TRIP_SET_IS_LOADING =
  'TRACK_TRIP_SET_IS_LOADING';
export const trackTripSetIsLoading = (isLoading) => ({
    type: TRACK_TRIP_SET_IS_LOADING,
    isLoading
});
