export const SET_LOADING_USER = 'SET_LOADING_USER';
export const internalLoadingCall = (payload) => ({
    type: SET_LOADING_USER,
    isLoading: payload
});

export const SET_OPEN_USER_POPUP = 'SET_OPEN_USER_POPUP';
export const setOpenUserPopUp = (isOpen) => ({
    type: SET_OPEN_USER_POPUP,
    isOpen
});

export const SET_OPEN_USER_MOBILE = 'SET_OPEN_USER_MOBILE';
export const setOpenUserMobile = (isOpen) => ({
    type: SET_OPEN_USER_MOBILE,
    isOpen
});

export const SET_LOADING_USER_UPDATE = 'SET_LOADING_USER_UPDATE';
export const internalUpdateLoadingCall = (payload) => ({
    type: SET_LOADING_USER_UPDATE,
    isLoadingUpdate: payload
});

export const USERS_LOAD = 'USERS_LOAD';
export const internalLoad = (users) => ({
    type: USERS_LOAD,
    users
});

export const USERS_FILTER = 'USERS_FILTER';
export const internalFilter = (term, filterOption, columnSorter) => ({
    type: USERS_FILTER,
    term,
    filterOption,
    columnSorter
});

export const USERS_TERM_FILTER = 'USERS_TERM_FILTER';
export const userTermFilter = term => ({
    type: USERS_TERM_FILTER,
    term
});

export const USERS_OPTION_FILTER = 'USERS_OPTION_FILTER';
export const userOptionFilter = option => ({
    type: USERS_OPTION_FILTER,
    option
});

export const SET_FILTERED_USERS = 'SET_FILTERED_USERS';
export const internalFilteredUsers = (userList, userOrderedList) => ({
    type: SET_FILTERED_USERS,
    userList,
    userOrderedList
});

export const SET_USER_FIRSTNAME_COLUMN = 'SET_USER_FIRSTNAME_COLUMN';
export const setUserFirstNameColumn = sort => ({
    type: SET_USER_FIRSTNAME_COLUMN,
    payload: sort
});

export const SET_USER_LASTNAME_COLUMN = 'SET_USER_LASTNAME_COLUMN';
export const setUserLastNameColumn = sort => ({
    type: SET_USER_LASTNAME_COLUMN,
    payload: sort
});

export const SET_USER_EMAIL_COLUMN = 'SET_USER_EMAIL_COLUMN';
export const setUserEmailColumn = sort => ({
    type: SET_USER_EMAIL_COLUMN,
    payload: sort
});

export const SET_USER_COLUMN_SORT = 'SET_USER_COLUMN_SORT';
export const setUserColumnSort = columnSorter => ({
    type: SET_USER_COLUMN_SORT,
    columnSorter
});

export const SET_COLUMNS_CHECK_ALL = 'SET_COLUMNS_CHECK_ALL';
export const setColumnsCheckAll = (checkedAll) => ({
    type: SET_COLUMNS_CHECK_ALL,
    checkedAll
});

export const SET_USER_OBJECT = 'SET_USER_OBJECT';
export const setUserObject = userObject => ({
    type: SET_USER_OBJECT,
    userObject
});

export const SET_SAVE_BUTTON = 'SET_SAVE_BUTTON';
export const setSaveButtonDisabled = disabled => ({
    type: SET_SAVE_BUTTON,
    disabled
});

export const SET_USER_RESPONSE = 'SET_USER_RESPONSE';
export const setUserResponse = (userResponse) => ({
    type: SET_USER_RESPONSE,
    userResponse
});

export const SET_OPEN_USER_DELETE_MODAL = 'SET_OPEN_USER_DELETE_MODAL';
export const setOpenUserDeleteModal = (showDeleteUserModal) => ({
    type: SET_OPEN_USER_DELETE_MODAL,
    showDeleteUserModal
});

export const SET_USER_FILTER_OPTIONS = 'SET_USER_FILTER_OPTIONS';
export const setFilterOptions = (filterOptions) => ({
    type: SET_USER_FILTER_OPTIONS,
    filterOptions
});
