import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import withRouter from 'util/customHooks/withRouter';

import {
    initialSetup,
    goToFacilityDetail,
    goToCalendar
} from 'redux/workers/districtTripTracker/dttFacilityWorker';

import DttFacilities from 'components/districtTripTracker/DttFacility/DttFacilities';

const mapStateToProps = state => {
    const { DttFacilityReducers } = state;

    return {
        isLoading: DttFacilityReducers.isLoading
    };
};

const mapDispatchToProps = {
    initialSetup,
    goToFacilityDetail,
    goToCalendar
};

const DttFacilityContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(DttFacilities)));

export default DttFacilityContainer;