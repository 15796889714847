import { localize } from "util/Localizer";

export const DttTabOptions = {
    Schools: 0,
    Students: 1
};

export const DttTabModel = [
    {
        label: localize("districtTripTracker.filterSchools"),
        value: DttTabOptions.Schools
    },
    {
        label: localize("districtTripTracker.filterStudents"),
        value: DttTabOptions.Students
    }
];