import Auth from "auth/Auth";
import {
    days as weekDays,
    TransportationRequestDates,
    TransportationRequestDays,
    TransportationRequestRecurringTrip,
    TransportationRequestRecurringTripOptions as recurringOptions,
    TransportationRequestSegments,
    TransportationRequestTimeDays,
    TransportationRequestTripTypes,
    TransportationRequestDatesOptions as DatesOptions,
    TransportationRequestSegmentsOptions as SegmentsOptions
} from "data/models/transportationRequest/TripBuilderModel";
import { localize } from "util/Localizer";
import { getDayName, getPlusDays, tomorrow, trIsEndDateValid, trIsStartDateValid } from "util/dateUtils";

const dataDecorator = (data) => {
    const dataFormated = [];
    
    data.map((d) => (
        dataFormated.push({
            id: d.id,
            label: localize(d.label)
        })
    ));

    return dataFormated;
};

export const dataRecurring = () => (
    dataDecorator(TransportationRequestRecurringTrip)
);

const setMinDateEndDate = (date) => (
    date ? new Date(date) : tomorrow()
);

const setStartDate = () => {
    const dayName = getDayName(new Date()).toLowerCase();

    switch (dayName) {
    case weekDays.sunday:
    case weekDays.monday:
    case weekDays.tuesday:
    case weekDays.wednesday:
        return getPlusDays(2);
    case weekDays.thursday:
    case weekDays.friday:
        return getPlusDays(4);
    case weekDays.saturday:
        return getPlusDays(3);
    default:
        return getPlusDays(1);
    }
};

export const dataDatesTrip = (trip) => {
    const datesTrips = [];

    TransportationRequestDates.forEach((date) => (
        datesTrips.push({
            id: date.id,
            label: trip.recurring === recurringOptions.oneTime
                ? localize('transportationRequest.tripDate')
                : localize(date.label),
            value: trip[date.id] ? new Date(trip[date.id]) : null,
            minDate: date.id === DatesOptions.startDate
                ? setStartDate()
                : setMinDateEndDate(trip[DatesOptions.startDate]),
            error: trip.recurring === recurringOptions.oneTime
                ? trIsStartDateValid(trip.startDate)
                : trIsEndDateValid(trip.endDate, trip.startDate)
        })
    ));

    if(trip.recurring === recurringOptions.oneTime) {
        return datesTrips.filter(date => date.id === DatesOptions.startDate);
    }

    return datesTrips;
};

export const dataDaysTrip = () => (
    dataDecorator(TransportationRequestDays)
);

export const dataTripType = () => (
    dataDecorator(TransportationRequestTripTypes)
);

export const dataSegment = (trip, segment) => {
    const segments = [];
    
    TransportationRequestSegments.forEach((field) => (
        segments.push({
            name: field.id,
            label: localize(field.label),
            value: trip[segment][field.section]
        })
    ));

    return segments;
};

export const dataTimes = (trip, type) => {
    const times = [];
    
    TransportationRequestTimeDays.forEach((day) => {
        if(trip.days.includes(day.id)) {
            times.push({
                id: day.id,
                label: localize(day.label),
                value: trip[type][day.id]
            });
        }
    });

    return times;
};

export const getOpositeField = (field) => {
    switch(field) {
    case SegmentsOptions.tripLeavingFrom:
        return SegmentsOptions.tripGoingTo;
    case SegmentsOptions.tripGoingTo:
        return SegmentsOptions.tripLeavingFrom;
    case SegmentsOptions.tripApartment:
        return SegmentsOptions.tripApartment2;
    case SegmentsOptions.tripApartment2:
        return SegmentsOptions.tripApartment;
    default:
        return '';
    }
};

export const groupDaysByTime = (times) => {
    const groupedDays = Object.entries(times).reduce((result, [ day, time ]) => {
        if (time !== '') {
            if (!result[time]) {
                // eslint-disable-next-line no-param-reassign
                result[time] = [day];
            } else {
                result[time].push(day);
            }
        }

        return result;
    }, {});
  
    const groupedObjects = Object.entries(groupedDays).map(([ time, days ]) => ({
        time,
        days
    }));
  
    return groupedObjects;
};

export const convertTo12HourFormat = (time) => {
    const [ hours, minutes ] = time.split(':');
    let formattedTime = '';
  
    let meridiem = 'AM';
    let hour = parseInt(hours, 10);

    if (hour >= 12) {
        meridiem = 'PM';
        if (hour !== 12) {
            hour -= 12;
        }
    }
    if (hour === 0) {
        hour = 12;
    }
  
    formattedTime = `${hour}:${minutes} ${meridiem}`;
    
    return formattedTime;
};

export const getAccountName = (id) => {
    const accounts = Auth?.getAccounts() ?? [];

    const account = accounts?.filter((acc) => (
        acc.AccountID === id
    ));

    return account[0]?.Name;
};