/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
import {
    setStudentErrors,
    setContactsErrors,
    setTripBuilderErrors
} from "redux/actions/transportationRequest/transportationRequestErrorsActions";
import {
    setTabValue,
    setTitle,
    setTrips
} from "redux/actions/transportationRequest/transportationRequestActions";
import { localize } from "util/Localizer";
import { Enum } from "util/EnumHelper";
import {
    dateIsValid,
    minimumDateAllowed,
    trIsEndDateValid,
    trIsStartDateValid,
    yesterday
} from "util/dateUtils";
import { isPhoneNumber, isTRFAddressValid } from 'util/validationUtil';
import { validateEmail, stringValidate } from "util/textFormatters";
import {
    TransportationRequestTripTypesOptions as TripTypesOptions,
    TransportationRequestRecurringTripOptions as recurringOptions,
    TransportationRequestSegmentTypes as SegmentTypes,
    TransportationRequestDays as optionsDays2,
    TransportationRequestSegmentsOptions
} from "data/models/transportationRequest/TripBuilderModel";
import {
    closeStyledSnackBar,
    openStyledSnackBar,
    setSnackbar
} from '../styledSnackbarWrapperWorker';

const isValidStudentTab = () => (dispatch, getState) => {
    const {tripModel, hasTN, transportationNeeds} = getState().TransportationRequestReducers;
    
    const {
        studentFirstName,
        studentLastName,
        studentId,
        studentDateOfBirth,
        selectedWheelchair,
        selectedSafetyVest
    } = tripModel;

    dispatch(setStudentErrors({}));
    
    const errors = {};

    if (stringValidate(studentFirstName)) {
        errors.studentFirstName = {isError: true};
    }

    if (stringValidate(studentLastName)) {
        errors.studentLastName = {isError: true};
    }
    if (stringValidate(studentId)) {
        errors.studentId = {isError: true};
    }

    if (studentDateOfBirth === null || !dateIsValid(studentDateOfBirth) || studentDateOfBirth < minimumDateAllowed()) {
        errors.studentDateOfBirth = {isError: true, message: localize('transportationRequest.invalidBirthDateMessage')};
    } else if (studentDateOfBirth > yesterday()) {
        errors.studentDateOfBirth = {isError: true, message: localize('transportationRequest.maxBirthDateMessage')};
    }

    if (hasTN && !Object.values(transportationNeeds).some(x => x === true)) {
        errors.transportationNeeds = {
            isError: true,
            message: localize('transportationRequest.transportationNeedsMessage')
        };
    }

    if (hasTN && transportationNeeds.wheelchair && !selectedWheelchair) {
        errors.wheelchair = {isError: true};
    }

    if (hasTN && transportationNeeds.safetyVest && !selectedSafetyVest) {
        errors.safetyVest = {isError: true};
    }

    dispatch(setStudentErrors(errors));

    return !Object.values(errors).some(x => x.isError);
};

const checkEmailError = (appAccess, emailAddress, errors, key) => {
    if (appAccess && (stringValidate(emailAddress) || validateEmail(emailAddress))) {
        errors[`${key}EmailAddress`] = {
            isError: true,
            message: localize('transportationRequest.invalidEmail')
        };
    }
    if (!appAccess && ((emailAddress) && validateEmail(emailAddress))) {
        errors[`${key}EmailAddress`] = {
            isError: true,
            message: localize('transportationRequest.invalidEmail')
        };
    }
};

const isValidContactsTab = () => (dispatch, getState) => {
    const {tripModel, numberOfContacts} = getState().TransportationRequestReducers;
    
    dispatch(setContactsErrors({}));

    const errors = {};

    for (let index = 1; index <= numberOfContacts; index+=1) {
        const key = `contact${index}`;
        
        if (stringValidate(tripModel[`${key}Name`])) {
            errors[`${key}Name`] = {isError: true};
        }
        if (stringValidate(tripModel[`${key}Relationship`])) {
            errors[`${key}Relationship`] = {isError: true};
        }
        if (stringValidate(tripModel[`${key}PhoneNumber`])
            || !isPhoneNumber(tripModel[`${key}PhoneNumber`])) {
            errors[`${key}PhoneNumber`] = {isError: true};
        }
        if (tripModel[`${key}AltPhoneNumber`] && !isPhoneNumber(tripModel[`${key}AltPhoneNumber`])) {
            errors[`${key}AltPhoneNumber`] = {isError: true};
        }

        checkEmailError(tripModel[`${key}AppAccess`], tripModel[`${key}EmailAddress`], errors, key);
    }
    
    dispatch(setContactsErrors(errors));

    return !Object.values(errors).some(x => x.isError);
};

const checkSegmentInfo = (errors, segmentType, segment) => {
    const { tripLeavingFrom, tripGoingTo } = segment;

    if (!isTRFAddressValid(tripLeavingFrom)) {
        errors[`${segmentType}-tripLeavingFrom`] = { isError: true };
        window.log(`Error: invalid address\nSegment: ${segmentType}\nLeaving From Address:`, tripLeavingFrom);
    }

    if (stringValidate(tripLeavingFrom.phone)
        || !isPhoneNumber(tripLeavingFrom.phone)) {
        errors[`${segmentType}-${TransportationRequestSegmentsOptions.tripPhone}`] = {isError: true};
    }

    if (!isTRFAddressValid(tripGoingTo)) {
        errors[`${segmentType}-tripGoingTo`] = { isError: true };
        window.log(`Error: invalid address\nSegment: ${segmentType}\nGoing To Address:`, tripGoingTo);
    }

    if (stringValidate(tripGoingTo.phone)
        || !isPhoneNumber(tripGoingTo.phone)) {
        errors[`${segmentType}-${TransportationRequestSegmentsOptions.tripPhone2}`] = {isError: true};
    }
};

const checkSingleTime = (errors, segmentType, hasTimeMultiples, singleTime) => {
    if(!hasTimeMultiples && singleTime === '') {
        errors[`${segmentType}SingleTime`] = { isError: true };
    }
};

const checkMultipleTimes = (errors, segmentType, hasMultiplesTimes, tripDays, times) => {
    if(hasMultiplesTimes) {
        optionsDays2.forEach((day) => {
            if(tripDays.includes(day.id) && times[day.id] === '') {
                errors[`${segmentType}-${day.id}Time`] = { isError: true };
                errors[`${segmentType}-Times`] = {
                    isError: true,
                    message: localize('transportationRequest.multipleTimesError')
                };
            }
        });
    }
};

const isValidTripBuilderTab = () => (dispatch, getState) => {
    const { trips } = getState().TransportationRequestReducers;
    
    dispatch(setTripBuilderErrors([]));

    const errors = [];

    for (let index = 0; index < trips.length; index+=1) {
        errors.push({});

        const {
            startDate,
            endDate,
            recurring,
            arrival,
            departure,
            tripTypes,
            arrivalHasTimeMultiples,
            arrivalSingleTime,
            departureHasTimeMultiples,
            departureSingleTime,
            arrivalTime,
            departureTime,
            days
        } = trips[index];

        if (!trIsStartDateValid(startDate)) {
            errors[index].startDate = { isError: true };
        }
        if (recurring === recurringOptions.recurring && !trIsEndDateValid(endDate, startDate)) {
            errors[index].endDate = { isError: true };
        }
        checkSegmentInfo(errors[index], SegmentTypes.arrival, arrival);
        if(tripTypes.includes(TripTypesOptions.multiStop)) {
            checkSegmentInfo(errors[index], SegmentTypes.departure, departure);
        }
        checkSingleTime(errors[index], SegmentTypes.arrival, arrivalHasTimeMultiples, arrivalSingleTime);
        if(!tripTypes.includes(TripTypesOptions.oneWay)) {
            checkSingleTime(errors[index], SegmentTypes.departure, departureHasTimeMultiples, departureSingleTime);
        }
        checkMultipleTimes(errors[index], SegmentTypes.arrival, arrivalHasTimeMultiples, days, arrivalTime);
        checkMultipleTimes(errors[index], SegmentTypes.departure, departureHasTimeMultiples, days, departureTime);

        if (Object.keys(errors[index]).length !== 0) {
            trips[index].collapsed = false;
            dispatch(setTrips([...trips]));
        }
    }
    
    dispatch(setTripBuilderErrors(errors));
    let isValid = false;

    errors.forEach(error => {
        isValid = isValid || (Object.values(error).some(x => x.isError));
    });
    
    return !isValid;
};

const canChangeTab = (tab) => (dispatch) => {
    switch (tab) {
    case 0:
        return dispatch(isValidStudentTab());
    case 1:
        return dispatch(isValidContactsTab());
    case 2:
        return dispatch(isValidTripBuilderTab());
    default:
        break;
    }

    return true;
};

export const onChangeTab = (newTabValue) => (dispatch, getState) => {
    const { tabValue, tripModel } = getState().TransportationRequestReducers;

    const isValid = dispatch(canChangeTab(tabValue));

    if (newTabValue < tabValue || isValid) {
        if(newTabValue <= (tabValue+1)) {
            dispatch(setTabValue(newTabValue));
        }
        if(newTabValue === 1) {
            dispatch(setTitle(`${tripModel.studentFirstName?.trim()} ${tripModel.studentLastName?.trim()}`));
        }
    } else {
        dispatch(setSnackbar(
            localize('transportationRequest.tabValidationError'),
            Enum.severityType.error
        ));
        dispatch(openStyledSnackBar());
        setTimeout(
            () => {
                dispatch(closeStyledSnackBar());
            },
            4000
        );
    }
};

export default onChangeTab;