import React from 'react';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import colorConstants from 'styles/ColorConstants';
import { localize } from '../../util/Localizer';
import { isPhoneNumber } from '../../util/validationUtil';
import TextMaskPhone from '../../widgets/common/TextMaskPhone';
import StyledInput from '../../components/Common/StyledInput/StyledInput';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginTop: theme.spacing(1),
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    "& .Mui-error::after": {
        borderBottomColor: theme.palette.everDrivenFuchsia
    }
}));

const PhoneInput = styled(StyledInput)((props) => {
    const hideLabel = /^true$/i.test(props?.hidelabel);
    const phoneInput = {
        backgroundColor: props.theme.palette.everDrivenLightBlue20,
        paddingLeft: 12,
        '&.Mui-disabled': {
            backgroundColor: props.theme.palette.everDrivenLightBlue60,
            WebkitTextFillColor: colorConstants.WEBKITTEXTFILLCOLOR
        }
    };

    const noLabelInput = {
        height: 48,
        paddingTop: 16
    };

    return {
        ...phoneInput,
        ...(hideLabel && noLabelInput)
    };
});

function PhoneNumber({
    idMobilePhone,
    modelInput,
    handleChangeModel,
    placeHolderName,
    disabled,
    isRequired,
    hideLabel,
    inputProps
}) {
    return (<StyledFormControl fullWidth required={isRequired}>
        {!hideLabel &&
            <InputLabel sx={{ marginLeft: 12 }}
                htmlFor={idMobilePhone}>{localize(placeHolderName)}
            </InputLabel>
        }
        <PhoneInput
            id={idMobilePhone}
            autoComplete="disabled"
            fullWidth
            hidelabel={hideLabel?.toString()}
            placeholder="(000) 000-0000"
            value={modelInput}
            onChange={handleChangeModel}
            name={idMobilePhone}
            inputComponent={TextMaskPhone}
            inputProps={inputProps}
            isDisabled={disabled}
            isError={modelInput ? !isPhoneNumber(modelInput) : false}

        />
    </StyledFormControl>);
}

export default PhoneNumber;
