import { connect } from 'react-redux';
import StudentNotes from 'components/TransportationRequest/StudentNotes/StudentNotes';
import {
    setShowRemoveStudentNotesModal,
    setShowStudentNotes
} from 'redux/actions/transportationRequest/transportationRequestActions';
import { onUpdateModel } from 'redux/workers/transportationRequest/transportationRequestWorker';
import { onRemoveStudentNotes } from 'redux/workers/transportationRequest/transportationRequestStudentWorker';

const mapStateToProps = state => {
    const {
        TransportationRequestReducers,
        TransportationRequestModalReducers
    } = state;

    return {
        showRemoveStudentNotesModal: TransportationRequestModalReducers.showRemoveStudentNotesModal,
        showStudentNotes: TransportationRequestReducers.showStudentNotes,
        tripModel: TransportationRequestReducers.tripModel
    };
};

const StudentNotesContainer = connect(mapStateToProps,
    {
        onRemoveStudentNotes,
        onUpdateModel,
        setShowRemoveStudentNotesModal,
        setShowStudentNotes
    })(StudentNotes);

export default StudentNotesContainer;
