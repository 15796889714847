export const STYLED_HEADER_SET_OPTION = 'STYLED_HEADER_SET_OPTION';
export const styledHeaderSetOption = option => ({
    type: STYLED_HEADER_SET_OPTION,
    option
});

export const STYLED_HEADER_SET_OPTIONS = "STYLED_HEADER_SET_OPTIONS";
export const styledHeaderSetOptions = (options) => ({
    type: STYLED_HEADER_SET_OPTIONS,
    options
});
