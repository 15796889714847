import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 * (This functionality was removed from react-router 6, that's why we added the custom hook)
 *
 * @param  blocker
 * @param  when
 * @see https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743
 */
function useBlocker(blocker, when = true) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (when) return;

        const unblock = navigator.block((tx) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                }
            };

            blocker(autoUnblockingTx);
        });

        // eslint-disable-next-line consistent-return
        return unblock;
    }, [ navigator, blocker, when ]);
}

export default useBlocker;