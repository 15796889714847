import { SIGNALR_INIT } from "redux/actions/signalRActions";

const initialState = {
    // Holding a reference to the signalR object
    signalR: null

};

/* eslint-disable-next-line default-param-last */
const signalRReducers = (state = initialState, action) => {
    if(action.type === SIGNALR_INIT) {
        return {
            ...state,
            signalR: action.signalR
        };
    }
    
    return state;
};

export default signalRReducers;
