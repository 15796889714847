import React from 'react';
import {
    FormControl,
    Grid,
    RadioGroup,
    Radio,
    FormHelperText
} from '@mui/material';
import PropTypes from 'prop-types';
import { localize } from 'util/Localizer';
import CommonStyles from 'styles/CommonStyles';
import StyledFormLabel from 'components/Common/StyledFormLabel/StyledFormLabel';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import StyledFormControlLabel from 'components/Common/StyledFormControlLabel/StyledFormControlLabel';
import StyledCheckbox from 'components/Common/StyledCheckbox/StyledCheckbox';
import { safetyVest, wheelchair } from 'redux/constants';
import StyledModal from 'components/Common/StyledModal/StyledModal';
import {
    InitialTransportationNeed,
    TransportationNeedOptions,
    MonitorNeededOptions,
    TransportationNeedDescription
} from 'data/models/descriptions/TransportationRequestModels';
import {
    museoSansFont,
    museoSansFont700
} from 'util/EnumHelper';
import { isOdd } from 'util/validationUtil';
import StyledDropdown from 'components/Common/StyledDropdown/StyledDropdown';
import { stringValidate } from 'util/textFormatters';

function TransportationNeed({
    disabledCarSeat,
    disabledSafetyVest,
    hasTN,
    transportationNeeds,
    tripModel,
    onUpdateModel,
    onUpdateTransportationNeed,
    setHasTN,
    errors
}) {
    const [ openLearnMore, setOpenLearnMore ] = React.useState(false);

    const onChangeShowTransportationNeeds = () => {
        setHasTN(!hasTN);
    };

    const onChangeTransportationNeeds = (evt) => {
        if (evt === null) {
            return false;
        }

        onUpdateTransportationNeed(evt);

        return true;
    };

    const onChangeOptionalValue = (evt) => {
        if (evt === null) {
            return false;
        }

        onUpdateModel(evt);

        return true;
    };

    const renderTransportationNeedsHelper = () => TransportationNeedDescription.map(item => (
        <Grid item xs={12} key={item.label} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <p style={{ fontFamily: museoSansFont, fontSize: 18 }}><b>{item.label}: </b>{item.content}</p>
        </Grid>
    ));

    const isDisabled = (name) => {
        switch (name) {
        case 'carSeat':
            return disabledCarSeat;
        case 'safetyVest':
            return disabledSafetyVest;
        default:
            return false;
        }
    };

    const renderTransportationNeeds = () => InitialTransportationNeed.map((item, index) => (
        <Grid key={item.name} item xs={6} sx={
            {
                paddingTop: index === 0 || index === 1 ? '10px': 0,
                paddingLeft: !isOdd(index) ? '0px': '20px',
                paddingRight: !isOdd(index) ? '20px': '0px',
                paddingBottom: '15px'
            }}
        >
            <StyledFormControlLabel
                isDisabled={isDisabled(item.name)}
                testId={item.name}
                size="medium"
                control={<StyledCheckbox
                    name={item.name}
                    color="secondary"
                    inputProps={{ "data-testid": `checkBox_${item.name}` }}
                    isChecked={transportationNeeds[item.name]}
                    isDisabled={isDisabled(item.name)}
                    size="medium"
                    onChange={onChangeTransportationNeeds}
                />}
                label={item.label}
            />
            {item.name === TransportationNeedOptions.wheelchair
                && transportationNeeds.wheelchair
                && <StyledDropdown
                    items={wheelchair}
                    name="selectedWheelchair"
                    value={tripModel.selectedWheelchair}
                    onChange={onChangeOptionalValue}
                    emptyOption={localize('transportationRequest.selectOption')}
                    extraStyle={{ paddingLeft: 30 }}
                    isError={errors.wheelchair?.isError && stringValidate(tripModel.selectedWheelchair)}
                />
            }
            {item.name === TransportationNeedOptions.safetyVest
                && transportationNeeds.safetyVest
                && <StyledDropdown
                    items={safetyVest}
                    name="selectedSafetyVest"
                    value={tripModel.selectedSafetyVest}
                    onChange={onChangeOptionalValue}
                    emptyOption={localize('transportationRequest.selectOption')}
                    extraStyle={{ paddingLeft: 30 }}
                    isError={errors.safetyVest?.isError && stringValidate(tripModel.selectedSafetyVest)}
                />
            }
            {item.name === TransportationNeedOptions.monitorNeeded && transportationNeeds.monitorNeeded &&
                <FormControl sx={{ paddingLeft: '35px', display: 'flex' }} variant="standard">
                    <RadioGroup
                        aria-label="selectedMonitorNeeded"
                        name="selectedMonitorNeeded"
                        value={tripModel.selectedMonitorNeeded}
                        onChange={(e) => onUpdateModel(e)}
                    >
                        <StyledFormControlLabel
                            testId="monitorNeededDistrict"
                            value={MonitorNeededOptions.districtMonitor}
                            control={<Radio color="secondary" />}
                            label={localize('transportationRequest.districtMonitor')}
                        />
                        <StyledFormControlLabel
                            testId="monitorNeededEverDriven"
                            value={MonitorNeededOptions.everDrivenMonitor}
                            control={<Radio color="secondary" />}
                            label={localize('transportationRequest.everDrivenMonitor')}
                        />
                    </RadioGroup>
                </FormControl>
            }
        </Grid>
    ));

    return (
        <>
            <Grid item xs={12} sx={{ paddingTop: '5px' }}>
                <StyledFormLabel
                    label={localize('transportationRequest.haveTransportationNeed')}
                    isRequired
                    extraStyle={{ fontFamily: museoSansFont, height: 34 }}
                    markStyle={{ fontFamily: museoSansFont700 }}
                />
                <FormControl sx={{ paddingTop: '11px', paddingBottom: '10px' }}>
                    <RadioGroup
                        aria-label="hasTransportationNeed"
                        name="hasTransPortationNeed"
                        row
                        value={hasTN}
                        onChange={onChangeShowTransportationNeeds}
                    >
                        <StyledFormControlLabel
                            testId="hasTransportationNeedYes"
                            value
                            control={<Radio
                                color="secondary"
                                inputProps={{ "data-testid": "radio_hasTransportationNeedYes" }}/>}
                            label={localize('transportationRequest.yes')}
                        />
                        <StyledFormControlLabel
                            extraStyle={{ paddingLeft: 45 }}
                            testId="hasTransportationNeedNo"
                            value={false}
                            control={<Radio
                                color="secondary"
                                inputProps={{ "data-testid": "radio_hasTransportationNeedNo" }} />}
                            label={localize('transportationRequest.no')}
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            { hasTN &&
                <>
                    <Grid item xs={12} sx={{ paddingTop: '10px', display: 'flex' }}>
                        <StyledFormLabel
                            internalStyle={{ display: 'flex', alignItems: 'flex-end' }}
                            label={localize('transportationRequest.transportationNeeds')}
                            isRequired
                        />
                        <StyledButton
                            color="inherit"
                            style={{ ...CommonStyles.linkButton, paddingLeft: 20, alignItems: 'flex-end' }}
                            type="button"
                            variant="text"
                            testId="openLearnMoreTNButton"
                            onClick={() => setOpenLearnMore(true)}
                        >
                            {localize('transportationRequest.learnMore')}
                        </StyledButton>
                    </Grid>
                    <Grid item xs={12}>
                        <FormHelperText sx={{marginLeft: 0, fontFamily: museoSansFont}}>
                            {errors.transportationNeeds?.message}
                        </FormHelperText>
                    </Grid>
                    {renderTransportationNeeds()}
                </>
            }
            <StyledModal
                open={openLearnMore}
                title={localize('transportationRequest.transportationNeeds')}
                onClose={() => setOpenLearnMore(false)}
            >
                {renderTransportationNeedsHelper()}
            </StyledModal>
        </>
    );
}

TransportationNeed.defaultProps = {
    disabledCarSeat: false,
    disabledSafetyVest: false,
    hasTN: false,
    transportationNeeds: null,
    tripModel: null,
    onUpdateModel: null,
    onUpdateTransportationNeed: null,
    setHasTN: null,
    errors: {}
};

/* eslint-disable react/forbid-prop-types */
TransportationNeed.propTypes = {
    disabledCarSeat: PropTypes.bool,
    disabledSafetyVest: PropTypes.bool,
    hasTN: PropTypes.bool,
    transportationNeeds: PropTypes.object,
    tripModel: PropTypes.object,
    onUpdateModel: PropTypes.func,
    onUpdateTransportationNeed: PropTypes.func,
    setHasTN: PropTypes.func,
    errors: PropTypes.object
};
/* eslint-enable react/forbid-prop-types */

export default TransportationNeed;