export const PAGINATION_SET_ACTIVE_PAGE =
  'PAGINATION_SET_ACTIVE_PAGE';
export const paginationSetActivePage = activePage => ({
    type: PAGINATION_SET_ACTIVE_PAGE,
    activePage
});

export const PAGINATION_SET_LIMIT_ROWS =
  'PAGINATION_SET_LIMIT_ROWS';
export const paginationSetLimitRows = limitRows => ({
    type: PAGINATION_SET_LIMIT_ROWS,
    limitRows
});

export const PAGINATION_SET_ROWS_SHOWED =
  'PAGINATION_SET_ROWS_SHOWED';
export const paginationSetRowsShowed = rowsShowed => ({
    type: PAGINATION_SET_ROWS_SHOWED,
    rowsShowed
});

export const PAGINATION_RESET_VALUES =
  'PAGINATION_RESET_VALUES';
export const paginationReset = () => ({
    type: PAGINATION_RESET_VALUES
});