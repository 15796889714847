import {
    dttFacilitySetSearchFacilities,
    dttFacilitySortByColumn,
    dttFacilitySearchTerm,
    dttFacilitySetFacilities,
    dttFacilityIsLoading,
    dttFacilityStateColumns
} from 'redux/actions/districtTripTracker/dttFacilityActions';
import { getFacilities } from 'services/facilityService';
import { paths } from "util/EnumHelper";
import { changeSort, compareValues } from "util/sorterUtil";
import { saveLocationPreferences } from "util/preferenceUtil";
import Auth from 'auth/Auth';

import {
    paginationSetLimitRows,
    paginationSetActivePage,
    paginationReset
} from 'redux/actions/StyledTablePaginationActions';

import { updatePath, accessPathMiddleware } from "../pathWorker";

const PLUS_PAGE = 1;

const filterFacilitiesBySearchTerm = (f, term) => (
    (f.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
      f.address.toLowerCase().indexOf(term.toLowerCase()) > -1)
);

const mapFacilities = (facilities, fromConfig = false) => facilities.map(f => {
    const address1 = fromConfig ? f.address1 : f.payload.Location.Address1;
    const address2 = fromConfig ? f.address2 || '' : f.payload.Location.Address2 || '';
    const city = fromConfig ? f.city: f.payload.Location.City;
    const state = fromConfig ? f.state : f.payload.Location.State;
    const zip = fromConfig ? f.zip || '' : f.payload.Location.Zip || '';

    return {
        id: fromConfig ? f.id : f.keyValue,
        name: fromConfig ? f.name : f.payload.FacilityName,
        address: `${address1}${address2}, ${city}, ${state} ${zip}`
    };
});

const searchFacilities = (query) => {
    // FilterSearch from in memory Facilities
    const allFacilities = Auth.getConfig().facilities.filter(f => f.activeFlag);
    const mappedFacilities = mapFacilities(allFacilities, true);

    const filteredFacilities = mappedFacilities.filter(facility => filterFacilitiesBySearchTerm(facility,
        query));

    return filteredFacilities;
};

const sortFacilities = (columnName, dttFacilityColumns, dttFacilities) => (
    dttFacilities.sort(
        compareValues(columnName,
            dttFacilityColumns[columnName].sort)
    )
);

const getFacilitiesEndpoint = (page) => async(dispatch, getState) => {
    dispatch(dttFacilityIsLoading(true));

    const {
        dttFacilityColumns,
        dttFacilitySortColumn
    } = getState().DttFacilityReducers;

    const {
        rowsShowed
    } = getState().StyledTablePaginationReducer;

    const response = await getFacilities(
        rowsShowed,
        page + PLUS_PAGE,
        dttFacilityColumns[dttFacilitySortColumn].sort,
        dttFacilitySortColumn
    );

    const results = mapFacilities(response.results);

    dispatch(paginationSetLimitRows(response.totalRows));
    dispatch(dttFacilitySetFacilities(results));
    dispatch(dttFacilityIsLoading(false));
};

export const sortFacilitiesByColumn = columnName => (dispatch, getState) => {
    const {
        dttFacilityColumns,
        dttFacilitySearchResults,
        dttFacilitySearch
    } = getState().DttFacilityReducers;

    const {
        rowsShowed
    } = getState().StyledTablePaginationReducer;

    const newSort = changeSort(dttFacilityColumns[columnName].sort);

    dttFacilityColumns[columnName].sort = newSort;
    
    dispatch(dttFacilityStateColumns(columnName, newSort));
    dispatch(dttFacilitySortByColumn(columnName));

    dispatch(paginationSetActivePage(0));

    if(dttFacilitySearch === '') {
        dispatch(getFacilitiesEndpoint(0));
    } else {
        const facilitiesSorted = sortFacilities(columnName, dttFacilityColumns, dttFacilitySearchResults);

        dispatch(dttFacilitySetFacilities(
            facilitiesSorted.slice(rowsShowed * 0, rowsShowed * PLUS_PAGE)
        ));
    }
};

export const facilitySetSearch = (searchTerm = '') => async (dispatch, getState) => {
    const {
        dttFacilityColumns,
        dttFacilitySortColumn
    } = getState().DttFacilityReducers;

    const {
        rowsShowed
    } = getState().StyledTablePaginationReducer;

    dispatch(paginationReset());
    dispatch(dttFacilitySearchTerm(searchTerm));

    let results = [];

    if (searchTerm === '') {
        dispatch(getFacilitiesEndpoint(0));
    } else {
        const filteredResults = searchFacilities(searchTerm);

        const sortedResults = sortFacilities(
            dttFacilitySortColumn,
            dttFacilityColumns,
            filteredResults
        );

        dispatch(dttFacilitySetSearchFacilities(sortedResults));
        dispatch(paginationSetLimitRows(sortedResults.length));

        results = sortedResults.slice(rowsShowed * 0, rowsShowed * PLUS_PAGE);
    }

    dispatch(dttFacilitySetFacilities(results));
};

export const initialSetup = () => async (dispatch, getState) => {
    const { dttFacilitySearch } = getState().DttFacilityReducers;

    dispatch(paginationReset());
    dispatch(facilitySetSearch(dttFacilitySearch));
};

export const goToFacilityDetail = (facility) => (dispatch) => {
    if (facility) {
        accessPathMiddleware(Auth.getConfig().areReportsEnabled);
        saveLocationPreferences(facility);
        dispatch(updatePath(paths.SchoolDetail.tabValue, facility.id));
    }
};

export const goToCalendar = (facility) => (dispatch) => {
    if (facility) {
        accessPathMiddleware(Auth.getConfig().areReportsEnabled);
        saveLocationPreferences(facility);
        dispatch(updatePath(paths.CalendarDetail.tabValue, facility.id));
    }
};

export const setPage = (page) => async (dispatch, getState) => {
    const {
        dttFacilitySearch,
        dttFacilitySearchResults
    } = getState().DttFacilityReducers;
    const {
        rowsShowed
    } = getState().StyledTablePaginationReducer;

    dispatch(paginationSetActivePage(page));

    if(dttFacilitySearch === '') {
        dispatch(getFacilitiesEndpoint(page));
    } else {
        dispatch(
            dttFacilitySetFacilities(
                dttFacilitySearchResults.slice(rowsShowed * page, rowsShowed * (page + PLUS_PAGE))
            ));
    }
};