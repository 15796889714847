/* eslint max-lines: 0 */

import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DPApp from 'components/DPApp';
import StyledTextField from 'components/Common/StyledTextField/StyledTextField';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import CalendarUploadList from 'components/calendar/CalendarUploadList';
import StyledLoading from 'components/Common/StyledLoading/StyledLoading';
import CalendarUploadedList from 'components/calendar/CalendarUploadedList';
import StyledHeaderContent from 'components/Common/StyledHeaderContent/StyledHeaderContent';
import StyledTypography from 'components/Common/StyledTypography/StyledTypography';
import DropZone from 'controls/Common/DropZone';
import ModalConfirmation from 'controls/Common/ModalConfirmation';
import { Enum, fileError } from 'util/EnumHelper';
import { acceptedFiles } from 'util/validationUtil';
import { maxSizeFileToMegaBytes } from 'util/fileUtil';
import { localize } from 'util/Localizer';
import useBlocker from 'util/customHooks/useBlocker';
import commonUseStyles, { commonStyles } from 'styles/CommonStyles';
import { generateUniqueKey } from 'util/arrayUtil';

const MAX_SIZE_FILE = Enum.maxFileSize;

const CalendarUploadGrid = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.offWhite,
    paddingRight: 15,
    paddingLeft: 15
}));

const NotesGridItem = styled(Grid)(() => ({
    marginTop: 30
}));

const CalendarUploadButtonGrid = styled(Grid)(() => ({
    marginTop: 15,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'right',
    '&> button:nth-of-type(2)': {
        marginLeft: 10
    },
    '&> :last-child': {
        marginLeft: 10
    }
}));

const CalendarUploadTypography = styled(StyledTypography)(({ theme }) => ({
    subTitle: {
        color: theme.palette.everDrivenDarkGrey
    },
    deleteMessage: {
        textAlign: 'center',
        marginBottom: 15
    },
    finalDeleteMessage: {
        textAlign: 'center',
        fontWeight: 700
    }
}));

const DropZoneLabel = styled('span')(({ theme }) => ({
    fontSize: 20,
    color: theme.palette.everDrivenDarkGrey
}));

const FakeLink = styled('u')(({ theme }) => ({
    fontSize: 20,
    color: theme.palette.everDrivenLightBlue
}));

const FormLabel = styled('span')(() => ({
    ...commonUseStyles.customFormLabel
}));

const MaxFileSize = styled('span')(({ theme }) => ({
    color: theme.palette.everDrivenDarkGrey,
    fontSize: 14
}));

const CalendarUploadCancelButton = styled(StyledButton)(({ theme }) => ({
    borderRadius: 8,
    width: 200,
    backgroundColor: theme.palette.white,
    color: theme.palette.everDrivenDarkBlue,
    borderColor: theme.palette.everDrivenDarkBlue,
    border: 2,
    borderStyle: 'solid',
    '&:hover' : {
        color: theme.palette.white,
        backgroundColor: theme.palette.everDrivenDarkBlue,
        borderColor: theme.palette.everDrivenDarkBlue
    },
    '&.Mui-disabled': {
        borderColor: 'transparent'
    }
}));

const CalendarUploadSubmitButton = styled(StyledButton)(({ theme }) => ({
    borderRadius: 8,
    width: 200,
    backgroundColor: theme.palette.everDrivenDarkBlue
}));

const CalendarUploadNotesTextField = styled(StyledTextField)(({ theme }) => ({
    borderColor: theme.palette.everDrivenDarkGrey,
    border: 1,
    borderStyle: 'solid',
    '& > div' : {
        backgroundColor: theme.palette.offWhite,
        '&.Mui-focused': {
            backgroundColor: theme.palette.offWhite
        },
        '&:hover' : {
            backgroundColor: theme.palette.offWhite
        },
        '&:focus' : {
            backgroundColor: theme.palette.offWhite
        }
    }
}));

const CalendarUploadListContainerGrid = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.offWhite,
    paddingRight: 15,
    paddingLeft: 15,
    paddingTop: 20,
    paddingBottom: 20

}));

const CalendarUploadLoadMoreButton = styled(StyledButton)(({ theme }) => ({
    ...commonUseStyles.roundedButton,
    ...commonStyles(theme).loadMoreButton
}));

function CalendarUpload({
    calendars,
    calendarAttachments,
    calendarUploadedResultList,
    disablePaging,
    isClearOpen,
    isDeleteAttachmentOpen,
    isLeavingNoSavingOpen,
    isLoading,
    isNotesModalOpen,
    keyDropZone,
    nextPath,
    notes,
    pageNumber,
    rejectedCalendars,
    selectedNotes,
    showPagination,
    onClearCalendarUpload,
    onDeleteUploadedCalendar,
    onGetUploadedCalendars,
    onLeaveCalendarUpload,
    onLoadForm,
    onSnackBarPropertyChange,
    onUploadCalendarFiles,
    setCalendarUploadNotes,
    setClearModalOpen,
    setDeleteAttachmentModalOpen,
    setKeyDropZone,
    setLeaveNoSavingModalOpen,
    setNextPath,
    setNotesModalOpen,
    setRejectedCalendarFiles,
    submitCalendars
}) {
    const setDeleteBodyMessage = () => (
        <>
            <CalendarUploadTypography variant="caption">
                {localize("calendarUpload.deleteConfirmation")}
            </CalendarUploadTypography>
            <CalendarUploadTypography variant="caption">
                {localize("calendarUpload.deleteConfirmation2")}
            </CalendarUploadTypography>
            <CalendarUploadTypography variant="caption">
                {localize("calendarUpload.deleteConfirmation3")}
            </CalendarUploadTypography>
        </>
    );

    const dropRejectMessage = (rejectedFile) => {
        if(rejectedFile.errors && rejectedFile.errors.length > 0) {
            switch (rejectedFile.errors[0].code) {
            case fileError.fileTooLarge:
                onSnackBarPropertyChange(
                    Enum.severityType.error,
                    localize('calendarUpload.errorMaxSizeFileMessage',
                        { file: rejectedFile.file.name, size: maxSizeFileToMegaBytes(MAX_SIZE_FILE).toString() })
                );
                break;
            case fileError.fileNameHasNonAsciiCharacter:
                onSnackBarPropertyChange(
                    Enum.severityType.error,
                    localize('calendarUpload.fileNameHasASCIICharacters',
                        { file: rejectedFile.file.name })
                );
                break;
            default:
                onSnackBarPropertyChange(
                    Enum.severityType.error,
                    localize('calendarUpload.errorFileMessage',
                        { file: rejectedFile.name })
                );
                break;
            }
        }
    };

    const fileRemovedMessage = (fileName) => {
        onSnackBarPropertyChange(
            Enum.severityType.info,
            localize('calendarUpload.fileRemoved', { file: fileName }));
    };

    const fileLimitExceedMessage = () => {
        onSnackBarPropertyChange(
            Enum.severityType.error,
            localize('calendarUpload.maxFileAllowed'));
    };

    const fileAddedMessage = (fileName) => {
        const exists = calendarAttachments.find(r => r.name === fileName);

        if (exists) {
            onSnackBarPropertyChange(
                Enum.severityType.warning,
                localize('calendarUpload.duplicateFilesMessage'));
        } else {
            onSnackBarPropertyChange(
                Enum.severityType.success,
                localize('calendarUpload.fileSuccessfullyAdded', { file: fileName }));
        }
    };

    const blocker = (tx) => {
        if (calendars.length > 0) {
            setNextPath(tx.location.pathname);
            setLeaveNoSavingModalOpen(true);
        } else {
            tx.retry();
        }
    };

    const onPageChange = () => {
        onGetUploadedCalendars(pageNumber + 1);
    };

    useBlocker(blocker, !calendars.length);

    useEffect(() => {
        // This is for preventing the browser ask for a confirmation to leave the page when you have work unfinished
        window.addEventListener('beforeunload', (event) => {
            event.stopImmediatePropagation();
        });

        setKeyDropZone(generateUniqueKey());
        onGetUploadedCalendars(pageNumber);

        return () => onLoadForm();
    }, []);

    return (
        <DPApp>
            {isLoading
                ? (
                    <StyledLoading isFullWidth message={localize('calendarUpload.processing')} />
                ) : null
            }
            <StyledHeaderContent
                idContainer="calendarUploadHeader"
                title={localize('calendarUpload.title')}
            >
                <Grid item xs={12}>
                    <CalendarUploadTypography variant="h5">
                        {localize('calendarUpload.subTitle')}
                    </CalendarUploadTypography>
                </Grid>
            </StyledHeaderContent>
            <CalendarUploadGrid container>
                <Grid item xs={12}>
                    <DropZone
                        key={keyDropZone}
                        allowedFiles={acceptedFiles}
                        dropZoneText={
                            <>
                                <div>
                                    <DropZoneLabel>
                                        {localize('calendarUpload.dropFiles')}
                                    </DropZoneLabel>
                                    <FakeLink>
                                        {localize('calendarUpload.dropFiles2')}
                                    </FakeLink>
                                </div>
                                <div>
                                    <MaxFileSize>
                                        {localize('calendarUpload.maxFiles')}
                                    </MaxFileSize>
                                </div>
                            </>
                        }
                        filesLimit={5}
                        fileObjects={calendarAttachments}
                        isCalendarUpload
                        maxFileSize={MAX_SIZE_FILE}
                        getDropRejectMessage={dropRejectMessage}
                        getFileAddedMessage={fileAddedMessage}
                        getFileLimitExceedMessage={fileLimitExceedMessage}
                        getFileRemovedMessage={fileRemovedMessage}
                        onChange={(files) => onUploadCalendarFiles(files)}
                        onChangeRejections={(files) => setRejectedCalendarFiles(files)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CalendarUploadList calendars={calendars} rejectedCalendars={rejectedCalendars} />
                </Grid>
                <NotesGridItem item xs={12}>
                    <FormLabel>
                        {localize('calendarUpload.notes')}
                    </FormLabel>
                    <CalendarUploadNotesTextField
                        name="calendarUploadNotes"
                        isMultiline
                        rows={3}
                        inputProps= {{ maxLength: "250", "data-testid": "calendarUploadNotes" }}
                        value={notes}
                        onChange={(e) => setCalendarUploadNotes(e.target.value)}
                    />
                </NotesGridItem>
                <CalendarUploadButtonGrid item xs={12}>
                    <CalendarUploadCancelButton
                        testId="cancelCalendarUpload"
                        type="button"
                        isDisabled={calendars.length === 0}
                        onClick={() => setClearModalOpen(true)}
                    >
                        {localize('calendarUpload.cancel')}
                    </CalendarUploadCancelButton>
                    <CalendarUploadSubmitButton
                        testId="submitCalendarUpload"
                        type="submit"
                        isDisabled={calendars.length === 0}
                        onClick={submitCalendars}
                    >
                        {localize('calendarUpload.submit')}
                    </CalendarUploadSubmitButton>
                </CalendarUploadButtonGrid>
            </CalendarUploadGrid>
            <StyledHeaderContent
                id="calendarUploadHeader"
                title={localize('calendarUpload.calendarListTitle')}
                extraStyle={{ paddingTop: 20 }}
            >
                <Grid item xs={12}>
                    <CalendarUploadTypography variant="h5">
                        {localize('calendarUpload.calendarListSubTitle')}
                    </CalendarUploadTypography>
                </Grid>
            </StyledHeaderContent>
            <CalendarUploadListContainerGrid container>
                <Grid item xs={12}>
                    <CalendarUploadedList
                        resultList={calendarUploadedResultList}
                    />
                </Grid>
                { showPagination &&
                    <Grid item xs={12} sx={{
                        display: 'flex',
                        justifyContent: 'center'}}>
                        <CalendarUploadLoadMoreButton
                            testId="loadMoreCalendarUploads"
                            isDisabled={disablePaging}
                            onClick={onPageChange}
                        >
                            {localize("calendarUpload.LoadMore")}
                        </CalendarUploadLoadMoreButton>
                    </Grid>
                }
            </CalendarUploadListContainerGrid>
            <ModalConfirmation
                isOpen={isClearOpen}
                contentMessage={localize('calendarUpload.cancelConfirmation')}
                confirmInputLabel={localize('calendarUpload.yes')}
                cancelInputLabel={localize('calendarUpload.no')}
                onConfirm={onClearCalendarUpload}
                onCancel={() => setClearModalOpen(false)}
            />
            <ModalConfirmation
                isOpen={isLeavingNoSavingOpen}
                contentMessage={localize('calendarUpload.sendFilesBeforeLeave')}
                confirmInputLabel={localize('calendarUpload.yes')}
                cancelInputLabel={localize('calendarUpload.no')}
                disableBackDrop
                disableEscKey
                onConfirm={() => onLeaveCalendarUpload(nextPath)}
                onCancel={() => setLeaveNoSavingModalOpen(false)}
            />
            <ModalConfirmation
                isOpen={isDeleteAttachmentOpen}
                contentMessage={setDeleteBodyMessage()}
                confirmInputLabel={localize('calendarUpload.yes')}
                cancelInputLabel={localize('calendarUpload.no')}
                onConfirm={onDeleteUploadedCalendar}
                onCancel={() => setDeleteAttachmentModalOpen(false)}
            />
            <ModalConfirmation
                isOpen={isNotesModalOpen}
                contentMessage={selectedNotes}
                confirmInputLabel={localize('calendarUpload.close')}
                hideCancelButton
                onConfirm={() => setNotesModalOpen(false)}
            />
        </DPApp>
    );
}

CalendarUpload.defaultProps = {
    calendars: [],
    calendarAttachments: [],
    calendarUploadedResultList: [],
    disablePaging: false,
    isClearOpen: false,
    isDeleteAttachmentOpen: false,
    isLeavingNoSavingOpen: false,
    isLoading: false,
    isNotesModalOpen: false,
    keyDropZone: '',
    nextPath: '',
    notes: '',
    pageNumber: 1,
    rejectedCalendars: [],
    selectedNotes: '',
    showPagination: false,
    onClearCalendarUpload: () => {},
    onDeleteUploadedCalendar: () => {},
    onGetUploadedCalendars: () => {},
    onLeaveCalendarUpload: () => {},
    onLoadForm: () => {},
    onSnackBarPropertyChange: () => {},
    onUploadCalendarFiles: () => {},
    setCalendarUploadNotes: () => {},
    setClearModalOpen: () => {},
    setDeleteAttachmentModalOpen: () => {},
    setKeyDropZone: () => {},
    setLeaveNoSavingModalOpen: () => {},
    setNextPath: () => {},
    setNotesModalOpen: () => {},
    setRejectedCalendarFiles: () => {},
    submitCalendars: () => {}
};

/* eslint-disable react/forbid-prop-types */
CalendarUpload.propTypes = {
    calendars: PropTypes.array,
    calendarAttachments: PropTypes.array,
    calendarUploadedResultList: PropTypes.array,
    disablePaging: PropTypes.bool,
    isClearOpen: PropTypes.bool,
    isDeleteAttachmentOpen: PropTypes.bool,
    isLeavingNoSavingOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    isNotesModalOpen: PropTypes.bool,
    keyDropZone: PropTypes.string,
    nextPath: PropTypes.string,
    notes: PropTypes.string,
    pageNumber: PropTypes.number,
    rejectedCalendars: PropTypes.array,
    selectedNotes: PropTypes.string,
    showPagination: PropTypes.bool,
    onClearCalendarUpload: PropTypes.func,
    onDeleteUploadedCalendar: PropTypes.func,
    onGetUploadedCalendars: PropTypes.func,
    onLeaveCalendarUpload: PropTypes.func,
    onLoadForm: PropTypes.func,
    onSnackBarPropertyChange: PropTypes.func,
    onUploadCalendarFiles: PropTypes.func,
    setCalendarUploadNotes: PropTypes.func,
    setClearModalOpen: PropTypes.func,
    setDeleteAttachmentModalOpen: PropTypes.func,
    setKeyDropZone: PropTypes.func,
    setLeaveNoSavingModalOpen: PropTypes.func,
    setNextPath: PropTypes.func,
    setNotesModalOpen: PropTypes.func,
    setRejectedCalendarFiles: PropTypes.func,
    submitCalendars: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default CalendarUpload;