import { connect } from 'react-redux';
import { onLoadForm, onLoadRequest } from 'redux/workers/transportationRequest/transportationRequestWorker';
import { onChangeTab } from 'redux/workers/transportationRequest/transportationRequestValidationWorker';

import TransportationRequest from 'screens/TransportationRequest/TransportationRequest';
import withRouter from 'util/customHooks/withRouter';

const mapStateToProps = state => {
    const { TransportationRequestReducers } = state;

    return {
        tabValue: TransportationRequestReducers.tabValue,
        title: TransportationRequestReducers.title
    };
};

const mapDispatchToProps = {
    onLoadForm,
    onLoadRequest,
    onChangeTab
};

const TransportationRequestContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TransportationRequest));

export default TransportationRequestContainer;
