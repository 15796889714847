import {
    DRAFTLIST_SEARCHTERM,
    DRAFTLIST_COLUMN_SORT,
    DRAFTLIST_COLUMNS,
    DRAFTLIST_RESET_COLUMNS,
    DRAFTLIST_DRAFTS,
    DRAFTLIST_PAGESIZE,
    DRAFTLIST_PAGE,
    DRAFTLIST_LOADING,
    DRAFTLIST_LIMITPAGESIZE,
    DRAFTLIST_LIMITPAGE,
    DRAFTLIST_SEARCHING,
    DRAFTLIST_SET_DELETE_DRAFT,
    DRAFTLIST_SET_OPEN_MODAL
} from "../actions/draftListActions";
import { initialDraftListColumnsSorted } from "../constants";

const initialState = {
    isLoading: false,
    isSearching: false,
    drafts: [],
    searchTerm: '',
    rowsShowed: 10,
    limitRows: 10,
    activePage: 0,
    limitPage: 1,
    columns: { ...initialDraftListColumnsSorted },
    sortColumn: initialDraftListColumnsSorted.dateDraftSaved.name,
    deleteDraftId: '',
    isModalOpen: false
};

/* eslint-disable-next-line default-param-last */
const DraftListReducers = (state = initialState, action) => {
    switch (action.type) {
    case DRAFTLIST_LOADING:
        return {
            ...state,
            isLoading: action.loading
        };
    case DRAFTLIST_SEARCHING:
        return {
            ...state,
            isSearching: action.isSearching
        };
    case DRAFTLIST_SEARCHTERM:
        return {
            ...state,
            searchTerm: action.search
        };
    case DRAFTLIST_COLUMNS:
        return {
            ...state,
            columns: {
                ...state.columns,
                [action.columnName]: {
                    ...state.columns[action.columnName],
                    sort: action.sort
                }
            }
        };
    case DRAFTLIST_COLUMN_SORT:
        return {
            ...state,
            sortColumn: action.sortColumn
        };
    case DRAFTLIST_RESET_COLUMNS:
        return {
            ...state,
            columns: { ...initialDraftListColumnsSorted },
            sortColumn: initialDraftListColumnsSorted.dateDraftSaved.name
        };
    case DRAFTLIST_DRAFTS:
        return {
            ...state,
            drafts: action.drafts
        };
    case DRAFTLIST_PAGESIZE:
        return {
            ...state,
            rowsShowed: action.rowsShowed
        };
    case DRAFTLIST_LIMITPAGESIZE:
        return {
            ...state,
            limitRows: action.limitRows
        };
    case DRAFTLIST_PAGE:
        return {
            ...state,
            activePage: action.activePage
        };
    case DRAFTLIST_LIMITPAGE:
        return {
            ...state,
            limitPage: action.limitPage
        };
    case DRAFTLIST_SET_DELETE_DRAFT:
        return {
            ...state,
            deleteDraftId: action.draftId
        };
    case DRAFTLIST_SET_OPEN_MODAL:
        return {
            ...state,
            isModalOpen: action.isOpen
        };
    default:
        return state;
    }
};

export default DraftListReducers;