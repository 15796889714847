import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import HowToGrantAppAccess from 'components/Resources/HowTos/HowToGrantAppAccess';
import withRouter from 'util/customHooks/withRouter';
import {
    updatePath
} from '../../workers/pathWorker';

const mapStateToProps = () => ({});

const HowToGrantAppAccessContainer = withRouter(
    connect(
        mapStateToProps, {
            updatePath
        }
    )(injectIntl(HowToGrantAppAccess))
);

export default HowToGrantAppAccessContainer;