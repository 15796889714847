import React from 'react';
import { FormControlLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import StyledFormControl from 'components/Common/StyledFormControl/StyledFormControl';
import PhoneNumber from '../Common/PhoneNumber';
import { localize } from '../../util/Localizer';
import commonUseStyles, { commonStyles } from '../../styles/CommonStyles';
import { realtionships } from '../../redux/constants';
import { validateEmail } from '../../util/textFormatters';
import StyledTextField from '../../components/Common/StyledTextField/StyledTextField';
import StyledCheckbox from '../../components/Common/StyledCheckbox/StyledCheckbox';
import Auth from '../../auth/Auth';

const FormLabel = styled('span')(({ theme }) => ({
    ...commonUseStyles.customFormLabel,
    "& .Mui-error": {
        color: theme.palette.everDrivenFuchsia
    },
    "& .MuiFormHelperText-root": {
        color: theme.palette.everDrivenFuchsia
    }
}));

const RequiredLabel = styled('span')(({ theme }) => ({
    color: theme.palette.everDrivenFuchsia
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    ...commonStyles(theme).selectItem
}));

const ErrorTextField = styled(StyledTextField)(({ theme }) => ({
    "& .Mui-error": {
        color: theme.palette.everDrivenFuchsia
    },
    "& .MuiFormHelperText-root": {
        color: theme.palette.everDrivenFuchsia
    },
    "& .Mui-error::after": {
        borderBottomColor: theme.palette.everDrivenFuchsia
    }
}));

function ContactForm({
    altPhoneValue,
    emailAddressValue,
    emailErrorValue,
    formName,
    inputRequired,
    nameValue,
    mobilePhoneValue,
    parentAppAccessValue,
    relationshipValue,
    onChangeValue,
    onChangePhoneValue,
    setEmailErrors
}) {
    const setEmail = (event) => {
        setEmailErrors({ [event.target.name]: false });
        onChangeValue(event);
    };

    const setEmailOnBlur = (evt) => {
        if (emailAddressValue.length > 0 && validateEmail(emailAddressValue)) {
            setEmailErrors({ [evt.target.name]: true });
            onChangeValue(evt);
        }
    };

    const renderRelationships = () => (
        realtionships.map(item => (
            <StyledMenuItem
                data-testid={localize(item.value)}
                key={item.index}
                value={item.key}>{localize(item.value)}
            </StyledMenuItem>
        ))
    );

    return (
        <>
            <div>
                <FormLabel>
                    {localize('tripRequestForm.name')}
                    {inputRequired ?
                        <RequiredLabel>
                            *
                        </RequiredLabel>
                        : null
                    }
                </FormLabel>
                <StyledTextField
                    name={`${formName}FirstName`}
                    autoComplete="off"
                    inputProps={{ className: 'qm_block', "data-testid": `${formName}FirstName` }}
                    isRequired={inputRequired}
                    onChange={onChangeValue}
                    value={nameValue ?? ""}
                />
            </div>
            <FormLabel>
                {localize('tripRequestForm.relationship')}
                {inputRequired ?
                    <RequiredLabel>
                        *
                    </RequiredLabel>
                    : null
                }
            </FormLabel>
            <StyledFormControl
                fullWidth
                size="small"
                variant="filled"
            >
                <Select
                    data-testid={`${formName}Relationship`}
                    name={`${formName}Relationship`}
                    onChange={onChangeValue}
                    value={relationshipValue ?? ""}
                    required={inputRequired}
                    MenuProps={{
                        disableScrollLock: true
                    }}
                >
                    <StyledMenuItem value="">
                        <em>{localize('tripRequestForm.relationshipNone')}</em>
                    </StyledMenuItem>
                    {renderRelationships()}
                </Select>
            </StyledFormControl>
            <div>
                <FormLabel>
                    {localize('tripRequestForm.mobilePhoneNumber')}
                    {inputRequired ?
                        <RequiredLabel>
                            *
                        </RequiredLabel>
                        : null
                    }
                </FormLabel>
                <PhoneNumber
                    idMobilePhone={`${formName}MobilePhone`}
                    modelInput={mobilePhoneValue ?? ""}
                    handleChangeModel={onChangePhoneValue}
                    inputProps={{ className: 'qm_block' }}
                    isRequired={inputRequired}
                    hideLabel
                />
                <FormLabel>
                    {localize('tripRequestForm.alternatePhoneNumber')}
                </FormLabel>
                <PhoneNumber
                    idMobilePhone={`${formName}AltPhone`}
                    modelInput={altPhoneValue ?? ""}
                    handleChangeModel={onChangePhoneValue}
                    inputProps={{ className: 'qm_block' }}
                    placeHolderName={localize('tripRequestForm.alternatePhoneNumber')}
                    hideLabel
                />
                <FormLabel>
                    {localize('tripRequestForm.emailAddress')}
                </FormLabel>
                <ErrorTextField
                    autoComplete="disabled"
                    
                    name={`${formName}EmailAddress`}
                    value={emailAddressValue ?? ""}
                    isError={emailErrorValue}
                    helperText={localize('userForm.InvalidFormatEmail')}
                    type="email"
                    onChange={setEmail}
                    onBlur={setEmailOnBlur}
                    inputProps={{ className: 'qm_block', "data-testid": `${formName}EmailAddress`, maxLength: 128 }}
                />
                {Auth.getConfig().parentAppEnabled &&
                    <FormControlLabel
                        size="medium"
                        data-testid={`${formName}AppAccess`}
                        control={
                            <StyledCheckbox
                                color="secondary"
                                isChecked={parentAppAccessValue}
                                isDisabled={validateEmail(emailAddressValue ?? "")}
                                name={`${formName}AppAccess`}
                                size="small"
                                onChange={onChangeValue}
                            />
                        }
                        label={localize('tripRequestForm.provideAccessToParentApp')}
                    />
                }
            </div>
        </>
    );
}

export default ContactForm;
