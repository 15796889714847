import { TransportationNeedOptions } from "data/models/descriptions/TransportationRequestModels";
import { Enum } from "util/EnumHelper";
import {
    setIsSameTripInfoCheck,
    setKeyDropZone,
    setPopulationTypes,
    setStudentAttachments,
    setTransportationNeeds,
    setTripEquipmentNeeds,
    setTripPopulationTypes,
    updateModel
} from "../actions/tripRequestFormActions";
// eslint-disable-next-line import/no-cycle
import { isNewCorrect } from "./tripRequestFormWorker";

export const formatTransporationNeeds = () => (dispatch, getState) => {
    const { transportationEquipmentNeeds } = getState().TripRequestFormReducers;

    const equipmentNeeds = [];

    if (transportationEquipmentNeeds.monitorNeeded) {
        equipmentNeeds.push(TransportationNeedOptions.monitorNeeded);
    }
    if (transportationEquipmentNeeds.mustRideAlone) {
        equipmentNeeds.push(TransportationNeedOptions.mustRideAlone);
    }
    if (transportationEquipmentNeeds.booster) {
        equipmentNeeds.push(TransportationNeedOptions.boosterSeat);
    }
    if (transportationEquipmentNeeds.safetyVest) {
        equipmentNeeds.push(TransportationNeedOptions.safetyVest);
    }
    if (transportationEquipmentNeeds.carSeat) {
        equipmentNeeds.push(TransportationNeedOptions.carSeat);
    }
    if (transportationEquipmentNeeds.buckleGuard) {
        equipmentNeeds.push(TransportationNeedOptions.buckleGuard);
    }
    if (transportationEquipmentNeeds.wheelchair) {
        equipmentNeeds.push(TransportationNeedOptions.wheelchair);
    }
    if (transportationEquipmentNeeds.notApplicable) {
        equipmentNeeds.push(TransportationNeedOptions.notApplicable);
    }

    dispatch(setTripEquipmentNeeds(equipmentNeeds));
};

export const formatPopulationTypeGeneralTripInfo = () => (dispatch, getState) => {
    const { populationTypes } = getState().TripRequestFormReducers;

    const populationTypeMap = {
        populationTypeMKV: Enum.populationTypes.MKV,
        populationTypeESE: Enum.populationTypes.ESE,
        populationTypeESY: Enum.populationTypes.ESY,
        populationTypeATP: Enum.populationTypes.ATP,
        populationTypeNCLB: Enum.populationTypes.NCLB,
        populationTypeRegularEd: Enum.populationTypes.RegularEd,
        populationTypeMedFragile: Enum.populationTypes.MedFragile,
        populationTypeMagnet: Enum.populationTypes.Magnet,
        populationTypeOutofDistrict: Enum.populationTypes.OutofDistrict,
        populationTypeSPED: Enum.populationTypes.SPED,
        populationTypeMultiDistrict: Enum.populationTypes.MultiDistrict,
        populationTypeDeafEd: Enum.populationTypes.DeafEd,
        populationTypeSpecialEvents: Enum.populationTypes.SpecialEvents,
        populationTypeSchoolofChoice: Enum.populationTypes.SchoolofChoice,
        populationTypeESSA: Enum.populationTypes.ESSA,
        populationTypePreK: Enum.populationTypes.PreK
    };

    const pTypes = Object.keys(populationTypeMap)
        .filter(key => populationTypes[key])
        .map(key => populationTypeMap[key]);

    dispatch(setTripPopulationTypes(pTypes));
};

export const onUpdateTransportationEquipmentNeed = (evt) => (dispatch) => {
    const { name, type } = evt.target;
    let { value } = evt.target;

    if (type === 'checkbox') {
        value = evt.target.checked;
    }

    if (name === 'notApplicable') {
        dispatch(setTransportationNeeds({
            monitorNeeded: false,
            mustRideAlone: false,
            booster: false,
            safetyVest: false,
            carSeat: false,
            buckleGuard: false,
            wheelchair: false,
            notApplicable: value
        }));
    } else {
        dispatch(setTransportationNeeds({
            notApplicable: false,
            [name]: value
        }));
    }

    dispatch(formatTransporationNeeds());
    dispatch(isNewCorrect());
};

export const onUpdatePopulationType = (evt) => (dispatch) => {
    const { name, type } = evt.target;
    let { value } = evt.target;

    if (type === 'checkbox') {
        value = evt.target.checked;
    }

    dispatch(setPopulationTypes({
        [name]: value
    }));

    dispatch(formatPopulationTypeGeneralTripInfo());
    dispatch(isNewCorrect());
};

export const setIsSameTripInfo = (checked) => (dispatch, getState) => {
    const { tripModels } = getState().TripRequestFormReducers;

    dispatch(setIsSameTripInfoCheck(checked));
    if (checked) {
        const studentOne = tripModels.find(x => x.student === 1);
        const { tripModel } = studentOne;

        dispatch(updateModel({
            accountId: tripModel.accountId ?? '',
            accountNumber: tripModel.accountNumber ?? '',
            accountName: tripModel.accountName ?? ''
        }));

        dispatch(setPopulationTypes(studentOne.populationTypes));
        dispatch(setTransportationNeeds(studentOne.transportationEquipmentNeeds));
        dispatch(setKeyDropZone(Math.random().toString()));
        dispatch(setStudentAttachments(studentOne.attachStudentDocuments));
        dispatch(formatTransporationNeeds());
        dispatch(isNewCorrect());
    }
};
