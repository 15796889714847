export const STUDENT_SELECTED = 'STUDENT_SELECTED';
export const studentSelected = student => ({
    type: STUDENT_SELECTED,
    student
});

export const STUDENT_SELECTED_CODE = 'STUDENT_SELECTED_CODE';
export const studentSelectedCode = studentCode => ({
    type: STUDENT_SELECTED_CODE,
    studentCode
});

export const STUDENT_SELECTED_ID = 'STUDENT_SELECTED_ID';
export const studentSelectedId = studentId => ({
    type: STUDENT_SELECTED_ID,
    studentId
});

export const STUDENT_IS_LOADING = 'STUDENT_IS_LOADING';
export const studentIsLoading = loading => ({
    type: STUDENT_IS_LOADING,
    loading
});

export const STUDENT_SELECTED_SCHOOL = 'STUDENT_SELECTED_SCHOOL';
export const studentSelectedSchool = schoolName => ({
    type: STUDENT_SELECTED_SCHOOL,
    schoolName
});
