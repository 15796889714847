export const basicInformation = {
    studentId: '',
    studentFirstName: '',
    studentLastName: '',
    studentDateOfBirth: null,
    studentGrade: '',
    studentAge: 0
};

export const contactInformation = {
    contact1Name: '',
    contact1Relationship: '',
    contact1PhoneNumber: '',
    contact1AltPhoneNumber: '',
    contact1EmailAddress: '',
    contact1AppAccess: true,
    contact2Name: '',
    contact2Relationship: '',
    contact2PhoneNumber: '',
    contact2AltPhoneNumber: '',
    contact2EmailAddress: '',
    contact2AppAccess: true,
    contact3Name: '',
    contact3Relationship: '',
    contact3PhoneNumber: '',
    contact3AltPhoneNumber: '',
    contact3EmailAddress: '',
    contact3AppAccess: true
};

export const additionalInformation = {
    selectedMonitorNeeded: '',
    selectedSafetyVest: '',
    selectedWheelchair: '',
    studentNotes: ''
};

export const populationType = {
    populationTypeMKV: false,
    populationTypeESE: false,
    populationTypeSPED: false,
    populationTypeESSA: false,
    populationTypeRegularEd: false,
    populationTypeDeafEd: false,
    populationTypeOutofDistrict: false,
    populationTypeSchoolofChoice: false,
    populationTypeMedFragile: false,
    populationTypeATP: false,
    populationTypeMultiDistrict: false,
    populationTypeESY: false,
    populationTypeNCLB: false,
    populationTypeSpecialEvents: false,
    populationTypeMagnet: false
};

export const transportationNeed = {
    handToHand: false,
    wheelchair: false,
    monitorNeeded: false,
    safetyVest: false,
    booster: false,
    buckleGuard: false,
    carSeat: false,
    mustRideAlone: false
};

export const trModel = {
    ...basicInformation,
    ...contactInformation,
    ...additionalInformation
};
