import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import AutoComplete from '@mui/material/Autocomplete';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { museoSansFont500 } from "util/EnumHelper";
import colorConstants from "styles/ColorConstants";
import { loadFacilities } from "redux/workers/districtTripTracker/facilityWorker";
import { localize } from "util/Localizer";
import { saveLocationPreferences, getUserFacilities } from 'util/preferenceUtil';
import withRouter from "util/customHooks/withRouter";

const StyledTextField = styled(TextField)(({theme}) => (
    {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '250px',
        '& ::placeholder': {
            color: theme.palette.everDrivenDarkCharcoal60,
            opacity: 1,
            fontSize: '14px',
            fontWeight: 500
        },
        '& ::before' : {
            borderBottom: `1px solid ${theme.palette.everDrivenDarkCharcoal60} !important`
        }
    }
));

const StyledTypography = styled(Typography)(() => (
    {
        display: 'flex',
        flexDirection: 'column'
    }
));

const StyledSubtitle = styled('span')(() => (
    {
        fontSize: '75%',
        fontWeight: 400,
        lineHeight: 1,
        color: colorConstants.EVERDRIVENGREY
    }
));

function FacilityAutoComplete({
    facilityList,
    loadFacilities: load,
    router,
    isCalendar

}) {
    const { navigate } = router;

    const onPreSelected = (item, value) => {
        if (value) {
            saveLocationPreferences(value);
            if (isCalendar) {
                navigate(`/Calendar/${value.id}`);
            } else {
                navigate(`/School/${value.id}/current`);
            }
        }
    };

    const isOptionEqual = (option, value) => option.id === value.id;

    const getList = () => {
        const activeFaclities = facilityList.filter(x => x.activeFlag);
        const prev = getUserFacilities(activeFaclities,
            'facility');
        // sort by recently used
        const sorted = prev.sort((obj, obj2) => obj.count - obj2.count);
        // remove doubles in the two lists
        const initList = Object.assign([],
            activeFaclities);
        const facList = initList.filter(f => !prev.includes(f));
        const list = [ ...sorted, ...facList ];

        return list;
    };

    useEffect(() => {
        if (facilityList.length === 0) {
            load();
        }
    }, []);

    return (
        <AutoComplete
            id="searchText"
            classes={{
                popper: {zIndex: 1}
            }}
            options={getList()}
            popupIcon={null}
            onChange={onPreSelected}
            isOptionEqualToValue={isOptionEqual}
            getOptionLabel={item => item.name}
            freeSolo
            renderOption={(properties, fac) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <div key={fac.id} {...properties}>
                    <StyledTypography variant="h4" >
                        <span>{fac.name}</span>
                        <StyledSubtitle>{fac.address}</StyledSubtitle>
                    </StyledTypography>
                </div>
            )}
            renderInput={params => (
                <StyledTextField
                    id={params.id}
                    InputLabelProps={params.InputLabelProps}
                    InputProps={{
                        ...params.InputProps,
                        sx: {fontFamily: museoSansFont500},
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{color: colorConstants.EVERDRIVENDARKCHARCOAL80}}/>
                            </InputAdornment>
                        )
                    }}
                    inputProps={{
                        maxLength: 64,
                        ...params.inputProps,
                        "data-testid": "schoolSearch",
                        onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                e.stopPropagation();
                            }
                        }
                    }}
                    placeholder={localize("schoolSearch.searchText")}
                    variant="standard"
                />
            )}
        />
    );
}

function mapStateToProps(state) {
    return {
        facilityList: state.FacilityReducers.facilities,
        term: state.FacilityReducers.term
    };
}

const ConnectedFacilityAutoComplete = withRouter(connect(mapStateToProps,
    { loadFacilities })(injectIntl(FacilityAutoComplete)));

export default ConnectedFacilityAutoComplete;
