/* eslint max-lines: 0 */
export const TRIPREQUESTFORM_RESPONSE = 'TRIPREQUESTFORM_RESPONSE';
export const tripRequestFormResponse = (response) => ({
    type: TRIPREQUESTFORM_RESPONSE,
    response
});

export const TRIPREQUESTFORM_REQUESTFILE = 'TRIPREQUESTFORM_REQUESTFILE';
export const tripRequestFormResponseFile = (responseFile) => ({
    type: TRIPREQUESTFORM_REQUESTFILE,
    responseFile
});

export const SET_TRIPREQUESTFORM_LOADING = 'SET_TRIPREQUESTFORM_LOADING';
export const setTripRequestFormLoading = payload => ({
    type: SET_TRIPREQUESTFORM_LOADING,
    isLoading: payload
});

export const SET_TRIPREQUESTFORM_SEARCH_TERM =
	'SET_TRIPREQUESTFORM_SEARCH_TERM';
export const setSearchTerm = payload => ({
    type: SET_TRIPREQUESTFORM_SEARCH_TERM,
    searchTerm: payload
});

export const SET_TRIPREQUESTFORM_DEFAULT_STUDENT_ID =
	'SET_TRIPREQUESTFORM_DEFAULT_STUDENT_ID';
export const setDefaultStudentId = payload => ({
    type: SET_TRIPREQUESTFORM_DEFAULT_STUDENT_ID,
    defaultStudentId: payload
});

export const UPDATE_TRIPREQUESTFORM_MODEL = 'UPDATE_TRIPREQUESTFORM_MODEL';
export const updateModel = payload => ({
    type: UPDATE_TRIPREQUESTFORM_MODEL,
    model: payload
});

export const SET_TRIPREQUESTFORM_ERROR_MESSAGE =
	'SET_TRIPREQUESTFORM_ERROR_MESSAGE';
export const setErrorMessage = payload => ({
    type: SET_TRIPREQUESTFORM_ERROR_MESSAGE,
    errorMessage: payload
});

export const SET_TRIPREQUESTFORM_ENABLED_SUBMIT =
	'SET_TRIPREQUESTFORM_ENABLED_SUBMIT';
export const setEnabledSubmit = payload => ({
    type: SET_TRIPREQUESTFORM_ENABLED_SUBMIT,
    isEnabled: payload
});

export const SET_TRIPREQUESTFORM_ENABLED_SCHOOL_INPUT =
	'SET_TRIPREQUESTFORM_ENABLED_SCHOOL_INPUT';
export const setEnabledSchoolInput = payload => ({
    type: SET_TRIPREQUESTFORM_ENABLED_SCHOOL_INPUT,
    enabledSchoolInputs: payload
});

export const SET_TRIPREQUESTFORM_ENABLED_STUDENT_DATE =
	'SET_TRIPREQUESTFORM_ENABLED_STUDENT_DATE';
export const setEnabledStudentDate = payload => ({
    type: SET_TRIPREQUESTFORM_ENABLED_STUDENT_DATE,
    isEnabledStudentDate: payload
});

export const SET_TRIPREQUESTFORM_SCHOOL_DAY = 'SET_TRIPREQUESTFORM_SCHOOL_DAY';
export const setSchoolDays = payload => ({
    type: SET_TRIPREQUESTFORM_SCHOOL_DAY,
    schoolDays: payload
});

export const SET_TRIPREQUESTFORM_POPULATION_TYPE =
	'SET_TRIPREQUESTFORM_POPULATION_TYPE';
export const setPopulationTypes = payload => ({
    type: SET_TRIPREQUESTFORM_POPULATION_TYPE,
    populationTypes: payload
});

export const SET_TRIPREQUESTFORM_TRANSPORTATION_NEED =
	'SET_TRIPREQUESTFORM_TRANSPORTATION_NEED';
export const setTransportationNeeds = payload => ({
    type: SET_TRIPREQUESTFORM_TRANSPORTATION_NEED,
    transportationNeeds: payload
});

export const SET_TRIPREQUESTFORM_SHOW_DIFFERENT_ADDRESS =
	'SET_TRIPREQUESTFORM_SHOW_DIFFERENT_ADDRESS';
export const setShowDifferentAddress = payload => ({
    type: SET_TRIPREQUESTFORM_SHOW_DIFFERENT_ADDRESS,
    showDifferentAddress: payload
});

export const SET_TRIPREQUESTFORM_ATTACHMENTS =
	'SET_TRIPREQUESTFORM_ATTACHMENTS';
export const setStudentAttachments = payload => ({
    type: SET_TRIPREQUESTFORM_ATTACHMENTS,
    studentAttachments: payload
});

export const SET_TRIPREQUESTFORM_EQUIPMENT_NEED =
	'SET_TRIPREQUESTFORM_EQUIPMENT_NEED';
export const setTripEquipmentNeeds = payload => ({
    type: SET_TRIPREQUESTFORM_EQUIPMENT_NEED,
    equipmentNeeds: payload
});

export const SET_TRIPREQUESTFORM_TRIP_POPULATION_TYPES =
	'SET_TRIPREQUESTFORM_TRIP_POPULATION_TYPES';
export const setTripPopulationTypes = payload => ({
    type: SET_TRIPREQUESTFORM_TRIP_POPULATION_TYPES,
    populationTypes: payload
});

export const SET_TRIPREQUESTFORM_REMOVE_SIBLING_OPEN = 'SET_TRIPREQUESTFORM_REMOVE_SIBLING_OPEN';
export const setRemoveSiblingModalStatus = payload => ({
    type: SET_TRIPREQUESTFORM_REMOVE_SIBLING_OPEN,
    status: payload
});

export const SET_TRIPREQUESTFORM_CONFIRM_SUBMIT_OPEN = 'SET_TRIPREQUESTFORM_CONFIRM_SUBMIT_OPEN';
export const setSubmitConfirmModalStatus = payload => ({
    type: SET_TRIPREQUESTFORM_CONFIRM_SUBMIT_OPEN,
    status: payload
});

export const SET_TRIPREQUESTFORM_REMOVE_TAB_MESSAGE = 'SET_TRIPREQUESTFORM_REMOVE_TAB_MESSAGE';
export const setRemoveTabMessage = payload => ({
    type: SET_TRIPREQUESTFORM_REMOVE_TAB_MESSAGE,
    message: payload
});

export const SET_TRIPREQUESTFORM_SUBMIT_FORM_MESSAGE = 'SET_TRIPREQUESTFORM_SUBMIT_FORM_MESSAGE';
export const setSubmitFormMessage = payload => ({
    type: SET_TRIPREQUESTFORM_SUBMIT_FORM_MESSAGE,
    message: payload
});

export const SET_TRIPREQUESTFORM_SIBLING_NUMBER = 'SET_TRIPREQUESTFORM_SIBLING_NUMBER';
export const setSiblingNumber = payload => ({
    type: SET_TRIPREQUESTFORM_SIBLING_NUMBER,
    siblingNumber: payload
});

export const SET_TRIPREQUESTFORM_CURRENT_SIBLING = 'SET_TRIPREQUESTFORM_CURRENT_SIBLING';
export const setCurrentSibling = payload => ({
    type: SET_TRIPREQUESTFORM_CURRENT_SIBLING,
    currentSibling: payload
});

export const SET_TRIPREQUESTFORM_LIMIT_REACHED = 'SET_TRIPREQUESTFORM_LIMIT_REACHED';
export const setLimitReached = payload => ({
    type: SET_TRIPREQUESTFORM_LIMIT_REACHED,
    limitReached: payload
});

export const SET_TRIPREQUESTFORM_SCHEDULE = 'SET_TRIPREQUESTFORM_SCHEDULE';
export const setSchedule = payload => ({
    type: SET_TRIPREQUESTFORM_SCHEDULE,
    schedule: payload
});

export const SET_TRIPREQUESTFORM_KEY_DROPZONE = 'SET_TRIPREQUESTFORM_KEY_DROPZONE';
export const setKeyDropZone = payload => ({
    type: SET_TRIPREQUESTFORM_KEY_DROPZONE,
    keyDropZone: payload
});

export const UPDATE_TRIPREQUESTFORM_ARRAY_FORM = 'UPDATE_TRIPREQUESTFORM_ARRAY_FORM';
export const updateArrayForm = payload => ({
    type: UPDATE_TRIPREQUESTFORM_ARRAY_FORM,
    form: payload
});

export const INSERT_TRIPREQUESTFORM_ARRAY_FORM = 'INSERT_TRIPREQUESTFORM_ARRAY_FORM';
export const insertArrayForm = payload => ({
    type: INSERT_TRIPREQUESTFORM_ARRAY_FORM,
    form: payload
});

export const REMOVE_TRIPREQUESTFORM_ARRAY_FORM = 'REMOVE_TRIPREQUESTFORM_ARRAY_FORM';
export const removeArrayForm = payload => ({
    type: REMOVE_TRIPREQUESTFORM_ARRAY_FORM,
    formNumber: payload
});

export const SET_TRIPREQUESTFORM_TAB_DELETE = 'SET_TRIPREQUESTFORM_TAB_DELETE';
export const setTabForDelete = payload => ({
    type: SET_TRIPREQUESTFORM_TAB_DELETE,
    tabNumber: payload
});

export const SET_TRIPREQUESTFORM_SCHEDULE_VALID = 'SET_TRIPREQUESTFORM_SCHEDULE_VALID';
export const setScheduleValid = payload => ({
    type: SET_TRIPREQUESTFORM_SCHEDULE_VALID,
    scheduleValid: payload
});

export const SET_TRIPREQUESTFORM_IS_SAME_ADDRESS = 'SET_TRIPREQUESTFORM_IS_SAME_ADDRESS';
export const setIsSameAddressCheck = payload => ({
    type: SET_TRIPREQUESTFORM_IS_SAME_ADDRESS,
    isSameAddress: payload
});

export const SET_TRIPREQUESTFORM_IS_SAME_TRIP_INFO = 'SET_TRIPREQUESTFORM_IS_SAME_TRIP_INFO';
export const setIsSameTripInfoCheck = payload => ({
    type: SET_TRIPREQUESTFORM_IS_SAME_TRIP_INFO,
    isSameTripInformation: payload
});

export const SET_TRIPREQUESTFORM_IS_SAME_CONTACT = 'SET_TRIPREQUESTFORM_IS_SAME_CONTACT';
export const setIsSameContactCheck = payload => ({
    type: SET_TRIPREQUESTFORM_IS_SAME_CONTACT,
    isSameContacts: payload
});

export const SET_TRIPREQUESTFORM_IS_SAME_SCHOOL = 'SET_TRIPREQUESTFORM_IS_SAME_SCHOOL';
export const setIsSameSchoolCheck = payload => ({
    type: SET_TRIPREQUESTFORM_IS_SAME_SCHOOL,
    isSameSchool: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_HOME_ADDRESS = 'SET_TRIPREQUESTFORM_SELECTED_HOME_ADDRESS';
export const setSelectedHomeAddress = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_HOME_ADDRESS,
    selectedHomeAddress: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_HOME_ADDRESS2 = 'SET_TRIPREQUESTFORM_SELECTED_HOME_ADDRESS2';
export const setSelectedHomeAddress2 = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_HOME_ADDRESS2,
    selectedHomeAddress2: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_PICKUP_ADDRESS = 'SET_TRIPREQUESTFORM_SELECTED_PICKUP_ADDRESS';
export const setSelectedPickUpAddress = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_PICKUP_ADDRESS,
    selectedPickUpAddress: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_PICKUP_ADDRESS2 = 'SET_TRIPREQUESTFORM_SELECTED_PICKUP_ADDRESS2';
export const setSelectedPickUpAddress2 = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_PICKUP_ADDRESS2,
    selectedPickUpAddress2: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_DROPOFF_ADDRESS = 'SET_TRIPREQUESTFORM_SELECTED_DROPOFF_ADDRESS';
export const setSelectedDropOffAddress = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_DROPOFF_ADDRESS,
    selectedDropOffAddress: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_DROPOFF_ADDRESS2 = 'SET_TRIPREQUESTFORM_SELECTED_DROPOFF_ADDRESS2';
export const setSelectedDropOffAddress2 = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_DROPOFF_ADDRESS2,
    selectedDropOffAddress2: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_SCHOOL = 'SET_TRIPREQUESTFORM_SELECTED_SCHOOL';
export const setSelectedSchool = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_SCHOOL,
    selectedSchool: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_SCHOOL_ADDRESS = 'SET_TRIPREQUESTFORM_SELECTED_SCHOOL_ADDRESS';
export const setSelectedSchoolAddress = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_SCHOOL_ADDRESS,
    selectedSchoolAddress: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_SCHOOL_ADDRESS2 = 'SET_TRIPREQUESTFORM_SELECTED_SCHOOL_ADDRESS2';
export const setSelectedSchoolAddress2 = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_SCHOOL_ADDRESS2,
    selectedSchoolAddress2: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_STUDENT = 'SET_TRIPREQUESTFORM_SELECTED_STUDENT';
export const setSelectedStudent = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_STUDENT,
    selectedStudent: payload
});

export const SET_TRIPREQUESTFORM_CLEAN = 'SET_TRIPREQUESTFORM_CLEAN';
export const cleanForm = () => ({
    type: SET_TRIPREQUESTFORM_CLEAN
});

export const SET_TRIPREQUESTFORM_EMAIL_ERROR = 'SET_TRIPREQUESTFORM_EMAIL_ERROR';
export const setEmailErrors = payload => ({
    type: SET_TRIPREQUESTFORM_EMAIL_ERROR,
    emailErrors: payload
});

export const SET_TRIPREQUESTFORM_CLEAR_STUDENT_OPEN = 'SET_TRIPREQUESTFORM_CLEAR_STUDENT_OPEN';
export const setClearStudentStatusModal = payload => ({
    type: SET_TRIPREQUESTFORM_CLEAR_STUDENT_OPEN,
    status: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_ACCOUNT_HAS_NO_EMAIL = 'SET_TRIPREQUESTFORM_SELECTED_ACCOUNT_HAS_NO_EMAIL';
export const setSelectedAccountHasNoEmail = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_ACCOUNT_HAS_NO_EMAIL,
    selectedAccountHasNoEmail: payload
});

export const SET_TRIPREQUESTFORM_STUDENT_BIRTHDATE_ERRORMESSAGE = 'SET_TRIPREQUESTFORM_STUDENT_BIRTHDATE_ERRORMESSAGE';
export const setStudentBirthDateErrorMessage = payload => ({
    type: SET_TRIPREQUESTFORM_STUDENT_BIRTHDATE_ERRORMESSAGE,
    errorMessage: payload
});

export const SET_TRIPREQUESTFORM_STUDENT_STARTDATE_ERRORMESSAGE = 'SET_TRIPREQUESTFORM_STUDENT_STARTDATE_ERRORMESSAGE';
export const setStudentStartDateErrorMessage = payload => ({
    type: SET_TRIPREQUESTFORM_STUDENT_STARTDATE_ERRORMESSAGE,
    errorMessage: payload
});

export const SET_TRIPREQUESTFORM_STUDENT_ENDDATE_ERRORMESSAGE = 'SET_TRIPREQUESTFORM_STUDENT_ENDDATE_ERRORMESSAGE';
export const setStudentEndDateErrorMessage = payload => ({
    type: SET_TRIPREQUESTFORM_STUDENT_ENDDATE_ERRORMESSAGE,
    errorMessage: payload
});

export const SET_TRIPREQUESTFORM_ENABLED_DRAFT = 'SET_TRIPREQUESTFORM_ENABLED_DRAFT';
export const setEnabledDraft = payload => ({
    type: SET_TRIPREQUESTFORM_ENABLED_DRAFT,
    isEnabledDraft: payload
});

export const SET_TRIPREQUESTFORM_DRAFT_FOR_SAVE = 'SET_TRIPREQUESTFORM_DRAFT_FOR_SAVE';
export const setIsDraftForSave = payload => ({
    type: SET_TRIPREQUESTFORM_DRAFT_FOR_SAVE,
    isDraftForSave: payload
});

export const SET_TRIPREQUESTFORM_SAVE_DRAFT_BEFORE_LEAVE = 'SET_TRIPREQUESTFORM_SAVE_DRAFT_BEFORE_LEAVE';
export const setSaveBeforeLeaveModalStatus = payload => ({
    type: SET_TRIPREQUESTFORM_SAVE_DRAFT_BEFORE_LEAVE,
    status: payload
});

export const SET_TRIPREQUESTFORM_NEXT_PATH = 'SET_TRIPREQUESTFORM_NEXT_PATH';
export const setNextPath = payload => ({
    type: SET_TRIPREQUESTFORM_NEXT_PATH,
    nextPath: payload
});

export const SET_TRIPREQUESTFORM_DRAFT_INDICATOR = 'SET_TRIPREQUESTFORM_DRAFT_INDICATOR';
export const clearDraftIndicator = () => ({
    type: SET_TRIPREQUESTFORM_DRAFT_INDICATOR
});

export const SET_TRIPREQUESTFORM_UPLOADED_DRAFT_ATTACHMENTS =
	'SET_TRIPREQUESTFORM_UPLOADED_DRAFT_ATTACHMENTS';
export const setUploadedDraftAttachments = payload => ({
    type: SET_TRIPREQUESTFORM_UPLOADED_DRAFT_ATTACHMENTS,
    uploadedDraftAttachments: payload
});

export const SET_TRIPREQUESTFORM_OLD_DRAFT_MODEL = 'SET_TRIPREQUESTFORM_OLD_DRAFT_MODEL';
export const updateOldDraftModel = payload => ({
    type: SET_TRIPREQUESTFORM_OLD_DRAFT_MODEL,
    model: payload
});

export const SET_TRIPREQUESTFORM_OLD_DRAFT_ATTACHMENTS = 'SET_TRIPREQUESTFORM_OLD_DRAFT_ATTACHMENTS';
export const setOldDraftStudentAttachments = payload => ({
    type: SET_TRIPREQUESTFORM_OLD_DRAFT_ATTACHMENTS,
    studentAttachments: payload
});

export const SET_TRIPREQUESTFORM_OLD_DRAFT_POPULATION_TYPE =
	'SET_TRIPREQUESTFORM_OLD_DRAFT_POPULATION_TYPE';
export const setOldDraftPopulationTypes = payload => ({
    type: SET_TRIPREQUESTFORM_OLD_DRAFT_POPULATION_TYPE,
    populationTypes: payload
});

export const SET_TRIPREQUESTFORM_OLD_DRAFT_TRANSPORTATION_NEED =
    'SET_TRIPREQUESTFORM_OLD_DRAFT_TRANSPORTATION_NEED';
export const setOldDraftTransportationNeeds = payload => ({
    type: SET_TRIPREQUESTFORM_OLD_DRAFT_TRANSPORTATION_NEED,
    transportationNeeds: payload
});

export const SET_TRIPREQUESTFORM_DELETE_DRAFT =
	'SET_TRIPREQUESTFORM_DELETE_DRAFT';
export const setDeleteDraftModalStatus = payload => ({
    type: SET_TRIPREQUESTFORM_DELETE_DRAFT,
    status: payload
});

export const SET_TRIPREQUESTFORM_DRAFT_SELECTED =
	'SET_TRIPREQUESTFORM_DRAFT_SELECTED';
export const setIsDraftSelected = payload => ({
    type: SET_TRIPREQUESTFORM_DRAFT_SELECTED,
    isDraftSelected: payload
});

export const SET_TRIPREQUESTFORM_NON_SCHOOL_ADDRESS_LIST =
    'SET_TRIPREQUESTFORM_NON_SCHOOL_ADDRESS_LIST';
export const setNonSchoolAddressList = payload => ({
    type: SET_TRIPREQUESTFORM_NON_SCHOOL_ADDRESS_LIST,
    addressList: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_HOME_ADDRESS =
    'SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_HOME_ADDRESS';
export const setSelectedPreviousHomeAddress = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_HOME_ADDRESS,
    selectedAddress: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_PICKUP_ADDRESS =
    'SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_PICKUP_ADDRESS';
export const setSelectedPreviousPickUpAddress = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_PICKUP_ADDRESS,
    selectedAddress: payload
});

export const SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_DROPOFF_ADDRESS =
    'SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_DROPOFF_ADDRESS';
export const setSelectedPreviousDropOffAddress = payload => ({
    type: SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_DROPOFF_ADDRESS,
    selectedAddress: payload
});

export const SET_TRIPREQUESTFORM_CALLED_GETCORRELATIONID = 'SET_TRIPREQUESTFORM_CALLED_GETCORRELATIONID';
export const setCalledGetCorrelationId = payload => ({
    type: SET_TRIPREQUESTFORM_CALLED_GETCORRELATIONID,
    calledGetCorrelationId: payload
});