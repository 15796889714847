import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import CalendarUpload from 'screens/CalendarUpload/CalendarUpload';
import {
    onClearCalendarUpload,
    onDeleteUploadedCalendar,
    onGetUploadedCalendars,
    onLeaveCalendarUpload,
    onLoadForm,
    onUploadCalendarFiles,
    submitCalendars
} from 'redux/workers/calendarUploadWorker';
import {
    setClearModalOpen,
    setCalendars,
    setCalendarAttachments,
    setCalendarUploadNotes,
    setDeleteAttachmentModalOpen,
    setKeyDropZone,
    setLeaveNoSavingModalOpen,
    setNextPath,
    setNotesModalOpen,
    setRejectedCalendarFiles
} from 'redux/actions/calendarUploadActions';
import {
    updatePath
} from 'redux/workers/pathWorker';
import {
    onSnackBarPropertyChange
} from 'redux/workers/styledSnackbarWrapperWorker';
import withRouter from 'util/customHooks/withRouter';

const mapStateToProps = state => {
    const { CalendarUploadReducers } = state;

    if (CalendarUploadReducers) {
        const { results, totalPages, pageNumber } = CalendarUploadReducers.calendarUploadedList;
        let showPagination = false;

        if (totalPages > 1) {
            showPagination = true;
        }

        return {
            calendars: CalendarUploadReducers.calendars,
            calendarAttachments: CalendarUploadReducers.calendarAttachments,
            calendarUploadedResultList: results,
            showPagination,
            pageNumber,
            disablePaging: pageNumber === totalPages,
            isClearOpen: CalendarUploadReducers.isClearOpen,
            keyDropZone: CalendarUploadReducers.keyDropZone,
            nextPath: CalendarUploadReducers.nextPath,
            notes: CalendarUploadReducers.notes,
            isLeavingNoSavingOpen: CalendarUploadReducers.isLeavingNoSavingOpen,
            isLoading: CalendarUploadReducers.isLoading,
            isDeleteAttachmentOpen: CalendarUploadReducers.isDeleteAttachmentOpen,
            selectedNotes: CalendarUploadReducers.selectedNotes,
            isNotesModalOpen: CalendarUploadReducers.isNotesModalOpen,
            rejectedCalendars: CalendarUploadReducers.rejectedCalendars
        };
    }

    return {};
};

const CalendarUploadContainer = withRouter(
    connect(
        mapStateToProps, {
            onClearCalendarUpload,
            onDeleteUploadedCalendar,
            onGetUploadedCalendars,
            onLeaveCalendarUpload,
            onLoadForm,
            onSnackBarPropertyChange,
            onUploadCalendarFiles,
            setCalendars,
            setCalendarAttachments,
            setCalendarUploadNotes,
            setClearModalOpen,
            setDeleteAttachmentModalOpen,
            setKeyDropZone,
            setLeaveNoSavingModalOpen,
            setNextPath,
            setNotesModalOpen,
            setRejectedCalendarFiles,
            submitCalendars,
            updatePath
        }
    )(injectIntl(CalendarUpload))
);

export default CalendarUploadContainer;