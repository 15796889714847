import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import SegmentNotesContainer from 'redux/containers/transportationRequest/tripBuilder/SegmentNotesContainer';
import SegmentTimesContainer from 'redux/containers/transportationRequest/tripBuilder/SegmentTimesContainer';
import SegmentInfoContainer from 'redux/containers/transportationRequest/tripBuilder/SegmentInfoContainer';
import StyledTypography from 'components/Common/StyledTypography/StyledTypography';
import { museoSansFont } from 'util/EnumHelper';
import { TransportationRequestTripTypesOptions } from 'data/models/transportationRequest/TripBuilderModel';
import colorConstants from 'styles/ColorConstants';

function Segment({
    segmentType,
    currentTrip,
    title,
    show,
    tripType
}) {
    const renderTitle = () => (
        title &&
            <Grid sx={{ display: 'flex' }}>
                <StyledTypography
                    extraStyle={{
                        color: colorConstants.EVERDRIVENDARKCHARCOAL,
                        fontFamily: museoSansFont,
                        lineHeight: 1.5,
                        height: 48,
                        margin: 0,
                        fontSize: 18,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    variant="h5"
                >
                    {title}
                </StyledTypography>
            </Grid>
    );

    return (
        show &&
            <Grid container sx={
                {
                    marginTop: tripType === TransportationRequestTripTypesOptions.oneWay ? '29px' : '20px',
                    display: 'flex'
                }}
            >
                {renderTitle()}
                <SegmentInfoContainer currentTrip={currentTrip} segment={segmentType}/>
                <SegmentTimesContainer currentTrip={currentTrip} segment={segmentType}/>
                <SegmentNotesContainer currentTrip={currentTrip} segment={segmentType}/>
            </Grid>
    );
}

Segment.defaultProps = {
    tripType: '',
    segmentType: '',
    currentTrip: 0,
    title: '',
    show: false
};

/* eslint-disable react/forbid-prop-types */
Segment.propTypes = {
    tripType: PropTypes.string,
    currentTrip: PropTypes.number,
    segmentType: PropTypes.string,
    title: PropTypes.string,
    show: PropTypes.bool

};
/* eslint-enable react/forbid-prop-types */

export default Segment;