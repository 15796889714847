import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import AutoComplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { searchStudent } from "redux/workers/districtTripTracker/dttStudentWorker";

import StyledTextField from "../../components/Common/StyledTextField/StyledTextField";
import StyledTypography from "../../components/Common/StyledTypography/StyledTypography";
import { localize } from "../../util/Localizer";
import { objectEmptyValidator } from '../../util/validationUtil';
import { studentDto } from "../../data/models/tripRequestForm/TripRequestFormModel";
import { commonStyles } from "../../styles/CommonStyles";

const StudentMemberCode = styled('span')(({ theme }) => ({
    ...commonStyles(theme).customSubtitle

}));

const AutoCompleteContainer = styled(AutoComplete)(() => ({
    marginLeft: 15,
    marginRight: 15
}));

const InputTextField = styled(StyledTextField)(({ theme }) => ({
    ...commonStyles(theme).inputTextField
}));

const StudentInfo = styled(StyledTypography)(() => ({
    display: 'flex',
    flexDirection: 'column'
}));

const useStyles = makeStyles(() => ({
    popperUp: {
        zIndex: 1
    }
}));

function TripRequestFormAutoComplete({
    defaultStudent,
    defaultStudentId,
    isLoading,
    searchTerm,
    selectedStudent,
    students,
    search: onSearch,
    onSelectStudent,
    onChangeInput
}) {
    let selectedValue = studentDto;

    const refInputStudentSearch = useRef();
    const refAutocomplete = useRef();
    const classes = useStyles();

    const performSearch = (value) => {
        if (value.length >= 1) {
            onChangeInput(value);
            onSearch(value, true);
        }
    };

    const onPreSelected = (item, value) => {
        selectedValue = value;
        if (!value) {
            selectedValue = {
                id: '',
                clientMemberCode: '',
                firstName: '',
                lastName: '',
                dateOfBirth: null,
                fullName: ''
            };
        } else {
            onChangeInput(selectedValue.fullName);
        }
        onSelectStudent(selectedValue);
        refInputStudentSearch.current.focus();
    };

    useEffect(() => {
        performSearch(searchTerm);
    }, []);

    useEffect(() => {
        if (students && students.length > 0) {
            if (defaultStudentId && objectEmptyValidator(selectedStudent)) {
                const student = students.find(x => x.id === defaultStudentId);

                if (student) {
                    onPreSelected(null, student);
                }
            }
        }
    }, [students]);

    useEffect(() => {
        if (defaultStudentId) {
            const student = students.find(x => x.id === defaultStudentId);

            if (student) {
                onPreSelected(null, student);
            } else {
                performSearch(searchTerm);
            }
        }
    }, [defaultStudentId]);

    const onLoadableTextField = (params) => (
        <InputTextField
            InputLabelProps={params.InputLabelProps}
            inputProps={{
                ...params.inputProps,
                "data-testid": "studentTextFilterAutocomplete",
                onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                        e.stopPropagation();
                    }
                }
            }}
            InputProps={{
                ...params.InputProps,
                className: "qm_block",
                endAdornment: (
                    <>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </>
                )
            }}
            inputRef={refInputStudentSearch}
            label={localize("tripRequestForm.find")}
            name="studentTextFilterAutocomplete"
            value={searchTerm}
            variant="standard"
            onChange={(e) => performSearch(e.target.value)}
        />
    );

    const onRenderOptionResult = (properties, student) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li key={student.id} {...properties}>
            <StudentInfo variant="h4">
                <span className="qm_block">{student.firstName} {student.lastName}</span>
                <StudentMemberCode>
                    {student.isDraft ? `${student.clientMemberCode} - Draft` : student.clientMemberCode}
                </StudentMemberCode>
            </StudentInfo>
        </li>
    );

    return (
        <AutoCompleteContainer
            classes={{
                popper: classes.popperUp,
                input: 'qm_block',
                option: 'qm_block'
            }}
            filterOptions={(x) => x}
            filterSelectedOptions
            includeInputInList
            freeSolo
            inputValue={searchTerm}
            loading={isLoading}
            loadingText={localize('search.searchingForStudents')}
            noOptionsText=""
            options={students}
            popupIcon={null}
            ref={refAutocomplete}
            onChange={onPreSelected}
            value={defaultStudent}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={item => !objectEmptyValidator(item) && item.fullName ? item.fullName : ''}
            renderInput={params => onLoadableTextField(params)}
            renderOption={(
                properties,
                option
            ) => onRenderOptionResult(
                properties, option
            )}
        />
    );
}

function mapStateToProps(state) {
    let studentsList = state.DttStudentReducers.dttStudents || [];

    studentsList = studentsList.map((u) => (
        Object.assign(u,
            {fullName: u.isDraft
                ? `${u.clientMemberCode} - ${u.firstName} ${u.lastName} - Draft`
                : `${u.clientMemberCode} - ${u.firstName} ${u.lastName}`
            })
    ));

    return {
        students: studentsList,
        isLoading: state.DttStudentReducers.isLoading,
        selectedStudent: state.TripRequestFormReducers.selectedStudent
    };
}

const ConnectedTripRequestFormAutoComplete =
    connect(
        mapStateToProps,
        {
            search: searchStudent
        })(injectIntl(TripRequestFormAutoComplete)
    );

export default ConnectedTripRequestFormAutoComplete;
