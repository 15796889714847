import React from 'react';
import { Grid, Select, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import StyledTextField from 'components/Common/StyledTextField/StyledTextField';
import { localize } from 'util/Localizer';
import { dateIsValid, minimumDateAllowed, yesterday } from 'util/dateUtils';
import StyledFormLabel from 'components/Common/StyledFormLabel/StyledFormLabel';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import { grades } from 'redux/constants';
import StyledFormControl from 'components/Common/StyledFormControl/StyledFormControl';
import CommonStyles from 'styles/CommonStyles';
import { museoSansFont } from 'util/EnumHelper';
import StyledDatePicker from 'components/Common/StyledDatePicker/StyledDatePicker';
import { stringValidate } from 'util/textFormatters';

function BasicInformation({
    hasGrade,
    tripModel,
    onUpdateModel,
    onUpdateStudentBirthDate,
    setHasGrade,
    errors
}) {
    const handleChangeBasicInformation = (evt) => {
        if (evt === null) {
            return false;
        }

        onUpdateModel(evt);

        return true;
    };

    const renderGrades = () => grades.map(item => (
        <MenuItem key={item.index} value={item.key} sx={{ fontFamily: museoSansFont }}>{item.value}</MenuItem>
    ));

    return (
        <Grid container sx={{ paddingBottom: '40px' }}>
            <Grid item xs={6} sx={{ paddingRight: '20px' }}>
                <StyledFormLabel label={localize('transportationRequest.firstName')} isRequired />
                <StyledTextField
                    autoComplete="disabled"
                    inputProps={{ className: 'qm_block', "data-testid": "studentFirstName", maxLength: 50 }}
                    isNewStyle
                    isRequired
                    name="studentFirstName"
                    value={tripModel.studentFirstName ?? ""}
                    onChange={handleChangeBasicInformation}
                    isError={errors.studentFirstName?.isError && stringValidate(tripModel.studentFirstName)}
                />
            </Grid>
            <Grid item xs={6} sx={{ paddingLeft: '20px' }}>
                <StyledFormLabel label={localize('transportationRequest.lastName')} isRequired />
                <StyledTextField
                    autoComplete="disabled"
                    data-testid="studentLastName"
                    inputProps={{ className: 'qm_block', "data-testid": "studentLastName", maxLength: 50 }}
                    isNewStyle
                    isRequired
                    name="studentLastName"
                    value={tripModel.studentLastName ?? ""}
                    onChange={handleChangeBasicInformation}
                    isError={errors.studentLastName?.isError && stringValidate(tripModel.studentLastName)}
                />
            </Grid>
            <Grid item xs={6} sx={{ paddingRight: '20px', paddingTop: '20px' }}>
                <StyledFormLabel label={localize('transportationRequest.studentId')} isRequired />
                <StyledTextField
                    autoComplete="disabled"
                    isNewStyle
                    isRequired
                    name="studentId"
                    value={tripModel.studentId ?? ""}
                    onChange={handleChangeBasicInformation}
                    isError={errors.studentId?.isError && stringValidate(tripModel.studentId)}
                    inputProps={{ "data-testid": "studentId", maxLength: 50 }}
                />
            </Grid>
            <Grid item xs={6} sx={{ paddingLeft: '20px', paddingTop: '20px' }}>
                <StyledFormLabel label={localize('transportationRequest.dateofBirth')} isRequired />
                <StyledDatePicker
                    id="studentDateOfBirth"
                    value={tripModel.studentDateOfBirth ?? null}
                    minDate={minimumDateAllowed()}
                    maxDate={yesterday()}
                    onChange={onUpdateStudentBirthDate}
                    error={errors.studentDateOfBirth?.isError
                        && (!dateIsValid(tripModel.studentDateOfBirth)
                        || tripModel.studentDateOfBirth < minimumDateAllowed()
                        || tripModel.studentDateOfBirth > yesterday())}
                />
            </Grid>
            <Grid item xs={6} sx={{ paddingRight: '20px', paddingTop: '20px' }}>
                {!hasGrade && <StyledButton
                    color="inherit"
                    style={CommonStyles.linkButton}
                    type="button"
                    startIcon={<AddIcon style={{ fontSize: 19 }} />}
                    variant="text"
                    testId="addStudentGrade"
                    onClick={() => setHasGrade(true)}
                >
                    {localize('transportationRequest.addGrade')}
                </StyledButton>
                }
                { hasGrade && <>
                    <StyledFormLabel label={localize('transportationRequest.grade')} />
                    <StyledFormControl
                        fullWidth
                        isNewStyle
                        size="small"
                        testId="studentGrade"
                        variant="filled"
                    >
                        <Select
                            displayEmpty
                            labelId="studentGrade"
                            MenuProps={{
                                disableScrollLock: true
                            }}
                            name="studentGrade"
                            value={tripModel.studentGrade ?? ""}
                            onChange={handleChangeBasicInformation}
                        >
                            {renderGrades()}
                        </Select>
                    </StyledFormControl>
                </>
                }
            </Grid>
        </Grid>
    );
}

BasicInformation.defaultProps = {
    hasGrade: false,
    onUpdateModel: null,
    onUpdateStudentBirthDate: null,
    setHasGrade: null,
    errors: {}
};

/* eslint-disable react/forbid-prop-types */
BasicInformation.propTypes = {
    hasGrade: PropTypes.bool,
    tripModel: PropTypes.object.isRequired,
    onUpdateModel: PropTypes.func,
    onUpdateStudentBirthDate: PropTypes.func,
    setHasGrade: PropTypes.func,
    errors: PropTypes.object
};
/* eslint-enable react/forbid-prop-types */

export default BasicInformation;