import React from 'react';
import { Table, TableContainer, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { bool, node, string } from 'prop-types';
import commonUseStyles from '../../../styles/CommonStyles';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    backgroundColor: theme.palette.offWhite
}));

const MobileRow = styled(Paper)(() => ({
    ...commonUseStyles.gridContent,
    padding: '8px 16px 8px 16px !important'
}));

const MobileTable = styled(Table)(() => ({
    width: '100%',
    '& > tbody > tr > td': {
        fontSize: 10,
        padding: 0,
        border: 0
    }
}));

function StyledTable ({ extraClassCss, isMobileView, children, testId }) {
    const renderNormalView = () => (
        <StyledTableContainer component={Paper} className={`${extraClassCss}` } padding="none" data-testid={testId}>
            <Table>
                {children}
            </Table>
        </StyledTableContainer>
    );

    const renderMobileView = () => (
        <MobileRow className={`${extraClassCss}`}>
            <TableContainer>
                <MobileTable>
                    {children}
                </MobileTable>
            </TableContainer>
        </MobileRow>
    );

    return (
        <>
            { !isMobileView && renderNormalView() }
            { isMobileView && renderMobileView() }
        </>
    );
}

StyledTable.defaultProps = {
    isMobileView: false,
    children: null,
    extraClassCss: null,
    testId: "styledTable"
};

StyledTable.propTypes = {
    isMobileView: bool,
    children: node,
    extraClassCss: string,
    testId: string
};

export default StyledTable;