import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { localize } from 'util/Localizer';
import StyledFormLabel from 'components/Common/StyledFormLabel/StyledFormLabel';
import StyledTypography from 'components/Common/StyledTypography/StyledTypography';
import { monthDayYearFormat } from 'util/dateUtils';
import {
    convertTo12HourFormat,
    getAccountName,
    groupDaysByTime
} from 'util/data/TripBuilderDecorator';
import CommonStyles from 'styles/CommonStyles';
import StyledSectionItem from 'components/Common/StyledSectionItem/StyledSectionItem';

function TripBuilderReview({
    trip,
    tripTypesOptions,
    recurringTripOptions,
    tripNumber
}) {
    const getRecurring = () => {
        if(trip.recurring === recurringTripOptions.recurring) {
            return localize('transportationRequest.reviewTripTypeRecurring');
        }

        return localize('transportationRequest.reviewTripTypeOneTime');
    };

    const getTripTypes = (title) => {
        if(trip.tripTypes.includes(tripTypesOptions.oneWay)) {
            return `${title}, ${localize('transportationRequest.oneWay')}`;
        }
        if(trip.tripTypes.includes(tripTypesOptions.roundTrip)) {
            return `${title}, ${localize('transportationRequest.roundTrip')}`;
        }

        return`${title}, ${localize('transportationRequest.multiStop')}`;
    };

    const setTripTypeTitle = () => {
        let title = '';

        title = getRecurring();
        title = getTripTypes(title);

        return title;
    };

    const renderValue = (value) => (value === '' ? '-' : value);

    const renderEndDays = (length, index) => (length !== index+1 ? ', ' : '');

    const renderDay = (day, length, index) => (
        localize(`transportationRequest.${day}`) + renderEndDays(length, index)
    );

    const renderTimeDays = (days, segment) => (
        days.map((group) => (
            <Grid container key={group.time}>
                <Grid item xs={6}>
                    <StyledTypography
                        variant="h5"
                        extraStyle={{
                            ...CommonStyles.bodyText,
                            fontSize: '16px',
                            paddingBottom: 0,
                            margin: 0,
                            lineHeight: 1.5
                        }}
                        testid={`Trip${tripNumber+1}${segment}-days-${group.time}`}
                    >
                        {
                            group.days.map((day, index) => (
                                renderDay(day, group.days.length, index)
                            ))
                        }
                        
                    </StyledTypography>
                </Grid>
                <Grid item xs={6}>
                    <StyledTypography
                        variant="h5"
                        extraStyle={{
                            ...CommonStyles.bodyText,
                            fontSize: '16px',
                            paddingBottom: 0,
                            margin: 0,
                            lineHeight: 1.5
                        }}
                        testid={`Trip${tripNumber+1}${segment}-time-${group.time}`}
                    >
                        {convertTo12HourFormat(group.time)}
                    </StyledTypography>
                </Grid>
            </Grid>
        ))
    );
    
    const renderDays = (singleTime, daysTime, days, isSingleTime, segment) => {
        if(isSingleTime && singleTime && days) {
            const daysFormated = [{
                time: singleTime,
                days
            }];

            return (
                renderTimeDays(daysFormated, segment)
            );
        }

        const daysFormated = groupDaysByTime(daysTime);

        if(daysFormated?.length > 0) {
            return (
                renderTimeDays(daysFormated, segment)
            );
        }

        return (
            <Grid container>
                <Grid item xs={6}>
                    <StyledTypography
                        variant="h5"
                        testid={`Trip${tripNumber+1}${segment}-singleTime`}
                        extraStyle={{
                            ...CommonStyles.bodyText,
                            fontSize: '16px',
                            paddingBottom: 0,
                            margin: 0,
                            lineHeight: 1.5
                        }}
                    >
                        {singleTime === ''
                            ? '-'
                            : renderValue(
                                convertTo12HourFormat(singleTime)
                            )}
                    </StyledTypography>
                </Grid>
            </Grid>
        );
    };

    const renderTitlesSegment = (labelOne, labelTwo) => (
        <Grid container>
            <Grid item xs={8}>
                <StyledFormLabel
                    label={labelOne}
                />
            </Grid>
            <Grid item xs={4} >
                <StyledFormLabel
                    label={labelTwo}
                />
            </Grid>
        </Grid>
    );

    const renderAddress = (address) => (
        <>
            {address.placeName &&
                <span data-testid="placeName">{address.placeName}<br/></span>
            }
            <span data-testid="addressLine1">{address.addressLine1Short || address.addressLine1 }<br/></span>
            {address.addressLine2 &&
                <span data-testid="addressLine2">{address.addressLine2}<br/></span>
            }
            <span data-testid="cityStateZip">{address.city},
                {" "}{address.stateShort || address.state} {address.postal}<br/></span>
            {address.phone &&
                <span data-testid="phone">{address.phone}</span>
            }
        </>
    );

    const renderSegment = (
        segment,
        labelSegment,
        leavingFrom,
        goingTo,
        labelTime,
        daysTime
    ) => (
        <Grid container sx={{ marginTop: '20px' }}>
            {renderTitlesSegment(labelSegment, labelTime)}
            <Grid container>
                <Grid item xs={8}>
                    <Grid container>
                        <Grid item xs={6}>
                            <StyledTypography
                                variant="h5"
                                extraStyle={{
                                    ...CommonStyles.bodyText,
                                    fontSize: '16px',
                                    paddingRight: '10px',
                                    lineHeight: 1.5,
                                    marginTop: 0,
                                    marginBottom: 0
                                }}
                                testid={`Trip${tripNumber+1}${segment}LeavingFrom`}
                            >
                                {leavingFrom.selectedAddress ? renderAddress(leavingFrom) : "-" }
                            </StyledTypography>
                        </Grid>
                        <Grid item xs={6} >
                            <StyledTypography
                                variant="h5"
                                extraStyle={{
                                    ...CommonStyles.bodyText,
                                    fontSize: '16px',
                                    paddingRight: '10px',
                                    lineHeight: 1.5,
                                    marginTop: 0,
                                    marginBottom: 0
                                }}
                                testid={`Trip${tripNumber+1}${segment}GoingTo`}
                            >
                                {goingTo.selectedAddress ? renderAddress(goingTo) : "-" }
                            </StyledTypography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} data-testid={`${segment}Schedule`}>
                    {daysTime}
                </Grid>
            </Grid>
        </Grid>
    );
    
    const renderDates = () => (
        `${monthDayYearFormat(trip.startDate)}${trip.endDate ? ' - ' : ''} ${monthDayYearFormat(trip.endDate)}`
    );

    return (
        trip &&
            <Grid container>
                <StyledSectionItem
                    size={8}
                    label={setTripTypeTitle()}
                    labelTestid={`Trip${tripNumber+1}Type`}
                    value={renderDates()}
                    testid={`Trip${tripNumber+1}Dates`}
                />
                <StyledSectionItem
                    label={localize('transportationRequest.accountNumberTitle')}
                    value={getAccountName(trip.account)}
                    testid={`Trip${tripNumber+1}Account`}
                />
                {renderSegment(
                    "Segment1",
                    !trip.tripTypes.includes(tripTypesOptions.oneWay)
                    && localize('transportationRequest.arrivalTitle'),
                    trip.arrival.tripLeavingFrom,
                    trip.arrival.tripGoingTo,
                    localize('transportationRequest.arrivalTimeTitle'),
                    renderDays(
                        trip.arrivalSingleTime,
                        trip.arrivalTime,
                        trip.days,
                        !trip.arrivalHasTimeMultiples,
                        "Segment1"
                    )
                )}
                {trip.arrivalNotes !== '' &&
                    <StyledSectionItem
                        contentStyle={{ marginTop: '20px' }}
                        size={12}
                        label={localize('transportationRequest.arrivalNotes')}
                        value={trip.arrivalNotes}
                        testid={`Trip${tripNumber+1}ArrivalNotes`}
                    />
                }
                {!trip.tripTypes.includes(tripTypesOptions.oneWay) &&
                    renderSegment(
                        "Segment2",
                        localize('transportationRequest.departureTitle'),
                        trip.departure.tripLeavingFrom,
                        trip.departure.tripGoingTo,
                        localize('transportationRequest.departureTimeTitle'),
                        renderDays(
                            trip.departureSingleTime,
                            trip.departureTime,
                            trip.days,
                            !trip.departureHasTimeMultiples,
                            "Segment2"
                        )
                    )
                }
                {!trip.tripTypes.includes(tripTypesOptions.oneWay) &&
                    trip.departureNotes !== '' &&
                        <StyledSectionItem
                            contentStyle={{ marginTop: '20px' }}
                            size={12}
                            label={localize('transportationRequest.departureNotes')}
                            value={trip.departureNotes}
                            testid={`Trip${tripNumber+1}DepartureNotes`}
                        />
                }
            </Grid>
    );
}

TripBuilderReview.defaultProps = {
    trip: null,
    tripTypesOptions: null,
    recurringTripOptions: null,
    tripNumber: 0
};

/* eslint-disable react/forbid-prop-types */
TripBuilderReview.propTypes = {
    trip: PropTypes.object,
    tripTypesOptions: PropTypes.object,
    recurringTripOptions: PropTypes.object,
    tripNumber: PropTypes.number
};

export default TripBuilderReview;