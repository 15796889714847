import React from 'react';
import {
    IconButton,
    DialogContent,
    DialogActions,
    Grid
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import colorConstants from 'styles/ColorConstants';
import { Enum, museoFont700, museoSansFont500 } from 'util/EnumHelper';
import CommonStyles, { commonStyles } from 'styles/CommonStyles';
import StyledDialogModal from '../StyledDialogModal/StyledDialogModal';
import StyledButton from '../StyledButton/StyledButton';

const CancelButton = styled(
    StyledButton,
    { shouldForwardProp: (prop) => prop !== 'isSuccess' }
)(({ theme, isSuccess }) => {
    let styles = {
        ...commonStyles(theme).cancelButton,
        width: 200,
        margin: '0 30px 0 50px'
    };

    if(isSuccess) {
        styles = {
            ...styles,
            border: `1px solid ${theme.palette.everDrivenDarkBlue}`,
            color: theme.palette.everDrivenDarkBlue
        };
    }

    return styles;
});

const ConfirmButton = styled(StyledButton)(({ theme }) => ({
    ...commonStyles(theme).confirmButton,
    width: 200,
    margin: '0 50px 0 30px'
}));

const ButtonContainer = styled(Grid)(() => ({
    display: 'flex',
    justifyContent: 'center'
}));

const ContentModal = styled(DialogContent)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    marginTop: 20,
    width: '100%'
}));

const styles = {
    confirmTitle: {
        textAlign: 'center',
        fontSize: 24,
        fontFamily: museoFont700,
        margin: 0,
        color: colorConstants.EVERDRIVENFUCHSIA,
        wordBreak: 'break-word',
        display: 'flex',
        flexDirection: 'column'
    },
    confirmContent: {
        textAlign: 'center',
        fontSize: 18,
        fontFamily: museoSansFont500,
        lineHeight: 1.5,
        margin: 0,
        padding: 20,
        color: colorConstants.EVERDRIVENDARKCHARCOAL,
        wordBreak: 'break-word',
        display: 'flex',
        flexDirection: 'column'
    }
};

function StyledConfirmModal({
    children,
    isSuccess,
    cancelInputLabel,
    confirmInputLabel,
    contentMessage,
    contentMessage2,
    contentTitle,
    dataId,
    onClose,
    disableBackDrop,
    disableEscKey,
    hideCancelButton,
    isOpen,
    onCancel,
    onConfirm
}) {
    const renderContentMessage = () => {
        if(contentMessage2) {
            return (
                <>
                    <p style={{ ...styles.confirmContent, marginTop: 20}}>
                        {contentMessage}
                    </p>
                    <p style={styles.confirmContent}>
                        {contentMessage2}
                    </p>
                </>
            );
        }

        return (
            <p style={{ ...styles.confirmContent, marginTop: 20}}>
                {contentMessage}
            </p>
        );
    };

    const renderContent = () => {
        if(contentTitle) {
            return (
                <>
                    <IconButton
                        aria-label="close"
                        data-testid={dataId}
                        onClick={() => onClose()}
                        sx={{ position: 'absolute', left: 40, top: 40, padding: 0 }}
                    >
                        <CloseIcon sx={{ fontSize: 26 }} />
                    </IconButton>
                    <p style={styles.confirmTitle}>
                        {contentTitle}
                    </p>
                    {renderContentMessage()}
                    {children}
                </>
            );
        }

        return (
            <>
                <p style={CommonStyles.contentConfirmModal}>
                    {contentMessage}
                </p>
                {children}
            </>
        );
    };

    const handleOnCloseModal = (event, reason) => {
        if (reason === Enum.modalCloseReason.backdropClick && disableBackDrop) {
            return null;
        }

        if (reason === Enum.modalCloseReason.escapeKeyDown && disableEscKey) {
            return null;
        }

        if (hideCancelButton) {
            onConfirm();
        } else {
            onCancel();
        }

        return true;
    };

    return (
        <StyledDialogModal
            open={isOpen}
            maxWidth="md"
            aria-labelledby="confirm-trf-form"
            onClose={(e, r) => handleOnCloseModal(e, r)}
        >
            <ContentModal data-testid="dialogContent" sx={{ padding: '40px 40px 0 40px', margin: 0}}>
                {renderContent()}
            </ContentModal>
            <DialogActions sx={{ padding: 0, margin: '20px 40px 40px' }}>
                <Grid container>
                    <ButtonContainer item xs={6}>
                        {!hideCancelButton &&
                            <CancelButton
                                isSuccess={isSuccess}
                                testId="modalConfirmCancelButton"
                                type="button"
                                onClick={onCancel !== null
                                    ? () => onCancel()
                                    : () => onClose()
                                }
                            >
                                {cancelInputLabel}
                            </CancelButton>
                        }
                    </ButtonContainer>
                    <ButtonContainer item xs={6}>
                        <ConfirmButton
                            testId="modalConfirmAcceptButton"
                            type="button"
                            onClick={() => onConfirm()}
                        >
                            {confirmInputLabel}
                        </ConfirmButton>
                    </ButtonContainer>
                </Grid>
            </DialogActions>
        </StyledDialogModal>
    );
}

StyledConfirmModal.defaultProps = {
    children: null,
    cancelInputLabel: '',
    confirmInputLabel: '',
    contentMessage: '',
    contentMessage2: [],
    contentTitle: '',
    dataId: '',
    disableBackDrop: false,
    disableEscKey: false,
    hideCancelButton: false,
    isOpen: false,
    isSuccess: false,
    onCancel: null,
    onClose: null,
    onConfirm: null
};

/* eslint-disable react/forbid-prop-types */
StyledConfirmModal.propTypes = {
    children: PropTypes.node,
    cancelInputLabel: PropTypes.string,
    confirmInputLabel: PropTypes.string,
    contentMessage: PropTypes.string,
    contentMessage2: PropTypes.array,
    contentTitle: PropTypes.string,
    dataId: PropTypes.string,
    disableBackDrop: PropTypes.bool,
    disableEscKey: PropTypes.bool,
    hideCancelButton: PropTypes.bool,
    isOpen: PropTypes.bool,
    isSuccess: PropTypes.bool,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default StyledConfirmModal;
