import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import StyledTextField from '../../components/Common/StyledTextField/StyledTextField';
import PhoneNumber from '../Common/PhoneNumber';
import { localize } from '../../util/Localizer';
import breakpointsNames from '../../styles/ResponsiveConstants';
import commonUseStyles from '../../styles/CommonStyles';
import { getUserFacilities } from '../../util/preferenceUtil';
import { requestTypeDto, schoolDto } from '../../data/models/tripRequestForm/TripRequestFormModel';

import SchoolAutoComplete from './SchoolAutoComplete';
import AddressAutoComplete from './AddressAutoComplete';
import { objectEmptyValidator } from '../../util/validationUtil';
import Auth from '../../auth/Auth';

const GrouLeftGridItem = styled(Grid)(({ theme }) => ({
    ...commonUseStyles.defaultGroupLeft,
    [theme.breakpoints.down(breakpointsNames.md)]: {
        paddingRight: 0
    }
}));

const GroupCenterGridItem = styled(Grid)(({ theme }) => ({
    ...commonUseStyles.defaultGroupCenter,
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        paddingRight: 0
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        paddingRight: 0,
        paddingLeft: 0
    }
}));

const GroupInsideLeftGridItem = styled(Grid)(() => ({
    ...commonUseStyles.defaultGroupInsideLeft
}));

const GroupInsideRightGridItem = styled(Grid)(() => ({
    ...commonUseStyles.defaultGroupInsideRight
}));

const FormLabel = styled('span')(() => ({
    ...commonUseStyles.customFormLabel
}));

const RequiredLabel = styled('span')(({ theme }) => ({
    color: theme.palette.everDrivenFuchsia
}));

function SchoolDetail({
    refSchool,
    refAddress,
    refAddressLine2,
    schoolInformation,
    requestType,
    classesInputEnabled,
    enabledSchoolInputs,
    selectedSchool,
    selectedSchoolAddress,
    selectedSchoolAddress2,
    handleChangePhoneSchoolInformation,
    handleChangeSchoolInformation,
    onSelectSchoolAddressInfo,
    onSelectSchoolAddressLine2Info,
    onUpdateSchoolAddressLine1,
    onUpdateSchoolAddressLine2,
    onSelectSchool,
    setIsSameSchool
}) {
    const onSelectAddressInfo = (item) => {
        onSelectSchoolAddressInfo(item);
    };

    const onSelectAddressLine2Info = (item) => {
        onSelectSchoolAddressLine2Info(item);
    };

    const onChangeSchoolAddressLine1 = (fieldValue) => {
        setIsSameSchool(false);
        onUpdateSchoolAddressLine1(fieldValue);
    };

    const onChangeSchoolAddressLine2 = (fieldValue) => {
        setIsSameSchool(false);
        onUpdateSchoolAddressLine2(fieldValue);
    };

    const onSelectSchoolInfo = (item) => {
        onSelectSchool(item);
    };

    const listSchools = () => {
        const Facilities = Auth.getConfig().facilities;
        const prev = getUserFacilities(Facilities,
            'facility');
        // sort by recently used
        const sorted = prev.sort((obj, obj2) => obj.count - obj2.count);
        // remove doubles in the two lists
        const initList = Object.assign([],
            Facilities);
        const facList = initList.filter(f => !prev.includes(f));

        return [ ...sorted, ...facList ];
    };

    return (
        <Grid item container xs={12} lg={8}>
            <GrouLeftGridItem item xs={12} md={6}>
                <FormLabel>
                    {localize('tripRequestForm.schoolSearch')}
                    {requestTypeDto.new === schoolInformation.requestType ?
                        <RequiredLabel>
                                *
                        </RequiredLabel>
                        : null
                    }
                </FormLabel>
                <SchoolAutoComplete
                    ref={refSchool}
                    idName="schoolName"
                    onSelectSchool={onSelectSchoolInfo}
                    value={schoolInformation.schoolName ?? ""}
                    defaultSchool={selectedSchool ?? schoolDto}
                    onChangeInput={handleChangeSchoolInformation}
                    isRequired={requestTypeDto.new === requestType}
                    optionList={listSchools()}
                />
            </GrouLeftGridItem>
            <GroupCenterGridItem item xs={12} md={6}>
                <FormLabel>
                    {localize('tripRequestForm.schoolName')}
                    {requestTypeDto.new === schoolInformation.requestType ?
                        <RequiredLabel>
                                *
                        </RequiredLabel>
                        : null
                    }
                </FormLabel>
                <StyledTextField
                    className={classesInputEnabled}
                    inputProps={{ "data-testid": "schoolName" }}
                    isDisabled={enabledSchoolInputs.schoolName}
                    isRequired={requestTypeDto.new === requestType}
                    autoComplete="disabled"
                    name="schoolName"
                    value={schoolInformation.schoolName ?? ""}
                    onChange={handleChangeSchoolInformation}
                />
            </GroupCenterGridItem>
            <GrouLeftGridItem item xs={12} md={6}>
                <FormLabel>
                    {localize('tripRequestForm.schoolAddress')}
                    {requestTypeDto.new === schoolInformation.requestType ?
                        <RequiredLabel>
                                *
                        </RequiredLabel>
                        : null
                    }
                </FormLabel>
                <AddressAutoComplete
                    ref={refAddress}
                    idName="schoolAddressLine1"
                    name="schoolAddressLine1"
                    value={schoolInformation.schoolAddressLine1 ?? ""}
                    defaultAddress={selectedSchoolAddress}
                    onSelectAddressInfo={onSelectAddressInfo}
                    onChangeInput={onChangeSchoolAddressLine1}
                    isRequired={requestTypeDto.new === requestType}
                    disabled={!!selectedSchool && !objectEmptyValidator(selectedSchool)}
                />
            </GrouLeftGridItem>
            <GroupCenterGridItem item xs={12} md={6}>
                <FormLabel>
                    {localize('tripRequestForm.addressLine2')}
                </FormLabel>
                <AddressAutoComplete
                    ref={refAddressLine2}
                    idName="schoolAddressLine2"
                    name="schoolAddressLine2"
                    value={schoolInformation.schoolAddressLine2 ?? ""}
                    defaultAddress={selectedSchoolAddress2}
                    onSelectAddressInfo={onSelectAddressLine2Info}
                    onChangeInput={onChangeSchoolAddressLine2}
                    disabled={!!selectedSchool && !objectEmptyValidator(selectedSchool)}
                />
            </GroupCenterGridItem>
            <GrouLeftGridItem item xs={12} md={6}>
                <FormLabel>
                    {localize('tripRequestForm.city')}
                    {requestTypeDto.new === schoolInformation.requestType ?
                        <RequiredLabel>
                                *
                        </RequiredLabel>
                        : null
                    }
                </FormLabel>
                <StyledTextField
                    className={classesInputEnabled}
                    inputProps={{ "data-testid": "schoolAddressCity" }}
                    isDisabled={enabledSchoolInputs.addressCity}
                    isRequired={requestTypeDto.new === requestType}
                    name="schoolAddressCity"
                    value={schoolInformation.schoolAddressCity ?? ""}
                    onChange={handleChangeSchoolInformation}
                />
            </GrouLeftGridItem>
            <GroupCenterGridItem item xs={12} md={6}>
                <Grid container>
                    <GroupInsideLeftGridItem item xs={6}>
                        <FormLabel>
                            {localize('tripRequestForm.zipCode')}
                            {requestTypeDto.new === schoolInformation.requestType ?
                                <RequiredLabel>
                                        *
                                </RequiredLabel>
                                : null
                            }
                        </FormLabel>
                        <StyledTextField
                            inputProps={{ "data-testid": "schoolAddressZipCode" }}
                            name="schoolAddressZipCode"
                            onChange={handleChangeSchoolInformation}
                            value={schoolInformation.schoolAddressZipCode ?? ""}
                            isDisabled={enabledSchoolInputs.addressZipCode}
                            className={classesInputEnabled}
                            required={requestTypeDto.new === requestType}
                        />
                    </GroupInsideLeftGridItem>
                    <GroupInsideRightGridItem item xs={6}>
                        <FormLabel>
                            {localize('tripRequestForm.state')}
                            {requestTypeDto.new === schoolInformation.requestType ?
                                <RequiredLabel>
                                        *
                                </RequiredLabel>
                                : null
                            }
                        </FormLabel>
                        <StyledTextField
                            inputProps={{ "data-testid": "schoolAddressState" }}
                            name="schoolAddressState"
                            onChange={handleChangeSchoolInformation}
                            value={schoolInformation.schoolAddressState ?? ""}
                            isDisabled={enabledSchoolInputs.addressState}
                            className={classesInputEnabled}
                            isRequired={requestTypeDto.new === requestType}
                        />
                    </GroupInsideRightGridItem>
                </Grid>
            </GroupCenterGridItem>
            <GrouLeftGridItem item xs={12} md={6}>
                <FormLabel>
                    {localize('tripRequestForm.schoolPhone')}
                    {requestTypeDto.new === schoolInformation.requestType ?
                        <RequiredLabel>
                                *
                        </RequiredLabel>
                        : null
                    }
                </FormLabel>
                <PhoneNumber
                    disabled={enabledSchoolInputs.schoolPhone}
                    idMobilePhone="schoolPhone"
                    isRequired={requestTypeDto.new === requestType}
                    hideLabel
                    modelInput={schoolInformation.schoolPhone ?? ""}
                    handleChangeModel={handleChangePhoneSchoolInformation}
                />
            </GrouLeftGridItem>
            <GroupCenterGridItem item xs={12} md={6}>
                <FormLabel>
                    {localize('tripRequestForm.arrivalInstructions')}
                </FormLabel>
                <StyledTextField
                    name="schoolArrivalInstructions"
                    isMultiline
                    rows="6"
                    onChange={handleChangeSchoolInformation}
                    value={schoolInformation.schoolArrivalInstructions ?? ""}
                    inputProps= {{ "data-testid": "schoolArrivalInstructions", maxLength: "200" }}
                />
            </GroupCenterGridItem>
        </Grid>
    );
}

export default SchoolDetail;
