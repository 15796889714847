import React, { useEffect } from 'react';
import { DialogTitle } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { localize } from '../../util/Localizer';
import StyledDialogModal from '../Common/StyledDialogModal/StyledDialogModal';
import StyledButton from '../Common/StyledButton/StyledButton';
import UserForm from './UserForm/UserForm';

const UserModalDialogTitle = styled(DialogTitle)(({ theme }) => ({
    fontSize: '1.3em',
    fontWeight: 'bold',
    color: theme.palette.white,
    background: theme.palette.everDrivenDarkBlue
}));

const CloseButton = styled(StyledButton)(({ theme }) => ({
    background: theme.palette.transparent,
    height: 40,
    minWidth: 30,
    padding: 0,
    margin: theme.spacing(1)
}));

const AddRemoveIconButton = styled(ArrowBackIos)(({ theme }) => ({
    color: theme.palette.everDrivenGrey,
    paddingLeft: 10,
    fontSize: 20
}));

function UserModal({
    isUserPopUpOpen: modalStatus,
    handleClosePopUp: handleClose,
    onSubmit,
    errorMessage,
    facilityList,
    user,
    setUserObject: setUser,
    setUserResponse: setErrorMessage,
    isLoadingUserUpdate: isSaving,
    saveButtonDisabled,
    setSaveButtonDisabled
}) {
    const onSubmitForm = (e) => {
        e.preventDefault();
        onSubmit();
    };

    useEffect(() =>{
        setErrorMessage('');
    }, [modalStatus]);

    return (
        <StyledDialogModal
            open={modalStatus}
            onClose={handleClose}
            maxWidth="lg"
            aria-labelledby="add-user-title"
        >
            <UserModalDialogTitle id="add-user-title">
                <CloseButton
                    disableElevation
                    testId="closeAddNewUserModalButton"
                    onClick={handleClose}
                >
                    <AddRemoveIconButton />
                </CloseButton>
                {localize('addUser.title')}
            </UserModalDialogTitle>
            <UserForm
                facilityList={facilityList}
                user={user}
                setUser={setUser}
                setSaveButtonDisabled={setSaveButtonDisabled}
                setErrorAlert={setErrorMessage}
                onSubmitForm={onSubmitForm}
                nameForm="userForm"
                errorMessage={errorMessage}
                saveButtonDisabled={saveButtonDisabled}
                isSaving={isSaving}
                buttonLabel={localize('addUser.createButton')}
            />

        </StyledDialogModal>
    );
}

export default UserModal;
