import {
    tripGroupResetFilters,
    tripGroupSetShowLoadMore,
    tripGroupSetToken,
    tripGroupSetTrips
} from "redux/actions/tripGroupActions";
import { facilityTrips } from "services/facilityService";
import { studentTrips } from "services/studentService";
import { Enum } from "util/EnumHelper";
import { setListTrips } from "util/data/TripFormat";
import { fixedEncodeURIComponent } from "util/textFormatters";
import {
    sortActive,
    sortActiveStudents,
    sortCompleted,
    sortCompletedStudents,
    sortFuture,
    sortFutureStudents,
    sortNoShowStudents
} from "util/tripSorter";

const sortTripsFacility = (filterTo, trips) => {
    switch(Enum.TripSection[filterTo]) {
    case Enum.TripSection.Today:
        return trips.sort(sortActive);
    case Enum.TripSection.Future:
        return trips.sort(sortFuture);
    case Enum.TripSection.NoShow:
        return trips.sort(sortNoShowStudents);
    case Enum.TripSection.Finished:
        return trips.sort(sortCompleted);
    default:
        return trips;
    }
};

const sortTripsStudent = (filterTo, trips) => {
    switch(Enum.TripSection[filterTo]) {
    case Enum.TripSection.Today:
        return trips.sort(sortActiveStudents);
    case Enum.TripSection.Future:
        return trips.sort(sortFutureStudents);
    case Enum.TripSection.NoShow:
        return trips.sort(sortCompletedStudents);
    case Enum.TripSection.Finished:
        return trips.sort(sortCompletedStudents);
    default:
        return trips;
    }
};

export const sortTrips = (tripType, trips, isMember) => (dispatch) => {
    let newTripState = [];

    if(!isMember) {
        newTripState = sortTripsFacility(tripType, trips);
    } else {
        newTripState = sortTripsStudent(tripType, trips);
    }

    dispatch(tripGroupSetTrips(tripType, newTripState));
};

export const defaultSetup = (tripType) => (dispatch) => {
    dispatch(tripGroupResetFilters());
    dispatch(tripGroupSetTrips(tripType, []));
    dispatch(tripGroupSetToken(tripType, null));
    dispatch(tripGroupSetShowLoadMore(tripType, 0));
};

export const loadTrips = (tripType, facilityId, isMember) => async (dispatch, getState) => {
    const {
        guid,
        tokens,
        trips,
        showLoadMore
    } = getState().TripGroupReducers;

    const continuationToken = tokens[tripType]
        ? fixedEncodeURIComponent(tokens[tripType])
        : null;

    let callFunction = null;

    if(!isMember) {
        callFunction = facilityTrips;
    } else {
        callFunction = studentTrips;
    }

    const response = await callFunction(
        facilityId ?? guid,
        Enum.TripSection[tripType],
        continuationToken
    );

    const newTripsResponse = setListTrips(response.trips, facilityId);
    const newTrips = [ ...trips[tripType], ...newTripsResponse ];
    
    dispatch(sortTrips(tripType, newTrips, isMember));

    dispatch(tripGroupSetToken(tripType,
        response.continuationToken
            ? JSON.stringify(response.continuationToken)
            : null
    ));

    if(showLoadMore[tripType] === 0 && response.continuationToken === null) {
        dispatch(tripGroupSetShowLoadMore(tripType, 1));
    }
};