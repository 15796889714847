import { connect } from 'react-redux';
import withRouter from 'util/customHooks/withRouter';
import StudentHeader from 'components/Student/StudentHeader';
import Auth from 'auth/Auth';

const mapStateToProps = (state, ownProps) => {
    const { StudentReducers } = state;

    return {
        hasManagementAccess: Auth.hasProgramManagementAccess(),
        navigate: ownProps.router.navigate,
        schoolName:  StudentReducers.schoolName,
        studentCode: StudentReducers.studentCode,
        studentId: ownProps.studentId,
        studentName: StudentReducers.student
    };
};

const StudentHeaderContainer = withRouter(
    connect(
        mapStateToProps,
        null
    )(StudentHeader)
);

export default StudentHeaderContainer;
