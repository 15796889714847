import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import DistrictTripTrackerTable from 'components/districtTripTracker/DistrictTripTrackerTable/DistrictTripTrackerTable';
import { facilitySearchColumns } from 'util/EnumHelper';
import { localize } from 'util/Localizer';
import {
    sortFacilitiesByColumn,
    facilitySetSearch,
    setPage
} from 'redux/workers/districtTripTracker/dttFacilityWorker';

const mapStateToProps = state => {
    const { DttFacilityReducers } = state;

    return {
        resultList: DttFacilityReducers.dttFacilities,
        headerCells: facilitySearchColumns,
        columns: DttFacilityReducers.dttFacilityColumns,
        emptyText: localize('search.noSchoolFound'),
        tableTitle: localize('dttFacility.tableTitle'),
        showPagination: true,
        isLoading: DttFacilityReducers.isLoading,
        searchValue: DttFacilityReducers.dttFacilitySearch
    };
};

const mapDispatchToProps = {
    performSearch: facilitySetSearch,
    sortByColumn: sortFacilitiesByColumn,
    setPage
};

const DttTableFacilityContainer =
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(DistrictTripTrackerTable));

export default DttTableFacilityContainer;