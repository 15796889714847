import React from 'react';
import PropTypes, {
    bool,
    func,
    string
} from 'prop-types';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { museoSansFont } from 'util/EnumHelper';

import StyledTextField from '../StyledTextField/StyledTextField';
import StyledTypography from '../StyledTypography/StyledTypography';

const StyleLabel = styled(StyledTypography)(({ theme }) => ({
    '&:active, &.active': {
        color: theme.palette.everDrivenDarkCharcoal60,
        opacity: 1
    },
    color: theme.palette.everDrivenDarkCharcoal60,
    opacity: 1,
    fontFamily: museoSansFont,
    margin: 0,
    fontSize: 16
}));

function DateTextField(props, id, dateErrorMessage) {
    return <StyledTextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        id={id}
        isNewStyle
        size="small"
        variant="filled"
        InputLabelProps={{
            shrink: false,
            fontFamily: museoSansFont
        }}
        // eslint-disable-next-line react/destructuring-assignment
        label={props.inputProps.value === ""
            ? <StyleLabel variant="body1"> MM/DD/YYYY </StyleLabel>
            : ""
        }
        autoComplete="disabled"
        fullWidth
        isError={dateErrorMessage}
        testId={id}
    />;
}

function StyledDatePicker({
    value,
    minDate,
    maxDate,
    id,
    error,
    onChange
}) {
    const handleChange = (evt) => {
        if(evt && (evt.toString() !== 'Invalid Date')) {
            onChange(evt, id);
        } else {
            onChange(null, id);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                data-testid={id}
                fullWidth
                required
                className="qm_block"
                disableToolbar
                variant="inline"
                inputFormat="MM/dd/yyyy"
                value={value}
                minDate={minDate ?? ''}
                maxDate={maxDate ?? ''}
                onChange={(evt) => handleChange(evt)}
                renderInput={(params) => DateTextField(
                    params,
                    `${id}`,
                    error
                )}
                KeyboardButtonProps={{
                    'aria-label': 'change date'
                }}
                InputAdornmentProps={{
                    color: "secondary"
                }}
            />

        </LocalizationProvider>
    );
}

StyledDatePicker.defaultProps = {
    value: null,
    minDate: null,
    maxDate: null,
    id: '',
    error: false,
    onChange: () => {}
};

/* eslint-disable react/forbid-prop-types */
StyledDatePicker.propTypes = {
    value: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    id: string,
    error: bool,
    onChange: func
};
/* eslint-enable react/forbid-prop-types */

export default StyledDatePicker;