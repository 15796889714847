import {
    DTT_STUDENT_RESET_COLUMNS,
    DTT_STUDENT_SEARCHTERM,
    DTT_STUDENT_SORT_BY_COLUMN,
    DTT_STUDENT_STATE_COLUMNS,
    DTT_STUDENT_IS_LOADING,
    DTT_STUDENT_SET_STUDENTS,
    DTT_STUDENT_SORT_STUDENTS,
    DTT_STUDENT_SET_SHOW_STUDENT_DATA
} from 'redux/actions/districtTripTracker/dttStudentActions';

import {
    initialStudentsColumnsSorted
} from '../../constants';

const initialState = {
    isLoading: true,

    dttStudents: [],
    dttStudentsSorted: [],
    dttStudentSearch: '',
    dttStudentColumns: JSON.parse(JSON.stringify(initialStudentsColumnsSorted)),
    dttStudentSortColumn: initialStudentsColumnsSorted.lastName.name,
    showStudentData: false
};

/* eslint-disable-next-line default-param-last */
const DttStudentReducers = (state = initialState, action) => {
    switch (action.type) {
    case DTT_STUDENT_IS_LOADING:
        return {
            ...state,
            isLoading: action.isLoading
        };
    case DTT_STUDENT_SEARCHTERM:
        return {
            ...state,
            dttStudentSearch: action.dttStudentSearch
        };
    case DTT_STUDENT_SORT_BY_COLUMN:
        return {
            ...state,
            dttStudentSortColumn: action.columnName
        };
    case DTT_STUDENT_STATE_COLUMNS:
        return {
            ...state,
            dttStudentColumns: {
                ...state.dttStudentColumns,
                [action.columnName]: {
                    ...state.dttStudentColumns[action.columnName],
                    sort: action.sort
                }
            }
        };
    case DTT_STUDENT_RESET_COLUMNS:
        return {
            ...state,
            dttStudentColumns: JSON.parse(JSON.stringify(initialStudentsColumnsSorted))
        };
    case DTT_STUDENT_SET_STUDENTS:
        return {
            ...state,
            dttStudents: action.dttStudents
        };
    case DTT_STUDENT_SORT_STUDENTS:
        return {
            ...state,
            dttStudentsSorted: action.dttStudentsSorted
        };
    case DTT_STUDENT_SET_SHOW_STUDENT_DATA:
        return {
            ...state,
            showStudentData: action.showStudentData
        };
    default:
        return state;
    }
};

export default DttStudentReducers;
