export const SET_CALENDARUPLOAD_MODEL = 'SET_CALENDARUPLOAD_MODEL';
export const setCalendars = payload => ({
    type: SET_CALENDARUPLOAD_MODEL,
    model: payload
});

export const SET_CALENDARUPLOAD_ATTACHMENTS = 'SET_CALENDARUPLOAD_ATTACHMENTS';
export const setCalendarAttachments = payload => ({
    type: SET_CALENDARUPLOAD_ATTACHMENTS,
    attachments: payload
});

export const SET_CALENDARUPLOAD_KEY_DROPZONE = 'SET_CALENDARUPLOAD_KEY_DROPZONE';
export const setKeyDropZone = payload => ({
    type: SET_CALENDARUPLOAD_KEY_DROPZONE,
    keyDropZone: payload
});

export const SET_CALENDARUPLOAD_NEXT_PATH = 'SET_CALENDARUPLOAD_NEXT_PATH';
export const setNextPath = payload => ({
    type: SET_CALENDARUPLOAD_NEXT_PATH,
    nextPath: payload
});

export const SET_CALENDARUPLOAD_CLEAR_OPEN = 'SET_CALENDARUPLOAD_CLEAR_OPEN';
export const setClearModalOpen = payload => ({
    type: SET_CALENDARUPLOAD_CLEAR_OPEN,
    status: payload
});

export const SET_CALENDARUPLOAD_LEAVE_NO_SAVING_OPEN = 'SET_CALENDARUPLOAD_LEAVE_NO_SAVING_OPEN';
export const setLeaveNoSavingModalOpen = payload => ({
    type: SET_CALENDARUPLOAD_LEAVE_NO_SAVING_OPEN,
    status: payload
});

export const SET_CALENDARUPLOAD_LOADING = 'SET_CALENDARUPLOAD_LOADING';
export const setCalendarUploadLoading = payload => ({
    type: SET_CALENDARUPLOAD_LOADING,
    isLoading: payload
});

export const SET_CALENDARUPLOAD_NOTES = 'SET_CALENDARUPLOAD_NOTES';
export const setCalendarUploadNotes = payload => ({
    type: SET_CALENDARUPLOAD_NOTES,
    notes: payload
});

export const SET_CALENDARUPLOAD_LIST = 'SET_CALENDARUPLOAD_LIST';
export const setCalendarsUploaded = payload => ({
    type: SET_CALENDARUPLOAD_LIST,
    calendarUploadedList: payload
});

export const SET_CALENDARUPLOAD_DELETE_ATTACHMENT_OPEN = 'SET_CALENDARUPLOAD_DELETE_ATTACHMENT_OPEN';
export const setDeleteAttachmentModalOpen = payload => ({
    type: SET_CALENDARUPLOAD_DELETE_ATTACHMENT_OPEN,
    status: payload
});

export const SET_CALENDARUPLOAD_DELETE_FILE_NAME = 'SET_CALENDARUPLOAD_DELETE_FILE_NAME';
export const setDeleteFileName = payload => ({
    type: SET_CALENDARUPLOAD_DELETE_FILE_NAME,
    deleteFileName: payload
});

export const SET_CALENDARUPLOAD_NOTES_OPEN = 'SET_CALENDARUPLOAD_NOTES_OPEN';
export const setNotesModalOpen = payload => ({
    type: SET_CALENDARUPLOAD_NOTES_OPEN,
    status: payload
});

export const SET_CALENDARUPLOAD_SELECTED_NOTES = 'SET_CALENDARUPLOAD_SELECTED_NOTES';
export const setSelectedNotes = payload => ({
    type: SET_CALENDARUPLOAD_SELECTED_NOTES,
    selectedNotes: payload
});

export const SET_CALENDARUPLOAD_REJECTED_FILES = 'SET_CALENDARUPLOAD_REJECTED_FILES';
export const setRejectedCalendarFiles = payload => ({
    type: SET_CALENDARUPLOAD_REJECTED_FILES,
    rejectedCalendars: payload
});

export const CLEAR_CALENDARUPLOAD_REJECTED_FILES = 'CLEAR_CALENDARUPLOAD_REJECTED_FILES';
export const clearRejectedFiles = () => ({
    type: CLEAR_CALENDARUPLOAD_REJECTED_FILES
});