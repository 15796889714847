/* eslint max-lines: 0 */
import _ from 'lodash';
import {
    TRIPREQUESTFORM_RESPONSE,
    TRIPREQUESTFORM_REQUESTFILE,
    SET_TRIPREQUESTFORM_LOADING,
    SET_TRIPREQUESTFORM_SEARCH_TERM,
    SET_TRIPREQUESTFORM_DEFAULT_STUDENT_ID,
    UPDATE_TRIPREQUESTFORM_MODEL,
    SET_TRIPREQUESTFORM_ERROR_MESSAGE,
    SET_TRIPREQUESTFORM_ENABLED_SUBMIT,
    SET_TRIPREQUESTFORM_ENABLED_SCHOOL_INPUT,
    SET_TRIPREQUESTFORM_ENABLED_STUDENT_DATE,
    SET_TRIPREQUESTFORM_SCHOOL_DAY,
    SET_TRIPREQUESTFORM_POPULATION_TYPE,
    SET_TRIPREQUESTFORM_TRANSPORTATION_NEED,
    SET_TRIPREQUESTFORM_SHOW_DIFFERENT_ADDRESS,
    SET_TRIPREQUESTFORM_ATTACHMENTS,
    SET_TRIPREQUESTFORM_EQUIPMENT_NEED,
    SET_TRIPREQUESTFORM_TRIP_POPULATION_TYPES,
    SET_TRIPREQUESTFORM_REMOVE_SIBLING_OPEN,
    SET_TRIPREQUESTFORM_CONFIRM_SUBMIT_OPEN,
    SET_TRIPREQUESTFORM_CURRENT_SIBLING,
    SET_TRIPREQUESTFORM_SIBLING_NUMBER,
    SET_TRIPREQUESTFORM_LIMIT_REACHED,
    SET_TRIPREQUESTFORM_REMOVE_TAB_MESSAGE,
    SET_TRIPREQUESTFORM_SCHEDULE,
    SET_TRIPREQUESTFORM_SUBMIT_FORM_MESSAGE,
    SET_TRIPREQUESTFORM_KEY_DROPZONE,
    SET_TRIPREQUESTFORM_TAB_DELETE,
    UPDATE_TRIPREQUESTFORM_ARRAY_FORM,
    INSERT_TRIPREQUESTFORM_ARRAY_FORM,
    REMOVE_TRIPREQUESTFORM_ARRAY_FORM,
    SET_TRIPREQUESTFORM_SCHEDULE_VALID,
    SET_TRIPREQUESTFORM_IS_SAME_ADDRESS,
    SET_TRIPREQUESTFORM_IS_SAME_TRIP_INFO,
    SET_TRIPREQUESTFORM_IS_SAME_CONTACT,
    SET_TRIPREQUESTFORM_IS_SAME_SCHOOL,
    SET_TRIPREQUESTFORM_SELECTED_HOME_ADDRESS,
    SET_TRIPREQUESTFORM_SELECTED_HOME_ADDRESS2,
    SET_TRIPREQUESTFORM_SELECTED_PICKUP_ADDRESS,
    SET_TRIPREQUESTFORM_SELECTED_PICKUP_ADDRESS2,
    SET_TRIPREQUESTFORM_SELECTED_DROPOFF_ADDRESS,
    SET_TRIPREQUESTFORM_SELECTED_DROPOFF_ADDRESS2,
    SET_TRIPREQUESTFORM_SELECTED_SCHOOL,
    SET_TRIPREQUESTFORM_SELECTED_SCHOOL_ADDRESS,
    SET_TRIPREQUESTFORM_SELECTED_SCHOOL_ADDRESS2,
    SET_TRIPREQUESTFORM_SELECTED_STUDENT,
    SET_TRIPREQUESTFORM_CLEAN,
    SET_TRIPREQUESTFORM_EMAIL_ERROR,
    SET_TRIPREQUESTFORM_CLEAR_STUDENT_OPEN,
    SET_TRIPREQUESTFORM_SELECTED_ACCOUNT_HAS_NO_EMAIL,
    SET_TRIPREQUESTFORM_STUDENT_STARTDATE_ERRORMESSAGE,
    SET_TRIPREQUESTFORM_STUDENT_BIRTHDATE_ERRORMESSAGE,
    SET_TRIPREQUESTFORM_STUDENT_ENDDATE_ERRORMESSAGE,
    SET_TRIPREQUESTFORM_ENABLED_DRAFT,
    SET_TRIPREQUESTFORM_DRAFT_FOR_SAVE,
    SET_TRIPREQUESTFORM_SAVE_DRAFT_BEFORE_LEAVE,
    SET_TRIPREQUESTFORM_NEXT_PATH,
    SET_TRIPREQUESTFORM_DRAFT_INDICATOR,
    SET_TRIPREQUESTFORM_UPLOADED_DRAFT_ATTACHMENTS,
    SET_TRIPREQUESTFORM_OLD_DRAFT_MODEL,
    SET_TRIPREQUESTFORM_OLD_DRAFT_ATTACHMENTS,
    SET_TRIPREQUESTFORM_OLD_DRAFT_POPULATION_TYPE,
    SET_TRIPREQUESTFORM_DELETE_DRAFT,
    SET_TRIPREQUESTFORM_DRAFT_SELECTED,
    SET_TRIPREQUESTFORM_OLD_DRAFT_TRANSPORTATION_NEED,
    SET_TRIPREQUESTFORM_NON_SCHOOL_ADDRESS_LIST,
    SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_HOME_ADDRESS,
    SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_PICKUP_ADDRESS,
    SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_DROPOFF_ADDRESS,
    SET_TRIPREQUESTFORM_CALLED_GETCORRELATIONID
} from '../actions/tripRequestFormActions';
import {
    populationTypeDto,
    scheduleModel,
    scheduleValidModel,
    schoolDaysDto,
    transportationEquipmentDto
} from '../../data/models/tripRequestForm/TripRequestFormModel';

const tripRequestFormInitialState = {
    response: {},
    responseFile: { status: '' },
    currentSibling: 1,
    siblingNumber: 1,
    tabForDelete: 1,
    limitReached: false,
    tripModels: [],
    tripModel: {},
    enabledSchoolInputs: {
        schoolName: false,
        addressCity: false,
        addressZipCode: false,
        addressState: false,
        schoolPhone: false
    },
    populationTypes: populationTypeDto,
    transportationEquipmentNeeds: transportationEquipmentDto,
    schedule: scheduleModel,
    scheduleValid: scheduleValidModel,
    schoolDays: schoolDaysDto,
    showDifferentAddress: {
        pk: false,
        do: false
    },
    attachStudentDocuments: [],
    uploadedDraftAttachments: [],
    isTRFLoading: false,
    errorMessages: [],
    searchTerm: '',
    defaultStudentId: '',
    selectedHomeAddress: {},
    selectedHomeAddress2: {},
    selectedPickUpAddress: {},
    selectedPickUpAddress2: {},
    selectedDropOffAddress: {},
    selectedDropOffAddress2: {},
    selectedSchool: {},
    selectedSchoolAddress: {},
    selectedSchoolAddress2: {},
    selectedStudent: {},
    isEnabledStudentDate: false,
    isEnabledDraft: false,
    isEnabledSubmit: true,
    isRemoveSiblingOpen: false,
    isSubmitConfirmOpen: false,
    isClearStudentFormOpen: false,
    removeTabMessage: '',
    submitFormMessage: '',
    keyDropZone: Math.random().toString(),
    isSameAddress: false,
    isSameTripInformation: false,
    isSameContacts: false,
    isSameSchool: false,
    isDraftForSave: false,
    isSaveDraftBeforeLeaveOpen: false,
    emailErrors: {
        parent1EmailAddress: false,
        contact1EmailAddress: false,
        contact2EmailAddress: false
    },
    selectedAccountHasNoEmail: false,
    studentBirthDateErrorMessage: '',
    studentStartDateErrorMessage: '',
    studentEndDateErrorMessage: '',
    nextPath: '',
    oldDraftModel: {},
    oldDraftAttachments: [],
    oldDraftPopulationTypes: populationTypeDto,
    oldDraftTransportationEquipmentNeeds: transportationEquipmentDto,
    isDraftSelected: false,
    isDeleteDraftModalOpen: false,
    nonSchoolAddressList: [],
    selectedPreviousHomeAddress: "",
    selectedPreviousPickUpAddress: "",
    selectedPreviousDropOffAddress: "",
    calledGetCorrelationId: false
};

/* eslint-disable-next-line default-param-last */
const tripRequestFormReducers = (state = tripRequestFormInitialState, action) => {
    switch (action.type) {
    case TRIPREQUESTFORM_RESPONSE:
        return {
            ...state,
            response: action.response
        };
    case TRIPREQUESTFORM_REQUESTFILE:
        return {
            ...state,
            responseFile: action.responseFile
        };
    case SET_TRIPREQUESTFORM_LOADING:
        return {
            ...state,
            isTRFLoading: action.isLoading
        };
    case SET_TRIPREQUESTFORM_SEARCH_TERM:
        return {
            ...state,
            searchTerm: action.searchTerm
        };
    case SET_TRIPREQUESTFORM_DEFAULT_STUDENT_ID:
        return {
            ...state,
            defaultStudentId: action.defaultStudentId
        };
    case UPDATE_TRIPREQUESTFORM_MODEL:
        return {
            ...state,
            isDraftForSave: state.tripModel.studentId !== '' && state.tripModel.studentFirstName !== '' &&
                state.tripModel.studentLastName !== '' &&
                (action.model.studentId === undefined || action.model.studentId !== '') &&
                (action.model.studentFirstName === undefined || action.model.studentFirstName !== '') &&
                (action.model.studentLastName === undefined || action.model.studentLastName !== '') &&
                (!_.isEqual({ ...state.tripModel, ...action.model }, state.oldDraftModel) ||
                !_.isEqual(state.populationTypes, state.oldDraftPopulationTypes) ||
                !_.isEqual(state.transportationEquipmentNeeds, state.oldDraftTransportationEquipmentNeeds) ||
                !_.isEqual(state.attachStudentDocuments, state.oldDraftAttachments)),
            tripModel: {
                ...state.tripModel,
                ...action.model
            }
        };
    case SET_TRIPREQUESTFORM_ERROR_MESSAGE:
        return {
            ...state,
            errorMessages: action.errorMessage
        };
    case SET_TRIPREQUESTFORM_ENABLED_SUBMIT:
        return {
            ...state,
            isEnabledSubmit: action.isEnabled
        };
    case SET_TRIPREQUESTFORM_ENABLED_SCHOOL_INPUT:
        return {
            ...state,
            enabledSchoolInputs: {
                ...state.enabledSchoolInputs,
                ...action.enabledSchoolInputs
            }
        };
    case SET_TRIPREQUESTFORM_ENABLED_STUDENT_DATE:
        return {
            ...state,
            isEnabledStudentDate: action.isEnabledStudentDate
        };
    case SET_TRIPREQUESTFORM_SCHOOL_DAY:
        return {
            ...state,
            schoolDays: {
                ...state.schoolDays,
                ...action.schoolDays
            }
        };
    case SET_TRIPREQUESTFORM_POPULATION_TYPE:
        return {
            ...state,
            isDraftForSave: state.tripModel.studentId !== '' && state.tripModel.studentFirstName !== '' &&
                state.tripModel.studentLastName !== '' &&
                (!_.isEqual(state.tripModel, state.oldDraftModel) ||
                !_.isEqual({...state.populationTypes, ...action.populationTypes}, state.oldDraftPopulationTypes) ||
                !_.isEqual(state.transportationEquipmentNeeds, state.oldDraftTransportationEquipmentNeeds) ||
                !_.isEqual(state.attachStudentDocuments, state.oldDraftAttachments)),
            populationTypes: {
                ...state.populationTypes,
                ...action.populationTypes
            }
        };
    case SET_TRIPREQUESTFORM_TRANSPORTATION_NEED:
        return {
            ...state,
            isDraftForSave: state.tripModel.studentId !== '' && state.tripModel.studentFirstName !== '' &&
                state.tripModel.studentLastName !== '' &&
                (!_.isEqual(state.tripModel, state.oldDraftModel) ||
                !_.isEqual({...state.transportationEquipmentNeeds, ...action.transportationNeeds},
                    state.oldDraftTransportationEquipmentNeeds) ||
                !_.isEqual(state.populationTypes, state.oldDraftPopulationTypes) ||
                !_.isEqual(state.attachStudentDocuments, state.oldDraftAttachments)),
            transportationEquipmentNeeds: {
                ...state.transportationEquipmentNeeds,
                ...action.transportationNeeds
            }
        };
    case SET_TRIPREQUESTFORM_SHOW_DIFFERENT_ADDRESS:
        return {
            ...state,
            showDifferentAddress: {
                ...state.showDifferentAddress,
                ...action.showDifferentAddress
            }
        };
    case SET_TRIPREQUESTFORM_ATTACHMENTS:
        return {
            ...state,
            isDraftForSave: state.tripModel.studentId !== '' && state.tripModel.studentFirstName !== '' &&
                state.tripModel.studentLastName !== '' &&
                (!_.isEqual(state.tripModel, state.oldDraftModel) ||
                !_.isEqual(state.transportationEquipmentNeeds, state.oldDraftTransportationEquipmentNeeds) ||
                !_.isEqual(state.populationTypes, state.oldDraftPopulationTypes) ||
                !_.isEqual(action.studentAttachments, state.oldDraftAttachments)),
            attachStudentDocuments: action.studentAttachments
        };
    case SET_TRIPREQUESTFORM_EQUIPMENT_NEED:
        return {
            ...state,
            tripModel: {
                ...state.tripModel,
                transportationEquipmentNeeds: action.equipmentNeeds
            }
        };
    case SET_TRIPREQUESTFORM_TRIP_POPULATION_TYPES:
        return {
            ...state,
            tripModel: {
                ...state.tripModel,
                populationTypes: action.populationTypes
            }
        };
    case SET_TRIPREQUESTFORM_REMOVE_SIBLING_OPEN:
        return {
            ...state,
            isRemoveSiblingOpen: action.status
        };
    case SET_TRIPREQUESTFORM_CONFIRM_SUBMIT_OPEN:
        return {
            ...state,
            isSubmitConfirmOpen: action.status
        };
    case SET_TRIPREQUESTFORM_CLEAR_STUDENT_OPEN:
        return {
            ...state,
            isClearStudentFormOpen: action.status
        };
    case SET_TRIPREQUESTFORM_SIBLING_NUMBER:
        return {
            ...state,
            siblingNumber: action.siblingNumber
        };
    case SET_TRIPREQUESTFORM_CURRENT_SIBLING:
        return {
            ...state,
            currentSibling: action.currentSibling
        };
    case SET_TRIPREQUESTFORM_LIMIT_REACHED:
        return {
            ...state,
            limitReached: action.limitReached
        };
    case SET_TRIPREQUESTFORM_REMOVE_TAB_MESSAGE:
        return {
            ...state,
            removeTabMessage: action.message
        };
    case SET_TRIPREQUESTFORM_SCHEDULE:
        return {
            ...state,
            schedule: {
                ...state.schedule,
                ...action.schedule
            }
        };
    case SET_TRIPREQUESTFORM_SUBMIT_FORM_MESSAGE:
        return {
            ...state,
            submitFormMessage: action.message
        };
    case SET_TRIPREQUESTFORM_KEY_DROPZONE:
        return {
            ...state,
            keyDropZone: action.keyDropZone
        };
    case UPDATE_TRIPREQUESTFORM_ARRAY_FORM:
        return {
            ...state,
            tripModels: state.tripModels.map((t) => {
                if (t.student === action.form.student) {
                    return action.form;
                }

                return t;
            })
        };
    case INSERT_TRIPREQUESTFORM_ARRAY_FORM:
        return {
            ...state,
            tripModels: [ ...state.tripModels, action.form ]
        };
    case REMOVE_TRIPREQUESTFORM_ARRAY_FORM:
        return {
            ...state,
            tripModels: state.tripModels.filter((t) => t.student !== action.formNumber).map((t) => {
                if (t.student > action.formNumber) {
                    const newT = t;

                    newT.student = t.student - 1;

                    return newT;
                }

                return t;
            })
        };
    case SET_TRIPREQUESTFORM_TAB_DELETE:
        return {
            ...state,
            tabForDelete: action.tabNumber
        };
    case SET_TRIPREQUESTFORM_SCHEDULE_VALID:
        return {
            ...state,
            scheduleValid: {
                ...state.scheduleValid,
                ...action.scheduleValid
            }
        };
    case SET_TRIPREQUESTFORM_IS_SAME_ADDRESS:
        return {
            ...state,
            isSameAddress: action.isSameAddress
        };
    case SET_TRIPREQUESTFORM_IS_SAME_TRIP_INFO:
        return {
            ...state,
            isSameTripInformation: action.isSameTripInformation
        };
    case SET_TRIPREQUESTFORM_IS_SAME_CONTACT:
        return {
            ...state,
            isSameContacts: action.isSameContacts
        };
    case SET_TRIPREQUESTFORM_IS_SAME_SCHOOL:
        return {
            ...state,
            isSameSchool: action.isSameSchool
        };
    case SET_TRIPREQUESTFORM_SELECTED_HOME_ADDRESS:
        return {
            ...state,
            selectedHomeAddress: action.selectedHomeAddress
        };
    case SET_TRIPREQUESTFORM_SELECTED_HOME_ADDRESS2:
        return {
            ...state,
            selectedHomeAddress2: action.selectedHomeAddress2
        };
    case SET_TRIPREQUESTFORM_SELECTED_PICKUP_ADDRESS:
        return {
            ...state,
            selectedPickUpAddress: action.selectedPickUpAddress
        };
    case SET_TRIPREQUESTFORM_SELECTED_PICKUP_ADDRESS2:
        return {
            ...state,
            selectedPickUpAddress2: action.selectedPickUpAddress2
        };
    case SET_TRIPREQUESTFORM_SELECTED_DROPOFF_ADDRESS:
        return {
            ...state,
            selectedDropOffAddress: action.selectedDropOffAddress
        };
    case SET_TRIPREQUESTFORM_SELECTED_DROPOFF_ADDRESS2:
        return {
            ...state,
            selectedDropOffAddress2: action.selectedDropOffAddress2
        };
    case SET_TRIPREQUESTFORM_SELECTED_SCHOOL:
        return {
            ...state,
            selectedSchool: action.selectedSchool
        };
    case SET_TRIPREQUESTFORM_SELECTED_SCHOOL_ADDRESS:
        return {
            ...state,
            selectedSchoolAddress: action.selectedSchoolAddress
        };
    case SET_TRIPREQUESTFORM_SELECTED_SCHOOL_ADDRESS2:
        return {
            ...state,
            selectedSchoolAddress2: action.selectedSchoolAddress2
        };
    case SET_TRIPREQUESTFORM_SELECTED_STUDENT:
        return {
            ...state,
            selectedStudent: action.selectedStudent
        };
    case SET_TRIPREQUESTFORM_CLEAN:
        return {
            ...state,
            ...tripRequestFormInitialState,
            selectedHomeAddress: {},
            selectedHomeAddress2: {},
            tripModel: {
                ...tripRequestFormInitialState.tripModel,
                tripStartDate: null,
                tripEndDate: null,
                studentDateOfBirth: null
            }
        };
    case SET_TRIPREQUESTFORM_EMAIL_ERROR:
        return {
            ...state,
            emailErrors: {
                ...state.emailErrors,
                ...action.emailErrors
            }
        };
    case SET_TRIPREQUESTFORM_SELECTED_ACCOUNT_HAS_NO_EMAIL:
        return {
            ...state,
            selectedAccountHasNoEmail: action.selectedAccountHasNoEmail
        };
    case SET_TRIPREQUESTFORM_STUDENT_STARTDATE_ERRORMESSAGE:
        return {
            ...state,
            studentStartDateErrorMessage: action.errorMessage
        };
    case SET_TRIPREQUESTFORM_STUDENT_BIRTHDATE_ERRORMESSAGE:
        return {
            ...state,
            studentBirthDateErrorMessage: action.errorMessage
        };
    case SET_TRIPREQUESTFORM_STUDENT_ENDDATE_ERRORMESSAGE:
        return {
            ...state,
            studentEndDateErrorMessage: action.errorMessage
        };
    case SET_TRIPREQUESTFORM_ENABLED_DRAFT:
        return {
            ...state,
            isEnabledDraft: action.isEnabledDraft
        };
    case SET_TRIPREQUESTFORM_DRAFT_FOR_SAVE:
        return {
            ...state,
            isDraftForSave: action.isDraftForSave
        };
    case SET_TRIPREQUESTFORM_SAVE_DRAFT_BEFORE_LEAVE:
        return {
            ...state,
            isSaveDraftBeforeLeaveOpen: action.status
        };
    case SET_TRIPREQUESTFORM_NEXT_PATH:
        return {
            ...state,
            nextPath: action.nextPath
        };
    case SET_TRIPREQUESTFORM_DRAFT_INDICATOR:
        return {
            ...state,
            isDraftForSave: false,
            tripModels: state.tripModels.map(x => {
                const newT = x;

                newT.isDraftForSave = false;

                return newT;
            })
        };
    case SET_TRIPREQUESTFORM_UPLOADED_DRAFT_ATTACHMENTS:
        return {
            ...state,
            uploadedDraftAttachments: action.uploadedDraftAttachments
        };
    case SET_TRIPREQUESTFORM_OLD_DRAFT_MODEL:
        return {
            ...state,
            oldDraftModel: {
                ...state.oldDraftModel,
                ...action.model
            }
        };
    case SET_TRIPREQUESTFORM_OLD_DRAFT_ATTACHMENTS:
        return {
            ...state,
            oldDraftAttachments: action.studentAttachments
        };
    case SET_TRIPREQUESTFORM_OLD_DRAFT_POPULATION_TYPE:
        return {
            ...state,
            oldDraftPopulationTypes: {
                ...state.oldDraftPopulationTypes,
                ...action.populationTypes
            }
        };
    case SET_TRIPREQUESTFORM_OLD_DRAFT_TRANSPORTATION_NEED:
        return {
            ...state,
            oldDraftTransportationEquipmentNeeds: {
                ...state.oldDraftTransportationEquipmentNeeds,
                ...action.transportationNeeds
            }
        };
    case SET_TRIPREQUESTFORM_DELETE_DRAFT: {
        return {
            ...state,
            isDeleteDraftModalOpen: action.status
        };
    }
    case SET_TRIPREQUESTFORM_DRAFT_SELECTED: {
        return {
            ...state,
            isDraftSelected: action.isDraftSelected
        };
    }
    case SET_TRIPREQUESTFORM_NON_SCHOOL_ADDRESS_LIST: {
        return {
            ...state,
            nonSchoolAddressList: action.addressList
        };
    }
    case SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_HOME_ADDRESS: {
        return {
            ...state,
            selectedPreviousHomeAddress: action.selectedAddress
        };
    }
    case SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_PICKUP_ADDRESS: {
        return {
            ...state,
            selectedPreviousPickUpAddress: action.selectedAddress
        };
    }
    case SET_TRIPREQUESTFORM_SELECTED_PREVIOUS_DROPOFF_ADDRESS: {
        return {
            ...state,
            selectedPreviousDropOffAddress: action.selectedAddress
        };
    }
    case SET_TRIPREQUESTFORM_CALLED_GETCORRELATIONID:
        return {
            ...state,
            calledGetCorrelationId: action.calledGetCorrelationId
        };
    default:
        return state;
    }
};

export default tripRequestFormReducers;
