/* eslint max-lines: 0 */
import breakpointsNames from "styles/ResponsiveConstants";
import COLORCONSTANTS from "styles/ColorConstants";

export const Enum = {
    MrmKeyType: {
        None: 0,
        Member: 1,
        Trip: 2,
        Facility: 3
    },
    MrmMsgType: {
        CloudWakeup: 100,
        CloudWakeupList: 110,
        Command: 120,
        CommandResolved: 130,
        MemberWakeup: 140,
        DeviceWakeupList: 150,
        FacilityProfile: 160,
        FindMember: 170,
        GetMemberPII: 180,
        GetMemberAdminProfile: 190,
        MemberEligibility: 200,
        MemberList: 210,
        MemberPII: 220,
        MemberProfile: 230,
        MemberAdminProfile: 240,
        MemberTripList: 250,
        TripStatusExpectation: 260,
        SelectMember: 270,
        SessionExpired: 280,
        SystemSettingListRiB: 290,
        SystemSettingListClientWeb: 292,
        SystemSettingListPassengerMobile: 296,
        TripChat2Dispatch: 300,
        TripChat2Member: 310,
        TripChatList: 320,
        TripStatus: 330,
        RateTrip: 340,
        SupportInfo: 350,
        SelectFacility: 400
    },
    TripState: {
        Scheduled: 1,
        WillCall: 11, // Yup I changed it from NotYetActivatedWillCall, the rest of the system expects WillCall.
        Active: 21,
        EnRoute: 101,
        OnSite: 111,
        OnBoard: 121,
        Accepted: 131,
        OnSiteAtDropoff: 141,
        Canceled: 201,
        NoShow: 211,
        Completed: 221,
        Voided: 231,
        PendingCancel: 301,
        PendingActivate: 311
    },
    TripSection: {
        None: 0,
        Today: 1,
        Future: 2,
        NoShow: 3,
        Finished: 4
    },
    PassengerCapacity: {
        Ambulatory: 0,
        Wheelchair: 1,
        NotSet: 2,
        CustomWheelchair: 3,
        PowerScooter: 4,
        CarSeat: 5,
        OverSizedWheelchair: 6,
        Gurney: 7
    },
    ServiceLevel: {
        Curb2Curb: 0,
        Door2Door: 1,
        Hand2Hand: 2
    },
    Command: {
        ActivateTrip: 0,
        CancelTrip: 1,
        RequestTransportation: 2
    },
    CommandResolved: {
        Approved: 0,
        Rejected: 1
    },
    CommandPayloadType: {
        None: 0,
        TransportationRequestBasic: 1
    },
    Sorter:{
        Desc:'desc',
        Asc: 'asc'
    },
    populationTypes: {
        NOT: 'Not Specified',
        MKV: 'MKV (McKinney-Vento)',
        ESE: 'ESE (Exceptional Student Education)',
        ESY: 'ESY (Extended School Year)',
        ATP: 'ATP (Adult Transition Program)',
        NCLB: 'NCLB (No Child Left Behind)',
        RegularEd: 'Regular Ed. (Regular Education Services)',
        MedFragile: 'Med. Fragile (Medically Fragile)',
        Magnet: 'Magnet (Magnet School)',
        OutofDistrict: 'Out of District',
        SPED: 'SPED (Special Education Services)',
        MultiDistrict: 'Multi-District (Students of different districts rideshare)',
        DeafEd: 'Deaf Ed. (Education services for hearing impaired students)',
        SpecialEvents: 'Special Events (Field Trips, etc.)',
        SchoolofChoice:'School of Choice',
        ESSA: 'ESSA (Every Student Succeeds Act)',
        PreK: 'Pre-K/ECE (Pre-Kindergarten/Early Childhood Ed.)'
    },
    transportationNeeds: {
        handToHand: 'Hand To Hand',
        monitorNeeded: 'Monitor Needed',
        mustRideAlone: 'Must Ride Alone',
        booster: 'CS - Booster',
        safetyVest: 'Safety Vest',
        carSeat: 'CS - Toddler',
        buckleGuard: 'Buckle Guard',
        wheelchair: 'Wheelchair',
        notApplicable: 'Ambulatory'
    },
    transportationNeedsName:{
        handToHand: 'handToHand',
        monitorNeeded: 'monitorNeeded',
        mustRideAlone: 'mustRideAlone',
        booster: 'booster',
        safetyVest: 'safetyVest',
        carSeat: 'carSeat',
        buckleGuard: 'buckleGuard',
        wheelchair: 'wheelchair'
    },
    reportRequestType: {
        Download: 0,
        View: 1
    },
    severityType: {
        error: 'error',
        errorInLine: 'error_in_line',
        success: 'success',
        warning: 'warning',
        info: 'info'
    },
    reportMessageType: {
        getReportFailed: "GET_REPORT_FAILED",
        downloadFailInside: "DOWNLOAD_FAIL_INSIDE",
        downloadFailMain: "DOWNLOAD_FAIL_MAIN",
        downloadSuccess: "DOWNLOAD_SUCCESS",
        studentNotFound: "STUDENT_NOT_FOUND"
    },
    reportStatus: {
        active: { key: 'active', value: 'ACTIVE' },
        onHold: { key: 'onHold', value: 'ON HOLD' },
        inProgress: { key: 'inProgress', value: 'IN PROGRESS' }
    },
    requestType: {
        new: 'new',
        update: 'update'
    },
    etaBuffer: 5,
    tripTimeToCancel: 1,
    tripTimeToWarnning: 2,
    modalCloseReason: {
        escapeKeyDown: "escapeKeyDown",
        backdropClick: "backdropClick"
    },
    maxAllowedSchoolsLength: 5,
    maxFileSize: 5242880,
    FALSE: 'False',
    TRUE: 'True',
    filterUsers: {
        allUsers: 0,
        admins: 1,
        progMgmt: 2,
        DTT: 3,
        VIP: 4,
        activeUsers: 5,
        inactiveUsers: 6
    },
    wheelchairOptions: {
        needsRamp: 'Needs Ramp',
        needsLift: 'Needs Lift'
    },
    monitorNeeded: {
        districtMonitor: 'District Monitor',
        everDrivenMonitor: 'Ever Driven Monitor'
    },
    safetyVestOptions: {
        extraSmall: 'Extra Small (18”-24”)',
        small: 'Small (25”-32”)',
        medium: 'Medium (32” -37”)',
        large: 'Large (37”-42”)'
    },
    typeOfInvite: {
        allInvites: 0,
        portalInvite: 1,
        vipInvite: 2
    },
    closedBannerCookie: "closedBanner",
    mswHandlersUrl: {
        baseUrl: 'https://t23-ridemanager-ex.alcschools.cloud',
        getConfig: '/api/v2/Config',
        getRunsheet: '/api/v2/Runsheet',
        postDraftRequest: '/api/v2/DraftRequest'
    }
};

// Simple function to find the key of an object given its value
export const getKeyByValue = (obj, value) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const element in obj) {
        if (obj[element] === value) {
            return element;
        }
    }

    return null;
};

export const paths = {
    DistrictTripTracker: {
        path: '/DistrictTripTracker',
        tabValue: 0
    },
    Home: {
        path: '/',
        tabValue: 20
    },
    TransportationRequest: {
        path: '/TransportationRequest',
        tabValue: 17
    },
    TransportationRequestForm: {
        path: '/TransportationRequestForm',
        tabValue: 17
    },
    CalendarUpload: {
        path: '/Calendars',
        tabValue: 2
    },
    Reports: {
        path: '/Reports',
        tabValue: 3
    },
    Invoicing: {
        path: '/Invoicing',
        tabValue: 4
    },
    User: {
        path: '/User',
        tabValue: 5
    },
    Support: {
        tabValue: 6
    },
    ResetPassword: {
        tabValue: 7
    },
    LogOut: {
        tabValue: 8
    },
    SchoolDetail: {
        path: `/School/%id/current`,
        tabValue: 9
    },
    StudentDetail: {
        path: `/triplist/%id`,
        tabValue: 10
    },
    Contact: {
        tabValue: 11
    },
    StudentOnboardingReport: {
        path: '/StudentOnboardingReport',
        tabValue: 12
    },
    CalendarDetail: {
        path: `/calendar/%id`,
        tabValue: 13
    },
    UserGuide: {
        path: '/userGuide/UserGuide.pdf',
        tabValue: 14
    },
    StudentUpload: {
        path: '/TransportationRequestForm/StudentUpload',
        tabValue: 15
    },
    DraftList: {
        path: '/DraftList',
        tabValue: 16
    },
    TrackTrip: {
        path: '/school/%facilityId/tracktrip/%studentId/%tripId',
        tabValue: 18
    },
    Error: {
        path: '/Error',
        tabValue: 19
    },
    TripDetail: {
        path: '/tracktrip/%studentId/%tripId',
        tabValue: 21
    },
    NewStudentDetail: {
        path: `/newtriplist/%id`,
        tabValue: 10
    },
    Resources: {
        path: '/Resources',
        tabValue: 22
    },
    HowToGrantAppAccess: {
        path: '/Resources/how-to-grant-app-access',
        tabValue: 23
    }
};

export const urlSupport = {
    url: "https://rrts1614707543.atlassian.net/servicedesk/customer/portal/3/group/17/create/58"
};

export const urlUpdates = {
    url: "https://www.everdriven.com/district-portal-updates/"
};

export const urlMainSupport = {
    url: "http://support.everdriven.com/"
};

export const defaultContact = {
    email: 'portalsupport@alcschools.com',
    phone: '(877) 225-7750 x 1'
};

export const urlStudentUploadTemplate = {
    url: "/studentTemplate/EverDrivenStudentDataUploadTemplate.xlsx"
};

export const urlDPTrainingVideos = [
    {
        url: "https://www.loom.com/embed/0a5a24c385f641c38215b6cf2afd36df?sid=22546157-8c08-4072-bd9c-9b3c772d2759",
        length: "9:33",
        sortId: 0,
        title:"resources.districtPortalTraining",
        description: "resources.description"
    }
];

export const loomFlags = '?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';

export const optionsFilterUsers = [
    {
        id: 0,
        name: 'userManagement.allUsers',
        quantity: 0,
        variant: 'contained',
        color: "primary"
    },
    {
        id: 1,
        name: 'userManagement.admin',
        quantity: 0,
        variant: 'text',
        color: "inherit"
    },
    {
        id: 2,
        name: 'userManagement.progMgmt',
        quantity: 0,
        variant: 'text',
        color: "inherit"
    },
    {
        id: 3,
        name: 'userManagement.DTT',
        quantity: 0,
        variant: 'text',
        color: "inherit"
    },
    {
        id: 4,
        name: 'userManagement.VIP',
        quantity: 0,
        variant: 'text',
        color: "inherit"
    },
    {
        id: 5,
        name: 'userManagement.activeUsers',
        quantity: 0,
        variant: 'text',
        color: "inherit"
    },
    {
        id: 6,
        name: 'userManagement.inactiveUsers',
        quantity: 0,
        variant: 'text',
        color: "inherit"
    }
];

export const draftListColumns = [
    {
        id: 0,
        text: 'draftList.studentLastName',
        name: 'studentLastName'
    },
    {
        id: 1,
        text: 'draftList.studentFirstName',
        name: 'studentFirstName'
    },
    {
        id: 2,
        text: 'draftList.studentId',
        name: 'studentId'
    },
    {
        id: 3,
        text: 'draftList.studentInputSource',
        name: 'source'
    },
    {
        id: 4,
        text: 'draftList.studentCreationDate',
        name: 'dateDraftSaved'
    },
    {
        id: 5,
        text: 'draftList.studentPercentage',
        name: 'percent'
    },
    {
        id: 6,
        text: 'draftList.rightControls',
        name: ''
    }
];

export const breakpointsWithoutMobile = [
    breakpointsNames.xl,
    breakpointsNames.lg,
    breakpointsNames.md
];

export const breakpointsWithMobile = [
    ...breakpointsWithoutMobile,
    breakpointsNames.sm,
    breakpointsNames.xs
];

export const studentSearchColumnsNames = {
    lastName: 0,
    firstName: 1,
    clientMemberCode: 2,
    request: 3,
    tripList: 4
};

export const studentSearchColumns = [
    {
        id: 0,
        text: 'dttStudent.lastName',
        name: 'lastName',
        visible: breakpointsWithMobile
    },
    {
        id: 1,
        text: 'dttStudent.firstName',
        name: 'firstName',
        visible: breakpointsWithMobile
    },
    {
        id: 2,
        text: 'dttStudent.studentId',
        name: 'clientMemberCode',
        visible: breakpointsWithoutMobile,
        isNumeric: true
    },
    {
        id: 3,
        text: 'dttStudent.request',
        name: '',
        visible: breakpointsWithoutMobile
    },
    {
        id: 4,
        text: 'dttStudent.tripList',
        name: '',
        visible: breakpointsWithMobile
    }
];

export const facilitySearchColumns = [
    {
        id: 0,
        text: 'dttFacility.name',
        name: 'name',
        visible: breakpointsWithMobile
    },
    {
        id: 1,
        text: 'dttFacility.address',
        name: 'address',
        visible: breakpointsWithoutMobile
    },
    {
        id: 2,
        text: 'dttFacility.calendar',
        name: '',
        visible: breakpointsWithMobile

    },
    {
        id: 3,
        text: 'dttFacility.view',
        name: '',
        visible: breakpointsWithMobile
    }
];

export const studentSearchColumnsWithOutRequest = [
    studentSearchColumns[studentSearchColumnsNames.lastName],
    studentSearchColumns[studentSearchColumnsNames.firstName],
    studentSearchColumns[studentSearchColumnsNames.clientMemberCode],
    studentSearchColumns[studentSearchColumnsNames.tripList]
];

export const optionsFilterStudentOnBoardingGroup = [
    {
        key: 'onHold',
        name: 'report.onHold',
        quantity: 0,
        variant: 'contained',
        color: "primary",
        backgroundColor: COLORCONSTANTS.GREYOPTION1
    },
    {
        key: 'inProgress',
        name: 'report.inProgress',
        quantity: 0,
        variant: 'contained',
        color: "primary",
        backgroundColor: COLORCONSTANTS.LIGHTBLUEOPTION1
    },
    {
        key: 'active',
        name: 'report.active',
        quantity: 0,
        variant: 'contained',
        color: "primary",
        backgroundColor: COLORCONSTANTS.GREENOPTION1
    }
];

export const indexReportName = {
    StudentOnboardingReport: 0,
    NoShowReport: 1,
    EndYearReport: 2
};

export const reports = [
    {
        index: indexReportName.StudentOnboardingReport,
        key: 'StudentOnboardingReport',
        url: paths.StudentOnboardingReport.tabValue,
        value: 'report.studentOnBoarding',
        messages: {
            downloadErrorMessage: 'report.studentOnboardingReportNotFound',
            loadErrorMessage: 'report.studentOnboardingReportNotFound'

        }
    },
    {
        index: indexReportName.NoShowReport,
        key: 'NoShowReport',
        value: 'report.noShow',
        messages: {
            downloadErrorMessage: 'report.excelFileNotFound'
        }
    },
    {
        index: indexReportName.EndYearReport,
        key: 'EndYearReport',
        value: 'report.endYear',
        messages: {
            downloadErrorMessage: 'report.endYearDownloadError'
        }
    }
];

export const addressType = {
    homeAddress: "Home Address",
    differentAddress: "Different Address",
    sameAddressAsPickUp: "Same address as A.M. Pickup Location"
};

export const defaultOptions = {
    title: 'Undefined',
    titleColor: 'red',
    style: {
        borderBottom: `1px solid ${COLORCONSTANTS.EVERDRIVENGREY40}`,
        padding: '16px 24px'
    }
};

export const optionsTripGroup = {
    Today: 'Today',
    Future: 'Future',
    NoShow: 'NoShow',
    Finished: 'Finished'
};

export const tripsFilterStates = {
    All: {
        filterType: 'All',
        states: []
    },
    OnSite: {
        filterType: 'OnSite',
        states: [ Enum.TripState.OnSite, Enum.TripState.OnSiteAtDropoff ]
    },
    EnRoute: {
        filterType: 'EnRoute',
        states: [Enum.TripState.EnRoute]
    },
    NoShow: {
        filterType: 'NoShow',
        states: [Enum.TripState.NoShow]
    },
    OnBoard: {
        filterType: 'OnBoard',
        states: [Enum.TripState.OnBoard]
    },
    Scheduled: {
        filterType: 'Scheduled',
        states: [Enum.TripState.Scheduled]
    },
    PendingCancellation: {
        filterType: 'PendingCancellation',
        states: [Enum.TripState.PendingCancellation]
    },
    Completed: {
        filterType: 'Completed',
        states: [Enum.TripState.Completed]
    },
    Canceled: {
        filterType: 'Canceled',
        states: [Enum.TripState.Canceled]
    }
};

export const optionsTripGroupDTT = [
    optionsTripGroup.Today,
    optionsTripGroup.Future,
    optionsTripGroup.NoShow,
    optionsTripGroup.Finished
];

export const audienceType = {
    school: 'School',
    district: 'District',
    other: 'Other'
};

export const fileError = {
    fileTooLarge: 'file-too-large',
    fileNameHasNonAsciiCharacter: 'name-has-non-ascii-characters'
};

export const museoSansFont = 'MuseoSans300, MuseoSans500, MuseoSans700, "Helvetica Neue", Roboto, Arial, sans-serif';
export const museoSansFont300 = 'MuseoSans300, MuseoSans100, MuseoSans700, "Helvetica Neue", Roboto, Arial, sans-serif';
export const museoSansFont500 = 'MuseoSans500, MuseoSans300, MuseoSans700, "Helvetica Neue", Roboto, Arial, sans-serif';
export const museoSansFont700 = 'MuseoSans700, MuseoSans300, MuseoSans500, "Helvetica Neue", Roboto, Arial, sans-serif';
export const museoFont = 'Museo100, Museo300, Museo700, "Helvetica Neue", Roboto, Arial, sans-serif';
export const museoFont300 = 'Museo300, Museo100, Museo700, "Helvetica Neue", Roboto, Arial, sans-serif';
export const museoFont700 = 'Museo700, Museo100, Museo500, "Helvetica Neue", Roboto, Arial, sans-serif';
export const inProgressStatus = [
    Enum.TripState.OnBoard,
    Enum.TripState.OnSite,
    Enum.TripState.EnRoute,
    Enum.TripState.OnSiteAtDropoff
];

export const ActiveTripStatus = [
    Enum.TripState.Active,
    Enum.TripState.Accepted,
    ...inProgressStatus
];

export const ScheduledTripStatus = [
    Enum.TripState.Scheduled,
    Enum.TripState.WillCall
];

export const CompletedTripStatus = [
    Enum.TripState.Completed,
    Enum.TripState.Canceled
];

export const statusWithoutDriverDetails = [
    Enum.TripState.Canceled,
    Enum.TripState.Scheduled,
    Enum.TripState.Pending,
    Enum.TripState.Active
];

export const StatePostalAbv = {
    alabama: 'AL',
    alaska: 'AK',
    arizona: 'AZ',
    arkansas: 'AR',
    california: 'CA',
    colorado: 'CO',
    connecticut: 'CT',
    delaware: 'DE',
    florida: 'FL',
    georgia: 'GA',
    hawaii: 'HI',
    idaho: 'ID',
    illinois: 'IL',
    indiana: 'IN',
    iowa: 'IA',
    kansas:	'KS',
    kentucky: 'KY',
    louisiana: 'LA',
    maine: 'ME',
    maryland: 'MD',
    massachusetts: 'MA',
    michigan: 'MI',
    minnesota: 'MN',
    mississippi: 'MS',
    missouri: 'MO',
    montana: 'MT',
    nebraska: 'NE',
    nevada: 'NV',
    newHampshire: 'NH',
    newJersey: 'NJ',
    newMexico: 'NM',
    newYork: 'NY',
    northCarolina: 'NC',
    northDakota: 'ND',
    ohio: 'OH',
    oklahoma: 'OK',
    oregon: 'OR',
    pennsylvania: 'PA',
    rhodeIsland: 'RI',
    southCarolina: 'SC',
    southDakota: 'SD',
    tennessee: 'TN',
    texas: 'TX',
    utah: 'UT',
    vermont: 'VT',
    virginia: 'VA',
    washington: 'WA',
    westVirginia: 'WV',
    wisconsin: 'WI',
    wyoming: 'WY',
    districtOfColumbia: 'DC'
};