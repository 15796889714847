import React from 'react';
import { TableRow } from '@mui/material';
import { node, string } from 'prop-types';

function StyledTableRow ({
    children,
    extraCssClass,
    testId
}) {
    return (
        <TableRow className={extraCssClass} data-testid={testId}>
            {children}
        </TableRow>
    );
}

StyledTableRow.defaultProps = {
    children: null,
    extraCssClass: null,
    testId: ''
};

StyledTableRow.propTypes = {
    children: node,
    extraCssClass: string,
    testId: string
};

export default StyledTableRow;