import React from "react";
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const Wrapper = styled(Paper)(() => ({
    position: 'relative',
    borderRadius: 8,
    marginTop: 15,
    width: 900
}));

function StyledWrapper({ children }) {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
}

export default StyledWrapper;