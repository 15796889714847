import { connect } from 'react-redux';
import { draftListColumns } from '../../../util/EnumHelper';
import DraftListTable from '../../../components/draftList/DraftListTable';

const mapStateToProps = state => {
    const { DraftListReducers } = state;

    if (DraftListReducers) {
        return {
            resultList: DraftListReducers.drafts,
            headerCells: draftListColumns,
            columns: DraftListReducers.columns,

            showPagination: true,
            isLoading: DraftListReducers.isSearching
        };
    }

    return {};
};

const DraftListTableContainer = connect(
    mapStateToProps, {}
)(DraftListTable);

export default DraftListTableContainer;