import { connect } from 'react-redux';
import withTheme from '@mui/styles/withTheme';
import UserBody from 'components/user/UserBody/UserBody';
import { closeStyledSnackBar } from 'redux/workers/styledSnackbarWrapperWorker';

const mapStateToProps = state => {
  	const { UserReducers } = state;

    if (UserReducers) {
        return {
            userList: UserReducers.filteredUsers,
            isModalMobile: UserReducers.isModalMobile,
            isLoadingUser: UserReducers.isLoadingUser
        };
    }

    return {};
};

const UserBodyContainer = withTheme(
    connect(
        mapStateToProps, {
            closeStyledSnackBar
        }
    )(UserBody)
);

export default UserBodyContainer;
