import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ErrorPage from 'screens/Error/ErrorPage';

import {
    updatePath
} from 'redux/workers/pathWorker';

const mapStateToProps = (state) => {
    const { ErrorPageReducer } = state;

    return {
        title: ErrorPageReducer.title,
        content: ErrorPageReducer.content
    };
};

const mapDispatchToProps = {
    updatePath
};

const ErrorPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ErrorPage));

export default ErrorPageContainer;