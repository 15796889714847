import React, { useState } from 'react';
import Hidden from '@mui/material/Hidden';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import {
    arrayOf,
    bool,
    func,
    node,
    object,
    string
} from 'prop-types';

import commonUseStyles, { commonStyles } from '../../../styles/CommonStyles';
import StyledButton from '../StyledButton/StyledButton';

const HeaderSection = styled('div')(({ theme }) => ({
    ...commonUseStyles.header,
    height:'fit-content',
    position: 'relative',
    borderRadius: '6px 6px 0px 0px',
    background: theme.palette.everDrivenLightBlue20,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '12px',
    ...commonUseStyles.defaultResponsive,
    justifyContent: 'space-between',
    '& > h5': {
        fontWeight: 'bold !important',
        marginTop: 0,
        marginBottom: 0
    }
}));

const GroupDeclaration = styled('h5')(() => ({
    minWidth: 100
}));

const ShowHideButton = styled(StyledButton)(({ theme }) => ({
    ...commonStyles(theme).groupCardFilterButton,
    boxShadow: 'none'
}));

const GroupByVehicleButton = styled(StyledButton)(({ theme }) => ({
    ...commonStyles(theme).groupCardFilterButton,
    boxShadow: 'none',
    padding: '2px 8px'
}));

const GroupButtonsBox = styled('div')((props) => {
    const isTrueSet = /^true$/i.test(props?.hasmenufilter);
    const groupButtons = {
        textAlign: 'right',
        marginLeft: 'auto'
    };

    return !isTrueSet && groupButtons;
});

function StyledCardGroupHeader({
    children,
    elements,
    grouped,
    groupLabel,
    groupTitle,
    hasMenuFilter,
    showGroupButton,
    groupType,
    onClickDisplayButton,
    onClickGroupButton }) {
    const [ displayButton, setDisplayButton ] = useState(true);

    const onShowHideClick = () => {
        setDisplayButton(!displayButton);
        onClickDisplayButton();
    };

    return (
        <HeaderSection className="header">
            <GroupDeclaration id="statusFilter">
                {`${groupTitle.toUpperCase()} (${elements.length})`}
            </GroupDeclaration>
            { children }
            { showGroupButton &&
                <Hidden mdDown>
				    <GroupButtonsBox hasmenufilter={hasMenuFilter}>
				        <GroupByVehicleButton
                            id="groupByVehicle"
				            className={ grouped ? 'active' : 'inactive' }
                            testId={`groupCardsButton_${groupType}`}
				            type="button"
				            variant="text"
				            onClick={onClickGroupButton}
				        >
				            {groupLabel}
				        </GroupByVehicleButton>
				    </GroupButtonsBox>
                </Hidden>
            }
            <ShowHideButton
                id="UpDownArrow"
                testId={
                    `showHideButton_${groupType}`
                }
                variant="text"
                onClick={() => onShowHideClick()}
            >
                {displayButton ? <UpIcon /> : <DownIcon />}
            </ShowHideButton>
        </HeaderSection>
    );
}

StyledCardGroupHeader.defaultProps = {
    grouped: false,
    groupLabel: null,
    hasMenuFilter: false,
    showGroupButton: false,
    onClickDisplayButton: () => null,
    onClickGroupButton: () => null
};

/* eslint-disable react/forbid-prop-types */
StyledCardGroupHeader.propTypes = {
    children: node.isRequired,
    elements: arrayOf(object).isRequired,
    grouped: bool,
    groupLabel: string,
    groupTitle: string.isRequired,
    hasMenuFilter: bool,
    showGroupButton: bool,
    groupType: string.isRequired,
    onClickDisplayButton: func,
    onClickGroupButton: func
};
/* eslint-enable react/forbid-prop-types */

export default StyledCardGroupHeader;
