/* eslint-disable max-lines */
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Isvg from "react-inlinesvg";
import { styled } from '@mui/material/styles';

import CommonStyles from 'styles/CommonStyles';
import * as dateUtils from 'util/dateUtils';
import { localize } from "util/Localizer";
import breakpointsNames from 'styles/ResponsiveConstants';
import { Enum, optionsTripGroup, statusWithoutDriverDetails } from 'util/EnumHelper';
import { tripPrimaryColor, tripSecondaryColor } from 'util/colorUtil';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import TripTimeDetail from 'components/trip/TripTimeDetail';
import Auth from 'auth/Auth';
import withRouter from 'util/customHooks/withRouter';

const SectionHeader = styled('div', {
    shouldForwardProp: prop => prop !== 'primaryColor'
})(({primaryColor}) => ({
    color: primaryColor,
    alignItems: 'center',
    fontSize: '14px',
    '&> :first-of-type > path': {
        fill: primaryColor
    },
    '&> *': {
        marginTop: '16px',
        marginRight: '8px'
    },
    display: 'flex',
    justifyContent: 'space-between'
}));

const StatusIcon = styled(Isvg, {
    shouldForwardProp: prop => prop !== 'primaryColor'
})(({primaryColor}) => ({
    margin: '12px',
    flexShrink: '1',
    minWidth: 24,
    '&> path': {
        fill: primaryColor
    }
}));

const StatusGroup = styled('div', {
    shouldForwardProp: prop => prop !== 'isMapCard'
})(({isMapCard}) => {
    const styles = {
        display: 'flex',
        justifyContent: 'space-between'
    };

    if (isMapCard) styles.height = 152;

    return styles;
});

const StatusDetail = styled('div')(() => ({
    flexGrow: '1',
    overflow: 'hidden'
}));

const AddressRow = styled('p', {
    shouldForwardProp: prop => prop !== 'isMapCard' && prop !== 'isBold'
})(({isMapCard, isBold}) => {
    let styles = {};

    if (isMapCard) {
        styles = {
            ...styles,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '90%'
        };
    }
    if (isBold) {
        styles.fontWeight = 'bold';
    }

    return styles;
});

const DriverDetails = styled('div', {
    shouldForwardProp: prop => prop !== 'secondaryColor' && prop !== 'isMapCard'
})(({secondaryColor, isMapCard, theme}) => {
    let styles = {
        background: secondaryColor,
        padding: '12px'
    };

    if (isMapCard) {
        styles = {
            ...styles,
            height: 'calc(100vh - 670px)',
            minHeight: 215,
            [theme.breakpoints.down(breakpointsNames.sm)]: {
                minHeight: 195
            }
        };
    }
    
    return styles;
});

const Split = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

const DetailBreakdown = styled('div')(() => ({
    wordBreak: 'break-word',
    width: '50%',
    '&> :nth-of-type(odd)': {
        fontSize: '12px',
        lineHeight: '12px',
        marginBottom: '0'
    },
    margin: '4px',
    '&> :nth-of-type(even)': {
        fontWeight: 'bold',
        margin: '4px 0'
    }
}));

const CtaBtn = styled(StyledButton, {
    shouldForwardProp: prop => prop !== 'isCancel'
})(({theme, isCancel}) => {
    const styles = {
        ...CommonStyles.roundedButton,
        width: '80%',
        margin: '12px 10%',
        background: isCancel ? `${theme.palette.everDrivenFuchsia} !important` : theme.palette.everDrivenLightBlue80
    };

    return styles;
});

function TripDetail({
    goToTrackTrip,
    isCancelDisabled,
    isExtended,
    isMapCard,
    isPendingCancel,
    trip,
    tripType,
    setShowDeleteConfirm
}) {
    const { DriverInfo, PickUpLocation, DropOffLocation, EtaTLT, EtaToPickUpSeconds, EtaToDropOffSeconds } = trip;
    const { DriverName, DriverGender, VehicleMake, VehicleModel, VehicleColor, VehicleLicense } = DriverInfo || {};
    const {
        Name: PUName,
        Address1: PUAddress1,
        Address2: PUAddress2, City: PUCity,
        State: PUState,
        Zip: PUZip } = PickUpLocation;
    const {
        Name: DOName,
        Address1: DOAddress1,
        Address2: DOAddress2,
        City: DOCity,
        State: DOState,
        Zip: DOZip } = DropOffLocation;

    const allowTrack = () => [
        Enum.TripState.EnRoute,
        Enum.TripState.OnSite,
        Enum.TripState.OnSiteAtDropoff,
        Enum.TripState.OnBoard
    ].includes(Enum.TripState[trip.status]);

    const allowCancel = () => [
        Enum.TripState.Accepted,
        Enum.TripState.Scheduled
    ].includes(Enum.TripState[trip.status]);

    const onCancelTrip = () => {
        setShowDeleteConfirm(true);
    };

    return (
        <>
            {isExtended && (
                <>
                    <StatusGroup isMapCard={isMapCard}>
                        <StatusIcon
                            primaryColor={tripPrimaryColor(trip)}
                            src={trip.PickUpLocation.Name ? "/img/locationCity.svg": "/img/locationOn.svg"}
                        />
                        <StatusDetail>
                            <SectionHeader primaryColor={tripPrimaryColor(trip)}>
                                <span>{localize("tripList.pickUp")}</span>
                                {tripType === optionsTripGroup.Today &&
                                    EtaTLT && EtaToPickUpSeconds &&
                                    (<span>ETA {dateUtils.timeFormat(EtaTLT)}</span>)
                                }
                            </SectionHeader>
                            <div className="qm_block">
                                <AddressRow isMapCard={isMapCard} isBold>{PUName}</AddressRow>
                                <AddressRow isMapCard={isMapCard}>{PUAddress1}</AddressRow>
                                {PUAddress2 && (
                                    <AddressRow isMapCard={isMapCard}>{PUAddress2}</AddressRow>
                                )}
                                <AddressRow isMapCard={isMapCard}>{`${PUCity}, ${PUState} ${PUZip}`}</AddressRow>
                            </div>
                        </StatusDetail>
                    </StatusGroup>
                    <StatusGroup isMapCard={isMapCard}>
                        <StatusIcon
                            primaryColor={tripPrimaryColor(trip)}
                            src={trip.DropOffLocation.Name ? "/img/locationCity.svg": "/img/locationOn.svg"}
                        />
                        <StatusDetail>
                            <SectionHeader primaryColor={tripPrimaryColor(trip)}>
                                <span>{localize("tripList.dropOff")}</span>
                                {tripType === optionsTripGroup.Today &&
						            EtaTLT && EtaToDropOffSeconds && !EtaToPickUpSeconds &&

							        (<span>ETA {dateUtils.timeFormat(EtaTLT)}</span>)
                                }
                                {tripType === optionsTripGroup.Today &&
							        EtaTLT && EtaToDropOffSeconds && EtaToPickUpSeconds &&

						            (<span>ETA {dateUtils.timeFormatDropOffEta(EtaTLT,
						            EtaToDropOffSeconds - EtaToPickUpSeconds)}</span>)
                                }
                            </SectionHeader>
                            <div className="qm_block">
                                <AddressRow isMapCard={isMapCard} isBold>{DOName}</AddressRow>
                                <AddressRow isMapCard={isMapCard}>{DOAddress1}</AddressRow>
                                {DOAddress2 && (
                                    <AddressRow isMapCard={isMapCard}>{DOAddress2}</AddressRow>
                                )}
                                <AddressRow isMapCard={isMapCard}>{`${DOCity}, ${DOState} ${DOZip}`}</AddressRow>
                            </div>
                        </StatusDetail>
                    </StatusGroup>
                </>
            )}

            <TripTimeDetail
                isExtended={isExtended}
                tripType={tripType}
                trip={trip}
                isMapCard={isMapCard}
            />
            {isExtended && !statusWithoutDriverDetails.includes(trip.State) && (
                <DriverDetails
                    secondaryColor={tripSecondaryColor(trip.State)}
                    isMapCard={isMapCard}
                    data-testid="driverDetails"
                >
                    <Split>
                        <DetailBreakdown>
                            <p>{localize("tripList.driverName")}</p>
                            <p data-testid="driverName" className="qm_block">{DriverName}</p>
                            <p>{localize("tripList.driverGender")}</p>
                            <p data-testid="driverGender">{DriverGender}</p>
                        </DetailBreakdown>
                        <DetailBreakdown>
                            <p>{localize("tripList.vehicle")}</p>
                            <p data-testid="vehicleMakeModel">{VehicleMake} {VehicleModel}</p>
                            <p>{localize("tripList.licensePlate")}</p>
                            <p data-testid="vehicleLicense" className="qm_block">{VehicleLicense}</p>
                            <p>{localize("tripList.color")}</p>
                            <p data-testid="vehicleColor">{VehicleColor}</p>
                        </DetailBreakdown>
                    </Split>
                </DriverDetails>
            )}
            {isExtended && !isMapCard && allowTrack() && (
                <CtaBtn
                    testId={`trackTripButton_${trip.TripGuid}_${trip.MemberGUID}`}
                    onClick={() => goToTrackTrip(trip)}
                >
                    {localize("tripList.trackTrip")}
                </CtaBtn>
            )}
            {isExtended &&
			!isMapCard &&
			allowCancel() &&
			!isPendingCancel &&
			!Auth.getConfig().disableCancel &&
			dateUtils.calcUtcHoursAgo(trip.DueDateTimeUTC) > 0 && (
                <CtaBtn
                    isCancel
                    isDisabled={isCancelDisabled}
                    testId={`cancelTripButton_${trip.TripGuid}_${trip.MemberGUID}`}
                    onClick={() => { onCancelTrip(); }}
                >
                    {localize("tripList.cancelTrip")}
                </CtaBtn>

            )}
        </>
    );
}

TripDetail.propTypes = {
    trip: PropTypes.shape({
        MemberPII: PropTypes.shape({ FirstName: PropTypes.string }),
        status: PropTypes.string,
        DueDateTimeUTC: PropTypes.string,
        MemberGUID: PropTypes.string,
        TripGuid: PropTypes.string,
        State: PropTypes.number
    }).isRequired,
    isExtended: PropTypes.bool.isRequired,
    tripType: PropTypes.string.isRequired,
    setShowDeleteConfirm: PropTypes.func.isRequired,
    isPendingCancel: PropTypes.bool.isRequired
};

export default withRouter(injectIntl(TripDetail));
