import {legacy_createStore as createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router';
import rootReducer from './reducers/index';
import { authMiddleware } from './workers/pathWorker';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(history) {
    const store = createStore(
        rootReducer(history),
        {},
        composeEnhancers(
            applyMiddleware(
                createRouterMiddleware(history), // for dispatching history actions
                thunkMiddleware,
                authMiddleware
            )
        )
    );
    
    return store;
}
