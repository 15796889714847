import {
    CLEAR_CALENDARUPLOAD_REJECTED_FILES,
    SET_CALENDARUPLOAD_KEY_DROPZONE,
    SET_CALENDARUPLOAD_MODEL,
    SET_CALENDARUPLOAD_NEXT_PATH,
    SET_CALENDARUPLOAD_CLEAR_OPEN,
    SET_CALENDARUPLOAD_LEAVE_NO_SAVING_OPEN,
    SET_CALENDARUPLOAD_ATTACHMENTS,
    SET_CALENDARUPLOAD_NOTES,
    SET_CALENDARUPLOAD_LOADING,
    SET_CALENDARUPLOAD_LIST,
    SET_CALENDARUPLOAD_DELETE_ATTACHMENT_OPEN,
    SET_CALENDARUPLOAD_DELETE_FILE_NAME,
    SET_CALENDARUPLOAD_NOTES_OPEN,
    SET_CALENDARUPLOAD_SELECTED_NOTES,
    SET_CALENDARUPLOAD_REJECTED_FILES
} from "../actions/calendarUploadActions";

const initialState = {
    calendars: [],
    calendarAttachments: [],
    calendarUploadedList: {
        pageSize: 0,
        pageNumber: 1,
        totalRows: 0,
        totalPages: 1,
        results: []
    },
    notes: '',
    keyDropZone: Math.random().toString(),
    nextPath: '',
    isClearOpen: false,
    isLeavingNoSavingOpen: false,
    isLoading: false,
    isDeleteAttachmentOpen: false,
    deleteFileName: '',
    isNotesModalOpen: false,
    selectedNotes: '',
    rejectedCalendars: []
};

/* eslint-disable-next-line default-param-last */
const calendarUploadReducers = (state = initialState, action) => {
    switch (action.type) {
    case SET_CALENDARUPLOAD_MODEL:
        return {
            ...state,
            calendars: action.model
        };
    case SET_CALENDARUPLOAD_ATTACHMENTS:
        return {
            ...state,
            calendarAttachments: action.attachments
        };
    case SET_CALENDARUPLOAD_KEY_DROPZONE:
        return {
            ...state,
            keyDropZone: action.keyDropZone
        };
    case SET_CALENDARUPLOAD_NEXT_PATH:
        return {
            ...state,
            nextPath: action.nextPath
        };
    case SET_CALENDARUPLOAD_CLEAR_OPEN:
        return {
            ...state,
            isClearOpen: action.status
        };
    case SET_CALENDARUPLOAD_LEAVE_NO_SAVING_OPEN:
        return {
            ...state,
            isLeavingNoSavingOpen: action.status
        };
    case SET_CALENDARUPLOAD_NOTES:
        return {
            ...state,
            notes: action.notes
        };
    case SET_CALENDARUPLOAD_LOADING:
        return {
            ...state,
            isLoading: action.isLoading
        };
    case SET_CALENDARUPLOAD_LIST:
        return {
            ...state,
            calendarUploadedList: {
                ...state.calendarUploadedList,
                ...action.calendarUploadedList
            }
        };
    case SET_CALENDARUPLOAD_DELETE_ATTACHMENT_OPEN:
        return {
            ...state,
            isDeleteAttachmentOpen: action.status
        };
    case SET_CALENDARUPLOAD_DELETE_FILE_NAME:
        return {
            ...state,
            deleteFileName: action.deleteFileName
        };
    case SET_CALENDARUPLOAD_NOTES_OPEN:
        return {
            ...state,
            isNotesModalOpen: action.status
        };
    case SET_CALENDARUPLOAD_SELECTED_NOTES:
        return {
            ...state,
            selectedNotes: action.selectedNotes
        };
    case SET_CALENDARUPLOAD_REJECTED_FILES:
        return {
            ...state,
            rejectedCalendars: [ ...action.rejectedCalendars, ...state.rejectedCalendars ]
        };
    case CLEAR_CALENDARUPLOAD_REJECTED_FILES:
        return {
            ...state,
            rejectedCalendars: []
        };
    default:
        return state;
    }
};

export default calendarUploadReducers;