import React from 'react';
import { styled } from '@mui/material/styles';
import commonUseStyles from '../../styles/CommonStyles';

const Separator = styled('div')(() => ({
    ...commonUseStyles.gridSeparator,
    height: 38
}));

function SeparatorContent() {
    return (
        <Separator> </Separator>
    );
}

export default SeparatorContent;