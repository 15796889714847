import { connect } from 'react-redux';
import withTheme from '@mui/styles/withTheme';
import UserModal from '../../../components/user/UserModal';

import {
    setUserObject,
    setUserResponse,
    setSaveButtonDisabled
} from '../../actions/userActions';

import { handleClosePopUp, onSubmit } from '../../workers/userWorker';

const mapStateToProps = state => {
    const { UserReducers, FacilityReducers } = state;

    if (UserReducers) {
        return {
            isUserPopUpOpen: UserReducers.isUserPopUpOpen,
            errorMessage: UserReducers.userResponse,
            facilityList: FacilityReducers.facilities,
            user: UserReducers.user,
            isLoadingUserUpdate: UserReducers.isLoadingUserUpdate,
            saveButtonDisabled: UserReducers.saveButtonDisabled
        };
    }

    return {};
};

const UserModalContainer = withTheme(
    connect(
        mapStateToProps, {
            handleClosePopUp,
            onSubmit,
            setUserObject,
            setUserResponse,
            setSaveButtonDisabled
        }
    )(UserModal)
);

export default UserModalContainer;
