import React from 'react';
import { Link, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ListIcon from '@mui/icons-material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import { localize } from 'util/Localizer';
import { monthDayYearFormat } from 'util/dateUtils';
import breakpointsNames from 'styles/ResponsiveConstants';
import StyledTable from 'components/Common/StyledTable/StyledTable';
import StyledTableRow from 'components/Common/StyledTableRow/StyledTableRow';
import StyledTableCell from 'components/Common/StyledTableCell/StyledTableCell';
import StyledTableBody from 'components/Common/StyledTableBody/StyledTableBody';

const LinkButton = styled(StyledButton)(({ theme }) => ({
    color: theme.palette.everDrivenLightBlue,
    fontWeight: 500,
    padding: '0 5px',
    marginLeft: 10,
    minWidth: 0,
    textTransform: 'none',
    fontSize: 12,
    '& > span > svg': {
        fontSize: '14px !important'
    },
    '&.Mui-disabled': {
        color: theme.palette.white
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        fontSize: 10
    }
}));

const DeleteStartIcon = styled(DeleteIcon)(({ theme }) => ({
    color: theme.palette.everDrivenDarkGrey
}));

const ListStartIcon = styled(ListIcon)(({ theme }) => ({
    color: theme.palette.everDrivenDarkGrey
}));

const CalendarAudienceColumn = styled('div')(({ theme }) => ({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left',
    width: 300,
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        width: 160
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: 200
    },
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        width: 160
    }
}));

const FileNameLink = styled(Link)(({ theme }) => ({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left',
    width: 350,
    underline: 'hover',
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        width: 160
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        width: 200,
        fontSize: 10
    },
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        width: 160
    }
}));

const useStyles = makeStyles((theme) => ({
    fileSizeCell: {
        width: 100,
        [theme.breakpoints.down(breakpointsNames.md)]: {
            textAlign: 'right'
        }
    },
    dateSubmittedCell: {
        width: 120,
        [theme.breakpoints.down(breakpointsNames.md)]: {
            textAlign: 'right'
        }
    },
    buttonColumn: {
        width: 200
    }
}));

function CalendarUploadedRow({
    row,
    onDownloadCalendarAttachment,
    onOpenDeleteAttachmentModal,
    onOpenNotesModal
}) {
    const classes = useStyles();

    const onShowNotes = () => {
        onOpenNotesModal(row.notes);
    };

    const onDeleteCalendar = () => {
        onOpenDeleteAttachmentModal(row.fileName);
    };

    const renderNormalView = () => (
        <StyledTableRow>
            <StyledTableCell scope="row" align="left">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <FileNameLink
                    component="button"
                    onClick={() => onDownloadCalendarAttachment(row.fileName)}
                >
                    {row.originalFileName}
                </FileNameLink>
            </StyledTableCell>
            <StyledTableCell extraCssClass={classes.dateSubmittedCell} scope="row">
                {monthDayYearFormat(row.dateSubmitted)}
            </StyledTableCell>
            <StyledTableCell extraCssClass={classes.fileSizeCell} scope="row">
                {`${(row.fileSize / 1024).toFixed(2)} KB`}
            </StyledTableCell>
            <StyledTableCell scope="row">
                <CalendarAudienceColumn>
                    {row.description}
                </CalendarAudienceColumn>
            </StyledTableCell>
            <StyledTableCell extraCssClass={classes.buttonColumn} scope="row" align="right">
                <LinkButton
                    color="inherit"
                    disabled={!row.notes.length > 0}
                    startIcon={<ListIcon fontSize="inherit" />}
                    testId={`showCalendarUploadNotes_${row.fileName}`}
                    type="button"
                    variant="text"
                    onClick={onShowNotes}
                >
                    {localize('calendarUpload.notes')}
                </LinkButton>
                <LinkButton
                    color="inherit"
                    startIcon={<DeleteStartIcon fontSize="inherit" />}
                    testId={`deleteCalendarUploaded_${row.fileName}`}
                    type="button"
                    variant="text"
                    onClick={onDeleteCalendar}
                >
                    {localize('calendarUpload.removeFile')}
                </LinkButton>
            </StyledTableCell>
        </StyledTableRow>
    );

    const renderMobileView = () => (
        <StyledTable isMobileView>
            <StyledTableBody>
                <StyledTableRow>
                    <StyledTableCell>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <FileNameLink
                            component="button"
                            onClick={() => onDownloadCalendarAttachment(row.fileName)}
                        >
                            {row.originalFileName}
                        </FileNameLink>
                    </StyledTableCell>
                    <StyledTableCell>
                        {monthDayYearFormat(row.dateSubmitted)}
                    </StyledTableCell>
                    <StyledTableCell>
                        <LinkButton
                            color="inherit"
                            startIcon={<ListStartIcon fontSize="inherit" />}
                            testId={`showMobileCalendarUploadNotes_${row.fileName}`}
                            type="button"
                            variant="text"
                            disabled={!row.notes.length > 0}
                            onClick={onShowNotes}
                        >
                            {localize('calendarUpload.notes')}
                        </LinkButton>
                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell>
                        <CalendarAudienceColumn>
                            {row.description}
                        </CalendarAudienceColumn>
                    </StyledTableCell>
                    <StyledTableCell>
                        {`${(row.fileSize / 1024).toFixed(2)} KB`}
                    </StyledTableCell>
                    <StyledTableCell>
                        <LinkButton
                            color="inherit"
                            startIcon={<DeleteStartIcon fontSize="inherit" />}
                            testId={`deleteMobileCalendarUpload_${row.fileName}`}
                            type="button"
                            variant="text"
                            onClick={onDeleteCalendar}
                        >
                            {localize('calendarUpload.removeFile')}
                        </LinkButton>
                    </StyledTableCell>
                </StyledTableRow>
            </StyledTableBody>
        </StyledTable>
    );

    return (
        <>
            <Hidden mdDown>
                {renderNormalView()}
            </Hidden>
            <Hidden mdUp>
                {renderMobileView()}
            </Hidden>
        </>
    );
}

export default CalendarUploadedRow;