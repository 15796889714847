import React, { useState } from 'react';
import {
    FormGroup,
    FormControl,
    FormControlLabel,
    RadioGroup
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { localize } from 'util/Localizer';
import { Enum } from 'util/EnumHelper';
import commonUseStyles from 'styles/CommonStyles';
import LabelDescription from 'controls/Common/LabelDescription';
import StyledCheckbox from '../Common/StyledCheckbox/StyledCheckbox';
import StyledRadio from '../Common/StyledRadio/StyledRadio';

const ADMIN = 'administrator';
const BILLING = 'billing';
const PROGRAM_MANAGEMENT = 'programManagement';
const DTT = 'districtTripTracker';
const TRFRECIPIENT = 'isAdditionalTRFRecipient';

const UserAccessGroupBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column'
}));

const TitleSpan = styled('span')(() => ({
    ...commonUseStyles.spanTitle
}));

const FormControlFullWidth = styled(FormControl)(() => ({
    fullWidth: true
}));

const FormControlLabelCheck = styled(FormControlLabel)(() => ({
    fullWidth: true,
    marginBottom: -10
}));

const RadioLabel = styled(FormControlLabel)(() => ({
    marginBottom: 0,
    marginRight:0,
    height: 25,
    fontSize: 14,
    fullWidth: true
}));

const RoleDescriptionLabel = styled(LabelDescription)(({ theme }) => ({
    fontSize: 11,
    marginTop: 0,
    paddingLeft: 25,
    fullWidth: true,
    lineHeight: "11px",
    color: theme.palette.everDrivenGrey60
}));

const schoolOptions = {
    AllSchools: 'All',
    SomeSchool: 'Some'
};

function UserAccess({
    user,
    onChangeInput,
    setShowSchool
}) {
    const contextUser = { ...user };

    const {
        isProfessionalAgent: profesionalAgent,
        canAccessTripTracking: districtTripTracker,
        isAdministrator: administrator,
        canAccessProgramManagement: programManagement,
        isAdditionalTRFRecipient
    } = contextUser;

    const initialTypeSchool = () =>(
        (!districtTripTracker
        || (districtTripTracker && profesionalAgent === Enum.TRUE))
            ? schoolOptions.AllSchools
            : schoolOptions.SomeSchool
    );

    const [ typeSchool, setTypeSchool ] = useState(initialTypeSchool());

    const handleUserAccessChange = event => {
        switch (event.target.name) {
        case ADMIN:
            contextUser.isAdministrator = event.target.checked;
            contextUser.canAccessBilling = event.target.checked;
            contextUser.canAccessProgramManagement = event.target.checked;
            contextUser.canAccessTripTracking = event.target.checked;
            if (event.target.checked) {
                setTypeSchool(schoolOptions.AllSchools);
                setShowSchool(false);
                contextUser.isProfessionalAgent = Enum.TRUE;
            }
            if (!event.target.checked) {
                contextUser.isAdditionalTRFRecipient = event.target.checked;
            }
            break;
        case BILLING:
            contextUser.canAccessBilling = event.target.checked;
            break;
        case PROGRAM_MANAGEMENT:
            contextUser.canAccessProgramManagement = event.target.checked;

            if (!event.target.checked) {
                contextUser.isAdditionalTRFRecipient = event.target.checked;
            }
            break;
        case DTT:
            contextUser.canAccessTripTracking = event.target.checked;
            setTypeSchool(schoolOptions.AllSchools);
            setShowSchool(false);
            if (!event.target.checked) {
                contextUser.isProfessionalAgent = Enum.FALSE;
            } else {
                contextUser.isProfessionalAgent = Enum.TRUE;
            }
            break;
        case TRFRECIPIENT:
            contextUser.isAdditionalTRFRecipient = event.target.checked;
            break;
        default:
            break;
        }

        contextUser.isDistrict = contextUser.canAccessTripTracking || contextUser.canAccessProgramManagement
            || contextUser.canAccessBilling || contextUser.isAdministrator;

        onChangeInput(contextUser);
    };

    const handleSchoolSelectionChange = event => {
        setTypeSchool(event.target.value);
        setShowSchool(event.target.value === schoolOptions.SomeSchool);

        if (event.target.value === schoolOptions.AllSchools) {
            contextUser.isProfessionalAgent = Enum.TRUE;
        } else {
            contextUser.isProfessionalAgent = Enum.FALSE;
        }

        onChangeInput(contextUser);
    };

    return (
        <UserAccessGroupBox>
            <TitleSpan>
                {localize('userForm.rolFeature')}
            </TitleSpan>
            <FormControlFullWidth
                component="fieldset"
                variant="standard"
            >
                <FormGroup>
                    <FormControlLabelCheck
                        control={
                            <StyledCheckbox
                                color="secondary"
                                isChecked={administrator}
                                name={ADMIN}
                                size="small"
                                onChange={handleUserAccessChange}
                                inputProps={{"data-testid": "admin-checkbox"}}
                            />
                        }
                        label={localize('userForm.administrator')}
                    />
                    <RoleDescriptionLabel
                        description={localize('userForm.RolAdministratorDescription')}
                    />
                    <FormControlLabelCheck
                        control={
                            <StyledCheckbox
                                color="secondary"
                                isChecked={programManagement}
                                isDisabled={administrator}
                                name={PROGRAM_MANAGEMENT}
                                size="small"
                                onChange={handleUserAccessChange}
                                inputProps={{"data-testid": "studentManagement-checkbox"}}
                            />
                        }
                        label={localize('userForm.programManagement')}
                    />
                    <RoleDescriptionLabel
                        description={localize('userForm.RolProgramManagement')}
                    />
                    <FormControlLabelCheck sx={{ marginLeft: "15px" }}
                        control={
                            <StyledCheckbox
                                color="secondary"
                                isChecked={isAdditionalTRFRecipient}
                                isDisabled={!programManagement}
                                name={TRFRECIPIENT}
                                size="small"
                                onChange={handleUserAccessChange}
                                inputProps={{"data-testid": "receiveTRFEmails-checkbox"}}
                            />
                        }
                        label={
                            <div
                                style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                                {localize('userForm.isAdditionalTRFRecipient')}
                            </div>
                        }
                    />
                    <RoleDescriptionLabel sx={{ paddingLeft: "52px" }}
                        description={localize('userForm.isAdditionalTRFDescription')}
                    />
                    <FormControlLabelCheck
                        control={
                            <StyledCheckbox
                                color="secondary"
                                isChecked={districtTripTracker}
                                isDisabled={administrator}
                                name={DTT}
                                size="small"
                                onChange={handleUserAccessChange}
                                inputProps={{"data-testid": "tripTracker-checkbox"}}
                            />
                        }
                        label={localize('userForm.districtTripTracker')}
                    />
                    <RoleDescriptionLabel
                        description={localize('userForm.RolDistrictTripTracker')}
                    />
                </FormGroup>
            </FormControlFullWidth>
            <FormControl sx={{ marginLeft: "25px", fullWidth: true }} variant="standard">
                <RadioGroup name="schoolSelectionType"
                    sx={{ paddingTop: "6px", fullWidth: true }}
                    value={typeSchool}
                    onChange={handleSchoolSelectionChange}
                >
                    <RadioLabel
                        value={schoolOptions.AllSchools}
                        control={
                            <StyledRadio
                            	color="secondary"
                                value={schoolOptions.AllSchools}
                                inputProps={{"data-testid": "allSchools-radio"}}
                            />
                        }
                        label={localize('userForm.allSchool')}
                        disabled={administrator || !districtTripTracker}
                    />
                    <RadioLabel
                        value={schoolOptions.SomeSchool}
                        control={
                            <StyledRadio
                                color="secondary"
                                value={schoolOptions.SomeSchool}
                                inputProps={{"data-testid": "someSchools-radio"}}
                            />
                        }
                        label={localize('userForm.someSchool')}
                        disabled={administrator || !districtTripTracker}
                    />
                </RadioGroup>
            </FormControl>
        </UserAccessGroupBox>
    );
}

export default UserAccess;
