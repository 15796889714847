import * as dateUtil from 'util/dateUtils';
import { Enum } from "./EnumHelper";
import { localize } from './Localizer';

export const showTripStatus = trip => {
    if(dateUtil.isTripLate(trip)) {
        return localize('tripList.statusLate');
    }

    if(trip?.IsEarly === true) {
        return localize('tripList.statusEarly');
    }
    
    switch (trip.State) {
    case Enum.TripState.Active:
        return localize('tripList.statusActive');
    case Enum.TripState.EnRoute:
        return localize('tripList.statusEnRoute');
    case Enum.TripState.OnSite:
        return localize('tripList.statusOnSite');
    case Enum.TripState.OnSiteAtDropoff:
        return localize('tripList.statusAtDropoff');
    case Enum.TripState.OnBoard:
        return localize('tripList.statusOnBoard');
    case Enum.TripState.Accepted:
        return localize('tripList.statusAccepted');
    case Enum.TripState.Scheduled:
        return localize('tripList.statusScheduled');
    case Enum.TripState.Canceled:
        return localize('tripList.statusCanceled');
    case Enum.TripState.WillCall:
        return localize('tripList.statusWillCall');
    case Enum.TripState.NoShow:
        return localize('tripList.statusNoShow');
    case Enum.TripState.Completed:
        return localize('tripList.statusCompleted');
    case Enum.TripState.PendingCancel:
        return localize('tripList.cancelPending');
    default:
        return '';
    }
};

export const getArrivesIn = (trip) => {
    let arrivesIn = null;

    const etaToScheduledDropOffMinutes =
        trip.ScheduledDropOffTLT ? dateUtil.calcMinutesFromNow(trip.ScheduledDropOffTLT) : null;
 
    const etaToDropOffMinutes =
        trip.EtaToDropOffSeconds ?
            Math.floor(trip.EtaToDropOffSeconds / 60) :
            etaToScheduledDropOffMinutes;

    switch(trip.State) {
    case Enum.TripState.EnRoute:
    case Enum.TripState.OnSite:
        if (dateUtil.isTripLate(trip)) {
            arrivesIn = etaToDropOffMinutes;
        } else {
            arrivesIn = etaToScheduledDropOffMinutes;
        }
        break;
    case Enum.TripState.OnBoard:
        arrivesIn = etaToDropOffMinutes;
        break;
    default:
        break;
    }
    
    return arrivesIn;
};

export const getPickUpDropOffText = (trip) => {
    let pickUp = null;
    let dropOff = null;
    
    switch(trip.State) {
    case Enum.TripState.EnRoute:
    case Enum.TripState.OnSite:
    case Enum.TripState.OnSiteAtDropoff:
        if (trip.State === Enum.TripState.EnRoute) {
            pickUp = trip.EtaToPickUpSeconds ?
                `${localize('tripDetails.eta')} ${dateUtil.timeFormatEta(trip.EtaToPickUpSeconds)}` :
                `${localize('tripDetails.scheduled')} ${dateUtil.timeFormat(trip.DueTimeTLT)}`;
        }

        if (dateUtil.isTripLate(trip)) {
            dropOff = trip.EtaToDropOffSeconds ?
                `${localize('tripDetails.eta')} ${dateUtil.timeFormatEta(trip.EtaToDropOffSeconds)}` :
                `${localize('tripDetails.scheduled')} ${dateUtil.timeFormat(trip.ScheduledDropOffTLT)}`;
        } else {
            dropOff = trip.ScheduledDropOffTLT ?
                `${localize('tripDetails.scheduled')} ${dateUtil.timeFormat(trip.ScheduledDropOffTLT)}` : null;
        }
        break;
    case Enum.TripState.OnBoard:
        pickUp = trip.PickupTLT ?
            `${localize('tripDetails.pickUp')} ${dateUtil.timeFormat(trip.PickupTLT)}` : null;
        dropOff = trip.EtaToDropOffSeconds ?
            `${localize('tripDetails.eta')} ${dateUtil.timeFormatEta(trip.EtaToDropOffSeconds)}` :
            `${localize('tripDetails.scheduled')} ${dateUtil.timeFormat(trip.ScheduledDropOffTLT)}`;
        break;
    default:
        break;
    }

    return ({
        pickUp,
        dropOff
    });
};