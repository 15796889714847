import React from 'react';
import { Grid, Paper, List, ListItem } from '@mui/material';
import DPApp from 'components/DPApp';
import { localize } from 'util/Localizer';
import colorConstants from "styles/ColorConstants";
import { paths, museoFont700, museoSansFont300 } from 'util/EnumHelper';
import { func } from 'prop-types';
import HowToHeader from './HowToHeader';
/* eslint-disable max-len */
const startingText = [
    `School districts can enable even safer handoffs at school by giving teachers and school staff access to EverDriven VIP. With this first-of-its-kind app, teachers and other school staff will receive student and driver ETAs for convenient and safe student handoffs.`,
    `To ensure the safety of every student, only District Portal admins may grant app access to teachers and school staff.`
];

const endingText = [`The new user will receive an email from us with instructions on how to get started with EverDriven VIP.`];

const items = [
    `Log in to District Portal.`,
    `Navigate to Users in the navigation bar.`,
    `Click the plus sign to add a new user. To ensure student safety, each school employee must be added individually, even when staff members share a mobile device to use the app.`,
    `Enter the new user’s information including first name, last name, and email address.`,
    `Under Mobile Access, select VIP App.`,
    `Select the school or schools where the new user meets students transported by EverDriven.`,
    `To finish, select Create and Send Invitation.`
];
/* eslint-enable max-len */

function HowToGrantAppAccess({ updatePath }) {
    return (
        <DPApp>
            <HowToHeader
                title={localize('howtograntappaccess.title')}
                buttonColor={colorConstants.EVERDRIVENLIGHTBLUE}
                textButton={`< ${localize('howtograntappaccess.back')}`}
                testId="back-to-resources"
                onClick={() => { updatePath(paths.Resources.tabValue); }}

            />
            <Paper data-testid="howToPaper"
                sx={{
                    maxWidth: 1128,
                    mx: 'auto',
                    mt: '40px',
                    pb: '32px',
                    borderRadius: '8px'
                }}>
                <Grid container spacing={2} sx={{ px: '32px' }}>
                    <Grid item xs={12} sx={{ fontFamily: museoFont700, fontSize: 24, mt: '20px' }}>
                        {localize('howtograntappaccess.subtitle')}
                    </Grid>
                    <Grid container spacing={4} columns={{ xs: 6, sm: 6, md: 12 }} sx={{
                        fontFamily: museoSansFont300,
                        fontSize: 16
                    }}>
                        <Grid item xs={6}>
                            <List>
                                {startingText.map(text => (
                                    <ListItem key={text}>{text}</ListItem>
                                ))}
                            </List>
                            <ol>
                                {items.map((item) => (

                                    <li key={item}>{item}</li>
                                ))}
                            </ol>
                            <List>
                                {endingText.map(text => (
                                    <ListItem key={text}>{text}</ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={6} sx={{ mt: '24px' }}>
                            <img
                                src="/img/Students-with-disabilities-in-front-of-school-with-teachers.png"
                                alt={localize('howtograntappaccess.imgAltText')}
                                width="100%"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </DPApp>
    );
}

HowToGrantAppAccess.defaultProps = {
    updatePath: () => { }
};

HowToGrantAppAccess.propTypes = {
    updatePath: func
};

export default HowToGrantAppAccess;
