import React from 'react';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';

const TextMaskPhone = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props;

    return (
        <IMaskInput
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
            mask="(#00) 000-0000"
            definitions={{
                '#': /[1-9]/
            }}
            inputRef={ref}
            onChange={onChange}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

TextMaskPhone.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default TextMaskPhone;
