import React from "react";
import { number } from "prop-types";
import StyledTabPanel from "components/Common/StyledTabPanel/StyledTabPanel";
import { DttTabOptions } from "data/models/tabModel/DttTabModel";
import DttStudentContainer from "redux/containers/districtTripTracker/DttStudentContainer";
import DttFacilityContainer from "redux/containers/districtTripTracker/DttFacilityContainer";

function DttContent({ value }) {
    return (
        <>
            <StyledTabPanel value={value} index={DttTabOptions.Students}>
                <DttStudentContainer />
            </StyledTabPanel>
            <StyledTabPanel value={value} index={DttTabOptions.Schools}>
                <DttFacilityContainer />
            </StyledTabPanel>
        </>
    );
}

DttContent.defaultProps = {
    value: 1
};

/* eslint-disable react/forbid-prop-types */
DttContent.propTypes = {
    value: number
};

export default DttContent;