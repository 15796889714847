import React from 'react';
import {
    bool,
    string
} from 'prop-types';

import { styled } from '@mui/material/styles';

import { commonStyles } from '../../../styles/CommonStyles';

import StyledTypography from '../StyledTypography/StyledTypography';

const InitialContentResponsive = styled('div')(({ theme }) => ({
    ...commonStyles(theme).contentResponsive,
    height: 'auto'
}));

const TitleCentralBanner = styled(StyledTypography)(({ theme }) => ({
    ...commonStyles(theme).titleResponsive,
    color: theme.palette.black
}));
const ParagraphCentralBanner = styled(StyledTypography)(({ theme }) => ({
    ...commonStyles(theme).paragraphResponsive
}));

function StyledBannerContent({
    title,
    content,
    show,
    ...props
}) {
    return show && (
        <InitialContentResponsive
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <TitleCentralBanner variant="h1">
                {title}
            </TitleCentralBanner>
            <ParagraphCentralBanner>
                {content}
            </ParagraphCentralBanner>
        </InitialContentResponsive>
    );
}

/* eslint-disable react/forbid-prop-types */
StyledBannerContent.propTypes = {
    title: string.isRequired,
    content: string.isRequired,
    show: bool.isRequired
};
export default StyledBannerContent;
