import React, { useRef, forwardRef, useImperativeHandle } from "react";
import AutoComplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

import StyledTextField from "../Common/StyledTextField/StyledTextField";
import StyledTypography from "../Common/StyledTypography/StyledTypography";
import { localize } from "../../util/Localizer";
import { objectEmptyValidator } from "../../util/validationUtil";
import { commonStyles } from "../../styles/CommonStyles";
import breakpointsNames from "../../styles/ResponsiveConstants";

const SuggestionSubtitleH4 = styled('span')(({ theme }) => ({
    ...commonStyles(theme).customSubtitle
}));

const AutoCompleteTextField = styled(StyledTextField)(() => ({
    '& > div': {
        height: 30,
        paddingTop: '0px !important',
        fontSize: '14px !important'
    }
}));

const CalendarAutoComplete = styled(AutoComplete)(({ theme }) => ({
    minWidth: 300,
    display: 'inline-block',
    [theme.breakpoints.down(breakpointsNames.lg)]: {
        minWidth: 300
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        minWidth: 250,
        width: '-webkit-fill-available'
    },
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        minWidth: 180,
        width: '-webkit-fill-available'
    }
}));

const CalendarTypeAutoComplete = forwardRef((props, ref) => {
    const {
        onSelectCalendarType,
        idName,
        value,
        defaultCalendarType,
        onChangeInput,
        isRequired,
        optionList
    } = props;

    const refAutocomplete = useRef();
    const refInputSearch = useRef();
    const loading = false;

    let calendarTypeInfo = {
        id: '',
        name: '',
        type: ''
    };

    const onClearInputFilter = () => {
        refAutocomplete.current.querySelector("button[title='Clear']").click();
    };

    useImperativeHandle(ref,
        () => ({
            onClearFilter() {
                onClearInputFilter();
            }
        }));

    const handleInput = (evt) => {
        onChangeInput(evt);
    };

    const onPreSelectedCalendarType = (item, fieldValue) => {
        if (fieldValue && typeof fieldValue === 'object') {
            calendarTypeInfo = {
                id: fieldValue.id,
                name: fieldValue.name,
                type: fieldValue.type
            };
            onSelectCalendarType(calendarTypeInfo);
        } else {
            calendarTypeInfo = {
                id: '',
                name: '',
                type: ''
            };

            onSelectCalendarType(calendarTypeInfo);
        }
    };

    const onLoadableTextField = params => (
        <AutoCompleteTextField
            id={idName}
            onChange={(e) => handleInput(e)}
            inputProps={{
                ...params.inputProps,
                "data-testid": idName,
                onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                        e.stopPropagation();
                    }
                }
            }}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </>
                )
            }}
            inputRef={refInputSearch}
            isRequired={isRequired}
            name={idName}
            value={value}
        />
    );

    const onRenderOptionResult = (properties, suggestion) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div key={suggestion.id} {...properties}>
            <StyledTypography variant="h4" sx={{ display: 'flex', flexDirection: 'column'}}>
                <span>{suggestion.name}</span>
                <SuggestionSubtitleH4>{suggestion.type}</SuggestionSubtitleH4>
            </StyledTypography>
        </div>
    );

    const getPopperUp = () => ({
        popperUp: {
            zIndex: 1
        }
    });

    return (
        <CalendarAutoComplete
            sx={() => getPopperUp()}
            freeSolo
            includeInputInList
            inputValue={value}
            loading={loading}
            loadingText={localize('calendarUpload.searchingForCalendarType')}
            options={optionList}
            popupIcon={null}
            ref={refAutocomplete}
            value={defaultCalendarType}
            getOptionLabel={item => !objectEmptyValidator(item) ? item.name : ''}
            onChange={onPreSelectedCalendarType}
            renderInput={params => (onLoadableTextField(params))}
            renderOption={(properties, suggestion) => onRenderOptionResult(properties,
                suggestion)}
        />
    );
});

export default CalendarTypeAutoComplete;
