import { bool, string, number } from 'prop-types';

const facilityShape = {
    id: string,
    dataVersion: number,
    name: string,
    address1: string,
    address2: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    lat: number,
    lng: number,
    personalLocation: bool,
    phoneNumber: string,
    timeZone: string,
    activeFlag: bool
};

export default facilityShape;