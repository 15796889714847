import React from 'react';
import Box from '@mui/material/Box';
import StyledTypography from 'components/Common/StyledTypography/StyledTypography';
import { localize } from 'util/Localizer';
import BasicInformationContainer from 'redux/containers/transportationRequest/BasicInformationContainer';
import TRNavContainer from 'redux/containers/transportationRequest/TRNavContainer';
import CommonStyles from 'styles/CommonStyles';
import StyledWrapper from 'components/Common/StyledWrapper/StyledWrapper';
import AdditionalInformation from '../AdditionalInformation/AdditionalInformation';

function StudentInformation() {
    return (
        <StyledWrapper>
            <Box sx={{ margin: '0 131px', padding: '30px 0 20px' }}>
                <StyledTypography
                    extraStyle={CommonStyles.subTitle}
                >
                    {localize('transportationRequest.basicInformation')}
                </StyledTypography>
                <BasicInformationContainer />
                <StyledTypography
                    extraStyle={CommonStyles.subTitle}
                >
                    {localize('transportationRequest.additionalInformation')}
                </StyledTypography>
                <AdditionalInformation />
            </Box>
            <TRNavContainer />
        </StyledWrapper>
    );
}

export default StudentInformation;
