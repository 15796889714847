import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ErrorPage from 'screens/Error/ErrorPage';

import {
    updatePath
} from 'redux/workers/pathWorker';
import { localize } from 'util/Localizer';

const mapStateToProps = () => ({
    title: localize('errorPage.title403'),
    content: localize('errorPage.content403')
});

const mapDispatchToProps = {
    updatePath
};

const Error403PageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ErrorPage));

export default Error403PageContainer;