// TODO: this file will grow into the sole location for the modification, suplementation, calculation needed
//  to transform the trips that we receive from the server into something that is usable.
import Auth from "../../auth/Auth";

// eslint-disable-next-line import/prefer-default-export
export const initialValues = tripModel => {
    const model = tripModel;

    model.amPickupLocation = "Home Address";
    model.pmDropOffLocation = "Same address as A.M. Pickup Location";
    
    const accounts = Auth.getAccounts();

    if (accounts.length === 1) {
        const account = accounts.find(r => r.AccountGUID);

        model.accountId = account.AccountGUID;
        model.accountNumber = account.AccountID;
        model.accountName = account.Name;
    }
};

export const dataAccounts = () => {
    const accounts = Auth?.getAccounts() ?? [];
    const accountsData = [];

    accounts?.map((account) => (
        accountsData.push({
            id: account.AccountID,
            label: account.Name
        })
    ));

    return accountsData;
};