import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import NoShowModalDatePicker from '../../../components/report/NoShowModalDatePicker';
import {
    closeNoShowModalDatePicker,
    downloadNoShowReport
} from '../../workers/reportsWorker';
import { getDateMonthAgo } from '../../../util/dateUtils';
import { localize } from '../../../util/Localizer';
import withRouter from '../../../util/customHooks/withRouter';

const MONTH_AGO = 3;

const mapStateToProps = (state) => {
    const { ReportReducers } = state;

    if (ReportReducers) {
        return {
            showDatePicker: ReportReducers.showDatePicker,
            alertMessage: ReportReducers.responseMessage,
            isDisabled: ReportReducers.isLoadingReport,
            title: localize('report.noShowDownloadTitle'),
            buttonName: localize('report.noShowDownloadButton'),
            buttonId: "btnConfirm",
            id: "add-user-title",
            minDate: getDateMonthAgo(MONTH_AGO),
            isDisableFuture: true
        };
    }

    return {};
};

const NoShowReportModalDatePickerContainer = withRouter(
    connect(
        mapStateToProps, {
            onSelectDate: downloadNoShowReport,
            closeModalDatePicker: closeNoShowModalDatePicker
        }
    )(injectIntl(NoShowModalDatePicker)));

export default NoShowReportModalDatePickerContainer;