import React, { useEffect } from 'react';

import DttContentContainer from 'redux/containers/districtTripTracker/DttContentContainer';
import StyledHeaderDttContainer from 'redux/containers/districtTripTracker/StyledHeaderDttContainer';

import DPApp from 'components/DPApp';
import StyledSystemAlert from 'components/Common/StyledSytemAlert/StyledSystemAlert';
import { Enum } from 'util/EnumHelper';
import { localize } from 'util/Localizer';
import Auth from 'auth/Auth';
import { func } from 'prop-types';

function DistrictTripTracker({
    initialSetup
}) {
    const teacherAppEnabled = Auth.getConfig().teacherAppEnabled !== false ?
        true : Auth.getConfig().teacherAppEnabled;

    const showBanner = localStorage.getItem(Enum.closedBannerCookie);
    const [ open, setOpen ] = React.useState(showBanner !== "true");

    useEffect(() => {
        initialSetup();
    }, []);

    return (
        <DPApp>
            { teacherAppEnabled ?
                <StyledHeaderDttContainer
                    systemAlert={
                        <StyledSystemAlert
                            isAlert
                            message={localize('districtTripTracker.systemAlertMessage')}
                            severity={Enum.severityType.info}
                            show={open}
                            title={localize('districtTripTracker.systemAlertTitle')}
                            link="/Resources/how-to-grant-app-access"
                            linkText={localize('districtTripTracker.systemAlertLinkText')}
                            onClose={setOpen}
                        />
                    }
                    showAlert={open}
                /> :
                <StyledHeaderDttContainer />
            }
            <DttContentContainer />
        </DPApp>
    );
}

DistrictTripTracker.defaultProps = {
    initialSetup: () => null
};

/* eslint-disable react/forbid-prop-types */
DistrictTripTracker.propTypes = {
    initialSetup: func
};
/* eslint-enable react/forbid-prop-types */

export default DistrictTripTracker;
