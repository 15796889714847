import React from 'react';
import { styled } from '@mui/material/styles';
import { Select, MenuItem } from '@mui/material';
import {
    array,
    bool,
    func,
    object,
    oneOfType,
    string
} from 'prop-types';
import StyledFormControl from 'components/Common/StyledFormControl/StyledFormControl';
import { museoSansFont500 } from 'util/EnumHelper';
import { localize } from 'util/Localizer';
import { isOdd } from 'util/validationUtil';

const StyledMenuItem = styled(
    MenuItem,
    { shouldForwardProp: (prop) => prop !== 'isItemOdd' }
)(({ theme, isItemOdd }) => ({
    // eslint-disable-next-line max-len
    borderTop: '1px solid var(--variants-gray-gray-40, rgba(120, 132, 146, 0.40))',
    borderBottom: '1px solid var(--variants-gray-gray-40, rgba(120, 132, 146, 0.40))',
    backgroundColor: isItemOdd ? theme.palette.offWhite: theme.palette.white,
    height: 57,
    fontFamily: museoSansFont500,
    color: theme.palette.everDrivenCharcoal,
    '&:hover': {
        backgroundColor: theme.palette.everDrivenDarkBlue,
        color: theme.palette.white
    },
    '&.Mui-selected': {
        backgroundColor: theme.palette.everDrivenDarkBlue,
        color: theme.palette.white
    }
}));

const MenuProps = {
    PaperProps: {
        style: {
            borderRadius: '0px 0px 8px 8px',
            boxShadow: '0px 1px 16px 0px rgba(51,51,51,0.15)'
        }
    },
    disableScrollLock: true
};

function StyledDropdown({
    name,
    value,
    items,
    extraStyle,
    emptyOption,
    onChange,
    isError
}) {
    const renderItems = () => items.map((item, index) => (
        <StyledMenuItem
            isItemOdd={isOdd(index)}
            key={`Item_${item.value}`}
            value={item.key}
            data-testid={item.value}
        >
            {localize(item.value)}
        </StyledMenuItem>
    ));

    return (
        <StyledFormControl
            fullWidth
            isNewStyle
            size="small"
            extraStyle={extraStyle}
            testId={name}
            variant="filled"
            isError={isError}
        >
            <Select
                displayEmpty
                labelId={`label_${name}`}
                MenuProps={MenuProps}
                name={name}
                inputProps={{ "data-testid": `input_${name}` }}
                renderValue={(selected) => {
                    if (!selected || selected.length === 0) {
                        return (
                            <span style={{ opacity: '60%', fontFamily: museoSansFont500 }}>
                                {emptyOption}
                            </span>
                        );
                    }

                    return selected;
                }}
                value={value}
                onChange={onChange}
            >
                {renderItems()}
            </Select>
        </StyledFormControl>
    );
}

StyledDropdown.defaultProps = {
    name: '',
    value: '',
    extraStyle: null,
    items: [],
    emptyOption: '',
    onChange: () => null,
    isError: false
};

/* eslint-disable react/forbid-prop-types */
StyledDropdown.propTypes = {
    name: string,
    value: string,
    items: oneOfType([
        array,
        object
    ]),
    extraStyle: object,
    emptyOption: string,
    onChange: func,
    isError: bool
};
/* eslint-enable react/forbid-prop-types */

export default StyledDropdown;
