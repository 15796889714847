import { Enum } from '../../util/EnumHelper';
import {
    SNACKBAR_OPEN,
    SNACKBAR_MESSAGE,
    SNACKBAR_SEVERITY
} from '../actions/styledSnackbarWrapperAction';

const initialState = {
    message: '',
    severity: Enum.severityType.warning,
    openSnackBar: false
};

// eslint-disable-next-line default-param-last
const styledSnackbarWrapperReducer = (state = initialState, action) => {
    switch (action.type) {
    case SNACKBAR_OPEN:
        return {
            ...state,
            openSnackBar: action.isOpen
        };
    case SNACKBAR_MESSAGE:
        return {
            ...state,
            message: action.message
        };
    case SNACKBAR_SEVERITY:
        return {
            ...state,
            severity: action.severity
        };
    default:
        return state;
    }
};

export default styledSnackbarWrapperReducer;
