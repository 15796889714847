import moment from 'moment';
import { Enum } from './EnumHelper';

export const calcIsToday = (trip, timeZone) => {
    let isToday = false;
    const todayDate = moment(new Date(), timeZone).format('YYYY-MM-DD');
    const tripState = trip.State;

    const dueDate = trip.DueDateTLT.substring(0,
        trip.DueDateTLT.indexOf('T'));
    
    switch(tripState) {
    case Enum.TripState.Active:
    case Enum.TripState.EnRoute:
    case Enum.TripState.OnSite:
    case Enum.TripState.OnBoard:
    case Enum.TripState.OnSiteAtDropoff:
        isToday = true;
        break;
    default:
        isToday = dueDate === todayDate;
        break;
    }
    
    return isToday;
};

export const calcUtcHoursAgo = (dateStr) => {
    const utcDate = moment.utc(dateStr);
    const hours = (utcDate - moment.utc()) / 3600000;

    return hours;
};

export const calcAge = (birthday)=>{
    const age = moment().diff(birthday,
        'years');

    return age;
};

export const calcMinutesFromNow = (dateStr) => {
    const now = moment();
    const minutesDiff = moment(dateStr).diff(now, 'minutes');

    return minutesDiff;
};

export const dateIsValid = (dateStr)=> {
    const date = moment(dateStr,
        [ "MM-DD-YYYY", "YYYY-MM-DD" ]);

    return date.isValid();
};

export const dateFormatMoment = (dateStr) =>{
    const date = moment(dateStr);

    return date;
};

export const dateFormatDateOfBirth = (dateStr) =>{
    const date = moment(dateStr).format("YYYY-MM-DD");

    return `${date}T00:00:00.000Z`;
};

export const addTimeZone = (dateStr) => {
    if(dateStr.includes('T')) {
        return dateStr;
    }

    return dateStr.replaceAll('-', '/');
};

export const timeFormat = (dateStr) => {
    if(dateStr === null) {
        return '';
    }

    const format = moment(dateStr).format("hh:mm A");

    return format;
};

export const timeFormatDropOffEta = (dateStr, secDiff) => {
    if(dateStr === null) {
        return '';
    }

    const format = moment(dateStr).add(secDiff,
        'seconds').format("hh:mm A");

    return format;
};

export const timeFormatEta = (etaSec) => {
    if(etaSec === null) {
        return '';
    }

    const format = moment().add(etaSec,
        'seconds').format("hh:mm A");

    return format;
};

export const compareIsBefore =(startDate, endDate) => {
    const response = moment(startDate).isBefore(endDate);

    return response;
};

export const compareIsSameOrBefore = (startDate, endDate) => {
    const response = moment(startDate).isSameOrBefore(endDate);

    return response;
};

export const compareIsDifferentDatePart = (startDate, endDate) => {
    const response = (moment(startDate).format("MM/DD/YYYY") !== moment(endDate).format("MM/DD/YYYY"));

    return response;
};

export const dayFormat = (dateStr) => {
    if (!dateStr) {
        return '';
    }

    const response = moment(dateStr,
        "YYYY-MM-DD hh:mm:ss").format("dddd");

    return response;
};

export const monthDayYearFormat = (dateStr) => {
    if (!dateStr) {
        return '';
    }

    const response = moment(dateStr,
        "YYYY-MM-DD hh:mm:ss").format("MM/DD/YYYY");

    return response;
};

export const minutesFormat = (dateStr) => {
    if (!dateStr) {
        return '';
    }

    const response = moment(dateStr,
        "YYYY-MM-DD hh:mm:ss").format("mm");

    return response;
};

export const longDateFormat = (dateStr) => {
    if (!dateStr) {
        return '';
    }

    const response = moment(dateStr,
        "YYYY-MM-DD hh:mm:ss").format("MM/DD/YYYY [at] h:mm a");

    return response;
};

export const dateFromJson = dateStr => {
    if (!dateStr) {
        return '';
    }

    const dateString = dateStr.substr(6);
    const date = moment(new Date(parseInt(dateString,
        10))).format('MM/DD/YYYY');

    return date;
};

export const longDateFromJson = dateStr => {
    if (!dateStr) {
        return '';
    }

    const dateString = dateStr.substr(6);
    const date = moment(new Date(
        parseInt(dateString, 10))).format('MM/DD/YYYY [at] h:mm a'
    );

    return date;
};

export const getDateMonthAgo = (month) => {
    const date = new Date();

    date.setMonth(date.getMonth() - month);
    
    return date;
};

export const getEndpointFormatDate = (date) => (moment(date).format('YYYYMMDD'));

export const convertToLocalTimeZone = dateStr => {
    if (!dateStr) {
        return '';
    }

    const dateUtc = moment.utc(dateStr);
    const localDate = dateUtc.local();

    return localDate.format("MM/DD/YYYY [at] h:mm a");
};

export const minimumDateAllowed = () => {
    const date = new Date('1900-01-01');

    // eslint-disable-next-line function-call-argument-newline
    date.setHours(0, 0, 0, 0);

    return date;
};

const goBackMinutes = (date, minutesBack) => moment(date).subtract({minutes: minutesBack});

// Checking if trip is early is should be done on backend and passed as trip object property isEarly.
export const isTripLate = (trip) => {
    if (trip.IsEmulated || !trip.EtaTLT) {
        return false;
    }

    let isLate = false;

    switch(trip.State) {
    case Enum.TripState.EnRoute:
        isLate = compareIsBefore(moment(trip.DueTimeTLT), goBackMinutes(trip.EtaTLT, Enum.etaBuffer));
        break;
    case Enum.TripState.OnSite:
        isLate = compareIsBefore(moment(trip.DueTimeTLT), goBackMinutes(trip.OnsiteTLT, Enum.etaBuffer));
        break;
    case Enum.TripState.OnBoard:
        isLate = compareIsBefore(moment(trip.ScheduledDropOffTLT), goBackMinutes(trip.EtaTLT, Enum.etaBuffer));
        break;
    default:
        break;
    }

    return isLate;
};

export const yesterday = () => {
    const date = new Date();

    // eslint-disable-next-line function-call-argument-newline
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() - 1);

    return date;
};

export const tomorrow = () => {
    const date = new Date();

    // eslint-disable-next-line function-call-argument-newline
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 1);

    return date;
};

export const today = () => {
    const date = new Date();

    // eslint-disable-next-line function-call-argument-newline
    date.setHours(0, 0, 0, 0);

    return date;
};

export const isToday = (date) => {
    const todayDate = new Date();

    return !compareIsDifferentDatePart(todayDate, date);
};

export const trIsStartDateValid = (startDate) => {
    if(!startDate) {
        return false;
    }

    const date = new Date(startDate);

    if (date < tomorrow()) {
        return false;
    }
    
    return !(date && !dateIsValid(date));
};

export const trIsEndDateValid = (endDate, startDate) => {
    if(!endDate) {
        return false;
    }

    const end = new Date(endDate);
    
    const dateToCompare = !startDate ? tomorrow() : new Date(startDate);

    if (end < dateToCompare) {
        return false;
    }

    return (end && dateIsValid(end));
};

export const getDayName = (date = new Date(), locale = 'en-US') => (
    date.toLocaleDateString(locale, { weekday: 'long' })
);

export const getPlusDays = (plusDays) => {
    const date = new Date();

    // eslint-disable-next-line function-call-argument-newline
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + plusDays);

    return date;
};