export const DRAFTLIST_SEARCHTERM =
  'DRAFTLIST_SEARCHTERM';
export const setSearchTerm = search => ({
    type: DRAFTLIST_SEARCHTERM,
    search
});

export const DRAFTLIST_SET_DELETE_DRAFT =
  'DRAFTLIST_SET_DELETE_DRAFT';
export const setDeleteDraft = draftId => ({
    type: DRAFTLIST_SET_DELETE_DRAFT,
    draftId
});

export const DRAFTLIST_SET_OPEN_MODAL =
  'DRAFTLIST_SET_OPEN_MODAL';
export const setOpenModal = isOpen => ({
    type: DRAFTLIST_SET_OPEN_MODAL,
    isOpen
});

export const DRAFTLIST_PAGESIZE = 'DRAFTLIST_PAGESIZE';
export const draftListPageSize = rowsShowed => ({
    type: DRAFTLIST_PAGESIZE,
    rowsShowed
});

export const DRAFTLIST_LIMITPAGESIZE = 'DRAFTLIST_LIMITPAGESIZE';
export const draftListLimitPageSize = limitRows => ({
    type: DRAFTLIST_LIMITPAGESIZE,
    limitRows
});

export const DRAFTLIST_PAGE = 'DRAFTLIST_PAGE';
export const draftListSelectPage = activePage => ({
    type: DRAFTLIST_PAGE,
    activePage
});

export const DRAFTLIST_LIMITPAGE = 'DRAFTLIST_LIMITPAGE';
export const draftListLimitPage = limitPage => ({
    type: DRAFTLIST_LIMITPAGE,
    limitPage
});

export const DRAFTLIST_DRAFTS = 'DRAFTLIST_DRAFTS';
export const listDrafts = drafts => ({
    type: DRAFTLIST_DRAFTS,
    drafts
});

export const DRAFTLIST_COLUMN_SORT =
  'DRAFTLIST_COLUMN_SORT';
export const columnSort = sortColumn => ({
    type: DRAFTLIST_COLUMN_SORT,
    sortColumn
});

export const DRAFTLIST_RESET_COLUMNS =
  'DRAFTLIST_RESET_COLUMNS';
export const columnsReset = () => ({
    type: DRAFTLIST_RESET_COLUMNS
});

export const DRAFTLIST_COLUMNS =
  'DRAFTLIST_COLUMNS';
export const draftColumns = (columnName, sort) => ({
    type: DRAFTLIST_COLUMNS,
    columnName,
    sort
});

export const DRAFTLIST_LOADING =
  'DRAFTLIST_LOADING';
export const setLoading = loading => ({
    type: DRAFTLIST_LOADING,
    loading
});

export const DRAFTLIST_SEARCHING =
  'DRAFTLIST_SEARCHING';
export const setSearching = isSearching => ({
    type: DRAFTLIST_SEARCHING,
    isSearching
});
