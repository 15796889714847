import React from 'react';
import {
    object,
    oneOf,
    oneOfType,
    node,
    string,
    bool
} from 'prop-types';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { museoSansFont } from 'util/EnumHelper';

const NewTooltip = styled(
    // eslint-disable-next-line react/jsx-props-no-spreading
    ({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />),
    { shouldForwardProp: (prop) => prop !== 'isNewStyle' }
)(({ theme, isNewStyle }) => {
    if (isNewStyle) {
        return {
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.everDrivenLightBlue40,
                color: theme.palette.everDrivenCharcoal,
                maxWidth: 400,
                fontSize: 12,
                borderRadius: '0px 8px 8px 8px',
                fontFamily: museoSansFont,
                textAlign: 'center',
                fontWeight: 300,
                marginTop: 50
            }
        };
    }

    return {
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.offWhite,
            color: theme.palette.black,
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(16),
            border: `1px solid ${theme.palette.everDrivenLightBlue20}`
        }
    };
});

function StyledTooltip({
    isNewStyle,
    placement,
    title,
    children
}) {
    return <NewTooltip
        isNewStyle={isNewStyle}
        PopperProps={{
            disablePortal: true,
            popperOptions: {
                positionFixed: true
            }
        }}
        placement={placement}
        title={title}
    >
        {children}
    </NewTooltip>;
}

StyledTooltip.defaultProps = {
    isNewStyle: false,
    title: '',
    placement: 'bottom'
};

/* eslint-disable react/forbid-prop-types */
StyledTooltip.propTypes = {
    children: node.isRequired,
    isNewStyle: bool,
    title: oneOfType([
        string,
        object
    ]),
    placement: oneOf([
        'bottom-end',
        'bottom-start',
        'bottom',
        'left-end',
        'left-start',
        'left',
        'right-end',
        'right-start',
        'right',
        'top-end',
        'top-start',
        'top'
    ])
};
/* eslint-enable react/forbid-prop-types */

export default StyledTooltip;