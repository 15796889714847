import React from 'react';
import {
    DialogTitle,
    Dialog,
    ListItem,
    ListItemText,
    List
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { formatPhoneNumber } from '../../util/textFormatters';
import { localize } from "../../util/Localizer";
import { defaultContact } from '../../util/EnumHelper';

const ContactDialogTitle = styled(DialogTitle)(({ theme }) => ({
    color: theme.palette.everDrivenDarkGrey,
    fontWeight: 700,
    fontSize: 14,
    padding: 10,
    borderBottomWidth: 1,
    borderBottomStyle: 'groove'
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    color: theme.palette.everDrivenDarkGrey,
    padding: '5px 10px'
}));

const StyledListItemText = styled(ListItemText)(() => ({
    '& > span': {
        fontSize: 14,
        fontWeight: 700
    }
}));

function ContactDetail({
    open,
    email,
    phoneNumber,
    onClose
}) {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="contact-dialog-title"
            open={open}
            id="contact-Modal"
        >
            <ContactDialogTitle
                id="contact-dialog-title"
            >
                {localize('header.contactEverDriven')}
            </ContactDialogTitle>
            <List sx={{padding: '10px 50px 10px 10px'}}>
                <StyledListItem>
                    <StyledListItemText
                        primary={
                            phoneNumber?.trim().length > 0
                                ? formatPhoneNumber(phoneNumber)
                                : defaultContact.phone
                        }
                    />
                </StyledListItem>
                <StyledListItem>
                    <StyledListItemText
                        primary={email?.trim().length > 0 ? email : defaultContact.email}
                    />
                </StyledListItem>
            </List>
        </Dialog>
    );
}

export default ContactDetail;