import { optionsFilterUsers } from 'util/EnumHelper';
import {
    USERS_FILTER,
    USERS_LOAD,
    SET_FILTERED_USERS,
    SET_LOADING_USER,
    SET_LOADING_USER_UPDATE,
    SET_OPEN_USER_POPUP,
    SET_OPEN_USER_MOBILE,
    SET_USER_FIRSTNAME_COLUMN,
    SET_USER_LASTNAME_COLUMN,
    SET_USER_COLUMN_SORT,
    SET_USER_EMAIL_COLUMN,
    SET_COLUMNS_CHECK_ALL,
    SET_USER_OBJECT,
    USERS_TERM_FILTER,
    USERS_OPTION_FILTER,
    SET_SAVE_BUTTON,
    SET_USER_RESPONSE,
    SET_OPEN_USER_DELETE_MODAL,
    SET_USER_FILTER_OPTIONS
} from '../actions/userActions';
import {
    initialUser,
    initialUserColumnsSorted
} from '../constants';

const userInitialState = {
    term: '',
    users: [],
    filteredUsers: [],
    orderedUsers: [],

    userResponse: '',

    filterOption: 0,
    filterOptions: optionsFilterUsers,
    isLoadingUser: false,
    isLoadingUserUpdate: false,
    isUserPopUpOpen: false,
    isModalMobile: false,

    columns: { ...initialUserColumnsSorted },
    columnSorter: initialUserColumnsSorted.firstName.name,

    checkedAll: false,
    user: { ...initialUser },
    saveButtonDisabled: true,
    showDeleteUserModal: false
};

/* eslint-disable-next-line default-param-last */
const UserReducers = (state = userInitialState, action) => {
    switch (action.type) {
    case USERS_LOAD:
        return {
            ...state,
            users: action.users
        };
    case USERS_FILTER:
        return {
            ...state,
            term: action.term,
            filterOption: action.filterOption,
            columnSorter: action.columnSorter
        };
    case USERS_TERM_FILTER:
        return {
            ...state,
            term: action.term
        };
    case USERS_OPTION_FILTER:
        return {
            ...state,
            filterOption: action.option
        };
    case SET_FILTERED_USERS:
        return {
            ...state,
            filteredUsers: action.userList,
            orderedUsers: action.userOrderedList
        };
    case SET_USER_RESPONSE:
        return {
            ...state,
            userResponse: action.userResponse
        };
    case SET_LOADING_USER:
        return {
            ...state,
            isLoadingUser: action.isLoading
        };
    case SET_LOADING_USER_UPDATE:
        return {
            ...state,
            isLoadingUserUpdate: action.isLoadingUpdate
        };
    case SET_OPEN_USER_POPUP:
        return {
            ...state,
            isUserPopUpOpen: action.isOpen
        };
    case SET_OPEN_USER_MOBILE:
        return {
            ...state,
            isModalMobile: action.isOpen
        };
    case SET_USER_FIRSTNAME_COLUMN:
        return {
            ...state,
            columns: {
                ...state.columns,
                firstName: {
                    ...state.columns.firstName,
                    sort: action.payload
                }
            }
        };
    case SET_USER_LASTNAME_COLUMN:
        return {
            ...state,
            columns: {
                ...state.columns,
                lastName: {
                    ...state.columns.lastName,
                    sort: action.payload
                }
            }
        };
    case SET_USER_EMAIL_COLUMN:
        return {
            ...state,
            columns: {
                ...state.columns,
                emailAddress: {
                    ...state.columns.emailAddress,
                    sort: action.payload
                }
            }
        };
    case SET_USER_COLUMN_SORT:
        return {
            ...state,
            columnSorter: action.columnSorter
        };

    case SET_COLUMNS_CHECK_ALL:
        return {
            ...state,
            checkedAll: action.checkedAll
        };
    case SET_USER_OBJECT:
        return {
            ...state,
            user: action.userObject
        };
    case SET_SAVE_BUTTON:
        return {
            ...state,
            saveButtonDisabled: action.disabled
        };
    case SET_OPEN_USER_DELETE_MODAL:
        return {
            ...state,
            showDeleteUserModal: action.showDeleteUserModal
        };
    case SET_USER_FILTER_OPTIONS:
        return {
            ...state,
            filterOptions: action.filterOptions
        };
    default:
        return state;
    }
};

export default UserReducers;
