import React, { useEffect } from "react";
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { generateUniqueKey } from '../../util/arrayUtil';
import { localize } from "../../util/Localizer";

import StyledHeaderContent from "../Common/StyledHeaderContent/StyledHeaderContent";
import StyledTypography from "../Common/StyledTypography/StyledTypography";
import StyledButton from "../Common/StyledButton/StyledButton";
import DropZone from "../../controls/Common/DropZone";
import { studentUploadAcceptedFiles } from "../../util/validationUtil";
import { Enum, fileError, urlStudentUploadTemplate } from "../../util/EnumHelper";
import StudentFileList from "./StudentFileList";
import { maxSizeFileToMegaBytes } from "../../util/fileUtil";

const MAX_SIZE_FILE = Enum.maxFileSize;

const SubTitle = styled(StyledTypography)(({ theme }) => ({
    color: theme.palette.everDrivenDarkGrey
}));

const StudentListUploadContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.offWhite,
    paddingRight: 15,
    paddingLeft: 15
}));

const ContainerButtons = styled(Grid)(() => ({
    marginTop: 15,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'right',
    '&> button:nth-of-type(2)': {
        marginLeft: 10
    },
    '&> :last-child': {
        marginLeft: 10
    }
}));

const FakeLink = styled('u')(({ theme }) => ({
    fontSize: 20,
    color: theme.palette.everDrivenLightBlue
}));

const DropzoneLabel = styled('span')(({ theme }) => ({
    fontSize: 20,
    color: theme.palette.everDrivenDarkGrey
}));

const MaxFiles = styled('span')(({ theme }) => ({
    color: theme.palette.everDrivenDarkGrey,
    fontSize: 14
}));

const CancelButton = styled(StyledButton)(({ theme }) => ({
    borderRadius: 8,
    width: 200,
    backgroundColor: theme.palette.white,
    color: theme.palette.everDrivenDarkBlue,
    borderColor: theme.palette.everDrivenDarkBlue,
    border: 2,
    borderStyle: 'solid',
    '&:hover' : {
        color: theme.palette.white,
        backgroundColor: theme.palette.everDrivenDarkBlue,
        borderColor: theme.palette.everDrivenDarkBlue
    },
    '&.Mui-disabled': {
        borderColor: 'transparent'
    }
}));

const SubmitButton = styled(StyledButton)(({ theme }) => ({
    borderRadius: 8,
    width: 200,
    backgroundColor: theme.palette.everDrivenDarkBlue
}));

function StudentListUpload({
    keyDropzone,
    studentFiles,
    studentRejectedFiles,
    onClearForm,
    onSnackBarPropertyChange,
    onUploadStudentFile,
    setKeyDropZone,
    setStudentRejectedFiles,
    setStep
}) {
    const dropRejectMessage = (rejectedFile) => {
        if(rejectedFile.errors && rejectedFile.errors.length > 0) {
            switch (rejectedFile.errors[0].code) {
            case fileError.fileTooLarge:
                onSnackBarPropertyChange(
                    Enum.severityType.error,
                    localize('studentUpload.errorMaxSizeFileMessage',
                        { size: maxSizeFileToMegaBytes(MAX_SIZE_FILE).toString() })
                );
                break;
            case fileError.fileNameHasNonAsciiCharacter:
                onSnackBarPropertyChange(
                    Enum.severityType.error,
                    localize('studentUpload.fileNameHasASCIICharacters',
                        { file: rejectedFile.file.name })
                );
                break;
            default:
                onSnackBarPropertyChange(
                    Enum.severityType.error,
                    localize('studentUpload.errorFileMessage')
                );
                break;
            }
        }
    };

    const fileRemovedMessage = (fileName) => {
        onSnackBarPropertyChange(
            Enum.severityType.info,
            localize('studentUpload.fileRemoved', { file: fileName }));
    };

    const fileLimitExceedMessage = () => {
        onSnackBarPropertyChange(
            Enum.severityType.error,
            localize('studentUpload.maxFileAllowed'));
    };

    useEffect(() => {
        setKeyDropZone(generateUniqueKey());
    }, []);

    return (
        <>
            <StyledHeaderContent
                id="studentBulkUploadHeader"
                title={localize('studentUpload.title')}
            >
                <Grid item xs={12}>
                    <SubTitle variant="h5">
                        {localize('studentUpload.subTitle')}
                    </SubTitle>
                </Grid>
            </StyledHeaderContent>
            <StudentListUploadContainer container>
                <Grid item xs={12}>
                    <DropZone
                        key={keyDropzone}
                        allowedFiles={studentUploadAcceptedFiles}
                        dropZoneText={
                            <>
                                <div>
                                    <DropzoneLabel>
                                        {localize('studentUpload.dropFiles')}
                                    </DropzoneLabel>
                                    <FakeLink>{localize('studentUpload.dropFiles2')}</FakeLink>
                                </div>
                                <div>
                                    <MaxFiles>
                                        {localize('studentUpload.maxFiles')}
                                    </MaxFiles>
                                </div>
                            </>
                        }
                        filesLimit={1}
                        fileObjects={studentFiles}
                        isCalendarUpload
                        maxFileSize={MAX_SIZE_FILE}
                        getDropRejectMessage={dropRejectMessage}
                        getFileLimitExceedMessage={fileLimitExceedMessage}
                        getFileRemovedMessage={fileRemovedMessage}
                        onChange={(files) => onUploadStudentFile(files)}
                        onChangeRejections={(files) => setStudentRejectedFiles(files)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledTypography variant="h5">
                        {localize('studentUpload.downloadTemplate')}
                        <a href={urlStudentUploadTemplate.url} download>
                            {localize('studentUpload.downloadTemplate2')}
                        </a>
                    </StyledTypography>
                </Grid>
                <Grid item xs={12}>
                    <StudentFileList files={studentFiles} rejectedFiles={studentRejectedFiles} />
                </Grid>

                <ContainerButtons item xs={12}>
                    <CancelButton
                        isDisabled={studentFiles.length === 0}
                        testId="studentListUploadCancelButton"
                        type="button"
                        onClick={() => onClearForm()}
                    >
                        {localize('studentUpload.cancel')}
                    </CancelButton>
                    <SubmitButton
                        isDisabled={studentFiles.length === 0}
                        testId="studentListUploadContinueButton"
                        type="submit"
                        onClick={() => setStep(1)}
                    >
                        {localize('studentUpload.continue')}
                    </SubmitButton>
                </ContainerButtons>
            </StudentListUploadContainer>
        </>
    );
}

export default StudentListUpload;