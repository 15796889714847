export const formatPlaceHolderPhoneNumber = '(   )    -    ';
export const personalInformationModel = {
    studentId: '',
    requestType: 'new',
    correlationId: '',
    studentFirstName: '',
    studentLastName: '',
    studentDateOfBirth: null,
    studentAge: '',
    studentGrade: '',
    tripEndDate: null,

    studentAddressLine1: '',
    studentAddressLine2: '',
    studentAddressCity: '',
    studentAddressZipCode: '',
    studentAddressState: '',
    studentAddressLatitude: '',
    studentAddressLongitude: '',

    parent1FirstName: '',
    parent1Relationship: '',
    parent1MobilePhone: '',
    parent1AltPhone: '',
    parent1EmailAddress: '',
    parent1AppAccess: false
};

export default personalInformationModel;

export const emergencyContactInformationModel = {
    contact1FirstName: '',
    contact1MobilePhone: '',
    contact1AltPhone: '',
    contact1Relationship: '',
    contact1EmailAddress: '',
    contact1AppAccess: false,
    contact2FirstName: '',
    contact2MobilePhone: '',
    contact2AltPhone: '',
    contact2Relationship: '',
    contact2EmailAddress: '',
    contact2AppAccess: false
};

export const schoolInformationModel = {
    schoolName: '',
    schoolAddressLine1: '',
    schoolAddressLine2: '',
    schoolAddressCity: '',
    schoolAddressZipCode: '',
    schoolAddressState: '',
    schoolPhone: '',
    schoolMonday: false,
    schoolTuesday: false,
    schoolWednesday: false,
    schoolThursday: false,
    schoolFriday: false,
    schoolSaturday: false,
    schoolSunday: false,
    schoolArrivalMonday: null,
    schoolArrivalTuesday: null,
    schoolArrivalWednesday: null,
    schoolArrivalThursday: null,
    schoolArrivalFriday: null,
    schoolArrivalSaturday: null,
    schoolArrivalSunday: null,
    schoolPickupMonday: null,
    schoolPickupTuesday: null,
    schoolPickupWednesday: null,
    schoolPickupThursday: null,
    schoolPickupFriday: null,
    schoolPickupSaturday: null,
    schoolPickupSunday: null,
    schoolArrivalInstructions: '',
    schoolAddressLatitude: '',
    schoolAddressLongitude: ''
};

export const scheduleModel = {
    schoolArrivalMonday: "",
    schoolArrivalTuesday: "",
    schoolArrivalWednesday: "",
    schoolArrivalThursday: "",
    schoolArrivalFriday: "",
    schoolArrivalSaturday: "",
    schoolArrivalSunday: "",
    schoolPickupMonday: "",
    schoolPickupTuesday: "",
    schoolPickupWednesday: "",
    schoolPickupThursday: "",
    schoolPickupFriday: "",
    schoolPickupSaturday: "",
    schoolPickupSunday: ""
};

export const scheduleValidModel = {
    schoolArrivalMonday: true,
    schoolArrivalTuesday: true,
    schoolArrivalWednesday: true,
    schoolArrivalThursday: true,
    schoolArrivalFriday: true,
    schoolArrivalSaturday: true,
    schoolArrivalSunday: true,
    schoolPickupMonday: true,
    schoolPickupTuesday: true,
    schoolPickupWednesday: true,
    schoolPickupThursday: true,
    schoolPickupFriday: true,
    schoolPickupSaturday: true,
    schoolPickupSunday: true
};

export const generalTripInformationModel = {
    accountId: '',
    accountNumber: '',
    accountName: '',
    tripStartDate: null,
    amPickupLocation: '',
    pmDropOffLocation: '',
    transportationEquipmentNeeds: [],
    comments: '',
    studentAttachments: [],
    populationTypes: [],

    pickupLocationAddressLine1: '',
    pickupLocationAddressLine2: '',
    pickupLocationAddressCity: '',
    pickupLocationAddressZipCode: '',
    pickupLocationAddressState: '',
    pickupLocationAddressLatitude: '',
    pickupLocationAddressLongitude: '',

    dropOffLocationAddressLine1: '',
    dropOffLocationAddressLine2: '',
    dropOffLocationAddressCity: '',
    dropOffLocationAddressZipCode: '',
    dropOffLocationAddressState: '',
    dropOffLocationAddressLatitude: '',
    dropOffLocationAddressLongitude: ''
};

export const populationTypeDto = {
    populationTypeMKV: false,
    populationTypeESE: false,
    populationTypeESY: false,
    populationTypeATP: false,
    populationTypeNCLB: false,
    populationTypeRegularEd: false,
    populationTypeMedFragile: false,
    populationTypeMagnet: false,
    populationTypeOutofDistrict: false,
    populationTypeSPED: false,
    populationTypeMultiDistrict: false,
    populationTypeDeafEd: false,
    populationTypeSpecialEvents: false,
    populationTypeSchoolofChoice: false,
    populationTypeESSA: false,
    populationTypePreK: false
};

export const transportationEquipmentDto = {
    monitorNeeded: false,
    mustRideAlone: false,
    booster: false,
    safetyVest: false,
    carSeat: false,
    buckleGuard: false,
    wheelchair: false,
    notApplicable: false
};

export const schoolDaysDto = {
    schoolMonday: false,
    schoolTuesday: false,
    schoolWednesday: false,
    schoolThursday: false,
    schoolFriday: false,
    schoolSaturday: false,
    schoolSunday: false
};

export const requestTypeDto = {
    new: 'new',
    update: 'update'
};

export const schoolDto = {
    name: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    state: '',
    phoneNumber: '',
    latitude: '',
    longitude: ''
};

export const studentDto = {
    id: '',
    clientMemberCode: '',
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    fullName: ''
};
