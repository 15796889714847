import {
    setIsSameContactCheck,
    updateModel
} from "../actions/tripRequestFormActions";
import { isNewCorrect } from "./tripRequestFormWorker";

export const setIsSameContact = (checked) => (dispatch, getState) => {
    const { tripModels } = getState().TripRequestFormReducers;

    dispatch(setIsSameContactCheck(checked));
    if (checked) {
        const studentOne = tripModels.find(x => x.student === 1);
        const { tripModel } = studentOne;

        dispatch(updateModel({
            contact1FirstName: tripModel.contact1FirstName ?? '',
            contact1MobilePhone: tripModel.contact1MobilePhone ?? '',
            contact1AltPhone: tripModel.contact1AltPhone ?? '',
            contact1Relationship: tripModel.contact1Relationship ?? '',
            contact1EmailAddress: tripModel.contact1EmailAddress ?? '',
            contact1AppAccess: tripModel.contact1AppAccess ?? '',
            contact2FirstName: tripModel.contact2FirstName ?? '',
            contact2MobilePhone: tripModel.contact2MobilePhone ?? '',
            contact2AltPhone: tripModel.contact2AltPhone ?? '',
            contact2Relationship: tripModel.contact2Relationship ?? '',
            contact2EmailAddress: tripModel.contact2EmailAddress ?? '',
            contact2AppAccess: tripModel.contact2AppAccess ?? '',
            parent1FirstName: tripModel.parent1FirstName ?? '',
            parent1Relationship: tripModel.parent1Relationship ?? '',
            parent1MobilePhone: tripModel.parent1MobilePhone ?? '',
            parent1AltPhone: tripModel.parent1AltPhone ?? '',
            parent1EmailAddress: tripModel.parent1EmailAddress ?? '',
            parent1AppAccess: tripModel.parent1AppAccess ?? ''
        }));
    }
    dispatch(isNewCorrect());
};

export const onUpdateEmergencyContactPhone = (evt) => (dispatch, getState) => {
    const { tripModel } = getState().TripRequestFormReducers;

    dispatch(updateModel({
        [evt.target.name]: evt.target.value
    }));

    if (!tripModel[evt.target.name] || tripModel[evt.target.name] !== evt.target.value) {
        dispatch(setIsSameContactCheck(false));
    }
    
    dispatch(isNewCorrect());
};