import React from 'react';
import PropTypes from 'prop-types';
import Map from 'google-maps-react';
import Marker from 'widgets/tracktrip/Marker';
import { inProgressStatus } from 'util/EnumHelper';

import { getCarIcon, getHomeIcon, getSchoolIcon } from '../mapIcons';

const mapStyle = {
    borderRadius: '12px',
    position: 'relative',
    width: '100%',
    height: '525px'
};

const mapContainerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%'
};

function MapContainer({ trip }) {
    const [ mapRef, setMapRef ] = React.useState(null);

    const carIcon = getCarIcon(trip);
    const homeIcon = getHomeIcon();
    const schoolIcon = getSchoolIcon();

    // This is only here because the data is multiplied by factor.
    const factor = 1000000;

    const pickupCoords = {
        lat: trip.PickUpLocation.Coordinates.Latitude / factor,
        lng: trip.PickUpLocation.Coordinates.Longitude / factor
    };

    const dropoffCoords = {
        lat: trip.DropOffLocation.Coordinates.Latitude / factor,
        lng: trip.DropOffLocation.Coordinates.Longitude / factor
    };

    const driverCoords = inProgressStatus.includes(trip.State) && trip.Coordinates ?
        {
            lat: trip.Coordinates.Latitude / factor,
            lng: trip.Coordinates.Longitude / factor
        } :
        null;

    const mapLoaded = () => {
        mapRef.map.setOptions({
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
            }
        });

        if (driverCoords) {
            mapRef.map.setCenter(driverCoords);
        } else {
            mapRef.map.setCenter(dropoffCoords);
        }
    };

    return (
        <Map
            ref={(m) => { setMapRef(m); }}
            google={window.google}
            containerStyle={mapContainerStyle}
            style={mapStyle}
            streetViewControl={false}
            zoom={11}
            onReady={() => mapLoaded()}
        >
            { trip.PickUpLocation.FacilityGuid ?
                <Marker position={pickupCoords} icon={schoolIcon} /> :
                <Marker position={pickupCoords} icon={homeIcon} />
            }

            { trip.DropOffLocation.FacilityGuid ?
                <Marker position={dropoffCoords} icon={schoolIcon} /> :
                <Marker position={dropoffCoords} icon={homeIcon} />
            }

            {driverCoords &&
                <Marker position={driverCoords} icon={carIcon} />
            }
        </Map>
    );
}

MapContainer.propTypes = {
    trip: PropTypes.shape({
        Coordinates: PropTypes.shape({
            Latitude: PropTypes.number,
            Longitude: PropTypes.number

        }),
        PickUpLocation: PropTypes.shape({
            Coordinates: PropTypes.shape({
                Latitude: PropTypes.number,
                Longitude: PropTypes.number
    
            })
        }),
        DropOffLocation: PropTypes.shape({
            Coordinates: PropTypes.shape({
                Latitude: PropTypes.number,
                Longitude: PropTypes.number
    
            })
        }),
        State: PropTypes.number
    }).isRequired
};

export default MapContainer;