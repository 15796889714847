import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, FormControlLabel } from '@mui/material';

import { localize } from '../../util/Localizer';
import commonUseStyles from '../../styles/CommonStyles';
import StyledTypography from '../../components/Common/StyledTypography/StyledTypography';
import StyledCheckbox from '../../components/Common/StyledCheckbox/StyledCheckbox';

import ContactForm from './ContactForm';

import breakpointsNames from '../../styles/ResponsiveConstants';

const useStyles = makeStyles(theme => ({
    emergencyContent: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3)
    },
    groupLeft: {
        ...commonUseStyles.defaultGroupLeft,
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingRight: 0
        }
    },
    groupCenter: {
        ...commonUseStyles.defaultGroupCenter,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            paddingRight: 0
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingRight: 0,
            paddingLeft: 0
        }
    },
    groupRight: {
        ...commonUseStyles.defaultGroupRight,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            paddingLeft: 0,
            paddingRight: 15
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    headerSection: {
        ...commonUseStyles.headerForm,
        marginTop: -20,
        marginBottom: 20
    },
    groupTitle: {
        marginBottom: -10
    },
    gridRepeatAddress: {
        marginTop: -15
    }
}));

function EmergencyContactInfo({
    currentSibling,
    emailErrors,
    isSameContacts,
    tripModel,
    onUpdateModel: onUpdateEmergencyContact,
    onUpdateEmergencyContactPhone,
    setEmailErrors,
    setIsSameContact
}) {
    const classes = useStyles();

    const handleChangeEmergencyContactInfo = (evt) => {
        if (evt === null) {
            return false;
        }

        setIsSameContact(false);
        onUpdateEmergencyContact(evt);

        return true;
    };

    const handleChangePhoneEmergencyContact = (evt) => {
        if (evt === null) {
            return false;
        }

        onUpdateEmergencyContactPhone(evt);

        return true;
    };

    const handleCopyContact = (evt) => {
        if (evt === null) {
            return false;
        }

        setIsSameContact(evt.target.checked);

        return true;
    };

    const classesHeader = `memberCard ${classes.headerSection}`;

    return (
        <Grid container className={classes.emergencyContent}>
            {currentSibling > 1 &&
				<Grid item xs={12} className={classes.gridRepeatAddress}>
				    <FormControlLabel
				        size="medium"
				        control={<StyledCheckbox
				            color="secondary"
				            isChecked={isSameContacts}
				            name="isSameContacts"
				            size="medium"
				            onChange={handleCopyContact}
				        />}
				        label={localize('tripRequestForm.repeatStudentInformation')}
				    />
				</Grid>
            }
            <Grid
                item
                xs={4}
                sm={12}
                md={6}
                lg={4}
                className={classes.groupLeft}
            >
                <div
                    className={classesHeader}
                >
                    <StyledTypography
                        variant="h5"
                        className={classes.groupTitle}
                    >
                        {localize('tripRequestForm.emergencyContactName1')}
                    </StyledTypography>
                </div>
                <ContactForm
                    altPhoneValue={tripModel.parent1AltPhone}
                    emailAddressValue={tripModel.parent1EmailAddress ?? ""}
                    emailErrorValue={emailErrors.parent1EmailAddress ?? ""}
                    formName="parent1"
                    inputRequired
                    nameValue={tripModel.parent1FirstName}
                    mobilePhoneValue={tripModel.parent1MobilePhone}
                    parentAppAccessValue={tripModel.parent1AppAccess}
                    relationshipValue={tripModel.parent1Relationship}
                    onChangeValue={handleChangeEmergencyContactInfo}
                    onChangePhoneValue={handleChangePhoneEmergencyContact}
                    setEmailErrors={setEmailErrors}
                />
            </Grid>
            <Grid
                item
                xs={4}
                sm={12}
                md={6}
                lg={4}
                className={classes.groupCenter}
            >
                <div
                    className={classesHeader}
                >
                    <StyledTypography
                        variant="h5"
                        className={classes.groupTitle}
                    >
                        {localize('tripRequestForm.emergencyContactName2')}
                    </StyledTypography>
                </div>
                <ContactForm
                    altPhoneValue={tripModel.contact1AltPhone}
                    emailAddressValue={tripModel.contact1EmailAddress}
                    emailErrorValue={emailErrors.contact1EmailAddress}
                    formName="contact1"
                    mobilePhoneValue={tripModel.contact1MobilePhone}
                    nameValue={tripModel.contact1FirstName}
                    parentAppAccessValue={tripModel.contact1AppAccess}
                    relationshipValue={tripModel.contact1Relationship}
                    onChangeValue={handleChangeEmergencyContactInfo}
                    onChangePhoneValue={handleChangePhoneEmergencyContact}
                    setEmailErrors={setEmailErrors}
                />
            </Grid>
            <Grid
                item
                xs={4}
                sm={12}
                md={6}
                lg={4}
                className={classes.groupRight}
            >
                <div
                    className={classesHeader}
                >
                    <StyledTypography
                        variant="h5"
                        className={classes.groupTitle}
                    >
                        {localize('tripRequestForm.emergencyContactName3')}
                    </StyledTypography>
                </div>
                <ContactForm
                    altPhoneValue={tripModel.contact2AltPhone}
                    emailAddressValue={tripModel.contact2EmailAddress}
                    emailErrorValue={emailErrors.contact2EmailAddress}
                    formName="contact2"
                    nameValue={tripModel.contact2FirstName}
                    mobilePhoneValue={tripModel.contact2MobilePhone}
                    parentAppAccessValue={tripModel.contact2AppAccess}
                    relationshipValue={tripModel.contact2Relationship}
                    onChangeValue={handleChangeEmergencyContactInfo}
                    onChangePhoneValue={handleChangePhoneEmergencyContact}
                    setEmailErrors={setEmailErrors}
                />
            </Grid>
            <Grid
                item
                xs={4}
                sm={12}
                md={6}
                lg={4}
                className={classes.groupRight}
            />
        </Grid>
    );
}

export default EmergencyContactInfo;
