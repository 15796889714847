import React from 'react';
import {
    DialogContent,
    DialogActions,
    DialogContentText,
    Grid
} from "@mui/material";
import { styled } from '@mui/material/styles';
import StyledDialogModal from '../../components/Common/StyledDialogModal/StyledDialogModal';
import StyledInput from '../../components/Common/StyledInput/StyledInput';
import { Enum } from '../../util/EnumHelper';

const StyledDialogContent = styled(DialogContent)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    marginTop: 20,
    width: '100%'
}));

const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    paddingLeft: 20,
    paddingRight: 20,
    color: theme.palette.black,
    wordBreak: 'break-word',
    display: 'flex',
    flexDirection: 'column'
}));

const InputContainer = styled(Grid)(() => ({
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 18
}));

const CancelButton = styled(StyledInput)(({ theme }) => ({
    borderRadius: '5px',
    background: theme.palette.everDrivenGrey40,
    color: theme.palette.white,
    padding: '0 5px',
    width: '100%'
}));

const ConfirmButton = styled(StyledInput)(({ theme }) => ({
    borderRadius: '5px',
    background: theme.palette.everDrivenDarkGreen,
    color: theme.palette.white,
    padding: '0 5px',
    width: '100%'
}));

function ModalConfirmation({
    isOpen,
    contentMessage,
    confirmInputLabel,
    cancelInputLabel,
    hideCancelButton,
    disableBackDrop,
    disableEscKey,
    onConfirm,
    onCancel
}) {
    const handleOnCloseModal = (event, reason) => {
        if (reason === Enum.modalCloseReason.backdropClick && disableBackDrop) {
            return null;
        }

        if (reason === Enum.modalCloseReason.escapeKeyDown && disableEscKey) {
            return null;
        }

        if (hideCancelButton) {
            onConfirm();
        } else {
            onCancel();
        }

        return true;
    };

    return (
        <StyledDialogModal
            open={isOpen}
            maxWidth="xs"
            aria-labelledby="confirm-trf-form"
            onClose={(e, r) => handleOnCloseModal(e, r)}
        >
            <StyledDialogContent data-testid="dialogContent">
                <StyledDialogContentText>
                    {contentMessage}
                </StyledDialogContentText>
            </StyledDialogContent>
            <DialogActions sx={{ padding: '10px' }}>
                <Grid container>
                    <InputContainer item xs={6}>
                        {!hideCancelButton &&
                            (<CancelButton
                                id="btnCancel"
                                data-testid="modalConfirmCancelButton"
                                type="button"
                                value={cancelInputLabel}
                                disableUnderline
                                onClick={() => onCancel()}
                            />)
                        }
                    </InputContainer>
                    <InputContainer item xs={6}>
                        <ConfirmButton
                            id="btnConfirm"
                            data-testid="modalConfirmAcceptButton"
                            type="button"
                            disableUnderline
                            value={confirmInputLabel}
                            onClick={() => onConfirm()}
                        />
                    </InputContainer>
                </Grid>
            </DialogActions>
        </StyledDialogModal>
    );
}

export default ModalConfirmation;
