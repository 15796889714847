import {
    STUDENT_SELECTED,
    STUDENT_SELECTED_CODE,
    STUDENT_SELECTED_ID,
    STUDENT_IS_LOADING,
    STUDENT_SELECTED_SCHOOL
} from "redux/actions/districtTripTracker/studentActions";

const initialState = {
    isLoading: true,

    studentCode: '',
    student: '',
    studentId: '',
    schoolName: ''
};

/* eslint-disable-next-line default-param-last */
const studentReducers = (state = initialState, action) => {
    switch (action.type) {
    case STUDENT_SELECTED:
        return {
            ...state,
            student: action.student
        };
    case STUDENT_SELECTED_CODE:
        return {
            ...state,
            studentCode: action.studentCode
        };
    case STUDENT_SELECTED_ID:
        return {
            ...state,
            studentId: action.studentId
        };
    case STUDENT_IS_LOADING:
        return {
            ...state,
            isLoading: action.loading
        };
    case STUDENT_SELECTED_SCHOOL:
        return {
            ...state,
            schoolName: action.schoolName
        };
    default:
        return state;
    }
};

export default studentReducers;
