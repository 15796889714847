export const TRIP_GROUP_SET_TRIPS =
  'TRIP_GROUP_SET_TRIPS';
export const tripGroupSetTrips = (tripType, trips) => ({
    type: TRIP_GROUP_SET_TRIPS,
    tripType,
    trips
});

export const TRIP_GROUP_SET_TOKEN =
  'TRIP_GROUP_SET_TOKEN';
export const tripGroupSetToken = (tripType, token) => ({
    type: TRIP_GROUP_SET_TOKEN,
    tripType,
    token
});

export const TRIP_GROUP_SET_SHOW_LOAD_MORE =
  'TRIP_GROUP_SET_SHOW_LOAD_MORE';
export const tripGroupSetShowLoadMore = (tripType, showLoadMore) => ({
    type: TRIP_GROUP_SET_SHOW_LOAD_MORE,
    tripType,
    showLoadMore
});

export const TRIP_GROUP_CHANGE_FILTER_VALUE =
  'TRIP_GROUP_CHANGE_FILTER_VALUE';
export const tripGroupSetFilter = (filter, filterValue) => ({
    type: TRIP_GROUP_CHANGE_FILTER_VALUE,
    filter,
    filterValue
});

export const TRIP_GROUP_RESET_FILTERS =
  'TRIP_GROUP_RESET_FILTERS';
export const tripGroupResetFilters = () => ({
    type: TRIP_GROUP_RESET_FILTERS
});

export const TRIP_GROUP_SET_IS_FACILITY =
  'TRIP_GROUP_SET_IS_FACILITY';
export const tripGroupSetIsFacility = (isFacility) => ({
    type: TRIP_GROUP_SET_IS_FACILITY,
    isFacility
});

export const TRIP_GROUP_SET_GUID =
  'TRIP_GROUP_SET_GUID';
export const tripGroupSetGuid = (guid) => ({
    type: TRIP_GROUP_SET_GUID,
    guid
});
