import React from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

import { localize } from '../../util/Localizer';
import commonUseStyles from '../../styles/CommonStyles';
import breakpointsNames from '../../styles/ResponsiveConstants';
import StyledButton from '../../components/Common/StyledButton/StyledButton';

const useStyles = makeStyles(theme => ({
    rowContent: {
        ...commonUseStyles.gridContent,
        ...commonUseStyles.defaultResponsive,
        paddingLeft: 0
    },
    reportNameColumn: {
        boxSizing: 'border-box',
        height: 58,
        paddingLeft: 25,
        position: 'relative'
    },
    reportMessageColumn: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        height: 'auto',
        paddingLeft: 25,
        paddingRight: 25,
        position: 'relative',
        color: theme.palette.lightBlueOption1

    },
    overflowNameReport: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    buttonColumn: {
        boxSizing: 'border-box',
        display: 'flex'
    },
    downloadButton: {
        boxShadow: theme.typography.boxShadow,
        color: theme.palette.white,
        marginLeft: 0,
        height: 58,
        width: '100%',
        borderRadius: '0px 6px 6px 0px',
        "&:disabled": {
            backgroundColor: theme.palette.everDrivenGrey40
        },
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            borderRadius: '0px 0px 6px 0px'
        }
    },
    viewButton: {
        boxShadow: theme.typography.boxShadow,
        color: theme.palette.white,
        height: 58,
        width: '100%',
        borderRadius: '0px 0px 0px 0px',
        "&:disabled": {
            backgroundColor: theme.palette.everDrivenGrey40
        },
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            borderRadius: '0px 0px 0px 6px'
        }
    },
    noShowReport: {
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            borderRadius: '0px 0px 6px 6px'
        }
    }
}));

function ReportRow({
    report,
    downloadReport,
    loadReport,
    rowMessage,
    downloadButtonActive,
    viewButtonActive,
    showMessage
}) {
    const classes = useStyles();

    const noShowReport = `${classes.downloadButton} ${!report.url ? classes.noShowReport : null}`;

    const onClickView = () => {
        loadReport(report);
    };

    return (
        <Paper
            id={report.key}
            className={classes.rowContent}
        >
            <Grid
                container
                className="memberCard"
            >
                <Grid
                    item
                    className={classes.reportNameColumn}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                >
                    <div className={classes.overflowNameReport}>
                        <strong>
                            {localize(report.value)}
                        </strong>
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={5}
                >
                    {showMessage && (
                        <div className={classes.reportMessageColumn}>
                            <span>{rowMessage}</span>
                        </div>
                    )}
                </Grid>

                <Grid
                    item
                    className={classes.buttonColumn}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={3}
                >
                    {report.url && (
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                        >
                            <StyledButton
                                className={classes.viewButton}
                                color="alternativeButton"
                                isDisabled={viewButtonActive}
                                testId={`viewReportButton_${report.key}`}
                                onClick={onClickView}
                            >
                                {localize('report.view')}
                            </StyledButton>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={report.url ? 6 : 12}
                        sm={report.url ? 6 : 12}
                        md={report.url ? 6 : 12}
                        lg={report.url ? 6 : 12}
                    >
                        <StyledButton
                            className={noShowReport}
                            color="alternativeButton"
                            isDisabled={downloadButtonActive}
                            testId={`downloadReportButton_${report.key}`}
                            onClick={() => downloadReport(report)}
                        >
                            {localize('report.download')}
                        </StyledButton>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default ReportRow;
