import {
    PAGINATION_RESET_VALUES,
    PAGINATION_SET_ACTIVE_PAGE,
    PAGINATION_SET_LIMIT_ROWS,
    PAGINATION_SET_ROWS_SHOWED
} from 'redux/actions/StyledTablePaginationActions';

const initialState = {
    limitRows: 100,
    activePage: 0,
    rowsShowed: 10
};

/* eslint-disable-next-line default-param-last */
const StyledTablePaginationReducer = (state = initialState, action) => {
    switch (action.type) {
    case PAGINATION_SET_ACTIVE_PAGE:
        return {
            ...state,
            activePage: action.activePage
        };
    case PAGINATION_SET_LIMIT_ROWS:
        return {
            ...state,
            limitRows: action.limitRows
        };
    case PAGINATION_SET_ROWS_SHOWED:
        return {
            ...state,
            rowsShowed: action.rowsShowed
        };
    case PAGINATION_RESET_VALUES:
        return {
            limitRows: 100,
            activePage: 0,
            rowsShowed: 10
        };
    default:
        return state;
    }
};

export default StyledTablePaginationReducer;
