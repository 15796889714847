import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

import PropTypes from 'prop-types';
import { localize } from 'util/Localizer';
import StudentOnBoardingCard from "components/report/StudentOnBoardingCard/StudentOnBoardingCard";
import StyledCardGroupHeader from 'components/Common/StyledCardGroupHeader/StyledCardGroupHeader';
import StyledCardGroupContainer from 'components/Common/StyledCardGroupContainer/StyledCardGroupContainer';
import TripGroupMenu from 'components/trip/TripGroupMenu';
import { optionsFilterStudentOnBoardingGroup as filterOptions} from 'util/EnumHelper';
import commonUseStyles, { commonStyles } from 'styles/CommonStyles';

const CARD_GROUP = {
    waiting : {
        title: "report.waitingOnClientResponse"
    },
    onHold : {
        title: "report.onHold"
    },
    inProgress : {
        title: "report.inProgress"
    },
    active : {
        title: "report.active"
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        padding: '16px 0'
    },
    cardGroup: {
        ...commonUseStyles.defaultResponsive,
        clear: 'both',
        padding: '0'
    },
    button: {
        ...commonStyles(theme).groupCardFilterButton,
        marginLeft: 'auto'
    },
    groupedContainer: {
        '&:not(:last-child)': {
            borderBottom: `4px ${theme.palette.everDrivenLightBlue80} solid`
        }
    },
    tripCard: {
        padding: 8
    },
    divScroll: {
        marginTop: -70,
        position: 'absolute'
    }
}));

function StudentOnBoardingGroup({
    cardElements,
    groupType,
    showGroupBtn,
    changeFilter,
    filter,
    changeGrouping,
    groupBySchool,
    filterList,
    onHandleStudentSearch,
    getRunsheet
}) {
    const classes = useStyles();

    const { title } = CARD_GROUP[groupType];

    const [ openGroup, setOpenGroup ] = useState(true);
    const [ groupedSchoolIds, setGroupedSchoolIds ] = useState([]);
    const [ filteredCards, setFilteredCards ] = useState([]);

    const filterSchools = (school, filterCards) => (filterCards).filter(card => card.School === school);

    const filterCards = filterCard => {
        if(filterCard && groupType) {
            // if filter is all cards
            if (filterCard === 'AllCards') {
                return cardElements;
            }

            // if filter is turned on
            return (cardElements || []).filter(element => element.Status === filterCard);
        }

        // return all when no filter is active
        return cardElements;
    };

    const renderCardElement = (card, elementId) => {
        if (card.StudentID || card.StudentName) {
            return (
                <Grid item xs={12} sm={6} md={4} lg={3}
                    className={classes.tripCard}
                    data-testid={`student-card-${groupType}`}
                    key={`${card.StudentID}-${elementId}`}>
                    <StudentOnBoardingCard
                        card={card}
                        id={elementId}
                        groupType={groupType}
                        onHandleStudentSearch={onHandleStudentSearch}
                        getRunsheet={getRunsheet}
                    />
                </Grid>
            );
        }

        return null;
    };

    const handleClick = (value) => changeFilter(groupType, value);

    useEffect(() => {
	    setFilteredCards(filterCards(filter));
    },
    [ filter, cardElements ]);

    useEffect(() => {
        const groups = [];

        cardElements.forEach(card => {
            if (!groups.includes(card.School)) {
                groups.push(card.School);
            }
        });

        setGroupedSchoolIds(groups);
    },
    [ groupBySchool, cardElements ]);

    return (
        <>
            <div id={`divGroup_${groupType}`} className={classes.divScroll} />
            <div className={classes.root}>
                <StyledCardGroupHeader
                    elements={cardElements}
                    grouped={groupBySchool}
                    groupLabel={localize("report.groupBySchool")}
                    groupTitle={localize(title)}
                    groupType={groupType}
                    hasMenuFilter={groupType === filterOptions[0].key}
                    showGroupButton={showGroupBtn}
                    data-testid="group-header"
                    onClickDisplayButton={() => setOpenGroup(!openGroup)}
                    onClickGroupButton={() => changeGrouping(groupType, !groupBySchool)}
                >
                    {groupType === filterOptions[0].key &&
                        <TripGroupMenu
                            defaultItem={{
                                key: 'AllCards',
                                value: `${localize('report.allCards')} (${cardElements.length})`
                            }}
                            filterOptions={filterList}
                            filterTrips={filterCards}
                            fullSize
                            iconClass={classes.button}
                            id={groupType}
                            filterChange={handleClick}
                        />
                    }
                </StyledCardGroupHeader>
                <StyledCardGroupContainer
                    grouped={groupBySchool}
                    groupLabel={localize("report.groupBySchool")}
                    isOpenPanel={openGroup}
                    groupType={groupType}
                    data-testid="group-container"
                    showGroupButton={showGroupBtn}
                    onClickGroupButton={() => changeGrouping(groupType, !groupBySchool)}
                >
                    { groupBySchool ? (
                        groupedSchoolIds.map(school => (
                            (filterSchools(
                                school,
                                filteredCards
                            ).length > 0) && (
                                <Grid key={school}
                                    container
                                    data-testid={`group-by-school-${groupType}`}
                                    className={`${classes.cardGroup} ${classes.groupedContainer}`} >
                                    {filterSchools(school, filteredCards).map(
                                        (card, index) => {
                                            const elementId = `card_${index + 1}`;

                                            return renderCardElement(card, elementId);
                                        }
                                    )}
                                </Grid>
                            )
                        ))
                    ) : (
                        <Grid container className={classes.cardGroup} data-testid={`no-group-${groupType}`}>
                            {filteredCards.length > 0 && filteredCards.map((card, index) => {
                                const elementId = `card_${index + 1}`;

                                return renderCardElement(card,
                                    elementId);
                            })}
                        </Grid>
                    )}
                </StyledCardGroupContainer>
            </div>
        </>
    );
}

StudentOnBoardingGroup.defaultProps = {
    cardElements: [],
    groupType: 'onHold',
    showGroupBtn: false,
    changeFilter: () => {},
    changeGrouping: () => {},
    groupBySchool: false,
    filter: 'AllCards',
    filterList: [],
    onHandleStudentSearch: () => {},
    getRunsheet: () => {}
};

/* eslint-disable react/forbid-prop-types */
StudentOnBoardingGroup.propTypes = {
    cardElements: PropTypes.array,
    groupType: PropTypes.string,
    showGroupBtn: PropTypes.bool,
    changeFilter: PropTypes.func,
    changeGrouping: PropTypes.func,
    groupBySchool: PropTypes.bool,
    filter: PropTypes.string,
    filterList: PropTypes.array,
    onHandleStudentSearch: PropTypes.func,
    getRunsheet: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default injectIntl(StudentOnBoardingGroup);
