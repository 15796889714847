import React from 'react';
import { TableBody } from '@mui/material';
import { node } from 'prop-types';

function StyledTableBody ({ children }) {
    return (
        <TableBody>
            {children}
        </TableBody>
    );
}

StyledTableBody.defaultProps = {
    children: null
};

StyledTableBody.propTypes = {
    children: node
};

export default StyledTableBody;