/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import {
    object,
    bool,
    func,
    array,
    number,
    string
} from 'prop-types';
import { Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import Auth from 'auth/Auth';
import { localize } from 'util/Localizer';
import breakpointsNames from 'styles/ResponsiveConstants';
import FilterOptions from 'controls/User/FilterOptions/FilterOptions';
import UserRowHeader from 'controls/User/UserRowHeader';
import FilterSearchBar from 'controls/Common/FilterSearchBar';
import useWidth from 'util/customHooks/useWidth';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import StyledHeaderContent from 'components/Common/StyledHeaderContent/StyledHeaderContent';
import TripGroupMenu from 'components/trip/TripGroupMenu';

import { Enum } from 'util/EnumHelper';
import colorConstants from 'styles/ColorConstants';

const AddButton = styled(StyledButton)(({ theme }) => ({
    background: theme.palette.primary.main,
    height: 60,
    borderRadius: '6px 0px 0px 6px',
    boxShadow: theme.typography.boxShadow
}));

const ItemButton = styled('div')(() => ({
    maxWidth: 48,
    marginRight: 15
}));

const ResultHeader = styled(Grid)(({ theme }) => ({
    float: 'left',
    width: '100%',
    [theme.breakpoints.down(breakpointsNames.md)]: {
        paddingTop: 20
    }
}));

const RightFilterContainer = styled('div')(({ theme }) => ({
    [theme.breakpoints.up(breakpointsNames.md)]: {
        display: 'none'
    }
}));

function UserHeader({
    checkedAll,
    columns,
    filterOption,
    filterOptions,
    isLoadingUser,
    isModalMobile,
    term,
    userList,
    filterUsers: filterSearch,
    handleChangeCheckAll,
    onShowDeleteModal,
    openUserForm,
    setFilterOptions,
    setUserOptionFilter,
    setUserTermFilter,
    sortUsersByColumn: onSorter
}) {
    const width = useWidth();

    const teacherAppEnabled = Auth.getConfig().teacherAppEnabled !== false ?
        true : Auth.getConfig().teacherAppEnabled;

    const handleChange = (selectedOption) => {
        const updatedOptions = filterOptions.map(option => ({
            ...option,
            variant: option.id === selectedOption.id ? 'contained' : 'text',
            color: option.id === selectedOption.id ? 'primary' : 'inherit'
        }));

        setFilterOptions(updatedOptions);
        setUserOptionFilter(selectedOption);
    };

    const handleOpen = () => {
        openUserForm(width);
    };

    const setCheckAll = (event) => {
        handleChangeCheckAll(event.target.checked);
    };

    const renderFilters = () => (
        <FilterOptions
            listOptions={filterOptions}
            handleChange={handleChange}
            onShowDeleteModal={onShowDeleteModal}
        />
    );

    const renderHeaders = () => (
        userList.length > 0 && (
            <UserRowHeader
                checkedAll={checkedAll}
                handleChangeCheckAll={setCheckAll}
                columns={columns}
                onSorter={onSorter}
            />
        )
    );

    const renderLeftControl = () => (
        <ItemButton className="item">
            <AddButton
                testId="addNewUserButton"
                onClick={handleOpen}
            >
                <Add fontSize="large" />
            </AddButton>
        </ItemButton>
    );

    const renderRightFilters = () => (
        <RightFilterContainer>
            <TripGroupMenu
                filterOptions={filterOptions}
                id="UserFilter"
                filterChange={handleChange}
                iconStyle={{ color: colorConstants.BLACK }}
            />
        </RightFilterContainer>
    );

    useEffect(() =>{
        if (!teacherAppEnabled) {
            const updatedOptions = filterOptions;

            updatedOptions[Enum.filterUsers.VIP].hidden = true;

            setFilterOptions(updatedOptions);
        }
    }, []);

    useEffect(() => {
        if(!isLoadingUser) {
            filterSearch();
        }
    }, [ columns, term, filterOption, isLoadingUser ]);

    return (
        <StyledHeaderContent
            isUserHeader
            idContainer="userManagerHeaderSearch"
            isBGTransparent={!(userList.length > 0 || isModalMobile)}
            title={localize('userManagement.title')}
        >
            <>
                <Grid item xs={12}>
                    <FilterSearchBar
                        leftControlFilter={renderLeftControl()}
                        filterLabel={localize('userManagement.findUser')}
                        filterValue={term}
                        onChangeFilter={setUserTermFilter}
                        rightControlFilter={renderRightFilters()}
                    />
                </Grid>
                <Grid item xs={12}>
                    {renderFilters()}
                </Grid>
                <ResultHeader item xs={12}>
                    {renderHeaders()}
                </ResultHeader>
            </>
        </StyledHeaderContent>
    );
}

UserHeader.defaultProps = {
    checkedAll: false,
    columns: null,
    filterOption: 0,
    filterOptions: [],
    isLoadingUser: false,
    isModalMobile: false,
    term: '',
    userList: [],
    filterUsers: () => null,
    handleChangeCheckAll: () => null,
    onShowDeleteModal: () => null,
    openUserForm: () => null,
    setFilterOptions: () => null,
    setUserOptionFilter: () => null,
    setUserTermFilter: () => null,
    sortUsersByColumn: () => null
};

UserHeader.propTypes = {
    checkedAll: bool,
    columns: object,
    filterOption: number,
    filterOptions: array,
    isLoadingUser: bool,
    isModalMobile: bool,
    term: string,
    userList: array,
    filterUsers: func,
    handleChangeCheckAll: func,
    onShowDeleteModal: func,
    openUserForm: func,
    setFilterOptions: func,
    setUserOptionFilter: func,
    setUserTermFilter: func,
    sortUsersByColumn: func
};

export default UserHeader;
