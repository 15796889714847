/* eslint max-lines: 0 */
import _ from 'lodash';
import {
    formatPlaceHolderPhoneNumber,
    scheduleValidModel
} from "data/models/tripRequestForm/TripRequestFormModel";
import { saveLocationPreferences } from "../../util/preferenceUtil";
import {
    formatPhoneNumber,
    objectEmptyValidator
} from "../../util/validationUtil";
import {
    setEnabledSchoolInput,
    setIsSameSchoolCheck,
    setSchedule,
    setScheduleValid,
    setSchoolDays,
    setSelectedSchool,
    setSelectedSchoolAddress,
    setSelectedSchoolAddress2,
    updateModel
} from "../actions/tripRequestFormActions";
// eslint-disable-next-line import/no-cycle
import { isNewCorrect } from "./tripRequestFormWorker";

const cleanInputsByID = (arrival, pickUp) => {
    document.getElementById(arrival).value = '';
    document.getElementById(pickUp).value = '';
};

export const setIsSameSchool = (checked) => (dispatch, getState) => {
    const { tripModels } = getState().TripRequestFormReducers;

    dispatch(setIsSameSchoolCheck(checked));
    if (checked) {
        const studentOne = tripModels.find(x => x.student === 1);
        const { tripModel, selectedSchool, selectedSchoolAddress, selectedSchoolAddress2 } = studentOne;
        const enabledInputs = {
            addressCity: false,
            addressZipCode: false,
            addressState: false,
            schoolPhone: false,
            schoolName: false
        };

        dispatch(updateModel({
            schoolName: tripModel.schoolName ?? '',
            schoolAddressLine1: tripModel.schoolAddressLine1 ?? '',
            schoolAddressLine2: tripModel.schoolAddressLine2 ?? '',
            schoolAddressCity: tripModel.schoolAddressCity ?? '',
            schoolAddressZipCode: tripModel.schoolAddressZipCode ?? '',
            schoolAddressState: tripModel.schoolAddressState ?? '',
            schoolPhone: tripModel.schoolPhone ?? '',
            schoolMonday: tripModel.schoolMonday ?? false,
            schoolTuesday: tripModel.schoolTuesday ?? false,
            schoolWednesday: tripModel.schoolWednesday ?? false,
            schoolThursday: tripModel.schoolThursday ?? false,
            schoolFriday: tripModel.schoolFriday ?? false,
            schoolSaturday: tripModel.schoolSaturday ?? false,
            schoolSunday: tripModel.schoolSunday ?? false,
            schoolArrivalMonday: tripModel.schoolArrivalMonday ?? null,
            schoolArrivalTuesday: tripModel.schoolArrivalTuesday ?? null,
            schoolArrivalWednesday: tripModel.schoolArrivalWednesday ?? null,
            schoolArrivalThursday: tripModel.schoolArrivalThursday ?? null,
            schoolArrivalFriday: tripModel.schoolArrivalFriday ?? null,
            schoolArrivalSaturday: tripModel.schoolArrivalSaturday ?? null,
            schoolArrivalSunday: tripModel.schoolArrivalSunday ?? null,
            schoolPickupMonday: tripModel.schoolPickupMonday ?? null,
            schoolPickupTuesday: tripModel.schoolPickupTuesday ?? null,
            schoolPickupWednesday: tripModel.schoolPickupWednesday ?? null,
            schoolPickupThursday: tripModel.schoolPickupThursday ?? null,
            schoolPickupFriday: tripModel.schoolPickupFriday ?? null,
            schoolPickupSaturday: tripModel.schoolPickupSaturday ?? null,
            schoolPickupSunday: tripModel.schoolPickupSunday ?? null,
            schoolArrivalInstructions: tripModel.schoolArrivalInstructions ?? '',
            schoolAddressLatitude: tripModel.schoolAddressLatitude ?? '',
            schoolAddressLongitude: tripModel.schoolAddressLongitude ?? ''
        }));

        if (selectedSchool && !objectEmptyValidator(selectedSchool)) {
            if (tripModel.schoolPhone) {
                if (formatPlaceHolderPhoneNumber !== tripModel.schoolPhone) {
                    enabledInputs.schoolPhone = true;
                }
            }

            if (tripModel.schoolName) {
                enabledInputs.schoolName = true;
            }
            if (tripModel.schoolAddressCity) {
                enabledInputs.addressCity = true;
            }
            if (tripModel.schoolAddressZipCode) {
                enabledInputs.addressZipCode = true;
            }
            if (tripModel.schoolAddressState) {
                enabledInputs.addressState = true;
            }
        }

        dispatch(setSelectedSchool(selectedSchool));
        dispatch(setSelectedSchoolAddress(selectedSchoolAddress));
        dispatch(setSelectedSchoolAddress2(selectedSchoolAddress2));
        dispatch(setSchoolDays(studentOne.schoolDays));
        dispatch(setSchedule(studentOne.schedule));
        dispatch(setEnabledSchoolInput(enabledInputs));
        dispatch(isNewCorrect());
    }
};

export const onSelectSchool = (item) => (dispatch, getState) => {
    const { selectedSchool } = getState().TripRequestFormReducers;

    if (!_.isEqual(selectedSchool, item.selectedSchool)) {
        dispatch(setIsSameSchool(false));
    }

    dispatch(setSelectedSchool(item.selectedSchool));
    dispatch(setSelectedSchoolAddress({}));
    dispatch(setSelectedSchoolAddress2({}));

    let { phoneNumber } = item;
    const enabledInputs = {
        addressCity: false,
        addressZipCode: false,
        addressState: false,
        schoolPhone: false,
        schoolName: false
    };

    if (item && item.id) {
        saveLocationPreferences(item);
    }

    if (item.phoneNumber) {
        if (item.phoneNumber !== formatPlaceHolderPhoneNumber) {
            phoneNumber = formatPhoneNumber(item.phoneNumber);
            enabledInputs.schoolPhone = true;
        } else {
            phoneNumber = '';
        }
    }

    dispatch(updateModel({
        schoolName: item.name,
        schoolAddressLine1: item.address1,
        schoolAddressLine2: item.address2 || '',
        schoolAddressCity: item.city,
        schoolAddressZipCode: item.zip,
        schoolAddressState: item.state,
        schoolAddressLatitude: item.latitude,
        schoolAddressLongitude: item.longitude,
        schoolPhone: phoneNumber
    }));

    if (item.name) {
        enabledInputs.schoolName = true;
    }
    if (item.city) {
        enabledInputs.addressCity = true;
    }
    if (item.zip) {
        enabledInputs.addressZipCode = true;
    }
    if (item.state) {
        enabledInputs.addressState = true;
    }

    dispatch(setEnabledSchoolInput(enabledInputs));
    dispatch(isNewCorrect());
};

export const onSelectSchoolAddressInfo = (item) => (dispatch, getState) => {
    const { selectedSchoolAddress } = getState().TripRequestFormReducers;

    if (objectEmptyValidator(item.selectedAddress)) {
        dispatch(setIsSameSchool(false));
        dispatch(setSelectedSchoolAddress({}));
        dispatch(updateModel({
            schoolAddressLine1: '',
            schoolAddressCity: '',
            schoolAddressZipCode: '',
            schoolAddressState: '',
            schoolAddressLatitude: '',
            schoolAddressLongitude: ''
        }));

        return false;
    }
    
    if (!_.isEqual(selectedSchoolAddress, item.selectedAddress)) {
        dispatch(setIsSameSchool(false));
        dispatch(setSelectedSchoolAddress(item.selectedAddress));
        dispatch(updateModel({
            schoolAddressLine1: item.streetAddress,
            schoolAddressCity: item.city,
            schoolAddressZipCode: item.zipCode,
            schoolAddressState: item.state,
            schoolAddressLatitude: item.latitude,
            schoolAddressLongitude: item.longitude
        }));
    }

    dispatch(isNewCorrect());

    return true;
};

export const onSelectSchoolAddressLine2Info = (item) => (dispatch, getState) => {
    const { selectedSchoolAddress2 } = getState().TripRequestFormReducers;

    if (objectEmptyValidator(item.selectedAddress)) {
        dispatch(setIsSameSchool(false));
        dispatch(setSelectedSchoolAddress2(item.selectedAddress));
        dispatch(updateModel({
            schoolAddressLine2: '',
            schoolAddressLine2Latitude: '',
            schoolAddressLine2Longitude: ''
        }));

        return false;
    }

    if (!_.isEqual(selectedSchoolAddress2, item.selectedAddress)) {
        dispatch(setIsSameSchool(false));
        dispatch(setSelectedSchoolAddress2(item.selectedAddress));
        dispatch(updateModel({
            schoolAddressLine2: item.streetAddress,
            schoolAddressLine2Latitude: item.latitude,
            schoolAddressLine2Longitude: item.longitude
        }));
    }

    dispatch(isNewCorrect());

    return true;
};

export const onUpdateSchoolDays = (evt) => (dispatch) => {
    const { name } = evt.target;
    const value = evt.target.checked;

    dispatch(setSchoolDays({
        [name]: value
    }));

    switch (name) {
    case 'schoolMonday':
        dispatch(updateModel({
            schoolMonday: value,
            schoolArrivalMonday: null,
            schoolPickupMonday: null
        }));
        dispatch(setSchedule({
            schoolArrivalMonday: "",
            schoolPickupMonday: ""
        }));
        dispatch(setScheduleValid(({
            schoolArrivalMonday: true,
            schoolPickupMonday: true
        })));
        cleanInputsByID('schoolArrivalMonday',
            'schoolPickupMonday');
        break;
    case 'schoolTuesday':
        dispatch(updateModel({
            schoolTuesday: value,
            schoolArrivalTuesday: null,
            schoolPickupTuesday: null
        }));
        dispatch(setSchedule({
            schoolArrivalTuesday: "",
            schoolPickupTuesday: ""
        }));
        dispatch(setScheduleValid(({
            schoolArrivalTuesday: true,
            schoolPickupTuesday: true
        })));
        cleanInputsByID('schoolArrivalTuesday',
            'schoolPickupTuesday');
        break;
    case 'schoolWednesday':
        dispatch(updateModel({
            schoolWednesday: value,
            schoolArrivalWednesday: null,
            schoolPickupWednesday: null
        }));
        dispatch(setSchedule({
            schoolArrivalWednesday: "",
            schoolPickupWednesday: ""
        }));
        dispatch(setScheduleValid(({
            schoolArrivalWednesday: true,
            schoolPickupWednesday: true
        })));
        cleanInputsByID('schoolArrivalWednesday',
            'schoolPickupWednesday');
        break;
    case 'schoolThursday':
        dispatch(updateModel({
            schoolThursday: value,
            schoolArrivalThursday: null,
            schoolPickupThursday: null
        }));
        dispatch(setSchedule({
            schoolArrivalThursday: "",
            schoolPickupThursday: ""
        }));
        dispatch(setScheduleValid(({
            schoolArrivalThursday: true,
            schoolPickupThursday: true
        })));
        cleanInputsByID('schoolArrivalThursday',
            'schoolPickupThursday');
        break;
    case 'schoolFriday':
        dispatch(updateModel({
            schoolFriday: value,
            schoolArrivalFriday: null,
            schoolPickupFriday: null
        }));
        dispatch(setSchedule({
            schoolArrivalFriday: "",
            schoolPickupFriday: ""
        }));
        dispatch(setScheduleValid(({
            schoolArrivalFriday: true,
            schoolPickupFriday: true
        })));
        cleanInputsByID('schoolArrivalFriday',
            'schoolPickupFriday');
        break;
    case 'schoolSaturday':
        dispatch(updateModel({
            schoolSaturday: value,
            schoolArrivalSaturday: null,
            schoolPickupSaturday: null
        }));
        dispatch(setSchedule({
            schoolArrivalSaturday: "",
            schoolPickupSaturday: ""
        }));
        dispatch(setScheduleValid(({
            schoolArrivalSaturday: true,
            schoolPickupSaturday: true
        })));
        cleanInputsByID('schoolArrivalSaturday',
            'schoolPickupSaturday');
        break;
    case 'schoolSunday':
        dispatch(updateModel({
            schoolSunday: value,
            schoolArrivalSunday: null,
            schoolPickupSunday: null
        }));
        dispatch(setSchedule({
            schoolArrivalSunday: "",
            schoolPickupSunday: ""
        }));
        dispatch(setScheduleValid(({
            schoolArrivalSunday: true,
            schoolPickupSunday: true
        })));
        cleanInputsByID('schoolArrivalSunday',
            'schoolPickupSunday');
        break;
    default:
        break;
    }

    dispatch(isNewCorrect());
};

export const onUpdateSchoolAddressLine1 = (fieldValue) => (dispatch) => {
    dispatch(updateModel({
        schoolAddressLine1: fieldValue
    }));

    dispatch(isNewCorrect());
};

export const onUpdateSchoolAddressLine2 = (fieldValue) => (dispatch) => {
    dispatch(updateModel({
        schoolAddressLine2: fieldValue
    }));

    dispatch(isNewCorrect());
};

export const onUpdateSchedule = (evt, name) => dispatch => {
    dispatch(updateModel({
        [name]: evt.target.valueAsDate
    }));
    dispatch(setSchedule({
        [name]: evt.target.value
    }));

    dispatch(isNewCorrect());
};

export const onCopyTime = (arrivalName, pickUpName) => (dispatch, getState) => {
    const { tripModel, schedule } = getState().TripRequestFormReducers;
    const arrivalFullTime = tripModel[arrivalName];
    const pickUpFullTime = tripModel[pickUpName];
    const arrivalTime = schedule[arrivalName];
    const pickUpTime = schedule[pickUpName];

    dispatch(updateModel({
        schoolArrivalMonday: tripModel.schoolMonday ? arrivalFullTime : null,
        schoolPickupMonday: tripModel.schoolMonday ? pickUpFullTime : null,
        schoolArrivalTuesday: tripModel.schoolTuesday ? arrivalFullTime : null,
        schoolPickupTuesday: tripModel.schoolTuesday ? pickUpFullTime : null,
        schoolArrivalWednesday: tripModel.schoolWednesday ? arrivalFullTime : null,
        schoolPickupWednesday: tripModel.schoolWednesday ? pickUpFullTime : null,
        schoolArrivalThursday: tripModel.schoolThursday ? arrivalFullTime : null,
        schoolPickupThursday: tripModel.schoolThursday ? pickUpFullTime : null,
        schoolArrivalFriday: tripModel.schoolFriday ? arrivalFullTime : null,
        schoolPickupFriday: tripModel.schoolFriday ? pickUpFullTime : null,
        schoolArrivalSaturday: tripModel.schoolSaturday ? arrivalFullTime : null,
        schoolPickupSaturday: tripModel.schoolSaturday ? pickUpFullTime : null,
        schoolArrivalSunday: tripModel.schoolSunday ? arrivalFullTime : null,
        schoolPickupSunday: tripModel.schoolSunday ? pickUpFullTime : null
    }));

    dispatch(setSchedule({
        schoolArrivalMonday: tripModel.schoolMonday ? arrivalTime : "",
        schoolPickupMonday: tripModel.schoolMonday ? pickUpTime : "",
        schoolArrivalTuesday: tripModel.schoolTuesday ? arrivalTime : "",
        schoolPickupTuesday: tripModel.schoolTuesday ? pickUpTime : "",
        schoolArrivalWednesday: tripModel.schoolWednesday ? arrivalTime : "",
        schoolPickupWednesday: tripModel.schoolWednesday ? pickUpTime : "",
        schoolArrivalThursday: tripModel.schoolThursday ? arrivalTime : "",
        schoolPickupThursday: tripModel.schoolThursday ? pickUpTime : "",
        schoolArrivalFriday: tripModel.schoolFriday ? arrivalTime : "",
        schoolPickupFriday: tripModel.schoolFriday ? pickUpTime : "",
        schoolArrivalSaturday: tripModel.schoolSaturday ? arrivalTime : "",
        schoolPickupSaturday: tripModel.schoolSaturday ? pickUpTime : "",
        schoolArrivalSunday: tripModel.schoolSunday ? arrivalTime : "",
        schoolPickupSunday: tripModel.schoolSunday ? pickUpTime : ""
    }));

    if (arrivalTime && pickUpTime) {
        dispatch(setScheduleValid(scheduleValidModel));
    } else if (!arrivalTime && pickUpTime) {
        const {
            schoolArrivalMonday,
            schoolArrivalTuesday,
            schoolArrivalWednesday,
            schoolArrivalThursday,
            schoolArrivalFriday,
            schoolArrivalSaturday,
            schoolArrivalSunday,
            ...scheduleValidPickUp
        } = scheduleValidModel;
        
        dispatch(setScheduleValid(scheduleValidPickUp));
    } else if (arrivalTime && !pickUpTime) {
        const {
            schoolPickupMonday,
            schoolPickupTuesday,
            schoolPickupWednesday,
            schoolPickupThursday,
            schoolPickupFriday,
            schoolPickupSaturday,
            schoolPickupSunday,
            ...scheduleValidArrival
        } = scheduleValidModel;
        
        dispatch(setScheduleValid(scheduleValidArrival));
    }

    dispatch(isNewCorrect());
};

export const onUpdateSchoolPhone = (evt) => (dispatch, getState) => {
    const { tripModel } = getState().TripRequestFormReducers;

    dispatch(updateModel({
        schoolPhone: evt.target.value
    }));

    if (!tripModel.schoolPhone || tripModel.schoolPhone !== evt.target.value) {
        dispatch(setIsSameSchoolCheck(false));
    }

    dispatch(isNewCorrect());
};
