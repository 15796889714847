export const CALENDAR_IS_LOADING = 'CALENDAR_IS_LOADING';
export const calendarIsLoading = loading => ({
    type: CALENDAR_IS_LOADING,
    loading
});

export const CALENDAR_SELECTED = "CALENDAR_SELECTED";
export const calendarSelected = (calendar) => ({
    type: CALENDAR_SELECTED,
    calendar
});
