import { connect } from 'react-redux';
import {
    onUpdateModel
} from 'redux/workers/transportationRequest/transportationRequestWorker';
import { onUpdateStudentBirthDate } from 'redux/workers/transportationRequest/transportationRequestStudentWorker';
import { setHasGrade } from 'redux/actions/transportationRequest/transportationRequestActions';
import BasicInformation from 'components/TransportationRequest/BasicInformation/BasicInformation';

const mapStateToProps = state => {
    const {
        TransportationRequestReducers,
        TransportationRequestErrorsReducers
    } = state;

    return {
        hasGrade: TransportationRequestReducers.hasGrade,
        studentBirthDateErrorMessage: TransportationRequestReducers.studentBirthDateErrorMessage,
        tripModel: TransportationRequestReducers.tripModel,
        errors: TransportationRequestErrorsReducers.studentErrors
    };
};

const BasicInformationContainer = connect(mapStateToProps,
    {
        onUpdateModel,
        onUpdateStudentBirthDate,
        setHasGrade
    })(BasicInformation);

export default BasicInformationContainer;
