import React from 'react';
import { Grid, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { requestTypeDto } from 'data/models/tripRequestForm/TripRequestFormModel';
import { localize } from '../../util/Localizer';
import commonUseStyles from '../../styles/CommonStyles';
import breakpointsNames from '../../styles/ResponsiveConstants';
import StyledTypography from '../../components/Common/StyledTypography/StyledTypography';
import StyledCheckbox from '../../components/Common/StyledCheckbox/StyledCheckbox';

import AddressForm from './AddressForm';
import AddressSection from './AddressSection';

const useStyles = makeStyles(theme => ({
    mainContent: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3)
    },
    groupLeft: {
        ...commonUseStyles.defaultGroupLeft,
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingRight: 0
        }
    },
    groupCenter: {
        ...commonUseStyles.defaultGroupCenter,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            paddingRight: 0
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingRight: 0,
            paddingLeft: 0
        }
    },
    groupRight: {
        ...commonUseStyles.defaultGroupRight,
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            paddingLeft: 0,
            paddingRight: 15
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    groupInsideLeft: {
        ...commonUseStyles.defaultGroupInsideLeft
    },
    groupInsideRight: {
        ...commonUseStyles.defaultGroupInsideRight
    },
    headerSection: {
        ...commonUseStyles.headerForm,
        marginTop: -20,
        marginBottom: 20
    },
    groupTitle: {
        marginBottom: -10
    },
    formLabel: {
        ...commonUseStyles.customFormLabel
    },
    gridRepeatAddress: {
        marginTop: -15
    },
    paragraphMargin: {
        ...commonUseStyles.paragraphDefaultMargin
    }
}));

function AddressInformation({
    currentSibling,
    isSameAddress,
    nonSchoolAddressList,
    selectedHomeAddress,
    selectedHomeAddress2,
    selectedPickUpAddress,
    selectedPickUpAddress2,
    selectedDropOffAddress,
    selectedDropOffAddress2,
    selectedPreviousHomeAddress,
    selectedPreviousDropOffAddress,
    selectedPreviousPickUpAddress,
    showDifferentAddress,
    tripModel,
    onSelectPreviousHomeAddress,
    onSelectPreviousPickUpAddress,
    onSelectPreviousDropOffAddress,
    onUpdateModel: onUpdateAddress,
    onUpdateLocation,
    onUpdateHomeAddress,
    onUpdateHomeAddress2,
    onUpdatePickUpAddress,
    onUpdatePickUpAddress2,
    onUpdateDropOffAddress,
    onUpdateDropOffAddress2,
    setIsSameAddress,
    updateModel: setAddress
}) {
    const classes = useStyles();

    const handleChangeAddress = (evt) => {
        if (evt === null) {
            return false;
        }

        onUpdateAddress(evt);

        return true;
    };

    const onSelectAddressInfo = (item) => {
        onUpdateHomeAddress(item);
    };

    const onSelectAddressLine2Info = (item) => {
        onUpdateHomeAddress2(item);
    };

    const onChangeStudentAdderssLine1 = (fieldValue) => {
        setIsSameAddress(false);
        setAddress({
            studentAddressLine1: fieldValue
        });
    };

    const onChangeStudentAdderssLine2 = (fieldValue) => {
        setIsSameAddress(false);
        setAddress({
            studentAddressLine2: fieldValue
        });
    };

    const handleCopyAddress = (evt) => {
        if (evt === null) {
            return;
        }
        setIsSameAddress(evt.target.checked);
    };

    const classesHeader = `memberCard ${classes.headerSection}`;

    const isHomeAddressRequired =
        tripModel.requestType !== requestTypeDto.update && (
            tripModel.amPickupLocation === "Home Address" ||
            tripModel.pmDropOffLocation === "Home Address"
        );

    return (
        <Grid container className={classes.mainContent}>
            {currentSibling > 1 &&
                <Grid item xs={12} className={classes.gridRepeatAddress}>
                    <FormControlLabel
                        size="medium"
                        control={<StyledCheckbox
                            color="secondary"
                            isChecked={isSameAddress}
                            name="isSameAddress"
                            size="medium"
                            onChange={handleCopyAddress}
                        />}
                        label={localize('tripRequestForm.repeatStudentInformation')}
                    />
                </Grid>
            }
            <Grid item xs={4} sm={12} md={6} lg={4} className={classes.groupLeft}>
                <div className={classesHeader}>
                    <StyledTypography
                        variant="h5"
                        className={classes.groupTitle}
                    >
                        {localize('tripRequestForm.homeAddress')}
                    </StyledTypography>
                </div>
                <AddressForm
                    isRequired={isHomeAddressRequired}
                    addressLine1Id="studentAddressLine1"
                    addressLine2Id="studentAddressLine2"
                    cityId="studentAddressCity"
                    zipCodeId="studentAddressZipCode"
                    stateId="studentAddressState"
                    onSelectAddressInfoLine1={onSelectAddressInfo}
                    onChangeInputAddressLine1={onChangeStudentAdderssLine1}
                    onSelectAddressInfoLine2={onSelectAddressLine2Info}
                    onChangeInputAddressLine2={onChangeStudentAdderssLine2}
                    onInputChange={handleChangeAddress}
                    addressLine1Value={tripModel.studentAddressLine1}
                    defaultaddressLine1Value={selectedHomeAddress}
                    addressLine2Value={tripModel.studentAddressLine2}
                    defaultaddressLine2Value={selectedHomeAddress2}
                    cityValue={tripModel.studentAddressCity}
                    zipCodeValue={tripModel.studentAddressZipCode}
                    stateValue={tripModel.studentAddressState}
                    previousAddressList={nonSchoolAddressList}
                    selectedPreviousAddress={selectedPreviousHomeAddress}
                    onSelectPreviousAddress={(evt) => onSelectPreviousHomeAddress(evt)}
                />
            </Grid>
            <AddressSection
                tripModel={tripModel}
                onUpdateLocation={onUpdateLocation}
                setAddressInformation={setAddress}
                onChangeAddress={handleChangeAddress}
                onUpdatePickUpAddress={onUpdatePickUpAddress}
                onUpdatePickUpAddress2={onUpdatePickUpAddress2}
                onUpdateDropOffAddress={onUpdateDropOffAddress}
                onUpdateDropOffAddress2={onUpdateDropOffAddress2}
                selectedPickUpAddress={selectedPickUpAddress}
                selectedPickUpAddress2={selectedPickUpAddress2}
                selectedDropOffAddress={selectedDropOffAddress}
                selectedDropOffAddress2={selectedDropOffAddress2}
                showDifferentAddress={showDifferentAddress}
                setIsSameAddress={setIsSameAddress}
                previousAddressList={nonSchoolAddressList}
                selectedPreviousPickUpAddress={selectedPreviousPickUpAddress}
                selectedPreviousDropOffAddress={selectedPreviousDropOffAddress}
                onSelectPreviousPickUpAddress={(evt) => onSelectPreviousPickUpAddress(evt)}
                onSelectPreviousDropOffAddress={(evt) => onSelectPreviousDropOffAddress(evt)}
            />
        </Grid>
    );
}

export default AddressInformation;
