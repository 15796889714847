import React from 'react';
import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { museoFont700, museoSansFont } from 'util/EnumHelper';
import colorConstants from "styles/ColorConstants";
import { localize } from 'util/Localizer';
import { Link } from 'react-router-dom';
import CommonStyles from 'styles/CommonStyles';
import Auth from 'auth/Auth';

const StyledH3 = styled(Typography)(() => ({
    color: colorConstants.EVERDRIVENDARKCHARCOAL,
    fontFamily: museoFont700,
    fontSize: 24,
    margin: 0
}));

const StyledLink = styled(Link)(() => ({
    ...CommonStyles.linkButton
}));

const StyledText = styled(Typography)(() => ({
    color: colorConstants.EVERDRIVENDARKCHARCOAL,
    fontFamily: museoSansFont,
    fontSize: 16
}));

function Sidebar() {
    const teacherAppEnabled = Auth.getConfig().teacherAppEnabled !== false ?
        true : Auth.getConfig().teacherAppEnabled;

    return (
        <Paper data-testid="HowTos"
            sx={{
                mx: '15px',
                p: '32px',
                borderRadius: '8px'
            }}
        >
            <StyledH3>{localize('resources.howto')}</StyledH3>
            {teacherAppEnabled ?
                <StyledLink to="/Resources/how-to-grant-app-access">
                    {localize('resources.AppAccessToStaff')}
                </StyledLink>
                :
                <StyledText>{localize('resources.comingSoon')}</StyledText>
            }
        </Paper>
    );
}

export default Sidebar;
