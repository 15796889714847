import tripModel from "data/models/Trips/TripModel";
import { Enum, getKeyByValue } from "util/EnumHelper";

const OLD_DATA_VERSION = 1;

const decorateFacilityETA = (trip, guid) => {
    let facilityEta = null;

    if(trip.PickUpLocation.FacilityGuid === guid) {
        facilityEta = trip.EtaToPickUpSeconds;
    }

    if(trip.DropOffLocation.FacilityGuid === guid) {
        facilityEta = trip.EtaToDropOffSeconds;
    }

    return facilityEta;
};

export const setTripFormat = (trip, guid=null) => {
    const tripFormat = {
        ...tripModel,
        ...trip.payload,
        commands: trip.commands ?? null,
        dataVersion: trip.dataVersion ?? OLD_DATA_VERSION,
        TripGuid: trip.id,
        facilityETA: guid ? decorateFacilityETA(trip.payload, guid) : null,
        status: getKeyByValue(Enum.TripState,
            trip.payload.State)
    };

    return tripFormat;
};

export const setListTrips = (trips, guid) => {
    const newTripList = [];

    trips.forEach((trip) => {
        newTripList.push(setTripFormat(trip, guid));
    });

    return newTripList;
};