import { monthDayYearFormat } from 'util/dateUtils';
import { Enum, paths } from 'util/EnumHelper';
import mrmFetch from 'util/fetchUtil';
import { localize } from 'util/Localizer';
import { fixedEncodeURIComponent } from 'util/textFormatters';
import { searchStudent } from './districtTripTracker/dttStudentWorker';
import { updatePath } from './pathWorker';
import { closeStyledSnackBar, openStyledSnackBar, setSnackbar } from './styledSnackbarWrapperWorker';
import {
    draftColumns,
    columnSort,
    setLoading,
    columnsReset,
    listDrafts,
    setSearchTerm,
    draftListPageSize,
    draftListSelectPage,
    draftListLimitPageSize,
    draftListLimitPage,
    setSearching,
    setDeleteDraft,
    setOpenModal
} from '../actions/draftListActions';

const changeSort = sort => {
    if (sort === Enum.Sorter.Asc) {
        return Enum.Sorter.Desc;
    }

    return Enum.Sorter.Asc;
};

const mapDraftsPercent= drafts => drafts.map(draft => (
    {
        ...draft,
        studentFirstName: decodeURIComponent(draft.studentFirstName),
        studentId: decodeURIComponent(draft.studentId),
        studentLastName: decodeURIComponent(draft.studentLastName),
        dateDraftSaved: monthDayYearFormat(draft.dateDraftSaved),
        percent: (draft.percent).toString()
    }
));

const getDrafts = () => async (dispatch, getState) => {
    const {
        activePage,
        searchTerm,
        rowsShowed,
        sortColumn,
        columns
    } = getState().DraftListReducers;

    dispatch(setSearching(true));

    try {
        const response = await mrmFetch(
            `/api/v2/DraftRequests?skip=${activePage + 1}&query=${searchTerm}` +
            `&pageSize=${rowsShowed}&orderType=${columns[sortColumn].sort}&order=${sortColumn}`,
            'get'
        );

        dispatch(listDrafts(mapDraftsPercent(response.results)));
        dispatch(draftListLimitPageSize(response.totalRows));
        dispatch(draftListLimitPage(response.totalPages));

        dispatch(setLoading(false));
        dispatch(setSearching(false));
    } catch (error) {
        window.warn(`error in getDraftList`,
            error
        );
    }
};

export const setPageSize = (pageSize) => (dispatch) => {
    dispatch(draftListPageSize(pageSize));
    dispatch(getDrafts());
};

export const setPage = (page) => (dispatch) => {
    dispatch(draftListSelectPage(page));
    dispatch(getDrafts());
};

export const initialSetup = () => (dispatch) => {
    dispatch(setLoading(true));
    dispatch(searchStudent('', true));
    dispatch(columnsReset());
    dispatch(getDrafts());
};

export const sortbyColumn = columnName => (dispatch, getState) => {
    const { columns } = getState().DraftListReducers;

    const newSort = changeSort(columns[columnName].sort);

    dispatch(draftColumns(columnName, newSort));
    dispatch(columnSort(columnName));

    dispatch(getDrafts());
};

export const performSearch = search => (dispatch) => {
    dispatch(setSearchTerm(search));
    dispatch(getDrafts());
};
export const goToTRF = () => (dispatch) => {
    dispatch(updatePath(paths.TransportationRequestForm.tabValue));
};

export const closeModal = () => (dispatch) => {
    dispatch(setDeleteDraft(''));
    dispatch(setOpenModal(false));
};

export const openModal = () => (dispatch) => {
    dispatch(setOpenModal(true));
};

export const addDeleteDraft = (draft) => (dispatch) => {
    dispatch(setDeleteDraft(draft.studentId));
    dispatch(openModal());
};

export const onDeleteDraft = () => async (dispatch, getState) => {
    const { deleteDraftId } = await getState().DraftListReducers;

    dispatch(setSearching(true));
   
    try {
        const resp = await mrmFetch(
            `/api/v2/DeleteDraft?query=${fixedEncodeURIComponent(deleteDraftId)}`,
            'delete'
        );

        if (resp === true) {
            dispatch(setSnackbar(
                localize('tripRequestForm.deleteDraftSuccess'),
                Enum.severityType.success
            ));
        } else {
            dispatch(setSnackbar(
                localize('tripRequestForm.deleteDraftError'),
                Enum.severityType.error
            ));
        }
        
        dispatch(setSearching(false));
        dispatch(closeModal());

        dispatch(getDrafts());
        dispatch(openStyledSnackBar());
        setTimeout(
            () => {
                dispatch(closeStyledSnackBar());
            },
            2000
        );
    } catch (error) {
        dispatch(setSearching(false));
        dispatch(setSnackbar(
            localize('tripRequestForm.deleteDraftError'),
            Enum.severityType.error
        ));
        dispatch(openStyledSnackBar());
        window.warn(`error in onDeleteDraft`,
            error
        );
    }
};
