import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Calendar from '../../../components/calendar/Calendar';

import { initialLoad } from '../../workers/districtTripTracker/calendarWorker';

import withRouter from '../../../util/customHooks/withRouter';

const mapStateToProps = (state, ownProps) => {
    const { FacilityReducers, CalendarReducers } = state;

    return {
        facilityId: ownProps.router.params.facilityId,
        facility: FacilityReducers.facility,
        isLoading: CalendarReducers.isLoading,
        calendars: CalendarReducers.calendars
    };
};

const CalendarContainer = withRouter(
    connect(mapStateToProps,
        {
            initialLoad
        })(injectIntl(Calendar))
);

export default CalendarContainer;