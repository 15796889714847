import { connect } from 'react-redux';
import TripBuilderEditor from 'components/TransportationRequest/TripBuilder/TripBuilderEditor/TripBuilderEditor';
import {
    removeTrip,
    trTripUpdateAccount,
    trTripUpdateDays,
    trTripUpdateRecurring,
    trTripUpdateTripType
} from 'redux/workers/transportationRequest/transportationRequestTripsWorker';
import { TransportationRequestSegmentTypes } from 'data/models/transportationRequest/TripBuilderModel';

const mapStateToProps = (state, ownProps) => {
    const { TransportationRequestReducers } = state;
    const { trips } = TransportationRequestReducers;

    return {
        trip: trips[ownProps.tripNumber],
        segmentTypes: TransportationRequestSegmentTypes
    };
};

const mapDispatchToProps = {
    removeTrip,
    onChangeRecurring: trTripUpdateRecurring,
    onChangeAccount: trTripUpdateAccount,
    onChangeTripType: trTripUpdateTripType,
    onChangeDays: trTripUpdateDays

};

const TripBuilderEditorContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TripBuilderEditor);

export default TripBuilderEditorContainer;
