import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getTrip } from 'redux/workers/trackTripWorker';
import { goToStudent } from 'redux/workers/districtTripTracker/studentWorker';
import TrackTrip from 'components/districtTripTracker/DttTrackTrip/TrackTrip';
import withRouter from 'util/customHooks/withRouter';

const mapStateToProps = (state, ownProps) => {
    const {
        FacilityReducers,
        TrackTripReducers
    } = state;
    
    return {
        trip: TrackTripReducers.trip,
        isLoading: TrackTripReducers.isLoading,
        facility: FacilityReducers.facility,
        memberId: ownProps.router.params.memberId,
        facilityId: ownProps.router.params.facilityId,
        tripId: ownProps.router.params.tripId,
        tripType: TrackTripReducers.tripType

    };
};

const mapDispatchToProps = {
    initialSetup: getTrip,
    goToStudent
};

const TrackTripContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(TrackTrip)));
  
export default TrackTripContainer;
