import { connect } from 'react-redux';
import { localize } from 'util/Localizer';
import StyledButtonSelector from 'components/Common/StyledButtonSelector/StyledButtonSelector';
import { dataDaysTrip } from 'util/data/TripBuilderDecorator';
import {
    TransportationRequestRecurringTripOptions as recurringOptions
} from 'data/models/transportationRequest/TripBuilderModel';

const mapStateToProps = (state, ownProps) => {
    const { TransportationRequestReducers } = state;
    const { trips } = TransportationRequestReducers;

    return {
        trip: trips[ownProps.currentTrip],
        title: localize('transportationRequest.tripDaysTitle'),
        options: dataDaysTrip(trips[ownProps.currentTrip]),
        showElements: trips[ownProps.currentTrip].recurring !== recurringOptions.oneTime,
        widthButton: 75,
        value: trips[ownProps.currentTrip].days

    };
};

const mapDispatchToProps = {};

const TripDaysContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StyledButtonSelector);

export default TripDaysContainer;
