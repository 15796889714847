import { requestTypeDto } from 'data/models/tripRequestForm/TripRequestFormModel';
import {
    compareIsBefore,
    compareIsSameOrBefore,
    dateIsValid,
    minimumDateAllowed,
    today,
    tomorrow
} from 'util/dateUtils';
import { localize } from 'util/Localizer';
import { stringValidate } from 'util/textFormatters';
import { isPhoneNumber } from 'util/validationUtil';

const dateOfBirthValidation = (dateOfBirth) => {
    const dobErrorMessages = [];
    const dateOfBirthIsValid = dateIsValid(dateOfBirth);

    if (!dateOfBirth || !dateOfBirthIsValid) {
        dobErrorMessages.push(localize('tripRequestForm.studentDateOfBirthFormatOrRequired'));
    }

    if (dateOfBirthIsValid && compareIsSameOrBefore(today(), dateOfBirth)) {
        dobErrorMessages.push(localize('tripRequestForm.studentBirthDateShouldBeInThePast'));
    }

    if (dateOfBirthIsValid && compareIsBefore(dateOfBirth, minimumDateAllowed())) {
        dobErrorMessages.push(localize('tripRequestForm.studentBirthDateMinDate'));
    }

    return dobErrorMessages;
};

const tripDatesValidation = (tripStartDate, tripEndDate, requestType) => {
    const tdErrorMessages = [];
    const tripStartDateIsValid = dateIsValid(tripStartDate);
    const tripEndDateIsValid = dateIsValid(tripEndDate);

    if ((tripStartDate && !tripStartDateIsValid) ||
        (requestType === requestTypeDto.new && !tripStartDate)) {
        tdErrorMessages.push(localize('tripRequestForm.tripStartDateFormatOrRequired'));
    }

    if ((tripEndDate && !tripEndDateIsValid) ||
        (requestType === requestTypeDto.new && !tripEndDate)) {
        tdErrorMessages.push(localize('tripRequestForm.tripEndDateFormatOrRequired'));
    }

    if (tripStartDateIsValid && compareIsBefore(tripStartDate, tomorrow())) {
        tdErrorMessages.push(localize('tripRequestForm.tripStartDateShouldBeInTheFuture'));
    }

    if (tripStartDateIsValid && tripEndDateIsValid
        && compareIsBefore(tripEndDate, tripStartDate)) {
        tdErrorMessages.push(localize('tripRequestForm.tripEndDateShouldBeGreaterThanStartDate'));
    }

    return tdErrorMessages;
};

const contactMissingFields = (tripModel, key) => {
    const fields = [
        tripModel[`${key}FirstName`],
        tripModel[`${key}Relationship`],
        tripModel[`${key}MobilePhone`],
        tripModel[`${key}AltPhone`],
        tripModel[`${key}EmailAddress`]
    ];

    const missingFields = [];

    // If any one of the fields for a contact has been filled in then
    // validate contact has FirstName, Relationship, and MobilePhone
    if (fields.some((field) => field)) {
        if (stringValidate(fields[0])) {
            missingFields.push(localize('tripRequestForm.name'));
        }
        if (stringValidate(fields[1])) {
            missingFields.push(localize('tripRequestForm.relationship'));
        }
        if (stringValidate(fields[2])) {
            missingFields.push(localize('tripRequestForm.mobilePhoneNumber'));
        }
    }

    return missingFields;
};

const validationSchoolDays = (schoolDay, startDate, endDate, keyRequired, keyRange) => {
    const vsdErrorMessages = [];

    if (!schoolDay) {
        return vsdErrorMessages;
    }

    if (startDate === null && endDate === null) {
        vsdErrorMessages.push(localize(`tripRequestForm.${keyRequired}`));
    } else if (!compareIsBefore(startDate,
        endDate)) {
        if (!((startDate && endDate === null) || (startDate === null && endDate))) {
            vsdErrorMessages.push(localize(`tripRequestForm.${keyRange}`));
        }
    }

    return vsdErrorMessages;
};

const isPhoneNumberValid = (phoneNumber, nameProperty) => {
    const pnErrorMessages = [];

    if (!isPhoneNumber(phoneNumber)) {
        pnErrorMessages.push(localize(`tripRequestForm.${nameProperty}FormatInvalid`));
    }

    return pnErrorMessages;
};

const isValidForm = (tripModel, emailErrors) => {
    let messages = [];
    let response = true;

    // date of birth validations
    messages = messages.concat(dateOfBirthValidation(tripModel.studentDateOfBirth));

    // trip dates validations
    messages = messages.concat(tripDatesValidation(
        tripModel.tripStartDate,
        tripModel.tripEndDate,
        tripModel.requestType));

    // phone numbers validations
    messages = messages.concat(isPhoneNumberValid(tripModel.parent1MobilePhone, 'parent1MobilePhone'));

    messages = messages.concat(isPhoneNumberValid(tripModel.parent1AltPhone, 'parent1AltPhone'));

    messages = messages.concat(isPhoneNumberValid(tripModel.contact1MobilePhone, 'contact1MobilePhone'));

    messages = messages.concat(isPhoneNumberValid(tripModel.contact1AltPhone, 'contact1AltPhone'));

    messages = messages.concat(isPhoneNumberValid(tripModel.contact2MobilePhone, 'contact2MobilePhone'));

    messages = messages.concat(isPhoneNumberValid(tripModel.contact2AltPhone, 'contact2AltPhone'));

    messages = messages.concat(isPhoneNumberValid(tripModel.schoolPhone, 'schoolPhone'));

    if (tripModel.accountId.trim() === '') {
        messages.push(localize('tripRequestForm.accountNumberIsRequired'));
    }

    if (tripModel.requestType === requestTypeDto.new &&
        tripModel.transportationEquipmentNeeds.length === 0) {
        messages.push(localize('tripRequestForm.transportationNeedsIsRequired'));
    }

    // school days validations
    messages = messages.concat(validationSchoolDays(
        tripModel.schoolMonday,
        tripModel.schoolArrivalMonday,
        tripModel.schoolPickupMonday,
        'schoolMondayIsRequired',
        'schoolMondayRangeInvalid'));

    messages = messages.concat(validationSchoolDays(
        tripModel.schoolTuesday,
        tripModel.schoolArrivalTuesday,
        tripModel.schoolPickupTuesday,
        'schoolTuesdayIsRequired',
        'schoolTuesdayRangeInvalid'));

    messages = messages.concat(validationSchoolDays(
        tripModel.schoolWednesday,
        tripModel.schoolArrivalWednesday,
        tripModel.schoolPickupWednesday,
        'schoolWednesdayIsRequired',
        'schoolWednesdayRangeInvalid'));

    messages = messages.concat(validationSchoolDays(
        tripModel.schoolThursday,
        tripModel.schoolArrivalThursday,
        tripModel.schoolPickupThursday,
        'schoolThursdayIsRequired',
        'schoolThursdayRangeInvalid'));

    messages = messages.concat(validationSchoolDays(
        tripModel.schoolFriday,
        tripModel.schoolArrivalFriday,
        tripModel.schoolPickupFriday,
        'schoolFridayIsRequired',
        'schoolFridayRangeInvalid'));

    messages = messages.concat(validationSchoolDays(
        tripModel.schoolSaturday,
        tripModel.schoolArrivalSaturday,
        tripModel.schoolPickupSaturday,
        'schoolSaturdayIsRequired',
        'schoolSaturdayRangeInvalid'));

    messages = messages.concat(validationSchoolDays(
        tripModel.schoolSunday,
        tripModel.schoolArrivalSunday,
        tripModel.schoolPickupSunday,
        'schoolSundayIsRequired',
        'schoolSundayRangeInvalid'));

    if (emailErrors.parent1EmailAddress) {
        messages.push(localize('tripRequestForm.InvalidFormatEmail'));
    }

    if (emailErrors.contact1EmailAddress) {
        messages.push(localize('tripRequestForm.InvalidFormatEmail'));
    }

    if (emailErrors.contact2EmailAddress) {
        messages.push(localize('tripRequestForm.InvalidFormatEmail'));
    }

    const contact2MissingFields = contactMissingFields(tripModel, 'contact1');

    if (contact2MissingFields.length > 0) {
        const missing = contact2MissingFields.join(", ");

        messages.push(`${localize('tripRequestForm.emergencyContact2MissingFields')} ${missing.toLowerCase()}`);
    }

    const contact3MissingFields = contactMissingFields(tripModel, 'contact2');

    if (contact3MissingFields.length > 0) {
        const missing = contact3MissingFields.join(", ");

        messages.push(`${localize('tripRequestForm.emergencyContact3MissingFields')} ${missing.toLowerCase()}`);
    }

    if (messages.length > 0) {
        response = false;
    }

    return {
        response,
        messages
    };
};

export default isValidForm;