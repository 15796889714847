import { connect } from 'react-redux';
import StyledRadioButtonsSelect from 'components/Common/StyledRadioButtonsSelect/StyledRadioButtonsSelect';
import { localize } from 'util/Localizer';
import { dataRecurring } from 'util/data/TripBuilderDecorator';

const mapStateToProps = (state, ownProps) => {
    const { TransportationRequestReducers } = state;
    const { trips } = TransportationRequestReducers;

    return {
        trip: trips[ownProps.currentTrip],
        title: localize('transportationRequest.recurringTripTitle'),
        name: 'RecurringTrip',
        value: trips[ownProps.currentTrip].recurring,
        options: dataRecurring(),
        isRequired: true
    };
};

const mapDispatchToProps = {};

const RecurringContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StyledRadioButtonsSelect);

export default RecurringContainer;
