import Auth from "auth/Auth";
import COLORCONSTANTS from "../../styles/ColorConstants";

export default function CreatePopupClass(tripList, facilityId, contentDivID, google, history, classes) {
    function resetPopUp() {
        const cancelButtons = document.querySelectorAll('[id^="cancelButton_"]');
        const tripContents = document.querySelectorAll('[id^="tripContent_"]');
        const tripRows = document.querySelectorAll('[id^="tripRow_"]');
        const trips = document.querySelectorAll('[id^="trip_"]');

        if (trips && trips.length > 1) {
            cancelButtons.forEach(cancelButton => {
                const newCancelButton = cancelButton;

                newCancelButton.style.display = 'none';

                return newCancelButton;
            });

            tripContents.forEach(tripContent => {
                const newTripContent = tripContent;

                newTripContent.style.display = 'none';

                return newTripContent;
            });

            tripRows.forEach((tripRow, index) => {
                const newTripRow = tripRow;

                if ((tripRows.length - 1) === index) {
                    newTripRow.style.backgroundImage = '';
                    newTripRow.style.backgroundPosition = '';
                    newTripRow.style.backgroundSize = '';
                    newTripRow.style.backgroundRepeat = '';
                    newTripRow.style.paddingBottom = 0;
                }

                return newTripRow;
            });

            trips.forEach(trip => {
                const newTrip = trip;

                newTrip.style.display = 'block';

                return newTrip;
            });
        }
    }
    
    function Popup(position, contentHtml) {
        this.position = position;
        this.resetPopUp = resetPopUp;

        const content = document.createElement("div");

        content.innerHTML= contentHtml;
        content.classList.add(classes.popUpBuble);
    
        // This zero-height div is positioned at the bottom of the bubble.
        const bubbleAnchor = document.createElement("div");

        bubbleAnchor.classList.add(classes.popupBubbleAnchor);
        bubbleAnchor.appendChild(content);

        const bubbleAnchorAfter = document.createElement("div");

        bubbleAnchorAfter.classList.add(classes.popupBubbleAnchorAfter);

        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement("div");
        this.containerDiv.classList.add(classes.popupContainer);
        this.containerDiv.id = contentDivID;
        this.containerDiv.appendChild(bubbleAnchor);
        this.containerDiv.appendChild(bubbleAnchorAfter);
    
        // Optionally stop clicks, etc., from bubbling up to the map.
        google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
    }
    // ES5 magic to extend google.maps.OverlayView.
    Popup.prototype = Object.create(google.maps.OverlayView.prototype);

    /** Called when the popup is added to the map. */
    Popup.prototype.onAdd = function add() {
        this.getPanes().floatPane.appendChild(this.containerDiv);
        
        if (tripList) {
            // click away event handler
            const containerPopUp = this.containerDiv;

            document.addEventListener('click',
                (event) => {
                    const isClickInside = containerPopUp.contains(event.target);

                    if (!isClickInside) {
                        if (containerPopUp.parentElement) {
                            resetPopUp();
                            containerPopUp.parentElement.removeChild(containerPopUp);
                        }
                    }
                });
            tripList.forEach((ntrip, n) => {
                const tripRowName =
                    document.getElementById(`tripRowName_${ntrip.VehicleRunID}_${ntrip.MemberPII.ClientMemberCode}`);
                const cancelButton =
                    document.getElementById(`cancelButton_${ntrip.VehicleRunID}_${ntrip.MemberPII.ClientMemberCode}`);
                const tripContent =
                    document.getElementById(`tripContent_${ntrip.VehicleRunID}_${ntrip.MemberPII.ClientMemberCode}`);
                const tripRow =
                    document.getElementById(`tripRow_${ntrip.VehicleRunID}_${ntrip.MemberPII.ClientMemberCode}`);
                const currentTripId =
                    document.getElementById(`trip_${ntrip.VehicleRunID}_${ntrip.MemberPII.ClientMemberCode}`).id;
                const statuslabel =
                    document.getElementById(`statusLabel_${ntrip.VehicleRunID}_${ntrip.MemberPII.ClientMemberCode}`);
                
                if (tripList.length > 1) {
                    tripRowName.addEventListener('click',
                        () => {
                            cancelButton.style.display = 'block !important';
                            tripContent.style.display = 'block';
                            if ((tripList.length - 1) === n) {
                                tripRow.style.backgroundImage =
                                    `linear-gradient(
                                        to right, 
                                        ${COLORCONSTANTS.EVERDRIVENLIGHTBLUE20} 40%,
                                        ${COLORCONSTANTS.WHITE} 20%)`;
                                tripRow.style.backgroundPosition = 'bottom';
                                tripRow.style.backgroundSize = '8px 1px';
                                tripRow.style.backgroundRepeat = 'repeat-x';
                                tripRow.style.paddingBottom = '2px';
                            }
                            const rowList = document.querySelectorAll(`[id^="trip_${ntrip.VehicleRunID}"]`);

                            rowList.forEach(row => {
                                const newRow = row;

                                if (row.id !== currentTripId) { newRow.style.display = 'none'; }

                                return newRow;
                            });
                        });
                    cancelButton.addEventListener('click',
                        () => {
                            cancelButton.style.display = 'none';
                            tripContent.style.display = 'none';
                            if ((tripList.length - 1) === n) {
                                tripRow.style.backgroundImage = '';
                                tripRow.style.backgroundPosition = '';
                                tripRow.style.backgroundSize = '';
                                tripRow.style.backgroundRepeat = '';
                                tripRow.style.paddingBottom = 0;
                            }
                            const rowList = document.querySelectorAll(`[id^="trip_${ntrip.VehicleRunID}"]`);

                            rowList.forEach(row => {
                                const newRow = row;

                                if (row.id !== currentTripId) { newRow.style.display = 'block'; }

                                return newRow;
                            });
                        });
                }
                
                statuslabel.addEventListener('click',
                    () => {
                        window.navsource = 'facility';
                        const { useNewTripTracker } = Auth.getConfig();
                        const url = useNewTripTracker ?
                            `/tracktrip/${ntrip.MemberGUID}/${ntrip.TripGuid}` :
                            `/school/${facilityId}/tracktrip/${ntrip.MemberGUID}/${ntrip.TripGuid}`;

                        if (history.location.pathname !== url) {
                            history.navigate(url, {
                                state: { type: 'active'}
                            });
                        }
                    });
            });
        }
    };

    /** Called when the popup is removed from the map. */
    Popup.prototype.onRemove = function remove() {
        if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
    };

    /** Called each frame when the popup needs to draw itself. */
    Popup.prototype.draw = function draw() {
        const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
    
        // Hide the popup when it is far out of view.
        const display =
            Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                ? "block"
                : "none";
    
        if (display === "block") {
            this.containerDiv.style.left = `${(divPosition.x - 245)}px`;
            this.containerDiv.style.top = `${(divPosition.y - ((this.containerDiv.offsetHeight/2)+15))}px`;
        }
        if (this.containerDiv.style.display !== display) {
            this.containerDiv.style.display = display;
        }
    };

    return Popup;
}