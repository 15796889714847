import React from 'react';
import {
    bool,
    object,
    oneOfType
} from 'prop-types';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { museoSansFont500, museoFont700, museoSansFont700 } from 'util/EnumHelper';
import colorConstants from 'styles/ColorConstants';
import { formatPhoneNumber } from 'util/textFormatters';
import { localize } from 'util/Localizer';
import FacilityAutoComplete from 'widgets/facility/FacilityAutoComplete';

const Header = styled(Box)(({ theme }) => ({
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: 'center',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.everDrivenGrey30}`,
    backgroundColor: theme.palette.white
}));

const SchoolHeader = styled(Grid)(() => (
    {
        justifyContent: 'space-between',
        alignItems: 'end',
        width: '1280px',
        padding: '50px 28px',
        gap: '15px',
        fontSize: '16px',
        color: colorConstants.EVERDRIVENDARKCHARCOAL
    }
));

const StyledTypography = styled(Typography)(() => (
    {
        fontFamily: museoSansFont500
    }
));

function StyledFacilityHeader({
    facility,
    isCalendar
}) {
    return (
        <Header>
            <SchoolHeader container>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: museoFont700,
                            color: colorConstants.EVERDRIVENDARKCHARCOAL80,
                            textTransform: 'uppercase'
                        }}
                    >
                        {localize('school.title')}
                    </Typography>
                    <Typography
                        data-testid="schoolName"
                        sx={{
                            fontFamily: museoSansFont700,
                            fontSize: '24px'
                        }}
                    >
                        {facility.name}
                    </Typography>
                    <Grid container alignItems="center" gap="10px">
                        {facility.phone &&
                            <>
                                <Grid item sx={{height: '24px'}}>
                                    <PhoneIcon
                                        sx={{
                                            color: colorConstants.EVERDRIVENDARKCHARCOAL80,
                                            width: '24px',
                                            height: '24px'
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <StyledTypography data-testid="schoolPhone">
                                        {formatPhoneNumber(facility.phone)}
                                    </StyledTypography>
                                </Grid>
                            </>
                        }
                        {facility.address &&
                            <>
                                <Grid item sx={{height: '24px'}}>
                                    <LocationOnIcon
                                        sx={{
                                            color: colorConstants.EVERDRIVENDARKCHARCOAL80,
                                            width: '24px',
                                            height: '24px'
                                        }}/>
                                </Grid>
                                <Grid item>
                                    <StyledTypography data-testid="schoolAddress">
                                        {facility.address}
                                    </StyledTypography>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
                <Grid item >
                    <FacilityAutoComplete
                        isCalendar={isCalendar}
                    />
                </Grid>
            </SchoolHeader>
        </Header>
    );
}

StyledFacilityHeader.defaultProps = {
    facility: null,
    isCalendar: false
};

/* eslint-disable react/forbid-prop-types */
StyledFacilityHeader.propTypes = {
    facility: oneOfType([
        object
    ]),
    isCalendar: bool

};
export default StyledFacilityHeader;
