import React, { useEffect, useState } from 'react';
import {
    func,
    shape,
    arrayOf
} from 'prop-types';
import {
    FormGroup,
    FormControl,
    FormControlLabel,
    Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import commonUseStyles from 'styles/CommonStyles';
import LabelDescription from 'controls/Common/LabelDescription';
import SchoolSelectList from 'controls/User/SchoolSelectList/SchoolSelectList';
import { localize } from 'util/Localizer';
import StyledCheckbox from 'components/Common/StyledCheckbox/StyledCheckbox';
import userShape from 'data/models/UserModel';
import facilityShape from 'data/models/FacilityModel';

const TEACHER = 'teacher';

const VipAccessGroupBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '10px'
}));

const TitleSpan = styled('span')(() => ({
    ...commonUseStyles.spanTitle
}));

const FormControlFullWidth = styled(FormControl)(() => ({
    fullWidth: true
}));

const FormControlLabelCheck = styled(FormControlLabel)(() => ({
    fullWidth: true,
    marginBottom: -10
}));

const RoleDescriptionLabel = styled(LabelDescription)(({ theme }) => ({
    fontSize: 11,
    marginTop: 0,
    paddingLeft: 25,
    fullWidth: true,
    lineHeight: "11px",
    color: theme.palette.everDrivenGrey60
}));

function VipAccess({
    user,
    facilityList,
    onChangeInput
}) {
    const [ searchValue, setSearchValue ] = useState('');
    const [ schoolList, setSchoolList ] = useState(facilityList);

    const contextUser = {...user };

    const { isTeacher } = contextUser;

    const updateSchoolLists = () => (
        facilityList.filter(school => school.name.toUpperCase().includes(searchValue))
    );

    const handleVipAccessChange = event => {
        if (!event.target.checked) {
            contextUser.allowedSchoolsVipApp = [];
        }

        contextUser.isTeacher = event.target.checked;
        onChangeInput(contextUser);
    };

    const selectSchool = (newSelectedSchool) => {
        const allowedSchoolsUser = [...contextUser.allowedSchoolsVipApp];
        const currentIndex = allowedSchoolsUser.indexOf(newSelectedSchool.id);

        if (currentIndex !== -1) {
            allowedSchoolsUser.splice(currentIndex, 1);
        } else {
            allowedSchoolsUser.push(newSelectedSchool.id);
        }

        contextUser.allowedSchoolsVipApp = (allowedSchoolsUser);
        onChangeInput(contextUser);
    };

    const selectAllSchools = (event, items) => {
        if (!event.target.checked) {
            contextUser.allowedSchoolsVipApp = [];
        } else {
            contextUser.allowedSchoolsVipApp = items.map(school => school.id);
        }
        onChangeInput(contextUser);
    };
    
    useEffect(() => {
        setSchoolList(updateSchoolLists());
    }, [searchValue]);

    return (
        <>
            <VipAccessGroupBox>
                <TitleSpan>
                    {localize('userForm.mobileAccess')}
                </TitleSpan>
                <FormControlFullWidth
                    component="fieldset"
                    variant="standard"
                >
                    <FormGroup>
                        <FormControlLabelCheck
                            control={
                                <StyledCheckbox
                                    color="secondary"
                                    isChecked={isTeacher}
                                    name={TEACHER}
                                    size="small"
                                    onChange={handleVipAccessChange}
                                    inputProps={{"data-testid": "vipApp-checkbox"}}
                                />
                            }
                            label={localize('userForm.vipApp')}
                        />
                        <RoleDescriptionLabel
                            description={localize('userForm.vipAppDescription')}
                        />
                    </FormGroup>
                </FormControlFullWidth>
            </VipAccessGroupBox>
            {isTeacher &&
            <Grid container alignItems="center" spacing={0} sx={{paddingLeft: "25px"}}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                    <SchoolSelectList
                        id="vipSchools"
                        items={schoolList}
                        unfilteredItems={facilityList}
                        checkedList={contextUser.allowedSchoolsVipApp ?? []}
                        onUpdateCheckedList={selectSchool}
                        updateSearch={setSearchValue}
                        searchValue={searchValue}
                        selectAllSchools={selectAllSchools}
                    />
                </Grid>
            </Grid>
            }
        </>
    );
}

VipAccess.defaultProps = {
    user: null,
    facilityList: [],
    onChangeInput: () => null
};

VipAccess.propTypes = {
    user: shape(userShape),
    facilityList: arrayOf(shape(facilityShape)),
    onChangeInput: func
};

export default VipAccess;