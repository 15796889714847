import React from "react";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import SchoolIcon from '@mui/icons-material/School';
import PropTypes from 'prop-types';
import Auth from "auth/Auth";
import { museoFont700, museoSansFont500, museoSansFont700, paths } from "util/EnumHelper";
import { localize } from "util/Localizer";
import StyledButton from "components/Common/StyledButton/StyledButton";
import colorConstants from "styles/ColorConstants";

const Header = styled(Grid)(({ theme }) => ({
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.everDrivenGrey30}`,
    backgroundColor: theme.palette.white,
    height: 177,
    marginBottom: 10
}));

const GoToTRFButton = styled(StyledButton)(({ theme }) => ({
    // eslint-disable-next-line max-len
    background: `linear-gradient(0deg, rgba(38, 38, 94, 0.8), rgba(38, 38, 94, 0.8)), ${theme.palette.everDrivenDarkBlue}`,
    padding: '8px 20px',
    borderRadius: 20,
    minWidth: 196,
    height: 48,
    fontSize: 16,
    fontFamily: museoSansFont700,
    color: theme.palette.white,
    boxShadow: 'none',
    marginLeft: 'auto',
    "&:hover": {
        boxShadow: 'none'
    }
}));

function StudentHeader ({
    hasManagementAccess,
    schoolName,
    studentCode,
    studentId,
    studentName,
    navigate
}) {
    const goToRTF = () => {
        navigate(
            Auth.getConfig().useNewTransportationRequestForm ?
                paths.TransportationRequest.path : paths.TransportationRequestForm.path,
            { state: { studentCode, studentId }}
        );
  	};

    return (
        <Header container>
            <Grid
                xs={12}
                item
                sx={{ display: 'flex', alignItems: 'center', width: '1280px', padding: '0px 28px' }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: colorConstants.EVERDRIVENDARKCHARCOAL
                }}>
                    <div style={{ fontFamily: museoFont700, fontSize: 16, textTransform: 'uppercase' }}>
                        {localize('student.student')}
                    </div>
                    <div
                        className="qm_block"
                        data-testid="studentName"
                        style={{ fontFamily: museoSansFont700, fontSize: 24 }}
                    >
                        {studentName}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 6 }}>
                        <div>
                            <span style={{ fontFamily: museoFont700, fontSize: 16 }}>ID</span>
                            <span
                                className="qm_block"
                                data-testid="studentCode"
                                style={{ fontFamily: museoSansFont500, fontSize: 16, paddingLeft: 10 }}
                            >
                                {studentCode}
                            </span>
                        </div>
                        <div style={{
                            fontFamily: museoSansFont500,
                            fontSize: 16,
                            paddingLeft: 10,
                            display: 'flex'
                        }}>
                            {schoolName &&
                                <>
                                    <SchoolIcon />
                                    <span data-testid="studentSchoolId" style={{ paddingLeft: 10 }}>
                                        {schoolName}
                                    </span>
                                </>
                            }
                        </div>
                    </div>
                </div>
                {hasManagementAccess && (
                    <GoToTRFButton
                        testId="newTripRequestButton"
                        type="button"
                        onClick={goToRTF}
                    >
                        {localize('student.newTripRequest')}
                    </GoToTRFButton>
                )}
            </Grid>
        </Header>
    );
}

StudentHeader.defaultProps = {
    hasManagementAccess: false,
    schoolName: '',
    studentCode: '',
    studentId: '',
    studentName: '',
    navigate: null
};

/* eslint-disable react/forbid-prop-types */
StudentHeader.propTypes = {
    hasManagementAccess: PropTypes.bool,
    schoolName: PropTypes.string,
    studentCode: PropTypes.string,
    studentId: PropTypes.string,
    studentName: PropTypes.string,
    navigate: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default StudentHeader;