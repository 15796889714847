import {
    STYLED_HEADER_SET_OPTION,
    STYLED_HEADER_SET_OPTIONS
} from 'redux/actions/styledHeaderActions';

const initialState = {
    option: 0,
    options: []
};

/* eslint-disable-next-line default-param-last */
const styledHeaderReducers = (state = initialState, action) => {
    switch (action.type) {
    case STYLED_HEADER_SET_OPTION:
        return {
            ...state,
            option: action.option
        };
    case STYLED_HEADER_SET_OPTIONS:
        return {
            ...state,
            options: action.options
        };
    default:
        return state;
    }
};

export default styledHeaderReducers;
