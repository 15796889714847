import { Enum } from "./EnumHelper";

export const compareValues = (key, order = 'asc') => (function innerSort(a, b) {
    if (!Object.prototype.hasOwnProperty.call(a,
        key) || !Object.prototype.hasOwnProperty.call(b,
        key)) {
        return 0;
    }

    const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

    if (varA === varB) {
        return 0;
    }

    if (varA === null) {
        return 1;
    }

    if (varB === null) {
        return -1;
    }

    if (order !== 'desc') {
        return varA < varB ? -1 : 1;
    }

    return varA < varB ? 1 : -1;
});

export const changeSort = sort => {
    if (sort === Enum.Sorter.Asc) {
        return Enum.Sorter.Desc;
    }

    return Enum.Sorter.Asc;
};