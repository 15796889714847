import React from 'react';
import {
    any,
    arrayOf,
    bool,
    func,
    number,
    object,
    oneOf,
    oneOfType,
    shape,
    string
} from 'prop-types';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { commonStyles } from 'styles/CommonStyles';

const NewTextField = styled(
    TextField,
    { shouldForwardProp: (prop) => prop !== 'isNewStyle' }
)(({ theme, isNewStyle }) => {
    if (isNewStyle) {
        return {
            ...commonStyles(theme).newInputStyle
        };
    }

    return {
        ...commonStyles(theme).oldInputStyle
    };
});

function StyledTextField({
    autoComplete,
    classes,
    className,
    color,
    defaultValue,
    extraStyle,
    FormHelperTextProps,
    helperText,
    id,
    InputLabelProps,
    inputProps,
    InputProps,
    inputRef,
    isDisabled,
    isError,
    isMultiline,
    isNewStyle,
    isRequired,
    label,
    name,
    placeholder,
    rows,
    testId,
    type,
    value,
    variant,
    onBlur,
    onFocus,
    onChange,
    onKeyPress
}) {
    return <NewTextField
        autoComplete={autoComplete}
        classes={classes}
        className={className}
        color={color}
        data-testid={testId}
        defaultValue={defaultValue}
        disabled={isDisabled}
        error={isError}
        FormHelperTextProps={FormHelperTextProps}
        fullWidth
        label={label}
        multiline={isMultiline}
        name={name}
        helperText={isError ? helperText : ''}
        id={id}
        isNewStyle={isNewStyle}
        InputLabelProps={InputLabelProps}
        inputProps={{
            maxLength: 64,
            ...inputProps
        }}
        InputProps={InputProps}
        inputRef={inputRef}
        placeholder={placeholder}
        required={isRequired}
        rows={isMultiline ? rows : null}
        size="small"
        sx={extraStyle}
        type={type}
        value={value}
        variant={variant}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onKeyPress={onKeyPress}
    />;
}

StyledTextField.defaultProps = {
    autoComplete: null,
    classes: null,
    className: null,
    color: 'primary',
    defaultValue: undefined,
    extraStyle: null,
    FormHelperTextProps: null,
    helperText: null,
    id: null,
    InputLabelProps: null,
    inputProps: null,
    InputProps: null,
    inputRef: null,
    isDisabled: false,
    isError: false,
    isMultiline: false,
    isNewStyle: false,
    isRequired: false,
    label: null,
    name: null,
    placeholder: null,
    rows: null,
    testId: null,
    type: 'input',
    value: undefined,
    variant: 'filled',
    onBlur: () => null,
    onChange: () => null,
    onKeyPress: () => null,
    onFocus: () => null
};

/* eslint-disable react/forbid-prop-types */
StyledTextField.propTypes = {
    autoComplete: string,
    classes: oneOfType([
        arrayOf(object),
        object
    ]),
    className: string,
    color: string,
    defaultValue: any,
    extraStyle: object,
    FormHelperTextProps: object,
    helperText: string,
    id: string,
    InputLabelProps: object,
    inputProps: object,
    InputProps: object,
    inputRef: oneOfType([
        func,
        shape({
            current: any
        })
    ]),
    isDisabled: bool,
    isError: bool,
    isMultiline: bool,
    isNewStyle: bool,
    isRequired: bool,
    label: any,
    name: string,
    placeholder: string,
    rows: oneOfType([
        number,
        string
    ]),
    testId: string,
    type: oneOf([
        'email',
        'input',
        'time'
    ]),
    value: any,
    variant: oneOf([
        'filled',
        'outlined',
        'standard'
    ]),
    onBlur: func,
    onChange: func,
    onKeyPress: func,
    onFocus: func
};
/* eslint-enable react/forbid-prop-types */

export default StyledTextField;
