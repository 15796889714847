import { useEffect, useState } from 'react';
import packageJson from '../package.json';

global.appVersion = packageJson.version;

const CacheBuster = ({ children }) => {
    const [ loading, setLoading ] = useState(true);
    const [ isLatestVersion, setIsLatestVersion ] = useState(true);

    const refreshCacheAndReload = async () => {
        if (window.caches) {
            // Service worker cache should be cleared with caches.delete()
            const names = await window.caches.keys();

            await Promise.all(names.map(async (name) => {
                window.caches.delete(name);
            }));
        }
        // delete browser cache and hard reload
        window.location.reload(true);
    };

    // version from response - first param, local version second param
    const semverGreaterThan = (versionA, versionB) => {
        const versionsA = versionA.split(/\./g);

        const versionsB = versionB.split(/\./g);

        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift());

            const b = Number(versionsB.shift());

            // eslint-disable-next-line no-continue
            if (a === b) { continue; }

            return a > b || Number.isNaN(b);
        }

        return false;
    };

    useEffect(() => {
        async function fetchCacheData() {
            try {
                const response = await fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' });
                const meta = await response.json();
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

                if (shouldForceRefresh) {
                    window.info(`We have a new version - ${latestVersion}. Should force refresh`);
                    setLoading(false);
                    setIsLatestVersion(false);
                } else {
                    window.info(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    setLoading(false);
                    setIsLatestVersion(true);
                }
            } catch (e) {
                setLoading(false);
                setIsLatestVersion(true);
                window.info(`File not exists meta.json - ${e}`);
            }
        }

        fetchCacheData();
    }, []);

    return (
        children({ loading, isLatestVersion, refreshCacheAndReload })
    );
};

export default CacheBuster;
