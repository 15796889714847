import React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { formatPhoneNumber } from "../../util/textFormatters";
import breakpointsNames from '../../styles/ResponsiveConstants';

const SchoolDetailContainer = styled(Grid)(({ theme }) => ({
    background: theme.palette.everDrivenDarkBlue,
    color: theme.palette.white,
    marginLeft: 0,
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down(breakpointsNames.md)]: {
        paddingBottom: 10
    },
    [theme.breakpoints.up(breakpointsNames.xs)]: {
        paddingLeft: 5,
        paddingRight: 5
    }
}));

const DetailSchoolName = styled('h3')(() => ({
    marginLeft: 0
}));

const SpanMarginBottom = styled('span')(() => ({
    marginBottom: '1em'
}));

function FacilitySchoolDetail({ facility }) {
    return (
        <SchoolDetailContainer container>
            <Grid item md={4} xs={12}>
                <DetailSchoolName id="detailSchoolName">
                    {facility.name}
                </DetailSchoolName>
            </Grid>
            <Grid item md={4} xs={12}>
                <SpanMarginBottom id="detailSchoolAddress">{facility.address}</SpanMarginBottom>
            </Grid>
            <Grid item md={4} xs={12}>
                <SpanMarginBottom id="detailSchoolPhone">{formatPhoneNumber(facility.phone)}</SpanMarginBottom>
            </Grid>
        </SchoolDetailContainer>
    );
}

export default FacilitySchoolDetail;