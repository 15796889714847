import React from 'react';
import {
    any
} from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    content: {
        padding: '.5em 2em',
        marginTop: '2em'
    }
}));

function StyledContentBox({
    children
}) {
    const classes = useStyles();

    return (
        <div className={`${classes.content} wrapper tripDetailsBox`}>
            {children}
        </div>
    );
}

StyledContentBox.defaultProps = {
    children: null
};

/* eslint-disable react/forbid-prop-types */
StyledContentBox.propTypes = {
    children: any

};
export default StyledContentBox;
