export const REPORT_SET_LOADING = 'REPORT_SET_LOADING';
export const setLoadingCall = (isLoading) => ({
    type: REPORT_SET_LOADING,
    isLoading
});

export const REPORT_LOAD = 'REPORT_LOAD';
export const loadResponseReport = (reportModel) => ({
    type: REPORT_LOAD,
    reportModel
});

export const REPORT_SET_MESSAGE_ROW = 'REPORT_SET_MESSAGE_ROW';
export const setMessageRow = (reportIndex, responseMessage) => ({
    type: REPORT_SET_MESSAGE_ROW,
    reportIndex,
    responseMessage
});

export const REPORT_SET_VIEW_BUTTON = 'REPORT_SET_VIEW_BUTTON';
export const setViewButton = (reportIndex, active) => ({
    type: REPORT_SET_VIEW_BUTTON,
    reportIndex,
    active
});

export const REPORT_SET_DOWNLOAD_BUTTON = 'REPORT_SET_DOWNLOAD_BUTTON';
export const setDownloadButton = (reportIndex, active) => ({
    type: REPORT_SET_DOWNLOAD_BUTTON,
    reportIndex,
    active
});

export const REPORT_SET_TERM = 'REPORT_SET_TERM';
export const setTermReport = (term) => ({
    type: REPORT_SET_TERM,
    term
});

export const REPORT_SET_CARD_ELEMENTS = 'REPORT_SET_CARD_ELEMENTS';
export const setCardElementsReport = (cardElements) => ({
    type: REPORT_SET_CARD_ELEMENTS,
    cardElements
});

export const REPORT_SET_DATE_PICKER = 'REPORT_SET_DATE_PICKER';
export const setDatePicker = (showDatePicker) => ({
    type: REPORT_SET_DATE_PICKER,
    showDatePicker
});
