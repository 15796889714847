import React, { useEffect } from 'react';
import { Grid, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import DPApp from 'components/DPApp';
import TripCard from 'components/trip/TripCard/TripCard';
import Container from 'widgets/tracktrip/MapContainer';
import FacilitySchoolDetail from "widgets/facility/FacilitySchoolDetail";
import StyledLoading from 'components/Common/StyledLoading/StyledLoading';
import { localize } from 'util/Localizer';
import breakpointsNames from 'styles/ResponsiveConstants';
import withRouter from 'util/customHooks/withRouter';

import StyledTypography from 'components/Common/StyledTypography/StyledTypography';

const GeneralContainer = styled('div')(({ theme }) => ({
    minHeight: 750,
    [theme.breakpoints.up(breakpointsNames.md)]: {
        height: 'calc(100vh - 100px)'
    }
}));

const Title = styled('div')(({ theme }) => ({
    paddingLeft: 25,
    paddingRight: 25,
    width: '100%',
    '& > h3' : {
        marginTop: 10
    },
    [theme.breakpoints.down(breakpointsNames.md)]: {
        textAlign: 'center'
    }
}));

const StudentDetailContainer = styled(Grid)(({ theme }) => ({
    paddingLeft: 25,
    paddingRight: 25,
    [theme.breakpoints.down(breakpointsNames.md)]: {
        order: 2,
        paddingBottom: 20
    }
}));

const MapContainerDetail = styled(Grid)(({ theme }) => ({
    borderRadius: 10,
    paddingRight: 25,
    [theme.breakpoints.down(breakpointsNames.md)]: {
        paddingRight: 0,
        order: 1,
        paddingBottom: 20
    }
}));

const HeaderContainer = styled(Grid)(({ theme }) => ({
    background: theme.palette.everDrivenLightBlue20,
    borderRadius: '8px',
    overflow: 'hidden',
    marginBottom: 15,
    marginLeft: 25,
    marginRight: 25
}));

function TrackTrip({
    tripId,
    memberId,
    facilityId,
    trip,
    facility,
    initialSetup,
    tripType,
    goToStudent,

    isLoading
}) {
    const renderResult = () => (
        (!isLoading && trip?.TripGuid === tripId &&
            <GeneralContainer>
                <Title>

                    <StyledTypography>
                        {localize('trackTrip.title')}
                    </StyledTypography>
                </Title>
                <Grid container>
                    <Hidden mdUp>
                        {facility &&
                            <HeaderContainer item xs={12}>
                                <FacilitySchoolDetail facility={facility} />
                            </HeaderContainer>
                        }
                    </Hidden>
                    <StudentDetailContainer item xs={12} md={4} lg={3}>
                        <TripCard
                            trip={trip}
                            tripType={tripType}
                            facilityId={facilityId}
                            isMapCard
                            showHeader
                            goToStudent={goToStudent}
                        />
                    </StudentDetailContainer>
                    <MapContainerDetail item xs={12} md={8} lg={9}>
                        <div id="map">
                            <Container trip={trip} />
                        </div>
                    </MapContainerDetail>
                </Grid>
            </GeneralContainer>
        )
    );

    const renderLoading = () => (
        isLoading &&
            <StyledLoading message={localize("trackTrip.loadingTripDetails")} />
    );

    useEffect(() => {
        initialSetup(memberId, tripId, facilityId);
    }, []);

    return(
        <DPApp>
            <div className="tripDetailsBox container-fluid">
                { renderResult() }
                { renderLoading() }
            </div>
        </DPApp>
    );
}

export default withRouter(TrackTrip);
