import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

import { localize } from 'util/Localizer';
import { showTripStatus, getArrivesIn, getPickUpDropOffText } from 'util/tripUtil';
import { tripStatusColor } from 'util/colorUtil';
import { monthDayYearFormat } from 'util/dateUtils';
import { formatSingleLineAddress } from 'util/textFormatters';
import { statusWithoutDriverDetails } from 'util/EnumHelper';

import { CardContainer, CardBody, IconRow, CardBodyText, CardSubText, ETAText, VertSVG } from '../TrackTrip.styles';
import CardHeader from '../CardHeader/CardHeader';
import DriverDetails from '../DriverDetails/DriverDetails';

const ID = styled('span')(() => ({
    fontFamily: 'Museo700',
    lineHeight: 1.5,
    fontSize: '12px',
    paddingLeft: '2px',
    width: '20px',
    height: '20px'
}));

const Divider = styled('hr')(() => ({
    margin: '0px 15px'
}));

function DetailCard({trip}) {
    const statusColor = tripStatusColor(trip);
    const tripStatus = showTripStatus(trip);
    
    const arrivesIn = getArrivesIn(trip);
    const pickUpDropOffText = getPickUpDropOffText(trip);

    return (
        <CardContainer data-testid="tripDetailCard">
            <CardHeader
                statusColor={statusColor}
                title={`${trip.MemberPII.FirstName} ${trip.MemberPII.LastName}`.trim()}
                status={tripStatus}
            />
            <CardBody>
                <Grid container>
                    <Grid item xs={6}>
                        <Stack sx={{gap: '5px'}}>
                            <IconRow>
                                <CalendarTodayIcon fontSize="inherit" />
                                <CardBodyText data-testid="tripDate">
                                    {monthDayYearFormat(trip.DueDateTLT)}
                                </CardBodyText>
                            </IconRow>
                            {trip.MemberPII.Grade && (
                                <IconRow>
                                    <AccountBalanceIcon fontSize="inherit" />
                                    <CardBodyText noWrap data-testid="studentGrade">
                                        {trip.MemberPII.Grade}
                                    </CardBodyText>
                                </IconRow>
                            )}
                            <IconRow>
                                <ID>ID</ID>
                                <CardBodyText noWrap data-testid="studentId">
                                    {trip.MemberPII.ClientMemberCode}
                                </CardBodyText>
                            </IconRow>
                        </Stack>
                    </Grid>
                    {arrivesIn && arrivesIn > 0 &&
                    <Grid item xs={6}>
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Stack>
                                <CardSubText data-testid="arrivesInHeading">
                                    {localize('tripDetails.arrivesIn')}
                                </CardSubText>
                                <ETAText data-testid="arrivesInTime">{arrivesIn} min</ETAText>
                            </Stack>
                        </div>
                    </Grid>
                    }
                    <Grid item xs={12} sx={{marginTop: '10px'}}>
                        <Stack>
                            <IconRow>
                                <PanoramaFishEyeIcon fontSize="inherit" />
                                <CardBodyText noWrap data-testid="pickupAddress">
                                    {formatSingleLineAddress(trip.PickUpLocation)}
                                </CardBodyText>
                            </IconRow>
                            <IconRow>
                                <VertSVG>
                                    <circle cx="9px" cy="7px" r="2px" />
                                    <circle cx="9px" cy="17px" r="2px" />
                                    <circle cx="9px" cy="27px" r="2px" />
                                </VertSVG>
                                {pickUpDropOffText.pickUp &&
                                    <CardSubText sx={{paddingTop: '2px'}} data-testid="pickupStatusText">
                                        {pickUpDropOffText.pickUp}
                                    </CardSubText>
                                }
                            </IconRow>
                            <IconRow>
                                <LocationOnIcon fontSize="inherit" />
                                <CardBodyText noWrap data-testid="dropoffAddress">
                                    {formatSingleLineAddress(trip.DropOffLocation)}
                                </CardBodyText>
                            </IconRow>
                            {pickUpDropOffText.dropOff &&
                                <CardSubText sx={{padding: "2px 0px 0px 25px"}} data-testid="dropoffStatusText">
                                    {pickUpDropOffText.dropOff}
                                </CardSubText>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </CardBody>
            {!statusWithoutDriverDetails.includes(trip.State) && trip.DriverInfo &&
            (
                <>
                    <Divider/>
                    <DriverDetails DriverInfo={trip.DriverInfo} />
                </>
            )}
        </CardContainer>
    );
}

DetailCard.propTypes = {
    trip: PropTypes.shape({
        MemberPII: PropTypes.shape({
            FirstName: PropTypes.string,
            LastName: PropTypes.string,
            Grade: PropTypes.string,
            ClientMemberCode: PropTypes.string
        }),
        DueDateTLT: PropTypes.string,
        Coordinates: PropTypes.shape({
            Latitude: PropTypes.number,
            Longitude: PropTypes.number

        }),
        PickUpLocation: PropTypes.shape({
            Coordinates: PropTypes.shape({
                Latitude: PropTypes.number,
                Longitude: PropTypes.number
    
            })
        }),
        DropOffLocation: PropTypes.shape({
            Coordinates: PropTypes.shape({
                Latitude: PropTypes.number,
                Longitude: PropTypes.number
    
            })
        }),
        State: PropTypes.number,
        status: PropTypes.string
    }).isRequired
};

export default DetailCard;