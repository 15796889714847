import React from 'react';
import { arrayOf, func, node, number, shape, string, object, bool } from 'prop-types';
import StyledHeaderContainer from 'components/Common/StyledHeaderContainer/StyledHeaderContainer';
import StyledTabs from 'components/Common/StyledTabs/StyledTabs';

function StyledHeader({
    idContainer,
    showAlert,
    systemAlert,
    tabItems,
    tabValue,
    title,
    onChangeTab,
    testid,
    extraStyle
}) {
    return (
        <StyledHeaderContainer
            idContainer={idContainer}
            systemAlert={systemAlert}
            title={title}
            titleStyle={{
                marginTop: showAlert ? '10px' : '40px'
            }}
            testId={testid}
            extraStyle={extraStyle}
        >
            <StyledTabs
                tabItems={tabItems}
                currentValue={tabValue}
                onChangeTab={onChangeTab}
            />
        </StyledHeaderContainer>
    );
}

StyledHeader.defaultProps = {
    idContainer: null,
    showAlert: false,
    systemAlert: null,
    title: null,
    testid: null,
    extraStyle: null
};

/* eslint-disable react/forbid-prop-types */
StyledHeader.propTypes = {
    idContainer: string,
    showAlert: bool,
    systemAlert: node,
    tabItems: arrayOf(shape({
        label: string,
        value: number
    })).isRequired,
    tabValue: number.isRequired,
    title: string,
    onChangeTab: func.isRequired,
    testid: string,
    extraStyle: object
};
/* eslint-enable react/forbid-prop-types */

export default StyledHeader;