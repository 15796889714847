import React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { localize } from 'util/Localizer';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import { museoSansFont, paths } from 'util/EnumHelper';
import colorConstants from 'styles/ColorConstants';
import CommonStyles from 'styles/CommonStyles';
import Auth from 'auth/Auth';

const LinkButton = styled(StyledButton)(({ theme }) => ({
    color: theme.palette.everDrivenLightBlue,
    backgroundColor: theme.palette.transparent,
    boxShadow: 'none',
    fontFamily: museoSansFont,
    fontWeight: 300,
    fontSize: 24,
    textDecorationLine: 'underline',
    padding: 0,
    "&:hover": {
        backgroundColor: theme.palette.transparent,
        boxShadow: 'none',
        textDecorationLine: 'underline'
    }
}));

const styles = {
    confirmContent: {
        textAlign: 'center',
        fontWeight: 400,
        fontSize: 24,
        fontFamily: museoSansFont,
        margin: 0,
        paddingTop: 20,
        color: colorConstants.EVERDRIVENDARKCHARCOAL,
        flexDirection: 'column'
    }
};

function ErrorPage({
    isOutOfContext,
    title,
    content,
    updatePath
}) {
    const contactLink = () => (
        <LinkButton
            testId="LinkContactButton"
            type="button"
            onClick={() => updatePath(paths.Support.tabValue)}
        >
            {localize('transportationRequest.failedSubmitModalContent2Link')}
        </LinkButton>
    );

    const contactUs = () => {
        if (isOutOfContext) {
            return (
                <p style={{ ...styles.confirmContent, paddingTop: 0, marginTop: 0}}>
                    {localize("errorPage.reachOut")}
                </p>
            );
        }

        return (
            <p style={{ ...styles.confirmContent, paddingTop: 0, marginTop: 0}}>
                {localize("errorPage.contactUs")} {contactLink()}
            </p>
        );
    };

    const dttLink = () => (
        <LinkButton
            testId="LinkDttButton"
            type="button"
            onClick={() => isOutOfContext ? Auth.signout() : updatePath(paths.DistrictTripTracker.tabValue)}
        >
            {isOutOfContext
                ? localize('errorPage.login')
                : localize('errorPage.dttLink')}
        </LinkButton>
    );

    return (
        <div>
            <Grid container sx={{ textAlign: '-webkit-center', marginTop: 21, flexWrap: 'nowrap' }}>
                <Grid item xs={12} sx={{ textAlign: '-moz-center' }}>
                    <Grid item xs={12} sx={{ paddingBottom: 5 }}>
                        <img className="globe" src="/img/ErrorImagePage.svg" alt={localize('errorPage.altText')} />
                    </Grid>
                    <Grid item xs={12} sx={{width: 600, textAlign: 'center', marginRigth: 0}}>
                        <p style={{ ...CommonStyles.contentConfirmModal, fontSize: 36}}>
                            {title}
                        </p>
                        <p style={styles.confirmContent}>
                            {content}
                        </p>
                        <>
                            <p style={styles.confirmContent}>
                                {localize('errorPage.navigateToDTT')} {dttLink()}
                            </p>
                            {contactUs()}
                        </>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

ErrorPage.defaultProps = {
    isOutOfContext: false,
    content: localize('errorPage.content404'),
    title: localize('errorPage.title404'),
    updatePath: null
};

ErrorPage.propTypes = {
    isOutOfContext: PropTypes.bool,
    content: PropTypes.string,
    title: PropTypes.string,
    updatePath: PropTypes.func
};

export default ErrorPage;
