import { connect } from 'react-redux';
import {
    trTripUpdateTime,
    trTripUpdateMultipleTimes,
    setMultipleTimes,
    setShowSegmentNotes
} from 'redux/workers/transportationRequest/transportationRequestTripsWorker';

import { localize } from 'util/Localizer';
import SegmentTimes from 'components/TransportationRequest/TripBuilder/Segment/SegmentTime/SegmentTimes';
import { dataTimes } from 'util/data/TripBuilderDecorator';
import {
    TransportationRequestRecurringTripOptions as RecurringTripOptions
} from 'data/models/transportationRequest/TripBuilderModel';
    
const mapStateToProps = (state, ownProps) => {
    const {
        TransportationRequestReducers,
        TransportationRequestErrorsReducers
    } = state;
    const { trips } = TransportationRequestReducers;
    const { tripBuilderErrors } = TransportationRequestErrorsReducers;

    return {
        times: dataTimes(trips[ownProps.currentTrip], `${ownProps.segment}Time`),
        singleTime: trips[ownProps.currentTrip][`${ownProps.segment}SingleTime`],
        title: localize(`transportationRequest.${ownProps.segment}TimeTitle`),
        multipleTimes: trips[ownProps.currentTrip][`${ownProps.segment}HasTimeMultiples`],
        showNotes: trips[ownProps.currentTrip][`${ownProps.segment}ShowNotes`],
        showMultipleTimesButton: (trips[ownProps.currentTrip].recurring === RecurringTripOptions.recurring
            && trips[ownProps.currentTrip].days.length > 0),
        isDisabledMultipleButton: trips[ownProps.currentTrip].days.length === 1,
        errors: tripBuilderErrors[ownProps.currentTrip] ?? null
    };
};

const mapDispatchToProps = {
    onChange: trTripUpdateTime,
    onChangeTime: trTripUpdateMultipleTimes,
    setMultipleTimes,
    setShowSegmentNotes
};

const SegmentTimesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SegmentTimes);

export default SegmentTimesContainer;
