import { connect } from 'react-redux';
import {
    setNotes,
    setShowSegmentNotes
} from 'redux/workers/transportationRequest/transportationRequestTripsWorker';

import SegmentNotes from 'components/TransportationRequest/TripBuilder/Segment/SegmentNotes/SegmentNotes';
    
const mapStateToProps = (state, ownProps) => {
    const {
        TransportationRequestReducers,
        TransportationRequestErrorsReducers
    } = state;
    const { trips } = TransportationRequestReducers;
    const { tripBuilderErrors } = TransportationRequestErrorsReducers;

    return {
        trip: trips[ownProps.currentTrip],
        showNotes: trips[ownProps.currentTrip][`${ownProps.segment}ShowNotes`],
        notes:  trips[ownProps.currentTrip][`${ownProps.segment}Notes`],
        errors: tripBuilderErrors[ownProps.currentTrip] ?? null
    };
};

const mapDispatchToProps = {
    setNotes,
    setShowSegmentNotes
};

const SegmentNotesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SegmentNotes);

export default SegmentNotesContainer;
