import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import DraftList from 'screens/DraftList/DraftList';
import {
    initialSetup,
    onDeleteDraft,
    goToTRF,
    performSearch,
    addDeleteDraft,
    closeModal
} from 'redux/workers/draftListWorker';
import { updatePath } from 'redux/workers/pathWorker';
import withRouter from 'util/customHooks/withRouter';

const mapStateToProps = (state, ownprops) => {
    const {
        StudentUploadReducers,
        DraftListReducers
    } = state;

    return {
        router: ownprops.router,

        drafts: (DraftListReducers.drafts && (DraftListReducers.drafts).length > 0),
        draftsSorted: DraftListReducers.draftsSorted,
        isCompleted: StudentUploadReducers.isCompleted,
        isLoading: StudentUploadReducers.isLoading || DraftListReducers.isLoading,
        isModalOpen: DraftListReducers.isModalOpen,
        searchTerm: DraftListReducers.searchTerm
    };
};

const DraftListContainer = withRouter(
    connect(
        mapStateToProps, {
            addDeleteDraft,
            closeModal,
            goToTRF,
            initialSetup,
            onDeleteDraft,
            performSearch,
            updatePath
        })(injectIntl(DraftList))
);

export default DraftListContainer;