import mrmFetch from "../../../util/fetchUtil";
import {
    calendarIsLoading,
    calendarSelected
} from "../../actions/districtTripTracker/calendarActions";

import { loadFacilities, initialSetup } from "./facilityWorker";
import { facilityIsLoading } from "../../actions/districtTripTracker/facilityActions";

export const selectCalendarFacility = (facilityId) => async (dispatch) => {
    window.log(`selected facility ${facilityId}`);
 
    if (facilityId) {
        const calendarResponse = await mrmFetch(`/api/v2/school/${facilityId}/Calendars`,
            'GET');
            
        window.log(`found calendars`,
            calendarResponse
        );

        dispatch(calendarSelected(calendarResponse));
    }

    dispatch(calendarIsLoading(false));
};

export const initialLoad = facilityId => dispatch => {
    dispatch(calendarIsLoading(true));

    dispatch(loadFacilities());
    dispatch(selectCalendarFacility(facilityId));
    
    dispatch(initialSetup(facilityId));
    dispatch(facilityIsLoading(true));
};
