import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DPApp from 'components/DPApp';
import { localize } from 'util/Localizer';
import TRContent from 'components/TransportationRequest/TRContent/TRContent';
import TRTabModel from 'data/models/tabModel/TRTabModel';
import StyledHeader from 'components/Common/StyledHeader/StyledHeader';
import StyledMobileUnavailable from 'components/Common/StyledMobileUnavailable/StyledMobileUnavailable';
import breakpointsNames from '../../styles/ResponsiveConstants';

const HiddenOnMobileDiv = styled('div')(({theme}) => ({
    display: 'block',
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        display: 'none'
    },
    [theme.breakpoints.only(breakpointsNames.sm)]: {
        display: 'block',
        "@media only screen and (orientation: landscape)": {
            display: 'none'
        }
    }
}));

function TransportationRequest({
    router,
    tabValue,
    title,
    onLoadForm,
    onLoadRequest,
    onChangeTab
}) {
    const { location } = router;

    const setTitle = () => {
        if (title) {
            return `${localize('transportationRequest.title')}: ${title?.trim()}`;
        }

        return localize('transportationRequest.title');
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        // This is for prevent the browser to ask for a confirmation to leave the page when you have work unfinished
        window.addEventListener('beforeunload', (event) => {
            event.stopImmediatePropagation();
        });

        onLoadForm();

        return () => onLoadForm();
    },
    []);

    useEffect(() => {
        onLoadForm();
        if (location.state) {
            onLoadRequest(location.state);
        }
    },
    [ location, onLoadRequest ]);

    return (
        <DPApp>
            <StyledMobileUnavailable isNewTRF />
            <HiddenOnMobileDiv>
                <StyledHeader
                    idContainer="trfContainerHeader"
                    title={setTitle()}
                    tabItems={TRTabModel}
                    tabValue={tabValue}
                    onChangeTab={onChangeTab}
                    extraStyle={{minWidth: '930px'}}
                />
                <TRContent value={tabValue} />
            </HiddenOnMobileDiv>
        </DPApp>
    );
}

TransportationRequest.defaultProps = {
    title: '',
    onLoadRequest: null
};

/* eslint-disable react/forbid-prop-types */
TransportationRequest.propTypes = {
    router: PropTypes.object.isRequired,
    tabValue: PropTypes.number.isRequired,
    title: PropTypes.string,
    onLoadForm: PropTypes.func.isRequired,
    onLoadRequest: PropTypes.func,
    onChangeTab: PropTypes.func.isRequired
};
/* eslint-enable react/forbid-prop-types */

export default TransportationRequest;