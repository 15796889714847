import COLORCONSTANTS from "./ColorConstants";

export const mapStyles = {
    position: 'relative',
    width: '100%',
    transition: 'height 1s ease'
};

const scale = 0.4;
const anchorX = 28;
const anchorY = 76;

export const homeIcon = {
    path: 'M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z',
    fillOpacity:1,
    fillColor: COLORCONSTANTS.BLACK,
    rotation: 0,
    // scale: 1.2,
    strokeWeight: 1,
    anchorX: 0,
    anchorY: 0
};

let pathCarIcon = 'M46.5,39.1c0.1-1.6-2.5-3.8-3.7-4.7V13.1C42.8,6.4,37.6,1,31,0.7c-2';

pathCarIcon = `${pathCarIcon}.3-0.1-5-0.2-8.3-0.2c-3,0-5.5,0.1-7.6,0.1	C8.4,0.9,3.2,`;
pathCarIcon = `${pathCarIcon}6.4,3.2,13v21.8c-1.3,1.1-3.2,2.8-3.1,4.2l3.1-1.6v49.2c0,`;
pathCarIcon = `${pathCarIcon}7.2,5.8,13,13,13h13.6c7.2,0,13-5.8,13-13V37.2L46.5,39.1z`;
pathCarIcon = `${pathCarIcon} M38.2,6.9c2.6,3.5-2.1,5.3-3.8,.1c-1.8-1.1-1.9-7.3-1.9-7.`;
pathCarIcon = `${pathCarIcon}3S35.6,3.4,38.2,6.9z M22.7,24.7h0.1c6.4,0,12.7,1.2,14.9,4.`;
pathCarIcon = `${pathCarIcon}1	C40,31.6,37,43,36.8,43.5H8.7C8.6,43,5.6,31.6,7.8,28.8C10.`;
pathCarIcon = `${pathCarIcon}1,25.9,16.3,24.7,22.7,24.7z M7.3,6.9C9.9,3.4,13,3.8,13,3.8	`;
pathCarIcon = `${pathCarIcon}s-0.1,6.1-1.9,7.3C9.3,12.2,4.7,10.5,7.3,6.9z M7.4,93.2,35.`;
pathCarIcon = `${pathCarIcon}1,96.4,23.1,96.4z M38.2,87.4L38,43.7l2.5-8.4l0.3,47C40.8,82.3,40.4,86.1,38.2,87.4z`;

export const carIcon = {
    path: pathCarIcon,
    fillOpacity:1,
    fillColor: COLORCONSTANTS.BLACK,
    scale,
    rotation: 0,
    strokeWeight: 1,
    anchorX,
    anchorY
};

let pathCarBackground= 'M29.9,99.8H16.4c-7.2,0-13-5.8-13-13V13c0-6.6,5.2-12.1,11.8-12.4c2';

pathCarBackground = `${pathCarBackground}.1-0.1,4.6-0.1,7.6-0.1 c3.2,0,5.9,0.1,8.2,0.2C37.`;
pathCarBackground = `${pathCarBackground}6,1,42.8,6.4,42.8,13.1v73.6C42.8,93.9,37,99.8,29.9,99.8z`;

export const carBackground = {
    path: pathCarBackground,
    fillOpacity:1,
    fillColor: COLORCONSTANTS.WHITE,
    scale,
    rotation: 0,
    strokeWeight: 1,
    anchorX,
    anchorY
};

let pathNullCar= 'M15.9,1c-0.2-0.6-0.8-1-1.4-1h-11C2.8,0,2.3,0.4,2.1,1L0,7v8c0,0.5,';

pathNullCar = `${pathNullCar}0.5,1,1,1h1c0.6,0,1-0.5,1-1v-1h12v1c0,0.5,0.5,1,1,1h1`;
pathNullCar = `${pathNullCar}c0.5,0,1-0.5,1-1V7L15.9,1z M14.5,11c-0.8,0-1.5-0.7-1.5-1.`;
pathNullCar = `${pathNullCar}5S13.7,8,14.5,8S16,8.7,16,9.5S15.3,11,14.5,11z M3.5,11`;
pathNullCar = `${pathNullCar}C2.7,11,2,10.3,2,9.5S2.7,8,3.5,8S5,8.7,5,9.5S4.3,`;
pathNullCar = `${pathNullCar}11,3.5,11z M2,6l1.5-4.5h11L16,6H2z`;
	
export const nullCarIcon = {
    path: pathNullCar,
    fillOpacity:1,
    fillColor: COLORCONSTANTS.WHITE,
    strokeColor: COLORCONSTANTS.WHITE,
    rotation: 0,
    // scale: 1.2,
    strokeWeight: 1,
    anchorX: 10,
    anchorY: 38
};