import { connect } from 'react-redux';
import TripBuilderReview from 'components/TransportationRequest/TripBuilder/TripBuilderReview/TripBuilderReview';
import {
    TransportationRequestRecurringTripOptions as recurringTripOptions,
    TransportationRequestTripTypesOptions as tripTypesOptions
} from 'data/models/transportationRequest/TripBuilderModel';

const mapStateToProps = (state, ownProps) => {
    const { TransportationRequestReducers } = state;
    const { trips } = TransportationRequestReducers;

    return {
        trip: trips[ownProps.tripNumber],
        recurringTripOptions,
        tripTypesOptions
    };
};

const mapDispatchToProps = {};

const TripBuilderReviewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TripBuilderReview);

export default TripBuilderReviewContainer;
