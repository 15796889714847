export const DTT_STUDENT_SEARCHTERM =
  'DTT_STUDENT_SEARCHTERM';
export const dttStudentSearchTerm = dttStudentSearch => ({
    type: DTT_STUDENT_SEARCHTERM,
    dttStudentSearch
});

export const DTT_STUDENT_IS_LOADING =
  'DTT_STUDENT_IS_LOADING';
export const dttStudentIsLoading = isLoading => ({
    type: DTT_STUDENT_IS_LOADING,
    isLoading
});

export const DTT_STUDENT_STATE_COLUMNS =
  'DTT_STUDENT_STATE_COLUMNS';
export const dttStudentStateColumns = (columnName, sort) => ({
    type: DTT_STUDENT_STATE_COLUMNS,
    columnName,
    sort
});

export const DTT_STUDENT_SORT_BY_COLUMN =
  'DTT_STUDENT_SORT_BY_COLUMN';
export const dttStudentSortByColumn = (columnName) => ({
    type: DTT_STUDENT_SORT_BY_COLUMN,
    columnName
});

export const DTT_STUDENT_RESET_COLUMNS =
  'DTT_STUDENT_RESET_COLUMNS';
export const dttStudentColumnsReset = () => ({
    type: DTT_STUDENT_RESET_COLUMNS
});

export const DTT_STUDENT_SET_STUDENTS = 'DTT_STUDENT_SET_STUDENTS';
export const dttStudentSetStudents = dttStudents => ({
    type: DTT_STUDENT_SET_STUDENTS,
    dttStudents
});

export const DTT_STUDENT_SORT_STUDENTS = 'DTT_STUDENT_SORT_STUDENTS';
export const dttStudentSetSortStudents = dttStudentsSorted => ({
    type: DTT_STUDENT_SORT_STUDENTS,
    dttStudentsSorted
});

export const DTT_STUDENT_SET_SHOW_STUDENT_DATA = 'DTT_STUDENT_SET_SHOW_STUDENT_DATA';
export const dttStudentSetShowStudentData = showStudentData => ({
    type: DTT_STUDENT_SET_SHOW_STUDENT_DATA,
    showStudentData
});