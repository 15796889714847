import { localize } from 'util/Localizer';

export const TRTabOptions = {
    Student: 0,
    Contacts: 1,
    TripBuilder: 2,
    Review: 3
};

const TRTabModel = [
    {
        label: localize("transportationRequest.tabStudent"),
        value: TRTabOptions.Student
    },
    {
        label: localize("transportationRequest.tabContacts"),
        value: TRTabOptions.Contacts
    },
    {
        label: localize("transportationRequest.tabTripBuilder"),
        value: TRTabOptions.TripBuilder
    },
    {
        label: localize("transportationRequest.tabReview"),
        value: TRTabOptions.Review
    }
];

export default TRTabModel;