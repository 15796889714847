import { connect } from 'react-redux';
import withTheme from '@mui/styles/withTheme';
import { onDeleteUser } from 'redux/workers/userWorker';
import { setOpenUserDeleteModal } from 'redux/actions/userActions';
import UserModalDelete from 'components/user/UserModalDelete/UserModalDelete';

const mapStateToProps = state => {
  	const { UserReducers } = state;

    if (UserReducers) {
        return {
            usersToDelete: UserReducers?.filteredUsers?.filter(x => x.isChecked),
            showDeleteUserModal: UserReducers.showDeleteUserModal
        };
    }

    return {};
};

const UserModalDeleteContainer = withTheme(
    connect(
        mapStateToProps, {
            onDeleteUser,
            setOpenUserDeleteModal
        }
    )(UserModalDelete)
);

export default UserModalDeleteContainer;
