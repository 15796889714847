export const SET_TR_STUDENT_ERRORS = 'SET_TR_STUDENT_ERRORS';
export const setStudentErrors = payload => ({
    type: SET_TR_STUDENT_ERRORS,
    studentErrors: payload
});

export const SET_TR_CONTACTS_ERRORS = 'SET_TR_CONTACTS_ERRORS';
export const setContactsErrors = payload => ({
    type: SET_TR_CONTACTS_ERRORS,
    contactsErrors: payload
});

export const SET_TR_TRIP_BUILDER_ERRORS = 'SET_TR_TRIP_BUILDER_ERRORS';
export const setTripBuilderErrors = payload => ({
    type: SET_TR_TRIP_BUILDER_ERRORS,
    tripBuilderErrors: payload
});