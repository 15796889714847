import { connect } from 'react-redux';
import StyledRadioButtonsSelect from 'components/Common/StyledRadioButtonsSelect/StyledRadioButtonsSelect';
import { localize } from 'util/Localizer';
import { dataAccounts } from 'util/data/dataDecorator';
import colorConstants from 'styles/ColorConstants';

const mapStateToProps = (state, ownProps) => {
    const { TransportationRequestReducers } = state;
    const { trips } = TransportationRequestReducers;

    return {
        trip: trips[ownProps.currentTrip],
        title: localize('transportationRequest.accountNumberTitle'),
        name: 'AccountsNumber',
        value: trips[ownProps.currentTrip].account,
        options: dataAccounts(),
        textButton: trips.length > 1 ? localize('transportationRequest.remove') : null,
        buttonColor: colorConstants.EVERDRIVENFUCHSIA,
        isRequired: true
    };
};

const mapDispatchToProps = {};

const AccountsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StyledRadioButtonsSelect);

export default AccountsContainer;
