import React, { useEffect } from 'react';
import { Tabs, Tab, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { leftMenu } from "util/dynamicMenu";
import breakpointsNames from 'styles/ResponsiveConstants';

const LeftMenuContainer = styled(Paper)(({ theme }) => ({
    flexGrow: 1,
    height: 'calc( 100vh - 80px )',
    opacity: 1,
    top: 80,
    display: 'flex',
    ...theme.leftMenuContainer,
    maxWidth: 100,
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        display: 'none !important'
    },
    [theme.breakpoints.only(breakpointsNames.sm)]: {
        display: 'block',
        "@media only screen and (orientation: landscape)": {
            display: 'none'
        }
    }
}));

const ButtonTab = styled(Tab)(({ theme }) => ({
    minWidth: 100,
    color: theme.palette.white,
    minHeight: 100,
    textTransform: 'none',
    padding: '9px 12px 6px 12px',
    "@media screen and ( max-height: 720px )": {
        "& > svg": {
            fontSize: '1.20rem'
        },
        fontSize: '1.5ex',
        minHeight: 80
    },
    "@media screen and ( max-height: 580px )": {
        "& > svg": {
            fontSize: '1.00rem'
        },
        "& > span": {
            fontSize: '0.6ex'
        },
        fontSize: '1.2ex',
        minHeight: 60
    },
    "@media screen and ( max-height: 460px )": {
        "& > svg": {
            fontSize: '0.70rem'
        },
        "& > span": {
            fontSize: '0.4ex'
        },
        fontSize: '0.9ex',
        minHeight: 40
    },
    "@media screen and ( max-height: 360px )": {
        "& > svg": {
            fontSize: '0.40rem'
        },
        "& > span": {
            fontSize: '0.2ex'
        },
        fontSize: '0.6ex',
        minHeight: 20
    },
    '& .Mui-selected': {
        color: 'white'
    }
}));

const TabMenu = styled(Tabs)(() => ({
    maxWidth: 100,
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center'
}));

const MenuContainer = styled('div')(() => ({
    display: 'table',
    height: '100%'
}));

function LeftMenu({
    path,
    tabValue,
    setTab,
    updatePath
}) {
    useEffect(() => {
        setTab();
    }, [path]);

    const dynMenu = leftMenu();

    const handleChange = (event, newValue) => {
        event.preventDefault();
        updatePath(newValue);
    };

    return (
        <LeftMenuContainer square data-testid="LeftMenu" >
            <MenuContainer>
                <TabMenu
                    orientation="vertical"
                    value={tabValue}
                    variant="scrollable"
                >
                    {dynMenu.map(x => (
                        <ButtonTab
                            key={x.id}
                            icon={x.icon}
                            label={x.label}
                            value={x.id}
                            onClick={e => handleChange(e, x.id)}
                            data-testid={x.testid}
                        />
                    ))}
                </TabMenu>
            </MenuContainer>
        </LeftMenuContainer>
    );
}

LeftMenu.defaultProps = {
    path: '',
    tabValue: 0,
    setTab: () => null,
    updatePath: () => null
};

/* eslint-disable react/forbid-prop-types */
LeftMenu.propTypes = {
    path: PropTypes.string,
    tabValue: PropTypes.number,
    setTab: PropTypes.func,
    updatePath: PropTypes.func
};
/* eslint-enable react/forbid-prop-types */

export default LeftMenu;
