import React from 'react';
import { arrayOf, func, string, shape } from 'prop-types';
import facilityShape from 'data/models/FacilityModel';

import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SearchIcon from '@mui/icons-material/Search';

import breakpointsNames from '../../styles/ResponsiveConstants';

import StyledCheckbox from '../../components/Common/StyledCheckbox/StyledCheckbox';
import StyledTextField from '../../components/Common/StyledTextField/StyledTextField';

import StyledListItem from '../../components/Common/StyledListItem/StyledListItem';
import StyledTooltip from '../../components/Common/StyledTooltip/StyledTooltip';
import StyledCardHeader from '../../components/Common/StyledCardHeader/StyledCardHeader';
import { Enum } from '../../util/EnumHelper';

const useStyles = (theme) => ({
    cardContainer: {
        fullWidth: true,
        height: 235.75,
        [theme.breakpoints.up(breakpointsNames.md)]: {
            marginRight: -20
        }
    },
    textFieldSearch: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing(0,
            0),
        fontWeight: 'bold',
        fontSize: '12px',
        backgroundColor: theme.palette.offWhite
    },
    list: {
        width: '100%',
        height: 150,
        overflow: 'auto',
        backgroundColor: theme.palette.offWhite,
        [theme.breakpoints.down(breakpointsNames.xl)]: {
            width: '100%'
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            width: '100%'
        }
    },
    listItem: {
        width: '100%'
    },
    listItemIcon: { minWidth: 30 },
    listItemText: {
        fontSize: '12px',
        textOverflow:'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }
});

function CustomList({
    classes,

    title,
    labelSearchText,

    items = [],
    unfilteredItems = [],

    checkedList = [],
    onUpdateCheckedList,
        
    updateSearch,
    searchValue,
    removeAllSchools

}) {
    const handleLostFocus = (event) => {
        if (event.key === 'Enter') {
            document.activeElement.blur();
        }
    };

    const onSearch = event => {
        if(event) {
            const searchText = event.target.value.toUpperCase();

            updateSearch(searchText);
        }
    };

    const renderList = (list) => (
        list.map(value => (
            <StyledListItem
                key={value.id}
                button
                onClick={() => onUpdateCheckedList(value)}
                disableGutters
                className={classes.listItem}
            >
                <ListItemIcon className={classes.listItemIcon}>
                    <StyledCheckbox
                        color="secondary"
                        disableRipple
                        isChecked={checkedList.filter(x => x === value.id).length !== 0}
                        inputProps={{
                            'aria-labelledby': `transfer-list-all-item-${value}-label`
                        }}
                        size="small"
                        tabIndex={-1}
                    />
                </ListItemIcon>
                <StyledTooltip title={value.name} placement="top-start">
                    <ListItemText
                        id={`transfer-list-all-item-${value}-label`}
                        primary={value.name}
                        className={classes.listItemText}
                        disableTypography
                    />
                </StyledTooltip>
            </StyledListItem>
        ))
    );

    return (
        <Card className={classes.cardContainer}>

            <StyledTextField
                className={classes.textFieldSearch}
                id="searchSchoolsTextField"
                inputProps={{ "data-testid": "searchSchoolsTextField" }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                label={labelSearchText}
                name="StyledTextField"
                onChange={onSearch}
                onKeyPress={handleLostFocus}
                value={searchValue}
            />
            <StyledCardHeader
                className={classes.cardHeader}
                disableTypography
                avatar={
                    <StyledCheckbox
                        color="secondary"
                        indeterminate={
                            checkedList.length < Enum.maxAllowedSchoolsLength
                            && checkedList.length !== 0
                        }
                        inputProps={{ 'aria-label': 'all items selected' }}
                        isChecked={
                            checkedList.length === Enum.maxAllowedSchoolsLength
                        }
                        size="small"
                        onClick={() => removeAllSchools()}
                    />
                }
                title={`${title} ${checkedList.length}/${Enum.maxAllowedSchoolsLength}`}
            />
            <Divider />
            <List
                className={classes.list}
                component="div" role="list" disablePadding
            >
                {renderList(checkedList
                    .filter(id => unfilteredItems.findIndex(school => school.id === id) === -1)
                    .map(id => ({ id, name: 'Deleted school (Remove before save)' })))}
                {renderList(items.filter(school => checkedList.includes(school.id)))}
                {renderList(items.filter(school => !checkedList.includes(school.id)))}

                <ListItem />
            </List>
        </Card>
    );
}

CustomList.defaultProps = {
    title: '',
    labelSearchText: '',
    items: [],
    unfilteredItems: [],
    checkedList: [],
    onUpdateCheckedList: () => null,
    updateSearch: () => null,
    searchValue: '',
    removeAllSchools: () => null
};

CustomList.propTypes = {
    title: string,
    labelSearchText: string,
    items: arrayOf(shape(facilityShape)),
    unfilteredItems: arrayOf(shape(facilityShape)),
    checkedList: arrayOf(string),
    onUpdateCheckedList: func,
    updateSearch: func,
    searchValue: string,
    removeAllSchools: func
};

export default withStyles(useStyles)(CustomList);
