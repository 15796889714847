import React, { useEffect } from 'react';
import {
    array,
    func
} from "prop-types";
import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import TRNavContainer from 'redux/containers/transportationRequest/TRNavContainer';
import StyledWrapper from 'components/Common/StyledWrapper/StyledWrapper';

import { localize } from 'util/Localizer';
import { museoSansFont } from 'util/EnumHelper';
import TripBuilderContentContainer
    from 'redux/containers/transportationRequest/tripBuilder/TripBuilderContentContainer';

const LIMIT_TRIPS = 5;

const AddAnotherTripButton = styled(StyledButton)(({ theme }) => ({
    backgroundColor: theme.palette.transparent,
    padding: '8px 20px',
    borderRadius: 20,
    border: `1px solid ${theme.palette.everDrivenLightBlue}`,
    width: '100%',
    height: 48,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: museoSansFont,
    color: theme.palette.everDrivenLightBlue,
    boxShadow: 'none',
    "&:hover": {
        boxShadow: 'none',
        backgroundColor: theme.palette.transparent
    }
}));

function TripBuilder({
    trips,
    addTrip,
    initialSetup
}) {
    const renderAddAnotherTrip = () => (
        (trips.length < LIMIT_TRIPS) &&
            <Grid item xs={12} sx={{paddingLeft: '25px', paddingRight: '25px'}}>
                <Box sx={{ margin: '0 105px', paddingBottom: '20px' }}>
                    <AddAnotherTripButton
                        testId="addAnotherTripButton"
                        type="button"
                        onClick={() => addTrip()}
                    >
                        {localize('transportationRequest.addAnotherTrip')}
                    </AddAnotherTripButton>
                </Box>
            </Grid>
    );

    useEffect(() => {
        if(trips?.length === 0) {
            initialSetup();
        }
    }, []);

    return (
        <Grid container sx={{ flexDirection: 'column', width: '100%', alignItems: 'center' }} >
            {trips?.map((trip, index) => (
                <StyledWrapper key={`Trip-${trips.findIndex(t => t === trip)}`}>
                    <Box sx={{
                        margin: '0 131px',
                        padding: trips.length > 1 && index === 0 ? '30px 0 40px' : '30px 0 20px'
                    }}>
                        <TripBuilderContentContainer tripNumber={index} />
                    </Box>
                    {trips.length === index+1 &&
                        <>
                            {renderAddAnotherTrip()}
                            <TRNavContainer />
                        </>
                    }
                </StyledWrapper>

            ))}
        </Grid>
    );
}

TripBuilder.defaultProps = {
    trips: [],
    addTrip: null,
    initialSetup: () => {}
};

/* eslint-disable react/forbid-prop-types */
TripBuilder.propTypes = {
    trips: array,
    addTrip: func,
    initialSetup: func
};

export default TripBuilder;