import React from "react";
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import CommonStyles from 'styles/CommonStyles';
import colorConstants from "styles/ColorConstants";
import { museoSansFont700 } from "util/EnumHelper";
import VideoHeaderLabel from './VideoHeaderLabel';

function StyledVideoHeader({
    title,
    icon,
    buttonColor,
    textButton,
    isRequired,
    testid,
    onClick
}) {
    return (
        <Grid item xs={12} sx={{
            display: 'flex',
            alignItems: 'center'
        }}>
            <Grid item xs={8} sx={{ textAlign: 'left' }}>
                <Typography sx={{ wordBreak: 'beak-word'}}>
                    <VideoHeaderLabel
                        markStyle={{ fontFamily: museoSansFont700 }}
                        label={title}
                        isRequired={isRequired}
                        testid={testid}
                    />
                </Typography>
            </Grid>
            <Grid item xs={4}>
                {textButton &&
                <StyledButton
                    style={{
                        ...CommonStyles.linkButton,
                        color: buttonColor,
                        marginLeft: 'auto',
                        alignItems: 'center',
                        height: 26
                    }}
                    type="button"
                    startIcon={icon}
                    variant="text"
                    testId={`${textButton}-Button`}
                    onClick={() => onClick()}
                >
                    {textButton}
                </StyledButton>
                }
            </Grid>
        </Grid>
    );
}

StyledVideoHeader.defaultProps = {
    title: '',
    icon: null,
    buttonColor: colorConstants.EVERDRIVENLIGHTBLUE,
    textButton: '',
    isRequired: false,
    onClick: null,
    testid: null
};

/* eslint-disable react/forbid-prop-types */
StyledVideoHeader.propTypes = {
    isRequired: PropTypes.bool,
    icon: PropTypes.object,
    buttonColor: PropTypes.string,
    textButton: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    testid: PropTypes.string
};

export default StyledVideoHeader;