/* eslint-disable max-lines */
import {
    cleanForm,
    setCurrentContact,
    setHasGrade,
    setNumberOfContacts,
    setPopulationTypeFormat,
    setPopulationTypes,
    setShowRemoveContactModal,
    setTabValue,
    setTitle,
    setTransportationNeedFormat,
    setTransportationNeeds,
    updateModel,
    updateOldDraftModel,
    setOldDraftPopulationTypes,
    setOldDraftTransportationNeeds,
    setHasMorePT,
    setHasTN,
    setShowStudentNotes,
    setDisabledCarSeat,
    setTrips,
    setDraftCanBeSaved,
    setOldDraftTrips,
    setShowErrorSubmitModal,
    setShowFailedSubmitModal,
    setShowSuccessSubmitModal,
    setTripNumberError,
    setIsDraftSelected,
    setCorrelationId,
    setSubmitButtonDisabled,
    setCalledGetCorrelationId
} from "redux/actions/transportationRequest/transportationRequestActions";
import mrmFetch from "util/fetchUtil";
import {
    additionalInformation,
    basicInformation,
    contactInformation,
    populationType,
    trModel,
    transportationNeed
} from "data/models/transportationRequest/TransportationRequestModel";
import Auth from "auth/Auth";

import { getStudentProfile, searchStudents } from "services/studentService";
import { addTimeZone } from "util/dateUtils";
import { Enum, StatePostalAbv, addressType, paths } from "util/EnumHelper";
import {
    TransportationRequestRecurringTripOptions,
    TransportationRequestTripTypesOptions,
    address,
    days,
    daysTime,
    newTrip
} from "data/models/transportationRequest/TripBuilderModel";
import { TransportationNeedOptions } from "data/models/descriptions/TransportationRequestModels";
import { toCamelCase, toUpperFirstLetter } from "util/textFormatters";
import { dataAccounts } from "util/data/dataDecorator";
import { errorPageSetContent, errorPageSetTitle } from "redux/actions/errorPageActions";
import { localize } from "util/Localizer";
import { getCorrelationId } from 'services/transportationRequestService';
import { formatPopulationType, formatTransportationNeeds } from "./transportationRequestStudentWorker";
import { onValidDraftCanBeSaved } from "./transportationRequestDraftWorker";
import { updatePath } from "../pathWorker";

const textResponse400 = "The current user does not have enough permission to create new users";

const setContact2 = (tripModel) => ({
    contact2Name: tripModel.contact3Name,
    contact2Relationship: tripModel.contact3Relationship,
    contact2PhoneNumber: tripModel.contact3PhoneNumber,
    contact2AltPhoneNumber: tripModel.contact3AltPhoneNumber,
    contact2EmailAddress: tripModel.contact3EmailAddress,
    contact2AppAccess: tripModel.contact3AppAccess
});

const setContact3 = () => ({
    contact3Name: '',
    contact3Relationship: '',
    contact3PhoneNumber: '',
    contact3AltPhoneNumber: '',
    contact3EmailAddress: '',
    contact3AppAccess: true
});

const updateModelWithContacts = (contacts) => (dispatch, getState) => {
    const { tripModel } = getState().TransportationRequestReducers;

    let modelUpdated = {...tripModel};

    contacts.forEach((contact, index) => {
        modelUpdated = {
            ...modelUpdated,
            [`contact${index+1}Name`]: contact.name || "",
            [`contact${index+1}Relationship`]: contact.relationship || "",
            [`contact${index+1}PhoneNumber`]: contact.mobilePhone || "",
            [`contact${index+1}AltPhoneNumber`]: contact.alternatePhone || "",
            [`contact${index+1}EmailAddress`]: contact.emailAddress || "",
            [`contact${index+1}AppAccess`]: contact.parentAppAccessFlag
        };
    });

    dispatch(updateModel(modelUpdated));
};

const updatePopulationTypes = (studentPopulationTypes) => (dispatch) => {
    let populationTypeUpdated = {...populationType};

    Object.keys(Enum.populationTypes).forEach((population) => {
        if(studentPopulationTypes.includes(Enum.populationTypes[population])) {
            populationTypeUpdated = {
                ...populationTypeUpdated,
                [`populationType${population}`]: true
            };
        }
    });

    if (populationTypeUpdated.populationTypeSchoolofChoice || populationTypeUpdated.populationTypeMedFragile
        || populationTypeUpdated.populationTypeMultiDistrict || populationTypeUpdated.populationTypeNCLB
        || populationTypeUpdated.populationTypeMagnet || populationTypeUpdated.populationTypeATP
        || populationTypeUpdated.populationTypeESY || populationTypeUpdated.populationTypeSpecialEvents) {
        dispatch(setHasMorePT(true));
    }

    dispatch(setPopulationTypes(populationTypeUpdated));
    dispatch(formatPopulationType());
};

const updateTransportationNeeds = (studentTransportationNeeds) => (dispatch, getState) => {
    const { tripModel } = getState().TransportationRequestReducers;

    let transportationNeedUpdated = {...transportationNeed};
    const modelUpdated = {...tripModel};

    Object.keys(TransportationNeedOptions).forEach((tNeed) => {
        if(studentTransportationNeeds.includes(TransportationNeedOptions[tNeed])) {
            transportationNeedUpdated = {
                ...transportationNeedUpdated,
                [tNeed]: true
            };
        }
    });

    if (transportationNeedUpdated.monitorNeeded || transportationNeedUpdated.wheelchair
        || transportationNeedUpdated.mustRideAlone || transportationNeedUpdated.safetyVest
        || transportationNeedUpdated.booster || transportationNeedUpdated.buckleGuard
        || transportationNeedUpdated.carSeat) {
        dispatch(setHasTN(true));

        if(transportationNeedUpdated.monitorNeeded) {
            modelUpdated.selectedMonitorNeeded = Enum.monitorNeeded.districtMonitor;
        }

        if(transportationNeedUpdated.safetyVest) {
            modelUpdated.selectedSafetyVest = Enum.safetyVestOptions.extraSmall;
        }

        if(transportationNeedUpdated.wheelchair) {
            dispatch(setDisabledCarSeat(true));
            transportationNeedUpdated.carSeat = false;
            modelUpdated.selectedWheelchair = Enum.wheelchairOptions.needsRamp;
        }
        dispatch(updateModel(modelUpdated));
    }

    dispatch(setTransportationNeeds(transportationNeedUpdated));
    dispatch(formatTransportationNeeds());
};

const populateFormWithStudent = (student) => (dispatch) => {
    const model = {
        ...trModel,
        studentId: student.clientMemberCode ?? '',
        studentFirstName: student.firstName ?? '',
        studentLastName: student.lastName ?? '',
        studentDateOfBirth: student.dateOfBirth ? new Date(student.dateOfBirth) : null
    };

    const contactsLength = student.contacts.length === 0 ? 1 : student.contacts.length;

    dispatch(updateModel(model));
    dispatch(updateModelWithContacts(student.contacts));
    dispatch(setNumberOfContacts(contactsLength));
    dispatch(updatePopulationTypes(student.populationTypes));
    dispatch(updateTransportationNeeds(student.transportationEquipmentNeeds));
};

const hasMorePT = populationTypes => populationTypes.populationTypeSchoolofChoice
    || populationTypes.populationTypeMedFragile
    || populationTypes.populationTypeMultiDistrict || populationTypes.populationTypeNCLB
    || populationTypes.populationTypeMagnet || populationTypes.populationTypeATP
    || populationTypes.populationTypeESY || populationTypes.populationTypeSpecialEvents;

const hasTN = transportationNeeds => transportationNeeds.handToHand
    || transportationNeeds.monitorNeeded
    || transportationNeeds.wheelchair || transportationNeeds.mustRideAlone
    || transportationNeeds.safetyVest || transportationNeeds.booster
    || transportationNeeds.buckleGuard || transportationNeeds.carSeat;

const getNumberOfContacts = model => {
    let nContacts = 1;

    if (model.contact2Name || model.contact2Relationship || model.contact2PhoneNumber || model.contact2AltPhoneNumber
        || model.contact2EmailAddress) {
        nContacts = 2;
    }

    if (model.contact3Name || model.contact3Relationship || model.contact3PhoneNumber || model.contact3AltPhoneNumber
        || model.contact3EmailAddress) {
        nContacts = 3;
    }

    return nContacts;
};

const formatModelDate = date => !date ? null : new Date(addTimeZone(date));

const populateOldDraftData = (tripModel) => ({
    contact1Name: tripModel.parent1FirstName,
    contact1Relationship: tripModel.parent1Relationship,
    contact1PhoneNumber: tripModel.parent1MobilePhone,
    contact1AltPhoneNumber: tripModel.parent1AltPhone,
    contact1EmailAddress: tripModel.parent1EmailAddress,
    contact1AppAccess: tripModel.parent1AppAccess,
    contact2Name: tripModel.contact1FirstName,
    contact2Relationship: tripModel.contact1Relationship,
    contact2PhoneNumber: tripModel.contact1MobilePhone,
    contact2AltPhoneNumber: tripModel.contact1AltPhone,
    contact2EmailAddress: tripModel.contact1EmailAddress,
    contact2AppAccess: tripModel.contact1AppAccess,
    contact3Name: tripModel.contact2FirstName,
    contact3Relationship: tripModel.contact2Relationship,
    contact3PhoneNumber: tripModel.contact2MobilePhone,
    contact3AltPhoneNumber: tripModel.contact2AltPhone,
    contact3EmailAddress: tripModel.contact2EmailAddress,
    contact3AppAccess: tripModel.contact2AppAccess
});

const getAddress = (type, tripModel, type2, hasCondition) => {
    let currentType = type;
    const newAddress = { ...address };

    if (hasCondition) {
        currentType = type2;
    }

    newAddress.addressLine1 = tripModel[`${currentType}AddressLine1`];
    newAddress.addressLine2 = tripModel[`${currentType}AddressLine2`];
    newAddress.city = toUpperFirstLetter(tripModel[`${currentType}AddressCity`]);
    newAddress.state = StatePostalAbv[toCamelCase(tripModel[`${currentType}AddressState`])]
        ?? tripModel[`${currentType}AddressState`];
    newAddress.postal = tripModel[`${currentType}AddressZipCode`];
    newAddress.country = 'US';
    if (newAddress.addressLine2) {
        newAddress.selectedAddress = `${newAddress.addressLine1} ${newAddress.addressLine2}, ${newAddress.city}, ` +
            `${newAddress.state} ${newAddress.postal}`;
    } else {
        newAddress.selectedAddress = `${newAddress.addressLine1}, ${newAddress.city}, ` +
            `${newAddress.state} ${newAddress.postal}`;
    }

    return newAddress;
};

const setIsRecurringTrip = (tripModel) => {
    if (tripModel.tripStartDate !== tripModel.tripEndDate) {
        const availableDays = [];
        const daysOfWeek = {
            schoolMonday: days.monday,
            schoolTuesday: days.tuesday,
            schoolWednesday: days.wednesday,
            schoolThursday: days.thursday,
            schoolFriday: days.friday,
            schoolSaturday: days.saturday,
            schoolSunday: days.sunday
        };

        Object.keys(daysOfWeek).forEach((day) => {
            if (tripModel[day]) {
                availableDays.push(daysOfWeek[day]);
            }
        });

        return {
            recurring: TransportationRequestRecurringTripOptions.recurring,
            days: availableDays
        };
    }

    return {
        recurring: TransportationRequestRecurringTripOptions.oneTime
    };
};

const setTripTimes = (schedule) => {
    const arrivalTimes = {
        monday: schedule.schoolArrivalMonday,
        tuesday: schedule.schoolArrivalTuesday,
        wednesday: schedule.schoolArrivalWednesday,
        thursday: schedule.schoolArrivalThursday,
        friday: schedule.schoolArrivalFriday,
        saturday: schedule.schoolArrivalSaturday,
        sunday: schedule.schoolArrivalSunday
    };
    const pickUpTimes = {
        monday: schedule.schoolPickupMonday,
        tuesday: schedule.schoolPickupTuesday,
        wednesday: schedule.schoolPickupWednesday,
        thursday: schedule.schoolPickupThursday,
        friday: schedule.schoolPickupFriday,
        saturday: schedule.schoolPickupSaturday,
        sunday: schedule.schoolPickupSunday
    };
    const distinctArrivalTimes = [...new Set(Object.values(arrivalTimes).filter(time => time))];
    const distinctPickUpTimes = [...new Set(Object.values(pickUpTimes).filter(time => time))];
    const [firstArrivalTime] = distinctArrivalTimes;
    const [firstPickUpTime] = distinctPickUpTimes;
    const times = {
        arrivalTime: { ...daysTime },
        arrivalSingleTime: '',
        departureTime: { ...daysTime },
        departureSingleTime: '',
        arrivalHasTimeMultiples: false,
        departureHasTimeMultiples: false,
        hasArrivalTime: distinctArrivalTimes.length > 0
            || (distinctArrivalTimes.length === 0 && distinctPickUpTimes.length === 0),
        hasPickUpTime: distinctPickUpTimes.length > 0
    };

    if (distinctArrivalTimes.length > 1) {
        times.arrivalHasTimeMultiples = true;
        times.arrivalTime = arrivalTimes;
    } else {
        times.arrivalSingleTime = firstArrivalTime;
    }

    if (distinctPickUpTimes.length > 1) {
        times.departureHasTimeMultiples = true;
        times.departureTime = pickUpTimes;
    } else {
        times.departureSingleTime = firstPickUpTime;
    }

    return times;
};

const setTripTypes = (hasArrivalTime, hasPickUpTime, tripModel) => {
    let tripType;

    if (hasArrivalTime && hasPickUpTime) {
        if (tripModel.amPickupLocation === addressType.homeAddress) {
            if (tripModel.pmDropOffLocation === addressType.differentAddress) {
                tripType = [TransportationRequestTripTypesOptions.multiStop];
            } else {
                tripType = [TransportationRequestTripTypesOptions.roundTrip];
            }
        } else if (tripModel.pmDropOffLocation === addressType.homeAddress) {
            tripType = [TransportationRequestTripTypesOptions.multiStop];
        } else if (tripModel.pmDropOffLocation === addressType.sameAddressAsPickUp) {
            tripType = [TransportationRequestTripTypesOptions.roundTrip];
        } else {
            tripType = [TransportationRequestTripTypesOptions.multiStop];
        }
    } else {
        tripType = [TransportationRequestTripTypesOptions.oneWay];
    }

    return tripType;
};

const setGoingToAddress = (tripModel) => {
    switch (tripModel.pmDropOffLocation) {
    case addressType.homeAddress:
        return getAddress('student', tripModel);
    case addressType.sameAddressAsPickUp:
        return getAddress('pickupLocation', tripModel);
    default:
        return getAddress('dropOffLocation', tripModel);
    }
};

const setSelectedMonitorNeeded = hasMonitor => hasMonitor ? Enum.monitorNeeded.districtMonitor : '';
const setSelectedSafetyVest = hasSafety => hasSafety ? Enum.safetyVestOptions.extraSmall : '';
const setBaseModel = (tripModel) => ({
    studentId: decodeURIComponent(tripModel.studentId),
    studentFirstName: decodeURIComponent(tripModel.studentFirstName),
    studentLastName: decodeURIComponent(tripModel.studentLastName),
    studentDateOfBirth: formatModelDate(tripModel.studentDateOfBirth),
    studentGrade: tripModel.studentGrade
});

const updateDraft = (model, populationTypeModel) => dispatch => {
    dispatch(setHasMorePT(hasMorePT(populationTypeModel)));
    dispatch(setNumberOfContacts(getNumberOfContacts(model)));
    dispatch(setOldDraftPopulationTypes(populationTypeModel));
    dispatch(setPopulationTypes(populationTypeModel));
    dispatch(setShowStudentNotes(!!model.studentNotes));
    dispatch(setHasGrade(!!model.studentGrade));
    dispatch(updateOldDraftModel(model));
    dispatch(updateModel(model));
    dispatch(formatPopulationType());
    dispatch(formatTransportationNeeds());
    dispatch(setDraftCanBeSaved(false));
};

const setOldDraftData = (item) => dispatch => {
    const {
        populationTypes,
        transportationEquipmentNeeds,
        tripModel,
        schedule
    } = item.draftObject.Draft;

    let model = setBaseModel(tripModel);

    const trip = newTrip();
    const times = setTripTimes(schedule);
    const { hasArrivalTime, hasPickUpTime } = times;
    const recurringValues = setIsRecurringTrip(tripModel);

    model = { ...model, ...populateOldDraftData(tripModel) };

    dispatch(setHasTN(hasTN(transportationEquipmentNeeds)));

    model.selectedMonitorNeeded = setSelectedMonitorNeeded(transportationEquipmentNeeds.monitorNeeded);
    model.selectedSafetyVest = setSelectedSafetyVest(transportationEquipmentNeeds.safetyVest);

    if(transportationEquipmentNeeds.wheelchair) {
        dispatch(setDisabledCarSeat(true));
        transportationEquipmentNeeds.carSeat = false;
        model.selectedWheelchair = Enum.wheelchairOptions.needsRamp;
    }

    dispatch(setOldDraftTransportationNeeds(transportationEquipmentNeeds));
    dispatch(setTransportationNeeds(transportationEquipmentNeeds));

    trip.account = tripModel.accountNumber;
    trip.startDate = tripModel.tripStartDate;
    trip.endDate = tripModel.tripEndDate;
    trip.recurring = recurringValues.recurring;
    trip.days = recurringValues.days;
    trip.arrivalHasTimeMultiples = times.arrivalHasTimeMultiples;
    trip.arrivalTime = times.arrivalTime;
    trip.arrivalSingleTime = times.arrivalSingleTime;
    trip.departureHasTimeMultiples = times.departureHasTimeMultiples;
    trip.departureTime = times.departureTime;
    trip.departureSingleTime = times.departureSingleTime;

    // Verifying trip types conditions
    trip.tripTypes = setTripTypes(hasArrivalTime, hasPickUpTime, tripModel);

    // Verifying conditions for arrival and departure
    if (hasArrivalTime) {
        if (tripModel.amPickupLocation === addressType.homeAddress) {
            trip.arrival.tripLeavingFrom = getAddress('student', tripModel);
            trip.arrival.tripGoingTo = getAddress('school', tripModel);
            trip.departure.tripLeavingFrom = getAddress('school', tripModel);
            trip.departure.tripGoingTo =
                getAddress(
                    'student',
                    tripModel,
                    'dropOffLocation',
                    trip.tripTypes[0] === TransportationRequestTripTypesOptions.multiStop
                );
        } else {
            trip.arrival.tripLeavingFrom = getAddress('pickupLocation', tripModel);
            trip.arrival.tripGoingTo = getAddress('school', tripModel);
            trip.departure.tripLeavingFrom = getAddress('school', tripModel);
            trip.departure.tripGoingTo = setGoingToAddress(tripModel);
        }
    } else {
        // when there is not arrival time, we use firstPickUpTime y pickUpTimes variables
        trip.arrivalSingleTime = times.departureSingleTime;
        trip.arrivalTime = times.departureTime;

        if (tripModel.amPickupLocation === addressType.homeAddress) {
            trip.arrival.tripLeavingFrom = getAddress('school', tripModel);
            trip.arrival.tripGoingTo =
                getAddress(
                    'student',
                    tripModel,
                    'dropOffLocation',
                    tripModel.pmDropOffLocation === addressType.differentAddress
                );
        } else {
            trip.arrival.tripLeavingFrom = getAddress('school', tripModel);
            trip.arrival.tripGoingTo = setGoingToAddress(tripModel);
        }
    }

    dispatch(setOldDraftTrips([trip]));
    dispatch(setTrips([trip]));
    dispatch(updateDraft(model, populationTypes));
};

const populateFormWithDraft = (item) => dispatch => {
    const {
        populationTypes,
        transportationNeeds,
        tripModel,
        trips,
        isNewDraft,
        inputSource,
        correlationId
    } = item.draftObject.Draft;

    let model = setBaseModel(tripModel);

    dispatch(setIsDraftSelected(true));
    dispatch(setCorrelationId(correlationId));

    if (isNewDraft) {
        model = { ...tripModel, ...model };

        dispatch(setHasTN(hasTN(transportationNeeds)));
        dispatch(setOldDraftTransportationNeeds(transportationNeeds));
        dispatch(setTransportationNeeds(transportationNeeds));
        dispatch(setOldDraftTrips(trips));
        dispatch(setTrips(trips));
        dispatch(updateDraft(model, populationTypes));
    } else if (inputSource === 'Upload') {
        const trip = newTrip();

        trip.account = dataAccounts()[0]?.id;
        trip.arrival.tripLeavingFrom = getAddress('student', tripModel);
        trip.arrival.tripGoingTo = getAddress('school', tripModel);
        trip.departure.tripGoingTo = getAddress('student', tripModel);
        trip.departure.tripLeavingFrom = getAddress('school', tripModel);

        dispatch(setOldDraftTrips([trip]));
        dispatch(setTrips([trip]));
        dispatch(updateOldDraftModel(model));
        dispatch(updateModel(model));
    } else {
        dispatch(setOldDraftData(item));
    }
};

export const onAddForm = () => dispatch => {
    const model = {
        ...basicInformation,
        ...contactInformation,
        contact1AppAccess: Auth.getConfig().parentAppEnabled,
        contact2AppAccess: Auth.getConfig().parentAppEnabled,
        contact3AppAccess: Auth.getConfig().parentAppEnabled,
        ...additionalInformation
    };

    dispatch(updateOldDraftModel({}));
    dispatch(setOldDraftPopulationTypes(populationType));
    dispatch(setOldDraftTransportationNeeds(transportationNeed));
    dispatch(setDraftCanBeSaved(false));
    dispatch(updateModel(model));
    dispatch(setTitle(''));
    dispatch(setHasGrade(false));
    dispatch(setNumberOfContacts(1));
    dispatch(setPopulationTypes(populationType));
    dispatch(setTransportationNeeds(transportationNeed));
    dispatch(setTabValue(0));
    dispatch(setTransportationNeedFormat([]));
    dispatch(setPopulationTypeFormat([]));
};

export const onLoadForm = () => dispatch => {
    dispatch(cleanForm());
    dispatch(onAddForm());
};

export const onUpdateModel = (evt) => (dispatch, getState) => {
    const { correlationId, calledGetCorrelationId } = getState().TransportationRequestReducers;
    const { name, type } = evt.target;
    let { value } = evt.target;

    if (correlationId === '' && calledGetCorrelationId === false) {
        (async () => {
            dispatch(setCalledGetCorrelationId(true));
        
            window.log("calling API to get CorrelationId");
            const response = await getCorrelationId();
    
            if (response?.correlationId) {
                dispatch(setCorrelationId(response.correlationId));
                window.log("CorrelationId set to", response.correlationId);
            } else {
                window.log("Error getting CorrelationId");
            }
        })();
    }

    if (type === 'checkbox') {
        value = evt.target.checked;
    }

    dispatch(updateModel({
        [name]: name === 'studentId' ? value.trim() : value
    }));
    dispatch(onValidDraftCanBeSaved());

    switch (name) {
    case 'selectedWheelchair':
    case 'selectedSafetyVest':
    case 'selectedMonitorNeeded':
        dispatch(formatTransportationNeeds());
        break;
    default:
        break;
    }
};

export const onLoadRemoveContactModal = (currentContact) => (dispatch) => {
    dispatch(setCurrentContact(currentContact));
    dispatch(setShowRemoveContactModal(true));
};

export const onRemoveContactSection = () => (dispatch, getState) => {
    const { currentContact, numberOfContacts, tripModel } = getState().TransportationRequestReducers;

    let newModel = tripModel;

    switch (currentContact) {
    case 1:
        newModel.contact1Name = tripModel.contact2Name;
        newModel.contact1Relationship = tripModel.contact2Relationship;
        newModel.contact1PhoneNumber = tripModel.contact2PhoneNumber;
        newModel.contact1AltPhoneNumber = tripModel.contact2AltPhoneNumber;
        newModel.contact1EmailAddress = tripModel.contact2EmailAddress;
        newModel.contact1AppAccess = tripModel.contact2AppAccess;
        newModel = { ...newModel, ...setContact2(tripModel) };
        newModel = { ...newModel, ...setContact3() };
        break;
    case 2:
        newModel = { ...newModel, ...setContact2(tripModel) };
        newModel = { ...newModel, ...setContact3() };
        break;
    case 3:
        newModel = { ...newModel, ...setContact3() };
        break;
    default:
        break;
    }

    dispatch(updateModel(newModel));
    dispatch(setNumberOfContacts(numberOfContacts - 1));
    dispatch(setShowRemoveContactModal(false));
    dispatch(onValidDraftCanBeSaved());
};

const setAccountGUID = (id) => {
    const accounts = Auth?.getAccounts();
    const account = accounts.filter(ac => ac.AccountID === id);

    return account[0].AccountGUID;
};

const setDepartureTime = (trip) => {
    if(trip.tripTypes[0] !== TransportationRequestTripTypesOptions.oneWay
        && trip.departureHasTimeMultiples) {
        return { ...trip.departureTime };
    }

    return null;
};

const setDepartureSingleTime = (trip) => {
    if(trip.tripTypes[0] !== TransportationRequestTripTypesOptions.oneWay
        && !trip.departureHasTimeMultiples
        && trip.departureSingleTime !== '') {
        return trip.departureSingleTime;
    }

    return null;
};

const setArrivalTime = (trip) => {
    if(trip.arrivalHasTimeMultiples) {
        return { ...trip.arrivalTime };
    }

    return null;
};

const setArrivalSingleTime = (trip) => {
    if(!trip.arrivalHasTimeMultiples
        && trip.arrivalSingleTime !== '') {
        return trip.arrivalSingleTime;
    }

    return null;
};

const setDepartureNotes = (trip) => {
    if(trip.tripTypes[0] !== TransportationRequestTripTypesOptions.oneWay
        && trip.departureNotes !== '') {
        return trip.departureNotes;
    }

    return null;
};

const formatTrips = (trips) => {
    const formatedTrips = [];

    trips.forEach(trip => {
        formatedTrips.push({
            accountId: setAccountGUID(trip.account),
            recurring: trip.recurring,
            startDate: trip.startDate,
            endDate: trip.endDate,
            days: trip.days.length === 0 ? [] : [...trip.days],
            tripType: trip.tripTypes[0],
            arrival: { ...trip.arrival },
            arrivalTime: setArrivalTime(trip),
            arrivalSingleTime: setArrivalSingleTime(trip),
            arrivalNotes: trip.arrivalNotes !== '' ? trip.arrivalNotes : null,
            departure:  trip.tripTypes[0] !== TransportationRequestTripTypesOptions.oneWay
                ? { ...trip.departure }
                : null,
            departureTime: setDepartureTime(trip),
            departureSingleTime: setDepartureSingleTime(trip),
            departureNotes: setDepartureNotes(trip)
        });
    });

    return formatedTrips;
};

export const closeFailedSubmitModal = () => (dispatch) => {
    dispatch(setShowFailedSubmitModal(false));
};

export const closeErrorSubmitModal = () => (dispatch) => {
    dispatch(setShowErrorSubmitModal(false));
};

export const closeSuccessSubmitModal = () => (dispatch) => {
    dispatch(setShowSuccessSubmitModal(false));
};

export const newTR = () => (dispatch) => {
    dispatch(updatePath(paths.TransportationRequest.path));

    dispatch(onLoadForm());
    dispatch(setTabValue(0));

    dispatch(closeSuccessSubmitModal());
};

export const editTrip = () => (dispatch) => {
    dispatch(setTabValue(2));
    dispatch(closeFailedSubmitModal());
};

const checkAccounts = (trips) => (dispatch) => {
    let isValid = true;

    trips.forEach((trip, index) => {
        const selectedAccount = Auth.getAccounts().find(x => x.AccountID === trip.account);

        if (!(selectedAccount?.Email)) {
            dispatch(setTripNumberError(index));
            isValid = false;
        }
    });

    return isValid;
};

export const submitForm = (rType = 'new') => async (dispatch, getState) => {
    dispatch(setSubmitButtonDisabled(true));
    const {
        tripModel,
        trips,
        isDraftSelected,
        correlationId
    } = getState().TransportationRequestReducers;

    const validAccounts = dispatch(checkAccounts(trips));

    if(!validAccounts) {
        dispatch(setShowErrorSubmitModal(true));
        dispatch(setSubmitButtonDisabled(false));
    } else {
        const requestModel = {
            requestType: rType,
            correlationId,
            isDraftSelected,
            transportationNeeds: tripModel.transportationNeeds,
            populationTypes: tripModel.populationType,
            basicInformation: {
                studentId: tripModel.studentId,
                studentFirstName: tripModel.studentFirstName,
                studentLastName: tripModel.studentLastName,
                studentDateOfBirth: tripModel.studentDateOfBirth,
                studentGrade: tripModel.studentGrade
            },
            contactInformation: {
                contact1Name: tripModel.contact1Name,
                contact1Relationship: tripModel.contact1Relationship,
                contact1PhoneNumber: tripModel.contact1PhoneNumber,
                contact1AltPhoneNumber: tripModel.contact1AltPhoneNumber,
                contact1EmailAddress: tripModel.contact1EmailAddress,
                contact1AppAccess: tripModel.contact1AppAccess,
                contact2Name: tripModel.contact2Name,
                contact2Relationship: tripModel.contact2Relationship,
                contact2PhoneNumber: tripModel.contact2PhoneNumber,
                contact2AltPhoneNumber: tripModel.contact2AltPhoneNumber,
                contact2EmailAddress: tripModel.contact2EmailAddress,
                contact2AppAccess: tripModel.contact2Relationship !== '' ? tripModel.contact2AppAccess : false,
                contact3Name: tripModel.contact3Name,
                contact3Relationship: tripModel.contact3Relationship,
                contact3PhoneNumber: tripModel.contact3PhoneNumber,
                contact3AltPhoneNumber: tripModel.contact3AltPhoneNumber,
                contact3EmailAddress: tripModel.contact3EmailAddress,
                contact3AppAccess: tripModel.contact3Relationship !== '' ? tripModel.contact3AppAccess : false
            },
            additionalInformation: {
                selectedMonitorNeeded: tripModel.selectedMonitorNeeded,
                selectedSafetyVest: tripModel.selectedSafetyVest,
                selectedWheelchair: tripModel.selectedWheelchair,
                studentNotes: tripModel.studentNotes
            },
            trips: [...formatTrips(trips)]
        };

        const body = JSON.stringify([requestModel]);

        mrmFetch(`/api/v2/Requests`,
            'post',
            null,
            body
        ).then((resp) => {
            if(resp === textResponse400) {
                dispatch(setShowFailedSubmitModal(true));
            } else if(resp === 422) {
                dispatch(errorPageSetTitle(localize('errorPage.title422')));
                dispatch(errorPageSetContent(localize('errorPage.content422')));
                dispatch(onLoadForm());
                dispatch(updatePath(paths.Error.tabValue));
            } else {
                dispatch(setShowSuccessSubmitModal(true));
            }
            dispatch(setSubmitButtonDisabled(false));
        }).catch(e => {
            window.warn(`error in submitTripRequestForm`,
                e
            );
        });
    }
};

export const onLoadRequest = (locationState) => async (dispatch) => {
    const { studentCode, studentId, isDraft } = locationState;

    if (isDraft) {
        const students = await searchStudents(studentCode, isDraft);

        if (students?.length > 0) {
            const draft = students.find(student => student.isDraft &&
                student.clientMemberCode === studentCode);

            if (draft) {
                dispatch(populateFormWithDraft(draft));
            }
        }
    } else {
        const studentData = await getStudentProfile(studentId);

        dispatch(populateFormWithStudent(studentData));
    }
};