import React from "react";
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { arrayOf, func, number, shape, string } from "prop-types";
import { museoSansFont } from "util/EnumHelper";

const MuseoTabs = styled((props) => (
    <Tabs
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...props }
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))(({ theme }) => ({
    maxWidth: 900,
    width: '100%',
    '& .MuiTabs-flexContainer': {
        justifyContent: 'center'
    },
    '& .MuiTabs-indicator': {
        height: '5px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.transparent
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: theme.palette.everDrivenLightBlue
    }
}));

const MuseoTab = styled(
    // eslint-disable-next-line react/jsx-props-no-spreading
    (props) => <Tab disableRipple {...props} />,
    { shouldForwardProp: (prop) => prop !== 'nItems'}
)(({ theme, nItems }) => ({
    width: `calc(100%/${nItems})`,
    maxWidth: `calc(100%/${nItems})`,
    fontFamily: museoSansFont,
    fontSize: 16,
    textTransform: 'none',
    '&:hover': {
        color: theme.palette.everDrivenLightBlue,
        opacity: 1
    },
    '&.MuiTab-textColorPrimary': {
        color: theme.palette.everDrivenDarkCharcoal80
    },
    '&.Mui-selected': {
        color: theme.palette.everDrivenLightBlue,
        backgroundColor: theme.palette.transparent
    },
    '&.Mui-focusVisible': {
        backgroundColor: theme.palette.transparent
    }
}));

function StyledTabs ({ tabItems, currentValue, onChangeTab }) {
    const onChange = (event, newValue) => {
        onChangeTab(newValue);
    };

    const nItems = tabItems ? tabItems.length : 1;

    return (
        <MuseoTabs value={currentValue} onChange={onChange}>
            { tabItems.map(tab => <MuseoTab
                data-testid={tab.label}
                label={tab.label}
                value={tab.value}
                key={tab.value}
                nItems={nItems}
            />)
            }
        </MuseoTabs>
    );
}

StyledTabs.propTypes = {
    tabItems: arrayOf(shape({
        label: string,
        value: number
    })).isRequired,
    currentValue: number.isRequired,
    onChangeTab: func.isRequired
};

export default StyledTabs;