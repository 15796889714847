import Auth from '../auth/Auth';

const LAST_SELECTED_TAB = "LAST_SELECTED_TAB";
const MAX_SAVED_LOCATIONS = 5;

export const hash = str => {
    let h = 0;
    let i;
    let chr;

    if (str.length === 0) return h;
    for (i = 0; i < str.length; i += 1) {
        chr = str.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        h = (h << 5) - h + chr;
        // eslint-disable-next-line no-bitwise
        h |= 0; // Convert to 32bit integer
    }

    return `${h}`; // Return a string to help out our later comparisons
};

export const saveTabSelectedPreference = (email, tab) => {
    const hashedEmail = hash(email);

    window.localStorage.setItem(`${hashedEmail}_${LAST_SELECTED_TAB}`,
        tab);
};

export const saveLocationPreferences = loc => {
    const email = Auth.getName();
    const hashedEmail = hash(email);
    const key = `${hashedEmail}_USER_FACILITIES`;

    const id = loc.id ? loc.id : loc.FacilityGuid;
    const hashed = hash(id);

    if (id) { // if a location is a facility, store it for later
        const saved = localStorage.getItem(key);

        if (saved) {
            let prev = JSON.parse(saved);
            const found = saved.indexOf(`${hashed}`);

            if (found !== -1) { // if already stored, up the counter
                const prevSelectedId = prev.filter(x => x.value.toString() === hashed)[0].id;

                prev.map(item => {
                    const newItem = item;

                    if (item.value.toString() === hashed) {
                        newItem.id = 1;
                    } else if (item.id < prevSelectedId) {
                        newItem.id += 1;
                    }

                    return newItem;
                });
                const formatted = JSON.stringify(prev);

                localStorage.setItem(key,
                    formatted);
            } else {
                const locObj = { id: 1, value: hashed };
                const userLoc = [];

                prev.map(item => {
                    const newItem = item;

                    newItem.id += 1;

                    return newItem;
                });
                // if not prev saved, verify if list has MAX_SAVED_LOCATIONS or more
                if (prev.length === MAX_SAVED_LOCATIONS) {
                    prev = prev.filter(x => x.id !== MAX_SAVED_LOCATIONS+1);
                }
        
                userLoc.push(locObj,
                    ...prev);
                const formatted = JSON.stringify(userLoc);

                localStorage.setItem(key,
                    formatted);

                return formatted;
            }
        } else {
            const locObj = { id: 1, value: hashed };
            const userLoc = [];

            userLoc.push(locObj);
            const formatted = JSON.stringify(userLoc);

            localStorage.setItem(key,
                formatted);

            return formatted;
        }
    }

    return null;
};

export const getLocalStorage = (item) => {
    const local = localStorage.getItem(item);

    return local;
};

export const getUserFacilities = (fl, location) => {
    const email = Auth.getName();
    const hashedEmail = hash(email);
    const key = `${hashedEmail}_USER_FACILITIES`;

    const allLocal = JSON.parse(getLocalStorage(key));

    // Validation for corrupted data  - removing storage
    if (allLocal) {
        let isCorrupted = false;

        for (let index = 0; index < allLocal.length; index+=1) {
            const element = allLocal[index];

            if (!element.id) {
                localStorage.removeItem(key);
                isCorrupted = true;
                break;
            }
        }

        if (isCorrupted) { allLocal.length = 0; }
    }

    const matches = [];
    let max = [];
    const sorted = allLocal && allLocal.sort();

    if (sorted && sorted.length > MAX_SAVED_LOCATIONS) {
        max = sorted.slice(Math.max(sorted.length - MAX_SAVED_LOCATIONS,
            1));
    } else {
        max = sorted;
    }
    if (max) {
        if (location === 'facility') {
            fl.forEach(f => {
                const hashed = hash(f.id);

                max.forEach(loc => {
                    if (loc.value.toString() === hashed) {
                        // eslint-disable-next-line no-param-reassign
                        f.count = loc.id;
                        matches.push(f);
                    }
                });
            });
        } else if (fl) {
            fl.forEach(f => {
                if (f.FacilityGuid) {
                    const hashed = hash(f.FacilityGuid);

                    max.forEach(loc => {
                        if (loc.value.toString() === hashed) {
                            // eslint-disable-next-line no-param-reassign
                            f.count = loc.id;
                            matches.push(f);
                        }
                    });
                }
            });
        }
    }

    return matches;
};

export const getTabSelectedPreference = email => {
    const hashedEmail = hash(email);
    const key = `${hashedEmail}_${LAST_SELECTED_TAB}`;
    const facilityKey = `${hashedEmail}_USER_FACILITIES`;

    try {
        return window.localStorage.getItem(key);
    } catch (ex) {
        window.localStorage.removeItem(key);
        window.localStorage.removeItem(facilityKey);

        return null;
    }
};
