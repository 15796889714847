import mrmFetch from "util/fetchUtil";

// eslint-disable-next-line import/prefer-default-export
export const submitTransportationRequests = async (body) => {
    const path = '/api/v2/Requests';
    const response = await mrmFetch(
        path,
        'post',
        null,
        body
    );
    
    return response;
};

export const getCorrelationId = async () => {
    const path = '/api/v2/CorrelationId';
    // Ignore 404 error
    const response = await mrmFetch(
        path,
        'GET',
        null,
        null,
        null,
        null,
        true
    );
    
    return response;
};