import React from 'react';
import { Grid } from '@mui/material';
import PopulationTypeContainer from 'redux/containers/transportationRequest/PopulationTypeContainer';
import TransportationNeedContainer from 'redux/containers/transportationRequest/TransportationNeedContainer';
import StudentNotesContainer from 'redux/containers/transportationRequest/StudentNotesContainer';

function AdditionalInformation() {
    return (
        <Grid container>
            <PopulationTypeContainer />
            <TransportationNeedContainer />
            <StudentNotesContainer />
        </Grid>
    );
}

export default AdditionalInformation;