import { push } from '@lagunovsky/redux-react-router';
import { setLogOutOn, updateActiveItem } from 'redux/actions/pathActions';
import { paths, urlSupport } from 'util/EnumHelper';

import Auth from 'auth/Auth';
import { addIdToPath, addIdsToPath } from 'util/updatePathTab';

const getAccessPath = () => (dispatch, getState) => {
    const { location } = getState().router;

    let hasAccess = false;

    switch (location.pathname) {
    case paths.DistrictTripTracker.path:
        hasAccess = Auth.hasTripTrackingAccess();
        break;
    case paths.Home.path:
        hasAccess = (Auth.hasTripTrackingAccess()
                    || Auth.hasProgramManagementAccess()
                    || Auth.hasUserAccess()
                    || Auth.hasBillingAccess());
        break;
    case paths.TransportationRequestForm.path:
    case paths.TransportationRequest.path:
    case paths.StudentUpload.path:
    case paths.DraftList.path:
    case paths.Reports.path:
    case paths.StudentOnboardingReport.path:
        hasAccess = Auth.hasProgramManagementAccess();
        break;
    case paths.Invoicing.path:
        hasAccess = Auth.hasBillingAccess();
        break;
    case paths.User.path:
    case paths.CalendarUpload.path:
        hasAccess = Auth.hasUserAccess();
        break;
    case paths.Resources.path:
        hasAccess = true;
        break;
    case paths.HowToGrantAppAccess.path:
        hasAccess = true;
        break;
    default:
        hasAccess = Auth.hasTripTrackingAccess();
        break;
    }

    return hasAccess;
};

export const accessPathMiddleware = (optionalAccess = true) => (dispatch) => {
    const hasAccess = dispatch(getAccessPath());

    if (!(hasAccess && optionalAccess)) {
        document.location = paths.Home.path;
    }
};

export const setTab = () => (dispatch, getState) => {
    const { location } = getState().router;

    switch (location.pathname) {
    case paths.DistrictTripTracker.path:
        dispatch(
            updateActiveItem(paths.DistrictTripTracker.tabValue)
        );
        break;
    case paths.TransportationRequestForm.path:
    case paths.TransportationRequest.path:
    case paths.StudentUpload.path:
    case paths.DraftList.path:
        dispatch(
            updateActiveItem(paths.DraftList.tabValue)
        );
        break;
    case paths.Reports.path:
    case paths.StudentOnboardingReport.path:
        dispatch(
            updateActiveItem(paths.Reports.tabValue)
        );
        break;
    case paths.Invoicing.path:
        dispatch(
            updateActiveItem(paths.Invoicing.tabValue)
        );
        break;
    case paths.User.path:
        dispatch(
            updateActiveItem(paths.User.tabValue)
        );
        break;
    case paths.CalendarUpload.path:
        dispatch(
            updateActiveItem(paths.CalendarUpload.tabValue)
        );
        break;
    case paths.Resources.path:
    case paths.HowToGrantAppAccess.path:
        dispatch(
            updateActiveItem(paths.Resources.tabValue)
        );
        break;
    default:
        dispatch(
            updateActiveItem(paths.DistrictTripTracker.tabValue)
        );
        break;
    }
};

export const updatePath = (value, id, state = null) => dispatch => {
    dispatch(accessPathMiddleware());

    switch (value) {
    case paths.DistrictTripTracker.tabValue:
        dispatch(push({
            pathname: paths.DistrictTripTracker.path,
            state
        }));
        break;
    case paths.Home.tabValue:
        dispatch(push({
            pathname: paths.Home.path,
            state
        }));
        break;
    case paths.TransportationRequestForm.tabValue:
        dispatch(push({
            pathname: Auth.getConfig().useNewTransportationRequestForm ?
                paths.TransportationRequest.path : paths.TransportationRequestForm.path,
            state
        }));
        break;
    case paths.Reports.tabValue:
        dispatch(push({
            pathname: paths.Reports.path,
            state
        }));
        break;
    case paths.StudentOnboardingReport.tabValue:
        dispatch(push({
            pathname: paths.StudentOnboardingReport.path,
            state
        }));
        break;
    case paths.Invoicing.tabValue:
        dispatch(push({
            pathname: paths.Invoicing.path,
            state
        }));
        break;
    case paths.User.tabValue:
        dispatch(push({
            pathname: paths.User.path,
            state
        }));
        break;
    case paths.CalendarUpload.tabValue:
        dispatch(push({
            pathname: paths.CalendarUpload.path,
            state
        }));
        break;
    case paths.Resources.tabValue:
        dispatch(push({
            pathname: paths.Resources.path,
            state
        }));
        break;
    case paths.HowToGrantAppAccess.tabValue:
        dispatch(push({
            pathname: paths.HowToGrantAppAccess.path,
            state
        }));
        break;
    case paths.SchoolDetail.tabValue:
        dispatch(push({
            pathname: addIdToPath(paths.SchoolDetail, id),
            state
        }));
        break;
    case paths.StudentDetail.tabValue:
        dispatch(push({
            pathname: addIdToPath(paths.StudentDetail, id),
            state
        }));
        break;
    case paths.LogOut.tabValue:
        dispatch(setLogOutOn(true));
        Auth.signout(() => {
            dispatch(push({
                pathname: paths.Home.path,
                state
            }));
        });
        break;
    case paths.CalendarDetail.tabValue:
        dispatch(push({
            pathname: addIdToPath(paths.CalendarDetail, id),
            state
        }));
        break;
    case paths.ResetPassword.tabValue:
        dispatch(setLogOutOn(true));
        Auth.resetPassword();
        break;
    case paths.Support.tabValue:
        window.location = urlSupport.url;
        break;
    case paths.StudentUpload.tabValue:
        dispatch(push({
            pathname: paths.StudentUpload.path,
            state
        }));
        break;
    case paths.DraftList.tabValue:
        dispatch(push({
            pathname: paths.DraftList.path,
            state
        }));
        break;
    case paths.TrackTrip.tabValue:
        dispatch(push({
            pathname: addIdsToPath(paths.TrackTrip, id),
            state
        }));
        break;
    case paths.Error.tabValue:
        dispatch(push({
            pathname: paths.Error.path,
            state
        }));
        break;
    case paths.TripDetail.tabValue:
        dispatch(push({
            pathname: addIdsToPath(paths.TripDetail, id),
            state
        }));
        break;
    default:
        break;
    }
};

export const authMiddleware = store => next => action => {
    store.dispatch(accessPathMiddleware());
    next(action);
};