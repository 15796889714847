import React, { useEffect, useState } from 'react';
import {
    shape,
    arrayOf,
    bool,
    func,
    string
} from 'prop-types';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText
} from '@mui/material';

import Auth from 'auth/Auth';

import StyledLoadingButton from 'components/Common/StyledLoadingButton/StyledLoadingButton';
import { localize } from 'util/Localizer';
import { validateEmail } from 'util/textFormatters';
import { onPreventSubmit } from 'util/formUtil';
import { Enum } from 'util/EnumHelper';
import facilityShape from 'data/models/FacilityModel';
import userShape from 'data/models/UserModel';

import UserPersonalData from '../UserPersonalData/UserPersonalData';
import UserAccess from '../UserAccess';
import UserSchool from '../UserSchool';
import VipAccess from '../VipAccess/VipAccess';

const DialogContentContainer = styled(DialogContent)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
    marginTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    minWidth: 300
}));

const ErrorMessageText = styled(DialogContentText)(({ theme }) => ({
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.everDrivenFuchsia
}));

const ResendInvitationButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const DialogActionsButtons = styled(DialogActions)(() => ({
    display: 'flex',
    flex: '0 0 auto',
    padding: 8,
    alignItems: 'center',
    justifyContent: 'flex-end'
}));

const UserNoAccessErrorMsg = styled(Box)(({ theme }) => ({
    width: '100%',
    marginTop: 5,
    paddingLeft: 20,
    paddingRight: 10,
    minWidth: 300,
    textAlign: 'right',
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.everDrivenFuchsia
}));

function UserForm({
    baseUser,
    buttonLabel,
    errorMessage,
    facilityList,
    inviteButtonLabel,
    inviteButtonVisible,
    isEdit,
    isSaving,
    nameForm,
    saveButtonDisabled,
    user,
    onSubmitForm,
    sendInvitation,
    setEditButtonLabel,
    setErrorAlert,
    setSaveButtonDisabled,
    setUser
}) {
    const initialTypeSchool = () => (
        !(!user.canAccessTripTracking ||
            (user.canAccessTripTracking && user.isProfessionalAgent === Enum.TRUE))
    );

    const [ showSchool, setShowSchool ] = useState(initialTypeSchool());

    const teacherAppEnabled = Auth.getConfig().teacherAppEnabled !== false ?
        true : Auth.getConfig().teacherAppEnabled;

    const showUserNoAccessErrorMsg = () => !((!isEdit ||
            (JSON.stringify(baseUser) === JSON.stringify(user)) ||
            user.isAdministrator ||
            user.canAccessProgramManagement ||
            user.canAccessTripTracking ||
            user.isTeacher
    ));

    const userIsInvalid = (form) => (form.firstName.length === 0
        || form.lastName.length === 0
        || (!isEdit && validateEmail(form.emailAddress))
        || (!form.isAdministrator
            && !form.canAccessBilling
            && !form.canAccessProgramManagement
            && !form.canAccessTripTracking
            && !form.isTeacher)
        || (form.canAccessTripTracking
            && ((!form.allowedSchools || form.allowedSchools.length === 0)
                && form.isProfessionalAgent === Enum.FALSE))
        || (form.isTeacher && (!form.allowedSchoolsVipApp || form.allowedSchoolsVipApp.length === 0))
    );

    const userMissingSchool = (form) => {
        const checkedAllowedSchools = form.allowedSchools ?? [];
        const isMissingAllowedSchool =
            checkedAllowedSchools.filter(id => facilityList.findIndex(school => school.id === id) === -1).length > 0;
        const checkedVipSchools = form.allowedSchoolsVipApp ?? [];
        const isMissingVipSchool =
            checkedVipSchools.filter(id => facilityList.findIndex(school => school.id === id) === -1).length > 0;

        return isMissingAllowedSchool || isMissingVipSchool;
    };

    const formIsSavable = (form) => {
        let isSavable = true;

        if (userIsInvalid(form)) {
            isSavable = false;
        }

        if (userMissingSchool(form)) {
            isSavable = false;
        }

        if (isEdit && (JSON.stringify(baseUser) === JSON.stringify(form))) {
            isSavable = false;
        }

        return isSavable;
    };

    const disableActionFormButtons = () => {
        if (formIsSavable(user)) {
            setSaveButtonDisabled(false);
        } else {
            setSaveButtonDisabled(true);
        }
    };

    const onChangeUserInput = (userForm) => {
        if (isEdit) {
            const isSaveAndSendInvite = (userForm.isNewUserAccount !== false && (!baseUser.canAccessProgramManagement
                && !baseUser.canAccessTripTracking && !baseUser.isAdministrator) && (userForm.canAccessProgramManagement
                || userForm.canAccessTripTracking || userForm.isAdministrator))
                || (userForm.isNewVipUserAccount !== false && userForm.allowedSchoolsVipApp?.length > 0);

            const editButtonLabel = formIsSavable(userForm) && isSaveAndSendInvite
                ? localize('editUser.SaveAndSendInvite')
                : localize('editUser.SaveChanges');

            setEditButtonLabel(editButtonLabel);
        }

        setUser({ ...userForm});
    };

    useEffect(() => {
        disableActionFormButtons();
    }, [user]);

    return (
        <form
            autoComplete="off"
            method="post"
            name={nameForm}
            onSubmit={e => {
                onPreventSubmit(e);
                onSubmitForm(e);
            }}
            data-testid={isEdit ? `userDetails_${user.emailAddress}` : 'newUserDetails'}
        >
            <DialogContentContainer>

                {errorMessage &&
                    (<ErrorMessageText data-testid="errorText">
                        {errorMessage}
                    </ErrorMessageText>)
                }
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={teacherAppEnabled ? 12 : 6}
                        lg={teacherAppEnabled ? 5 : 7}
                    >
                        <UserPersonalData
                            isEdit={isEdit}
                            user={user}
                            enabledStatus={user.accountEnabled}
                            onChangeInput={onChangeUserInput}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={teacherAppEnabled ? 4 : 5}
                    >
                        <UserAccess
                            user={user}
                            onChangeInput={onChangeUserInput}
                            setShowSchool={setShowSchool}
                        />
                        {showSchool &&
                            (<Grid item sm={10} md={11} lg={8} sx={{ paddingLeft: "25px" }}>
                                <UserSchool
                                    user={user}
                                    facilityList={facilityList}
                                    setErrorAlert={setErrorAlert}
                                    onChangeInput={onChangeUserInput}
                                />
                            </Grid>)
                        }
                    </Grid>
                    {teacherAppEnabled &&
                        (<Grid item xs={12} sm={12} md={6} lg={3}>
                            <Grid item sm={10} md={11}>
                                <VipAccess
                                    user={user}
                                    facilityList={facilityList}
                                    onChangeInput={onChangeUserInput}
                                />
                            </Grid>
                        </Grid>)
                    }
                </Grid>
            </DialogContentContainer>
            { showUserNoAccessErrorMsg() &&
                <UserNoAccessErrorMsg data-testid="userNoAccessError">
                    {localize('userManagement.userNoAccessError')}
                </UserNoAccessErrorMsg>
            }
            <DialogActionsButtons>
                {baseUser?.accountEnabled && inviteButtonVisible &&
                    <ResendInvitationButton
                        data-testid="resendInvitations"
                        variant="outlined"
                        onClick={sendInvitation}
                    >
                        {inviteButtonLabel}
                    </ResendInvitationButton>
                }
                <StyledLoadingButton
                    testId={isEdit ? "saveChanges" : "saveNew"}
                    saveButtonDisabled={saveButtonDisabled}
                    isLoading={isSaving}
                    buttonLabel={buttonLabel}
                />
            </DialogActionsButtons>
        </form>
    );
}

UserForm.defaultProps = {
    baseUser: null,
    buttonLabel: '',
    errorMessage: '',
    facilityList: [],
    inviteButtonLabel: '',
    inviteButtonVisible: false,
    isEdit: false,
    isSaving: false,
    nameForm: '',
    saveButtonDisabled: false,
    user: null,
    onSubmitForm: () => null,
    sendInvitation: () => null,
    setEditButtonLabel: () => null,
    setErrorAlert: () => null,
    setSaveButtonDisabled: () => null,
    setUser: () => null
};

UserForm.propTypes = {
    baseUser: shape(userShape),
    buttonLabel: string,
    errorMessage: string,
    facilityList: arrayOf(shape(facilityShape)),
    inviteButtonLabel: string,
    inviteButtonVisible: bool,
    isEdit: bool,
    isSaving: bool,
    nameForm: string,
    saveButtonDisabled: bool,
    user: shape(userShape),
    onSubmitForm: func,
    sendInvitation: func,
    setEditButtonLabel: func,
    setErrorAlert: func,
    setSaveButtonDisabled: func,
    setUser: func
};

export default UserForm;
