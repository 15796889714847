import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import StudentUpload from '../../../components/StudentUpload';
import {
    initForm,
    setLeaveNoSavingModalOpen,
    setNextPath
} from '../../actions/studentUploadActions';
import {
    onLeaveStudentUpload
} from '../../workers/studentUploadWorker';

const mapStateToProps = state => {
    const { StudentUploadReducers } = state;

    if (StudentUploadReducers) {
        return {
            isLeavingNoSavingOpen: StudentUploadReducers.isLeavingNoSavingOpen,
            nextPath: StudentUploadReducers.nextPath,
            step: StudentUploadReducers.step,
            studentFiles: StudentUploadReducers.studentFiles,
            isLoading: StudentUploadReducers.isLoading

        };
    }
    
    return {};
};

const StudentUploadContainer = connect(
    mapStateToProps, {
        initForm,
        onLeaveStudentUpload,
        setLeaveNoSavingModalOpen,
        setNextPath
    }
)(injectIntl(StudentUpload));

export default StudentUploadContainer;