import React, { useState } from 'react';
import {
    func,
    string,
    object,
    bool
} from 'prop-types';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import StyledDialogModal from '../Common/StyledDialogModal/StyledDialogModal';
import StyledButton from '../Common/StyledButton/StyledButton';
import StyledInput from '../Common/StyledInput/StyledInput';

const MAX_DATE = 8640000000000000;
const MIN_DATE = -8640000000000000;

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.white,
    background: theme.palette.everDrivenDarkBlue,
    display: 'flex',
    justifyContent: 'space-between'
}));

const Title = styled('div')(() => ({
    paddingTop: 10
}));

const CloseButton = styled(StyledButton)(({ theme }) => ({
    background: theme.palette.transparent,
    height: 40,
    minWidth: 30,
    padding: 0
}));

const AddRemoveIconButton = styled(CloseIcon)(({ theme }) => ({
    color: theme.palette.everDrivenGrey,
    fontSize: 20
}));

const StyledDialogContent = styled(DialogContent)(() => ({
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%'
}));

const ErrorMessage = styled(DialogContentText)(({ theme }) => ({
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.repOption2
}));

const StyledDialogActions = styled(DialogActions)(() => ({
    padding: 0,
    display: 'block'
}));

const ModalConfirmButton = styled(StyledInput)(({ theme }) => ({
    borderRadius: '5px',
    background: theme.palette.everDrivenDarkBlue,
    color: theme.palette.white,
    padding: 0,
    width: '100%',
    fontSize: 12,
    fontWeight: 'bold',
    opacity: 1,
    marginBottom: 15
}));

function NoShowModalDatePicker({
    showDatePicker,
    closeModalDatePicker,
    onSelectDate,
    isDisabled,
    errorMessage,
    title,
    buttonName,
    id,
    buttonId,
    isDisablePast,
    isDisableFuture,
    maxDate,
    minDate,
    shouldDisableDate
}) {
    const [ dateSelected, setDateSelected ] = useState(new Date());

    const onDateSelected = (evt) => {
        setDateSelected(evt);
    };

    const handleOnSelect = () => {
        onSelectDate(dateSelected);
    };

    return (
        <StyledDialogModal
            open={showDatePicker}
            onClose={closeModalDatePicker}
            maxWidth="md"
            aria-labelledby={id}
        >
            <StyledDialogTitle
                id={id}
                disabletypography="true"
            >
                <Title>
                    {title}
                </Title>
                <CloseButton
                    disableElevation
                    testId="noShowModalCloseButton"
                    onClick={closeModalDatePicker}
                >
                    <AddRemoveIconButton/>
                </CloseButton>

            </StyledDialogTitle>

            <StyledDialogContent>
                <ErrorMessage>
                    {errorMessage}
                </ErrorMessage>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        date={dateSelected}
                        onChange={onDateSelected}
                        disableFuture={isDisableFuture}
                        disablePast={isDisablePast}
                        minDate={minDate}
                        maxDate={maxDate}
                        shouldDisableDate={shouldDisableDate}
                        views={['day']}
                        renderInput={
                            (params) =>(
                                <TextField
                                    variant="standard"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                />
                            )
                        }
                    />
                </LocalizationProvider>

                <StyledDialogActions >
                    <ModalConfirmButton
                        id={buttonId}
                        type="button"
                        disableUnderline
                        value={buttonName}
                        isDisabled={isDisabled}
                        onClick={handleOnSelect}
                    />
                </StyledDialogActions>

            </StyledDialogContent>

        </StyledDialogModal>
    );
}

NoShowModalDatePicker.defaultProps = {
    showDatePicker: false,
    isDisabled: false,
    isDisablePast: false,
    isDisableFuture: false,

    title: null,
    id: null,
    buttonId: null,
    errorMessage: null,
    maxDate: new Date(MAX_DATE),
    minDate: new Date(MIN_DATE),

    shouldDisableDate:() => null,
    onSelectDate: () => null,
    closeModalDatePicker: () => null
};

/* eslint-disable react/forbid-prop-types */
NoShowModalDatePicker.propTypes = {
    isDisabled: bool,
    showDatePicker: bool,
    isDisablePast: bool,
    isDisableFuture: bool,

    title: string,
    id: string,
    buttonId: string,
    errorMessage: string,

    maxDate: object,
    minDate: object,

    shouldDisableDate: func,
    onSelectDate: func,
    closeModalDatePicker: func
};

export default NoShowModalDatePicker;
