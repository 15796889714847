import React from "react";
import { FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { bool, element, number, object, oneOf, oneOfType, string } from "prop-types";
import { museoSansFont } from "util/EnumHelper";

const Label = styled(FormControlLabel)(({ theme }) => ({
    height: 30,
    marginLeft: -3,
    '& .MuiFormControlLabel-label': {
        fontFamily: museoSansFont,
        fontSize: 16,
        color: theme.palette.everDrivenCharcoal,
        width: 'max-content'
    }
}));

function StyledFormControlLabel({
    control,
    extraStyle,
    isDisabled,
    label,
    size,
    testId,
    value
}) {
    return (
        <Label
            control={control}
            data-testid={testId}
            disabled={isDisabled}
            label={label}
            size={size}
            style={extraStyle}
            value={value}
        />
    );
}

StyledFormControlLabel.defaultProps = {
    isDisabled: false,
    label: null,
    extraStyle: null,
    size: 'medium',
    testId: null,
    value: null
};

/* eslint-disable react/forbid-prop-types */
StyledFormControlLabel.propTypes = {
    control: element.isRequired,
    extraStyle: object,
    isDisabled: bool,
    label: string,
    size: oneOf([
        'medium',
        'small'
    ]),
    testId: string,
    value: oneOfType([
        string,
        number,
        bool
    ])
};
/* eslint-enable react/forbid-prop-types */

export default StyledFormControlLabel;