import React from 'react';
import { TableHead } from '@mui/material';
import { node, string } from 'prop-types';

function StyledTableHead ({ children, extraCssClass }) {
    return (
        <TableHead className={extraCssClass}>
            {children}
        </TableHead>
    );
}

StyledTableHead.defaultProps = {
    children: null,
    extraCssClass: null
};

StyledTableHead.propTypes = {
    children: node,
    extraCssClass: string
};

export default StyledTableHead;