import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import DistrictTripTrackerTable from 'components/districtTripTracker/DistrictTripTrackerTable/DistrictTripTrackerTable';
import { localize } from 'util/Localizer';
import {
    sortStudentsByColumn,
    studentSetSearch,
    setPage
} from 'redux/workers/districtTripTracker/dttStudentWorker';

const mapStateToProps = state => {
    const {
        DttStudentReducers
    } = state;

    return {
        resultList: DttStudentReducers.dttStudentsSorted,
        columns: DttStudentReducers.dttStudentColumns,
        emptyText: localize('search.noStudentsFound'),
        tableTitle: localize('dttStudent.tableTitle'),
        showPagination: true,
        isLoading: DttStudentReducers.isLoading,
        searchValue: DttStudentReducers.dttStudentSearch
    };
};

const mapDispatchToProps = {
    performSearch: studentSetSearch,
    sortByColumn: sortStudentsByColumn,
    setPage
};

const DttTableStudentContainer =
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(DistrictTripTrackerTable));

export default DttTableStudentContainer;