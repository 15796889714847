import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import DttContent from 'components/districtTripTracker/DttContent/DttContent';

const mapStateToProps = state => {
    const { StyledHeaderReducers } = state;

    return {
        value: StyledHeaderReducers.option
    };
};

const mapDispatchToProps = {
};

const DttContentContainer =
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(DttContent));

export default DttContentContainer;