import { connect } from 'react-redux';
import StudentListUpload from '../../../components/studentUpload/StudentListUpload';

import {
    setKeyDropZone,
    setStep,
    setStudentRejectedFiles
} from '../../actions/studentUploadActions';
import {
    onSnackBarPropertyChange
} from '../../workers/styledSnackbarWrapperWorker';
import { onClearForm, onUploadStudentFile } from '../../workers/studentUploadWorker';

const mapStateToProps = state => {
    const { StudentUploadReducers } = state;

    if (StudentUploadReducers) {
        return {
            isFullyValidated: StudentUploadReducers.isFullyValidated,
            keyDropZone: StudentUploadReducers.keyDropZone,
            studentFiles: StudentUploadReducers.studentFiles,
            studentRejectedFiles: StudentUploadReducers.studentRejectedFiles
        };
    }

    return {};
};

const StudentListUploadContainer = connect(
    mapStateToProps, {
        onClearForm,
        onSnackBarPropertyChange,
        onUploadStudentFile,
        setKeyDropZone,
        setStep,
        setStudentRejectedFiles
    }
)(StudentListUpload);

export default StudentListUploadContainer;