import React from 'react';
import { injectIntl } from 'react-intl';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import StyledSnackbarWrapperContainer from 'redux/containers/common/StyledSnackbarWrapperContainer';
import breakpointsNames from '../styles/ResponsiveConstants';

const DPAppBox = styled('div')(({ theme }) => ({
    marginTop: 80,
    marginLeft: 100,
    zIndex: -2,
    minHeight: 'calc(100vh - 80px)',
    backgroundColor: theme.palette.offWhite,
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        marginLeft: 0
    },
    [theme.breakpoints.only(breakpointsNames.sm)]: {
        marginLeft: 100,
        "@media only screen and (orientation: landscape)": {
            marginLeft: 0
        }
    }
}));

const DPAppGroupBox = styled(Box)(() => ({
    position: 'fixed',
    zIndex: -1,
    opacity: 0.5,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'absolute',
    backgroundPosition: 'center',
    backgroundSize: "cover"
}));

function DPApp({ children, idName}) {
    return (
        <DPAppBox id={idName}>
            <DPAppGroupBox id="contentBackgroundPage" />
            {children}
            <StyledSnackbarWrapperContainer />
        </DPAppBox>
    );
}

const ConnectedDPApp = injectIntl(DPApp);

export default ConnectedDPApp;
