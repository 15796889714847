import { connect } from 'react-redux';
import StyledTablePagination from '../../../components/Common/StyledTablePagination/StyledTablePagination';
import { localize } from '../../../util/Localizer';

import {
    setPage,
    setPageSize
} from '../../workers/draftListWorker';

const mapStateToProps = state => {
    const { DraftListReducers } = state;

    if (DraftListReducers) {
        return {
            component: 'div',
            limitRows: DraftListReducers.limitRows,
            activePage: DraftListReducers.activePage,
            rowsShowed: DraftListReducers.rowsShowed,
            labelRowsPerPage: localize('draftList.pagination')
        };
    }

    return {};
};

const StyledDraftTablePaginationContainer = connect(
    mapStateToProps, {
        setPage,
        setPageSize
    }
)(StyledTablePagination);

export default StyledDraftTablePaginationContainer;