import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import PersonIcon from '@mui/icons-material/Person';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import { CardBody, IconRow, CardBodyText, CardSubText } from '../TrackTrip.styles';

function DriverDetails({DriverInfo}) {
    return (
        <CardBody data-testid="DriverDetails">
            <Grid container>
                <Grid item xs={6}>
                    <IconRow>
                        <PersonIcon fontSize="inherit" />
                        <CardBodyText
                            sx={{marginRight: '5px', overflowWrap: "anywhere"}}
                            data-testid="driverName"
                        >
                            {DriverInfo?.DriverName}
                        </CardBodyText>
                    </IconRow>
                    <CardSubText sx={{marginLeft: "25px"}} data-testid="driverGender">
                        {DriverInfo?.DriverGender}
                    </CardSubText>
                </Grid>
                <Grid item xs={6}>
                    <IconRow>
                        <DirectionsCarIcon fontSize="inherit" />
                        <CardBodyText data-testid="vehicleMakeModel">
                            {DriverInfo?.VehicleMake} {DriverInfo?.VehicleModel}
                        </CardBodyText>
                    </IconRow>
                    <CardSubText sx={{marginLeft: "25px"}} data-testid="vehicleColorPlate">
                        {DriverInfo?.VehicleColor && `${DriverInfo?.VehicleColor},`} {DriverInfo?.VehicleLicense}
                    </CardSubText>
                </Grid>
            </Grid>
        </CardBody>
    );
}

DriverDetails.defaultProps = {
    DriverInfo: {
        DriverName: "",
        VehicleMake: "",
        VehicleModel: "",
        VehicleColor: "",
        VehicleLicense: "",
        DriverGender: ""
    }
};

DriverDetails.propTypes = {
    DriverInfo: PropTypes.shape({
        DriverName: PropTypes.string,
        VehicleMake: PropTypes.string,
        VehicleModel: PropTypes.string,
        VehicleColor: PropTypes.string,
        VehicleLicense: PropTypes.string,
        DriverGender: PropTypes.string
    })
};

export default DriverDetails;