import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Paper from '@mui/material/Paper';

import StyledHeaderContent from "components/Common/StyledHeaderContent/StyledHeaderContent";
import StyledTypography from "components/Common/StyledTypography/StyledTypography";
import StyledButton from "components/Common/StyledButton/StyledButton";
import StyledLoading from "components/Common/StyledLoading/StyledLoading";
import { localize } from "util/Localizer";

const LIMIT_SHOW = 11;

const useStyles = makeStyles((theme) => ({
    subTitle: {
        color: theme.palette.everDrivenDarkGrey
    },
    container: {
        backgroundColor: theme.palette.offWhite,
        paddingRight: 15,
        paddingLeft: 15
    },
    sectionStudent: {
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15
    },
    sectionSchool: {
        paddingBottom: 15,
        paddingLeft: 15,
        paddingRight: 15
    },
    containerPaper: {
        borderRadius: '6px 6px 6px 6px',
        marginBottom: 20,
        width: 'inherit',
        backgroundColor: theme.palette.offWhite
    },
    countersNumbers: {
        fontSize: '70px',
        marginTop: 30,
        marginBottom: 5,
        textAlign: 'center'
    },
    countersSeccion: {
        fontSize: '25px',
        marginTop: 0,
        marginBottom: 30,
        padding: 0
    },
    counters: {
        backgroundColor: theme.palette.everDrivenGrey40,
        justifyContent: 'center',
        display: 'grid',
        margin: 15,
        height: 200
    },
    list: {
        backgroundColor: theme.palette.everDrivenGrey40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        margin: 15,
        height: 200,
        width: 'auto',
        paddingTop: 4
    },
    itemListBox: {
        marginRight: 70,
        width: '-webkit-fill-available',
        textAlignLast: 'start'
    },
    itemList: {
        textAlign: 'center',
        paddingLeft: 25,
        textOverflow:'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    containerButtons: {
        marginTop: 15,
        marginBottom: 15,
        display: 'flex',
        justifyContent: 'right',
        '&> button:nth-of-type(2)': {
            marginLeft: 10
        },
        '&> :last-child': {
            marginLeft: 10
        }
    },
    confirm: {
        color: theme.palette.everDrivenDarkGrey,
        display: 'flex',
        justifyContent: 'right',
        paddingRight: 195

    },
    btnCancel: {
        borderRadius: 8,
        width: 200,
        backgroundColor: theme.palette.white,
        color: theme.palette.everDrivenDarkBlue,
        borderColor: theme.palette.everDrivenDarkBlue,
        border: 2,
        borderStyle: 'solid',
        '&:hover' : {
            color: theme.palette.white,
            backgroundColor: theme.palette.everDrivenDarkBlue,
            borderColor: theme.palette.everDrivenDarkBlue
        },
        '&.Mui-disabled': {
            borderColor: 'transparent'
        }
    },
    btnSubmit: {
        borderRadius: 8,
        width: 200,
        backgroundColor: theme.palette.everDrivenDarkBlue
    }

}));

function StudentOverviewUpload({
    dataFromFile,
    setStep,
    closeStyledSnackBar,
    saveStudentUploadDraft,
    isLoading
}) {
    const classes = useStyles();

    const [ students, setStudents ] = useState([]);
    const [ schools, setSchools ] = useState([]);

    const showList = (list, isSchool = false) => {
        const itemLists = list.slice(0, 12).map((item, index) => (
            <Grid
                key={item['Student ID #']}
                item
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                className={classes.itemListBox}
            >
                {index === LIMIT_SHOW
                    ? <p className={classes.itemList}>
                        <strong>
                            {`+${list.length - LIMIT_SHOW} ${localize('studentUploadOverview.more')}`}
                        </strong>
                    </p>
                    : <p className={`qm_block ${classes.itemList}`}>
                        {isSchool
                            ? item['School Name']
                            : `${item['Student First Name'] } ${ item['Student Last Name']}`
                        }
                    </p>

                }
            </Grid>
        ));

        return (
            <Grid container className={classes.list}>
                {itemLists}
            </Grid>

        );
    };

    const showCounters = (number, text) => (
        <div className={classes.counters}>
            <p className={classes.countersNumbers}>
                {number}
            </p>
            <p className={classes.countersSeccion}>
                <strong>
                    {text}
                </strong>
            </p>
        </div>
    );

    const renderLoading = () => (
        isLoading && (
            <StyledLoading message={localize('studentUploadOverview.processing')} />
        )
    );

    const renderPage = () => (
        !isLoading && (
            <>
                <StyledHeaderContent
                    id="studentBulkUploadHeader"
                    title={localize('studentUploadOverview.title')}
                    extraStyle={{ paddingLeft: 30 }}
                >
                    <Grid item xs={12}>
                        <StyledTypography variant="h5" className={classes.subTitle}>
                            {localize('studentUploadOverview.subTitle')}
                        </StyledTypography>
                    </Grid>
                </StyledHeaderContent>
                <Grid container className={classes.container}>
                    <Grid container className={classes.container}>
                        <Paper square className={classes.containerPaper}>
                            <Grid container className={classes.sectionStudent}>
                                <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
                                    {showCounters(
                                        students.length,
                                        localize('studentUploadOverview.students'))
                                    }
                                </Grid>
                                <Grid item xs={12} sm={9} md={9} lg={10} xl={10}>
                                    {showList(students)}
                                </Grid>
                            </Grid>
                            <Grid container className={classes.sectionSchool}>
                                <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
                                    {showCounters(
                                        schools.length,
                                        localize('studentUploadOverview.schools'))
                                    }
                                </Grid>
                                <Grid item xs={12} sm={9} md={9} lg={10} xl={10}>
                                    {showList(schools, true)}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <StyledTypography variant="h5" className={classes.confirm}>
                            {localize('studentUploadOverview.confirm')}
                        </StyledTypography>
                    </Grid>
                    <Grid item xs={12} className={classes.containerButtons}>
                        <StyledButton
                            className={classes.btnCancel}
                            testId="studentOverviewUploadCancelButton"
                            type="button"
                            onClick={() => setStep(0)}
                        >
                            {localize('studentUploadOverview.goBack')}
                        </StyledButton>
                        <StyledButton
                            className={classes.btnSubmit}
                            testId="studentOverviewUploadSubmitButton"
                            type="submit"
                            onClick={() => saveStudentUploadDraft()}

                        >
                            {localize('studentUploadOverview.submit')}
                        </StyledButton>
                    </Grid>
                </Grid>
            </>
        )
    );

    useEffect(() => {
        closeStyledSnackBar();

        setStudents(dataFromFile.filter(
            (row, index, self) => index === self.findIndex(
                (item) => (item['Student ID #'] === row['Student ID #']))
        ));

        setSchools(dataFromFile.filter(
            (row, index, self) => index === self.findIndex(
                (item) => (item['School Name'] && item['School Name'] === row['School Name']))
        ));
    }, []);

    return (
        <>
            {renderLoading()}
            {renderPage()}
        </>
    );
}

export default StudentOverviewUpload;