import React from 'react';
import {
    Box,
    Stepper,
    StepConnector,
    Step,
    StepLabel
} from '@mui/material';
import {
    arrayOf,
    bool,
    element,
    elementType,
    number,
    string
} from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    boxStepper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    stepper: {
        width: 300
    },
    step: {
        paddingRight: 0
    },
    stepLabel: {
        paddingRight: 4
    }
}));

function StyledStepper({ activeStep, connector, steps, showLabel, customStepIcon, parentClassName, extraClassName }) {
    const classes = useStyles();

    return (
        <Box className={`${classes.boxStepper} ${parentClassName}`}>
            <Stepper
                className={`${classes.stepper} ${extraClassName}`}
                activeStep={activeStep}
                connector={connector}
            >
                {steps?.map((step) => (
                    <Step key={step} className={classes.step}>
                        { showLabel ?
                            <StepLabel StepIconComponent={customStepIcon} className={classes.stepLabel}>
                                {step}
                            </StepLabel>
                            :
                            <StepLabel StepIconComponent={customStepIcon} className={classes.stepLabel} />
                        }
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}

StyledStepper.defaultProps = {
    activeStep: 0,
    connector: <StepConnector />,
    customStepIcon: null,
    extraClassName: null,
    parentClassName: null,
    showLabel: false,
    steps: null
};

StyledStepper.propTypes = {
    activeStep: number,
    connector: element,
    customStepIcon: elementType,
    extraClassName: string,
    parentClassName: string,
    showLabel: bool,
    steps: arrayOf(string)
};

export default StyledStepper;