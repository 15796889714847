import React, { useState } from 'react';
import { Box, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PropTypes from 'prop-types';
import StyledVideoHeader from 'components/Common/StyledVideoHeader/StyledVideoHeader';
import { localize } from '../../util/Localizer';
import StyledLoading from '../Common/StyledLoading/StyledLoading';
import VideoContainer from './VideoContainer';

const VideoCollapse = styled(Collapse)(() => ({
    width: '100%'
}));

const FooterLabel = styled(Box)(() => ({
    fontFamily: 'MuseoSans500',
    fontSize: 16
}));

function CollapsableVideo({
    sortId,
    videoTitle,
    videoUrl,
    videoDescription,
    videoLength
}) {
    const [ open, setOpen ] = useState(sortId === 0);
    const [ loading, setLoading ] = useState(true);

    return(
        <Box>
            { loading && open
                ? (
                    <StyledLoading message={localize('resources.processing')} />
                ) : null
            }
            <StyledVideoHeader
                title={localize(videoTitle)}
                onClick={() => {
                    setLoading(open);
                    setOpen(!open);
                }
                }

                icon={open
                    ? <RemoveCircleOutlineIcon fontSize="inherit" />
                    : <AddCircleOutlineIcon fontSize="inherit" />
                }
                textButton={open
                    ? localize('resources.collapse')
                    : localize('resources.expand')}
                testid={`videoHeader${sortId}`}
                sx={{
                    pt: '16px',
                    pb: '8px',
                    mt: '16px',
                    mb: '8px'
                }}
            />
            <VideoCollapse in={open} timeout="auto" unmountOnExit>
                <Box alignItems="center"
                    justifyContent="center">
                    <VideoContainer
                        sortId={sortId}
                        videoUrl={videoUrl}
                        onLoad={() => setLoading(false)}
                    />
                    <FooterLabel sx={{ textAlign: 'left' }} data-testid={`videoDescription${sortId}`}>
                        {videoDescription}
                    </FooterLabel>
                    <FooterLabel sx={{ textAlign: 'left' }} data-testid={`videoLength${sortId}`}>
                        {videoLength}
                    </FooterLabel>
                </Box>
            </VideoCollapse>
        </Box>
    );
}

CollapsableVideo.defaultProps = {
    sortId: 0,
    videoTitle: '',
    videoUrl: null,
    videoDescription: '',
    videoLength: ''
};

/* eslint-disable react/forbid-prop-types */
CollapsableVideo.propTypes = {
    sortId: PropTypes.number,
    videoTitle: PropTypes.string,
    videoUrl: PropTypes.string,
    videoDescription: PropTypes.string,
    videoLength: PropTypes.string
};

/* eslint-enable react/forbid-prop-types */

export default CollapsableVideo;