import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export const CardContainer = styled(Paper)(() => ({
    color: '#333333',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)'
}));

export const CardStatusHeader = styled('div')(({statusColor}) => ({
    color: statusColor.color,
    backgroundColor: statusColor.backgroundColor,
    padding: '13px 25px 10px 25px'
}));

export const CardTitle = styled(Typography)(() => ({
    fontFamily: 'Museo700',
    fontSize: '16px'
}));

export const CardStatus = styled(Typography)(() => ({
    fontFamily: 'MuseoSans500',
    fontSize: '14px',
    textTransform: "uppercase"
}));

export const CardBody = styled('div')(() => ({
    padding: '10px 15px'
}));

export const IconRow = styled('div')(() => ({
    width: "100%",
    display: 'inline-flex',
    verticalAlign: 'middle',
    color: '#333333CC',
    gap: '7px'
}));

export const CardBodyText = styled(Typography)(() => ({
    fontFamily: 'MuseoSans500',
    fontSize: '12px',
    color: '#333333'
}));

export const CardSubText = styled(Typography)(() => ({
    fontFamily: 'MuseoSans500',
    fontSize: '12px',
    color: '#333333BF'
}));

export const ETAText = styled(Typography)(() => ({
    fontFamily: 'Museo700',
    fontSize: '24px',
    lineHeight: 1
}));

export const VertSVG = styled('svg')(() => ({
    color: '#333333CC',
    fill: 'currentColor',
    fontSize: '12px',
    width: '18px',
    height: '35px'
}));
