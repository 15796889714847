import {
    trackTripSetIsLoading,
    trackTripSetTrip,
    trackTripSetTripType
} from "redux/actions/trackTripActions";
import Auth from "auth/Auth";
import { Enum, paths } from "util/EnumHelper";
import { getTripType } from "util/tripSorter";
import { studentTrips } from "services/studentService";
import { setTripFormat } from "util/data/TripFormat";
import { fixedEncodeURIComponent } from "util/textFormatters";

import { updatePath } from "./pathWorker";

export const setTripData = (trip) => (dispatch) => {
    const tripType = getTripType(trip);

    dispatch(trackTripSetTrip(trip));
    dispatch(trackTripSetTripType(tripType));
};

const findTrip = async (tripId, studentId) => {
    let tripFound = null;
    let stopSearch = false;
    let continuationToken = null;
    let response = null;

    while (!stopSearch) {
        // eslint-disable-next-line no-await-in-loop
        response = await studentTrips(
            studentId,
            Enum.TripSection.Today,
            fixedEncodeURIComponent(JSON.stringify(continuationToken))
        );

        continuationToken = response.continuationToken;
        tripFound = response.trips.filter(trip => trip.id === tripId);

        if(tripFound.length > 0 || (tripFound.length === 0 && continuationToken === null)) {
            stopSearch = true;
        }
    }

    return tripFound;
};

export const goToTrackTrip = (trip) => async (dispatch) => {
    dispatch(trackTripSetIsLoading(true));
    
    dispatch(setTripData(trip));

    const ids = {
        studentId: trip.MemberGUID,
        tripId: trip.TripGuid,
        facilityId: trip.DropOffLocation.FacilityGuid ?? trip.PickUpLocation.FacilityGuid
    };

    const { useNewTripTracker } = Auth.getConfig();
   
    if (!useNewTripTracker) {
        ids.facilityId = trip.DropOffLocation.FacilityGuid ?? trip.PickUpLocation.FacilityGuid;
        dispatch(updatePath(paths.TrackTrip.tabValue, ids));
    } else {
        dispatch(updatePath(paths.TripDetail.tabValue, ids));
    }
};

export const getTrip = (studentId, tripId) => async (dispatch, getState) => {
    const { trip } = getState().TrackTripReducers;

    dispatch(trackTripSetIsLoading(true));

    if(!trip || trip.TripGuid !== tripId) {
        const tripFound = await findTrip(tripId, studentId);

        if (!tripFound || tripFound.length === 0) {
            window.warn(
                `No trips found for student: ${studentId} matching trip: ${tripId} sending to 404`
            );
            document.location = '/error';
        } else {
            const tripFormat = setTripFormat(tripFound[0]);
            
            dispatch(setTripData(tripFormat));
        }
    }
    dispatch(trackTripSetIsLoading(false));
};