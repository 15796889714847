import React from 'react';
import { Hidden } from '@mui/material';
import { localize } from 'util/Localizer';
import CalendarUploadedRowContainer from 'redux/containers/calendar/CalendarUploadedRowContainer';
import StyledEmptyTable from 'components/Common/StyledEmptyTable/StyledEmptyTable';
import StyledTable from 'components/Common/StyledTable/StyledTable';
import StyledTableHead from 'components/Common/StyledTableHead/StyledTableHead';
import StyledTableRow from 'components/Common/StyledTableRow/StyledTableRow';
import StyledTableBody from 'components/Common/StyledTableBody/StyledTableBody';
import StyledTableCell from 'components/Common/StyledTableCell/StyledTableCell';

function CalendarUploadedList({ resultList }) {
    if (resultList.length === 0) {
        return (
            <StyledEmptyTable message={localize('calendarUpload.filesUploadedEmpty')} />
        );
    }

    return (
        <>
            <Hidden mdDown>
                <StyledTable>
                    <StyledTableHead>
                        <StyledTableRow>
                            <StyledTableCell isHeader>
                                {localize('calendarUpload.fileName')}
                            </StyledTableCell>
                            <StyledTableCell isHeader>
                                {localize('calendarUpload.dateUploaded')}
                            </StyledTableCell>
                            <StyledTableCell isHeader>
                                {localize('calendarUpload.fileSize')}
                            </StyledTableCell>
                            <StyledTableCell isHeader>
                                {localize('calendarUpload.calendarAudience')}
                            </StyledTableCell>
                            <StyledTableCell />
                        </StyledTableRow>
                    </StyledTableHead>
                    <StyledTableBody>
                        {resultList.map((calendar, index) => (
                            <CalendarUploadedRowContainer
                                row={calendar}
                                nRow={index}
                                key={calendar.fileName}
                            />
                        ))}
                    </StyledTableBody>
                </StyledTable>
            </Hidden>
            <Hidden mdUp>
                {resultList.map((calendar, index) => (
                    <CalendarUploadedRowContainer
                        row={calendar}
                        nRow={index}
                        key={calendar.fileName}
                    />
                ))}
            </Hidden>
        </>
    );
}

export default CalendarUploadedList;