import React, { useEffect, useState } from 'react';
import PropTypes, {
    array,
    object,
    oneOfType
} from 'prop-types';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { makeStyles } from '@mui/styles';

import { commonStyles } from 'styles/CommonStyles';
import { localize } from 'util/Localizer';
import StyledCardGroupHeader from 'components/Common/StyledCardGroupHeader/StyledCardGroupHeader';
import StyledCardGroupContainer from 'components/Common/StyledCardGroupContainer/StyledCardGroupContainer';
import StyledButton from 'components/Common/StyledButton/StyledButton';

import TripCard from 'components/trip/TripCard/TripCard';
import TripGroupMenu from 'components/trip/TripGroupMenu';
import { tripsFilterStates, optionsTripGroup } from 'util/EnumHelper';

const TRIP_DETAIL = {
    Today: {
        title: 'tripGroup.today',
        filterOptions: [
            { name: 'tripGroup.allTrips', value: tripsFilterStates.All.filterType },
            { name: 'tripGroup.onSite', value: tripsFilterStates.OnSite.filterType },
            { name: 'tripGroup.driverOnWay', value: tripsFilterStates.EnRoute.filterType },
            { name: 'tripGroup.onBoard', value: tripsFilterStates.OnBoard.filterType },
            { name: 'tripGroup.scheduled', value: tripsFilterStates.Scheduled.filterType },
            { name: 'tripGroup.pendingCancellation', value: tripsFilterStates.PendingCancellation.filterType }
        ]
    },
    Future: {
        title: 'tripGroup.future',
        filterOptions: [
            { name: 'tripGroup.allTrips', value: tripsFilterStates.All.filterType },
            { name: 'tripGroup.scheduled', value: tripsFilterStates.Scheduled.filterType },
            { name: 'tripGroup.pendingCancellation', value: tripsFilterStates.PendingCancellation.filterType }
        ]
    },
    NoShow: {
        title: 'tripGroup.noShow',
        filterOptions: []
    },
    Finished: {
        title: 'tripGroup.finished',
        filterOptions: [
            { name: 'tripGroup.allTrips', value: tripsFilterStates.All.filterType },
            { name: 'tripGroup.completed', value: tripsFilterStates.Completed.filterType },
            { name: 'tripGroup.canceled', value: tripsFilterStates.Canceled.filterType }
        ]
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        padding: '16px 0'
    },
    button: {
        ...commonStyles(theme).groupCardFilterButton,
        height: 22,
        marginLeft: 'auto'
    },
    cardGroup: {
        clear: 'both',
        padding: '0'
    },
    vehicleGroup: {
        '&:not(:last-child)': {
            borderBottom: `4px ${theme.palette.everDrivenLightBlue80} solid`
        }
    },
    groupButtons: {
        marginLeft: 'auto'
    },
    tripCard: {
        padding: 8
    }
}));

function TripGroup({
    tripType,
    filter,
    trips,
    moreTrips,
    guid,
    memberId,
    isFacility,
    goToStudent,
    goToTrackTrip,
    changeFilter,
    showLoadMoreBtn,
    loadTrips,
    defaultSetup
}) {
    const classes = useStyles();

    const { title, filterOptions } = TRIP_DETAIL[tripType];

    const [ open, setOpen ] = useState(true);
    const [ groupByVehicle, setGroupByVehicle ] = useState(false);
    const [ vehicleGroups, setVehicleGroups ] = useState([]);
    const [ filteredTrips, setFilteredTrips ] = useState([]);

    const filterTrips = filterTrip => {
        if (filterTrip === tripsFilterStates.All.filterType) {
            return trips;
        }

        return (trips || []).filter(trip => {
            switch(filterTrip) {
            // pending cancellation technically still have scheduled as it's status
            // so we are filtering by command property
            case tripsFilterStates.PendingCancellation.filterType:
                return (
                    trip.commands && trip.commands.findIndex(
                        command => command.action === 'Cancel' || command.Action === 'Cancel'
                    ) > -1
                );
            case tripsFilterStates.Scheduled.filterType:
                return (
                    (trip.commands === null ||
                    (trip.commands && trip.commands.length === 0))
                    && tripsFilterStates.Scheduled.states.includes(trip.State));
            default:
                return tripsFilterStates[filterTrip].states.includes(trip.State);
            }
        });
    };

    const handleClick = (value) => changeFilter(tripType, value);

    const filterByVehicle = () => {
        const groups = [];

        filteredTrips.forEach(trip => {
            if (!groups.includes(trip.VehicleRunID)) {
                groups.push(trip.VehicleRunID);
            }
        });
        setVehicleGroups(groups);
    };

    const renderAccordionMenu = () => (
        tripType !== optionsTripGroup.NoShow && (
            <Hidden mdUp>
                <TripGroupMenu
                    filterOptions={filterOptions}
                    filterTrips={filterTrips}
                    iconClass={classes.button}
                    id={tripType}
                    filterChange={handleClick}
                />
            </Hidden>
        )
    );

    const renderFilters = () => (
        <Hidden mdDown>
            <div
                className={
                    isFacility ? classes.groupButtons : null
                }
            >
                {filterOptions.map(option => (
                    <StyledButton
                        className={`${classes.button} ${filter === option.value
                            ? 'active'
                            : null
                        }`}
                        id={localize(option.name)}
                        key={localize(option.name)}
                        testId={`tripFilterOption_${tripType}_${option.name}`}
                        variant="text"
                        onClick={() => {
                            changeFilter(tripType,
                                option.value);
                        }}
                    >
                        {localize(option.name)} ({filterTrips(option.value).length})
                    </StyledButton>
                ))}
            </div>
        </Hidden>
    );

    const renderTripsGroupedByVehicle = () => vehicleGroups.map(vehicleID => (
        <Grid
            key={vehicleID ?? 0}
            container
            id="cardVehicleGroup"
            className={`${classes.cardGroup} ${classes.vehicleGroup}`}
        >
            {(filteredTrips || [])
                .filter(trip => trip.VehicleRunID === vehicleID)
                .map(trip => (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        id="groupedTripCard"
                        className={classes.tripCard}
                        key={`${trip.TripGuid}-${trip.MemberGUID}`}
                    >
                        <TripCard
                            trip={trip}
                            tripType={tripType}
                            facilityId={guid}
                            defaultExtended={false}
                            noActionButton={false}
                            isMapCard={false}
                            goToStudent={goToStudent}
                            goToTrackTrip={goToTrackTrip}
                            showHeader={isFacility}
                        />
                    </Grid>
                ))}
        </Grid>
    ));

    const renderTrips = () => (
        <Grid container className={classes.cardGroup}>
            {filteredTrips.length > 0 &&
                filteredTrips.map(trip => (
                    <Grid item xs={12} sm={6} md={4} lg={3}
                        className={classes.tripCard} name="tripCard" data-testid={tripType}
                        key={`${trip.TripGuid}-${trip.MemberGUID}`}
                    >
                        <TripCard
                            key={`${trip.TripGuid}-${trip.MemberGUID}`}
                            trip={trip}
                            tripType={tripType}
                            facilityId={guid}
                            isMapCard={false}
                            goToStudent={goToStudent}
                            showHeader={isFacility}
                            goToTrackTrip={goToTrackTrip}
                        />
                    </Grid>
                ))}
        </Grid>
    );

    useEffect(() => {
        defaultSetup(tripType);
        loadTrips(tripType, guid, memberId);
    }, [guid]);

    useEffect(() => {
	    setFilteredTrips(filterTrips(filter));
    }, [ filter, trips ]);

    useEffect(() => {
        filterByVehicle();
    }, [ groupByVehicle, filteredTrips ]);

    return (
        <div className={classes.root} name={tripType}>
            <StyledCardGroupHeader
                elements={trips}
                grouped={groupByVehicle}
                groupLabel={localize("tripList.groupByVehicle")}
                groupTitle={localize(title).toUpperCase()}
                showGroupButton={isFacility}
                groupType={tripType}
                onClickDisplayButton={() => setOpen(!open)}
                onClickGroupButton={() => setGroupByVehicle(!groupByVehicle)}
            >
                {renderAccordionMenu()}
                {renderFilters()}
            </StyledCardGroupHeader>
            <StyledCardGroupContainer
                showLoadMoreBtn={!(showLoadMoreBtn === 1 && moreTrips === null)}
                disablePagBtn={moreTrips === null}
                grouped={groupByVehicle}
                groupLabel={localize("tripList.groupByVehicle")}
                isOpenPanel={open}
                paginationLabel={localize("tripList.LoadMore")}
                showGroupButton={isFacility}
                showPagination
                groupType={tripType}
                onClickGroupButton={() => setGroupByVehicle(!groupByVehicle)}
                onPageChange={() => loadTrips(tripType, guid, memberId)}
            >
                {groupByVehicle
                    ? renderTripsGroupedByVehicle()
                    : renderTrips()}
            </StyledCardGroupContainer>
        </div>
    );
}

TripGroup.defaultProps = {
    guid: null
};

TripGroup.propTypes = {
    trips: oneOfType([
        array,
        object
    ]).isRequired,
    tripType: PropTypes.string.isRequired,
    changeFilter: PropTypes.func.isRequired,
    isFacility: PropTypes.bool.isRequired,
    guid: PropTypes.string
};

export default TripGroup;
