import React from 'react';
import { MenuItem, MenuList, Grow, Paper, Popper, ClickAwayListener } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { styled } from '@mui/material/styles';

import { array, bool, func, object, string } from 'prop-types';
import breakpointsNames from 'styles/ResponsiveConstants';
import { localize } from 'util/Localizer';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import useWidth from 'util/customHooks/useWidth';

const TripGroupMenuItem = styled(MenuItem)(({ theme }) => ({
    backgroundColor: theme.palette.everDrivenLightBlue40,
    '&:hover': {
        backgroundColor: theme.palette.everDrivenDarkBlue,
        color: theme.palette.white
    },
    '&.Mui-selected': {
        backgroundColor: theme.palette.everDrivenDarkBlue,
        color: theme.palette.white,
        '&:hover': {
            backgroundColor: theme.palette.everDrivenDarkBlue,
            color: theme.palette.white
        }
    }
}));

function TripGroupMenu({
    defaultItem,
    filterOptions,
    fullSize,
    iconClass,
    iconStyle,
    id,
    filterChange,
    filterTrips
}) {
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [ selectedValue, setSelectedValue ] = React.useState(null);
    const [ isOpen, setIsOpen ] = React.useState(false);
    const width = useWidth();

    const handleClick = (value) => {
        filterChange(value);
        setAnchorEl(null);
        setSelectedValue(value.name ?? value);
        setIsOpen(false);
    };

    const handleOpen = event => {
        event.preventDefault();
        if (!isOpen) {
            setAnchorEl(event.currentTarget);
            setIsOpen(true);
        } else {
            setAnchorEl(null);
            setIsOpen(false);
        }
    };

    const handleClose = () => {
        if (isOpen) {
            setAnchorEl(null);
            setIsOpen(false);
        }
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setAnchorEl(null);
            setIsOpen(false);
        }
    };

    return (
        <>
            <StyledButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                className={iconClass}
                style={iconStyle}
                testId={`openMenuButton_${id}`}
                variant="text"
                onClick={handleOpen}
            >
                <FilterListIcon />
            </StyledButton>
            <Popper
                open={Boolean(
                    (fullSize || width === breakpointsNames.xs || width === breakpointsNames.sm)
                        ? anchorEl
                        : null)
                }
                anchorEl={
                    (fullSize || width === breakpointsNames.xs || width === breakpointsNames.sm)
                        ? anchorEl
                        : null
                }
                transition
                sx={{ zIndex: 1 }}
                placement="left-start"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        in={TransitionProps.in}
                        onEnter={TransitionProps.onEnter}
                        onExited={TransitionProps.onExited}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={
                                        Boolean((fullSize || width === breakpointsNames.xs
                                                          || width === breakpointsNames.sm)
                                            ? anchorEl
                                            : null
                                        )
                                    }
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                    disablePadding
                                >
                                    {
                                        defaultItem &&
                                            <TripGroupMenuItem
                                                key="defaultItem"
                                                onClick={() => handleClick(defaultItem.key)}
                                                selected={defaultItem.key === selectedValue}>
                                                {defaultItem.value}
                                            </TripGroupMenuItem>
                                    }
                                    {
                                        filterOptions.map(option => (
                                            <TripGroupMenuItem
                                                key={option.name ?? option.value}
                                                onClick={() => handleClick(option.value ?? option)}
                                                selected={(option.value ?? option.name) === selectedValue}>
                                                {option.name ? localize(option.name) : option.value}
                                                    ({
                                                    (option.quantity >= 0)
                                                        ? option.quantity
                                                        : filterTrips(option.value).length
                                                })
                                            </TripGroupMenuItem>
                                        ))
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

TripGroupMenu.defaultProps = {
    defaultItem: {},
    filterOptions: [],
    fullSize: false,
    iconClass: '',
    iconStyle: {},
    id: '',
    filterChange: () => null,
    filterTrips: () => null
};

/* eslint-disable react/forbid-prop-types */
TripGroupMenu.propTypes = {
    defaultItem: object,
    filterOptions: array,
    fullSize: bool,
    iconClass: string,
    iconStyle: object,
    id: string,
    filterChange: func,
    filterTrips: func
};
/* eslint-enable react/forbid-prop-types */

export default TripGroupMenu;
