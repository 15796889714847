// eslint-disable-next-line import/no-extraneous-dependencies
import { isUnique } from '../../util/arrayUtil';
import { Enum, paths } from '../../util/EnumHelper';
import mrmFetch from '../../util/fetchUtil';
import { convertExcelToData } from '../../util/fileUtil';
import { localize } from '../../util/Localizer';
import { getPathTabValue } from "../../util/updatePathTab";
import {
    initForm,
    setDataFromFile,
    setLeaveNoSavingModalOpen,
    setStudentFiles,
    setStudentRejectedFiles,
    setStudentUploadLoading,
    setStudentUploadCompleted
} from "../actions/studentUploadActions";
import { updatePath } from "./pathWorker";
import { onSnackBarPropertyChange, openStyledSnackBar, setSnackbar } from './styledSnackbarWrapperWorker';

export const onDeleteFile = nRow => (dispatch, getState) => {
    const { studentFiles } = getState().StudentUploadReducers;
    
    const updatedFiles = studentFiles.filter((file, index) => index !== nRow);
        
    dispatch(setStudentFiles({ studentFiles: updatedFiles, isFullyValidated: false }));
    dispatch(setDataFromFile([]));
};

export const onClearForm = () => (dispatch) => {
    dispatch(initForm());
    dispatch(updatePath(1));
};

export const onLeaveStudentUpload = (nextPath) => dispatch => {
    dispatch(initForm());
    dispatch(updatePath(getPathTabValue(nextPath)));
    dispatch(setLeaveNoSavingModalOpen(false));
};

export const onUploadStudentFile = (files) => async (dispatch, getState) => {
    const { dataFromFile } = getState().StudentUploadReducers;
    
    if (files && files.length > 0 && dataFromFile.length === 0) {
        const file = files[0];
        const allSheetsData = (await convertExcelToData(file, 'Student ID #'));

        if (allSheetsData.length > 0) {
            /* Validating Student ID, First Name and Last Name are populated for every record */
            const validateDataIntegrity = allSheetsData.filter(x => Object.hasOwn(x, 'Student ID #')
                && Object.hasOwn(x, 'Student First Name')
                && Object.hasOwn(x, 'Student Last Name')).length === allSheetsData.length;
    
            /* Validating Student IDs are uniques in the entire file */
            const validateIsUnique = isUnique(allSheetsData, 'Student ID #');
    
            if (validateDataIntegrity && validateIsUnique) {
                dispatch(setStudentFiles({ studentFiles: files, isFullyValidated: true}));

                dispatch(setDataFromFile(allSheetsData));
                dispatch(onSnackBarPropertyChange(
                    Enum.severityType.success,
                    localize('studentUpload.fileSuccessfullyAdded', { file: file.name })));
            } else {
                dispatch(setStudentFiles({ studentFiles: [], isFullyValidated: false}));
                dispatch(setStudentRejectedFiles(Object.values({ payload: { file }})));
                dispatch(onSnackBarPropertyChange(
                    Enum.severityType.error,
                    localize('studentUpload.missingRequiredFields')));
            }
        } else {
            dispatch(setStudentFiles({ studentFiles: [], isFullyValidated: false}));
            dispatch(setStudentRejectedFiles(Object.values({ payload: { file }})));
            dispatch(onSnackBarPropertyChange(
                Enum.severityType.error,
                localize('studentUpload.missingRequiredFields')));
        }
    }
};

export const cleanUploadStudent = () => (dispatch) => {
    dispatch(setStudentUploadCompleted(false));
    dispatch(initForm());
};

export const saveStudentUploadDraft = () => async (dispatch, getState) => {
    const { dataFromFile } = getState().StudentUploadReducers;

    dispatch(setStudentUploadLoading(true));

    const body = JSON.stringify(dataFromFile);

    try {
        await mrmFetch(`/api/v2/DraftRequests`,
            'post',
            null,
            body
        );

        dispatch(cleanUploadStudent());
        dispatch(updatePath(paths.TransportationRequestForm.tabValue));

        dispatch(setStudentUploadCompleted(true));
        dispatch(setStudentUploadLoading(false));
        dispatch(updatePath(paths.DraftList.tabValue));

        dispatch(setSnackbar(
            localize('studentUploadConfirm.uploadComplete'),
            Enum.severityType.success
        ));
        dispatch(openStyledSnackBar());
    } catch (error) {
        dispatch(setStudentUploadLoading(false));
        window.warn('error in onSaveDraftStudentUpload',
            error
        );
    }
};