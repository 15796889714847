import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Resources from 'components/Resources/Resources';
import withRouter from 'util/customHooks/withRouter';
import {
    updatePath
} from '../../workers/pathWorker';

const mapStateToProps = () => ({});

const ResourcesContainer = withRouter(
    connect(
        mapStateToProps, {
            updatePath
        }
    )(injectIntl(Resources))
);

export default ResourcesContainer;