import React from 'react';
import { Paper, IconButton, Hidden } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import commonUseStyles, { commonStyles } from '../../styles/CommonStyles';
import SeparatorHeader from '../../widgets/common/SeparatorHeader';
import breakpointsNames from '../../styles/ResponsiveConstants';
import { localize } from '../../util/Localizer';
import { Enum } from "../../util/EnumHelper";
import StyledCheckbox from '../../components/Common/StyledCheckbox/StyledCheckbox';

const HeaderGrid = styled(Paper)(({ theme }) => ({
    ...commonUseStyles.header,
    background: theme.palette.everDrivenLightBlue20,
    paddingLeft: 0
}));

const CheckBoxColumn = styled('div')(() => ({
    maxWidth: 40
}));

const NameColumn = styled('div')(({ theme }) => ({
    ...commonStyles(theme).userMgt.nameColumn,
    [theme.breakpoints.down(breakpointsNames.md)]: {
        paddingLeft: 10
    }
}));

const LastNameColumn = styled('div')(({ theme }) => ({
    ...commonStyles(theme).userMgt.lastNameColumn

}));

const EmailColumn = styled('div')(({ theme }) => ({
    ...commonStyles(theme).userMgt.emailColumn
}));

const GroupColumn = styled('div')(() => ({
    minWidth: 360
}));

const DetailsBox = styled('div')(() => ({
    minWidth: 52,
    maxWidth: 52
}));

const HideMobileSizeButton = styled(IconButton)(({ theme }) => ({
    [theme.breakpoints.down(breakpointsNames.sm)]: {
        display:'none!important'
    }
}));

function UserRowHeader({ checkedAll, handleChangeCheckAll, columns, onSorter }) {
    return <HeaderGrid>
        <div className="memberCard" style={commonUseStyles.headerContent}>
            <Hidden mdDown>
                <CheckBoxColumn>
                    <StyledCheckbox
                        color="secondary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        isChecked={checkedAll}
                        size="medium"
                        onChange={handleChangeCheckAll}
                    />
                </CheckBoxColumn>
                <SeparatorHeader />
            </Hidden>
            <NameColumn>
                <div>
                    <strong>{localize('userManagement.firstName')}</strong>
                    <HideMobileSizeButton
                        size="small"
                        onClick={()=> onSorter(columns.firstName.name)}
                    >
                        {columns.firstName.sort===Enum.Sorter.Asc
                            ? <ExpandMore />
                            : <ExpandLess />
                        }
                    </HideMobileSizeButton>
                </div>
            </NameColumn>
            <SeparatorHeader />
            <LastNameColumn>
                <div><strong>{localize('userManagement.lastName')}</strong>
                    <HideMobileSizeButton
                        size="small"
                        onClick={()=> onSorter(columns.lastName.name)}
                    >
                        {columns.lastName.sort===Enum.Sorter.Asc
                            ? <ExpandMore />
                            : <ExpandLess />
                        }
                    </HideMobileSizeButton>
                </div>
            </LastNameColumn>
            <SeparatorHeader/>
            <Hidden mdDown>
                <EmailColumn>
                    <div><strong>{localize('userManagement.email')}</strong>
                        <IconButton
                            size="small"
                            onClick={()=> onSorter(columns.emailAddress.name)}
                        >
                            {columns.emailAddress.sort===Enum.Sorter.Asc
                                ? <ExpandMore />
                                : <ExpandLess />
                            }
                        </IconButton>
                    </div>
                </EmailColumn>
                <SeparatorHeader/>
            </Hidden>
            <Hidden lgDown>
                <GroupColumn>
                    <div><strong>{localize('userManagement.featureGroups')}</strong></div>
                </GroupColumn>
                <SeparatorHeader/>
            </Hidden>
            <DetailsBox>
                <div>
                    <strong>
                        <Hidden smDown>
                            {localize('userManagement.details')}
                        </Hidden>
                    </strong>
                </div>
            </DetailsBox>
        </div>
    </HeaderGrid>;
}

export default UserRowHeader;
