import React from 'react';
import { Grid } from '@mui/material';
import {
    array,
    func,
    object,
    oneOfType,
    string
} from 'prop-types';

import { styled } from '@mui/material/styles';
import { localize } from '../../../util/Localizer';
import StyledButton from '../StyledButton/StyledButton';

const ButtonGroup = styled(Grid)(() => ({
    paddingBottom: 15,
    paddingTop: 15,
    marginBottom: -10
}));

const BoxTitle = styled('div')(() => ({
    verticalAlign: 'top',
    paddingTop: 3,
    paddingRight: 5
}));

const MinLabel = styled('div')(() => ({
    fontSize: 9
}));

const ListOption = styled(StyledButton)((props) => {
    const nonSelectedFilter = {
        color: props.theme.palette.black,
        backgroundColor: props.theme.palette.everDrivenLightBlue20,
        '&:hover': {
            backgroundColor: props.theme.palette.everDrivenDarkBlue,
            color: props.theme.palette.white
        }
    };

    const button = {
        marginLeft: 0,
        marginRight: 10,
        marginBottom: 5
    };

    return {
        ...button,
        ...(props?.color === 'inherit' && nonSelectedFilter)
    };
});

const defaultOption = {
    color: 'inherit',
    variant: 'text'
};

function StyledFilterOptions({
    listOptions,
    handleChange,
    title,
    description
}) {
    return (
        <ButtonGroup container>
            {title && description &&
                <div>
                    <BoxTitle>
                        <b>{title}: </b>
                    </BoxTitle>
                    <MinLabel>
                        {description}
                    </MinLabel>
                </div>
            }

            {listOptions && listOptions.length > 0 &&
                listOptions.map(option => {
                    if (!option.hidden) {
                        return (
                            <div
                                key={option.key ?? option.id}
                            >
                                <ListOption
                                    color={option.color ?? defaultOption.color}
                                    style={{backgroundColor: option.backgroundColor}}
                                    testId={`listOption_${option.name}`}
                                    variant={option.variant ?? defaultOption.variant}
                                    onClick={() => handleChange(option)}
                                >
                                    {localize(option.name)} ({option.quantity})
                                </ListOption>
                            </div>
                        );
                    }
                    
                    return null;
                })
            }

        </ButtonGroup>
    );
}

StyledFilterOptions.defaultProps = {
    description: null,
    title: null,
    listOptions: null,
    handleChange: () => null
};

StyledFilterOptions.propTypes = {
    listOptions: oneOfType([
        array,
        object
    ]),
    handleChange: func,
    title: string,
    description: string

};
export default StyledFilterOptions;
