import React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { localize } from '../../util/Localizer';

const CopyButton = styled(IconButton)(() => ({
    '& > span': {
        flexDirection: 'column'
    },
    flexDirection: 'column',
    padding: 12
}));

const CopyButtonText = styled('span')(() => ({
    fontSize: 8
}));

function CopyAllTimeButton({ disabled, id, onClick }) {
    return (
        <CopyButton
            aria-label="copy all"
            disabled={disabled}
            id={id}
            size="small"
            title={localize('tripRequestForm.copyAllToolTip')}
            onClick={onClick}
        >
            <FileCopyIcon fontSize="small" />
            <CopyButtonText>{localize('tripRequestForm.copyAllButton')}</CopyButtonText>
        </CopyButton>
    );
}

export default CopyAllTimeButton;
