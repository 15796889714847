import { ActiveTripStatus, Enum, ScheduledTripStatus, inProgressStatus, optionsTripGroup } from "./EnumHelper";
import { calcIsToday } from "./dateUtils";

const getCompletedDate = trip => {
    if (trip.State === Enum.TripState.Completed) {
        return trip.DropoffTLT === null ? trip.DueTimeTLT : trip.DropoffTLT;
    }
    if (trip.State === Enum.TripState.NoShow) {
        return trip.DueDateTimeUTC;
    }
    if (trip.State === Enum.TripState.Canceled) {
        return trip.CancelTLT === null ? trip.DueTimeTLT : trip.CancelTLT;
    }

    return null;
};

const internalSort = (firstDate, secondDate) => {
    if(firstDate === secondDate) {
        return 0;
    }

    return firstDate > secondDate ? 1 : -1;
};

export const sortCompleted = (first, second) => {
    const firstDate = getCompletedDate(first);
    const secondDate = getCompletedDate(second);

    return -1 * internalSort(firstDate,
        secondDate);
};

const checkInProgressTrips = (first, second) => {
    if (first.facilityETA && !second.facilityETA) {
        return -1;
    }
    if (!first.facilityETA && second.facilityETA) {
        return 1;
    }
    if (first.facilityETA && second.facilityETA) {
        return internalSort(first.facilityETA,
            second.facilityETA);
    }
    if (!first.facilityETA && !second.facilityETA) {
        return internalSort(first.DueDateTimeUTC,
            second.DueDateTimeUTC);
    }

    return 1;
};

export const sortActive = (first, second) => {
    // Ordering InProgress trips
    if (inProgressStatus.indexOf(first.State) !== -1
        && inProgressStatus.indexOf(second.State) === -1) {
        return -1;
    }
    if (inProgressStatus.indexOf(first.State) === -1
        && inProgressStatus.indexOf(second.State) !== -1) {
        return 1;
    }

    if (inProgressStatus.indexOf(first.State) !== -1
        && inProgressStatus.indexOf(second.State) !== -1) {
        return checkInProgressTrips(first, second);
    }

    // Ordering Active trips
    if (first.State === Enum.TripState.Active
        && ScheduledTripStatus.indexOf(second.State) !== -1) {
        return -1;
    }

    if (ScheduledTripStatus.indexOf(first.State) !== -1
        && second.State === Enum.TripState.Active) {
        return 1;
    }

    // Ordering Scheduled an Will Call trips
    if (first.State === Enum.TripState.Scheduled
        && second.State === Enum.TripState.WillCall) {
        return -1;
    }

    if (first.State === Enum.TripState.WillCall
        && second.State === Enum.TripState.Scheduled) {
        return 1;
    }

    return internalSort(first.DueDateTimeUTC,
        second.DueDateTimeUTC);
};

export const sortFuture = (first, second) => {
    if (first.DueDateTimeUTC === second.DueDateTimeUTC) {
        if (
            first.State === Enum.TripState.WillCall &&
      second.State !== Enum.TripState.WillCall
        ) {
            return 1;
        }
        if (
            first.State !== Enum.TripState.WillCall &&
      second.State === Enum.TripState.WillCall
        ) {
            return -1;
        }

        return internalSort(first.DueDateTimeUTC,
            second.DueDateTimeUTC);
    }

    return internalSort(first.DueDateTimeUTC,
        second.DueDateTimeUTC);
};

export const getTripType = (trip) => {
    if (ActiveTripStatus.indexOf(trip.State) !== -1
    || (ScheduledTripStatus.indexOf(trip.State) !== -1 && calcIsToday(trip,
        trip.timeZone))) {
        return optionsTripGroup.Today;
    }
    if (ScheduledTripStatus.indexOf(trip.State) !== -1 && !calcIsToday(trip,
        trip.timeZone)) {
        return optionsTripGroup.Future;
    }
    if (trip.State === Enum.TripState.NoShow) {
        return optionsTripGroup.NoShow;
    }

    return optionsTripGroup.Finished;
};

export const sortCompletedStudents = (first, second) => (
    getCompletedDate(first) > getCompletedDate(second)
        ? -1
        : 1
);

export const sortNoShowStudents = (first, second) => (
    first.DueDateTimeUTC > second.DueDateTimeUTC
        ? -1
        : 1
);

export const sortActiveStudents = (first, second) => {
    if (first.State !== Enum.TripState.WillCall && second.State !== Enum.TripState.WillCall) {
        return `${first.DueTimeTLT}` > `${second.DueTimeTLT}` ? 1 : -1;
    }

    if (first.DueDateTLT === second.DueDateTLT) {
        return first.status > second.status ? 1 : -1;
    }

    return `${first.DueTimeTLT}` > `${second.DueTimeTLT}` ? 1 : -1;
};

export const sortFutureStudents = (first, second) => {
    if (first.State !== Enum.TripState.WillCall && second.State !== Enum.TripState.WillCall) {
        return `${first.DueDateTLT} ${first.DueTimeTLT}` > `${second.DueDateTLT} ${second.DueTimeTLT}` ? 1 : -1;
    }

    if (first.DueDateTLT === second.DueDateTLT) {
        return first.status > second.status ? 1 : -1;
    }

    return `${first.DueDateTLT} ${first.DueTimeTLT}` > `${second.DueDateTLT} ${second.DueTimeTLT}` ? 1 : -1;
};
