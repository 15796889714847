import React, { useEffect } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import DPApp from './DPApp';
import CustomConector from './studentUpload/CustomConector';
import CustomStepIcon from './studentUpload/CustomStepIcon';
import StyledStepper from './Common/StyledStepper/StyledStepper';
import StudentListUploadContainer from '../redux/containers/studentUpload/StudentListUploadContainer';
import breakpointsNames from '../styles/ResponsiveConstants';
import StyledMobileUnavailable from './Common/StyledMobileUnavailable/StyledMobileUnavailable';
import ModalConfirmation from '../controls/Common/ModalConfirmation';
import useBlocker from '../util/customHooks/useBlocker';
import { localize } from '../util/Localizer';
import StudentOverviewUploadContainer from '../redux/containers/studentUpload/StudentOverviewUploadContainer';

const useStyles = makeStyles((theme) => ({
    boxStepper: {
        backgroundColor: theme.palette.offWhite,
        paddingTop: 15
    },
    step: {
        paddingRight: 0
    },
    stepLabel: {
        paddingRight: 4
    },
    container: {
        backgroundColor: theme.palette.offWhite,
        paddingRight: 15,
        paddingLeft: 15
    },
    containerButtons: {
        marginTop: 15,
        marginBottom: 15,
        display: 'flex',
        justifyContent: 'right',
        '&> button:nth-of-type(2)': {
            marginLeft: 10
        },
        '&> :last-child': {
            marginLeft: 10
        }
    },
    subTitle: {
        color: theme.palette.everDrivenDarkGrey
    },
    normalSize: {
        display: 'block',
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            display: 'none'
        },
        [theme.breakpoints.only(breakpointsNames.sm)]: {
            display: 'block',
            "@media only screen and (orientation: landscape)": {
                display: 'none'
            }
        }
    }
}));

const steps = [
    'Upload List',
    'Overview'
];

function StudentUpload({
    isLeavingNoSavingOpen,
    nextPath,
    step,
    studentFiles,
    initForm,
    onLeaveStudentUpload,
    setLeaveNoSavingModalOpen,
    setNextPath
}) {
    const classes = useStyles();

    const blocker = (tx) => {
        if (studentFiles.length > 0) {
            setNextPath(tx.location.pathname);
            setLeaveNoSavingModalOpen(true);
        } else {
            tx.retry();
        }
    };

    useBlocker(blocker, !studentFiles.length);

    useEffect(() => {
        // This is for preventing the browser ask for a confirmation to leave the page when you have work unfinished
        window.addEventListener('beforeunload', (event) => {
            event.stopImmediatePropagation();
        });

        return () => initForm();
    }, []);

    return (
        <DPApp>
            <StyledMobileUnavailable />
            <div className={classes.normalSize}>
                <StyledStepper
                    activeStep={step}
                    connector={<CustomConector />}
                    parentClassName={classes.boxStepper}
                    steps={steps}
                    customStepIcon={CustomStepIcon}
                />
                {
                    step === 0
                        ? <StudentListUploadContainer />
                        : <StudentOverviewUploadContainer />
                }
            </div>
            <ModalConfirmation
                isOpen={isLeavingNoSavingOpen}
                contentMessage={localize('studentUpload.sendFileBeforeLeave')}
                confirmInputLabel={localize('studentUpload.yes')}
                cancelInputLabel={localize('studentUpload.no')}
                disableBackDrop
                disableEscKey
                onConfirm={() => onLeaveStudentUpload(nextPath)}
                onCancel={() => setLeaveNoSavingModalOpen(false)}
            />
        </DPApp>
    );
}

export default StudentUpload;