import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { setCalendars } from '../../actions/calendarUploadActions';
import { onDeleteFile } from '../../workers/calendarUploadWorker';
import CalendarUploadRow from '../../../components/calendar/CalendarUploadRow';

const mapStateToProps = state => {
    const { CalendarUploadReducers } = state;

    if (CalendarUploadReducers) {
        return {
            calendars: CalendarUploadReducers.calendars
        };
    }

    return {};
};

const CalendarUploadRowContainer =
    connect(mapStateToProps,
        {
            onDeleteFile,
            setCalendars
        })(injectIntl(CalendarUploadRow));

export default CalendarUploadRowContainer;