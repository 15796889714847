import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import StyledFormLabel from 'components/Common/StyledFormLabel/StyledFormLabel';
import StyledDatePicker from 'components/Common/StyledDatePicker/StyledDatePicker';
import { trIsEndDateValid, trIsStartDateValid } from 'util/dateUtils';

function TripDates({
    options,
    currentTrip,
    errors,
    datesOptions,
    onChange
}) {
    const handleChange = (evt, id) => (
        onChange(evt, id, currentTrip)
    );

    const renderDates = () => (
        options.map(option => (
            <Grid item xs={6} key={option.id}>
                <StyledFormLabel label={option.label} isRequired />
                <StyledDatePicker
                    id={option.id}
                    value={option.value}
                    minDate={option.minDate}
                    onChange={handleChange}
                    error={errors?.[`${option.id}`]?.isError
                        && (option.id === datesOptions.startDate
                            ? !trIsStartDateValid(option.value)
                            : !trIsEndDateValid(option.value, options[0].value))
                    }
                />
            </Grid>
        ))
    );

    return (
        <Grid container columnSpacing={6} sx={{ marginTop: '20px' }}>
            {renderDates()}
        </Grid>
    );
}

TripDates.defaultProps = {
    currentTrip: null,
    options: [],
    errors: null,
    onChange: null
};

/* eslint-disable react/forbid-prop-types */
TripDates.propTypes = {
    currentTrip: PropTypes.number,
    options: PropTypes.array,
    errors: PropTypes.object,
    onChange: PropTypes.func

};
/* eslint-enable react/forbid-prop-types */

export default TripDates;