import React from 'react';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import AccountsContainer from 'redux/containers/transportationRequest/tripBuilder/AccountsContainer';
import RecurringContainer from 'redux/containers/transportationRequest/tripBuilder/RecurringContainer';
import TripDatesContainer from 'redux/containers/transportationRequest/tripBuilder/TripDatesContainer';
import TripDaysContainer from 'redux/containers/transportationRequest/tripBuilder/TripDaysContainer';
import TripTypesContainer from 'redux/containers/transportationRequest/tripBuilder/TripTypesContainer';
import SegmentContainer from 'redux/containers/transportationRequest/tripBuilder/SegmentContainer';
import StyledConfirmModal from 'components/Common/StyledConfirmModal/StyledConfirmModal';
import { localize } from 'util/Localizer';
import { museoSansFont } from 'util/EnumHelper';

function TripBuilderEditor({
    tripType,
    tripNumber,
    segmentTypes,
    removeTrip,
    onChangeRecurring,
    onChangeAccount,
    onChangeTripType,
    onChangeDays
}) {
    const [ showModal, setShowModal ] = React.useState(false);

    const handleChangeAccount = (evt) => (
        onChangeAccount(evt, tripNumber)
    );

    const handleChangeRecurring = (evt) => (
        onChangeRecurring(evt, tripNumber)
    );

    const handleChangeTripType = (evt) => (
        onChangeTripType(evt, tripNumber)
    );

    const handleChangeDays = (evt) => (
        onChangeDays(evt, tripNumber)
    );

    const handleRemoveTrip = () => {
        setShowModal(false);
        removeTrip(tripNumber);
    };

    return (
        <Grid container>
            <AccountsContainer
                currentTrip={tripNumber}
                extraStyle={{ height: 34, marginTop: -6 }}
                icon={<CloseIcon style={{ fontSize: 16, paddingBottom: 1 }} />}
                onClick={() => setShowModal(!showModal)}
                onChange={handleChangeAccount}
            />
            <RecurringContainer
                currentTrip={tripNumber}
                onChange={handleChangeRecurring}
                extraStyle={{ fontFamily: museoSansFont, height: 34, marginTop: 20 }}
            />
            <TripDatesContainer currentTrip={tripNumber}/>
            <TripDaysContainer currentTrip={tripNumber} onChange={handleChangeDays}/>
            <TripTypesContainer currentTrip={tripNumber} onChange={handleChangeTripType}/>
            <SegmentContainer currentTrip={tripNumber} segmentType={segmentTypes.arrival} tripType={tripType} />
            <SegmentContainer currentTrip={tripNumber} segmentType={segmentTypes.departure}/>
            <StyledConfirmModal
                isOpen={showModal}
                contentMessage={localize('transportationRequest.removeTrip')}
                confirmInputLabel={localize('transportationRequest.remove')}
                cancelInputLabel={localize('transportationRequest.cancel')}
                onConfirm={() => handleRemoveTrip()}
                onCancel={() => setShowModal(false)}
            />
        </Grid>
    );
}

TripBuilderEditor.defaultProps = {
    tripNumber: 0,
    tripType: null,
    segmentTypes: null,
    removeTrip: () => {},
    onChangeRecurring: () => {},
    onChangeAccount: () => {},
    onChangeTripType: () => {},
    onChangeDays: () => {}
};

/* eslint-disable react/forbid-prop-types */
TripBuilderEditor.propTypes = {
    tripType: PropTypes.string,
    tripNumber: PropTypes.number,
    segmentTypes: PropTypes.object,
    removeTrip: PropTypes.func,
    onChangeRecurring: PropTypes.func,
    onChangeAccount: PropTypes.func,
    onChangeTripType: PropTypes.func,
    onChangeDays: PropTypes.func
};

export default TripBuilderEditor;