import React from "react";
import CardHeader from '@mui/material/CardHeader';
import { makeStyles } from '@mui/styles';
import {
    bool,
    node,
    string
} from 'prop-types';

const useStyles = makeStyles(() => ({
    avatar: {
        marginRight: 0
    }
}));

function StyledCardHeader({
    className,
    disableTypography,
    avatar,
    title
}) {
    const classes = useStyles();

    return (
        <CardHeader
            classes={classes}
            className={className}
            disableTypography={disableTypography}
            avatar={avatar}
            title={title}
        />
    );
}

StyledCardHeader.defaultProps = {
    className: null,
    disableTypography: false,
    avatar: null,
    title: null
};

/* eslint-disable react/forbid-prop-types */
StyledCardHeader.propTypes = {
    className: string,
    disableTypography: bool,
    avatar: node,
    title: string
};
/* eslint-enable react/forbid-prop-types */

export default StyledCardHeader;