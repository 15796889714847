import React from 'react';
import { Paper, FormControl, InputLabel, IconButton, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from '@mui/styles/withStyles';
import StyledInput from '../../components/Common/StyledInput/StyledInput';
import commonUseStyles from '../../styles/CommonStyles';

const useStyles = theme => ({
    paper: {
        paddingLeft: 0,
        background: theme.palette.everDrivenLightBlue20,
        boxShadow: theme.typography.boxShadow,
        borderRadius: '6',
        height: 60,
        ...commonUseStyles.defaultResponsive,
        width: '100%'
    },
    searchTextField: {
        flex: '1 0 250px',
        '@media only screen and (max-width: 500px)': {
            flex: '1 0 150px'
        }
    },
    forms: {
        marginLeft: 15,
        marginRight: 15
    },
    inputTextField: {
        backgroundColor: theme.palette.transparent
    }
});

function FilterSearchBar({
    classes,
    leftControlFilter,
    filterLabel,
    filterValue,
    onChangeFilter,
    rightControlFilter,
    extraClassCss,
    autoFocus
}) {
    const onLostFocus = (event) => {
        if (event.key === 'Enter') {
            document.activeElement.blur();
        }
    };

    const handleMouseDownClear = event => {
        event.preventDefault();
    };

    const classNamePaper = `memberCard ${classes.paper} ${extraClassCss}`;

    return (
        <Paper className={classNamePaper}>
            {leftControlFilter}
            <div className={classes.searchTextField}>
                <form autoComplete="off"
                    className={classes.forms}
                    onSubmit={(e) => e.preventDefault()}
                >
                    <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="filterSearch">
                            {filterLabel}
                        </InputLabel>
                        <StyledInput
                            id="filterSearch"
                            className={classes.inputTextField}
                            value={filterValue}
                            onChange={(e) => onChangeFilter(e.target.value)}
                            onKeyPress={(e) => onLostFocus(e)}
                            autoFocus={autoFocus}
                            fullWidth
                            endAdornment={
                                filterValue.length > 0 ?
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => onChangeFilter('')}
                                            onMouseDown={handleMouseDownClear}
                                            edge="end"
                                            size="large"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                    : null
                            }
                        />
                    </FormControl>
                </form>
            </div>
            {rightControlFilter}
        </Paper>
    );
}

export default withStyles(useStyles)(FilterSearchBar);
