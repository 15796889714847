import React from "react";
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import CommonStyles from 'styles/CommonStyles';
import StyledFormLabel from 'components/Common/StyledFormLabel/StyledFormLabel';
import StyledTypography from "../StyledTypography/StyledTypography";

function StyledSectionItem({
    contentStyle,
    size,
    label,
    value,
    testid,
    labelTestid
}) {
    return (
        <Grid item xs={size} sx={{ ...contentStyle, paddingRight: '2px' }}>
            <StyledFormLabel
                label={label}
                testid={labelTestid}
            />
            <StyledTypography
                variant="body1"
                extraStyle={{ ...CommonStyles.bodyText, overflowWrap: 'anywhere' }}
                testid={testid}
            >
                {value ?? '-'}
            </StyledTypography>
        </Grid>
    );
}

StyledSectionItem.defaultProps = {
    contentStyle: null,
    size: 4,
    label: '',
    testid: '',
    value: null
};

/* eslint-disable react/forbid-prop-types */
StyledSectionItem.propTypes = {
    contentStyle: PropTypes.object,
    size: PropTypes.number,
    label: PropTypes.string,
    testid: PropTypes.string,
    value: PropTypes.any
};

export default StyledSectionItem;