export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const openSnackBar = (isOpen) => ({
    type: SNACKBAR_OPEN,
    isOpen
});

export const SNACKBAR_MESSAGE = 'SNACKBAR_MESSAGE';
export const setSnackBarMessage = (message) => ({
    type: SNACKBAR_MESSAGE,
    message
});

export const SNACKBAR_SEVERITY = 'SNACKBAR_SEVERITY';
export const setSnackBarSeverity = (severity) => ({
    type: SNACKBAR_SEVERITY,
    severity
});
