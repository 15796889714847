/* eslint-disable max-lines */
import React, { useState, useRef, forwardRef, useImperativeHandle, useMemo, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

import StyledTextField from '../../components/Common/StyledTextField/StyledTextField';
import StyledTypography from '../../components/Common/StyledTypography/StyledTypography';
import { localize } from "../../util/Localizer";
import { objectEmptyValidator } from '../../util/validationUtil';

const autocompleteService = { current: null };

const componentForm = {
    locality: 'long_name',
    administrative_area_level_1: 'long_name',
    country: 'long_name',
    postal_code: 'short_name'
};

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.secondary,
        marginRight: theme.spacing(2)
    },
    controlDisabled: {
        '& > div': {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            backgroundColor: theme.palette.everDrivenGrey40
        },
        '& > div > div > input': {
            backgroundColor: theme.palette.transparent
        },
        '& .MuiFilledInput-root.MuiInputBase-sizeSmall': {
            padding: 0,
            paddingTop: '10px',
            paddingLeft: '5px',
            paddingBottom: '10px'
        }

    },
    center: {
        paddingTop: '0px',
        paddingBottom: '0px',
        '& .MuiFilledInput-root.MuiInputBase-sizeSmall': {
            padding: 0,
            paddingTop: '10px',
            paddingLeft: '5px',
            paddingBottom: '10px'
        }
    },
    popperUp: {
        zIndex: 1
    }
}));

const AddressAutoComplete = forwardRef((props, ref) => {
    const classes = useStyles();
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const refAutocomplete = useRef();
    const refInputSearch = useRef();
    const {
        onSelectAddressInfo,
        value,
        label,
        idName,
        name,
        onChangeInput,
        isRequired,
        disabled,
        defaultAddress,
        isNewStyle,
        error
    } = props;
    const [ inputValue, setInputValue ] = useState(value);

    const addressInfo = {
        selectedAddress: {},
        streetAddress: '',
        city: '',
        zipCode: '',
        state: '',
        country: '',
        latitude: '',
        longitude: ''
    };

    const onClearInputFilter = () => {
        setInputValue('');
        const btn = refAutocomplete.current.querySelector("button[title='Clear']");

        if (btn) {
            btn.click();
        }
    };

    useImperativeHandle(ref,
        () => ({
            onClearFilter() {
                onClearInputFilter();
            }
        }));

    const handleChange = (event) => {
        setInputValue(event.target.value);
        onChangeInput(event.target.value);
    };

    const fetch = useMemo(
        () => throttle((request, callback) => {
            if (!request.input) {
                return false;
            }
            setLoading(true);
            const requestDto = {
                input: request.input,
                componentRestrictions: { country: 'US' }
            };

            autocompleteService.current.getPlacePredictions(requestDto,
                callback);

            return true;
        },
        200),
        []
    );

    const onPreSelectedAddress = (item, fieldValue) => {
        if (fieldValue && !objectEmptyValidator(fieldValue) && fieldValue.structured_formatting) {
            const parameter = {
                placeId: fieldValue.place_id
            };

            addressInfo.selectedAddress = fieldValue;
            addressInfo.streetAddress = fieldValue.structured_formatting.main_text;
            const geocoder = new window.google.maps.Geocoder();

            geocoder.geocode(parameter,
                (results, status) => {
                    if (status === window.google.maps.GeocoderStatus.OK) {
                        const addressComponents = results[0].address_components;

                        for (let i = 0; i < addressComponents.length; i += 1) {
                            const addressType = addressComponents[i].types[0];

                            if (componentForm[addressType]) {
                                const val = addressComponents[i][componentForm[addressType]];

                                switch (addressType) {
                                case 'locality':
                                    addressInfo.city = val;
                                    break;
                                case 'administrative_area_level_1':
                                    addressInfo.state = val;
                                    break;
                                case 'postal_code':
                                    addressInfo.zipCode = val;
                                    break;
                                case 'country':
                                    addressInfo.country = val;
                                    break;
                                default:
                                    break;
                                }
                            }
                            addressInfo.latitude = results[0].geometry.location.lat();
                            addressInfo.longitude = results[0].geometry.location.lng();
                        }
                    }
                    onSelectAddressInfo(addressInfo);
                });
        } else {
            onSelectAddressInfo({
                selectedAddress: {},
                streetAddress: '',
                city: '',
                zipCode: '',
                state: '',
                country: '',
                latitude: '',
                longitude: ''
            });
            setInputValue('');
        }
    };

    useEffect(() => {
        if (defaultAddress && !objectEmptyValidator(defaultAddress)) {
            setInputValue(defaultAddress.description);
            onPreSelectedAddress(null,
                defaultAddress);
        }
    },
    [defaultAddress]);

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setData([]);

            return undefined;
        }

        fetch({ input: inputValue },
            (results) => {
                if (active) {
                    setLoading(false);
                    setData(results || []);
                }
            });

        return () => {
            active = false;
        };
    },
    [ inputValue, fetch ]);

    const filterOption = (option) => {
        if (document.activeElement !== refInputSearch.current) {
            return false;
        }

        return option;
    };

    const onLoadableTextField = (params) => <StyledTextField
        isDisabled={disabled}
        id={idName}
        InputLabelProps={params.InputLabelProps}
        inputRef={refInputSearch}
        inputProps={{
            ...params.inputProps,
            "data-testid": idName,
            onKeyDown: (e) => {
                if (e.key === 'Enter') {
                    e.stopPropagation();
                }
            }
        }}
        isNewStyle={isNewStyle}
        InputProps={{
            className: params.InputProps.className,
            ref: params.InputProps.ref,
            endAdornment: (
                <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {(inputValue ? inputValue.length > 0 : false) ? params.InputProps.endAdornment : null}
                </>
            )
        }}
        label={label}
        name={name}
        isRequired={isRequired}
        value={value}
        isError={error}
        onChange={handleChange}
    />;

    const onRenderOptionResult = (properties, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        let parts = [];

        if (matches) {
            parts = parse(
                option.structured_formatting.main_text,
                matches.map((match) => [ match.offset, match.offset + match.length ])
            );
        }

        if (parts.length > 0) {
            return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Grid container alignItems="center" { ...properties } key={option.place_id}>
                    <Grid item>
                        <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid className="qm_block" item xs>
                        {parts.map((part) => (
                            <span key={Math.random()} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                            </span>
                        ))}

                        <StyledTypography
                            variant="body2"
                            color="textSecondary"
                        >
                            {option.structured_formatting.secondary_text}
                        </StyledTypography>
                    </Grid>
                </Grid>
            );
        }

        return null;
    };

    return (
        <Autocomplete
            classes={{
                popper: classes.popperUp,
                input: 'qm_block',
                option: 'qm_block'
            }}
            className={disabled ? classes.controlDisabled : classes.center}
            disabled={disabled}
            filterOptions={option => filterOption(option)}
            filterSelectedOptions
            freeSolo
            getOptionLabel={
                item => !objectEmptyValidator(item) && item.structured_formatting ?
                    item.structured_formatting.main_text :
                    inputValue || ''
            }
            id={idName}
            includeInputInList
            inputValue={value}
            loadingText={localize('search.searchingForStudents')}
            options={data}
            ref={refAutocomplete}
            value={defaultAddress}
            renderInput={params => onLoadableTextField(params)}
            renderOption={(properties, option) => onRenderOptionResult(properties,
                option)}
            onChange={onPreSelectedAddress}
        />
    );
});

export default AddressAutoComplete;