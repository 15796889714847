import {
    REPORT_SET_LOADING,
    REPORT_LOAD,
    REPORT_SET_MESSAGE_ROW,
    REPORT_SET_VIEW_BUTTON,
    REPORT_SET_DOWNLOAD_BUTTON,
    REPORT_SET_TERM,
    REPORT_SET_CARD_ELEMENTS,
    REPORT_SET_DATE_PICKER
} from '../actions/reportActions';

import { indexReportName } from "../../util/EnumHelper";

const reportsInitialState = {
    term: '',
    isLoadingReport: false,
    reportModel: {},
    cardElements: [],
    viewButtonsActive: {
        [indexReportName.StudentOnboardingReport]: false,
        [indexReportName.NoShowReport]: false
    },
    downloadButtonActive: {
        [indexReportName.StudentOnboardingReport]: false,
        [indexReportName.NoShowReport]: false
    },
    responseRowMessage: {
        [indexReportName.StudentOnboardingReport]: '',
        [indexReportName.NoShowReport]: ''
    },
    showDatePicker: false

};

// eslint-disable-next-line default-param-last
const ReportReducers = (state = reportsInitialState, action) => {
    switch (action.type) {
    case REPORT_SET_LOADING:
        return {
            ...state,
            isLoadingReport: action.isLoading
        };
    case REPORT_LOAD:
        return {
            ...state,
            reportModel: action.reportModel
        };
    case REPORT_SET_MESSAGE_ROW:
        return {
            ...state,
            responseRowMessage: {
                ...state.responseRowMessage,
                [action.reportIndex]: action.responseMessage
            }
        };
    case REPORT_SET_VIEW_BUTTON:
        return {
            ...state,
            viewButtonsActive: {
                ...state.viewButtonsActive,
                [action.reportIndex]: action.active
            }
        };
    case REPORT_SET_DOWNLOAD_BUTTON:
        return {
            ...state,
            downloadButtonActive: {
                ...state.downloadButtonActive,
                [action.reportIndex]: action.active
            }
        };
    case REPORT_SET_TERM:
        return {
            ...state,
            term: action.term.toLowerCase()
        };
    case REPORT_SET_CARD_ELEMENTS:
        return {
            ...state,
            cardElements: action.cardElements
        };
    case REPORT_SET_DATE_PICKER:
        return {
            ...state,
            showDatePicker: action.showDatePicker
        };
    default:
        return state;
    }
};

export default ReportReducers;
