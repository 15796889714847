import React from 'react';
import { Box, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { urlDPTrainingVideos, loomFlags } from 'util/EnumHelper';
import StyledHeader from 'components/Common/StyledHeader/StyledHeader';
import { localize } from 'util/Localizer';
import DPApp from '../DPApp';
import CollapsableVideo from './CollapsableVideo';
import Sidebar from './Sidebar';

const VideoContainer = styled(Grid)(() => ({
    align: 'center',
    textAlign: 'center',
    justify: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    direction: 'row',
    width: '100%'
}));

function Resources() {
    return (
        <DPApp>
            <StyledHeader
                idContainer="trfContainerHeader"
                title={localize('resources.title')}
                tabItems={[]}
                tabValue={0}
                onChangeTab={() => null}
                testid="resourcesHeader"
            />
            <Grid container sx={{maxWidth: '1128px', margin: '32px auto'}}>
                <VideoContainer item md={8} sm={12}>
                    {
                        urlDPTrainingVideos.map(
                            (video) => (
                                <Paper data-testid={`videoPaper${video.sortId}`}
                                    key={video.sortId}
                                    sx={{
                                        mx: '15px',
                                        mb: '32px',
                                        px: '40px',
                                        pb: '16px',
                                        borderRadius: '8px'
                                    }}
                                >
                                    <Box key={video.sortId}
                                        data-testid={`videoWrapperBox${video.sortId}`}
                                        sx={{ px: '0px' }}
                                    >
                                        <CollapsableVideo
                                            sortId={video.sortId}
                                            testId={`collapsableVideo${video.sortId}`}
                                            videoTitle={video.title}
                                            videoUrl={`${video.url}${loomFlags}`}
                                            videoDescription={localize(video.description)}
                                            videoLength={video.length}
                                        />
                                    </Box>
                                </Paper>
                            ))
                    }
                </VideoContainer>
                <Grid item md={4} sm={12}>
                    <Sidebar />
                </Grid>
            </Grid>
        </DPApp>
    );
}

export default Resources;
