import { connect } from 'react-redux';
import StudentInformation from '../../../controls/TripRequestForm/StudentInformation';
import { onUpdateModel } from '../../workers/tripRequestFormWorker';
import {
    onUpdateEndDate,
    onUpdateStartDate,
    onUpdateStudentAge
} from '../../workers/tripRequestFormStudentWorker';
import { updateModel } from '../../actions/tripRequestFormActions';

const mapStateToProps = state => {
    const { TripRequestFormReducers } = state;

    return {
        isEnabledStudentDate: TripRequestFormReducers.isEnabledStudentDate,
        studentBirthDateErrorMessage: TripRequestFormReducers.studentBirthDateErrorMessage,
        studentEndDateErrorMessage: TripRequestFormReducers.studentEndDateErrorMessage,
        studentStartDateErrorMessage: TripRequestFormReducers.studentStartDateErrorMessage,
        tripModel: TripRequestFormReducers.tripModel
    };
};

const StudentInformationContainer = connect(mapStateToProps,
    {
        onUpdateEndDate,
        onUpdateModel,
        onUpdateStartDate,
        onUpdateStudentAge,
        updateModel
    })(StudentInformation);

export default StudentInformationContainer;
