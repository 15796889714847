import React from 'react';
import { CircularProgress } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { bool, string } from 'prop-types';
import breakpointsNames from '../../../styles/ResponsiveConstants';

const useStyles = makeStyles((theme) => ({
    loadingParagraph: {
        margin: '0 0 10px',
        marginBlockStart: 0,
        marginblockEnd: 0
    },
    uiLoaderSpecificSection: {
        marginTop: 24,
        marginBottom: 24,
        textAlign: 'center',
        '& > div > p': {
            textAlign: 'center',
            fontSize: 20,
            margin: 0
        }
    },
    uiLoaderBackground: {
        width: '100%',
        height: '100%',
        top: 0,
        padding: 0,
        margin: 0,
        background: alpha(theme.palette.everDrivenGrey60, 0.3),
        display: 'block',
        position: 'fixed',
        zIndex: 3000
    },
    mainContent: {
        paddingTop: '15%',
        marginLeft: '-100px',
        [theme.breakpoints.down(breakpointsNames.sm)] : {
            marginLeft: 0
        },
        [theme.breakpoints.only(breakpointsNames.sm)]: {
            marginLeft: '-100px',
            "@media only screen and (orientation: landscape)": {
                marginLeft: 0
            }
        }
    },
    centerPage: {
        position: 'absolute',
        minWidth: 300,
        height: 200,
        zIndex: 50,
        top: '50%',
        left: '50%',
        margin: '-100px 0 0 -150px'
    }
}));

function StyledLoading({ isFullWidth, isLoggingOut, message }) {
    const classes = useStyles();

    const setMainCssClass = () => {
        let mainCss = classes.uiLoaderSpecificSection;

        if (isFullWidth) {
            mainCss = `${classes.uiLoaderBackground} ${mainCss}`;

            if (!isLoggingOut) {
                mainCss = `${classes.mainContent} ${mainCss}`;
            }
        }

        return mainCss;
    };

    return (
        <div
            className={setMainCssClass()}
            id="searchState"
        >
            <div className={isFullWidth ? classes.centerPage : null}>
                <p className={`${classes.loadingParagraph} searchHeader`}>
                    {message}
                </p>
                <CircularProgress
                    id="memProgress"
                    color="primary"
                    size={80}
                    thickness={5}
                    className="memProgress"
                />
            </div>
        </div>
    );
}

StyledLoading.defaultProps = {
    isFullWidth: false,
    isLoggingOut: false,
    message: null
};

/* eslint-disable react/forbid-prop-types */
StyledLoading.propTypes = {
    isFullWidth: bool,
    isLoggingOut: bool,
    message: string
};
/* eslint-enable react/forbid-prop-types */

export default StyledLoading;