import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getTrip } from 'redux/workers/trackTripWorker';
import TrackTripDetail from 'components/districtTripTracker/DttTrackTrip/TrackTripDetail/TrackTripDetail';
import withRouter from 'util/customHooks/withRouter';

const mapStateToProps = (state, ownProps) => {
    const {
        TrackTripReducers
    } = state;
    
    return {
        trip: TrackTripReducers.trip,
        isLoading: TrackTripReducers.isLoading,
        memberId: ownProps.router.params.memberId,
        tripId: ownProps.router.params.tripId
    };
};

const mapDispatchToProps = {
    getTrip
};

const TrackTripDetailContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(TrackTripDetail)));
  
export default TrackTripDetailContainer;
