import { connect } from 'react-redux';
import {
    addTrip,
    removeTrip,
    initialSetup
} from 'redux/workers/transportationRequest/transportationRequestTripsWorker';

import TripBuilder from 'components/TransportationRequest/TripBuilder/TripBuilder';
    
const mapStateToProps = (state) => {
    const { TransportationRequestReducers } = state;
    const { trips } = TransportationRequestReducers;

    return {
        trips
    };
};

const mapDispatchToProps = {
    addTrip,
    removeTrip,
    initialSetup
};

const TripBuilderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TripBuilder);

export default TripBuilderContainer;
