import {
    ERROR_PAGE_TITLE,
    ERROR_PAGE_CONTENT
} from 'redux/actions/errorPageActions';
import { localize } from 'util/Localizer';

const initialState = {
    content: localize('errorPage.content404'),
    title: localize('errorPage.title404')
};

// eslint-disable-next-line default-param-last
const errorPageReducer = (state = initialState, action) => {
    switch (action.type) {
    case ERROR_PAGE_TITLE:
        return {
            ...state,
            title: action.title
        };
    case ERROR_PAGE_CONTENT:
        return {
            ...state,
            content: action.content
        };
    default:
        return state;
    }
};

export default errorPageReducer;
