import {
    CALENDAR_IS_LOADING,
    CALENDAR_SELECTED
} from '../actions/districtTripTracker/calendarActions';

const initialState = {
    calendars: [],
    isLoading: true
};

/* eslint-disable-next-line default-param-last */
const calendarReducers = (state = initialState, action) => {
    switch (action.type) {
    case CALENDAR_IS_LOADING:
        return {
            ...state,
            isLoading: action.loading
        };
    case CALENDAR_SELECTED:
        return {
            ...state,
            calendars: [...action.calendar]
        };
    default:
        return state;
    }
};

export default calendarReducers;