import {
    TRIP_GROUP_SET_TRIPS,
    TRIP_GROUP_SET_TOKEN,
    TRIP_GROUP_CHANGE_FILTER_VALUE,
    TRIP_GROUP_RESET_FILTERS,
    TRIP_GROUP_SET_IS_FACILITY,
    TRIP_GROUP_SET_GUID,
    TRIP_GROUP_SET_SHOW_LOAD_MORE
} from 'redux/actions/tripGroupActions';

import { initialTripFilter } from 'redux/constants';

const initialState = {
    guid: "",
    trips: {
        Today: [],
        Future: [],
        NoShow: [],
        Finished: []
    },
    tokens: {
        Today: null,
        Future: null,
        NoShow: null,
        Finished: null
    },
    showLoadMore: {
        Today: 0,
        Future: 0,
        NoShow: 0,
        Finished: 0
    },
    isFacility: true,
    tripFilter: { ...initialTripFilter }
};

/* eslint-disable-next-line default-param-last */
const tripGroupReducers = (state = initialState, action) => {
    switch (action.type) {
    case TRIP_GROUP_SET_TRIPS:
        return {
            ...state,
            trips: {
                ...state.trips,
                [action.tripType]: action.trips
            }
        };
    case TRIP_GROUP_SET_SHOW_LOAD_MORE:
        return {
            ...state,
            showLoadMore: {
                ...state.showLoadMore,
                [action.tripType]: action.showLoadMore
            }
        };
    case TRIP_GROUP_SET_TOKEN:
        return {
            ...state,
            tokens: {
                ...state.tokens,
                [action.tripType]: action.token
            }
        };
    case TRIP_GROUP_CHANGE_FILTER_VALUE:
        return {
            ...state,
            tripFilter: {
                ...state.tripFilter,
                [action.filter]: action.filterValue
            }
        };
    case TRIP_GROUP_RESET_FILTERS:
        return {
            ...state,
            tripFilter: { ...initialTripFilter }
        };
    case TRIP_GROUP_SET_IS_FACILITY:
        return {
            ...state,
            isFacility: action.isFacility
        };
    case TRIP_GROUP_SET_GUID:
        return {
            ...state,
            guid: action.guid
        };
    default:
        return state;
    }
};

export default tripGroupReducers;
