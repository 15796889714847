import { connect } from 'react-redux';
import { localize } from 'util/Localizer';
import StyledButtonSelector from 'components/Common/StyledButtonSelector/StyledButtonSelector';
import { dataTripType } from 'util/data/TripBuilderDecorator';

const mapStateToProps = (state, ownProps) => {
    const { TransportationRequestReducers } = state;
    const { trips } = TransportationRequestReducers;

    return {
        trip: trips[ownProps.currentTrip],
        title: localize('transportationRequest.tripTypeTitle'),
        options: dataTripType(),
        widthButton: 200,
        value: trips[ownProps.currentTrip].tripTypes

    };
};

const mapDispatchToProps = {};

const TripTypesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StyledButtonSelector);

export default TripTypesContainer;
