import React, { useEffect } from 'react';
import { Paper, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { paths } from 'util/EnumHelper';
import Auth from 'auth/Auth';
import { localize } from 'util/Localizer';
import DPApp from 'components/DPApp';
import StyledLoading from 'components/Common/StyledLoading/StyledLoading';
import breakpointsNames from 'styles/ResponsiveConstants';

import StyledHeaderContent from 'components/Common/StyledHeaderContent/StyledHeaderContent';
import StyledMobileUnavailable from 'components/Common/StyledMobileUnavailable/StyledMobileUnavailable';
import StyledTypography from 'components/Common/StyledTypography/StyledTypography';
import DraftListTableContainer from 'redux/containers/draftList/DraftListTableContainer';
import StyledButton from 'components/Common/StyledButton/StyledButton';
import FilterSearchBar from 'controls/Common/FilterSearchBar';
import ModalConfirmation from 'controls/Common/ModalConfirmation';

const useStyles = makeStyles((theme) => ({
    listTitle: {
        paddingTop: 20,
        borderRadius: '6px 6px 0px 0px'
    },
    tableContainer: {
        borderRadius: '6px 6px 6px 6px',
        background: theme.palette.offWhite
    },
    searchBar: {
        marginBottom: 15
    },
    headerGrid: {
        height: 40,
        paddingLeft: 0,
        width: '100%',
        background: theme.palette.everDrivenLightBlue20,
        textAlign: 'center',
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    table: {
        background: theme.palette.white,
        margin: 10
    },
    headerText: {
        marginBottom: 0,
        marginTop: 0
    },
    addStudent: {
        height: 45,
        minWidth: 170
    },
    formContent: {
        paddingRight: 15,
        paddingLeft: 15,
        paddingTop: 200
    },
    normalSize: {
        display: 'block',
        [theme.breakpoints.down(breakpointsNames.sm)]: {
            display: 'none'
        },
        [theme.breakpoints.only(breakpointsNames.sm)]: {
            display: 'block',
            "@media only screen and (orientation: landscape)": {
                display: 'none'
            }
        }
    },
    buttonLink: {
        color: theme.palette.everDrivenLightBlue,
        fontWeight: 500,
        padding: 0,
        margin: 0,
        minWidth: 0,
        textTransform: 'none',
        fontSize: 12,
        '& > span > svg': {
            fontSize: '14px !important'
        },
        '&.Mui-disabled': {
            color: theme.palette.white
        },
        [theme.breakpoints.down(breakpointsNames.md)]: {
            fontSize: 10
        },
        [theme.breakpoints.down(breakpointsNames.lg)]: {
            padding: 5
        }
    },
    leftButtonLink: {
        paddingRight: 5,
        marginLeft: -10
    },
    rightControls: {
        display: 'flex',
        justifyContent: "space-between",
        margin: 0,
        padding: 0
    }
}));

function DraftList({
    drafts,
    isLoading,
    isModalOpen,
    router,
    searchTerm,
    addDeleteDraft,
    closeModal,
    goToTRF: goToBlackTRF,
    initialSetup,
    onDeleteDraft,
    performSearch,
    updatePath
}) {
    const classes = useStyles();
    const { navigate } = router;

    const goToTRF = (draft) => {
        const { studentId } = draft;

        navigate(
            Auth.getConfig().useNewTransportationRequestForm ?
                paths.TransportationRequest.path : paths.TransportationRequestForm.path,
            { state: { studentCode: studentId, studentId, isDraft: true }}
        );
  	};

    const renderRightControls = (draft) => (
        <div className={classes.rightControls}>
            <StyledButton
                className={`${classes.buttonLink} ${classes.leftButtonLink} `}
                color="inherit"
                type="button"
                variant="text"
                testId="openDraft"
                onClick={() => goToTRF(draft)}
            >
                <strong>
                    {localize('draftList.openDraft')}
                </strong>
            </StyledButton>
            <StyledButton
                startIcon={<DeleteIcon fontSize="inherit" className={classes.deleteIcon} />}
                className={classes.buttonLink}
                color="inherit"
                type="button"
                variant="text"
                testId="deleteDraft"
                onClick={() => addDeleteDraft(draft)}
            />
        </div>
    );

    const renderIsLoading = () => (
        isLoading &&
            <StyledLoading isFullWidth message={localize('draftList.processing')} />
    );

    const renderHeader = () => (
        <StyledHeaderContent
            isFixed
            title={localize('transportationRequest.title')}
        >
            <Grid item xs={12}>
                <Paper className={classes.headerGrid}>
                    <StyledTypography
                        className={classes.headerText}>
                        {localize('draftList.title')}
                    </StyledTypography>
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <StyledButton
                    className={classes.addStudent}
                    testId="addSibling"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => goToBlackTRF()}
                >
                    {localize('tripRequestForm.addSibling')}
                </StyledButton>
            </Grid>
            <Grid item xs={6} sx={{textAlign: 'right'}}>
                <StyledButton
                    className={classes.addStudent}
                    color="primary"
                    testId="uploadStudentData"
                    onClick={() => updatePath(paths.StudentUpload.tabValue)}
                >
                    {localize('tripRequestForm.uploadStudentData')}
                </StyledButton>
            </Grid>
        </StyledHeaderContent>
    );

    const renderBody = () => (
        !isLoading &&
        <>
            {renderHeader()}

            <Grid container className={classes.formContent}>
                <Grid item xs={12} className={classes.searchBar}>
                    <FilterSearchBar
                        filterLabel={localize('draftList.search')}
                        filterValue={searchTerm}
                        onChangeFilter={performSearch}
                    />
                </Grid>
                <Grid item xs={12} className={classes.tableContainer}>
                    <StyledHeaderContent
                        title={localize('draftList.draftListTitle')}
                        extraCssClass={classes.listTitle}
                    >
                        <Grid item xs={12}>
                            <StyledTypography variant="h5" className={classes.subTitle}>
                                {localize('draftList.draftListSubTitle')}
                            </StyledTypography>
                        </Grid>
                    </StyledHeaderContent>

                    {drafts &&
                        <Paper className={classes.table}>
                            <DraftListTableContainer
                                rightControls={renderRightControls}
                            />
                        </Paper>
                    }
                </Grid>
            </Grid>
        </>
    );

    useEffect(() => {
        initialSetup();
    }, []);

    return (
        <DPApp>
            <StyledMobileUnavailable />
            <div className={classes.normalSize}>
                {renderIsLoading()}
                {renderBody()}
            </div>

            <ModalConfirmation
                isOpen={isModalOpen}
                contentMessage={localize('draftList.deleteDraftConfirmation')}
                confirmInputLabel={localize('tripList.yes')}
                cancelInputLabel={localize('tripList.no')}
                onConfirm={() => onDeleteDraft()}
                onCancel={closeModal}
            />
        </DPApp>
    );
}

export default DraftList;
