import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { goToStudent } from 'redux/workers/districtTripTracker/studentWorker';
import { tripGroupSetFilter } from 'redux/actions/tripGroupActions';
import { goToTrackTrip } from 'redux/workers/trackTripWorker';
import withRouter from 'util/customHooks/withRouter';

import { loadTrips, defaultSetup } from 'redux/workers/tripGroupWorker';
import TripGroup from 'components/trip/TripGroup/TripGroup';

const mapStateToProps = (state, ownProps) => {
    const { TripGroupReducers } = state;

    return {
        tripType: ownProps.tripType,
        filter: TripGroupReducers.tripFilter[ownProps.tripType],
        trips: TripGroupReducers.trips[ownProps.tripType],
        moreTrips: TripGroupReducers.tokens[ownProps.tripType],
        guid: ownProps.router.params.facilityId,
        memberId: ownProps.router.params.memberId ?? false,
        isFacility: TripGroupReducers.isFacility,
        showLoadMoreBtn: TripGroupReducers.showLoadMore[ownProps.tripType]
    };
};

const mapDispatchToProps = {
    changeFilter: tripGroupSetFilter,
    loadTrips,
    goToTrackTrip,
    goToStudent,
    defaultSetup
};

const TripGroupContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(TripGroup)));

export default TripGroupContainer;
