import React from 'react';
import {
    string,
    oneOf,
    node,
    object
} from 'prop-types';
import { Typography } from '@mui/material';

function StyledTypography({
    className,
    children,
    extraStyle,
    testid,
    variant,
    ...props
}) {
    return <Typography
        variant={variant}
        className={className}
        data-testid={testid}
        sx={extraStyle}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        {children}
    </Typography>;
}

StyledTypography.defaultProps = {
    className: null,
    children: null,
    extraStyle: null,
    testid: null,
    variant: 'h3'
};

/* eslint-disable react/forbid-prop-types */
StyledTypography.propTypes = {
    children: node,
    className: string,
    extraStyle: object,
    testid: string,
    variant: oneOf([
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'body1',
        'body2',
        'caption'
    ])
};
/* eslint-enable react/forbid-prop-types */

export default StyledTypography;
